import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import { PENDING_FLOORPLAN_REQUESTS } from './queries';
import DataTables from '@preflighttech/preflight-tables';
import columnDefinitions from './columnDefinitions';
import {
  Typography, preflightTableStyles
} from 'components/src/styles';
import AuthContext from 'components/src/contexts/AuthContext';
import Review from './Review';
import * as Formatting from 'components/src/styles/formatting';

const Pending = () => {
  const [review, setReview] = useState();
  const [serverSideErrors, setServerSideErrors] = useState('');
  const auth = useContext(AuthContext);
  const { loading, error, data } = useQuery(PENDING_FLOORPLAN_REQUESTS);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  const columns = [
    ...columnDefinitions,
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <>
          {auth.hasPolicy('FloorplanRequest', 'update') && (
            <>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setReview({type: 'Approve', fpr: entry});
                }}
              >
                Approve
              </a>
              {' | '}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setReview({type: 'Deny', fpr: entry});
                }}
              >
                Deny
              </a>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <>
      {review && (
        <Review
          fprId={review.fpr.id}
          reviewType={review.type}
          setShowModal={setReview}
          showModal
          serverSideErrors={serverSideErrors}
          setServerSideErrors={setServerSideErrors}
          fprVin={review.fpr.vin}
          fprMileage={review.fpr.mileage}
        />
      )}

      <Helmet>
        <title>Pending FPRs</title>
      </Helmet>
      <div style={Typography.pageTitle}>Pending FPRs</div>

      <DataTables.Simple
        data={data.floorplanRequests}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Pending;
