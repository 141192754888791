import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';

const AuthLink = ({resource, action, component, exactPolicy, ...props}) => {
  const auth = useContext(AuthContext);
  const Component = component || Link;

  const hasPolicy = exactPolicy ?
    auth.hasExactPolicy(resource, action) :
    auth.hasPolicy(resource, action);

  if (hasPolicy) {
    return <Component {...props} />;
  } else {
    return null;
  }
};

export default AuthLink;
