import React from 'react';
import Form from './Form';
import { Formik } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as yup from 'yup';
import { TXN } from '../Show';
import DataState from '../../DataState';
import { setupPrintCheckHandler } from '../functions';

const SET_CHECK_NUMBER = gql`
  mutation SetCheckNumber(
    $id: ID!
    $reference: String!
  ) {
    setCheckNumber(
      id: $id
      reference: $reference
    ) {
      errors {
        message
        path
      }
    }
  }
`;

const schema = yup.object({
  reference: yup
    .string()
    .required('Required')
})

const Edit = ({ txnId, checkNumberForm, setCheckNumberForm, setShowCheckModal, lastDownloadTime, lastDownloadId, setLastDownloadId, setLastDownloadTime }) => {
  const id = parseInt(txnId)

  const [setCheckNumber, { loading: mutationLoading, error: mutationError }] = useMutation(SET_CHECK_NUMBER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: TXN, variables: { id: id } }],
    onCompleted(values) {
      setCheckNumberForm(false)
    }
  })

  const { loading, error, data } = useQuery(TXN, { variables: { id } })

  if (loading || mutationLoading) return <DataState.Loading />;
  if (error || mutationError) return <DataState.Error error={error || mutationError} />;

  const txn = data.txn

  const handleSubmit = (values) => {
    setCheckNumber({ variables: values })
  }

  let checkNumber = ''

  if (txn.reference) {
    checkNumber = txn.reference
  } else if (txn?.account?.nextCheckNumber) {
    checkNumber = txn.account.nextCheckNumber
  }

  return (
    <Formik
      initialValues={{
        id: id,
        reference: checkNumber.toString() || ''
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form
        handleSubmit={handleSubmit}
        checkNumberForm={checkNumberForm}
        setCheckNumberForm={setCheckNumberForm}
        txn={txn}
        lastDownloadId={lastDownloadId}
        lastDownloadTime={lastDownloadTime}
        setLastDownloadTime={setLastDownloadTime}
        setLastDownloadId={setLastDownloadId}
        setShowCheckModal={setShowCheckModal}
      />
    </Formik>
  )
}

export default Edit;
