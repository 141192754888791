import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as BasicForm from 'components/src/elements/forms';
import { Spacing } from 'components/src/styles';
import * as Buttons from 'components/src/elements/buttons';
import { UPDATE_DEALER } from './Edit';
import moment from 'moment';

const CompleteAnnualReview = ({dealer, closeModal, refetch, setAlertData}) => {
  const [updateDealer] = useMutation(
    UPDATE_DEALER,
    {
      onCompleted: () => {
        refetch();
        setAlertData({ content: 'Annual Review Date updated' });
        closeModal();
      }
    }
  );

  const handleSubmit = values => {
    updateDealer({
      variables: { id: dealer.id, annualReviewOn: values.annualReviewOn }
    });
  };

  let annualReviewOn = moment();
  if (dealer.annualReviewOn) {
    annualReviewOn = moment(dealer.annualReviewOn);
    annualReviewOn.add(1, 'years');
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={closeModal}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Complete Annual Review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            annualReviewOn: annualReviewOn.format('YYYY-MM-DD')
          }}
          onSubmit={handleSubmit}
          onReset={closeModal}
        >
          {({isValid}) => (
            <Form>
              <BasicForm.TextInput
                name="annualReviewOn"
                type="date"
                label="New Annual Review Date"
              />
              <div style={Spacing.buttonRow}>
                <Buttons.ModalCancel
                  type="cancel"
                  text="Cancel"
                  handleClose={closeModal}
                />
                <Buttons.Standard type="submit" text="Submit" />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CompleteAnnualReview;
