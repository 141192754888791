import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import DataState from '../DataState';
import { placeSchema, Form } from './Form';
import { titleCase } from '../../utils/stringHelpers';
import Loading from '../../elements/Loading';
import { DEALER_PLACE, UPDATE_DEALER_PLACE } from './Queries';


const Edit = () => {
  const { id, dealerId } = useParams();
  const { loading, error, data } = useQuery(DEALER_PLACE, { variables: { id } });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const history = useHistory();

  const [updateDealerPlace] = useMutation(UPDATE_DEALER_PLACE,
    {
      onCompleted() {
        setFormSubmitting(false);
        history.push({
          pathname: `/dealers/${dealerId}/places`,
          state: { alert: true, type: 'edited' }
        })
      }
    }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    name,
    type,
    attention,
    street,
    state,
    city,
  } = data.dealerPlace;

  const phone = data.dealerPlace.phone?.formatted || '';
  const fax = data.dealerPlace.fax?.formatted || '';
  const zip = data.dealerPlace.zip.value || '';

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    updateDealerPlace({ variables: values })
  };

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update Location: ${titleCase(name)}`}</h1>
        </div>
        <Formik
          initialValues={{
            id: id,
            name: titleCase(name) || '',
            type: type || '',
            phone: phone || '',
            fax: fax || '',
            attention: titleCase(attention) || '',
            street: titleCase(street) || '',
            city: titleCase(city) || '',
            state: titleCase(state) || '',
            zip: zip || '',
          }}
          validationSchema={placeSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  const formContainer = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2%'
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default Edit;
