import React, { useContext, useState, Fragment, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import DataTable from '../../elements/DataTable';
import DataState from '../DataState';
import * as Buttons from '../../elements/buttons';
import * as Formatting from '../../styles/formatting';
import * as Colors from '../../styles/colors';
import '../Dealers/dealershow.css';
import { DateHelpers, StringHelpers } from '../../utils';
import AuthContext from '../../contexts/AuthContext';
import TxnLinesTable from './Lines/Table';
import TransactionFiles from './FileUploads';
import moment from 'moment';
import {
  sourcePath,
  canPrintReceipt,
  setupPrintReceiptHandler,
  canPrintCheck,
  setupPrintCheckHandler,
  PrintForm,
} from './functions';
import NewVoid from '../Modals/Void/New';
import NewReverse from '../Modals/Reverse/New';
import { useAlert } from '../../hooks/useAlert';
import BasicAlert from '../../elements/BasicAlert';
import { TXN_LINE_FIELDS } from '../../queries/viewQueries';
import { TXNS_WITH_COUNT } from '../../queries/indexQueries';
import SubNav from '../../elements/SubNav'
import { setNavOptions } from '../Cars/SubNavOptions';
import { getDealerSubNavLinks } from '../Dealers/functions'
import Edit from './CheckNumbers/Edit';
import { setAlertData } from '../../utils/setAlertData';
import featureEnabled from 'components/src/utils/featureEnabled';

const useURLQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const TXN = gql`
  query Txn($id: ID!) {
    txn(id: $id) {
      id
      purpose
      amount
      name
      memo
      type
      bankDate
      createdDate
      reference
      paymentTerm { id, name }
      quote { id }
      voidBy { id, bankDate, memo, createdBy { fullName } }
      voidOf { id, bankDate, type, reference, purpose }
      dealers { id, name, dealerStatus { canFloor } }
      flooredCars { id, year, make, model, dealer { id } }
      voidable
      account { gl, name, nextCheckNumber }
      bankAccount { id, accountNumberMasked, owner { id, name } }
      sourceName
      createdAt, updatedAt,
      createdBy { fullName }, updatedBy { fullName }
      txnLines {
        ${TXN_LINE_FIELDS}
      }
      fileUploads { id, name, fileUrl, createdBy { fullName } createdAt }
      reconciles {
        id
        completedAt
      }
      clearedStatus
      voidBy { purpose }
    }
  }
`
const DESTROY_TXN = gql`
  mutation destroyTxn($id: ID!) {
    destroyTxn(
      id: $id
    ) {
      errors { message, path }
    }
  }
`

const txnLinesColumns = featureEnabled('doubleEntry', 'departments') ?
  [
    'earnedOn', 'createdDate', 'market', 'dealer', 'flooredCar', 'fee',
    'purpose', 'gl', 'account', 'department', 'debit', 'credit',
    'earnsInterest', 'memo'
  ] :
  [
    'earnedOn', 'createdDate', 'market', 'dealer', 'flooredCar', 'fee',
    'purpose', 'gl', 'account', 'debit', 'credit', 'earnsInterest', 'memo'
  ];

function Show(props) {
  let query = useURLQuery();
  const { id } = useParams();
  const history = useHistory()
  const [showVoidModal, setShowVoidModal] = useState(false);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const auth = useContext(AuthContext);
  const [lastDownloadTime, setLastDownloadTime] = useState(null);
  const [lastDownloadId, setLastDownloadId] = useState(null);
  const [showCheckModal, setShowCheckModal] = useState(false);
  const [checkNumberForm, setCheckNumberForm] = useState(false);
  const [reversable, setReversable] = useState(false);
  const [showReverseModal, setShowReverseModal] = useState(false)
  const hrStyle = {
    width: '100%',
  }

  const [destroyTxn] = useMutation(DESTROY_TXN,
    {
     awaitRefetchQueries: true,
     refetchQueries: TXNS_WITH_COUNT,
     onCompleted(data) {
       history.push({
         pathname: '/financial/transactions'
       })
     }
  });

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data } = useQuery(TXN, { variables: { id } });

  let currentData = null;

  useEffect(() => {
    if (data?.txn?.reference === null && showAlert) {
      const txn = data.txn
      setCheckNumberForm(true)
    } else {
      setCheckNumberForm(false)
    }

    if (data?.txn?.type === 'Journal Entry' && data?.txn?.purpose === null) {
      setReversable(true)
    }
  }, [data])

  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  let dealerNavLinks;
  let dealerTitle;
  let carNavLinks;
  let carTitle;
  let canFloor;

  const txnLines = data.txn.txnLines;
  const txn = data.txn;

  const reconcileStatus = txn.reconciles.map((rec) => {
    return (
      <Fragment key={rec.id}>
        <a href={'/financial/reconciles/' + rec.id}>Reconciliation {DateHelpers.setDate(rec.completedAt)}</a>
        <br />
      </Fragment>
    );
  });

  if (1 === txn.dealers.length) {
    const dealer = txn.dealers[0];
    canFloor = dealer.dealerStatus.canFloor ? true : false;
    dealerNavLinks = getDealerSubNavLinks(txn.dealers[0].id, auth);

    dealerTitle = {
      link: `/dealers/${dealer.id}`,
      text: dealer.name
    }

    if (
      1 === txn.flooredCars.length &&
      'car' === query.get('referrerType') &&
      dealer.id === txn.flooredCars[0].dealer.id
    ) {
      const { year, make, model } = txn.flooredCars[0];
      const carId = txn.flooredCars[0].id;
      const carName = `${year} ${make} ${model}`;
      [carNavLinks, carTitle] = setNavOptions(carId, dealer.id, carName, auth);
    }
  }

  let source;
  if (txn.bankAccount && txn.bankAccount.owner) {
    const owner = txn.bankAccount.owner
    source = (
      <Fragment>
        <Link to={sourcePath(owner)}>{owner.name}</Link>
        <span> {txn.bankAccount.accountNumberMasked}</span>
      </Fragment>
    );
  } else {
    source = txn.sourceName
  }

  const details = [
    ['Bank Date', DateHelpers.setDate(txn.bankDate)],
    ['Name', txn.name],
    ['Source', source],
    ['Type', txn.type],
    ['Reference', txn.reference],
    ['Purpose', txn.purpose],
    ['Amount', StringHelpers.currency(txn.amount)],
  ];

  if ('Bill' === txn.type && txn.paymentTerm?.name) {
    details.splice(5, 0, ['Payment Terms', txn.paymentTerm.name]);
  }

  const timestamps = [
    ['Created Date',  DateHelpers.setDate(txn.createdDate)],
    ['Created Time',  DateHelpers.setTime(txn.createdAt)],
    ['Created By',  txn.createdBy?.fullName],
  ];

  if (moment(txn.updatedAt) - moment(txn.createdAt) > 10000) {
    timestamps.push(['Last Updated Time', DateHelpers.setTime(txn.updatedAt)]);
    timestamps.push(['Last Updated By', txn.updatedBy?.fullName]);
  }

  const actions = [];
  if (['Void', 'NSF'].includes(txn.purpose)) {
    actions.push([`${txn.purpose} of`, (
      <Link to={`/transactions/${txn.voidOf.id}`}>
        {
          `
            ${txn.voidOf.type}
            ${txn.voidOf.reference || ''}
            ${txn.voidOf.purpose || ''}
          `
        }
      </Link>
    ), 'warning']);

    actions.push([
      'Original Transaction Date', DateHelpers.setDate(txn.voidOf.bankDate)
    ]);
  }

  if (txn.voidBy) {
    if (txn.voidBy.purpose) {
      actions.push(['VOIDED', (
        <Link to={`/transactions/${txn.voidBy.id}`}>
          {DateHelpers.setDate(txn.voidBy.bankDate)}
          {` by ${txn.voidBy.createdBy?.fullName}`}
        </Link>
      ), 'warning']);

      actions.push(['Void Memo', txn.voidBy.memo]);
    } else {
      actions.push(['REVERSED', (
        <Link to={`/transactions/${txn.voidBy.id}`}>
          {DateHelpers.setDate(txn.voidBy.bankDate)}
          {` by ${txn.voidBy.createdBy?.fullName}`}
        </Link>
      ), 'warning']);
    }
  }

  const printCheck = () => {
    setCheckNumberForm(true)
    setupPrintCheckHandler(
      txn,
      lastDownloadId, lastDownloadTime,
      setLastDownloadId, setLastDownloadTime, setShowCheckModal
    )
  }

  const actionLinks = (
    <>
      {
        auth.hasPolicy('Txn', 'update') && txn.voidable &&
          <Buttons.Link
            text="Void"
            onClick={() => setShowVoidModal(true)}
          />
      }

      {
        featureEnabled('doubleEntry', 'operationsTxns') &&
        auth.hasPolicy('Txn', 'update') && reversable && txn.voidable &&
          <Buttons.Link
            text="Reverse"
            onClick={() => setShowReverseModal(true)}
          />
      }

      {
        featureEnabled('doubleEntry', 'operationsTxns') &&
        auth.hasPolicy('Txn', 'update') &&
          <a href={`/transactions/${txn.id}/edit`}>Edit</a>
      }

      {
        showReverseModal &&
          <NewReverse
            txn={txn}
            showModal={showReverseModal}
            setShowModal={setShowReverseModal}
            displayAlert={displayAlert}
          />
      }

      {
        showVoidModal &&
          <NewVoid
            txn={txn}
            showModal={showVoidModal}
            setShowModal={setShowVoidModal}
            displayAlert={displayAlert}
          />
      }

      {
        canPrintReceipt(txn, auth) &&
          <a
            href=""
            className={`receipt-print${txn.quote.id}`}
            id={txn.quote.id}
            name={`print-receipt${txn.quote.id}`}
          >
            Print Receipt
          </a>
      }

      {
        featureEnabled('doubleEntry', 'checkPrinting') &&
        canPrintCheck(txn, auth) &&
          <a
            href="#"
            onClick={() => printCheck()}
            id={`check-print${id}`}
            data-id={id}
          >
            Print Check
          </a>
      }
      {
        showCheckModal &&
          <PrintForm
            showCheckModal={showCheckModal}
            setShowCheckModal={setShowCheckModal}
          />
      }
      {
        checkNumberForm &&
          <Edit
            checkNumberForm={checkNumberForm}
            setCheckNumberForm={setCheckNumberForm}
            txnId={txn.id}
            setShowCheckModal={setShowCheckModal}
            lastDownloadTime={lastDownloadTime}
            setLastDownloadTime={setLastDownloadTime}
            lastDownloadId={lastDownloadId}
            setLastDownloadId={setLastDownloadId}
          />
      }
    </>
  )

  if (canPrintCheck(txn, auth)) {
    setupPrintCheckHandler(
      txn,
      lastDownloadId, lastDownloadTime,
      setLastDownloadId, setLastDownloadTime, setShowCheckModal
    )
  }

  if (canPrintReceipt(txn, auth)) {
    setupPrintReceiptHandler(
      txn,
      lastDownloadTime, setLastDownloadTime,
      lastDownloadId, setLastDownloadId
    );
  }

  actions.push(['Actions', actionLinks, 'actions']);

  const isToday = DateHelpers.isToday(txn.createdDate);

  return (
    <Fragment>
      {dealerNavLinks &&
        <SubNav
          title={dealerTitle} navLinks={dealerNavLinks} canFloor={canFloor}
        />
      }
      {carNavLinks &&
        <SubNav
          title={carTitle}
          navLinks={carNavLinks}
          style={{ backgroundColor: Colors.xlYellow }}
        />
      }
      {showAlert &&
        <BasicAlert componentType="transaction" type={alertType} />
      }
      <Container style={Formatting.infoContainer}>
        <Row>
          <div className="col-xl-3 info-section">
            {details.map((detail, index) => {
              return (
                <Row className="info-row" key={index}>
                  <Col>{detail[0]}:</Col>
                  <Col align="right">{detail[1]}</Col>
                </Row>
              );
            })}
          </div>
          <div className="col-xl-3 info-section">
            {timestamps.map((detail, index) => {
              return (
                <Row className="info-row" key={index}>
                  <Col>{detail[0]}:</Col>
                  <Col align="right">{detail[1]}</Col>
                </Row>
              );
            })}
            <Row className="info-row">
              <Col>Note:</Col>
              <Col align="right">{txn.memo}</Col>
            </Row>

            {
              featureEnabled('doubleEntry', 'reconcile') &&
                <Row className="info-row">
                  <Col>{txn.clearedStatus}</Col>
                  <Col align="right">{reconcileStatus}</Col>
                </Row>
            }
          </div>
          <div className="col-xl-3 info-section">
            {actions.map((detail, index) => {
              return (
                <Row className={`info-row ${detail[2] || ''}`} key={index}>
                  <Col>{detail[0]}:</Col>
                  <Col align="right">{detail[1]}</Col>
                </Row>
              );
            })}
            <div style={{textAlign: 'center', marginTop: '20px'}}>
            { isToday && 'true' === process.env.REACT_APP_ALLOW_DELETE_TXN &&
              <Button
                variant="danger"
                onClick={() => destroyTxn({variables: { id: txn.id } })}
              >
                Delete
              </Button>
            }
            </div>
          </div>
        </Row>
      </Container>
      {
        featureEnabled('doubleEntry', 'checkPrinting') &&
          <>
            <hr style={hrStyle} />
            <TransactionFiles data={data} />
          </>
      }
      <hr style={hrStyle} />
      <TxnLinesTable txnLines={txnLines} shownColumns={txnLinesColumns} />
    </Fragment>
  );
}

export default Show;
