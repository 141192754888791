import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { FLOORED_CAR } from '../../../queries/viewQueries';
import { Spacing } from '../../../styles';
import { Formik } from 'formik';
import { Form, changeSourceSchema } from './Form';

const UPDATE_FLOORED_CAR = gql`
  mutation UpdateFlooredCar(
    $id: ID!,
    $vin: String!,
    $color: String!,
    $mileage: Int!,
    $sourceId: Int,
    $sourceType: String,
    $bankAccountId: Int
  ) {
    updateFlooredCar(
      id: $id
      vin: $vin
      color: $color
      mileage: $mileage
      sourceId: $sourceId
      sourceType: $sourceType
      bankAccountId: $bankAccountId
    ) {
      flooredCar {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const ChangeSourceModal = (props) => {
  let {
    show,
    handleClose,
    loading,
    vin,
    color,
    mileage,
    sourceType,
    sourceId,
    bankAccountId
  } = props;

  const id = parseInt(useParams().carId);

  const [updateFlooredCar] = useMutation(UPDATE_FLOORED_CAR, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FLOORED_CAR, variables: { id } }],
    onCompleted(variables) {
      handleClose();
    }
  });

  const handleSubmit = (values) => {
    if (values.sourceId) values.sourceId = parseInt(values.sourceId);
    updateFlooredCar({ variables: values })
  }

  const ModalBody = () => {
    return (
      <div style={Spacing.formWrapper}>
        <Formik
          initialValues={{
            id,
            vin,
            color,
            mileage,
            sourceType: "",
            sourceId: "",
            bankAccountId: ""
          }}
          validationSchema={changeSourceSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Change Source
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(loading) ? <p>Loading...</p> : <ModalBody />}
      </Modal.Body>
    </Modal>
  )
}

export default ChangeSourceModal;
