import React, { useContext, useState, useEffect } from 'react';
import { View } from 'react-native';
import DataTable from '@preflighttech/preflight-tables';
import AuthContext from 'components/src/contexts/AuthContext';
import DataState from 'components/src/components/DataState';
import {
  SCHEDULED_ACHS,
  CANCEL_ACH, CANCEL_DEALER_TXN, CANCEL_PRINCIPAL_ADJUSTMENT,
  UPDATE_ACH_HOLDS
} from './queries';
import { useQuery, useMutation } from '@apollo/client';
import { currency } from 'components/src/utils/stringHelpers';
import { setDate } from 'components/src/utils/dateHelpers';
import { Standard } from 'components/src/elements/buttons';
import * as Formatting from 'components/src/styles/formatting';
import { preflightTableStyles } from 'components/src/styles';
import 'components/src/styles/datatables.css';

const Actions = ({entry, direction, auth, cancelHandler}) => {
  const type = entry.__typename;
  const cancelAction = 'Quote' === type ? 'update' : 'destroy';

  return (
    <div className="datatables-actions">
      {
        'Quote' === type && auth.hasPolicy('Quote', 'read') &&
          <a href={`/financial/scheduled-achs/${entry.id}`}>View</a>
      }
      {
        auth.hasPolicy(type, cancelAction) && 'FlooredCar' !== type &&
          <a onClick={() => cancelHandler(entry)}>Cancel</a>
      }
    </div>
  );
}

const columnDefinitions = props => {
  const { auth, direction, holdValue, setHoldValue } = props;

  const definitions = [
    {
      key: 'paymentTo',
      label: 'Payment To',
      value: ({entry}) => entry.bankAccount.owner.name
    },
    {
      key: 'vin',
      label: 'VIN',
      minViewportWidth: 1000,
    },
    {
      key: 'bankDate',
      label: 'Bank Date',
      width: 120,
      minViewportWidth: 800,
      value: ({value}) => setDate(value)
    },
    {
      key: 'dealer',
      label: 'Dealer',
      sort: 'asc',
      value: ({value}) => value.name
    },
    {
      key: 'achAmount',
      label: 'Amount',
      width: 160,
      value: ({value}) => currency(value)
    },
    {
      key: 'txnPurpose',
      label: 'Purpose',
      minViewportWidth: 1400,
    },
    {
      key: 'createdBy',
      label: 'Setup By',
      minViewportWidth: 1400,
      value: ({value}) => value.fullName
    },
    {
      key: 'onHold',
      label: 'Hold',
      content: ({entry}) => {
        const checked = holdValue(entry);

        return (
          <div style={{flexDirection: 'row'}}>
            {
              auth.hasPolicy('AchBatch', 'update') &&
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setHoldValue(entry, !checked)}
                />
            }
            <span
              style={{backgroundColor: entry.onHold ? 'yellow' : 'transparent'}}
            >
              {entry.onHold ? ' Held' : ' Not held'}
              {entry.bankAccount.owner.xlgAuction ? ' (XLG)' : ''}
            </span>
          </div>
        )
      }
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({entry}) => (
        <Actions entry={entry} direction={direction} {...props} />
      )
    },
  ];

  if ('In' === direction) {
    return [...definitions.slice(2, -2), definitions[definitions.length - 1]];
  } else {
    return definitions;
  }
}

const sameResource = (first, second) => {
  return (first.__typename === second.__typename && first.id === second.id);
};

const HoldingButton = props => {
  return (
    <Standard
      {...props}
      topRowButtons
      buttonStyle={{marginTop: 0, display: 'inline'}}
    />
  );
};

const Achs = ({ direction }) => {
  const auth = useContext(AuthContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [holdValues, setHoldValues] = useState(undefined);

  const { loading, error, data, refetch } = useQuery(
    SCHEDULED_ACHS,
    { variables: { direction: direction, onHold: 'Out' === direction } }
  );

  const mutationOptions = { onCompleted: refetch };

  const [cancelAch] = useMutation(CANCEL_ACH, mutationOptions);
  const [destroyDealerTxn] = useMutation(CANCEL_DEALER_TXN, mutationOptions);
  const [destroyPrincipalAdjustment] =
    useMutation(CANCEL_PRINCIPAL_ADJUSTMENT, mutationOptions);

  const [updateAchHolds] = useMutation(
    UPDATE_ACH_HOLDS,
    {
      onCompleted: () => {
        refetch();
        alert('Holds updated');
        setIsSubmitting(false);
      }
    }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  if (!holdValues) {
    setHoldValues(data.scheduledAchs.map(ach => [ach, ach.onHold]));
  }

  const holdValue = ach => {
    return holdValues.find(([holdAch]) => sameResource(holdAch, ach))[1];
  };

  const setHoldValue = (ach, value) => {
    holdValues.find(([holdAch]) => sameResource(holdAch, ach))[1] = value;
    setHoldValues([...holdValues]);
  };

  const checkAllXlg = () => {
    holdValues.forEach(holdData => {
      if (holdData[0].bankAccount.owner.xlgAuction) {
        holdData[1] = true;
      }
    });

    setHoldValues([...holdValues]);
  };

  const uncheckAll = () => {
    holdValues.forEach(holdData => holdData[1] = false);
    setHoldValues([...holdValues]);
  };

  const submitHoldUpdates = () => {
    setIsSubmitting(true);

    const achs = holdValues.map(([ach, onHold]) => {
      return { type: ach.__typename, id: ach.id, onHold };
    });

    updateAchHolds({variables: { achs }});
  };

  const amountReducer = (sum, ach) => sum + parseFloat(ach.achAmount);
  const totalAmount = data.scheduledAchs.reduce(amountReducer, 0.0);

  const held = data.scheduledAchs.filter(ach => ach.onHold);
  const heldAmount = held.reduce(amountReducer, 0.0);
  const heldCount = held.length;

  const cancelHandler = entry => {
    if (window.confirm('Are you sure you want to cancel this ACH?')) {
      const type = entry.__typename;
      let mutation;

      if ('DealerTxn' === type) {
        mutation = destroyDealerTxn;
      } else if ('PrincipalAdjustment' === type) {
        mutation = destroyPrincipalAdjustment;
      } else if ('Quote' === type) {
        mutation = cancelAch;
      }

      mutation({ variables: { id: entry.id } });
    }
  };

  const label = direction === 'Out' ? 'Outgoing' : 'Incoming';

  const HoldNumber = () => {
    if ('Out' === direction) {
      return (
        <>
          <br/>
          <br/>
          On Hold:
          <span style={Formatting.infoBarImportantText}>
            {heldCount}
          </span>
          <span style={Formatting.infoBarImportantText}>
            {currency(heldAmount)}
          </span>
        </>
      )
    } else {
      return null;
    }
  }

  const columns = columnDefinitions(
    {direction, auth, cancelHandler, holdValue, setHoldValue}
  );

  return (
    <>
      {
        <div style={Formatting.infoBar}>
          {`Pending ${label} Subtotal:`}
          <span style={Formatting.infoBarImportantText}>
            {currency(totalAmount)}
          </span>
          <HoldNumber />
        </div>
      }
      {
        'Out' === direction && auth.hasPolicy('AchBatch', 'update') &&
          <div style={{marginTop: '0.5em', textAlign: 'right'}}>
            <strong>Holding</strong>{': '}
            <HoldingButton text="Check All XLG" onClick={checkAllXlg} />
            <HoldingButton text="Uncheck All" onClick={uncheckAll} />
            <HoldingButton
              text={isSubmitting ? 'Submitting...' : 'Submit Hold Updates'}
              onClick={submitHoldUpdates}
            />
          </div>
      }
      <DataTable.Simple
        data={data.scheduledAchs}
        columns={columns}
        pageLength={'All'}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        styles={preflightTableStyles}
      />
    </>
  )
};

export default Achs;
