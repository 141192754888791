import React from 'react';
import { Typography } from '../../../styles';
import DealerInfo from '../DealerInfo';

const index = () => {
  return (
    <>
      <DealerInfo />
      <div style={Typography.pageTitle}>Files</div>
    </>
  );
};

export default index;
