import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import DataState from '../../../DataState';
import { Helmet } from 'react-helmet';
import { Redirect, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Field } from 'formik';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '../../../../elements/ErrorMessage';
import { classNames } from '../functionality';
import * as yup from 'yup';
import { Typography, Spacing } from '../../../../styles';
import { Colors } from '../../../../styles';
import '../../../../styles/ratePrograms.css';

const UPDATE_RATE_PROGRAM_CHANGE = gql`
  mutation UpdateRateProgramChange($id: ID!, $rate: Float!, $effectiveOn: ISO8601Date!) {
      updateRateProgramChange(
        id: $id
        effectiveOn: $effectiveOn
        rate: $rate
      ) {
        rateProgramChange { id, rate, effectiveOn }
        errors { message, path }
       }
      }
     `
const RATE_PROGRAM_CHANGE = gql`
  query RATE_PROGRAM_CHANGE($id: ID!) {
    rateProgramChange(id: $id) {
      id
      rate
      effectiveOn
    }
  }
`

const UpdateRateProgramChange = () => {
  const rateProgramId = parseInt(useParams().id)
  const rateProgramChangeId = parseInt(useParams().changeId)

  const [redirect, setRedirect] = useState(false);
  const [updateRateProgramChange] = useMutation(UPDATE_RATE_PROGRAM_CHANGE,
    {
      onCompleted() {
        setRedirect(true);
      }
    }
  );

  const { loading, error, data } = useQuery(RATE_PROGRAM_CHANGE, { variables: { id: rateProgramChangeId} })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { rateProgramChange } = data;

  const today = new Date()

  const schema = yup.object({
    rate: yup
      .number()
      .min(0, 'Must be positive')
      .required('Required'),
    effectiveOn: yup
      .date()
      .min(today, 'The date must be in the future')
      .required('Required')
  });

  const initialValues = {
    id: rateProgramChange.id,
    rate: rateProgramChange.rate || '',
    effectiveOn: rateProgramChange.effectiveOn || ''
  }

  function RateProgramChangeForm() {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/system/rate-programs/${rateProgramId}`,
          state: { alert: true}
        }}
      />
    }

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isInvalid,
        errors,
        setValues
      }) => (
        <Form noValidate onSubmit={handleSubmit} style={{paddingRight: 40, paddingLeft: 40}}>
          <div style={Spacing.formWrapper}>
            <div style={Spacing.formHeader}>
              <h1 style={Typography.formHeader}>Update Rate Program Change</h1>
            </div>
          </div>
            <Form.Label>Rate</Form.Label>
            <Form.Group className='term-line-errors'>
              <Field className={classNames(errors, touched, 'rate')} type="number" name="rate" />
              <ErrorMessage name={"rate"} />
            </Form.Group>
            <Form.Label>Effective On</Form.Label>
            <Form.Group className='term-line-errors'>
              <Field className={classNames(errors, touched, 'effectiveOn')} type="date" name="effectiveOn" />
              <ErrorMessage name={"effectiveOn"} />
            </Form.Group>
             <Button
              type="submit"
              style={buttonColor}
            >
              Update Rate Program Change
            </Button>
          </Form>
        )}
      </Formik>
    )
  }

  const handleSubmit = (values) => {
    updateRateProgramChange({
      variables: values
    })
  }

  const buttonColor = {
     backgroundColor: Colors.xlGreen,
     borderColor: Colors.xlGreen,
     color: 'white'
  }

  return (
    <>
    <Helmet>
      <title>Update Rate Program Change</title>
    </Helmet>
    <RateProgramChangeForm />
    </>
  );
};

export default UpdateRateProgramChange;
