import React, { useEffect, useState } from 'react';
import { gql, useQuery } from "@apollo/client";
import DataState from "../../../DataState";
import { ErrorMessage } from '../../../../elements/ErrorMessage';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../../elements/forms';
import { inputStyle } from './AdvanceType';

const ACCOUNTS = gql`
  query {
    accounts(scopes: ["bank"]) {
      id
      name
      gl
    }
  }
`;

const AccountSelect = () => {
  const { setFieldValue, errors, handleBlur, touched, values, values: { txnType }} = useFormikContext();
  const { loading, error, data } = useQuery(ACCOUNTS);

  if (loading || !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  return <div>
    <BasicForm.Select
      name="accountId"
      label="Payment Made from Bank Account"
      style={{width: '30%', marginBottom: '8px'}}
    >
    <option value="">Select an Account</option>
      { setOptionValues(accounts) }
    </BasicForm.Select>
  </div>
}

const setOptionValues = (accounts) => {
  return accounts.map((account) => {
    const name = `GL${account.gl} - ${account.name}`
    const id = parseInt(account.id);

    return <option value={id}>{name}</option>
  })
}


export default AccountSelect;
