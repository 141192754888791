import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';
import { COLLATERAL_COVERAGES } from '../../System/CollateralCoverages/queries';

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

export const collateralCoverageSchema = yup.object({
  effectiveOn: yup
    .date()
    .required('Required'),
  insuranceProgramId: yup
    .string()
    .when('thirdParty', {
      is: false,
      then: yup
        .string()
        .required('Required'),
    }),
  expiresOn: yup
    .date()
    .min(today, 'The date must be in the future')
    .when('thirdParty', {
      is: true,
      then: yup
        .date()
        .required('Required'),
    })
});

const checkThirdParty = (thirdParty) => {
  return thirdParty === true
}

export const Form = () => {
  const isInitialMount = useRef(true);
  const { values, setFieldValue, isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setFieldValue('insuranceProgramId', '');
    }

  }, [values.thirdParty])

  const { loading, error, data } = useQuery(COLLATERAL_COVERAGES);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { insurancePrograms } = data;

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="insuranceCompany"
        type="text"
        label="Insurance Company"
      />
      <BasicForm.Checkbox
        label="Third Party"
        name="thirdParty"
      />
      <BasicForm.ProgrammaticSelect
        label='Insurance Program'
        name='insuranceProgramId'
        options={insurancePrograms}
        firstOption={!checkThirdParty(values.thirdParty)
          && "Select an Insurance Program" || ' '
        }
        disabled={checkThirdParty(values.thirdParty)}
      />
      <BasicForm.TextInput
        type="date"
        name="effectiveOn"
        label="Effective Date"
      />
      <BasicForm.TextInput
        type="date"
        name="expiresOn"
        label="Expiration Date"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
