import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import DataState from '../../DataState';
import { marketSchema, Form } from './Form';
import { titleCase } from '../../../utils/stringHelpers';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'

const MARKET = gql`
  query MARKET($id: ID!) {
    market(id: $id) {
      id
      name
      timezone
    }
  }
`;

const UPDATE_MARKET = gql`
    mutation UpdateMarket($id: ID!, $name: String, $timezone: String) {
      updateMarket(
        id: $id
        name: $name
        timezone: $timezone
      ) {
        market {
          id
          name
          timezone
        }
        errors {
          message
          path
        }
      }
    }
`;

const Edit = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(MARKET, { variables: { id } });
  const [showAlert, displayAlert] = useAlert();

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_MARKET,
    onCompleteRedirectTo: '/system/markets'
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { name, timezone } = data.market

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Edit Market: ${titleCase(name)}`}</h1>
        </div>
        <ServerSideErrors />
        <Formik
          initialValues={{
            id: id,
            name: name || '',
            timezone: timezone || '',
          }}
          validationSchema={marketSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formContainer}>
          <EditForm />
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default Edit;
