import React, { useState, useEffect, useRef, Fragment } from "react";
import { Text } from "react-native";
import { Helmet } from "react-helmet";
import { useParams, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { gql } from "@apollo/client";
import { Formik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { Form } from "./Form";
import { Spacing, Typography } from "../../styles";
import { formatValues } from "./Functions";
import NewPayment from "./ProcessWriteOff/Modal";
import DataState from "../DataState";

export const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
    }
  }
`;

const CREATE_QUOTE = gql`
  mutation CreateQuote(
    $dealerId: Int!
    $paymentDate: ISO8601Date!
    $quoteCarsAttributes: [QuoteCarInput!]
    $quoteFeesAttributes: [QuoteFeeInput!]
  ) {
    createQuote(
      dealerId: $dealerId
      paymentDate: $paymentDate
      quoteCarsAttributes: $quoteCarsAttributes
      quoteFeesAttributes: $quoteFeesAttributes
    ) {
      quote {
        id
        quoteFees {
          fee {
            id
          }
          amount
        }
        quoteCars {
          id
          flooredCar {
            id
          }
          principal
          interest
          insurance
          quoteFees {
            fee {
              id
            }
            amount
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

const NewQuote = ({ data }) => {
  const dealerId = parseInt(useParams().id);
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [quoteId, setQuoteId] = useState(null);
  const [carsFromWriteoff, setCarsFromWriteoff] = useState([]);
  const { loading, error, data: dealerData } = useQuery(DEALER, {
    variables: { id: dealerId },
  });
  let persistedQuoteValues = useRef({});

  let orderedData = useRef(data);
  const setOrderedData = (orderedData) => (orderedData.current = orderedData);

  const [
    createQuote,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_QUOTE, {
    onCompleted(values) {
      setCarsFromWriteoff(values.createQuote.quote.quoteCars);
      setQuoteId(parseInt(values.createQuote.quote.id));
      setShowModal(true);
    },
  });

  let today = new Date();
  today = today.toISOString().split("T")[0];

  if (loading || mutationLoading) return <DataState.Loading />;
  if (error || mutationError)
    return <DataState.Error error={error || mutationError} />;

  const {
    dealer: { name },
  } = dealerData;

  const handleSubmit = (values) => {
    persistedQuoteValues.current = values;

    delete values.maxPrincipal;

    formatValues(values.quoteCarsAttributes, [
      "principal",
      "interest",
      "insurance",
      "paymentType",
      "feeDetails",
    ]);

    createQuote({ variables: values });
  };

  const NewForm = () => {
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/system/dealer-statuses",
            state: { alert: true, type: "created" },
          }}
        />
      );
    }

    const {
      paymentDate,
      quoteCarsAttributes,
      quoteFeesAttributes,
    } = persistedQuoteValues.current;

    return (
      <Fragment>
        <div style={Object.assign({}, Spacing.formWrapper, { marginTop: "0" })}>
          <Formik
            initialValues={{
              dealerId,
              paymentDate: paymentDate || today,
              quoteCarsAttributes: quoteCarsAttributes || [],
              quoteFeesAttributes: quoteFeesAttributes || [],
              maxPrincipal: [],
            }}
            validationSchema={null}
            onSubmit={handleSubmit}
          >
            <Form
              data={orderedData.current}
              setOrderedData={setOrderedData}
            />
          </Formik>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Helmet>
        <title>New Dealer Quote</title>
      </Helmet>
      <div style={Spacing.formContainer}>
        {showModal && (
          <NewPayment
            showModal={showModal}
            setShowModal={setShowModal}
            dealerName={name}
            quoteId={quoteId}
            carsFromWriteoff={carsFromWriteoff}
          />
        )}
        <NewForm />
      </div>
    </Fragment>
  );
};

export default NewQuote;
