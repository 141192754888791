import React, { useState, Fragment } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import { fiscalMonthSchema, Form } from './Form';
import Loading from '../../../elements/Loading';
import { CREATE_FISCAL_MONTH, FISCAL_MONTHS } from './queries';
import { getServerSideErrors } from '../../../utils/errorHelpers';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors';
import * as Formatting from '../../../styles/formatting';

let submittedValues = {};
const New = () => {
  const [showAlert, displayAlert] = useAlert();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const history = useHistory();

  const [createFiscalMonth] = useMutation(CREATE_FISCAL_MONTH,
    {
      awaitRefetchQueries: true,
      refetchQueries: [ { query: FISCAL_MONTHS } ],
      onCompleted(values) {
        setFormSubmitting(false);
        const serverErrors = getServerSideErrors(values);
        if (serverErrors) {
          setServerSideErrors(serverErrors);
        } else {
          history.push({
            pathname: `/financial/fiscal-month-calendar`,
            state: { alert: true, type: 'created' },
          })
        }
      }
    }
  );

  const handleSubmit = (values) => {
    submittedValues = values;
    submittedValues.year = parseInt(values.year);
    submittedValues.period = parseInt(values.period);
    setFormSubmitting(true);
    createFiscalMonth({ variables: values })
  };

  const NewForm = () => {
    return (
      <Fragment>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Fiscal Month</h1>
          </div>
          <Formik
            initialValues={{
              year: parseInt(submittedValues.year) || '',
              period: parseInt(submittedValues.period) || '',
              startOn: submittedValues.startOn || '',
              endOn: submittedValues.endOn || ''
            }}
            validationSchema={fiscalMonthSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </Fragment>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    );
  };

  return (
    <Fragment>
      { serverSideErrors &&
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      }
      <div style={Spacing.formContainer}>
        <DisplayContent />
      </div>
    </Fragment>
  );
};

export default New;
