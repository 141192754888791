import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import DataTable from '../../elements/DataTable';
import AuthContext from '../../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import BasicAlert from '../../elements/BasicAlert';
import { titleCase } from '../../utils/stringHelpers';
import { setAlertData } from '../../utils/setAlertData';
import { useAlert } from '../../hooks/useAlert';
import DataState from '../DataState';
import { useHideableResource } from '../../hooks/useHideableResource';

export const VENDORS = gql`
  query {
    vendors {
      id
      name
      city
      state
      zip { formatted }
      phone { formatted }
      street
      hiddenAt, createdAt, updatedAt
      paymentTerm { name, days }
      primaryContact {
        id
        fullName
        officePhone {
          value
          formatted
        }
      }
    }
  }
`;

export const HIDDEN_VENDORS = gql`
  query {
    vendors(scopes: ["hidden"]) {
      id
      name
      city
      state
      zip { formatted }
      phone { formatted }
      street
      hiddenAt, createdAt, updatedAt
      paymentTerm { name, days }
      primaryContact {
        id
        fullName
        officePhone {
          value
          formatted
        }
      }
    }
  }
`;

const HIDE_VENDOR = gql`
  mutation HideVendor($id: ID!) {
    hideVendor(
      id: $id
    ) {
      vendor {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const UNHIDE_VENDOR = gql`
  mutation UnhideVendor($id: ID!) {
    unhideVendor(
      id: $id
    ) {
      vendor {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const Vendors: () => React$Node = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_VENDORS,
    VISIBLE_QUERY: VENDORS,
    HIDE_MUTATION: HIDE_VENDOR,
    UNHIDE_MUTATION: UNHIDE_VENDOR,
    variables: {},
  };

  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)

  const auth = useContext(AuthContext);

  const columns = [
    {
      title: 'Name'
    },
    {
      title: 'Street'
    },
    {
      title: 'City'
    },
    {
      title: 'State'
    },
    {
      title: 'Zip Code'
    },
    {
      title: 'Primary Contact'
    },
    {
      title: 'Contact Phone #'
    },
    {
      title: 'Payment Term'
    },
    {
      title: 'Actions'
    },
  ]

  useEffect(() => setAlertData(props, setAlertType, displayAlert), []);

  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const dataSet = data.vendors.map(function (vendor) {
    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${vendor.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${vendor.id}>Hide</a>`

    const actions = []

    if (auth.hasPolicy('Vendor', 'update')) {
      actions.push(`<a href="/vendors/${vendor.id}/edit"/>Edit</a>`)
    }

    if (auth.hasPolicy('Vendor', 'read')) {
      actions.push(`<a href="/vendors/${vendor.id}"/>View</a>`)
    }

    if (auth.hasPolicy('Vendor', 'hide')) {
      actions.push(hiddenLink)
    }

    if (actions === []) {
      actions.push(' ')
    }

    let name;

    if (auth.hasPolicy('Vendor', 'read')) {
      name = `<a href="/vendors/${vendor.id}"/>${vendor.name}</a>`
    } else {
      name = vendor.name
    }

    const phone = vendor.phone?.formatted || '';
    const zip = vendor.zip?.formatted || '';
    const primaryContact = vendor.primaryContact?.fullName || '';

    return [
      name,
      vendor.street,
      vendor.city,
      vendor.state,
      zip,
      primaryContact,
      phone,
      vendor.paymentTerm?.name || '',
      actions.join(" | ")
    ]

  })

  return (
    <>
      <Helmet>
        <title>Vendors</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="vendor" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Vendors'}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
    </>
  );
};

export default Vendors;
