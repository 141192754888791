import React from 'react';
import { Row, Col } from 'react-bootstrap';

const TableHeaders = ({ columnStyle }) => {
  return <Row>
    <Col style={columnStyle.smallDiv}>For Principal Value Below*</Col>
    <Col style={columnStyle.smallDiv}>Curtailment % at the end of the Term</Col>
  </Row>
}

export default TableHeaders;
