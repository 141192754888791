import React from 'react';
import { useParams } from 'react-router-dom';
import Info from '../Info';
import Notes from '../../Notes';
import { APPROVED_SOURCE_NOTES } from '../queries';

const ApprovedSourceNotes = () => {
  const id = parseInt(useParams().id);

  return (
    <>
      <Info />
      <Notes
        query={APPROVED_SOURCE_NOTES}
        ownerType="approvedSource"
        ownerId={id}
      />
    </>
  );
};

export default ApprovedSourceNotes;
