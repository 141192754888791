import React, { useContext, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import DataTable from "../../../elements/DataTable";
import DataState from "../../DataState";
import AuthContext from "../../../contexts/AuthContext";
import BasicAlert from "../../../elements/BasicAlert";
import { useAlert } from "../../../hooks/useAlert";
import { useHideableResource } from "../../../hooks/useHideableResource";
import { setUniqueAlertData } from "../../../utils/setAlertData";
import Info from "../Info";
import {
  VENDOR_CONTACTS,
  HIDDEN_VENDOR_CONTACTS,
} from "../../../queries/indexQueries";
import { gql } from "@apollo/client";

const HIDE_CONTACT = gql`
  mutation HideContact($id: ID!) {
    hideContact(id: $id) {
      contact {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const UNHIDE_CONTACT = gql`
  mutation UnhideContact($id: ID!) {
    unhideContact(id: $id) {
      contact {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const MAKE_PRIMARY_CONTACT = gql`
  mutation MakePrimaryContact($id: ID!) {
    makePrimaryContact(id: $id) {
      contact {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const Contacts = (props) => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();
  const id = parseInt(useParams().id);
  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_VENDOR_CONTACTS,
    VISIBLE_QUERY: VENDOR_CONTACTS,
    HIDE_MUTATION: HIDE_CONTACT,
    UNHIDE_MUTATION: UNHIDE_CONTACT,
    variables: { id },
  };

  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(
    hiddenProps
  );
  const [makePrimary] = useMutation(MAKE_PRIMARY_CONTACT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: VENDOR_CONTACTS,
        variables: { id },
      },
    ],
  });

  const auth = useContext(AuthContext);

  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const { vendor } = data;

  const columns = [
    {
      title: "First Name",
      visible: true,
    },
    {
      title: "Last Name",
      visible: true,
    },
    {
      title: "Mobile Phone",
      visible: true,
    },
    {
      title: "Office Phone",
      visible: true,
    },
    {
      title: "Email",
      visible: true,
    },
    {
      title: "Primary",
      visible: true,
    },
    {
      title: "Title",
      visible: true,
    },
    {
      title: "Actions",
      visible: true,
    },
  ];

  const dataSet = vendor.contacts.map((contact) => {
    const hiddenLink = showHidden
      ? `<a href='' id="unhide" data-id=${contact.id}>Unhide</a>`
      : `<a href='' id="hide" data-id=${contact.id}>Hide</a>`;

    const actions = []
    if (auth.hasPolicy('Contact', 'manage')) {
      actions.push(`<a href="/vendors/${vendor.id}/contacts/${contact.id}/edit"/>Edit</a>`)
      contact.primary ? null : actions.push(`<a href='' id="make-primary" data-id=${contact.id}>Make Primary</a>`)
    }
    if (auth.hasPolicy('Contact', 'hide')) {
      actions.push(hiddenLink)
    }

    if (actions === []) {
      actions.push(' ')
    }

    $(document).on("click", "#make-primary", function (event) {
      let id = event.target.dataset.id;
      id = parseInt(id, 10);
      makePrimary({ variables: { id: id } });
    });

    const formattedOfficePhone = contact.officePhone?.formatted || "";
    const formattedCellPhone = contact.mobilePhone?.formatted || "";
    const formattedEmail = `<a href="mailto:${contact.email}">${contact.email
      }</a>`;
    const primary = contact.primary ? "Yes" : "";

    return [
      contact.firstName,
      contact.lastName,
      formattedCellPhone,
      formattedOfficePhone,
      formattedEmail,
      primary,
      contact.title,
      actions.join(' | ')
    ];
  });

  const newRoute = `/vendors/${id}/contacts/new`;

  return (
    <>
      <Info />
      {showAlert && (
        <BasicAlert componentType="vendor contact" type={alertType} />
      )}
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={"Contacts"}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
        newRoute={newRoute}
      />
    </>
  );
};

export default Contacts;
