import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { SubmitForm } from './SubmitForm';
import { useFormikContext } from 'formik';

let prevAction = ''
function usePreviousAction(value) {
  const ref = useRef(value);
  useEffect(() => {
    prevAction = value;
  });
}

let prevFeeType = ''
function usePreviousFeeType(value) {
  const ref = useRef(value);
  useEffect(() => {
    prevFeeType = value;
  });
}

export const resetValues = () => {
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    if ((prevAction !== '') && (prevAction !== values.action)) {
      setFieldValue('flagAmount', '');
      setFieldValue('feeAmount', 0);
      setFieldValue('option', '');
    }
  }, [values.action])

  usePreviousAction(values.action)
}

export const resetFeeType = () => {
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
      if ((prevFeeType !== '') && (prevFeeType !== values.feeType)) {
        setFieldValue('flagAmount', '');
        setFieldValue('feeAmount', 0);
        setFieldValue('option', '');
        setFieldValue('action', '')
      }
    }, [values.feeType])

    usePreviousFeeType(values.feeType)
  }

 export const renderOrdinalSuffix = (ordinalValue, setOrdinalValue, flagAmount) => {
   const j = flagAmount % 10;
   const k = flagAmount % 100;

   if (flagAmount === '') {
     setOrdinalValue('st/nd/rd/th')
     return
   }

   if (j === 1 && k != 11) {
     setOrdinalValue('st');
   } else if (j === 2 && k != 12) {
     setOrdinalValue('nd');
   } else if (j === 3 && k != 13) {
     setOrdinalValue('rd');
   } else setOrdinalValue('th');
 }

 export const FeeAmount = (props) => {
   const { values, errors, touched, handleBlur, handleChange, name, symbol } = props;

   return <Form.Group>
       <Form.Label>{name}</Form.Label>
       <InputGroup>
         <InputGroup.Prepend>
           <InputGroup.Text>{ symbol ? symbol : '$' }</InputGroup.Text>
         </InputGroup.Prepend>
         <Form.Control
           type="number"
           name="feeAmount"
           value={values.feeAmount}
           onChange={handleChange}
           onBlur={handleBlur}
           isValid={touched.feeAmount && !errors.feeAmount}
           isInvalid={!!errors.feeAmount}
           min='0'
           step="0.1"
         />
         <Form.Control.Feedback type="invalid">
           {errors.feeAmount}
         </Form.Control.Feedback>
         </InputGroup>
     </Form.Group>
 }

export const FlagAmount = (props) => {
   const { values, errors, touched, handleBlur, handleChange, name } = props;
   return <Form.Group>
     <Form.Label>{name}</Form.Label>
     <Form.Control
       type="number"
       name="flagAmount"
       value={values.flagAmount}
       onChange={handleChange}
       onBlur={handleBlur}
       isValid={touched.flagAmount && !errors.flagAmount}
       isInvalid={!!errors.flagAmount}
     />

     <Form.Control.Feedback type="invalid">
       {errors.flagAmount}
     </Form.Control.Feedback>
   </Form.Group>
 }

export const renderSubmit = (values, errors, touched, handleBlur, handleChange, type, actionType) => {
   if (type === 'feeAmountOnly') {
     return (values.feeAmount) && (!errors.feeAmount)
       ?  <SubmitForm key={'feeAmountOnly'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} actionType={actionType}/>
       : null
   } else if (type === 'optionRequired') {
     return (values.feeAmount && values.option) && (!errors.feeAmount)
       ?  <SubmitForm key={'optionRequired'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} actionType={actionType}/>
       : null
   } else if (type === 'allRequired') {
     return (values.feeAmount && values.option && values.flagAmount) && (!errors.feeAmount)
       ?  <SubmitForm key={'allRequired'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} actionType={actionType}/>
       : null
   } else {
   return (values.feeAmount && (values.flagAmount || values.flagAmount === 0)) && (!errors.feeAmount && !errors.flagAmount)
       ?  <SubmitForm key={'bothAmounts'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} actionType={actionType}/>
       : null
     }
   }

export const FeeExtensionInput = (props) => {
 const { ordinalValue,
         setOrdinalValue,
         values,
         errors,
         touched,
         handleBlur,
         handleChange,
         name } = props;

 renderOrdinalSuffix(ordinalValue, setOrdinalValue, values.flagAmount)

 return <Form.Group style={{display: 'flex', alignItems: 'center'}}>
   <Form.Label>{name}</Form.Label>
   <Form.Control
     type="number"
     name="flagAmount"
     value={values.flagAmount}
     onChange={handleChange}
     onBlur={handleBlur}
     isValid={touched.flagAmount && !errors.flagAmount}
     isInvalid={!!errors.flagAmount}
     style={{width: '64px', margin: '0 8px 0 8px'}}
   />
   {ordinalValue} Extension
 </Form.Group>
}

export const TypeOfPurchaseOption = (props) => {
 const { values, errors, touched, handleBlur, handleChange, name } = props;
 return <div>
         <Form.Group>
           <Form.Label>Type of Purchase</Form.Label>
           <Form.Control
             as="select"
             name="option"
             value={values.option}
             onChange={handleChange}
             onBlur={handleBlur}
             isValid={touched.option && !errors.option}
             isInvalid={!!errors.option && touched.option}
           >
             <option>Select a Type</option>
             <option value="nap">NAP</option>
             <option value="auction">Auction</option>
           </Form.Control>
           <Form.Control.Feedback type="invalid">
             {errors.option}
           </Form.Control.Feedback>
       </Form.Group>
       </div>
   }

 export const PrincipalAmountOptions = (props) => {
   const { values, errors, touched, handleBlur, handleChange, name } = props;
   return <div>
       <Form.Group style={{display: 'flex', alignItems: 'center'}}>
         <Form.Label>When the principal amount is</Form.Label>
         <Form.Control
           as="select"
           name="option"
           value={values.option}
           onChange={handleChange}
           onBlur={handleBlur}
           isValid={touched.option && !errors.option}
           isInvalid={!!errors.option && touched.option}
           style={{width: '100px', margin: '0 8px 0 8px'}}
         >
           <option></option>
           <option value="above">Above</option>
           <option value="below">Below</option>
           <option value="equal">Equal To</option>
         </Form.Control>
         <Form.Control.Feedback type="invalid">
           {errors.option}
         </Form.Control.Feedback>
         <InputGroup>
           <InputGroup.Prepend>
             <InputGroup.Text>$</InputGroup.Text>
           </InputGroup.Prepend>
           <Form.Control
             type="number"
             name="flagAmount"
             value={values.flagAmount}
             onChange={handleChange}
             onBlur={handleBlur}
             isValid={touched.flagAmount && !errors.flagAmount}
             isInvalid={!!errors.flagAmount}
             min='0'
             step="0.1"
           />
           </InputGroup>
     </Form.Group>
   </div>
 }

export const BosOptions = (props) => {
   const { values, errors, touched, handleBlur, handleChange, name } = props;

   return <div>
       <Form.Group style={{display: 'flex', alignItems: 'center'}}>
         <Form.Label>When the BOS Date is greater than</Form.Label>
         <Form.Control
           type="number"
           name="flagAmount"
           value={values.flagAmount}
           onChange={handleChange}
           onBlur={handleBlur}
           isValid={touched.flagAmount && !errors.flagAmount}
           isInvalid={!!errors.flagAmount && touched.flagAmount}
           style={{width: '100px', margin: '0 8px 0 8px'}}
         />
         Days
         <Form.Control.Feedback type="invalid">
         </Form.Control.Feedback>
         <Form.Control
           as="select"
           name="option"
           value={values.option}
           onChange={handleChange}
           onBlur={handleBlur}
           isValid={touched.option && !errors.option}
           isInvalid={!!errors.option && touched.option}
           style={{width: '100px', margin: '0 8px 0 8px'}}
         >
           <option></option>
           <option value="before">Before</option>
           <option value="after">After</option>
         </Form.Control>
         <Form.Control.Feedback type="invalid">
           {errors.option}
         </Form.Control.Feedback>
        the Date Floored
     </Form.Group>
   </div>
 }
