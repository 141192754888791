import React from 'react';
import { setDate } from 'components/src/utils/dateHelpers';
import { currency } from 'components/src/utils/stringHelpers';
import AuthLink from 'components/src/components/AuthLink';

export const columnDefinitions = props => {
  const columns = [
    {
      key: 'inspectionOn',
      label: 'Lot Audit Date',
      sort: 'desc',
      content: ({ value }) => setDate(value)
    },
    {
      key: 'quiktrakJobNumber',
      label: 'Job Number'
    },
    {
      key: 'auditStatus',
      label: 'Lot Audit Status',
      content: ({ entry }) => {
        if (entry.auditClosedDate) {
          return "Closed"
        } else {
          return "Open"
        }
      }
    },
    {
      key: 'foundPercentage',
      label: 'Found Percentage',
      minViewportWidth: 1200,
      content: ({ entry }) => `${entry.foundPercentage}%`
    },
    {
      key: 'numberOfUnits',
      label: 'No. of Floored Cars'
    },
    {
      key: 'unitsUnseen',
      label: 'Units Missing At Audit',
    },
    {
      key: 'unverifiedAuditCarsCount',
      label: 'Currently Unverified / Unpaid Units',
    },
    {
      key: 'unverifiedAuditCarsValue',
      label: 'Value of Currently Missing Units',
      minViewportWidth: 800,
      content: ({ value }) => currency(value),
    },
    {
      key: 'totalCurrentBalance',
      label: 'Outstanding Balance',
      content: ({ entry }) => currency(entry.totalCurrentBalance)
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => {
        return (
          <AuthLink
            to={`/dealers/${entry.dealer.id}/audits/${entry.id}`}
            resource="Audit"
            action="read"
          >
            View
          </AuthLink>
        )
      }
    }
  ];

  return columns
}
