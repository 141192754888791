import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import DataState from '../../DataState';
import { fiscalMonthSchema, Form } from './Form';
import { titleCase } from '../../../utils/stringHelpers';
import { FISCAL_MONTH, UPDATE_FISCAL_MONTH } from './queries';

const Edit = () => {
  const id = parseInt(useParams().id);
  const history = useHistory();

  const { loading, error, data } = useQuery(FISCAL_MONTH, { variables: { id } });

  const [updateFiscalMonth] = useMutation(UPDATE_FISCAL_MONTH,
    {
      onCompleted() {
        history.push({
          pathname: `/financial/fiscal-month-calendar`,
          state: { alert: true },
        })
      }
    }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    year,
    period,
    periodName,
    startOn,
    endOn
  } = data.fiscalMonth;

  const handleSubmit = (values) => {
    values.year = parseInt(values.year);
    values.period = parseInt(values.period);

    updateFiscalMonth({ variables: values })
  };

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>
            Update Fiscal Month: {year} {periodName}
          </h1>
        </div>
        <Formik
          initialValues={{
            id: id,
            year: year || '',
            period: period || '',
            startOn: startOn || '',
            endOn: endOn || '',
          }}
          validationSchema={fiscalMonthSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <>
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    </>
  );
};

export default Edit;
