export const responsiveDefaults = {
  retrieve: true,
  language: { emptyTable: ' '},
  columnDefs: [
    { responsivePriority: 1, targets: [0, 1] },
    { responsivePriority: 2, targets: -1 },
  ],
}


export const getInitialColumnSortIndex = (columns, formattedColumnSortName) => {
  let columnId;
  columns.forEach((column, index) => {
    if (column.title === formattedColumnSortName) columnId = index;
  })

  return columnId;
}

export const createOrder = (columnId, direction) => {
  return (columnId !== null && columnId !== undefined)
      ? [columnId, direction]
      : [];
}

export const getColumnPriorityIndexes = (columns, columnPriorities) => {
  return columnPriorities.map((priority) => {
    return columns.findIndex((col, idx) => {
      return col.title.toLowerCase() === priority.toLowerCase()
    })
  })
}

export const getColumnIndex = (columns, columnTitle) => {
  const column = columns.find((column) => column.title === columnTitle );
  return columns.indexOf(column);
}
