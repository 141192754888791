import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import * as Style from '../../../styles/Source/showInfo';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import DataState from '../../DataState';
import { DateHelpers } from '../../../utils';
import { Spacing, Typography } from '../../../styles';
import * as StringHelper from '../../../utils/stringHelpers';
import * as Buttons from '../../../elements/buttons';
import Txns from './TxnsForReconciliation'

const RECONCILE = gql`
  query RECONCILE($id: ID!) {
    reconcile(id: $id) {
      id
      account {
        id
        name
        clearedBalance
      }
      startOn
      endOn
      startBalance
      endBalance
      completedAt
    }
  }
`

const UPDATE_RECONCILE = gql`
  mutation UpdateReconcile(
    $id: ID!
    $completedAt: ISO8601DateTime
  ) {
    updateReconcile(
      id: $id
      completedAt: $completedAt
    ) {
      reconcile {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const Show = () => {
  const id = parseInt(useParams().id);
  const history = useHistory();
  const { loading, error, data, refetch } = useQuery(RECONCILE, { variables: { id } });
  const [updateReconcile] = useMutation(UPDATE_RECONCILE);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    refetch()
    setTotal(data?.reconcile?.account?.clearedBalance || 0)
  }, [data])

  const complete = () => {
    updateReconcile({ variables: { id: id, completedAt: new Date() }});
    window.location.reload();
  }

  const leave = () => {
    history.push('/financial/reconciles');
  }

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const endBalance = data.reconcile.endBalance

  let buttons = (
    <div style={Spacing.buttonRow}>
      <Buttons.Cancel type="button" text="Leave" onClick={leave} />
    </div>
  );

  if (!data.reconcile.completedAt) {
    buttons = (
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="button" text="Save and Leave" onClick={leave} />
        {endBalance === total &&
          <Buttons.Standard type="button" text="Complete" onClick={complete} />
        }
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Bank Reconcile</title>
      </Helmet>
      <div style={Typography.pageTitle}>Bank Reconcile</div>
      <Container style={Style.auctionInfoContainer}>
        <Row>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>Account:</Col>
              <Col align="right">{data.reconcile.account.name}</Col>
            </Row>
          </div>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>Start Date:</Col>
              <Col align="right">{DateHelpers.setDate(data.reconcile.startOn)}</Col>
            </Row>
            <Row className="info-row">
              <Col>End Date:</Col>
              <Col align="right">{DateHelpers.setDate(data.reconcile.endOn)}</Col>
            </Row>
            <Row className="info-row">
              <Col>Completed At:</Col>
              <Col align="right">{DateHelpers.setDate(data.reconcile.completedAt)}</Col>
            </Row>
          </div>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>Start Balance:</Col>
              <Col align="right">{StringHelper.currency(data.reconcile.startBalance)}</Col>
            </Row>
            <Row className="info-row">
              <Col>End Balance:</Col>
              <Col align="right">{StringHelper.currency(endBalance)}</Col>
            </Row>
            {
              !data.reconcile.completedAt && <>
                <Row className="info-row">
                  <Col>Cleared Balance:</Col>
                  <Col align="right">{StringHelper.currency(total)}</Col>
                </Row>
                <Row className="info-row">
                  <Col>Difference:</Col>
                  <Col align="right">{StringHelper.currency(endBalance - total)}</Col>
                </Row>
              </>
            }
          </div>
        </Row>
      </Container>
      <div style={Style.bottomBorder} />
      {buttons}
      <Txns id={data.reconcile.id} refetch={refetch} />
    </>
  )
}

export default Show;
