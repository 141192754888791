import React from 'react';
import { Dots } from 'react-activity';

const DisplayLoading = ({ style, size }) => {
  if (!size) size = 30;

  return <div style={Object.assign({}, activityIndicator, style )}>
    <Dots size={size} color={'#2b6448'} />
  </div>
}

const activityIndicator = {
  display: 'flex',
  justifyContent: 'center'
}


export default DisplayLoading;
