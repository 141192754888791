import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import FormBody from './FormBody';
import { Form as FormikForm } from "formik";
import { useFormikContext } from "formik";
import Table from "react-bootstrap/Table";
import { Helmet } from 'react-helmet';
import BasicAlert from '../../../elements/BasicAlert';
import { setAlertData } from '../../../utils/setAlertData';
import { useAlert } from '../../../hooks/useAlert';
import DataState from '../../DataState';
import { SortButtons, handleSort } from "../../Payments/Sort";
import { Colors, Spacing } from '../../../styles';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import Button from 'react-bootstrap/Button';
import { filterButton } from '../../../elements/Pagination/FilterStyles';
import * as Styles from './styles/form';
import FilterModal from './Filter';

const Form = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const [orderedColumn, setOrderedColumn] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [reordered, setReordered] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  let currentColumn = useRef(null);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), []);

  const { values, setFieldValue } = useFormikContext();

  const {
    data,
    setOrderedData,
    setPaymentButton,
    handleSubmit,
    serverSideErrors,
    rawData,
    filteredData,
    setFilteredData,
    filterOptions,
    bankDateValue,
    } = props;

  const activeLines = values.txnLineAmounts.filter((line) => line.active)
  let total = 0;
  activeLines.map((line) => {
    const values = Object.values(line);
    total += Number.isInteger(values[0]) ? parseFloat(values[0]) : parseFloat(values[1])
  });

  total = isNaN(total) ? 0 : total;

  const hasBankDateValue = bankDateValue.current?.value !== '';

  return (
    <>
      <Helmet>
        <title>Unpaid Bills</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="unpaid bill" type={alertType} />
      }
      <FilterModal
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        setOrderedData={setOrderedData}
        rawData={rawData}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        filterOptions={filterOptions}
      />
      <FormikForm>
        <br />
        <Button style={filterButton} onClick={() => setShowFilter(true)}>
          Filter
        </Button>
        <br />
        <br />
        <Table striped hover responsive>
          <thead>
            <tr>
              <th style={Styles.columnHeaderStyle}>
              <BasicForm.JournalCheckbox
                id="payAll"
                name="payAll"
                type="checkbox"
                onClick={(event) => {
                  const checkAll = event.target.checked;
                  values.txnLineAmounts.map((obj, index) => {
                    setFieldValue(`txnLineAmounts[${index}].active`, checkAll)

                    const keys = Object.keys(obj);
                    const id = keys[0] === 'active' ? keys[1] : keys[0];

                    if (!checkAll) setFieldValue(`txnLineAmounts[${index}].txnLineId`, 0);
                  })
                }}
              />
              </th>
              <th
                className="ordered-column"
                style={Object.assign({}, Styles.columnHeaderStyle, { minWidth: "90px" })}
                onClick={() =>
                  handleSort(
                    data,
                    "payee",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Payee
                {
                // Rmoving sort functionality for first build
                // SortButtons columnName={"payee"} selectedColumn={orderedColumn //}
                }
              </th>
              <th
                className="ordered-column"
                style={Object.assign({}, Styles.columnHeaderStyle, { minWidth: "90px" })}
                onClick={() =>
                  handleSort(
                    data,
                    "reference",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Reference
              </th>
              <th
                className="ordered-column"
                style={Object.assign({}, Styles.columnHeaderStyle, {
                  minWidth: "150px",
                })}
                onClick={() =>
                  handleSort(
                    data,
                    "dueDate",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Due Date
              </th>
              <th
                className="ordered-column"
                style={Styles.columnHeaderStyle}
                onClick={() =>
                  handleSort(
                    data,
                    "openBalance",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Open Balance
              </th>
              <th
                className="ordered-column"
                style={Object.assign({}, Styles.columnHeaderStyle, { minWidth: "50px" })}
                onClick={() =>
                  handleSort(
                    data,
                    "payment",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Payment
              </th>
            </tr>
          </thead>
          <tbody>
            <FormBody data={data}/>
          </tbody>
        </Table>
        <div style={Styles.summaryRow}>
          <span style={Styles.summaryText}>
            {" "}
            Payment Total: ${ total }
          </span>
        </div>
        <div style={Spacing.paymentButtonRow}>
          <Buttons.PaymentCancel type="cancel" text="Cancel" />
          <Buttons.PaymentStandard
            type="button"
            text="Pay"
            onClick={() => handleSubmit(values, "payment")}
            disabled={activeLines.length < 1 || !hasBankDateValue || disabled}
          />
        </div>
      </FormikForm>
    </>
  );
};

export default Form;
