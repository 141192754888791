import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const COLLATERAL_COVERAGE_FIELDS = `
  id
  name
  description
  insuranceProgramChanges { id, rate, effectiveOn }
  expirable
  gracePeriod
  createdBy { fullName }
  hiddenAt
  canBeHidden
`;

const COLLATERAL_COVERAGE_FIELDS_AND_ERRORS = `
  insuranceProgram {
    ${COLLATERAL_COVERAGE_FIELDS}
  }
  ${ERRORS}
`;

export const COLLATERAL_COVERAGES = gql`
  query InsurancePrograms($scopes: [String!]) {
    insurancePrograms(
      scopes: $scopes
    ) {
      ${COLLATERAL_COVERAGE_FIELDS}
    }
  }
`;

export const HIDE_COLLATERAL_COVERAGE = gql`
  mutation HideInsuranceProgram($id: ID!) {
    hideInsuranceProgram(
      id: $id
    ) {
      ${COLLATERAL_COVERAGE_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_COLLATERAL_COVERAGE = gql`
  mutation UnhideInsuranceProgram($id: ID!) {
    unhideInsuranceProgram(
      id: $id
    )
    {
      ${COLLATERAL_COVERAGE_FIELDS_AND_ERRORS}
    }
  }
`;
