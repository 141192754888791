import React, { useState, useContext } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import moment from 'moment';
import AuthContext from '../../contexts/AuthContext';
import * as Formatting from '../../styles/formatting';
import * as StringHelpers from "../../utils/stringHelpers";
import * as DateHelpers from "../../utils/dateHelpers";
import * as Buttons from '../../elements/buttons';
import { canWaiveFee } from '../Fees/functions';
import NewFeeWaiver from '../Cars/Fees/Modal';
import '../Dealers/dealershow.css';

const FeeInfo = (fee) => {
  const auth = useContext(AuthContext);
  const [showWaiveModal, setShowWaiveModal] = useState(false);

  const term = 'none' === fee.termType ?
    '' : `${StringHelpers.titleCase(fee.termType)} ${fee.termNumber}`

  const details = [
    ['Name', fee.name],
    ['Term', term],
    ['Charge On', DateHelpers.setDate(fee.chargeOn)],
    ['Days Prorated', fee.prorateDays],
    ['Earns Interest?', StringHelpers.yesNo(fee.earnsInterest)]
  ];

  const amounts = [
    ['Original Amount', StringHelpers.currency(fee.amount)],
    ['Due', StringHelpers.currency(fee.due)],
    ['Earned', StringHelpers.currency(fee.earned)],
    ['Paid Amount', StringHelpers.currency(fee.paidAmount)],
    ['Waived Amount', StringHelpers.currency(fee.waivedAmount)]
  ];

  const timestamps = [
    ['Created Time',  DateHelpers.setTime(fee.createdAt)],
    ['Created By',  fee.createdBy?.fullName],
  ];

  if (moment(fee.updatedAt) - moment(fee.createdAt) > 10000) {
    timestamps.push(['Last Updated Time', DateHelpers.setTime(fee.updatedAt)]);
    timestamps.push(['Last Updated By', fee.updatedBy?.fullName]);
  }

  let statusClass = 'car-status-Unpaid'
  if ('Waived' === fee.status) {
    statusClass = 'car-status-Writeoff'
  } else if ('Paid' === fee.status) {
    statusClass = 'car-status-Paid'
  } else if ('Pending ACH' === fee.status) {
    statusClass = 'car-status-Voided'
  }

  const [canWaive, waiveIssue] = canWaiveFee(fee, auth);
  let waiveButton = waiveIssue;
  if (canWaive) {
    waiveButton = (
      <Buttons.Link
        text="Waive Fee"
        onClick={() => setShowWaiveModal(true)}
      />
    );
  }

  const actions = [
    ['Status', fee.status, statusClass],
    ['Waivers', waiveButton]
  ];

  if ('Paid' === fee.status) {
    actions.push(['Paid On', DateHelpers.setDate(fee.paidOrWaivedOn)]);
  } else if ('Waived' === fee.status) {
    actions.push(['Waived On', DateHelpers.setDate(fee.paidOrWaivedOn)]);
  }

  const groups = [details, amounts, timestamps, actions];

  return (
    <>
      <Container style={Formatting.infoContainer}>
        <Row>
          {
            groups.map((rows, groupIndex) => {
              return (
                <div key={groupIndex} className="col-xl-2 info-section">
                  {
                    rows.map((detail, index) => {
                      return (
                        <Row
                          className={`info-row ${detail[2] || ''}`}
                          key={index}
                        >
                          <Col>{detail[0]}:</Col>
                          <Col align="right">{detail[1]}</Col>
                        </Row>
                      );
                    })
                  }
                </div>
              );
            })
          }
        </Row>
      </Container>
      {
        showWaiveModal &&
          <NewFeeWaiver
            showModal={showWaiveModal}
            setShowModal={setShowWaiveModal}
            fee={fee}
          />
      }
    </>
  );
};

export default FeeInfo;
