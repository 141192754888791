import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useMutation } from '@apollo/client';
import { CustomizableDropzone } from 'components/src/elements/Dropzone';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import * as BasicForm from 'components/src/elements/forms';
import { Spacing } from 'components/src/styles';
import * as Buttons from 'components/src/elements/buttons';
import { currency } from 'components/src/utils/stringHelpers';
import { REQUEST_LINE_MOD } from './Queries';

const RequestLineMod = ({dealer, closeModal, setAlertData}) => {
  const dealerId = dealer.id;
  const [files, setFiles] = useState([]);

  const [requestLineMod, { loading }] = useMutation(
    REQUEST_LINE_MOD,
    {
      onCompleted: data => {
        closeModal();
        setAlertData({
          content: 'Temp/Line Mod Request Email has been sent to ' +
            data.requestLineMod.recipients.join(', ')
        });
      }
    }
  );

  const currentLine = currency(
    dealer.creditLine,
    { maximumFractionDigits: 0 }
  );

  const schema = yup.object({
    type: yup.string().required('Required'),
    amount: yup
      .number()
      .required('Required')
      .when('type', {
        is: 'Temp Increase',
        then: yup.number().max(
          dealer.creditLine,
          `Maximum request amount is ${currentLine}`
        )
      })
  });

  const handleSubmit = values => {
    requestLineMod({
      variables: { dealerId, files, amount: values.amount, type: values.type }
    });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={closeModal}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Request Temp/Line Mod
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Submitting this form will send an email to Dealer's Account Manager,
          with the uploaded file(s) attached.
        </p>
        <p>
          Current Credit Line Amount: {currentLine}
        </p>

        <Formik
          initialValues={{
            amount: '',
            type: '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
          onReset={closeModal}
        >
          {({isValid}) => (
            <Form>
              <BasicForm.Select
                name="type"
                type="select"
                label="Request Type"
              >
                <option></option>
                <option>Temp Increase</option>
                <option>Line Modification</option>
              </BasicForm.Select>
              <BasicForm.DollarInput
                name="amount"
                type="number"
                label="Increase my line by"
                onInput={e => e.target.value = Math.round(e.target.value)}
              />
              <BasicForm.StyledLabel>
                Last 3 Months of Bank Statements
              </BasicForm.StyledLabel>
              <CustomizableDropzone
                accept=".pdf, image/*"
                description="Drag and drop files here or click to choose files."
                setFiles={setFiles}
              />
              <div style={Spacing.buttonRow}>
                <Buttons.ModalCancel
                  type="cancel"
                  text="Cancel"
                  handleClose={closeModal}
                />
                <Buttons.Standard
                  type="submit"
                  text="Submit"
                  disabled={!isValid}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default RequestLineMod;
