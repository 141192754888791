import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { PayForm } from './Form';
import { Spacing, Typography } from '../../styles';
import { formatValues } from './Functions';
import NewPayment from './ProcessPayment/Modal';
import DataState from '../DataState';
import NewEmailQuote from './EmailQuote/Form';
import { isoToday, isOlderThanToday } from '../../utils/dateHelpers';
import { titleCase } from '../../utils/stringHelpers';

export const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      reserveBalance
      onAccountBalance
      payableFees {
        id
        amount
        name
        due
      }
      unpaidFees {
        id
        amount
        name
        due
      }
    }
    settings { booksClosedThrough }
  }
`;

const CREATE_QUOTE = gql`
  mutation CreateQuote($dealerId: Int!, $paymentDate: ISO8601Date!, $quoteCarsAttributes: [QuoteCarInput!], $quoteFeesAttributes: [QuoteFeeInput!]) {
    createQuote(
      dealerId: $dealerId
      paymentDate: $paymentDate
      quoteCarsAttributes: $quoteCarsAttributes
      quoteFeesAttributes: $quoteFeesAttributes
    ) {
      quote {
        id
        amount
        quoteFees { fee { id }, amount }
        quoteCars {
          id
          flooredCar { id }
          principal, interest, insurance
          quoteFees { fee { id }, amount }
        }
      }
      errors { path, message }
    }
}`;

const NewQuote = ({ data, total }) => {
  const dealerId = parseInt(useParams().id);

  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [quoteId, setQuoteId] = useState(null);
  const [quoteAmount, setQuoteAmount] = useState(null);
  const [sendQuoteModal, setSendQuoteModal] = useState(false);
  const [emailButton, setEmailButton] = useState(false);
  const [paymentButton, setPaymentButton] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');
  const [dealerFees, setDealerFees] = useState(false);

  let quoteDate = useRef(null);
  let orderedData = useRef(data);
  let persistedQuoteValues = useRef({});

  const { loading, error, data: dealerData }= useQuery(DEALER, { variables: { id: dealerId }});

  const setOrderedData = (orderedData) => orderedData.current = orderedData;

  const [createQuote, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_QUOTE, {
    onCompleted(values) {
      const key = Object.keys(values)[0];
      const errors = values[key].errors;

      if (errors?.length) {
        let errorString = '';
        let errorName = '';
        let errorMessage = '';
        errors.forEach((error, index) => {
          errorName = error.path[1];
          errorMessage = error.message;
          const separator = index > 0 ? ', ' : ''
          errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`
        })
        setServerSideErrors(errorString)
      } else {
        setQuoteId(parseInt(values.createQuote.quote.id));
        setQuoteAmount(parseFloat(values.createQuote.quote.amount));

        if (emailButton === true) {
          setSendQuoteModal(true)
        } else if (paymentButton === true) {
          setShowModal(true);
        }
      }
    }
  })

  if (loading) return <DataState.Loading />
  if (error || mutationError) return <DataState.Error error={error || mutationError} />;

  const { dealer: { name, reserveBalance, onAccountBalance } } = dealerData;

  const handleSubmit = (values, submitType) => {
    persistedQuoteValues.current = values;
    quoteDate.current = values.paymentDate;

    delete values.maxPrincipal;
    formatValues(values.quoteCarsAttributes, [
      'principal',
      'paymentType',
      'feeDetails',
    ])

    if (submitType === "email") {
      setEmailButton(true)
    } else if (submitType === "payment") {
      setPaymentButton(true)
    }
    const dd = dealerData.settings.booksClosedThrough;

    createQuote({ variables: values })
  };

  const { paymentDate, quoteCarsAttributes, quoteFeesAttributes } = persistedQuoteValues.current;

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/dealer-statuses",
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <Fragment>
        <div style={Object.assign({}, Spacing.formWrapper, {marginTop: '0'})}>
          <Formik
            initialValues={{
              dealerId,
              paymentDate: paymentDate || isoToday(),
              quoteCarsAttributes: quoteCarsAttributes || [],
              quoteFeesAttributes: quoteFeesAttributes || null,
              maxPrincipal: [],
            }}
            validationSchema={null}
          >
            <PayForm
              data={orderedData.current}
              dealerData={dealerData}
              total={total}
              setDealerFees={setDealerFees}
              dealerFees={dealerFees}
              setOrderedData={setOrderedData}
              setEmailButton={setEmailButton}
              setPaymentButton={setPaymentButton}
              handleSubmit={handleSubmit}
              serverSideErrors={serverSideErrors}
            />
          </Formik>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div style={Spacing.formContainer}>
        {
          showModal && <NewPayment
            showModal={showModal}
            setShowModal={setShowModal}
            dealerName={name}
            quoteId={quoteId}
            reserveBalance={reserveBalance}
            onAccountBalance={onAccountBalance}
            quoteAmount={quoteAmount}
            dealerFees={dealerFees}
            quoteDate={quoteDate.current}
            setPaymentButton={setPaymentButton}
          />
        }
        {
          sendQuoteModal && <NewEmailQuote
            sendQuoteModal={sendQuoteModal}
            setSendQuoteModal={setSendQuoteModal}
            dealerId={dealerId}
            setEmailButton={setEmailButton}
            quoteId={quoteId}
            paymentDate={paymentDate}
          />
        }
        <NewForm />
      </div>
    </Fragment>
  );
};

export default NewQuote;
