import React from 'react';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';

export const Form = () => {
  const { values } = useFormikContext();
  return (
    <FormikForm>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Create" />
      </div>
    </FormikForm>
  );
}
