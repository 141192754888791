import React, { useRef, Fragment } from 'react';
import { gql } from '@apollo/client';
import { Formik, useFormikContext } from 'formik';
import { isoToday } from '../../../utils/dateHelpers';
import { Spacing, Typography } from '../../../styles';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'
import useClosedBooks from 'components/src/hooks/useClosedBooks';
import Form, { txnSchema } from './Form';
import {
  CREATE_TXN as CREATE_VENDOR_CREDIT
} from 'components/src/queries/mutations';

const NewVendorCredit = () => {
  const customSubmitHandler = (values) => {
    values.typeName = undefined;

    if (values.entityId === '') {
      values.entityId = undefined;
    } else {
      values.entityId = parseInt(values.entityId);
    }

    if (values.entityType === '') {
      values.entityType = undefined;
    }

    const txnLinesAttributes = values.txnLinesAttributes;

    txnLinesAttributes.map((line, index) => {
      if (line.credit === '') {
        line.credit = undefined;
      }

      if (line.marketId === '') {
        line.marketId = undefined;
      }

      if (line.credit === undefined || line.credit === '') {
        values.txnLinesAttributes.splice(index, 1)
      }
    });

    let total = 0.0

    values.txnLinesAttributes.map(line => {
      total += parseFloat(line.credit)
    })

    const offsetTxnLine = {
      accountId: values.payableAccountId,
      debit: total.toFixed(2),
      earnedOn: isoToday()
    }

    txnLinesAttributes.unshift(offsetTxnLine)

    values.payableAccountId = undefined
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_VENDOR_CREDIT,
    redirectToShowPageType: 'txn',
    customSubmitHandler: customSubmitHandler
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);
  const checkClosedBooks = useClosedBooks(handleSubmit);

  const NewForm = () => {
    return (
      <Fragment>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create a Vendor Credit</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              type: 'Vendor Credit',
              createdDate: isoToday(),
              bankDate: isoToday(),
              name: '',
              reference: '',
              memo: '',
              entityType: undefined,
              entityId: undefined,
              payableAccountId: undefined,
              txnLinesAttributes: [
                {
                  credit: '',
                  accountId: undefined,
                  earnedOn: isoToday(),
                  earnsInterest: false,
                  memo: ''
                }
              ]
            }}
            validationSchema={txnSchema}
            onSubmit={checkClosedBooks}
          >
            <Form />
          </Formik>
        </div>
      </Fragment>
    );
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default NewVendorCredit;
