import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TITLE_LOG } from '../../../../queries/viewQueries';
import { useQuery } from '@apollo/client';
import DataState from '../../../DataState';
import * as Colors from '../../../../styles/colors';
import { Document, Page, pdfjs } from 'react-pdf';
import { isMobile, isSafari } from "react-device-detect";
import { page, containerStyle, orange, green } from '../Style';

const TitlePdf = () => {
  const id = parseInt(useParams().titleLogId);
  const [numPages, setNumPages] = useState(null);

  const { loading, error, data } = useQuery(TITLE_LOG, { variables: { id } });

  pdfjs.GlobalWorkerOptions.workerSrc = `
    //cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js
  `;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const src = data.titleLog.fileUpload.fileUrl
  const name = data.titleLog.fileUpload.name

  const CheckView = () => {
    if (isMobile || isSafari) {
      return (
        <div style={containerStyle}>
          <Document
            file={src}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      )
    } else {
      return (
        <div style={page}>
          <embed src={src} style={containerStyle} />
        </div>
      )
    }
  }

  return (
    <>
      <h3 style={orange}>Scanned Title</h3>
      <div style={green}>
        Name: {name}
      </div>
      <CheckView />
    </>
  )
}

export default TitlePdf
