import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { QUOTE_AMOUNTS } from '../../queries/viewQueries';
import { StyledErrorMessage } from '../../elements/forms';

//** Control query requests **//
let loadingQueryIndexes = [];
let changeInsuranceLoading = [];
let changeInterestLoading = [];

// Control changing amounts, allow for user to change princpal amounts
let previousPrincipals = [];
let previousInsurance = [];
let previousInterest = [];
// ** //

export const getQuoteData = (car, index, reordered, dealerFees) => {
  const [getQuote, { loading, error, data: quoteData }] = useLazyQuery(QUOTE_AMOUNTS);
  const { values, setFieldValue } = useFormikContext();
  let paymentType = null;
  let newPaymentType;

  if (values.quoteCarsAttributes && values.quoteCarsAttributes[index]) {
    newPaymentType = values.quoteCarsAttributes[index]['paymentType'];
  }

  if (newPaymentType && newPaymentType !== paymentType) {
    paymentType = newPaymentType;
  }

  useEffect(() => {
    getQuote({
      variables:
      {
        paymentType,
        flooredCarId: parseInt(car.id),
        date: values.paymentDate,
      }
    })

    loadingQueryIndexes[index] = true;
    changeInsuranceLoading[index] = true;
    changeInterestLoading[index] = true;

  }, [values.paymentDate, paymentType, reordered])

  if (loading || !quoteData) return null;
  if (error) return null;

  const { quoteAmounts } = quoteData;

  if (!previousPrincipals[index]) previousPrincipals[index] = quoteAmounts.principal;
  if (!previousInsurance[index]) previousInsurance[index] = quoteAmounts.insurance;
  if (!previousInterest[index]) previousInterest[index] = quoteAmounts.interest;

  // set static values on first load
  if (!values.quoteCarsAttributes || !values.quoteCarsAttributes[index]) {
    const totalDue = parseInt(quoteAmounts.principal) + parseInt(quoteAmounts.fees) +
      parseInt(quoteAmounts.interest) + parseInt(quoteAmounts.insurance) + parseInt(quoteAmounts.reserves);

    setFieldValue(`quoteCarsAttributes[${index}]`,
      {
        totalDue,
        id: index,
        principal: quoteAmounts.principal,
        paymentType: '',
        flooredCarId: parseInt(car.id),
        interest: parseFloat(quoteAmounts.interest),
        insurance: parseFloat(quoteAmounts.insurance),
        reserves: parseFloat(quoteAmounts.reserves),
      }
    )
    setFieldValue(`maxPrincipal[${index}]`, parseFloat(quoteAmounts.principal))
  }

  if (!values.quoteFeesAttributes && !dealerFees) {
    const dealerFeesMap = car.dealer.payableFees.map((fee) => {
      const feeId = parseInt(fee.id);
      const amount = parseFloat(fee.due)

      return { feeId, amount }
    })

    setFieldValue(`quoteFeesAttributes`,
      dealerFeesMap
    )

  }


  // set dynamic values after first load
  if (values.quoteCarsAttributes && values.quoteCarsAttributes[index] &&
    loadingQueryIndexes[index] === true) {
    if (values.quoteCarsAttributes[index].reserves !== parseFloat(quoteAmounts.reserves)) {
      let reserves = (!quoteAmounts.reserves)
        ? 0
        : parseFloat(quoteAmounts.reserves)
      values.quoteCarsAttributes[index].reserves = reserves;
    }

    // I have no idea why this works, but it keeps the correct values and fees in order
    values.quoteCarsAttributes[index].feeDetails = [];
    // ** //

    // Keep this it must be present for principal values to update on re-queries
    if (parseFloat(previousPrincipals[index]) !== parseFloat(quoteAmounts.principal)) {
      previousPrincipals[index] = parseFloat(quoteAmounts.principal);
      setFieldValue(`quoteCarsAttributes[${index}].principal`, quoteAmounts.principal);
      if (paymentType !== 'curtailment') values.maxPrincipal[index] = parseFloat(quoteAmounts.principal);
      loadingQueryIndexes[index] = false;
      previousPrincipals[index] = parseFloat(quoteAmounts.principal);
    }
  }

  // Must be present for insurance values to be changed and sorted correctly
  if (values.quoteCarsAttributes && values.quoteCarsAttributes[index] && changeInsuranceLoading[index]) {
    if (parseFloat(previousInsurance[index]) !== parseFloat(quoteAmounts.insurance)) {
      previousInsurance[index] = parseFloat(quoteAmounts.insurance);
      setFieldValue(`quoteCarsAttributes[${index}].insurance`, quoteAmounts.insurance);
      changeInsuranceLoading[index] = false;
    }
  }
  // Must be present for interest values to be changed and sorted correctly
  if (values.quoteCarsAttributes && values.quoteCarsAttributes[index] && changeInterestLoading[index]) {
    if (parseFloat(previousInterest[index]) !== parseFloat(quoteAmounts.interest)) {
      previousInterest[index] = parseFloat(quoteAmounts.interest);
      setFieldValue(`quoteCarsAttributes[${index}].interest`, quoteAmounts.interest);
      changeInterestLoading[index] = false;
    }
  }

  if (values.quoteCarsAttributes && values.quoteCarsAttributes[index] && !values.quoteCarsAttributes[index]['feeDetails']) {
    values.quoteCarsAttributes[index]['feeDetails'] = [];
  }
  return quoteData
}

export const formatValues = (values, types) => {
  types.forEach((type) => {
    values.map((value, index) => {
      delete value.id;
      delete value.totalDue;

      if (type === 'principal' && value[type] === '') value[type] = 0;
      if (type === 'insurance' && value[type] === '') value[type] = 0;
      if (type === 'interest' && value[type] === '') value[type] = 0;

      if (value.paymentType === 'principal') {
        value.insurance = 0
        value.interest = 0
      }

      if (type === 'principal' && value['principal'] !== 0) {
        value['principal'] = parseFloat(value['principal']);
      }

    })
  })

  values.map((value, index) => {
    value['quoteFeesAttributes'] = [];
    value.feeDetails.forEach((fee) => {
      const id = Object.keys(fee)[0];
      if (fee[id].amount || fee[id].amount === 0) {
        value['quoteFeesAttributes'].push(
          {
            feeId: parseInt(id),
            amount: fee[id].amount,
          }
        )
      }
    })

    delete value['feeDetails'];
  })

  return values
}

export const PrincipalError = ({ values, index, disabledArray }) => {
  const currentPrincipal = parseFloat(values.quoteCarsAttributes[index].principal);
  const maxPrincipal = parseFloat(values.maxPrincipal[index]);

  if (!currentPrincipal || currentPrincipal <= maxPrincipal) {
    disabledArray[index] = false;
  }

  if (currentPrincipal > maxPrincipal) {
    disabledArray[index] = true;

    return (
      <tr>
        <td colspan="15">
          <StyledErrorMessage style={{ fontSize: '14px', marginLeft: '18%', marginTop: '0%' }}>
            Can't be more than the Principal amount owed. To pay additional principal, please make a
            separate payment for principal only.
          </StyledErrorMessage>
        </td>
      </tr>
    )
  }

  return null;
}
