import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import DealerInfo from '../DealerInfo';
import { Spacing, Typography } from '../../../styles';
import DataState from '../../DataState';
import { useAlert } from '../../../hooks/useAlert';
import { uccSchema, Form } from './Form';
import Loading from '../../../elements/Loading';
import { AUTH_TOKEN } from '../../../constants';
import secrets from '../../../secrets';
import { isoToday } from '../../../utils/dateHelpers';

const DEALER = gql`
query Dealer($id: ID!) {
  dealer(id: $id) {
    id
    name
    primaryPlace {
      id
      state
    }
    uccs {
      id
    }
  }
}
`
const CREATE_UCC = gql`
  mutation CreateUcc($dealerId: Int!, $filedOn: ISO8601Date!,
    $filingLocation: String!, $position: Int!,
    $securedPartyName: String!, $address: String!, $city: String!, $state: String!,
    $zip: String!, $pmsiReceived: ISO8601Date, $pmsiSent: ISO8601Date, $xlf: Boolean!
    $originalFileDate: ISO8601Date, $pmsiTrackingNumber: String, $securedPartyType: String,
    $securedPartyId: Int, $createPmsiLetter: Boolean) {
      createUcc (
        dealerId: $dealerId
        filedOn: $filedOn
        filingLocation: $filingLocation
        position: $position
        securedPartyName: $securedPartyName
        address: $address
        city: $city
        state: $state
        zip: $zip
        pmsiReceived: $pmsiReceived
        pmsiSent: $pmsiSent
        xlf: $xlf
        originalFileDate: $originalFileDate
        pmsiTrackingNumber: $pmsiTrackingNumber
        securedPartyType: $securedPartyType
        securedPartyId: $securedPartyId
        createPmsiLetter: $createPmsiLetter
      ) {
        ucc { id, position, securedPartyName, securedPartyId, securedPartyType, filingLocation, filedOn, expiresOn,
              address, city, state, zip, pmsiReceived, pmsiSent, xlf, originalFileDate,
              pmsiTrackingNumber }
        shipment { id, fileUpload { id, fileUrl } }
        errors { message, path }
      }
    }
`

const NewUCC = () => {
  const dismissAlert = () => displayAlert(false)
  const [showAlert, displayAlert] = useAlert();
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const history = useHistory();
  const dealerId = useParams().id;
  const [newUcc] = useMutation(
    CREATE_UCC,
    {
      update(cache, { data }) {
        setFormSubmitting(false);

        cache.writeQuery({
          query: DEALER,
          data: {
            dealer: {
              id: dealer.id,
              name: dealer.name,
              __typename: dealer.__typename,
              uccs: [...dealer.uccs, data.createUcc.ucc]
            }
          }
        })
        setRedirect(true);
      }
    }
  )

  const { loading, error, data } = useQuery(DEALER, { variables: { id: dealerId } })

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { dealer } = data
  const { uccs } = data.dealer

  const handleSubmit = (values) => {
    values.dealerId = parseInt(dealerId)

    if (values.createOption === 'create') {
      values.xlf = false
    }

    if (values.createOption === 'manual') {
      values.securedPartyType = ''
      values.securedPartyId = undefined
    }

    if (values.pmsiOption === 'create') {
      values.pmsi = ''
      values.pmsiSendReceiveDate = ''
      values.pmsiTrackingNumber = ''
    }

    if (values.pmsi === 'Sent') {
      values.pmsiSent = values.pmsiSendReceiveDate
      delete values.pmsi
      delete values.pmsiSendReceiveDate
    }

    if (values.pmsi === 'Received') {
      values.pmsiReceived = values.pmsiSendReceiveDate
      delete values.pmsi
      delete values.pmsiSendReceiveDate
    }

    if (values.pmsi === '') {
      values.pmsiReceived = null;
      values.pmsiSent = null;
    }

    if (values.pmsiTrackingNumber !== undefined &&
        values.pmsiTrackingNumber !== '') {
      values.pmsiTrackingNumber = values.pmsiTrackingNumber.replace(/ /g, "")
    }

    values.position = 1;

    if (uccs.length > 0) values.position = uccs.length + 1

    values.state = values.state.toUpperCase();

    if (values.pmsiOption === 'create' && values.createOption === 'create') {
      values.createPmsiLetter = true
    }

    delete values.pmsiOption
    delete values.createOption

    setFormSubmitting(true)
    newUcc({ variables: values });
  };

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/dealers/${dealerId}/uccs`,
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New UCC</h1>
          </div>
          <Formik
            initialValues={{
              dealerId,
              filedOn: isoToday(),
              filingLocation: dealer.primaryPlace.state,
              position: '',
              securedPartyName: '',
              address: '',
              city: '',
              state: '',
              zip: '',
              pmsi: '',
              pmsiSendReceiveDate: '',
              xlf: false,
              originalFileDate: isoToday(),
              pmsiTrackingNumber: '',
              createOption: 'create',
              pmsiOption: 'create',
              securedPartyType: '',
              securedPartyId: '',
              createPmsiLetter: false
            }}
            validationSchema={uccSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <>
        <div style={Spacing.formContainer}>
          <NewForm />
        </div>
      </>
    )
  }

  return (
    <DisplayContent />
  );
};

export default NewUCC;
