import { gql } from '@apollo/client';
const ERRORS = `errors { path, message }`;

const BANK_ACCOUNT_FIELDS = `
  id
  type
  accountName
  bankName
  routingNumberMasked
  accountNumberMasked
  createdBy { id, fullName }
  createdAt
  updatedAt
  hiddenAt
  canBeHidden
`;

const BANK_ACCOUNT_FIELDS_AND_ERRORS = `
  bankAccount {
    ${BANK_ACCOUNT_FIELDS}
  }
  ${ERRORS}
`;

export const BANK_ACCOUNTS_SUBQUERY = `
  bankAccounts(scopes: $scopes) {
    ${BANK_ACCOUNT_FIELDS}
  }
`;

export const HIDE_BANK_ACCOUNT = gql`
  mutation HideBankAccount($id: ID!) {
    hideBankAccount(
      id: $id
    ) {
      ${BANK_ACCOUNT_FIELDS_AND_ERRORS}
    }
  }
`;

export const UNHIDE_BANK_ACCOUNT = gql`
  mutation UnhideBankAccount($id: ID!) {
    unhideBankAccount(
      id: $id
    ) {
      ${BANK_ACCOUNT_FIELDS_AND_ERRORS}
    }
  }
`;
