import * as yup from 'yup';
import { phoneRegExp } from "../../utils/stringHelpers";

export const editAddressSchema = yup.object({
  shipToType: yup.string().required('Required'),
  shipToId: yup.string().nullable().when('shipToType', {
    is: 'ApprovedSource',
    then: yup.string().required('Required'),
    otherwise: yup.string().when('shipToType', {
      is: 'Auction',
      then: yup.string().required('Required'),
      otherwise: yup.string().when('shipToType', {
        is: 'DealerPlace',
        then: yup.string().required('Required')
      })
    })
  }),
  dealerId: yup.string().nullable().when('shipToType', {
    is: 'DealerPlace',
    then: yup.string().required('Required')
  }),
  otherAddress: yup.object().when('shipToType', {
    is: 'Other',
    then: yup.object({
      name: yup.string().nullable().required('Required'),
      street: yup.string().nullable().required('Required'),
      city: yup.string().nullable().required('Required'),
      state: yup.string().nullable().required('Required'),
      zip: yup.string().nullable().required('Required'),
      phone: yup.string().nullable().matches(phoneRegExp, {
        message: "Please enter a valid number.",
        excludeEmptyString: false,
      }).required('Required')
    })
  })
});
