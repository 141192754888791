import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const ADMIN_DOCUMENT_FIELDS = `
  id
  name
  fileUpload {
    id
    name
    fileUrl
  }
  updatedBy {
    fullName
  }
  updatedAt
  lastAction
  canBeHidden
  hiddenAt
`;

const ADMIN_DOCUMENT_FIELDS_AND_ERRORS = `
  adminDocument {
    ${ADMIN_DOCUMENT_FIELDS}
  }
  ${ERRORS}
`;

export const ADMIN_DOCUMENT = gql`
  query AdminDocument($id: ID!) {
    adminDocument(id: $id) {
      ${ADMIN_DOCUMENT_FIELDS}
    }
  }
`;

export const ADMIN_DOCUMENTS = gql`
  query AdminDocuments($scopes: [String!]) {
    adminDocuments(
      scopes: $scopes
    ) {
      ${ADMIN_DOCUMENT_FIELDS}
    }
  }
`;

export const CREATE_ADMIN_DOCUMENT = gql`
  mutation CreateAdminDocument(
    $name: String!
    $file: Upload!
  ) {
    createAdminDocument(
      name: $name
      file: $file
    ) {
      ${ADMIN_DOCUMENT_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_ADMIN_DOCUMENT = gql`
  mutation UpdateAdminDocument(
    $id: ID!
    $name: String
    $file: Upload
  ) {
    updateAdminDocument(
      id: $id
      name: $name
      file: $file
    ) {
      ${ADMIN_DOCUMENT_FIELDS_AND_ERRORS}
    }
  }
`;

export const HIDE_ADMIN_DOCUMENT = gql`
  mutation HideBranch($id: ID!) {
    hideAdminDocument(
      id: $id
    ) {
      ${ADMIN_DOCUMENT_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_ADMIN_DOCUMENT = gql`
  mutation UnhideBranch($id: ID!) {
    unhideAdminDocument(
      id: $id
    ) {
      ${ADMIN_DOCUMENT_FIELDS_AND_ERRORS}
    }
  }
`;
