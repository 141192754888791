import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import DataState from '../DataState';
import TxnLinesTable from 'components/src/components/Transactions/Lines/Table';
import FeeInfo from './FeeInfo';
import { FEE_FIELDS, TXN_LINE_FIELDS } from '../../queries/viewQueries';

const FEE_TXN_LINES = gql`
  query Fee($id: ID!) {
    fee(id: $id) {
      ${FEE_FIELDS}
      txnLines {
        ${TXN_LINE_FIELDS}
      }
    }
  }
`;

const txnLinesColumns = [
  'earnedOn', 'createdDate', 'dealer', 'flooredCar',
  'txn', 'purpose', 'gl', 'account', 'debit', 'credit',
  'earnsInterest', 'memo'
];

const FeeTransactions = () => {
  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(FEE_TXN_LINES, { variables: { id } });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { fee: { txnLines } } = data;

  return (
    <>
      <FeeInfo {...data.fee} />
      <TxnLinesTable
        txnLines={data.fee.txnLines}
        shownColumns={txnLinesColumns}
      />
    </>
  );
};

export default FeeTransactions;
