import React from 'react';
import { useParams } from 'react-router-dom';
import DealerInfo from '../DealerInfo';
import Notes from '../../Notes';
import { DEALER_NOTES } from '../Queries';

const DealerNotes = ({id}) => {
  const ownerId = useParams().ownerId;

  if (!id) {
    id = parseInt(ownerId);
  }

  return (
    <>
      {ownerId && <DealerInfo />}
      <Notes
        query={DEALER_NOTES}
        ownerType="dealer"
        ownerId={id}
        prefix={`/dealers/${id}/notes`}
      />
    </>
  );
};

export default DealerNotes;
