import * as Colors from 'components/src/styles/colors';

export const modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px',
}

export const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })
}
