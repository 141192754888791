import { gql } from '@apollo/client';

const ADDRESS_FIELDS = `
  street
  city
  state
  zip { value, formatted }
  address
  phone { value, formatted }
`;

export const UNSHIPPED_TITLE_LOGS = gql`
  query TitleLogs(
    $filterColumns: [String!]
    $filterNames: [JSON!]
  ) {
    titleLogs(
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: ["unshipped"]
    ) {
      id
      flooredCar {
        id
        dealer {
          id
          name
        }
        market {
          id
          name
        }
        vin
      }
      titleStatus {
        id
        name
      }
      createdBy {
        id
        fullName
      }
      trackingNumber
      notes
      shipTitle
      shipToType
      shipTo {
        id
        shipToName
        ${ADDRESS_FIELDS}
      }
    }
    markets {
      name
      id
    }
  }
`;

export const CURRENT_USER = gql`
  query {
    currentUser {
      id
      firstName
      lastName
      fullName
    }
  }
`;

export const CANCEL_SHIPMENT = gql`
  mutation CancelTitleShipment(
    $id: ID!
  ) {
    cancelTitleShipment(
      id: $id
    ) {
      titleLog {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

export const CREATE_SHIPMENT = gql`
  mutation CreateShipment(
    $checkedEntries: [String!]!
    $shippingMethod: String
  ) {
    createShipment(
      checkedEntries: $checkedEntries
      shippingMethod: $shippingMethod
    ) {
      shipments {
        id
        fileUpload {
          id
          fileUrl
        }
      }
      fileUpload {
        id
        fileUrl
      }
      errors { path, message }
    }
  }
`;

export const UPDATE_TITLE_LOG = gql`
  mutation UpdateTitleLog(
    $id: ID!
    $shipToId: Int
    $shipToType: String
    $otherAddress: AddressShipmentInput
  ) {
    updateTitleLog(
      id: $id
      shipToId: $shipToId
      shipToType: $shipToType
      otherAddress: $otherAddress
    ) {
      titleLog {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

export const SELECT_OPTIONS = gql`
  query {
    dealers {
      id
      name
      dealerPlaces(scopes: ["visible"]) {
        id
        name
        ${ADDRESS_FIELDS}
      }
      defaultMailingLocation {
        id
        name
        ${ADDRESS_FIELDS}
      }
    }
    approvedSources {
      id
      name
      ${ADDRESS_FIELDS}
    }
    auctions {
      id
      name
      ${ADDRESS_FIELDS}
    }
    titleStatuses {
      id
      name
      info
      isPresent
      toDealer
      autoFeeTitleAdded
      autoFeeTitleBorrowed
      autoFeeTitleShipped
      autoFeeTitleMarkedPresent
      fund
      createdBy { firstName, lastName }
    }
  }
`;
