import React from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import * as BasicForm from '../../../elements/forms';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';

export const categorySchema = yup.object({
  name: yup.string().required('Required')
});

export const Form = () => {
  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}
