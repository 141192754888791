import React, { useState, useEffect } from "react";
import * as BasicForm from "../../../../elements/forms";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { divStyle, inputStyle } from "./AdvanceType";
import { useFormikContext } from "formik";
import DataState from "../../../DataState";
import {
  AUCTION_BANK_ACCOUNTS,
  APPROVED_SOURCE_BANK_ACCOUNTS,
  DEALER_BANK_ACCOUNTS
} from 'components/src/components/Cars/Options/Queries';
import { ErrorMessage } from "../../../../elements/ErrorMessage";

const BankAccountInformation = () => {
  const { values, errors, setFieldValue } = useFormikContext();
  const id = parseInt(values.sourceId);

  if (!values.sourceId) return null;

  let queryType;
  if (values.sourceType === "Auction") queryType = AUCTION_BANK_ACCOUNTS;
  if (values.sourceType === "ApprovedSource")
    queryType = APPROVED_SOURCE_BANK_ACCOUNTS;
  if (values.sourceType === "Dealer") queryType = DEALER_BANK_ACCOUNTS;

  const { loading, error, data } = useQuery(queryType, { variables: { id } });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const queryData = Object.values(data)[0];

  const formattedBankAccounts = queryData.bankAccounts.map((account) => {
    const name = `${account.accountName} ${account.accountNumberMasked}`;

    return { id: account.id, name };
  });


  const defaultSelected = (formattedBankAccounts.length === 1)
    ? [formattedBankAccounts[0]]
    : [];

  if (defaultSelected.length > 0) {
    values.bankAccountId = parseInt(defaultSelected[0].id);
  }

  return (
    <BasicForm.AutoCompleteTextInput
        clearButton
        name="bankAccountId"
        type="text"
        defaultSelected={defaultSelected}
        options={formattedBankAccounts}
        labelKey="name"
        style={Object.assign({}, inputStyle, { width: "350px", paddingLeft: "10px" })}
        id="source-data"
        onChange={(selected) => {
          selected.length > 0
            ? setFieldValue("bankAccountId", parseInt(selected[0].id))
            : setFieldValue("bankAccountId", null);
        }}
      />
  );
};

export default BankAccountInformation;
