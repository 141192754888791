import React, { useState } from 'react';
import { useParams, useRouteMatch, Link } from 'react-router-dom';
import UploadPreview from 'components/src/elements/UploadPreview';

export const AddLotImage = (props) => {
  const { id }  = props;
  const containerStyle = {
    textAlign: 'center',
    border: '1px solid #AAAAAA',
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const linkStyle = {
    color: '#AAAAAA',
    textDecoration: 'none',
  }

  return <Link
    style={linkStyle}
      to={{
        pathname: `/dealers/${id}/new-lot-image`
      }}
    >
    <div className='add-first-image-container' style={containerStyle}>
      <h5 className='add-first-image-text'>Upload a Lot Image</h5>
    </div>
  </Link>
}

export const LotImage = (props) => {
  const { src, id, uploadId } = props;
  const [showPreview, setShowPreview] = useState(false);

  const containerStyle = {
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  }

  return (
    <>
      <div
        className="image-container"
        style={containerStyle}
        onClick={() => setShowPreview(true)}
      >
        <img src={src} className="lot-image" />
        <div className='overlay'></div>
      </div>

      <Link to={`/dealers/${id}/new-lot-image`}>
        Upload a New Lot Image
      </Link>

      {showPreview && (
        <UploadPreview
          handleClose={() => setShowPreview(false)}
          show={showPreview}
          id={uploadId}
        />
      )}
    </>
  );
};

export const renderLotImage = (src, id, uploadId) => {
  return (src)
    ? <LotImage src={src} id={id} uploadId={uploadId} />
    : <AddLotImage id={id} />
}
