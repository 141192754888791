import { gql } from '@apollo/client';

export const FILE_UPLOAD = gql`
  query FileUpload($id: ID!) {
    fileUpload(id: $id) {
      id
      fileUrl
      name
    }
  }
`;
