import React from 'react';
import { TouchableHighlight, View } from 'react-native';
import { Link } from 'react-router-dom';
import AuthLink from 'components/src/components/AuthLink';

import { setDate } from 'components/src/utils/dateHelpers';
import { currency, titleCase } from 'components/src/utils/stringHelpers';
import {
  sourcePath, canPrintReceipt, setupPrintReceiptHandler
} from 'components/src/components/Transactions/functions';

export const columnDefinitions = props => {
  const {
    columns,
    auth,
    referrerType,
    referrerId,
    setVoidModalTxn,
    setLinesModalTxn,
    setPrintReceiptTxn,
    printReceiptTxn,
    lastDownloadTime,
    setLastDownloadTime,
    lastDownloadId,
    setLastDownloadId,
  } = props;

  const definitions = [
    {
      key: 'bankDate',
      label: 'Bank Date',
      sort: 'desc',
      width: 120,
      content: (({ value }) => <span>{setDate(value)}</span>)
    },
    {
      key: 'createdDate',
      label: 'Created Date',
      width: 120,
      minViewportWidth: 1300,
      content: (({ value }) => <span>{setDate(value)}</span>)
    },
    {
      key: 'market',
      label: 'Market',
      minViewportWidth: 1200,
      content: (({ value }) => <span>{value?.name}</span>)
    },
    {
      key: 'dealer',
      label: 'Dealer',
      minViewportWidth: 800,
      content: (({ value: dealer }) => {
        if (!dealer) { return null };

        return (
          <AuthLink
            to={`/dealers/${dealer.id}`}
            resource="Dealer"
            action="read"
          >
            {dealer.name}
          </AuthLink>
        );
      })
    },
    {
      key: 'dealerNumber',
      label: 'Dealer Number',
      width: 100,
      minViewportWidth: 1600,
      content: (({ entry }) => <span>{entry.dealer?.accountNumber}</span>)
    },
    {
      key: 'name',
      label: 'Name',
      content: (({ entry }) => {
        if (entry.entity) {
          return (
            <Link to={sourcePath(entry.entity)}>
              {entry.name || entry.entity.name}
            </Link>
          );
        } else {
          return <span>{entry.name}</span>;
        }
      })
    },
    {
      key: 'source',
      label: 'Source',
      minViewportWidth: 1000,
      content: (({ entry }) => {
        if (entry.bankAccount?.owner) {
          const owner = entry.bankAccount.owner;
          return <Link to={sourcePath(owner)}>{owner.name}</Link>;
        } else {
          return <span>{entry.sourceName}</span>;
        }
      })
    },
    {
      key: 'type',
      label: 'Type',
      width: 140,
    },
    {
      key: 'reference',
      label: 'Reference',
      width: 180,
      minViewportWidth: 1200,
    },
    {
      key: 'purpose',
      label: 'Purpose',
      minViewportWidth: 1400,
      content: (({ entry: txn }) => {
        let purpose = txn.purpose;

        if (['Void', 'NSF', 'Reverse'].includes(purpose) && txn.voidOf) {
          return (
            <span style={{ color: 'red' }}>
              {`${purpose} of `}
              <AuthLink
                to={`/transactions/${txn.voidOf.id}`}
                resource="Txn"
                action="read"
              >
                {txn.voidOf.type} {txn.voidOf.reference} {txn.voidOf.purpose}
              </AuthLink>
            </span>
          );
        } else if (txn.voidBy) {
          return (
            <span style={{ color: 'red' }}>
              {purpose}{' '}
              {'Reverse' === txn.voidBy.purpose ? 'Reversed ' : 'Voided '}
              <AuthLink
                to={`/transactions/${txn.voidBy.id}`}
                resource="Txn"
                action="read"
              >
                {setDate(txn.voidBy.bankDate)}
              </AuthLink>
            </span>
          );
        }

        return <span>{ purpose }</span>;
      })
    },
    {
      key: 'amount',
      label: 'Amount',
      width: 120,
      content: (({ value }) => (
        <span style={{textAlign: 'right'}}>
          {currency(value)}
        </span>
      ))
    },
    {
      key: 'dealerCount',
      label: 'Dealers',
      sort: 'prevent',
    },
    {
      key: 'flooredCarCount',
      label: 'Floored Cars',
      sort: 'prevent',
    },
    {
      key: 'dealers',
      label: 'Dealers',
      sort: 'prevent',
      content: (({ value }) => {
        return value.map(dealer => {
          return (
            <>
              <AuthLink
                key={dealer.id}
                to={`/dealers/${dealer.id}`}
                resource="Dealer"
                action="read"
              >
                {dealer.name}
              </AuthLink>
              <br />
            </>
          );
        })
      })
    },
    {
      key: 'flooredCars',
      label: 'Cars',
      sort: 'prevent',
      minViewportWidth: 1200,
      content: (({ value }) => {
        return value.map(car => {
          return (
            <>
              <AuthLink
                key={car.id}
                to={`/dealers/${car.dealer.id}/cars/${car.id}`}
                resource="FlooredCar"
                action="read"
              >
                {car.vin}
              </AuthLink>
              <br />
            </>
          );
        })
      }),
      width: 200,
    },
    {
      key: 'createdBy',
      label: 'Created By',
      width: 160,
      minViewportWidth: 1400,
      content: (({ value }) => <span>{value?.fullName}</span>)
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      width: (referrerId ? 380 : null),
      content: (({ entry }) => {
        const itemStyle = {
          paddingLeft: 5,
          paddingRight: 5,
          borderLeft: '1px solid black',
          display: 'inline',
          cursor: 'pointer',
        };

        const showLinesLink = !!(
          referrerType && referrerId &&
          auth.hasExactPolicy('Txn', 'view_accruals')
        );

        if (canPrintReceipt(entry, auth)) {
          setupPrintReceiptHandler(
            entry,
            lastDownloadTime, setLastDownloadTime,
            lastDownloadId, setLastDownloadId, setPrintReceiptTxn
          );
        }

        return (
          <div style={{display: 'block'}}>
            <AuthLink
              style={{paddingRight: 5}}
              to={`/transactions/${entry.id}?referrerType=${referrerType}&referrerId=${referrerId}`}
              resource="Txn"
              action="read"
            >View</AuthLink>

            {
              showLinesLink &&
                <div style={itemStyle} onClick={() => setLinesModalTxn(entry)}>
                  View {titleCase(referrerType)} Lines
                </div>
            }

            {
              auth.hasPolicy('Txn', 'update') && entry.voidable &&
                <div style={itemStyle} onClick={() => setVoidModalTxn(entry)}>
                  Void
                </div>
            }

            {
              canPrintReceipt(entry, auth) && auth.hasPolicy('Txn', 'read') &&
                <div
                  style={itemStyle}
                  className={`receipt-print${entry.quote.id}`}
                  id={entry.quote.id}
                >
                  Print Receipt
                </div>
            }
          </div>
        );
      })
    }
  ];

  return definitions.filter(column => columns.includes(column.key));
};
