import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import DataState from '../../DataState';
import { Spacing, Typography } from '../../../styles';
import { Formik } from 'formik';
import { reconcileSchema, Form } from './Form';

const RECONCILE = gql`
  query RECONCILE($id: ID!) {
    reconcile(id: $id) {
      account {
        id
      }
      startOn
      endOn
      startBalance
      endBalance
      completedAt
    }
  }
`;

const UPDATE_RECONCILE = gql`
  mutation UpdateReconcile(
    $id: ID!
    $accountId: Int
    $startOn: ISO8601Date!
    $endOn: ISO8601Date!
    $startBalance: Decimal
    $endBalance: Decimal
  ) {
    updateReconcile(
      id: $id
      accountId: $accountId
      startOn: $startOn
      endOn: $endOn
      startBalance: $startBalance
      endBalance: $endBalance
    ) {
      reconcile {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const Edit = () => {
  const id = parseInt(useParams().id);
  const history = useHistory();

  const { loading, error, data } = useQuery(RECONCILE, { variables: { id } });
  const [updateReconcile] = useMutation(UPDATE_RECONCILE,
    {
      onCompleted() {
        history.push({
          pathname: `/financial/reconciles`,
          state: { alert: true }
        })
      }
    }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const accountId = parseInt(data.reconcile.account.id);

  const handleSubmit = (values) => {
    updateReconcile({ variables: values })
  };

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Update Bank Reconcile</h1>
        </div>
        <Formik
          initialValues={{
            id: id,
            accountId: accountId,
            startBalance: data.reconcile.startBalance,
            endBalance: data.reconcile.endBalance,
            startOn: data.reconcile.startOn,
            endOn: data.reconcile.endOn
          }}
          validationSchema={reconcileSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <>
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    </>
  );
};

export default Edit;
