import React, { useState, useEffect, useContext } from 'react';
import DataState from '../../DataState';
import DataTables from '@preflighttech/preflight-tables';
import { Helmet } from 'react-helmet';
import AuthContext from '../../../contexts/AuthContext';
import AuthLink from 'components/src/components/AuthLink';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import * as Buttons from '../../../elements/buttons';
import { useAlert } from '../../../hooks/useAlert';
import * as yup from 'yup';
import { Colors } from '../../../styles';
import { titleCase } from '../../../utils/stringHelpers';
import { BaseRateOptions } from './BaseRateOptions';
import { createBrowserHistory } from 'history';
import { Typography, Spacing } from '../../../styles';
import moment from 'moment';
import '../../../styles/ratePrograms.css';
import { DateHelpers } from '../../../utils';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { preflightTableStyles } from 'components/src/styles';

const UPDATE_RATE_PROGRAM = gql`
  mutation UpdateRateProgram($id: ID!, $name: String!, $description: String) {
    updateRateProgram(
      id: $id
      name: $name
      description: $description
    ) {
      rateProgram {
        id
        name
        description
      }
      errors {
        message
        path
      }
    }
  }`

const RATE_PROGRAM = gql`
  query PROGRAM($id: ID!) {
    rateProgram(id: $id) {
      id
      name
      description
      baseRate { id }
      createdAt
      updatedAt
      hiddenAt
      rateProgramChanges {
        id
        rate
        effectiveOn
        createdBy { fullName }
        updatedBy { fullName }
      }
      currentRateProgramChange {
        rate
      }
    }
    baseRates {
      id
      name
    }
  }
`

const RateProgram = (props) => {
  const { id } = useParams();
  const newRoute = `/system/rate-programs/${id}/changes/new`
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  const customSubmitHandler = (values) => {
    values.baseRateId = parseInt(values.baseRateId)
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_RATE_PROGRAM,
    onCompleteRedirectTo: '/system/rate-programs',
    customSubmitHandler: customSubmitHandler
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const { loading, error, data } = useQuery(RATE_PROGRAM, { variables: { id } });
  const auth = useContext(AuthContext);

  useEffect(() => {
    const history = createBrowserHistory();

    if (props.location.state && props.location.state.alert) {
      if (props.location.state.type) setAlertType(props.location.state.type)

      displayAlert();
      let state = { ...history.location.state };
      delete state.alert
      delete state.type
      history.replace({ ...history.location, state })
    }
  }, [])

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { baseRates, rateProgram } = data;

  // Sort by effectiveOn
  rateProgram.rateProgramChanges = rateProgram.rateProgramChanges.sort(function (a, b) {

    return new Date(b.effectiveOn) - new Date(a.effectiveOn);
  });

  const changeColumns = [
    {
      key: 'rate',
      label: 'Rate',
      value: ({value}) => `${value}%`,
    },
    {
      key: 'effectiveOn',
      label: 'Effective On',
      content: ({value}) => DateHelpers.setDate(value),
    },
    {
      key: 'createdBy',
      label: 'Created By',
      value: ({value}) => value.fullName,
    },
    {
      key: 'updatedBy',
      label: 'Updated By',
      value: ({value}) => value.fullName,
    },
    {
      key: 'actions',
      label: 'Actions',
      content: ({ entry }) => {
        const noEdit = new Date(entry.effectiveOn) <= new Date();

        return (
          <ResourceActionLinks
            prefix={`/system/rate-programs/${id}/changes`}
            resource={entry}
            noRead
            noEdit={noEdit}
          >
            <AuthLink
              style={{paddingLeft: 5}}
              to={`/system/rate-programs/${id}/changes/${entry.id}/deltas`}
              resource="Delta"
              action="read"
            >
              {noEdit ? '' : '| '} Deltas
            </AuthLink>
          </ResourceActionLinks>
        )
      }
    }
  ];

  const currentRate = rateProgram.currentRateProgramChange?.rate;

  const schema = yup.object({
    name: yup.string().max(80).required('Required'),
    baseRateId: yup.number().required('Required')
  });

  const initialValues = {
    id: rateProgram.id,
    name: rateProgram.name || '',
    baseRateId: rateProgram.baseRate.id || '',
    description: rateProgram.description || ''
  }

  function RateProgramForm() {
    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          isInvalid,
          errors,
          setValues
        }) => (

            <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
              <div style={Spacing.formWrapper}>
                <div style={Spacing.formHeader}>
                  <h1 style={Typography.formHeader}>{`Update Rate Program: ${titleCase(rateProgram.name)}`}</h1>
                </div>
              </div>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.description && !errors.description}
                  isInvalid={!!errors.description && touched.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Base Rate</Form.Label>
                <Form.Control
                  as="select"
                  name="baseRateId"
                  value={values.baseRateId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.baseRateId && !errors.baseRateId}
                  isInvalid={!!errors.baseRateId && touched.baseRateId}
                  disabled
                >
                  <option value=""></option>
                  <BaseRateOptions baseRates={baseRates} />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.baseRateId}
                </Form.Control.Feedback>
              </Form.Group>
              <div style={Spacing.buttonRow}>
                <Buttons.Cancel type="cancel" text="Cancel" />
                <Buttons.Standard
                  type="submit"
                  disabled={!isValid || formSubmitting}
                  text="Submit"
                />
              </div>
            </Form>
          )}
      </Formik>
    )
  }

  const buttonColor = {
    backgroundColor: Colors.xlGreen,
    borderColor: Colors.xlGreen,
    color: 'white'
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <>
        <ServerSideErrors />
        <RateProgramForm />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Edit {titleCase(rateProgram.name)} Rate Program</title>
      </Helmet>
      <DisplayContent />
      <hr />
      <ResourcesHeader
        title="Rate Program Changes"
        subtitle={`Current Rate: ${currentRate || 0}%`}
        prefix={`/system/rate-programs/${id}/changes`}
        resourceType="RateProgramChange"
      />
      <DataTables.Simple
        data={data.rateProgram.rateProgramChanges}
        columns={changeColumns}
        pageLength={10}
        htmlTable
        movableColumns
        multiSort
        styles={preflightTableStyles}
      />
    </>
  );
};

export default RateProgram;
