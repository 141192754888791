import React, { useState } from 'react';
import * as yup from "yup";
import { Form as FormikForm, useFormikContext } from "formik";
import * as BasicForm from "../../../elements/forms";
import * as Buttons from "../../../elements/buttons";
import { Spacing } from "../../../styles";
import * as StringHelpers from "../../../utils/stringHelpers";

export const contactSchema = yup.object({
  firstName: yup.string().required("Required"),
  primary: yup.boolean().required("Required"),
  email: yup.string().email("Please enter a valid email address"),
  mobilePhone: yup
    .string()
    .matches(StringHelpers.phoneRegExp, {
      message: "Please enter a valid phone number.",
      excludeEmptyString: false,
    }),
  officePhone: yup
    .string()
    .matches(StringHelpers.phoneRegExp, {
      message: "Please enter a valid phone number.",
      excludeEmptyString: false,
    }),
});

export const Form = () => {
  const { values, setFieldValue, isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput name="firstName" type="text" label="First Name" />
      <BasicForm.TextInput name="lastName" type="text" label="Last Name" />
      <BasicForm.TextInput name="email" type="email" label="Email Address" />
      <BasicForm.TextInput name="title" type="text" label="Title" />
      <BasicForm.PhoneInput
        name="officePhone"
        label="Office Phone"
        onChange={(event) =>
          StringHelpers.handleNumberChange(event, "officePhone", setFieldValue)
        }
        value={values.officePhone || ''}
      />
      <BasicForm.PhoneInput
        name="mobilePhone"
        label="Mobile Phone"
        onChange={(event) =>
          StringHelpers.handleNumberChange(event, 'mobilePhone', setFieldValue)
        }
        value={values.mobilePhone || ''}
      />
      <BasicForm.Checkbox
        name="primary"
        type="checkbox"
        label="Primary Contact?"
      />
      <BasicForm.Checkbox
        name="receivesPaymentInformation"
        type="checkbox"
        label="Send Outgoing Payment Information?"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
};
