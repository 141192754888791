export const shipStyle = {
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingLeft: '500px',
  paddingRight: '500px'
}

export const selectStyle = {
  paddingLeft: '20px',
  paddingTop: '15px',
  paddingRight: '1200px',
  paddingBottom: '10px'
}

export const errorSelectionStyle = {
  fontWeight: 'bold',
  color: '#A1473F',
  fontStyle: 'italic',
  fontSize: '16px',
  marginTop: '-30px'
}
