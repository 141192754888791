import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { gql, useMutation } from '@apollo/client';
import { Spacing } from '../../../styles';
import { feeSchema, Form} from './Form';
import { Formik } from 'formik';
import DataState from '../../DataState';

const CREATE_FEE = gql`
  mutation CreateFee($dealerId: Int!, $flooredCarId: Int, $termType: String, $amount: Decimal!, $incomeAccountId: Int!, $name: String!) {
    createFee(
      dealerId: $dealerId
      flooredCarId: $flooredCarId
      termType: $termType
      amount: $amount
      incomeAccountId: $incomeAccountId
      name: $name
    ) {
      errors {
        message
        path
      }
    }
  }
`

const FeeModal = (props) => {
  let { setShowModal, showModal, dealerId, flooredCarId, handleClose } = props;

  const [createFee, { loading, error }] = useMutation(CREATE_FEE, {
    onCompleted(values) {
      setShowModal(false)
      window.location.reload();
    }
  });

  const handleSubmit = (values) => {
    createFee({variables: values})
  }
  const carId = parseInt(flooredCarId);

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <div style={Spacing.formWrapper}>
      <Formik
        initialValues={{
          dealerId: dealerId,
          flooredCarId: carId,
          termType: '',
          amount: '',
          incomeAccountId: 42,
          name: ''
        }}
        onSubmit={handleSubmit}
        validationSchema={feeSchema}
      >
        <Form
          showModal={showModal}
          setShowModal={setShowModal}
          handleSubmit={handleSubmit}
        />
      </Formik>
    </div>
  )
}


export default FeeModal;
