import { gql } from '@apollo/client';

export const SOLD = gql`
  mutation Sold(
    $id: ID!
    $soldOn: ISO8601Date!
    $dateMarkedSold: ISO8601Date!
    $soldTo: String
    $saleType: String
    $financeCompanyName: String
    $soldToAuctionId: Int
    $other: String
  ) {
    sold(
      id: $id
      soldOn: $soldOn
      dateMarkedSold: $dateMarkedSold
      soldTo: $soldTo
      saleType: $saleType
      financeCompanyName: $financeCompanyName
      soldToAuctionId: $soldToAuctionId
      other: $other
    ) {
      errors {
        message
        path
      }
    }
  }
`;

export const AUCTIONS = gql`
  query {
    auctions {
      id
      name
    }
  }
`;

export const FLOORED_CAR = gql`
  query FLOORED_CAR($id: ID!) {
    flooredCar(id: $id) {
      soldOn
      dateMarkedSold
      soldTo
      saleType
      financeCompanyName
      soldToAuctionId
      other
    }
  }
`

export const UPDATE_SOLD = gql`
  mutation UpdateSold(
    $id: ID!
    $soldOn: ISO8601Date!
    $soldTo: String
    $saleType: String
    $financeCompanyName: String
    $soldToAuctionId: Int
    $other: String
  ) {
    updateSold(
      id: $id
      soldOn: $soldOn
      soldTo: $soldTo
      saleType: $saleType
      financeCompanyName: $financeCompanyName
      soldToAuctionId: $soldToAuctionId
      other: $other
    ) {
      errors {
        message
        path
      }
    }
  }
`
