import React from "react";
import { useParams } from 'react-router-dom';
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import Deltas from "../../Deltas/";
import DataState from '../../DataState';

const AUCTION = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      deltas {
        id
        action
        data
        isImport
        createdAt
        updatedAt
        user
      }
    }
  }
`;

const index = () => {
  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(AUCTION, { variables: { id } });

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

 const { deltas } = data.auction;

  return <Deltas
    deltas={deltas}
   />;
};

export default index;
