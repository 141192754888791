import { gql } from '@apollo/client';

export const AUTOMATED_FEE_NAMES = gql`
  query NoUserLogAutomatedFeeNames {
    automatedFees { id, name }
  }
`;

export const DEALER_SELECTS = gql`
  query NoUserLogDealerSelects {
    branches { id, name }
    markets { id, name }
    dealerStatuses { id, name }
    users { id, firstName, lastName }
    dealerPrograms { id, name }
    ratePrograms { id, name }
    automatedFees { id, name }
    dealerPrograms { id, name }
    dealers { id, name }
    dealerPeople { id, firstName, lastName }
    dealerCategories { id, name }
  }
`;

export const DEALER_NAMES = gql`
  query NoUserLogDealerNames {
    dealers { id, name }
  }
`;

export const USER_SELECTS = gql`
  query NoUserLogUserSelects {
    users { id, firstName, lastName }
  }
`;

export const ACCOUNT_SELECTS = gql`
  query NoUserLogAccountSelects {
    accounts { id, gl, name }
    specialAccounts {
      key
      name
    }
    accountGroups { id, name }
  }
`;

export const BANK_ACCOUNT_SELECT = gql`
  query NoUserLogBankAccountSelect {
    accounts {
      id
      gl
      name
      reconciles {
        id
        account {
          id
        }
        endBalance
      }
    }
  }
`;

export const INSURANCE_STATE_SELECTS = gql`
  query NoUserLogInsuranceStateSelects {
    insurancePrograms {
      id
      name
    }
  }
`;
