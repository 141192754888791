import React from 'react';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { useFormikContext } from 'formik';

export const Form = ({ handleSubmit, handleClose, isSubmitting}) => {
  const { values } = useFormikContext();

  return (
    <>
      <BasicForm.TextArea
        name='memo'
        label='Payment Assistance Memo'
      />
      <div style={Spacing.buttonRow}>
        <Buttons.ModalCancel
          type='cancel'
          text='Cancel'
          handleClose={handleClose}
        />
        <Buttons.Standard
          type='submit'
          onClick={() => handleSubmit(values)}
          text='Submit'
          disabled={isSubmitting}
        />
      </div>
    </>
  );
}
