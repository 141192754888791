import React, { useState, Fragment } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from "formik";
import { useHistory } from 'react-router-dom';
import { Spacing, Typography } from "../../../styles";
import { paymentTermSchema, Form } from "./Form";
import { useServerSideErrors } from "../../../hooks/useServerSideErrors";
import Loading from "../../../elements/Loading";
import DataState from '../../DataState';
import { PAYMENT_TERMS } from './index';

const UPDATE_PAYMENT_TERM = gql`
  mutation UpdatePaymentTerm(
    $id: ID!,
    $name: String!,
    $days: Int!
  ) {
    updatePaymentTerm(
      id: $id
      name: $name
      days: $days
     ) {
      paymentTerm { name, days }
      errors {
        message
        path
      }
    }
  }
`

const PAYMENT_TERM = gql`
query PAYMENT_TERM($id: ID!) {
  paymentTerm(id: $id) {
    id
    name
    days
  }
}`


const UpdatePaymentTerm = () => {
  const history = useHistory();
  const { id } = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [updatePaymentTerm, { errors }] = useMutation(UPDATE_PAYMENT_TERM, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PAYMENT_TERMS,
      }
    ],
   onCompleted(values) {
      setFormSubmitting(false);
      history.push({
        pathname: '/system/payment-terms',
        state: { alert: true, type: 'created' }
      })
    }
  })

  const { loading, error, data } = useQuery(PAYMENT_TERM, { variables: { id } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { paymentTerm } = data;

  const handleSubmit = (values) => {
    setFormSubmitting(true);

    updatePaymentTerm({ variables: values })
  }

  const Edit = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>
            {`Edit Payment Term: ${paymentTerm.name}`}
          </h1>
        </div>
        <Formik
          initialValues={{
            id,
            name: paymentTerm.name || '',
            days: paymentTerm.days || 0,
          }}
          validationSchema={paymentTermSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    );
  };

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <Edit />
      </div>
    );
  };

  return (
    <Fragment>
      <DisplayContent />
    </Fragment>
  );
};

export default UpdatePaymentTerm;
