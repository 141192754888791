import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { UserContext } from 'components/src/contexts/UserContext';
import { dashboardColumn } from 'components/src/styles/formatting';
import { useAlert } from 'components/src/hooks/useAlert';
import BasicAlert from 'components/src/elements/BasicAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import DataState from 'components/src/components/DataState';
import Credit from './Credit';
import Dealers from './Dealers';
import Collections from './MarketStats/Collections';
import Tasks from './MarketStats/Tasks';
import MarketStats from './MarketStats';
import KPIs from './KPIs';
import { MARKET_STATS } from './MarketStats/queries';

const containerStyle = {
  width: '100%',
  maxWidth: '100%'
}

const rowStyle = {
  minHeight: '340px'
}

const Home = (props) => {
  const user = useContext(UserContext);

  const { loading, error, data } = useQuery(
    MARKET_STATS,
    { variables: { marketIds: user.filterMarketIds() } }
  );

  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const marketName = user.currentMarket.name || 'Corporate';

  const componentType = props.location.state?.componentType;

  return (
    <>
      <Helmet>
        <title>{marketName} Home</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType={componentType} type={alertType} />
      }
      <h1>{marketName} Home</h1>

      <Container fluid="lg" style={containerStyle}>
        <Row style={{minHeight: '220px'}}>
          <Credit />
          <MarketStats stats={data.marketStats} />
          <Dealers stats={data.marketStats} />
          <Collections stats={data.marketStats} />
        </Row>

        <Row>
          <Tasks stats={data.marketStats} />
        </Row>

        <Row style={rowStyle}>
          <Col className="dashboard-column" xs={12}>
            <KPIs />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
