import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const AUTOMATED_FEE_FIELDS = `
  id
  name
  action
  flagAmount
  active
  createdBy { fullName }
  updatedBy { fullName }
  account { id }
  feeAmount
  hiddenAt
  canBeHidden
`;

const AUTOMATED_FEE_FIELDS_AND_ERRORS = `
  automatedFee {
    ${AUTOMATED_FEE_FIELDS}
  }
  ${ERRORS}
`;

export const AUTOMATED_FEES = gql`
  query AutomatedFees($scopes: [String!]) {
    automatedFees(
      scopes: $scopes
    ) {
      ${AUTOMATED_FEE_FIELDS}
    }
  }
`;

export const HIDE_AUTOMATED_FEE = gql`
  mutation HideAutomatedFee($id: ID!) {
    hideAutomatedFee(
      id: $id
    ) {
      ${AUTOMATED_FEE_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_AUTOMATED_FEE = gql`
  mutation UnhideAutomatedFee($id: ID!) {
    unhideAutomatedFee(
      id: $id
    )
    {
      ${AUTOMATED_FEE_FIELDS_AND_ERRORS}
    }
  }
`;
