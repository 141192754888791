import { gql } from '@apollo/client'

export const TEMP_PROGRAM = gql`
  query TempProgram($id: ID!) {
    tempProgram(id: $id) {
      id
      dealerLine {
        id
        dealer {
          id
        }
      }
      programName
      programType
      effectiveOn
      endOn
      creditLimitIncrease
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_TEMP_PROGRAM = gql`
  mutation UpdateTempProgram(
    $id: ID!
    $dealerLineId: Int!
    $effectiveOn: ISO8601Date!
    $endOn: ISO8601Date!
  ) {
    updateTempProgram(
      id: $id
      dealerLineId: $dealerLineId
      effectiveOn: $effectiveOn
      endOn: $endOn
    ) {
      errors {
        message
        path
      }
    }
  }
`;

export const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      tempPrograms {
        id
        dealerLine {
          id
          dealer {
            id
          }
        }
        programName
        programType
        effectiveOn
        endOn
        creditLimitIncrease
        createdBy {
          id
          fullName
          firstName
          lastName
        }
      }
    }
  }
`;

export const CREATE_TEMP_PROGRAM = gql`
  mutation CreateTempProgram(
    $dealerLineId: Int!
    $rateProgramId: Int
    $dealerProgramId: Int
    $effectiveOn: ISO8601Date!
    $endOn: ISO8601Date!
    $creditLimitIncrease: Float
    $automatedFeeIds: [Int!]
  ) {
    createTempProgram(
      dealerLineId: $dealerLineId
      rateProgramId: $rateProgramId
      dealerProgramId: $dealerProgramId
      effectiveOn: $effectiveOn
      endOn: $endOn
      creditLimitIncrease: $creditLimitIncrease
      automatedFeeIds: $automatedFeeIds
    ) {
      errors {
        message
        path
      }
    }
  }
`;
