import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import RenderForm from './Form';
import { Spacing, Typography } from '../../../styles';
import DataState from '../../DataState';
import { titleCase } from '../../../utils/stringHelpers';
import HeaderOptions from './HeaderOptions';
import { setISODate } from '../../../utils/dateHelpers';
import filter from './functions/filter';
import setFilterOptions from './functions/setFilterOptions';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'
import Loading from '../../../elements/Loading';
import TxnsPayload from './txnsPayload';
import { PrintForm } from '../../Transactions/functions';

const TXN_LINE_FIELDS = `
  id
  txn {
    name
    reference
    bankDate
  },
  amountRemaining
`;

export const UNPAID_BILLS = gql`
  query {
    txnLines(scopes: ["unpaid_bills"]) {
      ${TXN_LINE_FIELDS}
    }
  }
`;

const PAY_BILLS = gql`
  mutation PayBills(
    $txnLineAmounts: [TxnLineAmountInput!]!
    $accountId: Int!
    $txnsPerVendor: String
    $type: String!
    $bankDate: ISO8601Date!
    $memo: String
  ) {
    payBills(
      txnLineAmounts: $txnLineAmounts
      accountId: $accountId
      txnsPerVendor: $txnsPerVendor
      type: $type
      bankDate: $bankDate
      memo: $memo
    ) {
      txns { id, type, reference, memo, amount, bankDate, account { nextCheckNumber } }
      txnLines {
        ${TXN_LINE_FIELDS}
      }
      errors {
        message
        path
      }
    }
  }
`

const UnpaidBills = (props) => {
  const [showCheckModal, setShowCheckModal] = useState(false)
  const [redirect, setRedirect] = useState(false);
  const [paymentButton, setPaymentButton] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');
  const bankDateValue = useRef(null);
  const options = useRef({
    bankDate: setISODate(new Date()),
    memo: '',
    txnsPerVendor: 'single',
    bankAccount: '',
    type: 'Check',
  });

  const serverSideErrorProps = {
    SAVE_MUTATION: PAY_BILLS,
    REFETCH_QUERY: UNPAID_BILLS,
  }

  const [ServerSideErrors, handleSubmit, formSubmitting, returnData] = useServerSideErrors(serverSideErrorProps);

  const formatSubmitValues = (values) => {
    values.type = options.current.type;
    values.accountId = parseInt(options.current.bankAccount);
    values.txnsPerVendor = options.current.txnsPerVendor;
    values.bankDate = options.current.bankDate;
    values.memo = options.current.memo;

    values.txnLineAmounts = values.txnLineAmounts.filter((value) => {
      if (value.active) {
        delete value.active;
        return value;
      }
    })

    handleSubmit(values);
  }

  const [orderedData, setOrderedData] = useState();
  const [rawData, setRawData] = useState(null);
  const [filteredData, setFilteredData] = useState({
    payee: '',
    dueDate: ['', '']
  });

  useEffect(() => {
    if (!orderedData || !rawData) return;
    if (filteredData.payee === '' && filteredData.dueDate.includes(''))  {
      setOrderedData(rawData);
      return;
    }

    let filteredOrderedData = orderedData;

    if (filteredData.payee !== '' || !filteredData.dueDate.includes('')) {
      filteredOrderedData = filter(rawData, filteredData);
    }

    setOrderedData(filteredOrderedData);
  }, [filteredData])

  const { loading, error, data } = useQuery(UNPAID_BILLS);

  if (loading) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  let txnLines;

  if (returnData && returnData.payBills.txnLines) {
    txnLines = returnData.payBills.txnLines;
    returnData.payBills.txnLines = undefined;
    setOrderedData(txnLines);
    setRawData(txnLines);
  } else {
    txnLines = data.txnLines;
  }

  // set ordered Data on first render
  if (!orderedData) setOrderedData(txnLines);
  if (!rawData) setRawData(txnLines);
  if (!rawData) return null;

  const [uniqPayees] = setFilterOptions(orderedData, 'name')

  const filterOptions = {
    payeeFilter: uniqPayees,
  }

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/financial/unpaid-bills",
          state: { alert: true, type: 'paid' }
        }}
      />
    }

    return (
      <Fragment>
        <div style={Object.assign({}, Spacing.formWrapper, {marginTop: '0'})}>
        <ServerSideErrors />
          <Formik
            initialValues={{
              txnLineAmounts: [],
            }}
            validationSchema={null}
          >
            <Fragment>
              <RenderForm
                data={orderedData}
                setOrderedData={setOrderedData}
                setPaymentButton={setPaymentButton}
                handleSubmit={formatSubmitValues}
                serverSideErrors={serverSideErrors}
                rawData={rawData}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                filterOptions={filterOptions}
                bankDateValue={bankDateValue}
              />
            </Fragment>
          </Formik>
        </div>
      </Fragment>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    }

    return (
      <Fragment>
      {
        showCheckModal &&
          <PrintForm
            showCheckModal={showCheckModal}
            setShowCheckModal={setShowCheckModal}
          />
      }
        { returnData && <TxnsPayload showCheckModal={showCheckModal} setShowCheckModal={setShowCheckModal} txns={returnData.payBills.txns}/> }
        <div style={Object.assign({}, Spacing.formWrapper, { marginLeft: '1%' })}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Unpaid Bills</h1>
          </div>
          <HeaderOptions options={options} bankDateValue={bankDateValue} />
          <div style={Spacing.formContainer}>
            <NewForm />
           </div>
        </div>
      </Fragment>
    );
  }

  return (
    <DisplayContent />
  )
};

export default UnpaidBills;
