import React, { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { titleCase } from '../../../utils/stringHelpers';
import { Helmet } from 'react-helmet';
import DataState from '../../DataState';
import DataTables from '@preflighttech/preflight-tables';
import AuthContext from 'components/src/contexts/AuthContext';
import { AUDIT } from './queries';
import * as Formatting from 'components/src/styles/formatting';
import * as Buttons from 'components/src/elements/buttons';
import { Container, Row, Col } from 'react-bootstrap';
import { setDate } from 'components/src/utils/dateHelpers';
import { preflightTableStyles, Typography } from 'components/src/styles';
import CloseAuditModal from './CloseAuditModal';

export const columns = [
  {
    key: 'flooredCar',
    label: 'Car',
    value: ({ value }) => value.name,
  },
  {
    key: 'vin',
    label: 'VIN',
    value: ({ entry }) => entry.flooredCar.vin,
    content: ({ entry, value }) => (
      <Link to={entry.flooredCar.webPath}>{value}</Link>
    ),
  },
  {
    key: 'unitNumber',
    label: 'Unit #',
    value: ({ entry }) => entry.flooredCar.unitNumber,
  },
  {
    key: 'qtFound',
    label: 'Found',
  },
  {
    key: 'qtException',
    label: 'Exception',
  },
  {
    key: 'qtStatus',
    label: 'Status',
  },
  {
    key: 'qtNotes',
    label: 'Notes',
  },
  {
    key: 'qtCustomerName',
    label: 'Customer Name',
  },
  {
    key: 'age',
    label: 'Days on Floorplan',
    cellStyle: { textAlign: 'right' },
    value: ({ entry }) => entry.flooredCar.termData.totalDays,
  },
  {
    key: 'qtFinanceName',
    label: 'Finance Type',
  },
  {
    key: 'verified',
    label: 'Verified?',
    content: ({ value }) => (value ? 'X' : ''),
  },
  {
    key: 'verificationMethod',
    label: 'Verification Method'
  },
  {
    key: 'location',
    label: 'Location',
    content: ({ entry, value }) => {
      if (entry.qtExcpAddress1 || entry.qtExcpAddress2) {
        return (
          <a
            href={`https://maps.google.com/?q=${entry.qtExcpAddress1},${entry.qtExcpAddress2}`}
            target="_blank"
          >
            {value}
          </a>
        )
      } else if (entry.qtStatus === 'SN') {
        return (
          <a
            href={`https://maps.google.com/?q=${entry.flooredCar.dealer.primaryPlace.street},${entry.flooredCar.dealer.primaryPlace.city},${entry.flooredCar.dealer.primaryPlace.state},${entry.flooredCar.dealer.primaryPlace.zip.value}`}
            target="_blank"
          >
            {value}
          </a>
        )
      } else {
        return value
      }
    }
  },
  {
    key: 'actions',
    label: 'Actions',
    content: ({ entry }) => (
      <Link to={`${entry.flooredCar.webPath}/audits`}>
        Reconcile View
      </Link>
    ),
  },
];

const Audit = props => {
  const auth = useContext(AuthContext);
  const { id } = useParams();

  const [showCloseAudit, setShowCloseAudit] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    AUDIT, { variables: { id } }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  return (
    <>
      <Helmet>
        <title>Audit for {titleCase(data.audit.dealer.name)}</title>
      </Helmet>
      <br />
      <Container style={Formatting.infoContainer}>
        <Row>
          <div className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">QuikTrak Job Number</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {data.audit.quiktrakJobNumber}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Inspector</th>
                </tr>
                <tr>
                  <td className="car-info-green">{data.audit.inspector}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div  className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Inspection On</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {setDate(data.audit.inspectionOn)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div  className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Closed On</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {data.audit.auditClosedDate ? (
                      setDate(data.audit.auditClosedDate)
                    ) : (
                      auth.hasExactPolicy('Audit', 'close_audit') && (
                        <Buttons.Link
                          text="Close Audit"
                          onClick={() => setShowCloseAudit(true)}
                          style={{fontWeight: 'bold'}}
                        />
                      )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
      <hr />
      <div style={Typography.pageTitle}>Audited Cars</div>
      <DataTables.Simple
        data={data.audit.auditCars}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable={true}
        movableColumns
        styles={preflightTableStyles}
      />

      {showCloseAudit && (
        <CloseAuditModal
          audit={data.audit}
          handleClose={() => setShowCloseAudit(false)}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default Audit;
