import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { titleCase } from '../utils/stringHelpers';
import * as Formatting from '../styles/formatting';

/**
 * This hook is intended to make rendering server side errors easy for the majority of cases - when
 *   nothing too squirrely is needing to happen within the useMutation. If you have a squirrely case,
 *   you can instead just:
 *     1. Set the serverSideErrors in state
 *     2. Repeat what is below within the onComplete option to get the errors and set them to state.
 *     3. Make a component to display that state, using Formatting.serverSideErrorStyles as here
 *          for consistency. (see ServerSideError)
 *     4. Display the ServerSideError component above your form.
 *
*/

/**
 * This will take in arguments for:
 *    SAVE_MUTATION: the mutation to be used (e.g. NEW_AUCTION)
 *    onCompleteRedirectTo: a string of the pathname where the mutation should redirect to on complete.
 *    customSubmitHandler: This is optional, only needed if your onSubmit is currently doing something
 *      more than just firing the submit and setting formSubmitting to true.
 *
 * It will return:
 *  1. The ServerSideErrors component ready for use in your component,
 *  2. handleSubmit, replacing the handleSubmit done within the component currently.
 *  3. formSubmitting, a boolean which is used to determine whether to show the loading screen or not.
 */

export const useServerSideErrors = (props) => {
  const { SAVE_MUTATION, onCompleteRedirectTo, customSubmitHandler, componentType, REFETCH_QUERY, refetchVariable, redirectToShowPageType } = props;
  const [serverSideErrors, setServerSideErrors] = useState('');
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [payload, setPayload] = useState(null);
  const history = useHistory();

  const [saveMutation] = useMutation(SAVE_MUTATION,
    {
      awaitRefetchQueries: REFETCH_QUERY ? true : false,
      refetchQueries: REFETCH_QUERY ?
        [
          {
            query: REFETCH_QUERY,
            variables: { id: refetchVariable }
          }
        ]
        : null,

      onCompleted(data) {
        const key = Object.keys(data)[0];
        const errors = data[key].errors;

        /*
          The switch to set up redirectPath is only useful if you need to
            redirect to a path that requires the ID of the newly created
            resource - if it's a static link, you can just pass onCompletedRedirectTo when calling this hook.
        */
        let redirectPath = onCompleteRedirectTo;

        const redirectLink = () => {
          switch(redirectToShowPageType) {
            case 'txn':
              const txnData = data.updateTxn || data.createTxn;
              redirectPath = `/transactions/${txnData.txn.id}`;
              break;
            // TODO: Build out cases here for dealer, etc. if needed
            default: redirectPath = onCompleteRedirectTo
          }
          return redirectPath
        }


        if (errors?.length) {
          setFormSubmitting(false);
          let errorString = '';
          let errorName = '';
          let errorMessage = '';
          errors.forEach((error, index) => {
            errorName = error.path[1];
            errorMessage = error.message;
            const separator = index > 0 ? ', ' : ''
            errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`
          })
          setServerSideErrors(errorString)
        } else {
          setFormSubmitting(false);
          setPayload(data)
          history.push({
            pathname: redirectLink(),
            state: { alert: true, type: 'saved', componentType: (componentType || 'item') }
          })
        }
      }
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    if (customSubmitHandler) {
      customSubmitHandler(values);
    }
    saveMutation({ variables: values })
  };

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  return (
    [ServerSideErrors, handleSubmit, formSubmitting, payload]
  );
};

export default useServerSideErrors;
