import { gql } from '@apollo/client';

export const AUCTION_AVAILABILITIES = gql`
  query {
    auctions {
      id
      name
      auctionAccessId
      lastEmailAvailabilitySentAt
      contactsWhoReceiveAvailability
      markets {
        name
      }
    }
  }
`;

export const RESEND_AUCTION_AVAILABILITIES = gql`
  mutation ResendAvailabilities($id: ID!) {
    resendAvailabilities(id: $id) {
      auction {
        id
      }
    }
  }
`;
