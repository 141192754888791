import React, { useContext, useState, useRef, useEffect } from 'react';
import { gql } from '@apollo/client';
import Info from './Info';
import { useAlert } from '../../hooks/useAlert';
import AuthContext from '../../contexts/AuthContext';
import { setUniqueAlertData } from '../../utils/setAlertData';
import { useParams } from 'react-router-dom';
import DataState from '../DataState';
import { useQuery } from '@apollo/client';
import { setAlertData } from '../../utils/setAlertData';
import BasicAlert from '../../elements/BasicAlert';
import DataTable from '../../elements/DataTable';
import * as StringHelper from '../../utils/stringHelpers';
import * as DateHelpers from '../../utils/dateHelpers';


const AUCTION = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      id
      name
      flooredCars (scopes: ["unpaid"]) {
        id
        dealerLine { dealer { id, name } }
        market { id, name }
        rateProgram { id, name }

        termData { totalDays }

        vin, year, make, model, series, style, color
        status, loanAmount, loanDate, bosDate
        titleState, titleStatus, titleNumber, titleReleaseOn, unitNumber, stockNumber

        advanceType, sourceType, sourceId, externalPaymentMethod, fundedOn

        currentFees, currentInterest, currentPrincipal, currentDueOn,
        currentCollateralCoverage

        createdAt, updatedAt

        car { id  }
      }
    }
  }
`;

const Cars = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(AUCTION, { variables: { id } });

  const auth = useContext(AuthContext);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const dataSet = data.auction.flooredCars.map(function (car) {
    car = { ...car };

    if (auth.hasPolicy('FlooredCar', 'read')) {
      car.actions = `
        <a href="/dealers/${car.dealerLine.dealer.id}/cars/${car.id}"/>View</a>
      `
    }

    const { currentFees, currentInterest, currentPrincipal, currentCollateralCoverage } = car;

    const make = StringHelper.titleCase(car.make) || '';
    const model = StringHelper.titleCase(car.model) || '';
    const color = StringHelper.titleCase(car.color) || '';
    const marketName = car.market.name;
    const age = car.termData?.totalDays || '';
    const nextDueDate = DateHelpers.setDate(car.currentDueOn) || '';
    const loanDate = DateHelpers.setDate(car.loanDate) || '';
    const fundedDate = DateHelpers.setDate(car.fundedOn) || '';

    const totalDue = (
      parseFloat(currentFees) + parseFloat(currentInterest) + parseFloat(currentPrincipal) +
      parseFloat(currentCollateralCoverage)
    )

    const dealerName = `<a href="/dealers/${car.dealerLine.dealer.id}"/>${StringHelper.titleCase(car.dealerLine.dealer.name)}</a>`
    const vinLink = `<a href="/dealers/${car.dealerLine.dealer.id}/cars/${car.id}">${car.vin}</a>`

    return [
      marketName,
      dealerName,
      vinLink,
      car.year,
      make,
      model,
      color,
      age,
      loanDate,
      fundedDate,
      nextDueDate,
      StringHelper.currency(currentPrincipal),
      StringHelper.currency(currentFees),
      StringHelper.currency(currentInterest),
      StringHelper.currency(currentCollateralCoverage),
      StringHelper.currency(totalDue),
      StringHelper.titleCase(car.titleStatus) || '',
      car.actions
    ]
  })

  const columns = [
    {
      title: 'Market'
    },
    {
      title: 'Dealer'
    },
    {
      title: 'VIN',
    },
    {
      title: 'Year',
      render: DateHelpers.renderDate,
    },
    {
      title: 'Make'
    },
    {
      title: 'Model'
    },
    {
      title: 'Color'
    },
    {
      title: 'Days on Floorplan',
      className: 'text-right'
    },
    {
      title: 'Loan Date',
      render: DateHelpers.renderDate
    },
    {
      title: 'Funded Date',

    },
    {
      title: 'Next Due Date'
    },
    {
      title: 'Principal',
      className: 'text-right'
    },
    {
      title: 'Fees Due',
      className: 'text-right'
    },
    {
      title: 'Interest Due',
      className: 'text-right'
    },
    {
      title: 'Collateral Coverage',
      className: 'text-right'
    },
    {
      title: 'Total Due',
      className: 'text-right'
    },
    {
      title: 'Title Status',
    },
    {
      title: 'Functions'
    },
  ];

  const newRoute = `/auctions/${id}/cars/new`

  let saleTimeAlertData = setUniqueAlertData(props, setAlertType, displayAlert);
  return (
    <>
      <Info {...saleTimeAlertData} />
      {showAlert &&
        <BasicAlert componentType="car" type={alertType} />
      }
      <DataTable
        addFilter
        dataSet={dataSet}
        columns={columns}
        pageTitle={`Cars`}
        newRoute={newRoute}
        unhideable={true}
      />
    </>
  )
};

export default Cars;

