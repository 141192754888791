import React from "react";
import Button from "react-bootstrap/Button";
import { filterButton } from "./FilterStyles";
import * as Colors from "../../styles/colors";

const buttonColor = {
  backgroundColor: Colors.xlYellow,
  borderColor: Colors.xlYellow,
};

export const ResetSettings = () => {
  return (
    <Button
      onClick={() => window.location.reload()}
      style={Object.assign({}, filterButton, buttonColor)}
    >
      Reset
    </Button>
  );
};
