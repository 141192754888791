import { useState } from 'react';

export const useAlert = () => {
  const [showAlert, toggleAlert] = useState(false)

  const displayAlert = () => {
    toggleAlert(true)
  };

  return [
    showAlert,
    displayAlert
  ]
}
