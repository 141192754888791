import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import { handleNumberChange } from '../../utils/stringHelpers';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
const zipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/

export const placeSchema = yup.object({
  name: yup.string().required('Required'),
  type: yup
    .string()
    .oneOf(['Lot', 'Storage', 'Repair Facility', "Dealer's Home", 'Other'])
    .required('Required'),
  phone: yup.string().matches(phoneRegExp, { message: "Please enter a valid number.", excludeEmptyString: false }),
  fax: yup.string().matches(phoneRegExp, { message: "Please enter a valid number.", excludeEmptyString: false }),
  zip: yup.string().matches(zipRegExp, { message: 'Please enter a valid ZIP code.', excludeEmptyString: false }),
});

export const Form = () => {
  const { values, setFieldValue, isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.Select name="type" label="Type">
        <option value="">Select a Place Type</option>
        <option value="Lot">Lot</option>
        <option value="Storage">Storage</option>
        <option value="Repair Facility">Repair Facility</option>
        <option value="Dealer's Home">Dealer's Home</option>
        <option value="Other">Other</option>
      </BasicForm.Select>
      <BasicForm.PhoneInput
        name="phone"
        label="Phone Number"
        onChange={event => handleNumberChange(event, 'phone', setFieldValue)}
        value={values.phone || ''}
      />
      <BasicForm.PhoneInput
        name="fax"
        label="Fax #"
        onChange={event => handleNumberChange(event, 'fax', setFieldValue)}
        value={values.fax || ''}
      />
      <BasicForm.TextInput
        name="attention"
        type="text"
        label="Attention To:"
      />
      <BasicForm.TextInput
        name="street"
        type="text"
        label="Street"
      />
      <BasicForm.TextInput
        name="city"
        type="text"
        label="City"
      />
      <BasicForm.StateAbbreviationSelect
        name="state"
        type="text"
        label="State"
      />
      <BasicForm.TextInput
        name="zip"
        type="text"
        label="Zip"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
