import React, { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../../../../elements/forms";
import { ErrorMessage } from "../../../../../../elements/ErrorMessage";
import { useQuery, gql } from "@apollo/client";
import { useParams, Redirect } from "react-router-dom";
import DataState from "../../../../../DataState";

const BANK_ACCOUNTS = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      bankAccounts {
        id
        accountName
        accountNumberMasked
      }
    }
  }
`;

const ACCOUNTS = gql`
  query {
    accounts(scopes: ["bank"]) {
      id
      name
      gl
      special { key }
    }
  }
`;

export const AdvanceTypeOptions = () => {
  const {
    errors,
    setFieldValue,
    values: { sourceType },
  } = useFormikContext();

  return <Dealer key="dealer" />;
};

const styledError = {
  marginLeft: "16px",
  marginTop: "0px",
};

const Dealer = ({ formattedSourceType }) => {
  const { handleBlur, setFieldValue, values, errors } = useFormikContext();
  const id = parseInt(useParams().id);

  const { loading, error, data } = useQuery(BANK_ACCOUNTS, {
    variables: { id },
  });

  if (loading || !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { dealer } = data;
  const { bankAccounts } = dealer;

  const formattedBankAccounts = bankAccounts.map((account) => {
    const name = `${account.accountName} ${account.accountNumberMasked}`;

    return { id: account.id, name };
  });

  return (
    <div style={divStyle}>
      Select the Dealer's Source ACH:
      <BasicForm.AutoCompleteTextInput
        clearButton
        styledError={styledError}
        name="bankAccountId"
        type="text"
        style={inputStyle}
        options={formattedBankAccounts}
        labelKey="name"
        style={{ width: "350px", paddingLeft: "10px" }}
        handleBlur={handleBlur}
        id="source-data"
        onChange={(selected) => {
          selected.length > 0
            ? setFieldValue("bankAccountId", selected[0].id)
            : setFieldValue("bankAccountId", "");
        }}
      />
      <BasicForm.InlineStyledErrorMessage>
        {errors.bankAccountId}
      </BasicForm.InlineStyledErrorMessage>
    </div>
  );
};

export const ExternalTypeOptions = () => {
  const {
    setFieldValue,
    errors,
    values,
    values: { txnType },
  } = useFormikContext();
  let [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    setPaymentMethod(txnType);
  }, [txnType]);

  useEffect(() => {
    values.accountId = parseInt(values.accountId) || '';
  }, [values.accountId])

  const { loading, error, data } = useQuery(ACCOUNTS);

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  const paymentsBank = accounts.find(account => {
    return account.special && 'payments_bank' === account.special.key
  });

  if (!values.accountId) {
    values.accountId = paymentsBank.id;
  }

  return [
    <PaymentMethod key="txnType" errors={errors} />,
    txnType &&
      paymentMethod && <div>
        <Advance key="check" type={paymentMethod} />
        <div>
          <BasicForm.Select
            name="accountId"
            label="Payment Made to GL Account"
          >
          <option value="">Select an Account</option>
          { setOptionValues(accounts) }
          </BasicForm.Select>
          <BasicForm.StyledErrorMessage>
            {errors.accountId}
          </BasicForm.StyledErrorMessage>
        </div>
      </div>
  ];
};

const PaymentMethod = ({ errors }) => {
  return (
    <div style={divStyle}>
      What will the method of payment be?
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Check"
        value="Check"
      />
      <BasicForm.Radio name="txnType" type="radio" label="ACH" value="ACH" />
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Wire Transfer"
        value="Wire"
      />
      <BasicForm.Radio name="txnType" type="radio" label="Cash" value="Cash" />
      <BasicForm.InlineStyledErrorMessage>
        {errors && errors.txnType}
      </BasicForm.InlineStyledErrorMessage>
    </div>
  );
};

const Advance = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const { type } = props;
  let advanceToLabel = type;
  if (type !== "Check") advanceToLabel = `${type} Reference`;

  const divStyle = {
    display: "flex",
    alignItems: "center",
  };

  const inlineInputStyle = {
    ...inputStyle,
    marginLeft: "0px",
  };

  const labelStyle = {
    marginTop: "0px",
  };

  if (type !== "Cash") {
    return (
      <div style={divStyle}>
        <div>
          <BasicForm.TextInput
            name="name"
            type="text"
            style={inlineInputStyle}
            label={`${type} To:`}
            labelStyle={labelStyle}
          />
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <BasicForm.TextInput
            name="reference"
            type="text"
            label={`${advanceToLabel} Number:`}
            style={inlineInputStyle}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }

  if (type === "Cash") {
    values.name = "";

    return (
      <div style={divStyle}>
        <div>
          <BasicForm.TextInput
            name="reference"
            type="text"
            label="Reference"
            style={inlineInputStyle}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }
};

const setOptionValues = (accounts) => {
  return accounts.map((account) => {
    const name = `GL${account.gl} - ${account.name}`
    const id = parseInt(account.id);

    return <option value={id}>{name}</option>
  })
}

export const divStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
};

const inputStyle = {
  marginLeft: "12px",
  height: "30px",
};
