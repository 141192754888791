import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { FLOORED_CAR, UPDATE_SOLD } from './Queries';
import DataState from '../../DataState';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { Form, soldSchema } from './Form';

const UpdateSoldModal = props => {
  const { handleClose, show, refetch, car, setRefetchAudit } = props;
  const id = parseInt(useParams().carId)

  const [updateSold, { loading, error }] = useMutation(UPDATE_SOLD, {
    onCompleted(variables) {
      handleClose();
      if (refetch) { refetch(); }
      if (setRefetchAudit) { setRefetchAudit(true); }
    }
  })

  const handleSubmit = values => {
    if (values.saleType === '') {
      values.saleType = null
    }
    updateSold({ variables: values })
  }

  const ModalBody = () => {
    return (
      <div>
        <Formik
          initialValues={{
            id,
            soldOn: car.soldOn,
            soldTo: car.soldTo,
            saleType: car.saleType,
            financeCompanyName: car.financeCompanyName,
            soldToAuctionId: car.soldToAuctionId,
            other: car.other
          }}
          validationSchema={soldSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Update Sale
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(loading) ? <p>Loading...</p> : <ModalBody />}
      </Modal.Body>
    </Modal>
  )
}

export default UpdateSoldModal;
