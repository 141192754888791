import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { Form } from './Form';
import { editSchema } from './schema';
import { UPDATE_AUDIT_CAR, CREATE_FILE_UPLOAD } from './queries';
import { useMutation } from '@apollo/client';
import { DateHelpers } from "../../../utils";

const Update = props => {
  const {
    handleClose,
    show,
    auditId,
    refetch,
    setEditAuditCarAlert,
    flooredCarId,
    quiktrakJobNumber,
    showPageRefetch,
    setRefetchAudit,
    unverifiedTwice,
    foundAudits,
    currentAudit
  } = props;

  let submittedValues = useRef();

  const [updateAuditCar, { loading }] = useMutation(UPDATE_AUDIT_CAR, {
    onCompleted(values) {
      handleClose();
      refetch();

      if (showPageRefetch) {
        showPageRefetch();
      }

      if (setRefetchAudit) {
        setRefetchAudit(true);
      }

      setEditAuditCarAlert(true);
    }
  });

  const [createFileUpload, { loading: fileLoading }] = useMutation(
    CREATE_FILE_UPLOAD, {
      onCompleted(values) {
        const id = parseInt(values.createFileUpload.fileUpload.id)

        submittedValues.current.fileUploadId = id

        updateAuditCar({ variables: submittedValues.current })
      }
    }
  );

  let today = new Date();

  today = today.toISOString().split('T')[0];

  const handleSubmit = (values) => {
    if (values.soldOn) {
      values.dateMarkedSold = today
    }

    if (values.zip) {
      values.zip = values.zip.replace(/\D/g, '');
    }

    submittedValues.current = values

    if (values.files !== undefined) {
      createFileUpload({ variables: values })
    } else {
      updateAuditCar({ variables: values })
    }
  };

  const imageName = `
    Audit Photo - ${quiktrakJobNumber} - ${DateHelpers.renderDate(new Date())}
  `;

  const UpdateResolve = () => {
    return (
      <div>
        <Formik
          initialValues={{
            id: parseInt(auditId),
            flooredCarId: flooredCarId,
            fileUploadId: undefined,
            verificationMethod: '',
            soldOn: undefined,
            note: '',
            dateMarkedSold: undefined,
            soldTo: undefined,
            saleType: undefined,
            financeCompanyName: undefined,
            soldToAuctionId: undefined,
            other: undefined,
            name: imageName,
            type: 'AWS Storage',
            ownerType: 'AuditCar',
            ownerId: parseInt(auditId),
            files: [],
            arbitratedOn: undefined,
            arbitrationReason: '',
            companyName: '',
            personName: '',
            street: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            financeStreet: '',
            financeCity: '',
            financeState: '',
            financeZip: '',
            financePhone: '',
            financeCompanyContact: ''
          }}
          validationSchema={editSchema}
          onSubmit={handleSubmit}
        >
          <Form
            unverifiedTwice={unverifiedTwice}
            foundAudits={foundAudits}
            currentAudit={currentAudit}
          />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>Resolve Audit Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { (loading || fileLoading) ? <p>Loading...</p> : <UpdateResolve /> }
      </Modal.Body>
    </Modal>
  )
}

export default Update;
