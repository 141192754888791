import React from "react";
import { Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { modalStyle, multiSelectStyle } from "../FilterStyles";
import Select from "react-select";
import "./multiSelect.css";

const adjustFilterValues = (selectedList, label, currentFilters, handleFilters) => {
  label = label
    .split(" ")
    .map((l) => l.toLowerCase())
    .join("_");

  if (selectedList && selectedList.length === 0) selectedList = null;

  handleFilters({ ...currentFilters.current, [label]: selectedList });
};

const MultiSelect = ({
  currentFilters,
  multiSelectLabels,
  multiSelectOptions,
  handleFilters,
  multiSelectData,
}) => {
  if (!multiSelectData) return null;

  const filterKeys = multiSelectData.map((d) => Object.keys(d)).flat();
  return multiSelectLabels.map((label, index) => {
    return (
      <Row key={index} style={Object.assign({}, modalStyle.rows, { alignItems: "center" })}>
        <Form.Label>{label}</Form.Label>
        <Select
          options={multiSelectOptions[index]}
          value={currentFilters.current[filterKeys[index]]}
          defaultValue={currentFilters.current[filterKeys[index]]}
          isMulti={true}
          onChange={(event) =>
            adjustFilterValues(event, label, currentFilters, handleFilters)
          }
          styles={multiSelectStyle}
          className="multi-select"
        />
      </Row>
    );
  });
};

export default MultiSelect;
