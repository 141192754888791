import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FLOORED_CAR_TITLE_LOGS } from '../../../queries/viewQueries'
import New from './New';
import Update from './Edit';
import { DateHelpers } from '../../../utils';
import DataState from '../../DataState';
import * as DateHelper from '../../../utils/dateHelpers';
import DataTable from '@preflighttech/preflight-tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { addRecord } from './Style';
import { Typography, preflightTableStyles } from 'components/src/styles';
import BasicAlert from '../../../elements/BasicAlert';

const index = () => {
  const id = parseInt(useParams().carId);
  const dealerId = parseInt(useParams().id);

  const [editId, setEditId] = useState()
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [newTitleLogAlert, setNewTitleLogAlert] = useState(false)
  const [editTitleLogAlert, setEditTitleLogAlert] = useState(false)

  const { loading, error, data, refetch } = useQuery(
    FLOORED_CAR_TITLE_LOGS, { variables: { id } }
  );

  const handleClose = () => setShow(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShow = () => setShow(true);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const formatShipTo = (data) => {
    return (
      <>
        <>
          {
            data.shipToName !== '' && data.shipToName !== undefined ?
              data.shipToName : null
          }
        </>
        <br/>
        <>
          {
            `${data.street !== '' && data.street !== undefined ?
              data.street : null}`
          }
        </>
        <br/>
        <>
          {
            `${data.city !== '' && data.city !== undefined ?
              data.city + ', ' : null}` +
            `${data.state !== '' && data.state !== undefined ?
              data.state + ', ' : null}` +
            `${data.zip ?
              data.zip.formatted : null}`
          }
        </>
      </>
    )
  }

  const columns = [
    {
      key: 'trackingNumber',
      label: 'Tracking Number'
    },
    {
      key: 'shippedTo',
      label: 'Shipped To',
      content: ({ entry }) => {
        if (entry.shipment?.shipmentAt && entry.shipTo?.shipToName) {
          return formatShipTo(entry.shipTo)
        } else {
          return null
        }
      }
    },
    {
      key: 'titleStatus',
      label: 'Status',
      content: ({ value }) => value?.name
    },
    {
      key: 'notes',
      label: 'Notes'
    },
    {
      key: 'fileUpload',
      label: 'Scanned Title',
      content: ({ entry }) => {
        if (entry.fileUpload) {
          return (
            <a
              href={`
                ${id}/title-logs/${entry?.id}/title-pdf/
                ${entry?.fileUpload?.id}
              `}
            >
              View
            </a>
          )
        } else {
          return null
        }
      }
    },
    {
      key: 'watermarkedFileUpload',
      label: 'Watermarked Title',
      content: ({ entry }) => {
        if (entry.watermarkedFileUpload) {
          return (
            <a
              href={`
                ${id}/title-logs/${entry?.id}/watermarked-title-pdf/
                ${entry?.watermarkedFileUpload?.id}
              `}
            >
              View
            </a>
          )
        } else {
          return null
        }
      }
    },
    {
      key: 'createdAt',
      label: 'Created On',
      sort: 'desc',
      content: ({ entry }) => {
        if (entry.shipment?.shipmentAt) {
          return DateHelpers.setDate(entry.shipment.shipmentAt)
        } else {
          return DateHelpers.setDate(entry.createdAt)
        }
      }
    },
    {
      key: 'createdBy',
      label: 'Created By',
      content: ({ value }) => value.fullName
    },
    {
      key: 'actions',
      label: 'Actions',
      content: ({ entry }) => {
        let id = entry.id
        return (
          <a
            href="#"
            onClick={() => {
              setEditId(parseInt(id));
              setShowEdit(true);
            }}
          >
            Edit
          </a>
        )
      }

    }
  ]

  return (
    <>
      {
        newTitleLogAlert &&
          <BasicAlert componentType="Title Log" type="created" />
      }
      {
        editTitleLogAlert &&
          <BasicAlert componentType="Title Log" type="updated" />
      }
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <span style={Typography.pageTitle}>Title Logs</span>
        <span>
          <a href="#" onClick={() => setShow(true)} style={addRecord}>
            <FontAwesomeIcon icon={faPlusCircle} />
          </a>
        </span>
      </div>
      <DataTable.Simple
        data={data.flooredCar.titleLogs}
        columns={columns}
        pageLength={25}
        lengthMenu={ [10, 25, 50, 'All'] }
        disableSearch={true}
        title="Title Logs"
        htmlTable
        styles={preflightTableStyles}
      />
      { show && <New
          vin={data.flooredCar.vin}
          refetch={refetch}
          show={show}
          handleClose={handleClose}
          setNewTitleLogAlert={setNewTitleLogAlert}
          dealerId={dealerId}
        />
      }
      { showEdit && <Update
          show={showEdit}
          handleClose={handleCloseEdit}
          editId={editId}
          refetch={refetch}
          setEditTitleLogAlert={setEditTitleLogAlert}
        />
      }
    </>
  )
}

export default index;
