import React, { useState, useEffect, useContext } from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import AuthLink from 'components/src/components/AuthLink';
import { USERS, HIDE_USER, UNHIDE_USER } from './Queries';
import { currency, titleCase } from 'components/src/utils/stringHelpers';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert from 'components/src/elements/BasicAlert';
import { setDate } from 'components/src/utils/dateHelpers';
import { preflightTableStyles } from 'components/src/styles';

const Users = props => {
  const [showHidden, setShowHidden] = useState(false);
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data, refetch } = useQuery(
    USERS,
    { variables: { scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_USER, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_USER, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'firstName',
      label: 'First Name',
      sort: 'asc',
    },
    {
      key: 'lastName',
      label: 'Last Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'mobilePhone',
      label: 'Cell Phone #',
      content: ({value}) => value.formatted
    },
    {
      key: 'roles',
      label: 'Roles',
      value: ({value}) => value.map(role => role.name).join(', '),
    },
    {
      key: 'markets',
      label: 'Markets',
      value: ({value}) => value.map(market => market.name).join(', '),
    },
    {
      key: 'waiverLimit',
      label: 'Waiver Limit',
      value: ({value}) => value && parseFloat(value),
      content: ({entry}) => entry.waiverLimit && currency(entry.waiverLimit)
    },
    {
      key: 'currentSignInAt',
      label: 'Last Login',
      content: ({ value }) => setDate(value),
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix="/system/users"
          resource={entry}
          hide={hide}
          unhide={unhide}
          noRead
        >
          <AuthLink
            style={{paddingLeft: 5}}
            to={`/system/users/${entry.id}/deltas`}
            resource="Delta"
            action="read"
          >
            | Deltas
          </AuthLink>
          <AuthLink
            style={{paddingLeft: 5}}
            to={`/system/users/${entry.id}/logs`}
            resource="UserLog"
            action="read"
          >
            | Logs
          </AuthLink>
        </ResourceActionLinks>
      )
    }
  ];

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="User" type={alertType} />
      )}
      <ResourcesHeader
        title="Users"
        prefix="/system/users"
        resourceType="User"
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />

      <AuthLink
        style={{paddingLeft: 5}}
        to="/system/userlogs"
        resource="UserLog"
        action="read"
      >
        All User Logs
      </AuthLink>

      <DataTables.Simple
        data={data.users}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Users;
