import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import DataState from '../../../DataState';
import { useParams } from "react-router-dom";
import Notes from '../../../Notes'
import moment from 'moment'

const UCC_NOTES = gql`
  query Ucc($id: ID!) {
    ucc(id: $id) {
      id
      filedOn
      notes {
        id, createdAt, __typename, subject, note, statusAt, status, important, collection, corporate,
        createdBy {
          id, firstName, lastName,
        }
        statusBy {
          id, firstName, lastName,
        }
      }
    }
  }`

const UccNotes = () => {
  const dealerId = useParams().parentId;
  const id = useParams().ownerId;

  return (
    <Notes
      query={UCC_NOTES}
      ownerType="ucc"
      ownerId={id}
      prefix={`/dealers/${dealerId}/uccs/${id}/notes`}
    />
  );
};

export default UccNotes;
