import React, { useState, useEffect, useContext } from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import AuthLink from 'components/src/components/AuthLink';
import { DEALER_PROGRAMS, HIDE_DEALER_PROGRAM, UNHIDE_DEALER_PROGRAM } from './queries';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { rest } from 'components/src/utils/rest';
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert, {
  ExportToExcelAlert
} from 'components/src/elements/BasicAlert';
import { setDate } from 'components/src/utils/dateHelpers';
import { preflightTableStyles } from 'components/src/styles';

const DealerPrograms = props => {
  const [showHidden, setShowHidden] = useState(false);
  const [alertType, setAlertType] = useState();
  const [customAlert, displayCustomAlert] = useState(false);
  const [showAlert, displayAlert] = useAlert();
  const [settings, setSettings] = useState({});

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data, refetch } = useQuery(
    DEALER_PROGRAMS,
    { variables: { scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_DEALER_PROGRAM, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_DEALER_PROGRAM, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'code',
      label: 'Code',
      sort: 'asc',
    },
    {
      key: 'description',
      label: 'Description',
    },
    {
      key: 'totalTerms',
      label: 'Number of Terms',
    },
    {
      key: 'termDays',
      label: 'Total Term Days',
    },
    {
      key: 'totalExtensions',
      label: 'Number of Extensions',
    },
    {
      key: 'totalDays',
      label: 'Total Days (with Extensions)',
    },
    {
      key: 'activeDealers',
      label: 'Active Dealers',
    },
    {
      key: 'activeFlooredCars',
      label: 'Active Cars',
    },
    {
      key: 'createdAt',
      label: 'Created',
      content: ({value}) => setDate(value)
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix="/system/dealer-programs"
          resource={entry}
          hide={hide}
          unhide={unhide}
          noRead
        >
          <AuthLink
            style={{paddingLeft: 5}}
            to={`/system/dealer-programs/${entry.id}/deltas`}
            resource="Delta"
            action="read"
          >
            | Deltas
          </AuthLink>
        </ResourceActionLinks>
      )
    }
  ];

  const handleExportToExcel = () => {
    rest({
      path: 'report/dealer-programs',
      params: {
        search: settings.search,
        sort: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.direction,
      },
    }).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const buttons = (
    <ExportToExcel handleExportToExcel={handleExportToExcel} />
  );

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="DealerProgram" type={alertType} />
      )}
      { customAlert && <ExportToExcelAlert /> }
      <ResourcesHeader
        title="Dealer Programs"
        prefix="/system/dealer-programs"
        resourceType="DealerProgram"
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />

      <DataTables.Simple
        data={data.dealerPrograms}
        columns={columns}
        buttons={buttons}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        movableColumns
        styles={preflightTableStyles}
        setSettings={setSettings}
      />
    </>
  );
};

export default DealerPrograms;
