import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../hooks/useAlert';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors';
import DataState from '../../DataState';
import { Helmet } from 'react-helmet';
import { titleCase } from '../../../utils/stringHelpers';
import { Spacing, Typography } from '../../../styles';
import { Formik } from 'formik';
import { Form, categorySchema } from './Form';
import Loading from '../../../elements/Loading';

const UPDATE_DEALER_CATEGORY = gql`
  mutation UpdateDealerCategory($id: ID!, $name: String) {
    updateDealerCategory(id: $id, name: $name) {
      dealerCategory { id }
      errors { message, path }
    }
  }
`

const DEALER_CATEGORY = gql`
  query CATEGORY($id: ID!) {
    dealerCategory(id: $id) {
      id
      name
    }
  }
`

const UpdateDealerCategory = () => {
  const { id } = useParams();
  const [showAlert, displayAlert] = useAlert();
  const [redirect, setRedirect] = useState(false);

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_DEALER_CATEGORY,
    onCompleteRedirectTo: '/system/dealer-categories'
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const { loading, error, data } = useQuery(DEALER_CATEGORY, { variables: { id } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { dealerCategory } = data;

  const UpdateForm = () => {
    if (redirect) {
      return  <redirect
        to={{
          pathname: "/system/dealer-categories",
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <div sytle={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Edit Dealer Type: {titleCase(dealerCategory.name)}</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              id: dealerCategory.id,
              name: dealerCategory.name
            }}
            validationSchema={categorySchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <UpdateForm />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>edit {titleCase(dealerCategory.name)} Dealer Type</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default UpdateDealerCategory;
