import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useQuery } from '@apollo/client';
import { FILE_UPLOAD } from './queries';
import DataState from 'components/src/components/DataState';

const containerStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '600px',
};

const UploadPreview = props => {
  const { handleClose, show, id } = props;

  const { loading, error, data } = useQuery(FILE_UPLOAD, { variables: { id } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onHide={handleClose}
        show={show}
      >
        <Modal.Header closeButton>
          {data.fileUpload.name}
        </Modal.Header>
        <Modal.Body>
          <a href={data.fileUpload.fileUrl} target="_blank">
            Open in New Window
          </a>
          <br />
          <img src={data.fileUpload.fileUrl} style={containerStyle} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UploadPreview;
