import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useMutation } from '@apollo/client';
import { CLOSE_AUDIT } from './queries';
import * as Buttons from 'components/src/elements/buttons';
import { StyledLabel, StyledTextInput } from 'components/src/elements/forms';
import { Spacing } from 'components/src/styles';

const CloseAuditModal = ({ audit, handleClose, refetch }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [verificationMethod, setVerificationMethod] = useState();

  const [closeAudit] = useMutation(
    CLOSE_AUDIT,
    {
      onCompleted: () => {
        refetch();
        handleClose();
      }
    }
  );

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>Close Audit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledLabel>
          Reason (will display as 'Verification Method' on any currently
          unverified cars)
        </StyledLabel>
        <StyledTextInput
          name="verificationMethod"
          type="text"
          className="form-control"
          value={verificationMethod || ''}
          onChange={e => setVerificationMethod(e.target.value)}
        />
        <div style={Spacing.buttonRow}>
          <Buttons.ModalCancel
            type="cancel"
            text="Cancel"
            handleClose={handleClose}
          />
          <Buttons.Standard
            type="submit"
            text="Submit"
            onClick={() => {
              setFormSubmitting(true);
              closeAudit({ variables: { id: audit.id, verificationMethod } });
            }}
            disabled={!verificationMethod || formSubmitting}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CloseAuditModal;
