import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.css';

const MarketSelect = ({ label, markets, year, mutation }) => {
  const [selected, setSelected] = useState('');

  const handleSubmit = () => {
    const selectedMarket = markets.find(market => market.id === selected);

    if (confirm(`Really ${label} KPIs for ${selectedMarket.name}?`)) {
      mutation({ variables: { year: year, marketIds: [selected] } });
    }
  };

  return (
    <Row className="market-select">
      <Col>
        {label} Market:
      </Col>

      <Col>
        <select onChange={e => setSelected(e.target.value)}>
          <option value="">Select Market</option>
          {markets.map(market => (
            <option key={market.id} value={market.id}>{market.name}</option>
          ))}
        </select>
      </Col>

      <Col>
        <button
          type="button"
          className="btn btn-primary kpi-btn"
          disabled={!selected}
          onClick={handleSubmit}
        >
          {label}
        </button>
      </Col>
    </Row>
  );
};

export default MarketSelect;
