import { gql } from '@apollo/client';

export const FLOORED_CAR = gql`
  query FLOORED_CAR($id: ID!) {
    flooredCar(id: $id) {
      id
      soldOn
      soldTo
      saleType
      financeCompanyName
      soldToAuctionId
      other
      auditCars {
        id
        markedUnsold
        qtExcpAddress1
        qtExcpAddress2
        auditedAt
        qtFound
        qtException
        qtStatus
        qtNotes
        auditCode
        verificationMethod
        verified
        location
        manualClose
        qtCustomerName
        createdAt, updatedAt
        updatedBy {
          id
          fullName
        }
        flooredCar {
          id
          lastAuditCode
          soldOn
          auditCars {
            id
          }
          dealer {
            primaryPlace {
              street
              city
              state
              zip { value }
            }
          }
        }
        audit {
          id
          inspectionOn
          quiktrakJobNumber
        }
        fileUpload {
          id
          fileUrl
          name
        }
        fileUploads {
          id
          fileUrl
          name
        }
      }
    }
  }
`;

export const AUCTIONS = gql`
  query {
    auctions {
      id
      name
    }
  }
`;

export const UPDATE_AUDIT_CAR = gql`
  mutation UpdateAuditCar(
    $id: ID!
    $verificationMethod: String
    $soldOn: ISO8601Date
    $note: String
    $flooredCarId: Int
    $dateMarkedSold: ISO8601Date
    $soldTo: String
    $saleType: String
    $financeCompanyName: String
    $soldToAuctionId: Int
    $other: String
    $fileUploadId: Int
    $arbitratedOn: ISO8601Date
    $arbitrationReason: String
    $companyName: String
    $personName: String
    $street: String
    $city: String
    $state: String
    $zip: String
    $phone: String
    $financeStreet: String
    $financeCity: String
    $financeState: String
    $financeZip: String
    $financePhone: String
    $financeCompanyContact: String
  ) {
    updateAuditCar(
      id: $id
      financeStreet: $financeStreet
      financeCity: $financeCity
      financeState: $financeState
      financeZip: $financeZip
      financePhone: $financePhone
      financeCompanyContact: $financeCompanyContact
      companyName: $companyName
      personName: $personName
      street: $street
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      flooredCarId: $flooredCarId
      verificationMethod: $verificationMethod
      soldOn: $soldOn
      note: $note
      dateMarkedSold: $dateMarkedSold
      soldTo: $soldTo
      saleType: $saleType
      financeCompanyName: $financeCompanyName
      soldToAuctionId: $soldToAuctionId
      other: $other
      fileUploadId: $fileUploadId
      arbitratedOn: $arbitratedOn
      arbitrationReason: $arbitrationReason
    ) {
      errors {
        message
        path
      }
    }
  }
`;

export const CREATE_FILE_UPLOAD = gql`
  mutation CreateFileUpload(
    $name: String!,
    $type: String!,
    $ownerType: String,
    $ownerId: Int,
    $createdById: Int,
    $file: Upload,
    $mainLotPhoto: Boolean,
    $files: [Upload!]
  ) {
    createFileUpload(
      name: $name
      type: $type
      ownerType: $ownerType
      ownerId: $ownerId
      createdById: $createdById
      file: $file
      mainLotPhoto: $mainLotPhoto
      files: $files
    ) {
      fileUpload {
        id
        ownerType
        ownerId
        name
        type
        fileUrl
      }
      errors {
        message
        path
      }
    }
  }
`;
