import React from 'react';
import Modal from 'react-bootstrap/Modal';
import NewAuctionToken from '../../AuctionTokens/New';

const New = ({ handleClose, show, ownerType, ownerId, gqlQuery }) => {
  return <Modal
    size='lg'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={handleClose}
    show={show}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        Create a new Auction Token
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <NewAuctionToken
        handleClose={handleClose}
        ownerType={ownerType}
        ownerId={ownerId}
        gqlQuery={gqlQuery}
      />
    </Modal.Body>
  </Modal>
}

export default New;
