import React, { useState, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { FLOORED_CARS, CREATE_TITLE_LOG_BATCH } from './Queries';
import DataTables from '@preflighttech/preflight-tables';
import {
  Typography,
  Spacing,
  preflightTableStyles
} from 'components/src/styles';
import { columnDefinitions } from './columnDefinitions';
import Select from 'react-select';
import { shipStyle, selectStyle, errorSelectionStyle } from './Style';
import * as Buttons from '../../elements/buttons';
import { useQuery, useMutation } from '@apollo/client';
import DataState from '../DataState';
import BasicAlert from '../../elements/BasicAlert';
import Loading from '../../elements/Loading';
import { Redirect } from 'react-router-dom';
import { View } from 'react-native';
import Filter from 'components/src/elements/Filter';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';
import Button from "react-bootstrap/Button";
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import { AUTH_TOKEN } from '../../constants';
import secrets from '../../secrets';
import { ExportToPdfAlert } from 'components/src/elements/BasicAlert';

const queryVariables = filters => {
  const variables = {
    scopes: ['setupUnshippedTitles'], filterColumns: [], filterNames: []
  };

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      variables['filterColumns'].push(key);
      variables['filterNames'].push(value);
    }
  });

  return variables;
}

const SetupUnshippedTitles = () => {
  const [redirect, setRedirect] = useState(false);
  const [checkedEntries, setCheckedEntries] = useState([]);
  const [titleStatus, setTitleStatus] = useState({
    label: 'Released to Dealer', value: '6'
  });
  const [filters, setFilters] = useState({});
  const [settings, setSettings] = useState({});
  const [customAlert, displayCustomAlert] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    FLOORED_CARS, { variables: queryVariables(filters) }
  );

  const [createTitleLogBatch, {
    loading: loadingTitleLogBatch
  }] = useMutation(
    CREATE_TITLE_LOG_BATCH, {
      onCompleted() { setRedirect(true) }
    }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  // sets initial checked items

  const titleStatusOptions = data.titleStatuses.map(option => {
    return { label: option.name, value: option.id }
  });

  const handleTitleStatusChange = selected => {
    if (selected) {
      setTitleStatus(selected)
    }
  };

  const checkAll = () => {
    data.flooredCars.forEach(car => {
      if (!checkedEntries.includes(car.id)) {
        checkedEntries.push(car.id);
      }
    })

    setCheckedEntries([...checkedEntries]);
  };

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);
    refetch(queryVariables(currentFilters));
  }

  const handleExportToPdf = () => {
    const axios = require('axios');
    const FileDownload = require('js-file-download');
    const token = localStorage.getItem(AUTH_TOKEN);
    axios({
      method: 'get',
      url: `${secrets.api.baseUri}/setup-unshipped-titles`,
      responseType: 'blob',
      headers: {
        'Content-Type' : 'application/json',
        'Accept' : 'application/pdf',
        'Authorization' : `${token}`,
        'JWT_AUD' : 'react'
      },
      params: {
        sort: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.sort,
        filter_columns: queryVariables(filters).filterColumns,
        filter_names: queryVariables(filters).filterNames
      }
    })
    .then(res => {
      displayCustomAlert(true)
      var date = new Date();
      var timestamp = date.getTime();

      FileDownload(res.data, `Setup_Unshipped_Titles_${timestamp}.pdf`)
    });
  }

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <ExportToExcel handleExportToPdf={handleExportToPdf} pdf={true} />
      <Filter clearFilters={() => setFilters({})}>
        <Filter.MultiSelect
          columnKey="dealer_status_id"
          label="Dealer Status"
          options={data.dealerStatuses}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.MultiSelect
          columnKey="market_id"
          label="Market"
          options={data.markets}
          filters={filters}
          setFilter={setFilter}
        />
      </Filter>
      <ResetSettings />
    </View>
  );

  const columns = columnDefinitions({
    checkedEntries: checkedEntries ? [...checkedEntries] : [],
    setCheckedEntries,
  });

  if (redirect) {
    return <Redirect
      to={{
        pathname: `/unshipped-titles`,
        state: { alert: true }
      }}
    />
  }

  const visibleCheckedEntries = [];

  data.flooredCars.forEach(car => {
    if (checkedEntries.includes(car.id)) {
      visibleCheckedEntries.push(car.id);
    }
  });

  return (
    <>
      { customAlert && <ExportToPdfAlert /> }
      <Helmet>
        <title>Setup Unshipped Titles</title>
      </Helmet>
      <div style={Typography.pageTitle}>
        Setup Title Shipments from Title Release Report
      </div>
      <div style={selectStyle}>
        <Button style={{margin: "5px"}} onClick={() => setCheckedEntries([])}>
          Uncheck All
        </Button>
        <Button style={{margin: "5px"}} onClick={checkAll}>
          Check All
        </Button>
      </div>
      <DataTables.Simple
        data={data.flooredCars}
        columns={columns}
        buttons={buttons}
        pageLength={'All'}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        disableSearch
        styles={preflightTableStyles}
        setSettings={setSettings}
      />
      <div style={errorSelectionStyle}>
        { visibleCheckedEntries.length < 1 ? 'Required' : '' }
      </div>
      <div style={selectStyle}>
        <div style={Typography.formSubHeader}>
          Selected: {visibleCheckedEntries.length}
        </div>
      </div>
      <div style={shipStyle}>
        <div>New Title Status:</div>
        <Select
          isMulti={false}
          options={titleStatusOptions}
          onChange={handleTitleStatusChange}
          value={titleStatus}
          style={{ width: '500px' }}
        />
      </div>
      <div style={Spacing.buttonRow}>
        {loadingTitleLogBatch ? <Loading /> :
          <Buttons.Standard
            type='submit'
            text='Process Titles'
            disabled={visibleCheckedEntries.length < 1}
            onClick={
              () => createTitleLogBatch({
                variables: {
                  flooredCarIds: visibleCheckedEntries,
                  titleStatusId: titleStatus.value
                }
              })
            }
          />
        }
      </div>
    </>
  )
}

export default SetupUnshippedTitles;
