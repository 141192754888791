import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import Table from './Table';
import { Typography } from 'components/src/styles';

const index = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const defaultFilters = {};

  if (params.get('gl-dates')) {
    defaultFilters['earned_on'] = params.get('gl-dates').split('|');

    if (!defaultFilters['earned_on'][0]) {
      defaultFilters['earned_on'][0] = '2000-01-01';
    }
  }

  if (params.get('account')) {
    defaultFilters['account_id'] = params.get('account').split('|');
  }

  if (params.get('market')) {
    defaultFilters['market_id'] = params.get('market').split('|');
  }

  return (
    <>
      <div style={Typography.pageTitle}>General Ledger</div>
      <Table title={false} defaultFilters={defaultFilters} />
    </>
  );
};

export default index;
