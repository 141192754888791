import { gql } from '@apollo/client';
import { NOTES_SUBQUERY } from '../Notes/queries';

export const VENDOR_NOTES = gql`
  query Vendor($id: ID!, $scopes: [String!]) {
    vendor(id: $id) {
      id
      name
      ${NOTES_SUBQUERY}
    }
  }
`;
