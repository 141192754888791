import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { UNSOLD, CREATE_NOTE } from './Queries';
import { useMutation } from '@apollo/client'
import { Spacing } from '../../../styles';
import { unsoldSchema, Form } from './Form';

const UnsoldModal = props => {
  const { handleClose, show, refetch } = props
  const id = parseInt(useParams().carId)

  let submittedNote = useRef()

  const [createNote, { loading, error }] = useMutation(CREATE_NOTE, {
    onCompleted(values) {
      unsold({ variables: { id: id, note: submittedNote.current } })
    }
  })

  const [unsold, { loading: unsoldLoading }] = useMutation(UNSOLD, {
    onCompleted(values) {
      handleClose();
      refetch();
    }
  })

  const handleSubmit = values => {
    submittedNote.current = values.note

    createNote({ variables: values })
  }

  const ModalBody = () => {
    return (
      <div style={Spacing.formWrapper}>
        <Formik
          initialValues={{
            subject: 'Marked as not sold.',
            note: '',
            ownerType: 'FlooredCar',
            ownerId: id
          }}
          validationSchema={unsoldSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Mark As Not Sold
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(loading || unsoldLoading) ? <p>Loading...</p> : <ModalBody />}
      </Modal.Body>
    </Modal>
  )
}

export default UnsoldModal;
