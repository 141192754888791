import { gql, useQuery } from '@apollo/client';

const ENUM_QUERY = gql`
  query EnumValues($modelName: String!, $attributeName: String!) {
    enumValues(modelName: $modelName, attributeName: $attributeName) {
      values
      allowNull
      defaultValue
    }
  }
`;

const useEnum = (modelName, attributeName) => {
  const { loading, data } = useQuery(
    ENUM_QUERY, { variables: { modelName, attributeName } }
  );

  if (loading && !data) return [[]];

  return [
    data.enumValues.values,
    data.enumValues.allowNull,
    data.enumValues.defaultValue
  ];
};

export default useEnum;
