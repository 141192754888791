import React, { useState, Fragment } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useAlert } from '../../../hooks/useAlert';
import { useHistory } from 'react-router-dom';
import { Spacing, Typography } from '../../../styles';
import { Formik } from 'formik';
import { departmentSchema, Form } from './Form';
import { DEPARTMENTS, DEPARTMENT, CREATE_DEPARTMENT } from './queries';
import Loading from '../../../elements/Loading';
import { getServerSideErrors } from '../../../utils/errorHelpers';

const New = () => {
  const [showAlert, displayAlert] = useAlert();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const history = useHistory();

  const [createDepartment] = useMutation(CREATE_DEPARTMENT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DEPARTMENTS
        }
      ],

      onCompleted(values) {
        setFormSubmitting(false);
        const serverErrors = getServerSideErrors(values);
        if (serverErrors) {
          setServerSideErrors(serverErrors);
        } else {
          history.push({
            pathname: `/financial/departments`,
            state: { alert: true, type: 'created' },
          })
        }
      }
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true);
    createDepartment({ variables: values })
  };

  const NewForm = () => {
    return (
      <Fragment>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Department</h1>
          </div>
          <Formik
            initialValues={{
              name: '',
            }}
            validationSchema={departmentSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </Fragment>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    );
  };

  return (
    <Fragment>
      { serverSideErrors &&
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      }
      <div style={Spacing.formContainer}>
        <DisplayContent />
      </div>
    </Fragment>
  );
};

export default New;
