import React from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import moment from 'moment';

export const repoSchema = ({ repoOn, loanDate }) => yup.object({
  repoOn: yup
    .date()
    .required('Required')
    .test('Repo Date', 'must be within last 30 days', value => {
      return repoOn || moment().diff(moment(value), 'days') < 30;
    })
    .test('Repo Date', 'must be after loan date', value => {
      return moment(loanDate).diff(moment(value), 'days') < 0
    }),
  repoLocation: yup
    .string()
    .required('Required'),
  });

export const Form = () => {
  return (
    <FormikForm>
      <BasicForm.TextInput
        name="repoOn"
        type="date"
        label="Repo On"
      />
      <BasicForm.TextInput
        name="repoLocation"
        type="text"
        label="Repo Location"
      />
      <BasicForm.Checkbox
        name="repoSold"
        label="Repo Sold?"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}
