import React, { useEffect } from 'react';
import * as BasicForm from '../../../elements/forms';
import { useQuery } from "@apollo/client";
import { useFormikContext } from 'formik';
import Select from 'react-select';
import { ACCOUNTS, PAYABLE_ACCOUNTS } from './Queries'

const OffsetTransactionLine = (props) => {
  const { payableAccount, receiveFunds } = props;
  const { values, setValues, errors } = useFormikContext()
  const { loading, error, data } = useQuery(ACCOUNTS);
  const {
    loading: payableLoading,
    error: payableError,
    data: payableData
  } = useQuery(PAYABLE_ACCOUNTS);

  if (loading || !data || payableLoading || !payableData) return null;
  if (error || payableError) {
    return <DataState.Error error={error || payableError } />
  };

  let accounts;
  let label;
  let name;

  if (payableAccount) {
    accounts = payableData.accounts
    label = 'Accounts Payable Account'
    name = 'payableAccountId'
  } else {
    accounts = data.accounts
    label = 'Bank Account'
    name = 'bankAccountId'
  }

  const formattedAccounts = accounts.map((account) => {
    return { value: account.id, label: `${account.gl} - ${account.name}` }
  })

  const handleAccountChange = (selected) => {
    if (payableAccount) {
      values.payableAccountId = parseInt(selected.value)
    } else {
      values.bankAccountId = parseInt(selected.value)
    }
    setValues(values)
  }

  return (
    <>
      <BasicForm.StyledLabel>{label}</BasicForm.StyledLabel>
      <Select
        options={formattedAccounts}
        isMulti={false}
        onChange={handleAccountChange}
      />
      <div
        style={{
          fontWeight: 'bold',
          color: '#A1473F',
          fontStyle: 'italic',
          fontSize: '16px'
        }}
      >
        { errors.payableAccountId ? errors.payableAccountId.value : '' }
        { errors.bankAccountId ? errors.bankAccountId.value : '' }
      </div>
    </>
  );
};

export default OffsetTransactionLine;
