import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import BasicAlert from '../../../elements/BasicAlert';
import { gql, useQuery, useMutation } from '@apollo/client';
import DataTables from '@preflighttech/preflight-tables';
import { Helmet } from 'react-helmet';
import DataState from '../../DataState';
import { setTime, setDate } from 'components/src/utils/dateHelpers';
import {
  ACH_BATCHES,
  CREATE_INCOMING_ACH,
  CREATE_OUTGOING_ACH,
  PROCESS_AUTOPAY
} from './queries';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { preflightTableStyles } from 'components/src/styles';
import featureEnabled from 'components/src/utils/featureEnabled';

const AchBatches: () => React$Node = (props) => {
  const [isSubmitting, setIsSubmitting] = useState();

  const { loading, error, data } = useQuery(ACH_BATCHES)
  const history = useHistory();

  const reloadScreen = (componentType, type) => {
    history.push({
      pathname: `/financial/ach-batches`,
      state: { alert: true, componentType: componentType, type: type },
    });
    window.location.reload();
  };

  const [createIncomingAch] = useMutation(CREATE_INCOMING_ACH, {
    onCompleted: () => reloadScreen('ach batch', 'created (incoming)')
  });

  const [createOutgoingAch] = useMutation(CREATE_OUTGOING_ACH, {
    onCompleted: () => reloadScreen('ach batch', 'created (outgoing)')
  });

  const [processAutopay] = useMutation(PROCESS_AUTOPAY, {
    onCompleted: (data) => {
      console.log(data.processAutopay.flooredCars);
      reloadScreen('autopay', 'processed');
    }
  });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'direction',
      label: 'Direction',
    },
    {
      key: 'sentAt',
      label: 'Sent At',
      sort: 'asc',
      content: ({value}) => setTime(value)
    },
    {
      key: 'filename',
      label: 'Filename',
    },
    {
      key: 'bankDate',
      label: 'Bank Date',
      content: ({value}) => setDate(value)
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix="/financial/ach-batches"
          resource={entry}
          noEdit
        />
      )
    }
  ];

  const confirmAndProcess = (label, method) => {
    if (confirm(`Really process ${label}?`)) {
      setIsSubmitting(label);
      method();
    }
  }

  return (
    <>
      {props.location?.state?.alert && !isSubmitting && (
        <BasicAlert
          componentType={props.location.state.componentType}
          type={props.location.state.type}
        />
      )}
      {
        isSubmitting ?
          <Alert variant="info" style={Spacing.alerts}>
            Processing {isSubmitting}
          </Alert>
        :
          <div style={Spacing.topButtonRow}>
            <Buttons.Standard
              text="Create Incoming"
              onClick={() => confirmAndProcess('Incoming', createIncomingAch)}
              topRowButtons
            />
            <Buttons.Standard
              text="Create Outgoing"
              onClick={() => confirmAndProcess('Outgoing', createOutgoingAch)}
              topRowButtons
            />
            {featureEnabled('autopay', 'button') && (
              <Buttons.Standard
                text="Process Autopay"
                onClick={() => confirmAndProcess('Autopay', processAutopay)}
                topRowButtons
              />
            )}
          </div>
      }

      <ResourcesHeader title="ACH Batches" noNew />
      <DataTables.Simple
        data={data.achBatches}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50] }
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default AchBatches;
