import { columns } from './index';

export const getColumnName = (columnIndex) => {
  const currentColumns = columns.map((column) => {
    return column.title.toLowerCase().split(' ').join('_')
  })

  const unFilterableColumns = ['actions', 'functions', 'params']

  const sortedColumn = currentColumns[columnIndex];
  return unFilterableColumns.includes(sortedColumn)
    ? null
    : sortedColumn
}
