import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';

export const marketSchema = yup.object({
  name: yup.string().required('Required.'),
  timezone: yup.string().required('Required.'),
});

export const Form = () => {
  const { isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.Select name="timezone" label="Time Zone">
        <option value="">Select a Time Zone</option>
        <option value="US/Central">US/Central</option>
        <option value="US/East-Indiana">US/East-Indiana</option>
        <option value="US/Eastern">US/Eastern</option>
        <option value="US/Mountain">US/Mountain</option>
        <option value="US/Pacific">US/Pacific</option>
      </BasicForm.Select>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
