import React, { useEffect, useRef, useState } from 'react';
import * as BasicForm from '../../../../elements/forms';
import { divStyle } from './AdvanceType';
import { useFormikContext } from 'formik';
import { ErrorMessage } from '../../../../elements/ErrorMessage';
import AccountSelect from './AccountSelect';
import { SourceType } from './SourceType';
import { Advance } from './Advance';

export const ExternalTypeOptions = () => {
  let [paymentMethod, setPaymentMethod] = useState(null);

  const {
    setFieldValue,
    values: { sourceType, txnType },
  } = useFormikContext();

  useEffect(() => {
    setPaymentMethod(txnType)
  }, [txnType])

  useEffect(() => {
    setFieldValue("sourceId", "");
    setFieldValue("bankAccountId", null);
  }, [sourceType]);

  return (
    [
      <PaymentMethod key="txnType" />,
      <AccountSelect />,
      <SourceType includeOther />,
      <Advance key='check' type={paymentMethod} />,
    ]
  )
}

 const PaymentMethod = () => {
  return <div style={divStyle}>
    <BasicForm.StyledLabel>
      What will the method of payment be?
    </BasicForm.StyledLabel>
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Check"
        value="Check"
      />
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="ACH"
        value="ACH"
      />
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Wire Transfer"
        value="Wire"
      />
      <BasicForm.InlineStyledErrorMessage>
        <ErrorMessage name='txnType'/>
      </BasicForm.InlineStyledErrorMessage>
    </div>
}
