import { gql } from '@apollo/client';
import {
  WITH_COUNT_VARIABLES, WITH_COUNT_ARGUMENTS
} from 'components/src/queries/withCount';

export const SHIPMENTS_WITH_COUNT = gql`
  query ShipmentsWithCount(
    ${WITH_COUNT_VARIABLES}
  ) {
    shipmentsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        id
        trackingNumber
        priceQuoted
        trackingStatus
        statusUpdateAt
        toAddress {
          id
          name
        }
        fromAddress {
          id
          name
        }
        sourceType
        sourceId
        shipmentAt
        type
        pmsiTitleCount
        shipmentBy {
          id
          fullName
        }
        createdBy {
          id
          fullName
        }
        fileUpload {
          id
          fileUrl
        }
      }
      count
      shipTos
      users {
        id
        fullName
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateStatus($id: ID!) {
    updateStatus(
      id: $id
    ) {
      shipment {
        id
        trackingStatus
        statusUpdateAt
      }
    }
  }
`

export const CREATE_LABEL = gql`
  mutation CreateLabel($id: ID!) {
    createLabel(
      id: $id
    ) {
      shipment {
        id
        fileUpload {
          id
          fileUrl
        }
      }
    }
  }
`
