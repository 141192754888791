import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../elements/forms";
import { ErrorMessage } from "../../../elements/ErrorMessage";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useParams, Redirect } from "react-router-dom";
import DataState from "../../DataState";
import Select from 'react-select';
import DealerBankSelect from 'components/src/components/Dealers/BankAccounts/Select';

const ACCOUNTS = gql`
  query {
    accounts(scopes: ["bank"]) {
      id
      name
      gl
      special {
        key
      }
    }
  }
`;

export const AdvanceTypeOptions = () => {
  const {
    errors,
    setFieldValue,
    values: { sourceType },
  } = useFormikContext();

  useEffect(() => {
    setFieldValue("bankAccountId", "");
  }, [sourceType]);

  return <Dealer key="dealer" />;
};

const styledError = {
  marginLeft: "16px",
  marginTop: "0px",
};

const Dealer = ({ formattedSourceType }) => {
  const { handleBlur, setFieldValue, values, errors } = useFormikContext();
  const id = parseInt(useParams().id);

  return (
    <>
      <div style={divStyle}>
        Select the Dealer's Source ACH:
      </div>
      <div style={divStyle}>
        <DealerBankSelect
          id={id}
          values={values}
          setFieldValue={setFieldValue}
          styledError={styledError}
          style={{ width: "700px", paddingLeft: "10px" }}
          handleBlur={handleBlur}
        />
      </div>
      <div style={divStyle}>
        <BasicForm.InlineStyledErrorMessage>
          {errors.bankAccountId}
        </BasicForm.InlineStyledErrorMessage>
      </div>
    </>
  );
};

export const ExternalTypeOptions = ({ quoteAmount, setDisabled }) => {
  const {
    setValues,
    setFieldValue,
    errors,
    values,
    values: { txnType },
  } = useFormikContext();
  let [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    setPaymentMethod(txnType);
  }, [txnType]);

  useEffect(() => {
    values.accountId = parseInt(values.accountId) || "";
  }, [values.accountId]);

  const { loading, error, data } = useQuery(ACCOUNTS);

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  const paymentsBank = accounts.find((account) => {
    return account.special && "payments_bank" === account.special.key;
  });

  let defaultValue = { value: paymentsBank?.id, label: `${paymentsBank?.gl} - ${paymentsBank?.name}`}

  if (!values.accountId) {
    values.accountId = paymentsBank?.id;
  }

  (values.total < quoteAmount) ? setDisabled(true) : setDisabled(false)

  const formattedAccounts = accounts.map((account) => {
    return { value: account.id, label: `${account.gl} - ${account.name}` }
  })

  const handleAccountChange = (selected) => {
    values.accountId = parseInt(selected.value)

    setValues(values)
  }

  return [
    <PaymentMethod key="txnType" errors={errors} />,
    txnType && paymentMethod && (
      <div>
        <Advance key="check" type={paymentMethod} />
        <div>
          <BasicForm.StyledLabel>Payment Made to GL Account</BasicForm.StyledLabel>
          <Select
            defaultValue={defaultValue}
            options={formattedAccounts}
            isMulti={false}
            onChange={handleAccountChange}
          />
          <BasicForm.StyledErrorMessage>
            {errors.accountId}
          </BasicForm.StyledErrorMessage>
          <BasicForm.TextInput
            name="total"
            label="Payment Amount"
            type="number"
          />
          <BasicForm.InlineStyledErrorMessage style={{ marginTop: "8px" }}>
            {parseFloat(values.total) < parseFloat(quoteAmount) && (
              <p style={{ marginBottom: "0px" }}>
                Must be greater than or equal to the payment amount.
              </p>
            )}
          </BasicForm.InlineStyledErrorMessage>
        </div>
      </div>
    ),
  ];
};

const PaymentMethod = ({ errors }) => {
  return (
    <div style={divStyle}>
      What will the method of payment be?
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Check"
        value="Check"
      />
      <BasicForm.Radio name="txnType" type="radio" label="ACH" value="ACH" />
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Wire Transfer"
        value="Wire"
      />
      <BasicForm.Radio name="txnType" type="radio" label="Cash" value="Cash" />
      <BasicForm.InlineStyledErrorMessage>
        {errors && errors.txnType}
      </BasicForm.InlineStyledErrorMessage>
    </div>
  );
};

const Advance = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const { type } = props;
  let advanceToLabel = type;
  if (type !== "Check") advanceToLabel = `${type} Reference`;

  const divStyle = {
    display: "flex",
    alignItems: "center",
  };

  const inlineInputStyle = {
    ...inputStyle,
    marginLeft: "12px",
  };

  const labelStyle = {
    marginTop: "0px",
  };

  if (type !== "Cash") {
    return (
      <div style={divStyle}>
        <div>
          <BasicForm.TextInput
            name="name"
            type="text"
            style={inlineInputStyle}
            label={`${type} From:`}
            labelStyle={labelStyle}
          />
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <BasicForm.TextInput
            name="reference"
            type="text"
            label={`${advanceToLabel} Number:`}
            style={inlineInputStyle}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }

  if (type === "Cash") {
    values.name = "";

    return (
      <div style={divStyle}>
        <div>
          <BasicForm.TextInput
            name="reference"
            type="text"
            label="Reference"
            style={inlineInputStyle}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }
};

export const divStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
};

const inputStyle = {
  marginLeft: "12px",
  height: "30px",
};
