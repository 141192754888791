import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { useAlert } from '../../hooks/useAlert';
import DataState from '../DataState';
import Loading from '../../elements/Loading';
import { EditForm } from './EditForm';
import { useServerSideErrors } from '../../hooks/useServerSideErrors'
import AuthContext from '../../contexts/AuthContext';
import {
  FLOORED_CAR,
  UPDATE_FLOORED_CAR,
  UPDATE_FLOORED_CAR_AUTOPAY_DISABLED,
} from './Queries'
import { editCarSchema } from './Schema';

const EditCar = () => {
  const auth = useContext(AuthContext);

  const { carId, id } = useParams();
  const {
    loading,
    error,
    data
  } = useQuery(FLOORED_CAR, { variables: { id: carId } });

  const [showAlert, displayAlert] = useAlert();

  const useAutopayDisabledMutation = (
    !auth.hasPolicy('FlooredCar', 'update') &&
    auth.hasExactPolicy('FlooredCar', 'exclude_from_autopay')
  );

  const serverSideErrorProps = {
    SAVE_MUTATION: (
      useAutopayDisabledMutation ?
        UPDATE_FLOORED_CAR_AUTOPAY_DISABLED :
        UPDATE_FLOORED_CAR
    ),
    onCompleteRedirectTo: `/dealers/${id}/cars/${carId}`,
    componentType: 'car',
    REFETCH_QUERY: FLOORED_CAR,
    refetchVariable: carId
  }

  const [
    ServerSideErrors,
    handleSubmit,
    formSubmitting
  ] = useServerSideErrors(serverSideErrorProps);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    name,
    vin,
    year,
    make,
    model,
    color,
    series,
    mileage,
    blackbookValue
  } = data.flooredCar;

  const Form = () => {
    if (formSubmitting) {
      return (
        <Loading />
      )
    } else {

      return (
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Edit Car: {name}</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              id: carId,
              mileage: mileage,
              blackbookValue: blackbookValue || "",
              vin: vin,
              year: year,
              make: make,
              model: model,
              series: series || "",
              color: color || "",
              autopayDisabled: data.flooredCar.autopayDisabled,
              rateProgramId:
                parseInt(data.flooredCar.rateProgram.id) || null,
              dealerProgramId:
                parseInt(data.flooredCar.dealerProgram.id) || null,
              automatedFeeIds: data.flooredCar.automatedFeeIds || []
            }}
            validationSchema={editCarSchema}
            onSubmit={handleSubmit}
          >
            <EditForm bbv={blackbookValue} />
          </Formik>
        </div>
      )
    }
  }

  return (
    <>
      {showAlert &&
        <Alert variant='success' style={Spacing.alerts}>
          This Car has been updated.
        </Alert>
      }
      <div style={Spacing.formContainer}>
        <Form />
      </div>
    </>
  );
};

export default EditCar;
