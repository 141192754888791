import React, { useParams } from 'react';
import Modal from "react-bootstrap/Modal";
import { useQuery } from '@apollo/client';
import { DEALER_PEOPLE } from 'components/src/components/DealerPeople/queries';
import DataState from '../../DataState';
import * as BasicForm from '../../../elements/forms';
import { Spacing } from "../../../styles";
import * as Buttons from "../../../elements/buttons";
import Select from 'react-select';
import { Colors } from '../../../styles';
import { useFormikContext } from 'formik';
import Loading from '../../../elements/Loading';
import MissingEmails from './MissingEmails';

const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })
}

const EmailQuote = ({setSendQuoteModal, sendQuoteModal, dealerId, setEmailButton, handleSubmit, formSubmitting}) => {
  const { data, loading, error } = useQuery(
    DEALER_PEOPLE, { variables: { id: dealerId }}
  );

  const { setFieldValue, dirty, values } = useFormikContext();
  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const usersWithoutEmail = [];
  let formattedDealerPeople = data.dealer.dealerPeople.map((people) => {
    if (people.email && people.email !== '') {
      return { value: people.id, label: people.firstName }
    } else {
      usersWithoutEmail.push(people.fullName)
    }
  }).filter((person) => person);

  const handleChange = (selectedList) => {
    if (!selectedList) {
      setFieldValue('dealerPeopleIds', []);
      return
    }

    selectedList = selectedList.map((people) => {
      return parseInt(people.value)
    })

    setFieldValue('dealerPeopleIds', selectedList);
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={() => {setSendQuoteModal(false); setEmailButton(false)}}
      show={sendQuoteModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Send Quote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(() =>
          {if (formSubmitting) {
            return (
              <div style={Spacing.formContainer}>
                <Loading />
              </div>
            )
          } else {
            return (
              <>
                <BasicForm.Checkbox
                  label="Send Me A Copy"
                  name="copy"
                  type="checkbox"
                />
                <BasicForm.StyledLabel>Dealer People</BasicForm.StyledLabel>
                <Select
                  options={formattedDealerPeople}
                  isMulti={true}
                  onChange={handleChange}
                  styles={customStyles}
                />
                <MissingEmails names={usersWithoutEmail} />
                <div style={Spacing.buttonRow}>
                  <Buttons.ModalCancel
                    text="Cancel"
                    handleClose={ () => {setSendQuoteModal(false); setEmailButton(false)}}
                  />
                  <Buttons.Standard
                    type="submit"
                    text="Submit"
                    disabled={!dirty}
                    onClick={() => handleSubmit(values)}
                  />
                </div>
              </>
            )
          }
        })()}
      </Modal.Body>
    </Modal>
  )
}

export default EmailQuote
