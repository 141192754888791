import React, { useState } from 'react';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors';
import { fileUploadSchema, Form } from './Form';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { CURRENT_USER } from '../../../queries/currentUser';
import DataState from '../../DataState';
import { TXNS_WITH_COUNT } from "../../../queries/indexQueries";
import { useHistory } from 'react-router-dom';
import { Spacing } from '../../../styles';
import Loading from '../../../elements/Loading';
import { TXN } from '../Show';

const CREATE_FILE_UPLOAD = gql`
  mutation CreateFileUpload(
    $name: String!,
    $type: String!,
    $ownerType: String,
    $ownerId: Int,
    $createdById: Int!,
    $file: Upload,
  ) {
    createFileUpload(
      name: $name
      type: $type
      ownerType: $ownerType
      ownerId: $ownerId
      createdById: $createdById
      file: $file
    ) {
      fileUpload {
        id
        ownerType
        ownerId
        name
        type
        fileUrl
      }
      errors {
        message
        path
      }
    }
  }
`
const New = ({ handleClose, show, ownerId }) => {
  const { loading, error, data } = useQuery(CURRENT_USER)
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [createFileUpload, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_FILE_UPLOAD,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: TXN, variables: { id: ownerId }}],
      onCompleted(values) {
        setFormSubmitting(false)
        handleClose(true)
        window.location.reload()
      }
    }
  )

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createFileUpload({ variables: values })
  }

  const { currentUser } = data;

  const createdById = parseInt(currentUser.id);

  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          type: 'AWS Storage',
          ownerType: 'Txn',
          ownerId,
          createdById,
          file: ''
        }}
        validationSchema={fileUploadSchema}
        onSubmit={handleSubmit}
      >
        <Form handleClose={handleClose} show={show} />
      </Formik>
    </div>
  )
}

export default New
