import React from 'react'
import { useFormikContext } from 'formik';
import SingleFeeForm from './SingleFee';
import MultipleFeesForm from './MultipleFees';
import IntervalFeesForm from './IntervalFees';
import InterestOnlyForm from './InterestOnly';

const optionStyle = {
  container: {
    marginTop: '20px',
  },
}

const RenderForm = ({ index, accounts }) => {
  const { values } = useFormikContext();

  switch (values.termsAttributes[index].feeType) {
    case 'Single':
      return <SingleFeeForm index={index} accounts={accounts}/>
    case 'Multiple':
      return <MultipleFeesForm index={index} accounts={accounts} />
    case 'Interval':
      return <IntervalFeesForm index={index} accounts={accounts} />
    case 'Interest':
      return <InterestOnlyForm index={index} />
    default:
      return null
  }
}

const FeeTypeOptionForm = ({ option, index, accounts, values }) => {
  return <div style={optionStyle.container}>
    <RenderForm index={index} accounts={accounts} />
  </div>
}

export default FeeTypeOptionForm;
