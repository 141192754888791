import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const ROLE_FIELDS = `
  id
  name
  hiddenAt
  canBeHidden
`;

const ROLE_FIELDS_AND_ERRORS = `
  role {
    ${ROLE_FIELDS}
  }
  ${ERRORS}
`;

export const ROLES = gql`
  query Roles($scopes: [String!]) {
    roles(
      scopes: $scopes
    ) {
      ${ROLE_FIELDS}
    }
  }
`;

export const HIDE_ROLE = gql`
  mutation HideRole($id: ID!) {
    hideRole(
      id: $id
    ) {
      ${ROLE_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_ROLE = gql`
  mutation UnhideRole($id: ID!) {
    unhideRole(
      id: $id
    )
    {
      ${ROLE_FIELDS_AND_ERRORS}
    }
  }
`;
