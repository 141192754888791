import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useAlert } from '../../../hooks/useAlert';
import { roleSchema, RoleForm } from './Form';
import { handleFormatting } from './functionality';
import { Spacing, Typography } from '../../../styles';
import { useHistory } from 'react-router-dom';
import Loading from '../../../elements/Loading';
import { ROLES } from './queries'
import * as Formatting from '../../../styles/formatting';
import { titleCase } from '../../../utils/stringHelpers';

const CREATE_ROLE = gql`
 mutation CreateRole($name: String!, $policies: JSON) {
   createRole (
     name: $name
     policies: $policies
   ) {
     role { id, name, policies }
     errors { message, path }
   }
 }
`;

const New = () => {
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [createRole] = useMutation(CREATE_ROLE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ROLES
        }
      ],
      onCompleted(data) {
        const key = Object.keys(data)[0];
        const errors = data[key].errors;
        if (errors?.length) {
          setFormSubmitting(false);
          let errorString = '';
          let errorName = '';
          let errorMessage = '';
          errors.forEach((error, index) => {
            errorName = error.path[1];
            errorMessage = error.message;
            const separator = index > 0 ? ', ' : ''
            errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`
          })
          setServerSideErrors(errorString)
        } else {
          setFormSubmitting(false);
          history.push({
            pathname: `/system/roles`,
            state: { alert: true, type: 'created' },
          })
        }
      }
    }
  );

  const handleSubmit = (values) => {
    if (values.options.flat().length > 0) {
      handleFormatting(values)
    }

    setFormSubmitting(true)
    createRole({ variables: values })
  };

  const NewForm = () => {
    return (
      <>
        <Formik
          initialValues={{
            name: '',
            policies: [[]],
            options: [[]],
          }}
          validationSchema={roleSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            isInvalid,
            errors,
            setValues
          }) => (
              <RoleForm />
            )}
        </Formik>
      </>
    )
  }

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formWrapper, { paddingLeft: '40px', marginTop: '40px' }}>
          <h1 style={Typography.formHeader}>Create a New Role</h1>
        </div>
        <ServerSideErrors />
        <NewForm />
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <Helmet>
        <title>New Role</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default New;
