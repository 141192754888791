import React from "react";
import * as BasicForm from "../../elements/forms";

const DynamicInputs = ({ touched, index, handleBlur }) => {
  return ['interest', 'insurance'].map((type) => {
    return <td key={type}>
      <BasicForm.WriteOffInput
        name={`quoteCarsAttributes[${index}][${type}]`}
        type="number"
        touched={touched}
        onBlur={handleBlur}
        onFocus={(e) => e.target.select()}
      />
    </td>
    });
};

export default DynamicInputs;
