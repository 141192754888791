import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from 'components/src/styles';

const Header = ({title}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div style={Typography.pageTitle}>{title}</div>
    </>
  );
};

export default Header;
