import React from "react";
import Deltas from '../../../Deltas';

const index = ({ termsAttributes }) => {
  const deltas = termsAttributes.map((attribute) => attribute.deltas).flat();

 return <Deltas deltas={deltas} />;
};

export default index;
