import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Typography } from '../../../../styles';
import TxnLinesTable from 'components/src/components/Transactions/Lines/Table';
import { ON_ACCOUNT_TXN_LINES } from '../../../../queries/viewQueries';
import DataState from '../../../DataState';
import { Helmet } from 'react-helmet';
import * as Buttons from '../../../../elements/buttons';
import { Container, Row, Col } from 'react-bootstrap';
import NewWithdrawalAdjustment from '../Withdraw/Modal';
import NewCredit from './Credit/Modal';
import * as StringHelper from '../../../../utils/stringHelpers';
import AuthContext from '../../../../contexts/AuthContext';

const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      onAccountBalance
    }
  }
`;

const txnLinesColumns = [
  'earnedOn', 'createdDate', 'flooredCar',
  'txn', 'purpose', 'gl', 'account', 'debit', 'credit', 'memo'
];

const OnAccount = () => {
  const id = parseInt(useParams().id);

  const { loading, error, data } = useQuery(ON_ACCOUNT_TXN_LINES, { variables: { dealerId: id, accountId: 15 } });
  const { loading: dealerLoading, error: dealerError, data: dealerData } = useQuery(DEALER, { variables: { id }})
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showCreditModal, setShowCreditModal] = useState(false)
  const handleShowWithdraw = () => setShowWithdrawModal(true);
  const handleShowCredit = () => setShowCreditModal(true);

  const auth = useContext(AuthContext);

  if ((loading || dealerLoading) && (!data || !dealerData)) return <DataState.Loading />;
  if (error || dealerError) return <DataState.Error error={error || dealerError} />;

  const { txnLines } = data;
  const balance = dealerData.dealer.onAccountBalance;
  const dealerName = dealerData.dealer.name;

  let withdrawButton;
  let creditButton;

  if (auth.hasPolicy('Txn', 'create')) {
    withdrawButton = <Buttons.Link text="Withdraw" onClick={() => handleShowWithdraw()} />
    creditButton = <Buttons.Link text="Credit" onClick={() => handleShowCredit()} />
  }

  return (
    <>
      <Helmet>
        <title>On Account</title>
      </Helmet>
      <div>
      <div className='col-xl-2'>
        <Row className='info-row'>
          <Col>
            {withdrawButton}
          </Col>
          <Col align="right">
            {creditButton}
          </Col>
        </Row>
        <Row className='info-row'>
          <Col>
            On Account Balance
          </Col>
          <Col align='right'>
            {StringHelper.currency(balance)}
          </Col>
        </Row>
      </div>
      {
        showWithdrawModal && <NewWithdrawalAdjustment
          type="On Account"
          showWithdrawModal={showWithdrawModal}
          setShowWithdrawModal={setShowWithdrawModal}
          dealerId={id}
          balance={balance}
        />
      }
      {
        showCreditModal && <NewCredit
          showModal={showCreditModal}
          setShowModal={setShowCreditModal}
          dealer={dealerData.dealer}
        />
      }
      <TxnLinesTable
        txnLines={txnLines}
        currencyCheckbox={false}
        shownColumns={txnLinesColumns}
      />;
    </div>
  </>
  )
}

export default OnAccount;
