import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataTables from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import AuthLink from 'components/src/components/AuthLink';
import {
  APPROVED_SOURCES, HIDE_APPROVED_SOURCE, UNHIDE_APPROVED_SOURCE
} from './queries';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { titleCase } from '../../utils/stringHelpers';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert from 'components/src/elements/BasicAlert';
import { preflightTableStyles } from 'components/src/styles';

const ApprovedSources = props => {
  const [showHidden, setShowHidden] = useState(false);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data, refetch } = useQuery(
    APPROVED_SOURCES,
    { variables: { scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_APPROVED_SOURCE, { onCompleted: refetch });
  const [unhide] =
    useMutation(UNHIDE_APPROVED_SOURCE, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'name',
      label: 'Name',
      sort: 'asc',
      content: ({ entry, value }) => (
        <Link to={`/approved-sources/${entry.id}`}>{value}</Link>
      ),
    },
    {
      key: 'street',
      label: 'Street',
    },
    {
      key: 'city',
      label: 'City',
    },
    {
      key: 'state',
      label: 'State',
    },
    {
      key: 'zip',
      label: 'Zip Code',
      value: ({ value }) => value?.formatted,
    },
    {
      key: 'primaryContact',
      label: 'Primary Contact',
      value: ({ value }) => value?.fullName,
    },
    {
      key: 'phone',
      label: 'Phone #',
      value: ({ value }) => value?.formatted,
    },
    {
      key: 'type',
      label: 'Type',
      value: ({ value }) => titleCase(value),
    },
    {
      key: 'agreementOnFile',
      label: 'ICA',
      value: ({ value }) => value ? 'Yes' : 'No',
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix="/approved-sources"
          resource={entry}
          hide={hide}
          unhide={unhide}
        />
      )
    },
  ]

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="Approved Source" type={alertType} />
      )}
      <ResourcesHeader
        title="Approved Sources"
        prefix="/approved-sources"
        resourceType="ApprovedSource"
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />

      <DataTables.Simple
        data={data.approvedSources}
        columns={columns}
        pageLength={10}
        htmlTable
        movableColumns
        multiSort
        styles={preflightTableStyles}
      />
    </>
  );
};

export default ApprovedSources;
