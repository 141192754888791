import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Field} from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { useAlert } from '../../../../hooks/useAlert';
import BasicAlert from '../../../../elements/BasicAlert';
import { ErrorMessage } from '../../../../elements/ErrorMessage';
import { classNames } from '../functionality';
import { Typography, Spacing } from '../../../../styles';
import * as yup from 'yup';
import { Colors } from '../../../../styles';
import '../../../../styles/baseRates.css';
import DataState from '../../../DataState';

const NEW_BASE_RATE_CHANGE = gql`
   mutation CreateBaseRateChanges($baseRateId: Int!, $effectiveOn: ISO8601Date!, $rate: Decimal!)
     {
      createBaseRateChanges(
       baseRateId: $baseRateId
       effectiveOn: $effectiveOn
       rate: $rate
     ) {
       baseRateChange { id, rate, effectiveOn }
       errors { message, path }
     }
   }
`

const BASE_RATE = gql`
  query BASE_RATE($id: ID!) {
      baseRate(id: $id) {
        id
        name
        baseRateChanges { id, rate, effectiveOn }
        createdAt
        hiddenAt
        updatedAt
      }
    }`

const BASE_RATES = gql`
  query {
      baseRates {
        id
        name
        baseRateChanges {
          id
          rate
          effectiveOn
        }
      }
    }
`

const NewBaseRateChange = () => {
  const baseRateId = parseInt(useParams().id)
  const [redirect, setRedirect] = useState(false);
  const [showAlert, displayAlert] = useAlert();
  const [newBaseRateChange] = useMutation(
    NEW_BASE_RATE_CHANGE,
    {
      update(cache, { data })
      {
        cache.writeQuery({
          query: BASE_RATES,
          data: {
            baseRates: {
              id: baseRateId,
              name: baseRate.name,
              createdAt: baseRate.createdAt,
              updatedAt: baseRate.updatedAt,
              hiddenAt: baseRate.hiddenAt,
              __typename: baseRate.__typename,
              baseRateChanges: [
                ...baseRate.baseRateChanges,
               {
                 id: data.createBaseRateChanges.baseRateChange.id,
                 rate: data.createBaseRateChanges.baseRateChange.rate,
                 effectiveOn: data.createBaseRateChanges.baseRateChange.effectiveOn,
                 __typename: data.createBaseRateChanges.baseRateChange.__typename
               }
            ]}
           }
        })

          setRedirect(true);
        }
      }
    );

  const { loading, error, data } = useQuery(BASE_RATE, { variables: { id: baseRateId } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { baseRate } = data;

  const today = new Date()

  const schema = yup.object({
      rate: yup.number().min(0, 'Must be positive').required('Required'),
      effectiveOn: yup.date().min(today, 'The date must be in the future').required('Required')
    });

  function BaseRateChangeForm() {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/system/base-rates/${baseRateId}`,
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          baseRateId: baseRateId,
          rate: 0,
          effectiveOn: ''
        }}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isInvalid,
        errors,
        setValues
      }) => (
        <Form noValidate onSubmit={handleSubmit} style={{paddingRight: 40, paddingLeft: 40}}>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>{`Create A Base Rate Change For: ${baseRate.name}`}</h1>
          </div>
        </div>
          <Form.Label>Rate</Form.Label>
          <Form.Group className='term-line-errors'>
            <Field className={classNames(errors, touched, 'rate')} type="number" name="rate" />
            <ErrorMessage name={"rate"} />
          </Form.Group>
          <Form.Label>Effective On</Form.Label>
          <Form.Group className='term-line-errors'>
            <Field className={classNames(errors, touched, 'effectiveOn')} type="date" name="effectiveOn" />
            <ErrorMessage name={"effectiveOn"} />
          </Form.Group>
           <Button
            type="submit"
            style={buttonColor}
          >
            Create Base Rate Change
          </Button>
        </Form>
      )}
      </Formik>
    )
  }

  const buttonColor = {
     backgroundColor: Colors.xlGreen,
     borderColor: Colors.xlGreen,
     color: 'white'
  }

  const handleSubmit = (values) => {
    newBaseRateChange({
      variables: values
    })
  }

  return (
    <>
    <Helmet>
      <title>New Base Rate</title>
    </Helmet>
    {showAlert &&
      <BasicAlert componentType="base rate" type="create" />
    }
    <BaseRateChangeForm />
    </>
  );
};

export default NewBaseRateChange;
