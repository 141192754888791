import React, { useEffect, useState, useRef, useContext, TouchableOpacity } from 'react';
import { useMutation } from '@apollo/client';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationSquare, faCars } from '@fortawesome/pro-solid-svg-icons';
import { UserContext } from '../contexts/UserContext';
import { gql } from '@apollo/client';

const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($currentMarketScopeId: Int) {
    updateCurrentUser(
      currentMarketScopeId: $currentMarketScopeId
    ) {
      user {
        id
        currentMarketScope {
          id
          name
        }
      }
    }
  }
`;

const nameLine = {
  paddingLeft: '10px',
  paddingRight: '10px'
}

const optionNameText = {
  fontSize: '0.9rem',
  marginLeft: '5px'
}

const optionLines = {
  paddingLeft: '10px',
  paddingRight: '10px',
  fontSize: '0.9rem',
  display: 'flex',
  flexWrap: 'wrap',
}

const pipeSeparator = {
  marginLeft: '5px',
  marginRight: '5px'
}

const ESResultOption = (props) => {
  const user = useContext(UserContext);
  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER,
    {
      onCompleted(data) {
        const { currentMarketScope } = data.updateCurrentUser.user;
        user.setCurrentMarket({
          id: currentMarketScope.id,
          name: currentMarketScope.name,
        })
      }
    }
  );
  const history = useHistory();
  let forceRefresh = null;
  let { option, hideMenu } = props;
  let optionType = option._index;
  const marketIds = props;
  // const redirectTo = (e, path) => {
  //   e.preventDefault();
  //   history.push(path);
  // }

  const switchMarket = async (newMarketId) => {
    await updateCurrentUser({ variables: { currentMarketScopeId: parseInt(newMarketId) } });
  }

  const checkSwitchMarket = (marketId, forceRefresh, redirectTo) => {
    // if(marketIds !== null && user.currentMarket && user.currentMarket.id !== option.market_id) {
    //   switchMarket(marketId).then(() => {
    //     history.push(redirectTo);
    //     hideMenu();
    //   });
    // }else {
      history.push(redirectTo);
      hideMenu();
      location.reload();
    // }
  }

  switch(optionType) {
    case 'auctions':
      forceRefresh = useRouteMatch('/auctions/')
      return (
        <div style={nameLine}>
          <i className="fas fa-gavel"></i>
          <Link to={`/auctions/${option._id}?forceRefresh=${forceRefresh !== null ? true : false}`}
            style={optionNameText} onClick={() => hideMenu()}
          >
            {option._source.name}
          </Link>
        </div>
      )
      break;
    case 'approved_sources':
      forceRefresh = useRouteMatch('/approved_sources/')
      return (
        <div style={nameLine}>
          <i className="fas fa-building"></i>
          <Link to={`/approved-sources/${option._id}?forceRefresh=${forceRefresh !== null ? true : false}`}
            style={optionNameText} onClick={() => hideMenu()}
          >
            {option._source.name}
          </Link>
        </div>
      )
      break;
    case 'dealers':
      forceRefresh = useRouteMatch('/dealers/')
      return (
        <div>
          <div className='es-option-top-line' style={nameLine}>
            <i className="fas fa-car"></i>
            <Link style={optionNameText}
              onClick={() => checkSwitchMarket(option._source.market_id,
                forceRefresh,
                `/dealers/${option._id}?forceRefresh=${forceRefresh !== null ? true : false}`) }>
              {option._source.name}
            </Link>
          </div>
          <div className='es-option-second-line' style={optionLines}>
            <Link onClick={() => checkSwitchMarket(option._source.market_id,
                forceRefresh,
                `/system/branches/${option._source.branch_id}/edit`)}
              className='es-link'>
              {option._source.branch_name}
            </Link>
            <div style={pipeSeparator}>{' | '}</div>
            <Link onClick={() => checkSwitchMarket(option._source.market_id,
                forceRefresh,
                `/system/markets/${option._source.market_id}/edit`)}
              className='es-link'>
              {option._source.market_name}
            </Link>
          </div>
          <div className='es-option-link-line' style={optionLines}>
            <Link className='es-link'
               onClick={() => checkSwitchMarket(option._source.market_id,
                forceRefresh,
                `/dealers/${option._id}/cars/new`)}>
              Floor Car
            </Link>
            <div style={pipeSeparator}>{' | '}</div>
            <Link className='es-link'
               onClick={() => checkSwitchMarket(option._source.market_id,
                forceRefresh,
                `/dealers/${option._id}/payments`)}>
              Setup Payment
            </Link>
            <div style={pipeSeparator}>{' | '}</div>
            <Link className='es-link'
               onClick={() => checkSwitchMarket(option._source.market_id,
                forceRefresh,
                `/dealers/${option._id}/edit`)}>
              Edit Dealer
            </Link>
          </div>
        </div>
      )
      break;
    case 'dealer_people':
      forceRefresh = useRouteMatch('/dealer_people/')
      return (
        <div>
          <div className='es-option-top-line' style={nameLine}>
            <i className="fas fa-users"></i>
            <Link to={`/dealers/${option._source.dealer_id}/people/${option._id}/edit`}
              style={optionNameText} onClick={() => hideMenu()}
            >
              {option._source.full_name}
            </Link>
          </div>
          <div className='es-option-link-line' style={optionLines}>
            <Link to={ `/dealers/${option._source.dealer_id}` }
              className='es-link' onClick={() => hideMenu()}>
              {option._source.dealer_name}
            </Link>
          </div>
        </div>
      )
      break;
    case 'floored_cars':
      forceRefresh = useRouteMatch('/floored_cars/')
      return (
        <div>
          <div className='es-option-top-line' style={nameLine}>
            <FontAwesomeIcon icon={faCars} />
            <Link
              style={optionNameText}
              onClick={() => checkSwitchMarket(option._source.market_id,
                forceRefresh,
                `/dealers/${option._source.dealer_id}/cars/${option._id}?forceRefresh=${forceRefresh !== null ? true : false}`)}
            >
              {`${option._source.name}`}
            </Link>
          </div>
          <div className='es-option-vin-line' style={optionLines}>
            {option._source.vin}
          </div>
          <div className='es-option-link-line' style={optionLines}>
            <Link
              className='es-link'
              onClick={() => checkSwitchMarket(option._source.market_id,
                forceRefresh,
                `/dealers/${option._source.dealer_id}?forceRefresh=${forceRefresh !== null ? true : false}`)}
            >
              {option._source.dealer_name}
            </Link>
          </div>
        </div>
      )
      break;
    case 'users':
      forceRefresh = useRouteMatch('/users/')
      return (
        <div>
          <div className='es-option-top-line' style={nameLine}>
            <i className="fas fa-users"></i>
            <Link to={`/system/users/${option._id}/edit`}
              style={optionNameText} onClick={() => hideMenu()}
            >
              {option._source.full_name}
            </Link>
          </div>
          <div className='es-option-vin-line' style={optionLines}>
            {option._source.email}
          </div>
        </div>
      )
      break;
    case 'vendors':
      forceRefresh = useRouteMatch('/vendors/')
      return (
        <div style={nameLine}>
          <i className="fas fa-gavel"></i>
          <Link to={`/vendors/${option._id}?forceRefresh=${forceRefresh !== null ? true : false}`}
            style={optionNameText} onClick={() => hideMenu()}
          >
            {option._source.name}
          </Link>
        </div>
      )
      break;
    default:
      return false;
      break;
  }
}

export default ESResultOption;
