import React from "react";
import { useParams } from 'react-router-dom';
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import Deltas from "../../../Deltas";
import DataState from '../../../DataState';

const RATE_PROGRAM = gql`
  query RateProgram($id: ID!) {
    rateProgram(id: $id) {
      name
      deltas {
        id
        action
        data
        isImport
        createdAt
        updatedAt
        user
      }
    }
  }
`;

const index = () => {
  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(RATE_PROGRAM, { variables: { id } });

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

 const { deltas, name } = data.rateProgram;

  return <Deltas
    deltas={deltas}
    resourceName={name}
   />;
};

export default index;
