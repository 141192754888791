import React from 'react';
import { Link } from 'react-router-dom';
import DataState from '../../DataState';
import { useQuery } from '@apollo/client';
import { REPORT_DATA } from './queries';
import dasherizeKeys from 'components/src/utils/dasherizeKeys';
import { currency, dashToCamelCase } from 'components/src/utils/stringHelpers';


const Item = ({ value, style, url }) => {
  const itemStyle = {};

  if (style) {
    if (style.b) {
      itemStyle.fontWeight = 'bold';
    }

    if (style.i) {
      itemStyle.fontStyle = 'italic';
    }

    if (style.sz) {
      itemStyle.fontSize = (style.sz / 11).toFixed(2) + 'rem';
    }

    if (style.alignment?.indent) {
      itemStyle.paddingLeft = style.alignment.indent + 'rem';
    }

    if (style.border && style.border.edges.includes('top')) {
      itemStyle.borderTop = '1px solid black';
    }

    if ('accounting' === style.format) {
      itemStyle.textAlign = 'right';
      value = currency(value);
    }

    if ('percentage' === style.format) {
      itemStyle.textAlign = 'right';
      value = (value * 100).toFixed(2) + '%';
    }
  }

  if (url) {
    value = <Link to={url}>{value}</Link>
  }

  return (
    <td style={itemStyle}>{value}</td>
  );
};

const Entry = ({ height, items }) => {
  const entryStyle = height ? { height: `${height}px`} : {};

  return (
    <tr style={entryStyle}>
      { items.map((item, key) => <Item key={key} {...item} />) }
    </tr>
  );
};

const Preview = ({ type, params }) => {
  const { loading, error, data } = useQuery(
    REPORT_DATA, {
      variables: { type: dashToCamelCase(type), params: dasherizeKeys(params) }
    }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { headers, entries } = data.reportData;

  return (
    <table className="financial">
      <thead>
        <tr>
          { headers.map((value, idx) => <th key={idx}>{value}</th>) }
        </tr>
      </thead>
      <tbody>
        { entries.map((entry, idx) => <Entry key={idx} {...entry} />) }
      </tbody>
    </table>
  );
};

export default Preview;
