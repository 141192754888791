import React, { useContext, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DataTables from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from '../DataState';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert from 'components/src/elements/BasicAlert';
import { preflightTableStyles } from 'components/src/styles';
import * as Formatting from 'components/src/styles/formatting';
import Loading from 'components/src/elements/Loading';
import AuthContext from 'components/src/contexts/AuthContext';
import DealerInfo from '../Dealers/DealerInfo';
import ResetDealerPerson from './ResetDealerPerson';
import {
  DEALER_PEOPLE,
  HIDE_DEALER_PERSON,
  UNHIDE_DEALER_PERSON,
  UPDATE_DEALER_PRINCIPAL,
  INVITE_DEALER_USER,
} from './queries';

const DealerPeople = props => {
  const id = parseInt(useParams().id);

  const [showHidden, setShowHidden] = useState(false);
  const [alertType, setAlertType] = useState();
  const [sendingInvite, setSendingInvite] = useState();
  const [resetUserId, setResetUserId] = useState();
  const [showAlert, displayAlert] = useAlert();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data, refetch } = useQuery(
    DEALER_PEOPLE,
    { variables: { id, scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_DEALER_PERSON, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_DEALER_PERSON, { onCompleted: refetch });

  const [makePrincipal] = useMutation(
    UPDATE_DEALER_PRINCIPAL, { onCompleted: refetch }
  );

  const [inviteUser] = useMutation(
    INVITE_DEALER_USER, {
      onCompleted: () => {
        refetch();
        setSendingInvite(false);
      }
    }
  );

  const auth = useContext(AuthContext);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const isPrincipal = person => person.id === data.dealer.principalPerson?.id;
  const prefix = `/dealers/${id}/people`;

  const columns = [
    {
      key: 'firstName',
      label: 'First Name',
    },
    {
      key: 'lastName',
      label: 'Last Name',
    },
    {
      key: 'mobilePhone',
      label: 'Mobile Phone',
      value: ({ value }) => value?.formatted,
    },
    {
      key: 'userProvidedPhoneNumber',
      label: 'User Provided Mobile',
      value: ({ entry }) => entry.dealerUser?.userProvidedPhoneNumber?.formatted,
    },
    {
      key: 'email',
      label: 'Email',
      value: ({ entry }) => entry.dealerUser?.email,
    },
    {
      key: 'homeStreet',
      label: 'Address',
      minViewportWidth: 1600,
    },
    {
      key: 'homeCity',
      label: 'City',
    },
    {
      key: 'homeState',
      label: 'State',
    },
    {
      key: 'homeZip',
      label: 'Zip',
      minViewportWidth: 1600,
      value: ({ value }) => value?.formatted,
    },
    {
      key: 'dealerPrincipal',
      label: 'Dealer Principal?',
      value: ({ entry }) => isPrincipal(entry) ? 'Yes' : ''
    },
    {
      key: 'role',
      label: 'Role',
    },
    {
      key: 'title',
      label: 'Title',
    },
    {
      key: 'userStatus',
      label: 'User Status',
      content: ({ entry, value }) => {
        if (
          'Invite User' === value ||
          'Resend?' === value.substr(value.length - 7)
        ) {
          return (
            <a
              href="#"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setSendingInvite(
                  `${entry.fullName} (${entry.dealerUser.email})`
                );
                inviteUser({ variables: { id: entry.dealerUser.id } })
              }}
            >
              {'Invite User' === value ? 'Send Invitation Email' : value}
            </a>
          );
        } else {
          return value;
        }
      }
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix={prefix}
          resource={entry}
          hide={hide}
          unhide={unhide}
          noRead
        >
          {auth.hasPolicy('DealerPerson', 'update') && !entry.hiddenAt && (
            <>
              {!isPrincipal(entry) && (
                <a
                  href="#"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    makePrincipal({
                      variables: {id, principalPersonId: entry.id}
                    });
                  }}
                >
                  {' | Make Principal'}
                </a>
              )}
              {entry.dealerUser && (
                <a
                  href="#"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setResetUserId(entry.dealerUser.id);
                  }}
                >
                  {' | Reset'}
                </a>
              )}
            </>
          )}
        </ResourceActionLinks>
      )
    },
  ];

  if (resetUserId) {
    return <ResetDealerPerson dealerUserId={resetUserId} />
  }

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="Dealer Person" type={alertType} />
      )}

      <DealerInfo />

      <ResourcesHeader
        title={`${data.dealer.name} People`}
        prefix={prefix}
        resourceType="DealerPerson"
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />

      {sendingInvite ? (
        <div style={Formatting.centeredLoadingDiv}>
          <div style={Formatting.loadingText}>
            Sending invitation to {sendingInvite}
          </div>

          <Loading />
        </div>
      ) : (
        <DataTables.Simple
          data={data.dealer.dealerPeople}
          columns={columns}
          pageLength="All"
          lengthMenu={ [10, 25, 50, 'All'] }
          htmlTable
          movableColumns
          styles={preflightTableStyles}
        />
      )}
    </>
  )
};

export default DealerPeople;
