import React, { useContext, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useAlert } from '../hooks/useAlert';
import { Helmet } from 'react-helmet';
import DataState from './DataState';
import { setAlertData } from '../utils/setAlertData';
import { useQuery } from '@apollo/client';
import { NOTIFICATIONS } from '../queries/viewQueries';
import { CURRENT_USER } from '../queries/commonQueries';
import Moment from 'react-moment';
import moment from 'moment';
import * as Buttons from '../elements/buttons';
import { useMutation } from '@apollo/client';
import './notification.css';
import TrackVisibility from 'react-on-screen';
import { DateHelpers } from "../utils";
import { Row, Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import * as Colors from '../styles/colors';
import { saveAs } from 'file-saver';
import { isIpad13, isTablet, isMobile, isChrome } from "react-device-detect";

const ERRORS = `
  errors {
    message
    path
  }
`;

const HIDE_NOTIFICATION = gql`
  mutation HideNotification($id: ID!) {
    hideNotification(
      id: $id
    ) {
      notification {
        id
      }
      ${ERRORS}
    }
  }
`;

const HIDE_ALL_NOTIFICATIONS = gql`
  mutation HideAllNotifications(
    $beforeToday: Boolean
  ) {
    hideAllNotifications(
      beforeToday: $beforeToday
    ) {
      ${ERRORS}
    }
  }
`;

const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $id: ID!,
    $viewedAt: ISO8601DateTime!
  ) {
    updateNotification(
      id: $id
      viewedAt: $viewedAt
    ) {
      notification {
        id
        viewedAt
      }
    }
  }
`;

const green = {
  color: Colors.xlGreen,
  paddingTop: "10px",
  paddingLeft: "10px"
}

const orange = {
  color: Colors.xlOrange,
}

const HideNotifications = props => {
  const {data, ownerId, setShowToday, showToday, refetch} = props;

  const [hideMutation] = useMutation(
    HIDE_ALL_NOTIFICATIONS, { onCompleted: refetch }
  );

  const hideAll = () => {
    hideMutation();
  }

  const hidePrior = () => {
    hideMutation({ variables: { beforeToday: true } });
  }

  const viewAll = () => {
    setShowToday(false)
  }

  const viewToday = () => {
    setShowToday(true)
  }

  let note;

  if (showToday === true) {
    note = "Todays Notifications"
  } else if (showToday === false) {
    note = "All Notifications"
  }

  return (
    <>
      <h5 style={orange}>{note}</h5>
      <Row>
        <Col align="left" style={{paddingLeft: "10px"}}>
          <Buttons.Link text="Clear All" onClick={() => hideAll()} style={{paddingRight: "15px"}} />
            |
          <Buttons.Link text="Clear All Prior To Today" onClick={() => hidePrior()} style={{paddingLeft: "15px"}} />
        </Col>
        <Col align="right" style={{paddingRight: "10px"}}>
          <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
            <ToggleButton onClick={() => viewAll()} value={1}>View All</ToggleButton>
            <ToggleButton onClick={() => viewToday()} value={2}>View Today</ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
    </>
  )
}

const Notification = ({ notification, isVisible, refetch }) => {
  let now = moment();
  const [updateMutation] = useMutation(UPDATE_NOTIFICATION)
  const [hideMutation] = useMutation(
    HIDE_NOTIFICATION, { onCompleted: refetch }
  );

  if (isVisible) {
    updateMutation({ variables: { id: notification.id, viewedAt: now } });
  }

  let linkText;
  if (notification.link != '' && notification.link != null) {
    linkText = "Click here to view."
  } else {
    linkText = ""
  }

  var FileSaver = require('file-saver');

  const downloadFile = () => {
    FileSaver.saveAs(notification.link, notification.link.split("/").splice(-1))
  }

  const ReportButton = () => {
    if (isIpad13 || isTablet || isMobile && isChrome && notification.link.split(".").splice(-1)[0] === "xlsx") {
      return (
        <a onClick={() => downloadFile()} className="alert-link">
          <div>
            {linkText}
          </div>
        </a>
      )
    } else {
      return (
        <a href={notification.link} className="alert-link">
          <div>
            {linkText}
          </div>
        </a>
      )
    }
  }

  return (
    <div className="alert alert-primary" role="alert" key={notification.id}>
      {notification.notification}
      <ReportButton />
      <div>
        {DateHelpers.setTime(notification.createdAt)}
      </div>
      <div className="topcorner">
        <Buttons.Link text="X" onClick={() => hideMutation({ variables: { id: notification.id } })} />
      </div>
    </div>
  )
}

const UserNotification = (props) => {
  const { loading, error, data, refetch } = useQuery(NOTIFICATIONS, { variables: { ownerId: parseInt(props.ownerId) } })
  const [showToday, setShowToday] = useState(false);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  let today = new Date();

  let date = today.getDate() + "-"+ parseInt(today.getMonth()+1) +"-"+today.getFullYear();

  return (
    data.notifications.length === 0
      ? <div className="zero">You have no new notifications.</div>
      : <div className="container pt-3">
        <div style={{paddingBottom: "20px"}}>
          <HideNotifications
            data={data}
            ownerId={props.ownerId}
            setShowToday={setShowToday}
            showToday={showToday}
            refetch={refetch}
          />
        </div>
        <div className="mx-5">
          {data.notifications.map(notification => {
            if (showToday === true) {
              let noteDate = new Date(notification.createdAt)
              let newNoteDate = noteDate.getDate() + "-" + parseInt(noteDate.getMonth() + 1) + "-" + noteDate.getFullYear();

              if (newNoteDate === date) {
                return (
                  <TrackVisibility once key={notification.id}>
                    <Notification
                      notification={notification}
                      refetch={refetch}
                    />
                  </TrackVisibility>
                )
              }
            } else {
              return (
                <TrackVisibility once key={notification.id}>
                  <Notification
                    notification={notification}
                    refetch={refetch}
                  />
                </TrackVisibility>
              )
            }
          })}
        </div>
      </div>
  )
}

const Notifications: () => React$Node = (props) => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();
  const { loading, error, data } = useQuery(CURRENT_USER);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const ownerId = data.currentUser.id;

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <h3 style={green}>Notifications</h3>
      <UserNotification ownerId={ownerId} />
    </>
  );
};

export default Notifications;
