import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import AuthContext from '../../contexts/AuthContext';
import DataTables from '@preflighttech/preflight-tables';
import { preflightTableStyles } from 'components/src/styles';
import Filter from 'components/src/elements/Filter';
import { rest } from 'components/src/utils/rest';
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';

import NewVoid from '../Modals/Void/New';
import LinesModal from './Lines/Modal';

import { columnDefinitions } from './columnDefinitions';
import BasicAlert, {
  ExportToExcelAlert
} from 'components/src/elements/BasicAlert';
import { useAlert } from 'components/src/hooks/useAlert';
import { FILTER_TYPES } from 'components/src/elements/Filter/constants';

import { TXNS_WITH_COUNT } from 'components/src/queries/indexQueries';

const Flooring = props => {
  const {
    shownColumns, availableFilters, scopes, referrerType, referrerId,
    reportPath, disableExportToExcel
  } = props;

  const auth = useContext(AuthContext);

  const [showAlert, displayAlert] = useAlert();
  const [customAlert, displayCustomAlert] = useState(false);
  const [voidModalTxn, setVoidModalTxn] = useState(false);
  const [linesModalTxn, setLinesModalTxn] = useState(false);
  const [printReceiptTxn, setPrintReceiptTxn] = useState(false);
  const [lastDownloadTime, setLastDownloadTime] = useState(null);
  const [lastDownloadId, setLastDownloadId] = useState(null);
  const [filterOptions, setFilterOptions] = useState();
  const [filters, setFilters] = useState({});
  const [settings, setSettings] = useState({});

  const columns = columnDefinitions({
    auth,
    setVoidModalTxn, setLinesModalTxn, setPrintReceiptTxn, printReceiptTxn,
    printReceiptTxn, setPrintReceiptTxn, lastDownloadTime, setLastDownloadTime,
    referrerType, referrerId,
    columns: shownColumns
  });

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);
  }

  const queryVariables = {scopes: scopes, filterColumns: [], filterNames: []};

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      queryVariables['filterColumns'].push(key);
      queryVariables['filterNames'].push(value);
    }
  });

  if (referrerType && referrerId) {
    if ('car' === referrerType) {
      queryVariables['filterColumns'].push('floored_car_id');
    } else {
      queryVariables['filterColumns'].push(`${referrerType}_id`);
    }
    queryVariables['filterNames'].push(referrerId);
  }

  const handleExportToExcel = () => {
    rest({
      path: (reportPath || 'accruals-report'),
      params: {
        referrerType,
        referrerId,
        scopes,
        search: settings.search,
        sort: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.sort,
        current_filters: queryVariables,
      },
    }).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      {!disableExportToExcel &&
        <ExportToExcel handleExportToExcel={handleExportToExcel} />
      }
      {
        availableFilters &&
          <Filter clearFilters={() => setFilters({})}>
            {
              availableFilters.includes('dealer') &&
                <Filter.Select
                  columnKey="dealer"
                  label="Dealer"
                  options={filterOptions?.dealers}
                  filters={filters}
                  setFilter={setFilter}
                />
            }
            {
              availableFilters.includes('payment_type') &&
                <Filter.Select
                  columnKey="payment_type"
                  label="Payment Type"
                  options={filterOptions?.paymentTypes}
                  filters={filters}
                  setFilter={setFilter}
                />
            }
            {
              availableFilters.includes('purpose') &&
                <Filter.Select
                  columnKey="purpose"
                  label="Purpose"
                  options={filterOptions?.purposes}
                  filters={filters}
                  setFilter={setFilter}
                />
            }
            {
              availableFilters.includes('created_by') &&
                <Filter.Select
                  columnKey="created_by"
                  label="Created By"
                  options={filterOptions?.createdBy}
                  filters={filters}
                  setFilter={setFilter}
                />
            }
            {
              availableFilters.includes('bank_date') &&
                <Filter.DateRange
                  columnKey="bank_date"
                  label="Bank Date"
                  filters={filters}
                  setFilter={setFilter}
                />
            }
            {
              availableFilters.includes('created_date') &&
                <Filter.DateRange
                  columnKey="created_date"
                  label="Created Date"
                  filters={filters}
                  setFilter={setFilter}
                />
            }
          </Filter>
      }
      <ResetSettings />
    </View>
  );

  const lengthMenu = [10, 25, 50];
  if (referrerType && referrerId) { lengthMenu.push('All'); }

  return (
    <>
      { showAlert && <BasicAlert componentType='transaction' type='voided' /> }
      { customAlert && <ExportToExcelAlert /> }
      {
        voidModalTxn &&
          <NewVoid
            txn={voidModalTxn}
            showModal={true}
            setShowModal={setVoidModalTxn}
            displayAlert={displayAlert}
          />
      }
      {
        linesModalTxn &&
          <LinesModal
            txn={linesModalTxn}
            referrerType={referrerType}
            referrerId={referrerId}
            setTxn={setLinesModalTxn}
          />
      }
      <DataTables.Gql
        query={TXNS_WITH_COUNT}
        queryName="txnsWithCount"
        queryVariables={queryVariables}
        entriesName="txns"
        columns={columns}
        buttons={buttons}
        pageLength={10}
        lengthMenu={lengthMenu}
        dataUpdated={data => setFilterOptions(data?.txnsWithCount)}
        setSettings={setSettings}
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Flooring;
