import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import DataState from '../../DataState';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ProcessEditTempProgram } from './EditForm';
import { DateHelpers } from '../../../utils';
import { TEMP_PROGRAM, UPDATE_TEMP_PROGRAM } from './Queries';

const Edit = props => {
  const { tempId, showModal, setShowModal } = props

  const id = tempId

  const [formSubmitting, setFormSubmitting] = useState(false);

  const { loading: loadingTemp, error: tempError, data } = useQuery(
    TEMP_PROGRAM, { variables: { id } }
  );

  const [updateTempProgram, { loading, error }] = useMutation(
    UPDATE_TEMP_PROGRAM, { onCompleted(values) {
      setFormSubmitting(false)
      setShowModal(false)
      window.location.reload();
    }}
  )

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    updateTempProgram({ variables: values })
  }

  if (loading || loadingTemp) return <DataState.Loading />;
  if (error|| tempError) return <DataState.Error error={error || tempError} />;

  const dealerLineId = parseInt(data.tempProgram.dealerLine.id)
  const programType = data.tempProgram.programType
  const programName = data.tempProgram.programName
  const credit = data.tempProgram.creditLimitIncrease
  const today = new Date()
  const yesterday = new Date(today)

  yesterday.setDate(yesterday.getDate() - 1)

  const fullTempOption = yup.object({
    effectiveOn: yup
      .date()
      .min(yesterday, 'date cannot be in the past')
      .required('required'),
    endOn: yup
      .date()
      .min(yup.ref('effectiveOn'), ({ min }) => {
        `date must be after${DateHelpers.setDate(data.tempProgram.effectiveOn)}`
      })
      .required('required'),
  })

  const cancelTempDay = new Date(data.tempProgram.effectiveOn)

  cancelTempDay.setDate(cancelTempDay.getDate() - 1)

  const halfTempOption = yup.object({
    endOn: yup
      .date()
      .min(cancelTempDay, 'date is too far in the past')
      .required('required'),
  })

  let schema;

  if (new Date(data.tempProgram.effectiveOn) > today) {
    schema = fullTempOption;
  } else {
    schema = halfTempOption;
  }

  return (
    <Formik
      initialValues={{
        id: tempId,
        dealerLineId: dealerLineId,
        effectiveOn: data.tempProgram.effectiveOn,
        endOn: data.tempProgram.endOn,
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <ProcessEditTempProgram
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        formSubmitting={formSubmitting}
        programType={programType}
        programName={programName}
        credit={credit}
        effectiveOn={data.tempProgram.effectiveOn}
      />
    </Formik>
  )
}

export default Edit;
