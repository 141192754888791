export const renderLabels = (filterData) => {
  if (!filterData) return [];

  let keys = filterData.map((data) => Object.keys(data)).flat();
  keys = keys.map((key) => {
    key = key.split("_");
    key = key.map((word) => capitalize(word)).join(" ");

    return key;
  });

  return keys;
};

export const renderSelectOptions = (filterData) => {
  if (!filterData) return [];

  const data = filterData.map((option) => Object.values(option)).flat();
  return data;
};

export const renderMultiSelectOptions = (multiFilterData) => {
  if (!multiFilterData) return [];

  const data = multiFilterData.map((option) => {
    const values = Object.values(option)[0];

    return values.map((value) => {
      return { value, label: value };
    });
  });

  return data;
};

export const renderCheckboxOptions = (filterCheckboxData) => {
  if (!filterCheckboxData) return [];

  const data = filterCheckboxData.map((option) => Object.values(option)).flat();
  return data;
};

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
