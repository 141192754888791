import React, { useContext, useEffect, useState } from 'react';
import DataTable from '../../elements/DataTable';
import { titleCase } from '../../utils/stringHelpers';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { useAlert } from '../../hooks/useAlert';
import DataState from '../DataState';
import AuthContext from '../../contexts/AuthContext';
import { useParams } from "react-router-dom";
import DealerInfo from '../Dealers/DealerInfo';
import { PLACES, HIDDEN_PLACES } from '../../queries/indexQueries'
import BasicAlert from '../../elements/BasicAlert';
import { setAlertData } from '../../utils/setAlertData';
import { useHideableResource } from '../../hooks/useHideableResource';
import {
  DEALER,
  UPDATE_DEALER,
  HIDE_PLACE,
  UNHIDE_PLACE
} from './Queries';

const DealerPlaces = (props) => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  const dealerId = parseInt(useParams().id);

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_PLACES,
    VISIBLE_QUERY: PLACES,
    HIDE_MUTATION: HIDE_PLACE,
    UNHIDE_MUTATION: UNHIDE_PLACE,
    variables: { dealerId: dealerId }
  };

  const [makePrimary] = useMutation(UPDATE_DEALER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PLACES,
        variables: { dealerId: dealerId },
      }
    ],
  });

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])
  const [
    showHidden,
    setShowHidden,
    loading,
    error,
    data
  ] = useHideableResource(hiddenProps)

  const {
    loading: dealerLoading,
    error: dealerError,
    data: dealerData
  } = useQuery(DEALER, { variables: { id: dealerId } });

  const auth = useContext(AuthContext);

  let currentData = null;
  if (loading && (currentData !== data) || !dealerData || !data)
    return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const primaryPlaceId = dealerData.dealer.primaryPlace?.id;
  const defaultShippingId = dealerData.dealer.defaultMailingLocation?.id

  const dataSet = data.dealerPlaces.map(function (place) {
    const isPrimaryPlace = place.id === primaryPlaceId ? 'Yes' : '';
    let isDefaultShipping = place.id === defaultShippingId ? 'Yes' : '';

    let hiddenLink = showHidden ?
      `| <a href='' id="unhide" data-id=${place.id}>Unhide</a>` :
      `| <a href='' id="hide" data-id=${place.id}>Hide</a>`

    if (!place.canBeHidden) {
      hiddenLink = ''
    }

    const setPrimaryLink = (isPrimaryPlace || place.hiddenAt) ?
      `` :
      `|
        <a href='' id="set-primary${place.id}" data-id=${place.id}>
          Set Primary
        </a>
      `

    const setDefaultLink = (isDefaultShipping || place.hiddenAt) ? '' :
      `
        <a href="" id="set-default${place.id}" data-id=${place.id}>
          Set Default
        </a>
      `

    if (auth.hasPolicy('DealerPlace', 'update')) {
      if (isDefaultShipping === '') {
        isDefaultShipping = setDefaultLink
      }

      place.actions = `
        <a href="/dealers/${dealerId}/places/${place.id}/edit"/>Edit</a>
        ${hiddenLink}
        ${setPrimaryLink}
      `
    }

    $(document).on('click', `#set-primary${place.id}`, function (event) {
      let id = event.target.dataset.id
      id = parseInt(id, 10)
      if (window.confirm(`You are about to change the Primary Location for ` +
          `${dealerData.dealer.name}.  This will affect information sent to ` +
          `QuikTrak and Auction Access.  Are you sure you want to proceed?`)
      ) {
        makePrimary({ variables: { id: dealerId, primaryPlaceId: id } })
      } else {
        return null;
      }
    })

    $(document).on('click', `#set-default${place.id}`, function (event) {
      let id = event.target.dataset.id
      id = parseInt(id, 10)
      if (window.confirm(`You are about to change the Default Shipping ` +
          `Address for ${dealerData.dealer.name}. Are you sure you want to ` +
          `proceed?`)
      ) {
        makePrimary({
          variables: { id: dealerId, defaultMailingLocationId: id }
        })
      } else {
        return null;
      }
    })

    const formattedPhone = place.phone.formatted;
    const formattedZip = place.zip.formatted;

    return [
      titleCase(place.name),
      isPrimaryPlace,
      isDefaultShipping,
      place.type,
      formattedPhone,
      titleCase(place.street),
      titleCase(place.city),
      place.state,
      formattedZip,
      '',
      place.actions || '',
    ]
  })

  const columns = [
    {
      title: 'Name'
    },
    {
      title: "Primary Location?"
    },
    {
      title: "Default Mailing Location"
    },
    {
      title: "Location Type"
    },
    {
      title: "Phone"
    },
    {
      title: "Address"
    },
    {
      title: 'City',
    },
    {
      title: 'State',
    },
    {
      title: 'Zip',
    },
    {
      title: 'Map',
    },
    {
      title: 'Functions'
    },
  ];

  const newRoute = `/dealers/${dealerId}/places/new`
  const dealerName = dealerData.dealer.name;

  return (
    <>
      <Helmet>
        <title>{dealerName}: Places</title>
      </Helmet>
      <DealerInfo />
      {showAlert &&
        <BasicAlert componentType="dealer place" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={`Locations for ${titleCase(dealerName)}`}
        newRoute={newRoute}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
    </>
  )
};

export default DealerPlaces;
