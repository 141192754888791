import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import * as yup from "yup";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import * as BasicForm from "../../elements/forms";
import { DEALER_SELECTS } from "../../queries/selects";
import * as Buttons from "../../elements/buttons";
import { Spacing } from "../../styles";
import TermAttributes from "./TermAttributes";
import { TermButtons } from "./Buttons";
import DataState from "../DataState";

const ACCOUNTS = gql`
  query {
    accounts(scopes: ["income"]) {
      id
      gl
      name
      special {
        key
      }
    }
  }
`;

export const dealerProgramSchema = yup.object({
  code: yup.string().max(25, "Maximum 25 characters").required('Required'),
  description: yup.string().max(80, "Maximum 80 characters").required('Required'),
  termsAttributes: yup.array().of(
    yup.object().shape({
      type: yup.string().required("Required"),
      termDays: yup.number().required("Required"),
      feeType: yup.string().required("Required"),
      termLinesAttributes: yup.array().of(
        yup.object().shape({
          curtailmentPercentage: yup.number().required("Required"),
          termLineFeesAttributes: yup.array().of(
            yup.object().shape({
              accountId: yup
                .number()
                .typeError('Required'),
              amount: yup
                .number()
                .typeError('Required'),
              earnType: yup
                .string()
                .typeError('Required'),
            })
          ),
        })
      ),
    })
  ),
});

export const Form = ( { isEdit }) => {
  const { setFieldValue, errors, values } = useFormikContext();
  const { loading, error, data } = useQuery(ACCOUNTS);

  if (loading || !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const termsAttributes = values.termsAttributes.filter((attr) => !attr._destroy);

  return (
    <FormikForm>
      <BasicForm.TextInput name="code" type="text" label="Fee Program Name" />
      <BasicForm.TextInput
        name="description"
        type="text"
        label="Fee Program Description"
      />
      <p style={{ paddingTop: "10px" }}>
        (ex: "3 terms @ 30 days with 5% curtailment ")
      </p>
      {termsAttributes.map((term, index) => {
      return <div
          key={index}
          style={{ border: "2px solid black", marginBottom: "15px" }}
        >
          <TermAttributes
            accounts={data.accounts}
            term={term}
            index={index}
            values={values}
            key={index}
            isEdit={isEdit}
          />
        </div>
      })}
      <TermButtons />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
};
