import React, { useState, useEffect, useContext } from 'react';
import { Form as FormikForm, useFormikContext } from 'formik';
import Select from 'react-select';
import * as BasicForm from '../../../elements/forms';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';
import { errorStyle } from './style';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';
import { AUCTIONS } from './queries';
import {
  verificationOptions,
  twoUnverifiedOptions
} from './schema';
import AuthContext from '../../../contexts/AuthContext';
import { handleNumberChange } from "../../../utils/stringHelpers";

export const Form = props => {
  const auth = useContext(AuthContext);

  const { values, setFieldValue, errors } = useFormikContext();

  const { loading, error, data } = useQuery(AUCTIONS);

  useEffect(() => {
    if (values.saleType !== 'Finance') {
      setFieldValue('financeCompanyName', undefined)
    }
    if (values.saleType !== 'Auction') {
      setFieldValue('soldToAuctionId', undefined)
    }
    if (values.saleType !== 'Other') {
      setFieldValue('other', undefined)
    }
  }, [values.saleType])

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const handleVerificationChange = (selected) => {
    if (selected.value !== values.verificationMethod) {
      setFieldValue('soldOn', undefined)
      setFieldValue('soldTo', undefined)
      setFieldValue('saleType', undefined)
      setFieldValue('files', undefined)
      setFieldValue('arbitrationReason', undefined)
      setFieldValue('arbitratedOn', undefined)
      setFieldValue('companyName', undefined)
      setFieldValue('personName', undefined)
      setFieldValue('street', undefined)
      setFieldValue('city', undefined)
      setFieldValue('state', undefined)
      setFieldValue('zip', undefined)
      setFieldValue('phone', undefined)
    }

    setFieldValue('verificationMethod', selected.value)
  }

  const handleFile = (file) => {
    if (!values.files) {
      values.files = []
    }

    if (values.files) {
      values.files.push(file)
      setFieldValue('files', values.files)
    }
  }

  const handleRemove = (file) => {
    const newFile = values.files.filter(image => image.path !== file)
    setFieldValue('files', newFile)
  }

  let options;

  if (props.currentAudit.qtStatus === 'AUC') {
    options = verificationOptions
  } else {
    if (props.foundAudits.length === 2) {
      options = twoUnverifiedOptions
    } else {
      options = verificationOptions
    }
  }

  if (props.currentAudit.qtStatus === 'IT') {
    options.push({
      label: 'Recent Purchase < 8 Days',
      value: 'Recent Purchase < 8 Days'
    })
  }

  const locationOptions = [
    'Auction',
    'Phone',
    'In Person',
    'Virtual',
    'Photo With Daily Code',
    'Auction',
  ]

  return (
    <FormikForm>
      <BasicForm.StyledLabel>Verification Method</BasicForm.StyledLabel>
      <Select
        isMulti={false}
        options={options}
        onChange={handleVerificationChange}
      />
      <div style={errorStyle}>
        { errors.verificationMethod ? errors.verificationMethod : '' }
      </div>
      {locationOptions.includes(values.verificationMethod) && (
        <>
          <BasicForm.TextInput
            name='companyName'
            type='text'
            label='Name of Company'
          />
          <BasicForm.TextInput
            name='personName'
            type='text'
            label='Name of Person'
          />
          <BasicForm.TextInput
            name="street"
            type="text"
            label="Street"
          />
          <BasicForm.TextInput
            name="city"
            type="text"
            label="City"
          />
          <BasicForm.StateAbbreviationSelect
            name="state"
            type="text"
            label="State"
          />
          <BasicForm.TextInput
            name="zip"
            type="text"
            label="Zip"
          />
          <BasicForm.PhoneInput
            name="phone"
            country="US"
            value={values.phone || ''}
            onChange={ event =>
              handleNumberChange(event, 'phone', setFieldValue)
            }
            label="Phone Number"
          />
        </>
      )}
      {values.verificationMethod === 'Arbitrated' && (
        <>
          <BasicForm.TextInput
            name='arbitratedOn'
            type='date'
            label='Arbitrated On'
          />
          <BasicForm.TextInput
            name='arbitrationReason'
            type='text'
            label='Arbitration Reason'
          />
        </>
      )}
      {!['Arbitrated', 'Phone'].includes(values.verificationMethod) && (
        <BasicForm.ImageUpload
          name="files"
          handleFile={handleFile}
          label="Image"
          handleRemove={handleRemove}
        />
      )}
      {values.verificationMethod === 'Sold' && (
        <>
          <BasicForm.TextInput
            name='soldOn'
            type='date'
            label='Date Sold'
          />
          <BasicForm.TextInput
            name='soldTo'
            type='text'
            label='Sold To'
          />
          <BasicForm.Select name='saleType' label='Sale Type'>
            <option value="">Select a Sale Type</option>
            <option>Finance</option>
            <option>BHPH</option>
            <option>Cash</option>
            <option>Auction</option>
            <option>Other</option>
          </BasicForm.Select>
          <BasicForm.TextInput
            name="financeCompanyContact"
            type="text"
            label="Finance Company Contact Name"
          />
          <BasicForm.TextInput
            name="financeStreet"
            type="text"
            label="Street"
          />
          <BasicForm.TextInput
            name="financeCity"
            type="text"
            label="City"
          />
          <BasicForm.StateAbbreviationSelect
            name="financeState"
            type="text"
            label="State"
          />
          <BasicForm.TextInput
            name="financeZip"
            type="text"
            label="Zip"
          />
          <BasicForm.PhoneInput
            name="financePhone"
            country="US"
            value={values.financePhone || ''}
            onChange={ event =>
              handleNumberChange(event, 'financePhone', setFieldValue)
            }
            label="Phone Number"
          />
        </>
      )}
      {values.saleType === 'Finance' && (
        <BasicForm.TextInput
          name='financeCompanyName'
          type='text'
          label='Finance Company Name'
        />
      )}
      {values.saleType === 'Auction' && (
        <BasicForm.ProgrammaticSelect
          name='soldToAuctionId'
          label='Auction'
          options={data.auctions}
          optionName='Auction'
        />
      )}
      {values.saleType === 'Other' && (
        <BasicForm.TextInput
          name='other'
          type='text'
          label='Other'
        />
      )}
      <BasicForm.TextInput
        name='note'
        type='text'
        label='Note'
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Standard
          type="submit"
          text="Submit"
        />
      </div>
    </FormikForm>
  )
}
