import { gql } from '@apollo/client';
import { TXN_LINE_FIELDS } from '../../../queries/viewQueries';

const ERRORS = `errors { path, message }`;

const TXN_FIELDS = `
  id
  type
  bankDate
  reference
  name
  purpose
  memo
  entity { id }
  paymentTerm {
    id
    name
  }
  txnLines {
    ${TXN_LINE_FIELDS}
  }
`;

const TXN_FIELDS_AND_ERRORS = `
  txn {
    ${TXN_FIELDS}
  }
  ${ERRORS}
`;

export const TXN_LINE_SELECTS = gql`
  query {
    dealers { id, name }
    accounts { id, name, gl }
    departments { id, name }
    markets {id, name }
  }
`;

export const FLOORED_CARS_BY_DEALER = gql`
  query Dealer($id: ID!){
    dealer(id: $id) {
      flooredCars {
        id,
        name
      }
    }
  }
`

export const FEES_BY_FLOORED_CAR = gql`
  query FlooredCar($id: ID!){
    flooredCar(id: $id) {
      fees {
        id,
        name
      }
    }
  }
`
export const TXN = gql`
  query Txn($id: ID!) {
    txn(id: $id) {
      ${TXN_FIELDS}
    }
  }
`;

export const UPDATE_TXN = gql`
  mutation UpdateTxn(
    $id: ID!
    $type: String!
    $bankDate: ISO8601Date!
    $reference: String
    $name: String
    $purpose: String
    $memo: String
    $entityType: String
    $entityId: Int
    $txnLinesAttributes: [TxnLineInput!]!
    $paymentTermId: Int
    $booksClosedPassword: String
  ) {
    updateTxn (
      id: $id
      type: $type
      bankDate: $bankDate
      reference: $reference
      name: $name
      purpose: $purpose
      memo: $memo
      entityType: $entityType
      entityId: $entityId
      txnLinesAttributes: $txnLinesAttributes
      paymentTermId: $paymentTermId
      booksClosedPassword: $booksClosedPassword
     ) {
       ${TXN_FIELDS_AND_ERRORS}
    }
  }
`;

export const ACCOUNTS = gql`
  query {
    accounts(scopes: ["bank"]) {
      id
      name
      gl
    }
  }
`;

export const PAYABLE_ACCOUNTS = gql`
  query {
    accounts(scopes: ["accounts_payable"]) {
      id
      name
      gl
    }
  }
`;

export const TXN_NAME_FIELD_SELECTS = gql`
  query {
    paymentTerms { id, name, days }
    markets { id, name }
    dealers { id, name }
    auctions { id, name }
    approvedSources { id, name }
    vendors {
      id,
      name,
      paymentTerm {
        id
      },
      payableAccount {
        id
      },
      expenseAccount {
        id
      }
    }
  }
`;
