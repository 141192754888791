import React, { useEffect } from 'react';
import { AdvanceTypeOptions } from '../../Cars/Options/advanceTypeOptions';
import { Form as FormikForm, useFormikContext } from 'formik';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';
import * as yup from 'yup';

export const changeSourceSchema = yup.object({
  sourceType: yup
    .string()
    .required('Required'),
  bankAccountId: yup
    .number()
    .required('Required'),
  sourceId: yup
    .string()
    .required('Required')
})

export const Form = () => {
  const { values, dirty, setFieldValue, isValid } = useFormikContext();

  useEffect(() => {
    setFieldValue('sourceId', values.sourceId)
  }, [values.sourceId])

  useEffect(() => {
    setFieldValue('bankAccountId', values.bankAccountId)
  }, [values.bankAccountId])

  return (
    <FormikForm>
      <AdvanceTypeOptions />
      <div style={Spacing.buttonRow}>
        <Buttons.Standard type="submit" text="Submit" disabled={!dirty} />
      </div>
    </FormikForm>
  )
}
