import React, { useState, Fragment, useContext } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik } from "formik";
import { useHistory, useParams } from 'react-router-dom';
import { Spacing, Typography } from "../../styles";
import { dealerProgramSchema, Form } from "./Form";
import { useServerSideErrors } from "../../hooks/useServerSideErrors";
import Loading from "../../elements/Loading";
import { DEALER_PROGRAM } from "../../queries/viewQueries";
import { DEALER_PROGRAMS } from "./queries";
import { formatInitialValues } from './functions';
import DataState from '../DataState';
import AuthContext from '../../contexts/AuthContext';
import Deltas from './Terms/Deltas';

const UPDATE_DEALER_PROGRAM = gql`
  mutation UpdateDealerProgram(
    $id: ID!
    $code: String!
    $description: String!
    $termsAttributes: [TermInput!]!
  ) {
    updateDealerProgram(
      id: $id
      code: $code
      description: $description
      termsAttributes: $termsAttributes
    ) {
      errors {
        message
        path
      }
    }
  }
`;

const EditDealerProgram = () => {
  const { id } = useParams();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [updateDealerProgram, { errors }] = useMutation(UPDATE_DEALER_PROGRAM, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: DEALER_PROGRAMS
      }
    ],
   onCompleted(values) {
      setFormSubmitting(false);
      history.push({
        pathname: '/system/dealer-programs',
        state: { alert: true, type: 'updated' }
      })
    }
  })

  const handleSubmit = (values) => {
    setFormSubmitting(true);

    values.termsAttributes = values.termsAttributes.map((attribute, index) => {
      if (attribute.feeType !== 'Interest') return attribute;

      attribute.termLinesAttributes = attribute.termLinesAttributes.map((attr, idx) => {
        delete attr.termLineFeesAttributes
        return attr
      })

      return attribute
    })

    updateDealerProgram({ variables: values })
  }

  const { loading, error, data } = useQuery(DEALER_PROGRAM, { variables: { id }})

  if (loading) return <Loading />
  if (error) return <DataState.Error error={error} />;

  const { dealerProgram } = data;

  const initialValues = formatInitialValues(dealerProgram);

  const Edit = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Edit Dealer Program</h1>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={dealerProgramSchema}
          onSubmit={handleSubmit}
        >
          <Form isEdit />
        </Formik>
      </div>
    );
  };

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <Edit />
      </div>
    );
  };

  return (
    <Fragment>
      <DisplayContent />
      { auth.hasPolicy('Delta', 'view') &&
        <Deltas termsAttributes={dealerProgram.termsAttributes}/>
      }
    </Fragment>
  );
};

export default EditDealerProgram;
