import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EditAuction from '../components/Auctions/Edit';
import Contacts from '../components/Auctions/Contacts';
import FPRs from '../components/Auctions/FPRs';
import Cars from '../components/Auctions/AuctionCars';
import NewSaleTime from '../components/Auctions/SaleTimes/New';
import EditSaleTime from '../components/Auctions/SaleTimes/Edit';
import NewContact from '../components/Auctions/Contacts/New'
import EditContact from '../components/Auctions/Contacts/Edit'
import Notes from '../components/Auctions/Notes';
import NewNote from '../components/Notes/New';
import EditNote from '../components/Notes/Edit';
import BankAccounts from '../components/Auctions/BankAccounts';
import NewBankAccount from '../components/Auctions/BankAccounts/New';
import Deltas from '../components/Auctions/Deltas';
import AuctionTokens from '../components/Auctions/AuctionTokens';

const AuctionRoutes = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path="/auctions/:id/edit" component={EditAuction} />
        <Route path="/auctions/:id/contacts" component={Contacts} exact />
        <Route path="/auctions/:id/FPRs" component={FPRs} />
        <Route path="/auctions/:id/cars" component={Cars} />
        <Route path="/auctions/:id/contacts/new" component={NewContact} />
        <Route path="/auctions/:ownerId/contacts/:id/edit" component={EditContact} />
        <Route path="/auctions/:id/sale-times/new" component={NewSaleTime} />
        <Route path="/auctions/:id/sale-times/:saleTimeId/edit" component={EditSaleTime} />
        <Route path="/auctions/:ownerId/notes" component={Notes} exact />
        <Route path="/auctions/:ownerId/notes/new" component={NewNote} exact />
        <Route path="/auctions/:ownerId/notes/:id/edit" component={EditNote} exact />
        <Route path="/auctions/:id/bank-accounts" component={BankAccounts} exact/>
        <Route path="/auctions/:id/bank-accounts/new" component={NewBankAccount} exact/>
        <Route path="/auctions/:id/auction-tokens" component={AuctionTokens} exact/>
        <Route path="/auctions/:id/deltas" component={Deltas} exact/>
      </Switch>
    </>
  )
}

export default AuctionRoutes;
