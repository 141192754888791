import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';
import { gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../contexts/AuthContext';
import DataState from '../../DataState';
import DataTable from '../../../elements/DataTable';
import { useAlert } from '../../../hooks/useAlert';
import BasicAlert from '../../../elements/BasicAlert';
import { useParams, Redirect } from "react-router-dom";
import { DEALER_LINE_COLLATERAL_COVERAGES,
         HIDDEN_DEALER_LINE_COLLATERAL_COVERAGES } from '../../../queries/indexQueries';
import { useHideableResource } from '../../../hooks/useHideableResource';
import { setAlertData } from '../../../utils/setAlertData';
import * as DateHelper from '../../../utils/dateHelpers';
import { responsiveDefaults } from '../../../utils/datatableHelpers';
import { Colors } from '../../../styles';
import * as Buttons from '../../../elements/buttons';
import AuthLink from '../../../components/AuthLink';
import { useLocation } from 'react-router-dom'

const DELETE = gql`
  mutation DestroyDealerLineInsuranceProgram($id: ID!) {
    destroyDealerLineInsuranceProgram(
      id: $id
    ) {
      dealerLineInsuranceProgram {
        id
      }
      errors {
        message
        path
      }
    }
  }
`
// I'm keeping this and the useHideableResource function in case we want to
// eventually change this component to allow for hiding / unhiding of
// collateral coverages.
const UNHIDE = gql`
  mutation UnhideInsuranceProgram($id: ID!) {
    unhideInsuranceProgram(
      id: $id
    )
    {
      insuranceProgram {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const UPDATE_DEALER_LINE_INSURANCE_PROGRAM = gql`
  mutation UpdateDealerlineInsuranceProgram($id: ID!, $expiresOn: ISO8601Date) {
    updateDealerLineInsuranceProgram(
      id: $id
      expiresOn: $expiresOn
    ) {
      dealerLineInsuranceProgram {
        id
        expiresOn
      }
    }
  }
`

const textForNoData = {
  fontSize: '50px',
  textAlign: 'center',
  color: Colors.xlGreen,
  paddingTop: '40px'
}

const CollateralCoverages = (props) => {
  const id = parseInt(useParams().id);
  const history = useHistory();
  let dealerLineId;
  let isRefreshFromExpire = useRef(false);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const [queryData, setQueryData] = useState();
  const tableId = '#collateral-coverage-table';

  responsiveDefaults.language = {};
  responsiveDefaults.ordering= false;

  const datatable = $(tableId).DataTable(responsiveDefaults);

  const [updateDealerLineInsuranceProgram, { data: mutationData }] = useMutation(UPDATE_DEALER_LINE_INSURANCE_PROGRAM,
    {
      awaitRefetchQueries: true,
      refetchQueries: [ { query: DEALER_LINE_COLLATERAL_COVERAGES, variables: { id } }],
      onCompleted(values) {
        setAlertType('updated');
        displayAlert();
        isRefreshFromExpire.current = true;
      }
    })

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_DEALER_LINE_COLLATERAL_COVERAGES,
    VISIBLE_QUERY: DEALER_LINE_COLLATERAL_COVERAGES,
    HIDE_MUTATION: DELETE,
    UNHIDE_MUTATION: UNHIDE,
    variables: { id },
  };

  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)

  useEffect(() => {
    setQueryData(data);
  }, [data])

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])
  const auth = useContext(AuthContext);

  let currentData = null;

  if (loading && (currentData !== data || !data) || !queryData) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const { dealer: { dealerLines } } = queryData;
  const dealer = queryData.dealer;

  let dealerLineInsurancePrograms = dealerLines[0].dealerLineInsurancePrograms;

  dealerLineId = dealerLines[0].id;

  dealerLineInsurancePrograms = dealerLineInsurancePrograms.sort(function(a,b){
    if (a.activeProgram) {
      return -1;
    }

    return new Date(b.effectiveOn) - new Date(a.effectiveOn);
  });


  const columns = [
    {
      title: 'Insurance Program'
    },
    {
      title: 'Active Rate',
      className: 'text-right',
    },
    {
      title: 'Third Party'
    },
    {
      title: 'Effective On',
      render: DateHelper.renderDate
    },
    {
      title: 'Expires On',
      render: DateHelper.renderDate,
      className: 'showOverdue'
    },
    {
      title: 'Insurance Company'
    },
    {
      title: 'Created By'
    },
    {
      title: 'Updated By'
    },
    {
      title: 'Updated At'
    },
    {
      title: 'Functions'
    },
    {
      title: 'Active?'
    }
  ];

  let currentRate = null;
  let hasCurrentActive = false;
  const dataSet = dealerLineInsurancePrograms.map((program) => {
    const effectiveOn = DateHelper.setDate(program.effectiveOn);

    const today = new Date
    const currentDate = today === new Date(program.effectiveOn);
    const showActions = today < new Date(program.effectiveOn);

    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${program.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${program.id}>Delete</a>`

    const actions = []

    if (auth.hasPolicy('DealerLineInsuranceProgram', 'update') && showActions) {
      actions.push(`<a href="/dealers/${id}/collateral-coverages/${program.id}">Edit</a>`)
    }

    if (auth.hasPolicy('DealerLineInsuranceProgram', 'hide') && showActions) {
      actions.push(hiddenLink)
    }

    if (actions === []){
      actions.push(' ')
    }

    if ((!showActions || today) && currentRate === null) currentRate = program.activeRate;

    const createdBy = `${program.createdBy?.firstName || ''} ${program.createdBy?.lastName || ''}`
    const updatedBy = `${program.updatedBy?.firstName || ''} ${program.updatedBy?.lastName || ''}`

    const insuranceProgram = program.insuranceProgram?.name || '';
    const thirdParty = (program.thirdParty) ? 'Yes' : 'No'
    const expiresOn = DateHelper.setDate(program.expiresOn);
    const activeRate = program.activeRate ? `${program.activeRate}%` : '';
    const updatedAt = DateHelper.setDate(program.updatedAt);

    let activeProgram = program.activeProgram;

    if (activeProgram) {
      activeProgram = activeProgram.split(' ')
      activeProgram.push(`<a href="javascript:void(0)" id="expire" data-id=${program.id}>Expire Now</a>`)
      activeProgram = activeProgram.join(' | ')
      hasCurrentActive = true;
    }

    return [
      insuranceProgram,
      activeRate,
      thirdParty,
      effectiveOn,
      expiresOn,
      program.insuranceCompany || '',
      createdBy,
      updatedBy,
      updatedAt,
      actions.join(" | "),
      activeProgram || '',
    ]
  })

  if ((isRefreshFromExpire.current && !hasCurrentActive) || !hasCurrentActive) {
    history.push(`/dealers/${id}/collateral-coverages/new`)
  }

  datatable.clear().rows.add(dataSet).draw(false);

  $(document).on('click', '#expire', function(event) {
    const id = parseInt(event.target.dataset.id);
    const expiresOn = dealer.market.today;

    updateDealerLineInsuranceProgram({ variables: { id, expiresOn }})
  })

  const CollateralCoverageData = () => {
    if (dataSet.length === 0) {
      const location = useLocation();
      return (
        <Fragment>
          <div style={textForNoData}>No Collateral Coverages Available</div>
         <AuthLink
           to={`${location.pathname}/new`}
           resource="User"
           action="read"
         >
           <p style={Object.assign({}, textForNoData, {fontSize: '22px', marginBottom: '40px'})}>
             Create a Collateral Coverage
           </p>
         </AuthLink>
        </Fragment>
      )
    } else {
      return (
        <>
          <DataTable
            columns={columns}
            dataSet={dataSet}
            pageTitle='Collateral Coverages'
            unhideable={true}
            subHeader={`Current Rate: ${currentRate || 0}%`}
            orderRows={false}
            tableId={tableId}
          />
        </>
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Collateral Coverage</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="collateral coverage" type={alertType} />
      }
      <CollateralCoverageData />
    </>
  );
};

export default CollateralCoverages;
