import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import { REJECTED_FLOORPLAN_REQUESTS } from './queries';
import DataTables from '@preflighttech/preflight-tables';
import columnDefinitions from './columnDefinitions';
import {
  Typography, preflightTableStyles
} from 'components/src/styles';

const Rejected = () => {
  const { loading, error, data } = useQuery(REJECTED_FLOORPLAN_REQUESTS);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    ...columnDefinitions,
    {
      key: 'memo',
      label: 'Reason',
    }
  ];

  return (
    <>
      <Helmet>
        <title>Rejected FPRs</title>
      </Helmet>
      <div style={Typography.pageTitle}>Rejected FPRs</div>

      <DataTables.Simple
        data={data.floorplanRequests}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Rejected;
