import React, { Fragment } from 'react';
import Table from 'components/src/components/Transactions/Table';
import { Typography } from 'components/src/styles';

const index = () => {
  const shownColumns = [
    'bankDate', 'createdDate', 'market', 'name', 'type', 'reference',
    'amount', 'createdBy', 'actions'
  ];

  const availableFilters = [
    'payment_type', 'purpose', 'created_by', 'bank_date', 'created_date'
  ];

  return (
    <Fragment>
      <div style={Typography.pageTitle}>Operations Transactions</div>
      <Table
        shownColumns={shownColumns}
        availableFilters={availableFilters}
        scopes={['operations']}
        reportPath="operations-transactions-report"
      />
    </Fragment>
  );
};

export default index;
