import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../../contexts/AuthContext";
import Table from "react-bootstrap/Table";
import { useFormikContext } from "formik";
import { Form as FormikForm } from "formik";
import * as StringHelper from "../../utils/stringHelpers";
import * as BasicForm from "../../elements/forms";
import * as Buttons from "../../elements/buttons";
import { Spacing } from "../../styles";
import { Colors } from "../../styles";
import "./payments.css";
import * as yup from "yup";
import { FormBody } from "./FormBody";
import { SortButtons, handleSort } from "./Sort";
import Form from "react-bootstrap/Form";
import * as Formatting from '../../styles/formatting';

export const schema = yup.object({});

export const formNumberStyle = {
  textAlign: "right",
};

const columnHeaderStyle = {
  minWidth: "130px",
  borderBottom: "none",
};

const summaryRow = {
  display: "flex",
  justifyContent: "space-evenly",
};

const summaryText = {
  fontSize: "40",
  fontWeight: "bold",
};

export const PayForm = ({ dealerData, serverSideErrors, handleSubmit, data, total, dealerFees, setDealerFees, setOrderedData, setPaymentButton, setEmailButton }) => {
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [principalTotal, setPrincipalTotal] = useState(0);
  const [interestTotal, setInterestTotal] = useState(0);
  const [insuranceTotal, setInsuranceTotal] = useState(0);
  const [reservesTotal, setReservesTotal] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [dealerFeeTotal, setDealerFeeTotal] = useState(total)
  const [orderedColumn, setOrderedColumn] = useState(null);
  const [reordered, setReordered] = useState(false);

  let currentColumn = useRef(null);

  const { values, errors, setFieldValue } = useFormikContext();

  const auth = useContext(AuthContext);

  const togglePayment = () => {
    let checked = dealerFees === true;
    checked = !checked ? setDealerFees(true) : setDealerFees(false);
  };

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  if (reordered) {
    delete values.quoteCarsAttributes;
    delete values.maxPrincipal;
  }

  if (dealerFees === true) {
    delete values.quoteFeesAttributes;
  } else {
    if (!values.quoteFeesAttributes && !dealerFees) {
      const dealerFeesMap = dealerData.dealer.payableFees.map((fee) => {
        const feeId = parseInt(fee.id);
        const amount = parseFloat(fee.due)

        return { feeId, amount }
      })

      setFieldValue(`quoteFeesAttributes`,
        dealerFeesMap
      )
    }
  }

  return (
    <>
      <ServerSideErrors />
      <FormikForm>
        {(() => {
          if (total > 0) {
            return (
              <>
                <div style={summaryRow}>
                  <div style={{ padding: "20px" }}>
                    <Form.Check
                      label="Do Not Include Dealer Fees"
                      checked={dealerFees === true}
                      onChange={() => togglePayment()}
                    />
                  </div>
                </div>
              </>
            );
          }
        })()}
        <div style={tableTopDivStyle}>
          <BasicForm.CustomStyleTextInput
            name="paymentDate"
            type="date"
            divStyle={inputDivStyle}
            label="Payment Date"
          />
          <BasicForm.CustomStyleTextInput
            name="search"
            type="search"
            style={searchStyle.input}
            divStyle={searchStyle.div}
            labelStyle={searchStyle.label}
            label="Search"
          />
        </div>
        <br />
        <Table striped hover responsive>
          <thead>
            <tr>
              <th
                className="ordered-column"
                style={Object.assign({}, columnHeaderStyle, { minWidth: "50px" })}
                onClick={() =>
                  handleSort(
                    data,
                    "unitNumber",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Unit
                <SortButtons
                  columnName={"unitNumber"}
                  selectedColumn={orderedColumn}
                />
              </th>
              <th style={columnHeaderStyle}>VIN</th>
              <th
                className="ordered-column"
                style={Object.assign({}, columnHeaderStyle, { minWidth: "90px" })}
                onClick={() =>
                  handleSort(
                    data,
                    "year",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Year
                <SortButtons columnName={"year"} selectedColumn={orderedColumn} />
              </th>
              <th style={columnHeaderStyle}>Make</th>
              <th style={columnHeaderStyle}>Model</th>
              <th
                className="ordered-column"
                style={Object.assign({}, columnHeaderStyle, {
                  minWidth: "150px",
                })}
                onClick={() =>
                  handleSort(
                    data,
                    "loanDate",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Loan Date
                <SortButtons
                  columnName={"loanDate"}
                  selectedColumn={orderedColumn}
                />
              </th>
              <th
                className="ordered-column"
                style={columnHeaderStyle}
                onClick={() =>
                  handleSort(
                    data,
                    "currentDueOn",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Due Date
                <SortButtons
                  columnName={"currentDueOn"}
                  selectedColumn={orderedColumn}
                />
              </th>
              <th
                className="ordered-column"
                style={Object.assign({}, formNumberStyle, { minWidth: "50px" })}
                onClick={() =>
                  handleSort(
                    data,
                    "totalDays",
                    setOrderedColumn,
                    orderedColumn,
                    setOrderedData,
                    setReordered,
                    currentColumn
                  )
                }
              >
                Days
                <SortButtons
                  columnName={"totalDays"}
                  selectedColumn={orderedColumn}
                />
              </th>
              <th>Principal</th>
              <th>Fees Due</th>
              <th style={formNumberStyle}>Interest Due</th>
              <th style={formNumberStyle}>Collateral Coverage</th>
              <th style={formNumberStyle}>Reserves</th>
              <th style={Object.assign({}, formNumberStyle)}>Total Due</th>
              <th>Payment Type</th>
            </tr>
          </thead>
          <tbody>
            <FormBody
              auth={auth}
              data={data}
              setPaymentTotal={setPaymentTotal}
              setFeesTotal={setFeesTotal}
              setPrincipalTotal={setPrincipalTotal}
              setInterestTotal={setInterestTotal}
              setInsuranceTotal={setInsuranceTotal}
              setReservesTotal={setReservesTotal}
              setDisabled={setDisabled}
              paymentTotal={paymentTotal}
              reordered={reordered}
              setReordered={setReordered}
              disabled={disabled}
              dealerFeesTotal={total}
              feesTotal={feesTotal}
              insuranceTotal={insuranceTotal}
              interestTotal={interestTotal}
              reservesTotal={reservesTotal}
              principalTotal={principalTotal}
              dealerFees={dealerFees}
              setDealerFeeTotal={setDealerFeeTotal}
            />
          </tbody>
        </Table>
        <ServerSideErrors />

        <div style={summaryRow}>
          <span style={summaryText}>
            {" "}
            Dealer Level Fees Total: {StringHelper.currency(dealerFeeTotal)}
          </span>
          <span style={summaryText}>
            {" "}
            Principal Total: {StringHelper.currency(principalTotal)}
          </span>
          <span style={summaryText}>
            {" "}
            Interest Total: {StringHelper.currency(interestTotal)}
          </span>
          <span style={summaryText}>
            {" "}
            Collateral Coverage Total: {StringHelper.currency(insuranceTotal)}
          </span>
          <span style={summaryText}>
            {" "}
            Reserves Total: {StringHelper.currency(reservesTotal)}
          </span>
          <span style={summaryText}>
            {" "}
            Fees Total: {StringHelper.currency(feesTotal)}
          </span>
          <span style={summaryText}>
            {" "}
            Payment Total: {StringHelper.currency(paymentTotal)}
          </span>
        </div>

        <div style={Spacing.paymentButtonRow}>
          <Buttons.PaymentCancel type="cancel" text="Cancel" />
          <Buttons.PaymentStandard
            type="button"
            text="Send Quote Via Email"
            onClick={() => handleSubmit(values, "email")}
            disabled={disabled}
          />
          <Buttons.PaymentStandard
            type="button"
            text="Set Up Payment"
            onClick={() => handleSubmit(values, "payment")}
            disabled={disabled}
          />
        </div>
      </FormikForm>
    </>
  );
};

const searchStyle = {
  div: {
    display: "flex",
    width: "20%",
    alignItems: "center",
  },
  label: {
    paddingRight: "10px",
  },
  input: {
    border: `1px solid ${Colors.xlGreen}`,
  },
};

const tableTopDivStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
};

const inputDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: "40%",
};
