import React from 'react';
import DataState from '../../DataState';
import { Helmet } from 'react-helmet';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Formik, Field } from 'formik';
import Form from 'react-bootstrap/Form';
import * as Buttons from '../../../elements/buttons';
import { BaseRateOptions } from './BaseRateOptions';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import * as yup from 'yup';
import { Typography, Spacing } from '../../../styles';
import { classNames } from './functionality';
import { Colors } from '../../../styles';
import Loading from '../../../elements/Loading';
import '../../../styles/ratePrograms.css';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'

const NEW_RATE_PROGRAM = gql`
  mutation CreateRateProgram($name: String!, $baseRateId: Int!, $description: String, $rateProgramChangesAttributes: [RateProgramChangeInput!]!) {
      createRateProgram(
        name: $name
        description: $description
        baseRateId: $baseRateId
        rateProgramChangesAttributes: $rateProgramChangesAttributes
      ) {
        rateProgram {
          id
          baseRate { id }
          rateProgramChanges {
            id
            rate
            effectiveOn
          }
        }
        errors {
          message
          path
        }
      }
    }
`

const BASE_RATES = gql`{
  baseRates {
    id
    name
  }
}`

const NewRateProgram = () => {
  const customSubmitHandler = (values) => {
    values.baseRateId = parseInt(values.baseRateId)
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: NEW_RATE_PROGRAM,
    onCompleteRedirectTo: '/system/rate-programs',
    customSubmitHandler: customSubmitHandler
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const { loading, error, data } = useQuery(BASE_RATES)

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />

  const { baseRates } = data;

  const today = new Date()

  const schema = yup.object({
    name: yup
      .string()
      .max(80)
      .required('Required'),
    baseRateId: yup
      .number()
      .required('Required'),
    rateProgramChangesAttributes: yup.array().of(
      yup.object().shape({
        rate: yup
          .number()
          .min(0, 'Must be positive')
          .required('Required'),
        effectiveOn: yup
          .date()
          .min(today, 'The date must be in the future')
          .required('Required')
      })
    )
  });

  const initialValues = {
    name: '',
    baseRateId: '',
    description: '',
    rateProgramChangesAttributes: [{ rate: 0, effectiveOn: '' }]
  }

  function RateProgramForm() {
    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
              <div style={Spacing.formWrapper}>
                <div style={Spacing.formHeader}>
                  <h1 style={Typography.formHeader}>Create a Rate Program</h1>
                </div>
              </div>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.description && !errors.description}
                  isInvalid={!!errors.description && touched.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Base Rate</Form.Label>
                <Form.Control
                  as="select"
                  name="baseRateId"
                  value={values.baseRateId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.baseRateId && !errors.baseRateId}
                  isInvalid={!!errors.baseRateId && touched.baseRateId}
                >
                  <option value=""></option>
                  <BaseRateOptions baseRates={baseRates} />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.baseRateId}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Label className='rate-program-changes-label'>Rate Program Changes</Form.Label>
              <Form.Label>Rate</Form.Label>
              <Form.Group className='term-line-errors'>
                <Field className={classNames(errors, touched, 'rate')} type="number" name="rateProgramChangesAttributes[0].rate" />
                <ErrorMessage name={'rateProgramChangesAttributes[0].rate'} />
              </Form.Group>
              <Form.Label>Effective On</Form.Label>
              <Form.Group className='term-line-errors'>
                <Field className={classNames(errors, touched, 'effectiveOn')} type="date" name="rateProgramChangesAttributes[0].effectiveOn" />
                <ErrorMessage name={'rateProgramChangesAttributes[0].effectiveOn'} />
              </Form.Group>
              <div style={Spacing.buttonRow}>
                <Buttons.Cancel type="cancel" text="Cancel" />
                <Buttons.Standard
                  type="submit"
                  text="Submit"
                  disabled={!isValid || formSubmitting}
                />
              </div>
            </Form>
          )}
      </Formik>
    )
  }

  const buttonColor = {
    backgroundColor: Colors.xlGreen,
    borderColor: Colors.xlGreen,
    color: 'white'
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <>
        <ServerSideErrors />
        <RateProgramForm />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>New Rate Program</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default NewRateProgram;
