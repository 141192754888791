export const labelStyle = {
  marginTop: "0px",
};

export const inputStyle = {
  marginLeft: '12px',
  width: '173px',
  height: '30px',
}

export const inlineInputStyle = {
  ...inputStyle,
  marginLeft: "0px",
};

export const advanceRefStyle = {
  paddingLeft: '20px'
}

export const autoTextInput = {
  width: '350px',
  paddingLeft: '10px'
}

export const divStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  alignItems: 'baseline',
}

export const styledError = {
  marginLeft: "16px",
  marginTop: "0px",
};

export const pStyle = {
  paddingLeft: '10px'
}
