import React, { useContext, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useAlert } from '../../../hooks/useAlert';
import DataTable from '../../../elements/DataTable';
import AuthContext from '../../../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import DataState from '../../DataState';
import BasicAlert from '../../../elements/BasicAlert';
import { setAlertData } from '../../../utils/setAlertData';
import { useQuery } from '@apollo/client';
import { DateHelpers, StringHelpers } from '../../../utils';
import { titleCase, currency } from '../../../utils/stringHelpers';

const dealerNameStyle = {
  fontWeight: 'bold',
  fontSize: '1.5em',
  color: 'rgb(214, 142, 0)',
  marginTop: '1%',
  paddingLeft: '0.5%'
}

const quoteDateAccountLine = {
  display: 'flex',
  paddingLeft: '1%'
}

const quoteDate = {
  fontWeight: 'bold',
  fontSize: '1em',
  color: 'rgb(214, 142, 0)',
}

const quoteAccount = {
  fontWeight: 'bold',
  fontSize: '1em',
  color: 'rgb(214, 142, 0)',
  paddingLeft: '2%'
}

const ACH = gql`
  query Quote($id: ID!) {
    quote(id: $id) {
      dealer { id, name }
      quoteCars {
        flooredCar { id, stockNumber, vin, year, make, model, color }
        quoteFees { amount,
          fee { amount, name }
        }
        principal
        interest
        reserves
        insurance
        amount
        paymentType
      }
      paymentDate
      bankDate
      status
      amount
      bankAccount { accountName }
    }
  }
`

const Show = (props) => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(ACH, { variables: { id } });

  const auth = useContext(AuthContext);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const dealer = data.quote.dealer;

  const columns = [
    {
      title: 'Stock Number'
    },
    {
      title: 'VIN'
    },
    {
      title: 'Year'
    },
    {
      title: 'Make'
    },
    {
      title: 'Model'
    },
    {
      title: 'Color'
    },
    {
      title: 'Principal',
      className: 'text-right'
    },
    {
      title: 'Interest',
      className: 'text-right'
    },
    {
      title: 'Reserves',
      className: 'text-right'
    },
    {
      title: 'Fees Total',
      className: 'text-right'
    },
    {
      title: 'Insurance',
      className: 'text-right'
    },
    {
      title: 'Total',
      className: 'text-right'
    },
    {
      title: 'Payment Type',
    },
    {
      title: 'Fees',
      className: 'text-right'
    }
  ]

  const quote = data.quote;
  const quoteCars = quote.quoteCars;

  const dataSet = quoteCars.map(function (quoteCar) {
    quoteCar = { ...quoteCar }
    const flooredCar = quoteCar.flooredCar;
    const vinLink = `<a href="/dealers/${data.quote.dealer.id}/cars/${quoteCar.flooredCar.id}">${flooredCar.vin}</a>`;
    let feesTotal = 0.0;
    const quoteFees = quoteCar.quoteFees.map(function (fee) {
      feesTotal += parseFloat(fee.fee.amount);

      return [
        `${fee.fee.name}    ${currency(fee.fee.amount)}`
      ]
    });

    const total = parseFloat(quoteCar.principal) + parseFloat(quoteCar.interest) +
      parseFloat(quoteCar.reserves) + parseFloat(feesTotal) + parseFloat(quoteCar.insurance);

    return [
      flooredCar.stockNumber,
      vinLink,
      flooredCar.year,
      flooredCar.make,
      flooredCar.model,
      flooredCar.color,
      currency(quoteCar.principal),
      currency(quoteCar.interest),
      currency(quoteCar.reserves),
      currency(feesTotal),
      currency(quoteCar.insurance),
      currency(total),
      titleCase(quoteCar.paymentType),
      quoteFees
    ]
  });

  return (
    <div>
      <div style={dealerNameStyle}>
        <Link to={`/dealers/${dealer.id}`} style={dealerNameStyle} >
          {StringHelpers.truncate(dealer.name, 35, '...')}
        </Link>
      </div>
      <div style={quoteDateAccountLine}>
        <div style={quoteDate}>
          {`Payment Date: ${DateHelpers.setDate(data.quote.paymentDate)}`}
        </div>
        <div style={quoteAccount}>
          {`Account: ${data.quote.bankAccount !== null ?
            data.quote.bankAccount.accountName : ''}`}
        </div>
        <div style={quoteAccount}>
          {`Amount: ${currency(data.quote.amount)}`}
        </div>
      </div>
      <div>
        <DataTable
          dataSet={dataSet}
          columns={columns}
          pageTitle={'Cars in Payment'}
          addFilter={false}
          unhideable={true}
          uncreatable={true}
          responsive={true}
        />
      </div>
    </div>
  )
}

export default Show;
