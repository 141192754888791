import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import DataTable from '@preflighttech/preflight-tables';
import { preflightTableStyles } from 'components/src/styles';
import AuthContext from 'components/src/contexts/AuthContext';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert from 'components/src/elements/BasicAlert';
import { titleCase } from 'components/src/utils/stringHelpers';
import { HIDE_NOTE, UNHIDE_NOTE } from './queries'
import { columns } from './columnDefinitions';

const Table = props => {
  const { query, ownerType, ownerId, unhideable, setNoteUserIds } = props;
  const auth = useContext(AuthContext);
  const prefix = props.prefix || useLocation().pathname;

  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  const location = useLocation();

  useEffect(() => setAlertData({location}, setAlertType, displayAlert), []);

  const [showHidden, setShowHidden] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    query,
    { variables: { id: ownerId, scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_NOTE, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_NOTE, { onCompleted: refetch });

  useEffect(() => {
    const userIds = [];

    if (data && data[ownerType]?.notes) {
      data[ownerType].notes.forEach(note => {
        if (note.createdBy && !userIds.includes(note.createdBy.id)) {
          userIds.push(note.createdBy.id);
        }

        if (note.statusBy && !userIds.includes(note.statusBy.id)) {
          userIds.push(note.statusBy.id);
        }
      });

      setNoteUserIds(userIds);
    }
  }, [data]);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const corporateUser =
    auth.hasExactPolicy('Note', 'View Corporate Only Notes');
  let entries = data[ownerType].notes;

  if (!corporateUser) {
    entries = entries.filter(entry => !entry.corporate);
  }

  const name = data[ownerType].fullName || data[ownerType].name ||
    titleCase(ownerType);

  return (
    <>
      {showAlert && (
        <BasicAlert
          componentType={`${titleCase(ownerType)} Note`}
          type={alertType}
        />
      )}

      <ResourcesHeader
        title={`Notes for ${name}`}
        prefix={prefix}
        resourceType="Note"
        showHidden={!unhideable && showHidden}
        setShowHidden={!unhideable && setShowHidden}
        noHelmet
      />

      <DataTable.Simple
        data={entries}
        columns={columns({ hide, unhide, unhideable, prefix })}
        pageLength={10}
        movableColumns
        htmlTable
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Table;
