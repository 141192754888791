import React from "react";
import * as BasicForm from "../../../elements/forms";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import DataState from "../../DataState";
import { ErrorMessage } from "../../../elements/ErrorMessage";
import {
  AUCTION_BANK_ACCOUNTS,
  APPROVED_SOURCE_BANK_ACCOUNTS,
  DEALER_BANK_ACCOUNTS
} from './Queries'
import { autoTextInput, inputStyle } from './Style';

const BankAccountInformation = () => {
  const { values, setFieldValue } = useFormikContext();
  const id = parseInt(values?.sourceId);

  let queryType;

  if (values.sourceType === "Auction") {
    queryType = AUCTION_BANK_ACCOUNTS;
  }
  if (values.sourceType === "ApprovedSource") {
    queryType = APPROVED_SOURCE_BANK_ACCOUNTS;
  }
  if (values.sourceType === "Dealer") {
    queryType = DEALER_BANK_ACCOUNTS;
  }

  const { loading, error, data } = useQuery(queryType, { variables: { id } });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const queryData = Object.values(data)[0];

  const formattedBankAccounts = queryData.bankAccounts.map((account) => {
    const name = `${account.accountName} ${account.accountNumberMasked}`;

    return { id: account.id, name };
  });


  const defaultSelected = (formattedBankAccounts.length === 1)
    ? [formattedBankAccounts[0]]
    : [];

  if (defaultSelected.length > 0) {
    values.bankAccountId = parseInt(defaultSelected[0].id);
  }

  return (
    <BasicForm.AutoCompleteTextInput
      clearButton
      name="bankAccountId"
      type="text"
      defaultSelected={defaultSelected}
      options={formattedBankAccounts}
      labelKey="name"
      style={
        Object.assign(
          {}, inputStyle, autoTextInput
        )
      }
      id="source-data"
      onChange={(selected) => {
        selected.length > 0
          ? setFieldValue("bankAccountId", parseInt(selected[0].id))
          : setFieldValue("bankAccountId", "");
      }}
    />
  );
};

export default BankAccountInformation;
