import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import { gql, useQuery } from '@apollo/client';
import { Form as FormikForm, useFormikContext } from "formik";
import * as BasicForm from "../../elements/forms";
import * as Buttons from "../../elements/buttons";
import { Spacing } from "../../styles";
import { phoneRegExp, handleNumberChange } from "../../utils/stringHelpers";
import { PAYMENT_TERMS } from '../../queries/indexQueries';
import DataState from '../DataState';
import Select from 'react-select';

const EXPENSE_ACCOUNT = gql`
  query {
    accounts(scopes: ["expense"]) {
      id
      name
      gl
    }
  }
`

const PAYABLE_ACCOUNTS = gql`
  query {
    accounts(scopes: ["accounts_payable"]) {
      id
      name
      gl
    }
  }
`

export const vendorSchema = yup.object({
  name: yup.string().required("Required"),
  state: yup
    .string()
    .max(2, "Please enter the 2 letter state abbreviation")
    .required("Required"),
  phone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    }),
});

export const Form = () => {
  const { values, setFieldValue, isValid, setValues } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();
  const { loading, error, data } = useQuery(PAYMENT_TERMS);
  const { loading: expenseLoading, error: expenseError, data: expenseData } = useQuery(EXPENSE_ACCOUNT);
  const { loading: payableLoading, error: payableError, data: payableData } = useQuery(PAYABLE_ACCOUNTS);

  useEffect(() => {
    if (values.tinType === '') {
      setFieldValue('tinType', null)
    }
    if (values.federalClassification === '') {
      setFieldValue('federalClassification', null)
    }
    if (values.taxClassification === '') {
      setFieldValue('taxClassification', null)
    }
    if (values.eligibleFor1099 === '') {
      setFieldValue('eligibleFor1099', null)
    }
  }, [values])


  if (loading || expenseLoading || payableLoading || !payableData || !expenseData) return <DataState.Loading />;
  if (error || expenseError || payableError) return <DataState.Error />;

  const { paymentTerms } = data;

  const payable = payableData.accounts;
  const expense = expenseData.accounts;

  const handleChangeExpenses = (selected) => {
    if (!selected) {
      values.expenseAccountId = undefined;
      setValues(values)
    } else {
      values.expenseAccountId = parseInt(selected.value)
      setValues(values)
    }
  }

  const preSelectedExpense = () => {
    if (values.expenseAccountId) {
      const account = expense.find((value) => parseInt(value.id, 10) === parseInt(values.expenseAccountId))
      return { value: parseInt(account.id), label: `${account.gl} - ${account.name}` }
    } else {
      return { value: undefined, label: '' }
    }
  }

  const formattedExpenses = expense.map((account) => {
    return { value: account.id, label: `${account.gl} - ${account.name}` }
  })

  const handleChangePayables = (selected) => {
    if (!selected) {
      values.payableAccountId = undefined;
      setValues(values)
    } else {
      values.payableAccountId = parseInt(selected.value)
      setValues(values)
    }
  }

  const preSelectedPayable = () => {
    if (values.payableAccountId) {
      const account = payable.find((value) => parseInt(value.id, 10) === parseInt(values.payableAccountId))
      return { value: parseInt(account.id), label: `${account.gl} - ${account.name}` }
    } else {
      return { value: undefined, label: '' }
    }
  }

  const formattedPayables = payable.map((account) => {
    return { value: account.id, label: `${account.gl} - ${account.name}` }
  })

  return (
    <FormikForm>
      <BasicForm.TextInput name="name" type="text" label="Name" />
      <BasicForm.TextInput name="street" type="text" label="Street" />
      <BasicForm.TextInput name="city" type="text" label="City" />
      <BasicForm.StateAbbreviationSelect
        name="state"
        type="text"
        label="State"
      />
      <BasicForm.TextInput name="zip" type="text" label="Zip Code" />
      <BasicForm.PhoneInput
        name="phone"
        country="US"
        value={values.phone || ''}
        onChange={event => handleNumberChange(event, 'phone', setFieldValue)}
        label='Phone Number'
      />
      <BasicForm.StyledLabel>Expense Account</BasicForm.StyledLabel>
      <Select
        options={formattedExpenses}
        onChange={handleChangeExpenses}
        isClearable={true}
        defaultValue={preSelectedExpense()}
      />
      <BasicForm.StyledLabel>Payable Account</BasicForm.StyledLabel>
      <Select
        options={formattedPayables}
        onChange={handleChangePayables}
        isClearable={true}
        defaultValue={preSelectedPayable()}
      />
      <BasicForm.TextInput name="tin" type="text" label="Taxpayer Identification Number (EIN/SSN/ITIN)" />
      <BasicForm.Select name="tinType" label="TIN Type">
        <option value="">Select</option>
        <option>EIN</option>
        <option>SSN</option>
        <option>ITIN</option>
      </BasicForm.Select>
      <BasicForm.Select name="federalClassification" label="Federal Classification">
        <option value="">Select</option>
        <option>Individual/sole proprietor or single-member LLC</option>
        <option>C Corporation</option>
        <option>S Corporation</option>
        <option>Partnership</option>
        <option>Trust/estate</option>
        <option>Limited liability company</option>
        <option>Government/Municipality</option>
      </BasicForm.Select>
      <BasicForm.Select name="taxClassification" label="LLC Tax Classification">
        <option value="">Select</option>
        <option>C corporation</option>
        <option>S corporation</option>
        <option>Partnership</option>
      </BasicForm.Select>
      <BasicForm.Select name="eligibleFor1099" label="1099 Eligibility">
        <option value="">Select</option>
        <option>No</option>
        <option>Yes</option>
        <option>Rent - Box 1</option>
      </BasicForm.Select>
      <BasicForm.Select name="paymentTermId" label="Default Payment Terms">
        <option value="">Select</option>
        { paymentTerms.map((term) => {
          return <option key={term.id} value={term.id}>{term.name}</option>
        })}
      </BasicForm.Select>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={formSubmitting}
        />
      </div>
    </FormikForm>
  );
};




// <BasicForm.ProgrammaticSelect
      //   label='Expense Account'
      //   name='expenseAccountId'
      //   options={expense}
      // />
      // <BasicForm.ProgrammaticSelect
      //   label='Payable Account'
      //   name='payableAccountId'
      //   options={payable}
      // />
