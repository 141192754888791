import React from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { formatDeltas } from './functions';
import { setTime } from 'components/src/utils/dateHelpers';
import { preflightTableStyles } from 'components/src/styles';

const index = ({ resourceName, deltas }) => {
  const columns = [
    {
      key: 'user',
      label: 'User',
    },
    {
      key: 'action',
      label: 'Action',
    },
    {
      key: 'name',
      label: 'Field Name',
    },
    {
      key: 'oldValue',
      label: 'Old Data',
    },
    {
      key: 'newValue',
      label: 'New Data',
    },
    {
      key: 'createdAt',
      label: 'Created At',
      sort: 'desc',
      content: ({ value }) => setTime(value),
    },
  ];

  const formattedDeltas = formatDeltas(deltas).flat();

  return (
    <DataTables.Simple
      data={formattedDeltas}
      columns={columns}
      pageLength={10}
      htmlTable
      movableColumns
      multiSort
      styles={preflightTableStyles}
    />
  )
}

export default index;
