import secrets from 'components/src/secrets';
import { AUTH_TOKEN } from 'components/src/constants';

export const rest = args => {
  const axios = require('axios');

  if (args.path && !args.url) {
    args.url = `${secrets.api.baseUri}/${args.path}`;
  }

  if (!args.method) { args.method = 'get' };

  args.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/xlsx',
    Authorization: localStorage.getItem(AUTH_TOKEN),
    JWT_AUD: 'react',
    ...args.headers
  }

  return axios(args);
};
