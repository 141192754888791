import React from "react";
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { formatDeltas } from 'components/src/components/Deltas/functions';
import DataState from 'components/src/components/DataState';
import { DEALER_DELTAS } from './Queries';
import DataTables from '@preflighttech/preflight-tables';
import { setDate } from 'components/src/utils/dateHelpers';
import { preflightTableStyles } from 'components/src/styles';

const columns = [
  {
    key: 'createdAt',
    label: 'Date',
    sort: 'desc',
    content: ({ value }) => setDate(value),
  },
  {
    key: 'newValue',
    label: 'Credit Score',
  },
  {
    key: 'user',
    label: 'Set By',
  },
];

const CreditHistory = () => {
  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(
    DEALER_DELTAS, { variables: { id } }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const formattedDeltas = formatDeltas(data.dealer.deltas).flat().
    filter(delta => 'internal_credit_score' === delta.name && delta.newValue);

  return (
    <DataTables.Simple
      data={formattedDeltas}
      columns={columns}
      pageLength={10}
      htmlTable
      movableColumns
      multiSort
      styles={preflightTableStyles}
    />
  );
};

export default CreditHistory;
