import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import DataState from '../../DataState';
import { useAlert } from '../../../hooks/useAlert';
import BasicAlert from '../../../elements/BasicAlert';
import { uccSchema, Form } from './Form';
import { setAlertData } from '../../../utils/setAlertData';
import Loading from '../../../elements/Loading';

export const UCC = gql`
  query Ucc($id: ID!) {
    ucc(id: $id) {
      id
      filedOn
      filingLocation
      position
      securedPartyName
      securedPartyType
      securedPartyId
      address
      city
      state
      zip
      pmsiReceived
      pmsiSent
      xlf
      originalFileDate
      pmsiTrackingNumber
      notes {
        id, createdAt, __typename, subject, note, statusAt, status, important, collection, corporate,
        createdBy {
          id, firstName, lastName,
        }
        statusBy {
          id, firstName, lastName,
        }
      }
    }
  }`

const UPDATE_UCC = gql`
  mutation updateUcc($id: ID!, $filedOn: ISO8601Date!,
    $filingLocation: String!, $position: Int!,
    $securedPartyName: String!, $address: String!, $city: String!, $state: String!,
    $zip: String!, $pmsiReceived: ISO8601Date, $pmsiSent: ISO8601Date, $xlf: Boolean!
    $originalFileDate: ISO8601Date, $pmsiTrackingNumber: String
    $securedPartyType: String, $securedPartyId: Int) {
      updateUcc (
        id: $id
        filedOn: $filedOn
        filingLocation: $filingLocation
        position: $position
        securedPartyName: $securedPartyName
        securedPartyType: $securedPartyType
        securedPartyId: $securedPartyId
        address: $address
        city: $city
        state: $state
        zip: $zip
        pmsiReceived: $pmsiReceived
        pmsiSent: $pmsiSent
        xlf: $xlf
        originalFileDate: $originalFileDate
        pmsiTrackingNumber: $pmsiTrackingNumber
      ) {
        ucc { id, position, securedPartyName, securedPartyId, securedPartyType, filingLocation, filedOn, expiresOn,
              address, city, state, zip, pmsiReceived, pmsiSent, xlf,
              originalFileDate, pmsiTrackingNumber }

        errors { message, path }
      }
    }
`

const UpdateUCC = (props) => {
  const dismissAlert = () => displayAlert(false)
  const [showAlert, displayAlert] = useAlert();
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [alertType, setAlertType] = useState();
  const history = useHistory();
  const dealerId = useParams().parentId;
  const uccId = useParams().ownerId;
  const [updateUcc] = useMutation(UPDATE_UCC,
    {
      onCompleted() {
        setFormSubmitting(false);
        setRedirect(true);
      }
    }
  )

  const { loading, error, data } = useQuery(UCC, { variables: { id: uccId } })

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { ucc } = data;

  if (ucc.pmsiReceived) ucc.pmsi = 'Received';
  if (ucc.pmsiSent) ucc.pmsi = 'Sent';
  if (!ucc.pmsiSent && !ucc.pmsiReceived) {
    ucc.pmsi = '';
    ucc.pmsiSent = '';
    ucc.pmsiReceived = '';
  }

  let createOption;

  if (ucc.securedPartyType) {
    createOption = 'create'
  } else {
    createOption = 'manual'
  }

  let pmsiOption;

  if (ucc.pmsi === "Sent" || ucc.pmsi === "Received") {
    pmsiOption = 'manual'
  } else {
    pmsiOption = 'create'
  }

  const handleSubmit = (values) => {
    values.dealerId = parseInt(dealerId)

    if (values.createOption === 'create') {
      values.xlf = false
    }

    if (values.createOption === 'manual') {
      values.securedPartyType = ''
      values.securedPartyId = undefined
    }

    if (values.pmsiOption === 'create') {
      values.pmsi = ''
      values.pmsiSendReceiveDate = ''
      values.pmsiTrackingNumber = ''
    }

    if (values.pmsi === 'Sent') {
      values.pmsiSent = values.pmsiSendReceiveDate;
      values.pmsiReceived = null;
      delete values.pmsi;
      delete values.pmsiSendReceiveDate;
    }

    if (values.pmsi === 'Received') {
      values.pmsiReceived = values.pmsiSendReceiveDate;
      values.pmsiSent = null;
      delete values.pmsi;
      delete values.pmsiSendReceiveDate;
    }

    if (values.pmsi === '') {
      values.pmsiReceived = null;
      values.pmsiSent = null;
    }

    if (values.pmsiTrackingNumber !== undefined && values.pmsiTrackingNumber !== '') {
      values.pmsiTrackingNumber = values.pmsiTrackingNumber.replace(/ /g, "")
    }

    values.state = values.state.toUpperCase();

    setFormSubmitting(true)
    updateUcc({ variables: values });
  };

  const UpdateForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/dealers/${dealerId}/uccs`,
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Update UCC</h1>
          </div>
          <Formik
            initialValues={{
              id: uccId,
              createOption: createOption,
              pmsiOption: pmsiOption,
              filedOn: ucc.filedOn || '',
              filingLocation: ucc.filingLocation || '',
              position: ucc.position || 9999,
              securedPartyName: ucc.securedPartyName || '',
              securedPartyType: ucc.securedPartyType || '',
              securedPartyId: ucc.securedPartyId || '',
              address: ucc.address || '',
              city: ucc.city || '',
              state: ucc.state || '',
              zip: ucc.zip || '',
              pmsi: ucc.pmsi || '',
              pmsiSendReceiveDate: ucc.pmsiSent || ucc.pmsiReceived || '',
              xlf: ucc.xlf || false,
              originalFileDate: ucc.originalFileDate || '',
              pmsiTrackingNumber: ucc.pmsiTrackingNumber || '',
            }}
            validationSchema={uccSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <>
        <div style={Spacing.formContainer}>
          <UpdateForm />
        </div>
      </>
    )
  }

  return (
    <DisplayContent />
  );
};

export default UpdateUCC;
