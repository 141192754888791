import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import * as StringHelpers from '../../../utils/stringHelpers';
import { Spacing } from '../../../styles';

export const branchSchema = yup.object({
  code: yup
    .string()
    .required('Required')
    .max(6, 'Maximum length for branch code is 6 characters'),
  name: yup
    .string()
    .required('Required')
    .max(60, 'Maximum length for branch name is 60 characters'),
  street: yup
    .string()
    .required('Required')
    .max(100, 'Maximum length for street name is 100 characters'),
  city: yup
    .string()
    .required('Required')
    .max(50, 'Maximum length for the city is 50 characters'),
  state: yup
    .string()
    .required('Required')
    .max(100, 'Maximum length for a state abbreviation is 2 characters'),
  attention: yup
    .string()
    .required('Required'),
  phoneNumber: yup
    .string()
    .required('Required')
    .matches(StringHelpers.phoneRegExp, { message: "Please enter a valid phone number.", excludeEmptyString: false }),
  zip: yup
    .string()
    .required('Required')
    .matches(StringHelpers.zipRegExp, { message: 'Please enter a valid ZIP code.', excludeEmptyString: false }),
  email: yup
    .string()
    .required('Required')
    .email('Please enter a valid email address'),
});

export const Form = () => {
  const { isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="code"
        type="text"
        label="Code"
      />
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.TextInput
        name="street"
        type="text"
        label="Street"
      />
      <BasicForm.TextInput
        name="city"
        type="text"
        label="City"
      />
      <BasicForm.StateAbbreviationSelect
        name="state"
        type="text"
        label="State"
      />
      <BasicForm.TextInput
        name="zip"
        type="text"
        label="Zip"
      />
      <BasicForm.TextInput
        name="phoneNumber"
        type="text"
        label="Phone Number"
      />
      <BasicForm.TextInput
        name="attention"
        type="text"
        label="Attention:"
      />
      <BasicForm.TextInput
        name="email"
        type="text"
        label="Email"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
