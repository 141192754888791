import React, { useEffect, useRef } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';

const RESOURCES = gql`
  query {
    policies {
        resource
        actions {
          name
          params { name, values }
        }
      }
  }`


export const getResources = () => {
  const { loading, error, data } = useQuery(RESOURCES);

  return { loading, error, data }
}

export const selectedPolicy = (policies, userPolicy) => {
  return policies.find((policy) => {
   return policy.resource === userPolicy
  })
}

export const selectedAction = (selectedPolicy, userAction) => {
  return selectedPolicy.actions.find((action) => {
      return action.name === userAction
  })
}

export const selectedOption = (selectedAction, userOption) => {
  if (!selectedAction) {
    return;
  }
  return selectedAction.params.find((option) => {
    return option.name === userOption
  })
}

export const handleAddPolicy = (values, setValues) => {
  values.policies = [...values.policies, []]
  values.options = [...values.options, []]
  setValues(values)
}

export const handleRemovePolicy = (values, setValues, index) => {
  values.policies.splice(index, 1)
  values.options.splice(index, 1)
  setValues(values)
}

let prevOption = ''
function usePreviousOptions(value) {
  const ref = useRef(value);
  useEffect(() => {
    prevOption = value;
  });
}

export const resetOptions = (index) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if ((prevOption !== '') && (prevOption !== values.options[index][0])) {
      values.options[index] = [values.options[index][0]]
    }
  }, [values.options[index][0]])

  usePreviousOptions(values.options[index][0])
}

let prevAction = ''
function usePreviousParam(value) {
  const ref = useRef(value);
  useEffect(() => {
    prevAction = value;
  });
}

export const resetParam = (index) => {
  const { values } = useFormikContext();

    useEffect(() => {
      if ((prevAction !== '') && (prevAction !== values.policies[index][1])) {
        values.options[index] = []
      }
    }, [values.policies[index][1]])

    usePreviousParam(values.policies[index][1])
  }

let prevResource = ''
function usePreviousResource(value) {
  const ref = useRef(value);
  useEffect(() => {
    prevResource = value;
  });
}

export const resetAction = (index, selectedPolicy) => {
  const { values, setValues } = useFormikContext();

  const actions = selectedPolicy.actions.map((action) => {
    return action.name
  })

  useEffect(() => {
    if ((prevResource !== '') && (prevResource !== values.policies[index][0])) {
      if (values.policies[index][1] && actions.includes(values.policies[index][1])) {
        values.options[index] = []
      } else {
        values.policies[index] = [values.policies[index][0]]
        values.options[index] = []
     }
    }

  }, [values.policies[index][0]])

  usePreviousResource(values.policies[index][0])
}


export const handleFormatting = (values) => {
  let { policies, options } = values
  options.forEach((option, index1) => {
    const optionHash = {}
    if (option.length > 1) {
      option.forEach((value, index2) => {
        if (index2 === 0) optionHash[value] = []
        const key = Object.keys(value)[0]
        if (value[key] === true) {
          optionHash[option[0]].push(key)
        }
      })
        policies[index1].push(optionHash)
      }
  })
}

export const formatOptions = (policies) => {
  const options = []

  const updatedPolicies = policies.map((policy, index) => {
      if (policy[2]) {
        const key = Object.keys(policy[2])[0]
        options.push([key])

        const values = Object.values(policy[2])[0]
        values.forEach((value) => {
          options[index].push({[value]: true})
        })

       return [policy[0], policy[1]]
      } else {
        options.push([])
        return policy
      }
    })

    return {options, updatedPolicies}
}

