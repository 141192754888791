import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TransactionForm from './TransactionForm';
import TransactionLineForm from './TransactionLines/Form';
import DebitAndCreditInputs from './TransactionLines/DebitAndCreditInputs';
import TransactionLineInputs from './TransactionLines/TransactionLineInputs';
import
  TransactionLineFlooringInputs
  from './TransactionLines/TransactionLineFlooringInputs';
import { Form as FormikForm, useFormikContext } from 'formik';
import TypeInput from './TypeInput';
import PaymentTermInput from './PaymentTermInput';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';
import TransactionPurpose from './TransactionPurpose'
import * as yup from 'yup';
import { TXN } from './Queries';

export const txnSchema = yup.object().shape({
  txnLinesAttributes: yup
    .array()
    .of(
      yup.object().shape({
        debit: yup.number(),
        credit: yup.number(),
        accountId: yup
          .number()
          .when('debit', {
            is: '' || undefined,
            then: yup.number(),
            otherwise: yup.number().required('required')
          })
          .when('credit', {
            is: '' || undefined,
            then: yup.number(),
            otherwise: yup.number().required('required')
          })
      }, ['debit', 'credit'])
    )
})

const EditForm = () => {
  const { values, setValues } = useFormikContext();
  const { id } = useParams();

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [typeLabel, setTypeLabel] = useState(null);
  const [nameLabel, setNameLabel] = useState(null);
  const [referenceLabel, setReferenceLabel] = useState(null);
  const [dateLabel, setDateLabel] = useState(null);

  const { loading, error, data } = useQuery(TXN, { variables: { id } });

  const txnLines = data?.txn.txnLines;

  useEffect(() => {
    const txnLinesAttributes = values.txnLinesAttributes

    if (data) {
      txnLinesAttributes.pop()
    }

    txnLines?.map((line) => {
      let newLine = {
        id: line.id,
        credit: line.credit || '', debit: line.debit || '',
        accountId: parseInt(line.account?.id) || undefined,
        marketId: parseInt(line.market?.id) || undefined,
        dealerId: parseInt(line.dealer?.id) || undefined,
        flooredCarId: parseInt(line.flooredCar?.id) || undefined,
        feeId: parseInt(line.fee?.id) || undefined,
        purpose: line.purpose || undefined, earnedOn:line.earnedOn || undefined,
        debitLessCredit: line.debitLessCredit || undefined,
        earnsInterest: line.earnsInterest || undefined,
        memo: line.memo || ''
      }
      txnLinesAttributes.push(newLine)
    })

    if (data?.txn?.type === 'Bill') {
      setNameLabel('Payee')
      setTypeLabel('Payee Type')
      setReferenceLabel('Invoice Number')
      setDateLabel('Invoice Date')
    } else if (data?.txn?.type === 'Vendor Credit') {
      setDateLabel('Credit Date')
      setReferenceLabel('Credit Number')
    } else if (data?.txn?.type === 'Journal Entry') {
      setReferenceLabel('JE Number')
      setDateLabel('GL Date')
    }
  }, [data])

  let columns;

  if (!(location.search.includes('detailed=t'))) {
    columns = {
      display: 'grid',
      gridTemplateColumns: '2fr 2fr 2fr 1fr 1fr 4fr',
    }
  } else {
    columns = {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2.5fr',
    }
  }

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <>
      <FormikForm>
        <TransactionForm
          typeLabel={typeLabel}
          nameLabel={nameLabel}
          referenceLabel={referenceLabel}
          dateLabel={dateLabel}
        >
          <PaymentTermInput />
          <TypeInput />
          <TransactionPurpose />
        </TransactionForm>
        <TransactionLineForm
          setDisableSubmit={setDisableSubmit}
          disableSubmit={disableSubmit}
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          columns={columns}
        >
          <TransactionLineInputs>
            <TransactionLineFlooringInputs />
            <DebitAndCreditInputs />
          </TransactionLineInputs>
        </TransactionLineForm>
      </FormikForm>
    </>
  )
}

export default EditForm;
