import React, { useState } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Formik, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import { useAlert } from '../../../hooks/useAlert';
import BasicAlert from '../../../elements/BasicAlert';
import * as yup from 'yup';
import { Typography, Spacing } from '../../../styles';
import { FeeTypeForm } from './FeeTypeForm';
import '../../../styles/automatedFees.css';
import { AUTOMATED_FEES } from './index';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'


const NEW_AUTOMATED_FEE = gql`
   mutation CreateAutomatedFee($name: String!, $action: String!, $fullyEarned: Boolean!,
    $flagAmount: Float, $accountId: Int!, $feeAmount: Float!, $option: String!, $earnsInterest: Boolean!,
    $displayName: String!, $active: Boolean!, $canBeWaived: Boolean) {
     createAutomatedFee(
       name: $name
       action: $action
       fullyEarned: $fullyEarned
       flagAmount: $flagAmount
       displayName: $displayName
       accountId: $accountId
       feeAmount: $feeAmount
       option: $option
       earnsInterest: $earnsInterest
       active: $active
       canBeWaived: $canBeWaived
     ) {
       automatedFee {
         id
       }
       errors {
         message
         path
       }
     }
   }
`

const NewAutomatedFee = () => {
  const [redirect, setRedirect] = useState(false);
  const [showAlert, displayAlert] = useAlert();

  const customSubmitHandler = (values) => {
    if (values.flagAmount === '') values.flagAmount = null
    values.accountId = parseInt(values.accountId, 10)
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: NEW_AUTOMATED_FEE,
    onCompleteRedirectTo: "/system/automated-fees",
    customSubmitHandler: customSubmitHandler
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const schema = yup.object({
    name: yup.string().max(80).required('Required'),
    displayName: yup.string().max(80).required('Required'),
    feeType: yup.string().required('Required'),
    action: yup.string().required('Required'),
    accountId: yup.string().required('Required'),
    feeAmount: yup.number().min(0, "Must be positive").required('Required'),
    flagAmount: yup.number().min(0, 'Must be positive').typeError("Required"),
    option: yup.string(),
    fullyEarned: yup.boolean().required('Required'),
    earnsInterest: yup.boolean().required('Required'),
    canBeWaived: yup.boolean().required('Required')
  });

  const initialValues = {
    name: '',
    displayName: '',
    feeType: '',
    action: '',
    accountId: '',
    flagAmount: '',
    feeAmount: 0,
    fullyEarned: false,
    option: '',
    earnsInterest: false,
    active: false,
    canBeWaived: false
  }

  function FeeProgramForm() {
    return (
      <>
        <ServerSideErrors />
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            isInvalid,
            errors,
            setValues
          }) => (
              <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
                <div style={Spacing.formWrapper}>
                  <div style={Spacing.formHeader}>
                    <h1 style={Typography.formHeader}>Create An Automated Fee</h1>
                  </div>
                </div>
                <Form.Group>
                  <Form.Label>Automated Fee Program Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.name && !errors.name}
                    isInvalid={!!errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Name of Fee to Display to Dealers</Form.Label>
                  <Form.Control
                    type="text"
                    name="displayName"
                    value={values.displayName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.displayName && !errors.displayName}
                    isInvalid={!!errors.displayName && touched.displayName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.displayName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as="select"
                    name="feeType"
                    value={values.feeType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.feeType && !errors.feeType}
                    isInvalid={!!errors.feeType && touched.feeType}
                  >
                    <option value="">Select a Fee Type</option>
                    <option value="car">Car</option>
                    <option value="dealer">Dealer</option>
                    <option value="title">Title</option>
                  </Form.Control>
                  {(!values.feeType) && <Text>Please select a fee type to display options</Text>}
                </Form.Group>
                {values.feeType && <FeeTypeForm
                  type={values.feeType}
                  values={values}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  actionType={'Create'}
                  setFieldValue={setFieldValue}
                />
                }
              </Form>
            )}
        </Formik>
      </>
    )
  }

  const LoadedContent = () => {
    return (
      <>
        {showAlert &&
          <BasicAlert componentType="automated fee" type="create" />
        }
        <FeeProgramForm />
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <Helmet>
        <title>New Automated Fee</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default NewAutomatedFee;
