import React, { useEffect } from "react";
import * as BasicForm from "../../../elements/forms";
import { divStyle } from './Style'
import { useFormikContext } from "formik";
import { SourceType } from './SourceType';
import BankAccountInformation from './BankAccountInfo';
import DealerBankSelect from 'components/src/components/Dealers/BankAccounts/Select';
import { autoTextInput, inputStyle } from './Style';

export const AdvanceTypeOptions = () => {
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    setFieldValue("sourceId", "");
    setFieldValue("bankAccountId", "");
  }, [values.sourceType]);

  return (
    <>
      <SourceType key="sourceType" />
      {
        values.sourceId &&
          <div style={divStyle}>
            <BasicForm.StyledLabel>
              Bank Account Information:
            </BasicForm.StyledLabel>
            {'Dealer' == values.sourceType ? (
              <DealerBankSelect
                id={values.sourceId}
                values={values}
                setFieldValue={setFieldValue}
                style={{...inputStyle, ...autoTextInput}}
              />
            ) : (
              <BankAccountInformation />
            )}
          </div>
      }
    </>
  )
};
