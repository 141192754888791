import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Spacing, Colors } from '../../../styles';
import { Formik, useFormikContext } from 'formik';
import { DEALER_SELECTS } from '../../../queries/selects';
import DataState from '../../DataState';
import { Form as FormikForm } from 'formik';
import Select from 'react-select';
import * as Buttons from '../../../elements/buttons';
import { Redirect } from 'react-router-dom';
import BasicAlert, { StatementOfAccountAlert } from "../../../elements/BasicAlert";
import * as yup from 'yup';
import { StyledLabel } from 'components/src/elements/forms';
import { Dots } from 'react-activity';

const STATEMENT_OF_ACCOUNT = gql`
  mutation statementOfAccount($dealerIds: [Int!], $sendTo: String) {
    statementOfAccount(
      dealerIds: $dealerIds
      sendTo: $sendTo
    ) {
      errors { message, path }
    }
  }
`

const StatementOfAccount = () => {
  const [redirect, setRedirect] = useState(false);
  const [statementAlert, displayStatementAlert] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [statementOfAccount] = useMutation(STATEMENT_OF_ACCOUNT, {
    onCompleted() {
      setProcessing(false)
      displayStatementAlert(true);
    }
  })

  const handleSubmit = (values) => {
    setProcessing(true)
    statementOfAccount({ variables: values})
  }

  const handleClose = () => {
    setRedirect(true)
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/`
        }}
      />
    );
  }

  if(processing) {
    return (
      <div style={activityDiv}>
        <div style={activityIndicator}>
          <Dots size={50} color={'#2b6448'} />
        </div>
      </div>
    )
  } else {
    return (
      <div style={Spacing.formWrapper}>
        <Formik
          initialValues={{
            dealerIds: [],
            sendTo: 'Dealer'
          }}
          onSubmit={handleSubmit}
        >
          <FilterForm handleClose={handleClose} statementAlert={statementAlert} />
        </Formik>
      </div>
    )
  }
}

const FilterForm = ({ handleClose, statementAlert }) => {
  const { loading, error, data: selectData } = useQuery(DEALER_SELECTS);
  const { setFieldValue, setValues, errors, isValid, values } = useFormikContext();

  if (loading && !selectData) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const handleChangeDealer = (selectedList) => {
    if (!selectedList) {
      values.dealerIds = [];
      setValues(values)
      return
    }

    selectedList = selectedList.map((dealer) => {
      return parseInt(dealer.value, 10)
    })

    values.dealerIds = selectedList
    setValues(values)
  }

  const formattedDealers = selectData.dealers.map((dealer) => {
    return { value: dealer.id, label: dealer.name }
  })

  const sendToOptions = [
    { value: 'Dealer', label: 'Dealer People' },
    { value: 'Both', label: 'Dealer People and send me a copy' },
    { value: 'Self', label: 'Me Only' },
  ];

  return (
    <div style={container}>
      {statementAlert && <StatementOfAccountAlert />}
      <div style={reportsFilterTitle}>Statement Of Account Report</div>
      <div style={filterContainer}>
        <div style={filterHeader}>Report Filter Options</div>
        <FormikForm>
          <StyledLabel>Email To</StyledLabel>
          <Select
            options={sendToOptions}
            styles={customStyles}
            onChange={({value}) => setFieldValue('sendTo', value)}
            defaultValue={sendToOptions[0]}
          />

          <StyledLabel>Dealers</StyledLabel>
          <Select
            options={formattedDealers}
            isMulti={true}
            styles={customStyles}
            onChange={handleChangeDealer}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose} />
            <Buttons.Standard type="submit" text="Submit" />
          </div>
        </FormikForm>
      </div>
    </div>
  )
}

const filterContainer = {
  width: '40%',
  paddingLeft: '1.5%'
}

const filterHeader = {
  fontWeight: 'bold',
  fontSize: '1em',
  color: 'rgb(214, 142, 0)',
  marginTop: '1%'
}

const reportsFilterTitle = {
  fontWeight: 'bold',
  fontSize: '1.5em',
  color: 'rgb(214, 142, 0)',
  marginTop: '1%',
  marginTop: '1%',
  paddingLeft: '0.5%'
}

const container = {
  paddingLeft: '0.5%',
  paddingRight: '0.5%'
}

const activityDiv = {
  display: 'flex',
  justifyContent: 'center',
  height: '100px',
  flexDirection: 'column'
}

const activityIndicator = {
  display: 'flex',
  justifyContent: 'center'
}

const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })

}

export default StatementOfAccount;
