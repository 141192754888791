import React, { useState } from 'react';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import { useFormikContext } from 'formik';
import { PolicyTable } from './PolicyTable';
import DataState from '../../DataState';
import { getResources } from './functionality';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';


export const roleSchema = yup.object({
  name: yup
    .string()
    .required('Required')
    .min(3, 'Role names must be at least 3 characters.')
    .max(60, 'Role names cannot be more than 60 characters'),
  policies: yup.array().of(
    yup.array().min(2, 'Required').nullable()
  )
});

export const RoleForm = () => {
  const { values,
    setValues,
    handleChange,
    handleBlur,
    onBlur,
    isValid,
    isInvalid,
    touched,
    handleSubmit,
    errors } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  const { loading, error, data } = getResources();

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { policies } = data;

  return (
    <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={touched.name && !errors.name}
          isInvalid={!!errors.name && touched.name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>
      <PolicyTable policies={policies} />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </Form>
  );
}



