import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { contactSchema, Form } from './Form';
import { AUCTION_CONTACTS } from '../../../queries/indexQueries'
import Loading from '../../../elements/Loading';

const CREATE_CONTACT = gql`
  mutation CreateContact(
    $firstName: String!,
    $lastName: String,
    $email: String,
    $title: String,
    $officePhone: String,
    $mobilePhone: String,
    $primary: Boolean!,
    $receivesAuctionAvailability: Boolean,
    $receivesPaymentInformation: Boolean,
    $ownerType: String!,
    $ownerId: Int!) {

    createContact (
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      title: $title,
      officePhone: $officePhone,
      mobilePhone: $mobilePhone,
      primary: $primary,
      receivesAuctionAvailability: $receivesAuctionAvailability
      receivesPaymentInformation: $receivesPaymentInformation
      ownerType: $ownerType,
      ownerId: $ownerId,
    ) {
      contact { id, firstName, lastName }
      errors { message, path }
    }
  }
`;


const New = () => {
  const history = useHistory();
  const auctionId = useParams().id;
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [createContact] = useMutation(CREATE_CONTACT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: AUCTION_CONTACTS,
          variables: { id: auctionId }
        }
      ],
      onCompleted() {
        setFormSubmitting(false);
        history.push({
          pathname: `/auctions/${auctionId}/contacts`,
          state: { alert: true, type: 'created' }
        })
      }
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createContact({ variables: values })
  };

  const NewForm = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Contact</h1>
          </div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              title: '',
              officePhone: '',
              mobilePhone: '',
              primary: false,
              receivesAuctionAvailability: false,
              receivesPaymentInformation: false,
              ownerType: 'Auction',
              ownerId: parseInt(auctionId),
            }}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default New;
