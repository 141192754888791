import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import DataState from '../../DataState';
import { Spacing, Typography } from '../../../styles';
import { titleCase } from '../../../utils/stringHelpers';
import { Formik } from 'formik';
import { departmentSchema, Form } from './Form';
import { DEPARTMENT, UPDATE_DEPARTMENT } from './queries';

const Edit = () => {
  const id = parseInt(useParams().id);
  const history = useHistory();

  const { loading, error, data } = useQuery(DEPARTMENT, { variables: { id } });

  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT,
    {
      onCompleted() {
        history.push({
          pathname: `/financial/departments`,
          state: { alert: true },
        })
      }
    }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const {
    name,
  } = data.department;

  const handleSubmit = (values) => {
    updateDepartment({ variables: values })
  };

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update Department: ${name}`}</h1>
        </div>
        <Formik
          initialValues={data.department}
          validationSchema={departmentSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <>
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    </>
  )
}

export default Edit;
