import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Form, programChangeSchema } from './Form';
import { Spacing, Typography } from '../../../../styles';
import { COLLATERAL_COVERAGE, COLLATERAL_COVERAGE_CHANGE } from '../../../../queries/viewQueries';
import DataState from '../../../DataState';

const UPDATE_COLLATERAL_COVERAGE_CHANGE = gql`
  mutation UpdateInsuranceProgramChange( $id: ID!,
  $insuranceProgramId: Int, $rate: Decimal!, $effectiveOn: ISO8601Date!)
  {
    updateInsuranceProgramChange(
      id: $id
      insuranceProgramId: $insuranceProgramId
      rate: $rate
      effectiveOn: $effectiveOn
    ) {
      insuranceProgramChange { id }
      errors { message, path }
    }
  }
 `

 const EditCollateralCoverageChange = () => {
   const [redirect, setRedirect] = useState(false);
   const collateralCoverageId = parseInt(useParams().id);
   const collateralCoverageChangeId = parseInt(useParams().changeId);

   const [updateCollateralCoverageChange, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_COLLATERAL_COVERAGE_CHANGE,
     {
       awaitRefetchQueries: true,
       refetchQueries: [{ query: COLLATERAL_COVERAGE, variables: { id: collateralCoverageId } }],
       onCompleted(variables) {
        setRedirect(true);
       }
     })

  const { loading, error, data } = useQuery(COLLATERAL_COVERAGE_CHANGE, {
      variables: { id: collateralCoverageChangeId }
      }
    )

  if (loading || mutationLoading) return <DataState.Loading />;
  if (error || mutationError) return <DataState.Error error={error || mutationError} />;

  const { insuranceProgramChange } = data;

 const handleSubmit = (values) => {

   updateCollateralCoverageChange({ variables: values })
 };

 const UpdateForm = () => {
  if (redirect) {
    return <Redirect
      to={{
        pathname: `/system/collateral-coverages/${collateralCoverageId}`,
        state: { alert: true }
      }}
    />
  }

 return (
   <>
     <div style={Spacing.formWrapper}>
       <div style={Spacing.formHeader}>
         <h1 style={Typography.formHeader}>
          {`Edit Collateral Coverage Change: ${insuranceProgramChange.rate}% `}
         </h1>
       </div>

       <Formik
         initialValues={{
           id: collateralCoverageChangeId,
           insuranceProgramId: collateralCoverageId,
           rate: insuranceProgramChange.rate || 0,
           effectiveOn: insuranceProgramChange.effectiveOn || '',
         }}
         validationSchema={programChangeSchema}
         onSubmit={handleSubmit}
       >
         <Form />
       </Formik>
     </div>
   </>
 )
}

return (
  <>
  <Helmet>
    <title>Edit Collateral Coverage Change</title>
  </Helmet>
  <div style={Spacing.formContainer}>
    <UpdateForm />
  </div>
  </>
);
};

export default EditCollateralCoverageChange;
