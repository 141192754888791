import React from 'react';
import * as yup from 'yup';
import * as Buttons from '../../elements/buttons';
import * as BasicForm from '../../elements/forms';
import { Form as FormikForm, useFormikContext } from 'formik';
import { Spacing } from '../../styles';
import { gql, useQuery } from '@apollo/client';
import styled from '@emotion/styled';

export const updatesSchema = yup.object({
  userIdOld: yup.string().required('Required'),
  userIdNew: yup.string().required('Required')
});

const USERS = gql`
  query {
    users {
      id
      fullName
    }
  }
`;

const HIDDEN_USERS = gql`
  query {
    users(scopes: ["hidden"]) {
      id
      fullName
    }
  }
`

const Hint = styled.p`
  margin-top: 0.6em;
  font-size: 0.8em;
  color: #303030;
`;

export const Form = ({ formSubmitting }) => {
  const { setFieldValue, values } = useFormikContext();
  const { loading, error, data } = useQuery(USERS);
  const { loading: hiddenLoading, error: hiddenError, data: hiddenData } = useQuery(HIDDEN_USERS)

  if ((loading || !data) || (hiddenLoading || !hiddenData)) return null;
  if (error || hiddenError) return <DataState.Error error={error || hiddenError} />;

  const newUserOptions = data.users.concat(hiddenData.users)

  const userOptions = newUserOptions.filter(function( obj ) {
    return obj.fullName !== ' ';
  });

  return (
    <FormikForm>
      <BasicForm.ProgrammaticSelect
        label='Select Old User'
        name='userIdOld'
        optionName='select...'
        options={userOptions}
      />
      <Hint>
        Select the User you would like to update.
      </Hint>
      <BasicForm.ProgrammaticSelect
        label='Select New User'
        name='userIdNew'
        optionName='select...'
        options={data.users}
      />
      <Hint>
        The New User will replace the Account Manager and/or Market Service Rep of the Old User.
      </Hint>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={formSubmitting}
        />
      </div>
    </FormikForm>
  );
}


export default Form;
