import moment from 'moment'

export const getTime = (data) => {
  if (!data) return;

  const dateTime = moment(data);
  if (!dateTime._isValid) return '';

  return dateTime.utc().format('h:mm a')
}

export const formatFromQuery = (data) => {
  if (!data) return;

  const dateTime = moment(data)
  if (!dateTime._isValid) return '';

  return dateTime.utc().format('HH:mm')
}
