import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import DataState from '../../DataState';
import { accountSchema, Form } from './Form';
import { titleCase } from '../../../utils/stringHelpers';

const ACCOUNT = gql`
  query ACCOUNT($id: ID!) {
    account(id: $id) {
      gl,
      name,
      special { key },
      parent {
        id
      },
      nextCheckNumber,
      accountGroupId
    }
  }
`;

const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($id: ID!, $gl: String!, $name: String!,
    $special: String, $parentId: Int, $nextCheckNumber: Int, $accountGroupId: Int)
  {
    updateAccount(id: $id, gl: $gl, name: $name, special: $special,
      parentId: $parentId, nextCheckNumber: $nextCheckNumber, accountGroupId: $accountGroupId)
    {
      account {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const Edit = () => {
  const id = parseInt(useParams().id);
  const history = useHistory();

  const { loading, error, data } = useQuery(ACCOUNT, { variables: { id } });

  const [updateAccount] = useMutation(UPDATE_ACCOUNT,
    {
      onCompleted() {
        history.push({
          pathname: `/financial/accounts`,
          state: { alert: true },
        })
      }
    }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    gl,
    name,
    special,
    nextCheckNumber,
    accountGroupId
  } = data.account;

  const parentId = parseInt(data.account.parent?.id);

  const handleSubmit = (values) => {
    if (values.nextCheckNumber === '') values.nextCheckNumber = null;
    if (!values.parentId || values.parentId === '') values.parentId = null;
    if (values.accountGroupId === '') values.accountGroupId = null;

    updateAccount({ variables: values })
  };

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update Account: ${titleCase(name)}`}</h1>
        </div>
        <Formik
          initialValues={{
            id: id,
            gl: gl || '',
            name: name || '',
            special: special?.key || '',
            parentId: parentId || '',
            nextCheckNumber: nextCheckNumber || '',
            accountGroupId: accountGroupId || ''
          }}
          validationSchema={accountSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <>
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    </>
  );
};

export default Edit;
