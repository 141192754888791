import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';


const availabilityRegExp = /^(?:Yes|No|Zero)$/

export const statusSchema = yup.object({
  name: yup.string().required('Required'),
  canFloor: yup.bool().required('Required'),
  canPay: yup.bool().required('Required'),
  canLogin: yup.bool().required('Required'),
  sendAudits: yup.bool().required('Required'),
  chooseOnNsf: yup.bool().required('Required'),
  sendAvailability: yup.string().matches(availabilityRegExp, 'Required').required('Required')
});

export const Form = () => {
  const { isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.Checkbox
        name="canPay"
        type="checkbox"
        label="Can Pay"
      />
      <BasicForm.Checkbox
        name="canFloor"
        type="checkbox"
        label="Can Floor"
      />
      <BasicForm.Checkbox
        name="canLogin"
        type="checkbox"
        label="Can Login"
      />
      <BasicForm.Checkbox
        name="sendAudits"
        type="checkbox"
        label="Send Audits to Quiktrak"
      />
      <BasicForm.Checkbox
        name="requireCorporateChange"
        type="checkbox"
        label="Requires a Corporate User with the CorporateLock[unlock] Role Policy to change the status"
      />
      <BasicForm.Checkbox
        name="hidesDealer"
        type="checkbox"
        label="Hides Dealer"
      />
      <BasicForm.Checkbox
        name="sendAaClosedMessage"
        type="checkbox"
        label="Send Closed Message to Auction Access"
      />
      <BasicForm.Checkbox
        name="default"
        type="checkbox"
        label="Default, Stop Accrual"
      />
      <BasicForm.Checkbox
        name="chooseOnNsf"
        type="checkbox"
        label="Change to This Status on NSF"
      />
      <BasicForm.Checkbox
        name="excludeFromTitleRelease"
        type="checkbox"
        label="Exclude From Title Release Report"
      />
      <BasicForm.Select name="sendAvailability" label="Send Availability">
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
        <option value="Zero">Zero</option>
      </BasicForm.Select>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
