import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import DataState from '../components/DataState';
import * as BasicForm from './forms';

const ACCOUNTS = gql`
  query Accounts($scopes: [String!]) {
    accounts(scopes: $scopes) {
      id
      name
      gl
      special { key }
    }
  }
`;

const setOptionValues = (accounts) => {
  return accounts.map((account) => {
    const name = `GL${account.gl} - ${account.name}`
    const id = parseInt(account.id);

    return <option key={id} value={id}>{name}</option>
  })
};

const AccountSelect = ({scopes, label, values, defaultSpecial}) => {
  const { loading, error, data } =
    useQuery(ACCOUNTS, { variables: { scopes } });

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  if (defaultSpecial) {
    const defaultAccount = accounts.find(account => {
      return account.special && defaultSpecial === account.special.key
    });

    if (!values.accountId) {
      values.accountId = defaultAccount.id;
    }
  }

  return (
    <div>
      <BasicForm.Select
        name="accountId"
        label={label}
        style={{width: '30%', marginBottom: '8px'}}
      >
        <option key="select" value="">Select an Account</option>
        { setOptionValues(accounts) }
      </BasicForm.Select>
    </div>
  );
};

export default AccountSelect;
