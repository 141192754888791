import { gql } from '@apollo/client';
import { NOTES_SUBQUERY } from '../../Notes/queries';

const ERRORS = `errors { path, message }`;

const USER_FIELDS = `
  id
  email
  firstName
  lastName
  mobilePhone {
    formatted
  }
  roles {
    name
  }
  roleIds
  markets {
    name
  }
  marketIds
  supervisor { id, fullName }
  hasWaiverLimit
  waiverLimit
  currentSignInAt
  hiddenAt
  canBeHidden
`;

const USER_FIELDS_AND_ERRORS = `
  user {
    ${USER_FIELDS}
  }
  ${ERRORS}
`;

export const USERS = gql`
  query Users($scopes: [String!]) {
    users(
      scopes: $scopes
    ) {
      ${USER_FIELDS}
    }
  }
`;

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      ${USER_FIELDS}
    }
  }
`;

export const HIDE_USER = gql`
  mutation HideUser($id: ID!) {
    hideUser(
      id: $id
    ) {
      ${USER_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_USER = gql`
  mutation UnhideUser($id: ID!) {
    unhideUser(
      id: $id
    )
    {
      ${USER_FIELDS_AND_ERRORS}
    }
  }
`;

export const USER_NOTES = gql`
  query User($id: ID!, $scopes: [String!]) {
    user(id: $id) {
      id
      fullName
      ${NOTES_SUBQUERY}
    }
  }
`;

const USER_VARIABLES = `
  $firstName: String
  $lastName: String
  $mobilePhone: String
  $roleIds: [Int!]
  $marketIds: [Int!]
  $supervisorId: ID
  $hasWaiverLimit: Boolean
  $waiverLimit: Decimal
`;

const USER_ARGUMENTS = `
  email: $email
  firstName: $firstName
  lastName: $lastName
  mobilePhone: $mobilePhone
  password: $password
  passwordConfirmation: $passwordConfirmation
  roleIds: $roleIds
  marketIds: $marketIds
  supervisorId: $supervisorId
  hasWaiverLimit: $hasWaiverLimit
  waiverLimit: $waiverLimit
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    ${USER_VARIABLES}
  ) {
    createUser (
      ${USER_ARGUMENTS}
    ) {
      ${USER_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String
    $password: String
    $passwordConfirmation: String
    ${USER_VARIABLES}
  ) {
    updateUser(
      id: $id
      ${USER_ARGUMENTS}
    ) {
      ${USER_FIELDS_AND_ERRORS}
    }
  }
`;
