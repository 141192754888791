import React from 'react';
import AuthLink from 'components/src/components/AuthLink';
import { ColTitle, ReportLink } from '../index';

const Titles = () => {
  return (
    <>
      <ColTitle>Titles/Shipments</ColTitle>

      <AuthLink
        to={'/reports/cars-by-title-status'}
        resource="FlooredCar"
        action="read"
        component={ReportLink}
      >
        Cars by Title Status
      </AuthLink>

      <AuthLink
        to={'/shipments'}
        resource="Shipment"
        action="update"
        component={ReportLink}
      >
        Shipments
      </AuthLink>

      <AuthLink
        to={'/reports/report/title-release'}
        resource="FlooredCar"
        action="read"
        component={ReportLink}
      >
        Title Release Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/title-status'}
        resource="FlooredCar"
        action="read"
        component={ReportLink}
      >
        Title Status Report
      </AuthLink>

      <AuthLink
        to={'/unshipped-titles'}
        resource="Shipment"
        action="update"
        component={ReportLink}
      >
        Unshipped Titles
      </AuthLink>

      <AuthLink
        to={'/setup-unshipped-titles'}
        resource="Shipment"
        action="update"
        component={ReportLink}
      >
        Setup Title Shipments from Title Release Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/end-of-day-shipments'}
        resource="Shipment"
        action="read"
        component={ReportLink}
      >
        End of Day Title Shipment Close Report
      </AuthLink>
    </>
  );
};

export default Titles;
