import React, { useState, useEffect } from "react";
import * as BasicForm from "../../../elements/forms";
import { gql } from "@apollo/client";
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import {
  divStyle,
  inputStyle,
  styledError,
  autoTextInput,
  pStyle
} from "./Style";
import { useFormikContext } from "formik";
import { AUCTIONS, APPROVED_SOURCES, DEALERS } from './Queries';

export const SourceId = () => {
  const {
    values: { sourceType, sourceId },
    values,
  } = useFormikContext();

  let formattedSourceType = `${sourceType} Source`;

  if (sourceType === "ApprovedSource") {
     formattedSourceType =  "Approved Source"
  }

  if (sourceType === 'other') {
    return null;
  }

  return <div style={divStyle}>
    <BasicForm.StyledLabel>
      Select the {formattedSourceType}:
    </BasicForm.StyledLabel>
    {
      !sourceType && <p style={pStyle}>
        Please select a Source Type
      </p>
    }
    {sourceType && sourceType === "Auction" && (
      <Auctions key="auctions" />
    )}
    {sourceType && sourceType === "ApprovedSource" && (
      <ApprovedSource key="approved-source" />
      )}
    {sourceType && sourceType === "Dealer" && (
      <Dealer key="dealer" />
    )}
  </div>
}

const Auctions = () => {
  const {
    handleBlur,
    setFieldValue,
  } = useFormikContext();
  const { loading, error, data } = useQuery(AUCTIONS);

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { auctions } = data;

  return (
    <BasicForm.AutoCompleteTextInput
      clearButton
      styledError={styledError}
      name="sourceId"
      type="text"
      style={Object.assign({}, inputStyle, autoTextInput)}
      options={auctions}
      labelKey="name"
      handleBlur={handleBlur}
      id="source-data"
      onChange={(selected) => {
        selected.length > 0
          ? setFieldValue("sourceId", selected[0].id)
          : setFieldValue("sourceId", "");
      }}
    />
  );
};

const ApprovedSource = () => {
  const { handleBlur, setFieldValue } = useFormikContext();

  const { loading, error, data } = useQuery(APPROVED_SOURCES);
  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { approvedSources } = data;

  return (
    <BasicForm.AutoCompleteTextInput
      clearButton
      styledError={styledError}
      name="sourceId"
      type="text"
      options={approvedSources}
      labelKey="name"
      style={Object.assign({}, inputStyle, autoTextInput)}
      handleBlur={handleBlur}
      id="source-data"
      onChange={(selected) => {
        selected.length > 0
          ? setFieldValue("sourceId", selected[0].id)
          : setFieldValue("sourceId", "");
      }}
    />
  );
};

const Dealer = () => {
  const id = parseInt(useParams().id);
  const { handleBlur, setFieldValue, values } = useFormikContext();

  if (!values.sourceId) {
    values.sourceId = id;
  }

  const { loading, error, data } = useQuery(DEALERS);

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { dealers } = data;

  const defaultSelected = dealers.filter((dealer) => {
    return parseInt(dealer.id) === id;
  })

  return (
    <BasicForm.AutoCompleteTextInput
      clearButton
      styledError={styledError}
      defaultSelected={defaultSelected}
      name="sourceId"
      type="text"
      style={Object.assign({}, inputStyle, autoTextInput)}
      options={dealers}
      labelKey="name"
      handleBlur={handleBlur}
      id="source-data"
      onChange={(selected) => {
        selected.length > 0
          ? setFieldValue("sourceId", selected[0].id)
          : setFieldValue("sourceId", "");
      }}
    />
  );
};
