import React, { Fragment, useState, useContext } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { Spacing } from '../../styles';
import { Link, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import * as Colors from '../../styles/colors';
import * as Formatting from '../../styles/formatting';
import {
  titleCase, currency, integerWithDelimiter
} from 'components/src/utils/stringHelpers';
import DataState from '../DataState';
import { DEALER } from '../../queries/viewQueries';
import { UPDATE_DEALER_AUTOPAY } from './Queries';
import './dealershow.css';
import FlooredByMonth from './FlooredByMonth';
import RequestLineMod from './RequestLineMod';
import RequestNAPQuote from './RequestNAPQuote';
import CompleteAnnualReview from './CompleteAnnualReview';
import { renderLotImage } from './LotImage/LotImageDetails';
import * as Buttons from '../../elements/buttons';
import NewTempProgram from './TempPrograms/New';
import AuthContext from '../../contexts/AuthContext';
import UpdateStatus from './UpdateStatus';
import FeeModal from '../Modals/Fee/New';
import * as DateHelpers from '../../utils/dateHelpers';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

const percent = value => (value * 100).toFixed(1) + '%';

const INVENTORY_EQUITY_TEXT = 'Blackbook vs Floored Amount. ' +
  'This is a dollar amount and is calculated by subtracting the total ' +
  'floored amount of the current inventory of floored cars from their ' +
  'blackbook value.';

const renderInventoryEquityTooltip = props => (
  <Tooltip {...props}>{INVENTORY_EQUITY_TEXT}</Tooltip>
);

const DealerInfo = (props) => {
  const id = props.id || useParams().id || useParams().parentId || useParams().ownerId;
  const { loading, error, data, refetch } =
    useQuery(DEALER, { variables: { id } });
  const [showModal, setShowModal] = useState(false);
  const [showDealerStatusModal, setShowDealerStatusModal] = useState(false);
  const [updateDealerAutopay] =
    useMutation(UPDATE_DEALER_AUTOPAY, { onCompleted: refetch });
  const [requestNAPQuote, setRequestNAPQuote] = useState(false);
  const [requestLineMod, setRequestLineMod] = useState(false);
  const [showCompleteAnnualReview, setShowCompleteAnnualReview] =
    useState(false);
  const auth = useContext(AuthContext);
  const [alertData, setAlertData] = useState();

  const handleClose = () => setShowModal(false)
  const [showFeeModal, setShowFeeModal] = useState(false)
  const handleFeeClose = () => setShowFeeModal(false)

  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  let showUpdateStatusLink = true;
  const requireCorporateChangeForStatus = data.dealer.dealerStatus.requireCorporateChange;
  if (requireCorporateChangeForStatus) {
    if (auth.hasPolicy('CorporateLock', 'unlock')) {
      showUpdateStatusLink = true;
    } else {
      showUpdateStatusLink = false;
    }
  }

  const dealer = data.dealer;
  const {
    accountNumber,
    creditLine,
    availableCredit,
    currentPrincipal,
    lotPhotoUpload,
  } = dealer;

  const lotImage = lotPhotoUpload?.fileUrl || '';
  const marketName = data.dealer.market.name;
  const status = data.dealer.dealerStatus.name;
  const flooredCarCount = data.dealer.flooredCars.length

  const onAccountLink = <a href={'/dealers/' + id + '/accounts/on-account'}>View</a>
  const reservesLink = <a href={'/dealers/' + id + '/accounts/reserves'}>View</a>
  const dealerId = parseInt(data.dealer.id)

  let dealerProgram;
  if (data.dealer.dealerLinesAttributes[0].dealerProgram?.name.length > 20) {
    dealerProgram = <a href={'/system/dealer-programs/' + data.dealer.dealerLinesAttributes[0].dealerProgram?.id}>{titleCase(data.dealer.dealerLinesAttributes[0].dealerProgram?.name.substring(0, 20))}...</a>
  } else {
    dealerProgram = <a href={'/system/dealer-programs/' + data.dealer.dealerLinesAttributes[0].dealerProgram?.id}>{titleCase(data.dealer.dealerLinesAttributes[0].dealerProgram?.name)}</a>
  }

  let rateProgram;
  if (data.dealer.dealerLinesAttributes[0].rateProgram?.name.length > 20) {
    rateProgram = <a href={'/system/rate-programs/' + data.dealer.dealerLinesAttributes[0].rateProgram?.id}>{titleCase(data.dealer.dealerLinesAttributes[0].rateProgram?.name.substring(0, 20))}...</a>
  } else {
    rateProgram = <a href={'/system/rate-programs/' + data.dealer.dealerLinesAttributes[0].rateProgram?.id}>{titleCase(data.dealer.dealerLinesAttributes[0].rateProgram?.name)}</a>
  }

  let activeDealerProgram = null;
  let aDPE = null
  if(data.dealer.dealerLinesAttributes[0].activeDealerProgram) {
    aDPE = `Ends On: ${DateHelpers.setDate(data.dealer.dealerLinesAttributes[0].tempDealerProgramEnd)}`;
    if (data.dealer.dealerLinesAttributes[0].activeDealerProgram?.name.length > 20) {
      activeDealerProgram = <a href={'/system/dealer-programs/' + data.dealer.dealerLinesAttributes[0].activeDealerProgram?.id}>{titleCase(data.dealer.dealerLinesAttributes[0].activeDealerProgram?.name.substring(0, 20))}...</a>
    } else {
      activeDealerProgram = <a href={'/system/dealer-programs/' + data.dealer.dealerLinesAttributes[0].activeDealerProgram?.id}>{titleCase(data.dealer.dealerLinesAttributes[0].activeDealerProgram?.name)}</a>
    }
  }

  let activeRateProgram = null;
  let aRPE = null;
  if(data.dealer.dealerLinesAttributes[0].activeRateProgram) {
    aRPE = `Ends On: ${DateHelpers.setDate(data.dealer.dealerLinesAttributes[0].tempRateProgramEnd)}`;
    if (data.dealer.dealerLinesAttributes[0].activeRateProgram?.name.length > 20) {
      activeRateProgram = <a href={'/system/rate-programs/' + data.dealer.dealerLinesAttributes[0].activeRateProgram?.id}>{titleCase(data.dealer.dealerLinesAttributes[0].activeRateProgram?.name.substring(0, 20))}...</a>
    } else {
      activeRateProgram = <a href={'/system/rate-programs/' + data.dealer.dealerLinesAttributes[0].activeRateProgram?.id}>{titleCase(data.dealer.dealerLinesAttributes[0].activeRateProgram?.name)}</a>
    }
  }

  let names = [];
  const dealerTypes = data.dealer.dealerCategories.map((type) => {
    names.push(type.name)
  })

  const creditIncrease = data.dealer.tempPrograms.map((credit) => {
    const today = new Date();

    let date = new Date(credit.endOn)

    date.setDate(date.getDate() + 1)
    date.setHours(23.00)

    if (credit.creditLimitIncrease === null) {
      return null;
    } else {
      if (new Date(credit.effectiveOn) <= today && date > today) {
        return (
          <Fragment key={credit.id}>
            Temp: {currency(parseInt(credit.creditLimitIncrease))}<br />
          </Fragment>
        )
      }
    }
  });

  let sum;
  sum = parseFloat(data.dealer.creditLine);

  const total = data.dealer.tempPrograms.map((num) => {
    const today = new Date();
    if (num.creditLimitIncrease === null) {
    } else {
      if (new Date(num.effectiveOn) <= today && new Date(num.endOn) >= today) {
        sum += parseInt(num.creditLimitIncrease)
      }
    }
  })

  const onAccountBalance = data.dealer.onAccountBalance
  const reserveBalance = data.dealer.reserveBalance

  const greenText = {
    color: Colors.xlGreen,
  }

  const boldText = {
    fontWeight: 750,
    marginLeft: '2%',
  }

  const bottomBorder = {
    height: '5px',
    width: '100%',
    backgroundColor: Colors.xlOrange,
    marginTop: '20px',
  }

  const imageContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const buttonRow = {
    ...Spacing.buttonRow,
    margin: '0%',
    width: '35%',
    marginLeft: '36%'
  }

  const statusId = data.dealer.dealerStatus.id

  const showActiveDealerProgramLabel = () => {

    if(data.dealer.dealerLinesAttributes[0].activeDealerProgram?.id !==
      data.dealer.dealerLinesAttributes[0].dealerProgram?.id) {
      return `Temp Dealer Program`;
    }else {
      return null;
    }
  }

  const showActiveDealerProgram = () => {
    if(data.dealer.dealerLinesAttributes[0].activeDealerProgram?.id !==
      data.dealer.dealerLinesAttributes[0].dealerProgram?.id) {
      return activeDealerProgram;
    }else {
      return null;
    }
  }

  const showActiveDealerProgramEnd = () => {
    if(data.dealer.dealerLinesAttributes[0].activeDealerProgram?.id !==
      data.dealer.dealerLinesAttributes[0].dealerProgram?.id) {
      return aDPE;
    }else {
      return null;
    }
  }

  const showActiveRateProgramLabel = () => {
    if(data.dealer.dealerLinesAttributes[0].activeRateProgram?.id !==
      data.dealer.dealerLinesAttributes[0].rateProgram?.id) {
      return `Temp Rate Program`;
    }else {
      return null;
    }
  }

  const showActiveRateProgram = () => {
    if(data.dealer.dealerLinesAttributes[0].activeRateProgram?.id !==
      data.dealer.dealerLinesAttributes[0].rateProgram?.id) {
      return activeRateProgram;
    }else {
      return null;
    }
  }

  const showActiveRateProgramEnd = () => {
    if(data.dealer.dealerLinesAttributes[0].activeRateProgram?.id !==
      data.dealer.dealerLinesAttributes[0].rateProgram?.id) {
      return aRPE;
    }else {
      return null;
    }
  }

  const updateAutopay = enable => {
    const confirmation = enable ? "Really enable AutoPay?" :
      "Really cancel AutoPay";

    if (confirm(confirmation)) {
      updateDealerAutopay({ variables: { id, autopay: enable } });
    }
  };

  const licenseExpiredOver30Days = dealer.licenseExpiresOn &&
    new Date().getTime() - new Date(dealer.licenseExpiresOn).getTime() >
    (1000 * 3600 * 24 * 30)

  const auditFoundCount = dealer.recentAudits.map(audit => audit.unitsSeen).
    reduce((a, b) => a + b, 0);
  const auditUnitCount = dealer.recentAudits.map(audit => audit.numberOfUnits).
    reduce((a, b) => a + b, 0);
  const auditFoundPercent = auditFoundCount / auditUnitCount;

  const creditScores = dealer.dealerPeople.map(person => person.creditScore).
    filter(creditScore => creditScore);

  return (
    <Fragment>
      {alertData && (
        <Alert variant={alertData.variant || 'success'} style={Spacing.alerts}>
          {alertData.content}
        </Alert>
      )}
      <Container style={Formatting.infoContainer}>
        <Row>
          <div className="col-xl-2" style={imageContainer} >
            {renderLotImage(lotImage, id, lotPhotoUpload?.id)}
          </div>
          <div className="col-xl-3 info-section">
             <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Status</th>
                </tr>
                <tr>
                  <td className={"dealer-status"}>
                    {status}
                  </td>
                </tr>
              </tbody>
            </table><br />
            <Row className="info-row">
              <Col>Account Number:</Col>
              <Col align="right">{accountNumber}</Col>
            </Row>
            <Row className="info-row">
              <Col>Currently Floored Cars</Col>
              <Col align="right">{flooredCarCount}</Col>
            </Row>
            <Row className="info-row">
              <Col>Credit Limit:</Col>
              <Col align="right">
                {currency(data.dealer.creditLine)}
                <br />
                {creditIncrease}
                Total: {currency(sum)}
                <br />
                Contracted Line: {currency(data.dealer.contractedLine)}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Current Principal Balance:</Col>
              <Col align="right">{currency(currentPrincipal)}</Col>
            </Row>
            <Row className="info-row">
              <Col>Current Availability</Col>
              <Col align="right">{currency(availableCredit)}</Col>
            </Row>
            <Row className="info-row">
              <Col>On Account Balance</Col>
              <Col align="right">{currency(onAccountBalance)} ({onAccountLink})</Col>
            </Row>
            <Row className="info-row">
              <Col>Reserves Balance</Col>
              <Col align="right">{currency(reserveBalance)} ({reservesLink})</Col>
            </Row>
            <Row className="info-row">
              <Col>AutoPay</Col>
              <Col align="right">
                AutoPay {data.dealer.autopay ? 'Enabled' : 'Disabled'}
                {auth.hasExactPolicy('Dealer', 'autopay') && (
                  <span>
                    {' ('}
                      <a
                        href="#"
                        onClick={() => updateAutopay(!data.dealer.autopay)}
                      >
                       {data.dealer.autopay ? 'Cancel' : 'Enable'}
                     </a>
                    )
                  </span>
                )}
                {data.dealer.dealerLine.autopayDisabled &&
                  ' (Excluded from Autopay)'
                }
              </Col>
            </Row>
            <Row className="info-row">
              <Col>
                Average Purchase Price
                <br />
                <span style={{marginLeft: 10}}>Trailing 12 Months</span>
                <br />
                <span style={{marginLeft: 10}}>Last 90 Days</span>
                <br />
                TTM Avg Gross Yield
                <br />
                Net Yield - TTM
                <br />
                Net Yield - Last 90 Days
              </Col>
              <Col align="right">
                <br />
                {currency(dealer.averageLoanTTM)}
                <br />
                {currency(dealer.averageLoan90Days)}
                <br />
                {percent(dealer.grossYieldTTM)}
                <br />
                {percent(dealer.netYieldTTM)}
                <br />
                {percent(dealer.netYield90Days)}
              </Col>
            </Row>
          </div>
          <div className="col-xl-4 info-section">
            <Row className="info-row">
              <Col>
                Dealer Program
                <div>{showActiveDealerProgramLabel()}</div>
              </Col>
              <Col align="right">
                {dealerProgram}
                <div>{showActiveDealerProgram()}</div>
                <div>{showActiveDealerProgramEnd()}</div>
              </Col>
            </Row>
            <Row className="info-row">
              <Col>
                Rate Program
                <div>{showActiveRateProgramLabel()}</div>
              </Col>
              <Col align="right">
                {rateProgram}
                <div>{showActiveRateProgram()}</div>
                <div>{showActiveRateProgramEnd()}</div>
              </Col>
            </Row>
            <Row className="info-row">
              <Col>
                <FlooredByMonth data={dealer.flooredByMonthCounts} />
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Paid Cars - Average Turn Days on Floor (TTM)</Col>
              <Col align="right">
                {integerWithDelimiter(dealer.averageDaysOnAccountTTM)}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Paid Cars - Average Turn Days on Floor (Last 90 Days)</Col>
              <Col align="right">
                {integerWithDelimiter(dealer.averageDaysOnAccount90Days)}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>License Expired Over 30 Days?</Col>
              <Col align="right">{licenseExpiredOver30Days ? 'Y' : 'N'}</Col>
            </Row>
            <Row className="info-row">
              <Col>On Time Pay History</Col>
              <Col align="right">{percent(dealer.onTimePay)}</Col>
            </Row>
            <Row className="info-row">
              <Col>
                <Link
                  to={`/dealers/${dealer.id}/nsf-history`}
                  style={{textDecoration: 'underline'}}
                >
                  NSF Count
                </Link>
              </Col>
              <Col align="right">{dealer.nsfCount}</Col>
            </Row>
            <Row className="info-row">
              <Col className="info-link-box">
                <a
                  onClick={e => {e.preventDefault(); setRequestLineMod(true);}}
                >
                  Request Temp/Line Mod
                </a>
                <a
                  onClick={e => {e.preventDefault(); setRequestNAPQuote(true);}}
                >
                  Request NAP Flooring Quote
                </a>
                {dealer.unverifiedAuditCarsCount > 0 && (
                  <Link to={`/dealers/${dealer.id}/audits/`}>
                    Dealer Has Unverified Audit Units
                  </Link>
                )}
              </Col>
            </Row>
          </div>
          <div className="col-xl-3 info-section">
            <Row className="info-row">
              <Col>Market</Col>
              <Col align="right">{marketName}</Col>
            </Row>
            <Row className="info-row">
              <Col>Account Manager</Col>
              <Col align="right">{dealer.accountRep?.fullName}</Col>
            </Row>
            <Row className="info-row">
              <Col>
                <div>
                  Last {dealer.recentAudits.length} Audits
                  : {percent(auditFoundPercent)}
                </div>
                <table style={{minWidth: '40%', width: '300px'}}>
                  <tbody>
                    {dealer.recentAudits.map(audit => (
                      <tr key={audit.id}>
                        <td>
                          <Link
                            to={`/dealers/${dealer.id}/audits/${audit.id}`}
                            style={{display: 'block', color: 'black'}}
                          >
                            {DateHelpers.setDate(audit.inspectionOn)}
                          </Link>
                        </td>
                        <td>
                          {audit.unitsSeen}/{audit.numberOfUnits} Found
                        </td>
                        <td align="right">
                          {percent(audit.unitsSeen/audit.numberOfUnits)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Annual Review Date</Col>
              <Col align="right">
                {dealer.annualReviewOn &&
                  DateHelpers.setDate(dealer.annualReviewOn)
                }
                {auth.hasPolicy('Dealer', 'update') && (
                  <Buttons.Inline
                    text= "Complete"
                    onClick={() => setShowCompleteAnnualReview(true)}
                  >
                    Complete
                  </Buttons.Inline>
                )}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Average Days on Account</Col>
              <Col align="right">
                {parseInt(dealer.averageDaysOnAccountUnpaid)}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Audit Units > 7 Days Unverified</Col>
              <Col align="right">
                {dealer.unverifiedAuditCarsCount7Days > 0 ?
                  <Link to={`/dealers/${dealer.id}/audits/`}>Yes</Link> :
                  'No'
                }
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Borrowed Titles > 7 Days Unpaid</Col>
              <Col align="right">
                {dealer.borrowedTitleDueCount > 0 ? 'Yes' : 'No'}
              </Col>
            </Row>
            {auth.hasExactPolicy('DealerPerson', 'credit_score') && (
              <Row className="info-row">
                <Col>
                  <Link
                    to={`/dealers/${dealer.id}/credit-history`}
                    style={{textDecoration: 'underline'}}
                  >
                    Credit Score/Risk Score
                  </Link>
                </Col>
                <Col align="right">{creditScores.join(', ')}</Col>
              </Row>
            )}
            <Row className="info-row">
              <Col>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderInventoryEquityTooltip}
                >
                  <span>
                    Inventory Equity{'  '}
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                </OverlayTrigger>
              </Col>
              <Col align="right">
                {currency(parseFloat(dealer.blackbookMinusFlooredAmount))}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Dealer Type</Col>
              <Col align="right">
              {dealer.dealerCategories.map(category => (
                <div key={category.id}>{category.name}</div>
              ))}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Utilization Rate YOY</Col>
              <Col align="right">{dealer.utilizationYoy}</Col>
            </Row>
            <Row className="info-row">
              <Col>Number of Times Locked</Col>
              <Col align="right">{dealer.timesLocked}</Col>
            </Row>
          </div>
        </Row>
      </Container>
      <div style={buttonRow}>
        {showUpdateStatusLink
          ?
          <Buttons.Link style={{ width: '100%' }}
            text="Update Status"
            onClick={() => setShowDealerStatusModal(true)}
          />
          : null
        }
        <Buttons.Link style={{ width: '100%' }}
          text="Add Temp Program"
          onClick={() => setShowModal(true)}
        />
        <Buttons.Link style={{ width: '100%' }}
          text="Add A Dealer Level Fee"
          onClick={() => setShowFeeModal(true)}
        />
      </div>
      <div style={bottomBorder} />
      {
        showModal && <NewTempProgram
          showModal={showModal}
          setShowModal={setShowModal}
        />
      }
      {
        showDealerStatusModal &&
        <UpdateStatus
          showDealerStatusModal={showDealerStatusModal}
          setShowDealerStatusModal={setShowDealerStatusModal}
          currentStatus={statusId}
        />
      }
      {
        showFeeModal && <FeeModal
          showModal={showFeeModal}
          setShowModal={setShowFeeModal}
          dealerId={dealerId}
          handleClose={handleFeeClose}
        />
      }
      {requestLineMod && (
        <RequestLineMod
          dealer={dealer}
          closeModal={() => setRequestLineMod(false)}
          setAlertData={setAlertData}
        />
      )}
      {requestNAPQuote && (
        <RequestNAPQuote
          closeModal={() => setRequestNAPQuote(false)}
          setAlertData={setAlertData}
        />
      )}
      {showCompleteAnnualReview && (
        <CompleteAnnualReview
          dealer={dealer}
          closeModal={() => setShowCompleteAnnualReview(false)}
          refetch={refetch}
          setAlertData={setAlertData}
        />
      )}
    </Fragment>
  )
}

export default DealerInfo;
