import React, { useState, useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { useLazyQuery } from '@apollo/client';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext, useField } from 'formik';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import * as StringHelpers from '../../utils/stringHelpers';
import { DEALER_USER_BY_EMAIL } from './queries';

export const personSchema = yup.object({
  name: yup.string(),
  email: yup.string().email('Please enter a valid email address'),
  mobilePhone: yup.string().matches(StringHelpers.phoneRegExp,
    { message: "Please enter a valid number.", excludeEmptyString: false }),
  officePhone: yup.string().matches(StringHelpers.phoneRegExp,
    { message: "Please enter a valid number.", excludeEmptyString: false }),
  homePhone: yup.string().matches(StringHelpers.phoneRegExp,
    { message: "Please enter a valid number.", excludeEmptyString: false }),
  homeZip: yup.string().matches(StringHelpers.zipRegExp,
    { message: 'Please enter a valid ZIP code.', excludeEmptyString: false }),
  creditScore: yup.number('Must be a number').max(850),
});

const EmailInput = ({ dealerUserId }) => {
  const [checkEmail, { data }] = useLazyQuery(DEALER_USER_BY_EMAIL);

  const [field, meta] = useField('email');

  const handleBlur = e => {
    if (e.target.value && /@/.test(e.target.value)) {
      checkEmail({ variables: { email: e.target.value.trim() } });
    }
    field.onBlur(e);
  }

  const dealerUser = data?.dealerUserByEmail;

  return (
    <>
      <BasicForm.StyledLabel htmlFor="email">Email</BasicForm.StyledLabel>
      <BasicForm.StyledTextInput
        className="form-control"
        type="email"
        {...field}
        onBlur={handleBlur}
      />
      {meta.touched && meta.error && (
        <BasicForm.StyledErrorMessage className="error">
          {meta.error}
        </BasicForm.StyledErrorMessage>
      )}
      {dealerUser && dealerUser.id !== dealerUserId && (
        <BasicForm.StyledErrorMessage className="error">
          A Dealer User with email {dealerUser.email} already exists. If you
          continue, this dealer person will be added to the existing user
          account. The user will be able to choose which dealer's information to
          view in the dealer portal.<br /><br />

          Current dealers:
          <ul>
            {dealerUser.dealerPeople.map(person => (
              <li key={person.id}>{person.dealer.name}</li>
            ))}
          </ul>
        </BasicForm.StyledErrorMessage>
      )}
    </>
  );
};

export const Form = ({ dealerUserId }) => {
  const auth = useContext(AuthContext);
  const { values, setFieldValue, isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="firstName"
        type="text"
        label="First Name"
      />
      <BasicForm.TextInput
        name="lastName"
        type="text"
        label="Last Name"
      />
      <BasicForm.TextInput
        name="title"
        type="text"
        label="Title"
      />
      <BasicForm.Select
        name="role"
        label="Role"
        value={values.role || 'Normal'}
      >
        <option value='Normal'>Normal</option>
        <option value='Financial'>Financial</option>
        <option value='Dealer Principal'>Dealer Principal</option>
      </BasicForm.Select>
      <EmailInput dealerUserId={dealerUserId} />
      <BasicForm.PhoneInput
        name="mobilePhone"
        label="Mobile Phone"
        value={values.mobilePhone || ''}
        onChange={event =>
          StringHelpers.handleNumberChange(event, 'mobilePhone', setFieldValue)
        }
      />
      <BasicForm.PhoneInput
        name="officePhone"
        label="Office Phone"
        value={values.officePhone || ''}
        onChange={event =>
          StringHelpers.handleNumberChange(event, 'officePhone', setFieldValue)
        }
      />
      <BasicForm.PhoneInput
        name="homePhone"
        label="Home Phone"
        value={values.homePhone || ''}
        onChange={event =>
          StringHelpers.handleNumberChange(event, 'homePhone', setFieldValue)
        }
      />
      <BasicForm.TextInput
        name="homeStreet"
        type="text"
        label="Home Street"
      />
      <BasicForm.TextInput
        name="homeCity"
        type="text"
        label="Home City"
      />
      <BasicForm.StateAbbreviationSelect
        name="homeState"
        type="text"
        label="Home State"
      />
      <BasicForm.TextInput
        name="homeZip"
        type="text"
        label="Home Zip"
      />
      {auth.hasExactPolicy('DealerPerson', 'credit_score') && (
        <BasicForm.TextInput
          name="creditScore"
          type="text"
          label="Credit Score"
        />
      )}
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
