import React from 'react';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import { useFormikContext } from 'formik';
import Button from 'react-bootstrap/Button';
import { Colors } from '../../../styles';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import Table from 'react-bootstrap/Table';
import * as Options from './Options';
import '../../../styles/feePrograms.css';
import { handleAddPolicy, handleRemovePolicy } from './functionality';


export const PolicyTable = (props) => {
  const { policies } = props;
  const { values, setValues } = useFormikContext();

  return <Table>
    <thead>
      <tr>
        <th>Resource</th>
        <th>Action</th>
        <th>Options</th>
        <th></th>
        <th className='fee-button-header'>
          <Button
            key={'addPolicy'}
            onClick={() => handleAddPolicy(values, setValues)}
            style={buttonColor}
          >
            Add
            </Button>
        </th>
      </tr>
    </thead>
    <tbody>
      {values.policies.map((policy, index) => {
        return <tr key={index}>
          <td>
            <Options.SelectResource index={index} policies={policies} /></td>
          {values.policies[index][0] ? (
            <td><Options.SelectAction
              policies={policies}
              userPolicy={values.policies[index][0]}
              index={index}
            />
            </td>
          ) : <td></td>
          }
          {values.policies[index][0] && values.policies[index][1] ? (
            <td>
              <Options.SelectParam
                policies={policies}
                userPolicy={values.policies[index][0]}
                userAction={values.policies[index][1]}
                index={index}
              />
            </td>
          ) : <td></td>
          }
          {values.options[index] && values.options[index][0] &&
            (values.policies[index][0] && values.policies[index][1])
            && (values.policies[index][1] !== null) ? (
              <td style={{ width: '10%' }}>
                <Options.SelectOptionCheckbox
                  policies={policies}
                  userPolicy={values.policies[index][0]}
                  userAction={values.policies[index][1]}
                  userOption={values.options[index][0]}
                  index={index}
                />
              </td>
            ) : <td style={{ width: '10%' }}></td>
          }
          <th></th>
          <td>
            <Button
              key={'removePolicy'}
              onClick={() => handleRemovePolicy(values, setValues, index)}
              style={buttonColor}
            >
              Remove
                </Button>
          </td>
        </tr>
      })}
    </tbody>
    <tr>
      <th>Resource</th>
      <th>Action</th>
      <th>Options</th>
      <th></th>
      <th className='fee-button-header'>
        <Button
          key={'addPolicy'}
          onClick={() => handleAddPolicy(values, setValues)}
          style={buttonColor}
        >
          Add
        </Button>
      </th>
    </tr>
  </Table>
}

const buttonColor = {
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen,
  color: 'white',
  marginTop: '10px',
  width: '100%'
}
