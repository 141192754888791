import React, { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../../elements/forms";
import { ErrorMessage } from "../../../../elements/ErrorMessage";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useParams, Redirect } from "react-router-dom";
import DataState from "../../../DataState";
import DealerBankSelect from 'components/src/components/Dealers/BankAccounts/Select';

const ACCOUNTS = gql`
  query {
    accounts(scopes: ["bank"]) {
      id
      name
      gl
      special {
        key
      }
    }
  }
`;

export const AdvanceTypeOptions = () => {
  const {
    errors,
    setFieldValue,
    values: { sourceType },
  } = useFormikContext();

  useEffect(() => {
    setFieldValue("bankAccountId", null);
  }, [sourceType]);

  return <Dealer key="dealer" />;
};

const styledError = {
  marginLeft: "16px",
  marginTop: "0px",
};

const Dealer = ({ formattedSourceType }) => {
  const { handleBlur, setFieldValue, values, errors } = useFormikContext();
  const id = parseInt(useParams().id);

  return (
    <div style={divStyle}>
      Select the Dealer's Source ACH:
      <DealerBankSelect
        id={id}
        values={values}
        setFieldValue={setFieldValue}
        style={{ width: "350px", paddingLeft: "10px" }}
        styledError={styledError}
        handleBlur={handleBlur}
      />
      <BasicForm.InlineStyledErrorMessage>
        {errors.bankAccountId}
      </BasicForm.InlineStyledErrorMessage>
    </div>
  );
};

export const ExternalTypeOptions = ({ quoteAmount, setDisabled }) => {
  const {
    setFieldValue,
    errors,
    values,
    values: { txnType },
  } = useFormikContext();
  let [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    setPaymentMethod(txnType);
  }, [txnType]);

  useEffect(() => {
    values.accountId = parseInt(values.accountId) || null;
  }, [values.accountId]);

  const { loading, error, data } = useQuery(ACCOUNTS);

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  const paymentsBank = accounts.find((account) => {
    return account.special && "payments_bank" === account.special.key;
  });

  if (!values.accountId) {
    values.accountId = paymentsBank.id;
  }

  (values.total <= quoteAmount) ? setDisabled(true) : setDisabled(false)

  return [
    <PaymentMethod key="txnType" errors={errors} />,
    txnType && paymentMethod && (
      <div>
        <Advance key="check" type={paymentMethod} />
        <div>
          <BasicForm.Select name="accountId" label="Payment Made to GL Account">
            <option value="">Select an Account</option>
            {setOptionValues(accounts)}
          </BasicForm.Select>
          <BasicForm.StyledErrorMessage>
            {errors.accountId}
          </BasicForm.StyledErrorMessage>
          <BasicForm.InlineStyledErrorMessage style={{ marginTop: "8px" }}>
            {values.total && values.total <= quoteAmount && (
              <p style={{ marginBottom: "0px" }}>
                Must be greater than or equal to the payment amount.
              </p>
            )}
          </BasicForm.InlineStyledErrorMessage>
        </div>
      </div>
    ),
  ];
};

const PaymentMethod = ({ errors }) => {
  return (
    <div style={divStyle}>
      What will the method of payment be?
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Check"
        value="Check"
      />
      <BasicForm.Radio name="txnType" type="radio" label="ACH" value="ACH" />
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Wire Transfer"
        value="Wire"
      />
      <BasicForm.Radio name="txnType" type="radio" label="Cash" value="Cash" />
      <BasicForm.InlineStyledErrorMessage>
        {errors && errors.txnType}
      </BasicForm.InlineStyledErrorMessage>
    </div>
  );
};

const Advance = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const { type } = props;
  let advanceToLabel = type;
  if (type !== "Check") advanceToLabel = `${type} Reference`;

  const divStyle = {
    display: "flex",
    alignItems: "center",
  };

  const inlineInputStyle = {
    ...inputStyle,
    marginLeft: "0px",
  };

  const labelStyle = {
    marginTop: "0px",
  };

  if (type !== "Cash") {
    return (
      <div style={divStyle}>
        <div>
          <BasicForm.TextInput
            name="name"
            type="text"
            style={inlineInputStyle}
            label={`${type} From:`}
            labelStyle={labelStyle}
          />
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <BasicForm.TextInput
            name="reference"
            type="text"
            label={`${advanceToLabel} Number:`}
            style={inlineInputStyle}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }

  if (type === "Cash") {
    values.name = "";

    return (
      <div style={divStyle}>
        <div>
          <BasicForm.TextInput
            name="reference"
            type="text"
            label="Reference"
            style={inlineInputStyle}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }
};

const setOptionValues = (accounts) => {
  return accounts.map((account) => {
    const name = `GL${account.gl} - ${account.name}`;
    const id = parseInt(account.id);

    return <option value={id}>{name}</option>;
  });
};

export const divStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
};

const inputStyle = {
  marginLeft: "12px",
  height: "30px",
};
