import React, { useState, Fragment } from 'react';
import { gql, useMutation } from '@apollo/client';
import { RECONCILE } from '../../../queries/indexQueries';
import { useAlert } from '../../../hooks/useAlert';
import { useHistory } from 'react-router-dom';
import { Spacing, Typography } from '../../../styles';
import { Formik } from 'formik';
import { reconcileSchema, Form } from './Form';
import Loading from '../../../elements/Loading';
import { getServerSideErrors } from '../../../utils/errorHelpers';

const CREATE_RECONCILE = gql`
  mutation CreateReconcile(
    $accountId: Int!
    $startOn: ISO8601Date!
    $endOn: ISO8601Date!
    $startBalance: Decimal!
    $endBalance: Decimal
  ) {
    createReconcile(
      accountId: $accountId
      startOn: $startOn
      endOn: $endOn
      startBalance: $startBalance
      endBalance: $endBalance
    ) {
      reconcile {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const New = () => {
  const [showAlert, displayALert] = useAlert();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const history = useHistory();

  const [createReconcile] = useMutation(CREATE_RECONCILE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: RECONCILE
        }
      ],
      onCompleted(values) {
        setFormSubmitting(false);
        const serverErrors = getServerSideErrors(values);
        if (serverErrors) {
          setServerSideErrors(serverErrors);
        } else {
          const id = values.createReconcile.reconcile.id;
          history.push({
            pathname: `/financial/reconciles/${id}`,
            state: { alert: true, type: 'created' }
          })
        }
      }
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true);
    createReconcile({ variables: values })
  };

  const NewForm = () => {
    return (
      <Fragment>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Reconcile An Account</h1>
          </div>
          <Formik
            initialValues={{
              accountId: '',
              startOn: '',
              endOn: '',
              startBalance: '',
              endBalance: '',
            }}
            validationSchema={reconcileSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </Fragment>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }

    return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    );
  };

  return (
    <Fragment>
      {serverSideErrors &&
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      }
      <div style={Spacing.formContainer}>
        <DisplayContent />
      </div>
    </Fragment>
  );
};

export default New;
