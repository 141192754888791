import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { useFormikContext } from 'formik';
import Modal from 'react-bootstrap/Modal';

const ACCOUNTS = gql`
  query {
    accounts {
      id
      name
      gl
    }
  }
`;

const labelStyle = {
  marginTop: "20px",
};

export const feeSchema = yup.object({
  type: yup
    .string()
    .required('Choose A Fee Type'),
  name: yup
    .string()
    .max(40)
    .required('Enter Name Of Fee'),
  amount: yup
    .number()
    .required('Enter Amount')
});

export const Form = ({setShowModal, showModal, handleSubmit}) => {
  const { setFieldValue, values, errors, isValid, dirty } = useFormikContext();

  const [formSubmitting, setFormSubmitting] = useState();
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => setShowModal(false);

  const { loading, error, data } = useQuery(ACCOUNTS);

  useEffect(() => {
    if (values.type === "Lot Audit Fee") {
      setFieldValue('incomeAccountId', 21)
      setFieldValue('name', 'Lot Audit Fee')
      setFieldValue('amount', 90)
      setDisabled(true)
    } else if (values.type === "Off Site Lot Audit Fee") {
      setFieldValue('incomeAccountId', 22)
      setFieldValue('name', 'Off Site Lot Audit Fee')
      setFieldValue('amount', '')
      setDisabled(true)
    } else if (values.type === "Repairs") {
      setFieldValue('incomeAccountId', 41)
      setFieldValue('name', 'Repo Repairs')
      setFieldValue('amount', '')
      setDisabled(true)
    } else if (values.type === "Repo / Towing") {
      setFieldValue('incomeAccountId', 41)
      setFieldValue('name', 'Repo / Towing')
      setFieldValue('amount', '')
      setDisabled(true)
    } else if (values.type === "Documentary Stamp Tax") {
      setFieldValue('incomeAccountId', 43)
      setFieldValue('name', 'Documentary Stamp Tax')
      setFieldValue('amount', '')
      setDisabled(true)
    } else if (values.type === "Create Your Own Fee") {
      setFieldValue('incomeAccountId', 42)
      setFieldValue('name', '')
      setFieldValue('amount', '')
      setDisabled(false)
    }
  }, [values.type]);

  useEffect(() => {
    if (values.flooredCarId === undefined) {
      setFieldValue('flooredCarId', null)
    }
  }, [values.flooredCarId]);

  if (loading || !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add a Manual Fee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BasicForm.Select name="type" label="Choose a Fee From These Options">
            <option value="">Select a Fee</option>
            <option>Create Your Own Fee</option>
            { !values.flooredCarId && <option>Documentary Stamp Tax</option> }
            <option>Lot Audit Fee</option>
            <option>Off Site Lot Audit Fee</option>
            <option>Repairs</option>
            <option>Repo / Towing</option>
          </BasicForm.Select>
          <BasicForm.TextInput
            name="name"
            type="text"
            label="Name of Fee"
            labelStyle={labelStyle}
            disabled={disabled}
          />
          <BasicForm.TextInput
            name="amount"
            type="number"
            label="Fee Amount"
            labelStyle={labelStyle}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel type='cancel' text='Cancel' handleClose={handleClose} />
            <Buttons.Standard
              type='submit'
              text='Submit'
              onClick={() => handleSubmit(values)}
              disabled={!isValid || formSubmitting || !dirty}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
