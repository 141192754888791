import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';
import { Colors } from '../../../styles';
import { useFormikContext } from 'formik';

const ACCOUNTS = gql`
query {
  accounts(scopes: ["income"])
  { id, gl, name }
}`

export const SubmitForm = (props) => {
  const { isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  const { loading, error, data } = useQuery(ACCOUNTS);
  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { values, errors, touched, handleBlur, handleChange, actionType } = props;
  return <div>
    <Form.Group>
      <Form.Label>Assign to GL Code</Form.Label>
      <Form.Control
        as="select"
        name="accountId"
        value={values.accountId}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={touched.accountId && !errors.accountId}
        isInvalid={!!errors.accountId && touched.accountId}
      >
        <option value=''></option>
        {data.accounts.map((account) => {
          return <option key={account.id} value={account.id}>
            GL {account.gl} - {account.name}
          </option>
        })}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {errors.accountId}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group style={{ display: 'flex' }}>
      <Form.Label>Is amount fully earned on Day 1?</Form.Label>
      <Form.Check
        type="checkbox"
        name="fullyEarned"
        value={values.fullyEarned}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={!errors.fullyEarned}
        isInvalid={!!errors.fullyEarned}
        style={{ marginLeft: '10px' }}
        checked={values.fullyEarned}
      />
    </Form.Group>
    <Form.Group style={{ display: 'flex' }}>
      <Form.Label>Earns Interest?</Form.Label>
      <Form.Check
        type="checkbox"
        name="earnsInterest"
        value={values.earnsInterest}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={!errors.earnsInterest}
        isInvalid={!!errors.earnsInterest}
        style={{ marginLeft: '10px' }}
        checked={values.earnsInterest}
      />
    </Form.Group>
    <Form.Group style={{ display: 'flex' }}>
      <Form.Label>Active?</Form.Label>
      <Form.Check
        type="checkbox"
        name="active"
        value={values.active}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={!errors.active}
        isInvalid={!!errors.active}
        style={{ marginLeft: '10px' }}
        checked={values.active}
      />
    </Form.Group>
    <Form.Group style={{ display: 'flex' }}>
      <Form.Label>Can Be Waived?</Form.Label>
      <Form.Check
        type="checkbox"
        name="canBeWaived"
        value={values.canBeWaived}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={!errors.canBeWaived}
        isInvalid={!!errors.canBeWaived}
        style={{ marginLeft: '10px' }}
        checked={values.canBeWaived}
      />
    </Form.Group>
    <Button
      type="submit"
      disabled={!isValid || formSubmitting}
      style={buttonColor}
    >
      {`${actionType} Automated Fee`}
    </Button>
  </div>
}



const buttonColor = {
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen,
  color: 'white'
}
