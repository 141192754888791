import React, { useState, useEffect, useContext } from 'react';
import AuthLink from 'components/src/components/AuthLink';
import DataTables from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import {
  ADMIN_DOCUMENTS,
  HIDE_ADMIN_DOCUMENT,
  UNHIDE_ADMIN_DOCUMENT
} from './queries';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert from 'components/src/elements/BasicAlert';
import { preflightTableStyles } from 'components/src/styles';
import { setDate } from 'components/src/utils/dateHelpers';

const AdminDocuments = props => {
  const [showHidden, setShowHidden] = useState(false);
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data, refetch } = useQuery(
    ADMIN_DOCUMENTS,
    { variables: { scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_ADMIN_DOCUMENT, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_ADMIN_DOCUMENT, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'name',
      label: 'Document Name',
    },
    {
      key: 'updatedAt',
      label: 'Most Recent Update',
      content: ({ value }) => setDate(value),
    },
    {
      key: 'updatedBy',
      label: 'Most Recent Update By',
      value: ({ value }) => value.fullName,
    },
    {
      key: 'fileUpload',
      label: 'File Name',
      value: ({ value }) => value?.name,
    },
    {
      key: 'lastAction',
      label: 'Action Taken',
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix="/system/admin-documents"
          resource={entry}
          hide={hide}
          unhide={unhide}
          noRead
          noEdit
        >
          <a href={entry.fileUpload?.fileUrl} target="_blank">
            View Document
          </a>
          {' |'}
          <AuthLink
            style={{paddingLeft: 5}}
            to={`/system/admin-documents/${entry.id}/edit`}
            resource="AdminDocument"
            action="update"
          >
            Replace
          </AuthLink>
          {' |'}
        </ResourceActionLinks>
      )
    }
  ];

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="AdminDocument" type={alertType} />
      )}
      <ResourcesHeader
        title="Reports - Admin Section Entries - Make Modifications"
        prefix="/system/admin-documents"
        resourceType="AdminDocument"
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />

      <DataTables.Simple
        data={data.adminDocuments}
        columns={columns}
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default AdminDocuments;
