import React, { useRef, useState } from 'react';
import { modalStyle } from '../../../elements/Pagination/FilterStyles';
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as BasicForm from '../../../elements/forms';
import { Row, Col } from "react-bootstrap";

const FilterModal = (
  { showFilter,
    setShowFilter,
    filteredData,
    setFilteredData,
    rawData,
    setOrderedData,
    filterOptions
  }) => {
  const { payeeFilter } = filterOptions;
  const fromDate = useRef('');
  const toDate = useRef('');
  const [stateTo, setToDate] = useState(toDate.current);
  const [stateFrom, setFromDate] = useState(fromDate.current);

  const checkDateValues = () => {
    const fromValue = fromDate.current.value;
    const toValue = toDate.current.value;

    if (fromValue && toValue) {
       setFilteredData(Object.assign({}, filteredData,
         {['dueDate']: [fromValue, toValue] })
      )}
    }

 return <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={() => setShowFilter(false)}
    show={showFilter}
  >
    <Modal.Header style={modalStyle.header} closeButton>
      <Modal.Title style={modalStyle.title}>
        Filter Options
        <Button
          variant="danger"
          onClick={() => {
            setFilteredData({
              payee: '',
              dueDate: ['', '']
            });
            setOrderedData(rawData);
            setShowFilter(false);
          }
        }
        >
          Clear Filters
        </Button>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body style={modalStyle.body}>
     <Row key={'payee'}>
       <Form.Label>Payee</Form.Label>
       <Form.Control
         as="select"
         value={filteredData.payee}
         onChange={(event) => {
            const value = event.target.value;
            setFilteredData(Object.assign({}, filteredData, {['payee']: value }))
          }
         }
       >
         <option key={0} value="">All</option>
         {payeeFilter.map((option, optionIndex) => {
             return <option key={option}>{option}</option>
            })
         })}
       </Form.Control>
     </Row>
     <br />
     <br />
     <Row key={'date'}>
       <Form.Label style={{ paddingRight: "20px" }}>{'Due Date'}</Form.Label>
       <Col style={{ borderBottom: "none" }}>
         <p>From</p>
         <Form.Control
           type="date"
           value={stateFrom || filteredData.dueDate[0]}
           onChange={event => setFromDate(event.target.value)}
           ref={fromDate}
           onBlur={checkDateValues}
         />
       </Col>
       <Col style={{ borderBottom: "none" }}>
         <p>To</p>
         <Form.Control
           type="date"
           value={stateTo || filteredData.dueDate[1]}
           ref={toDate}
           onChange={event => setToDate(event.target.value)}
           onBlur={checkDateValues}
         />
       </Col>
     </Row>
    </Modal.Body>
  </Modal>
}

export default FilterModal;
