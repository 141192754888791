import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import DataTable from '../../elements/DataTable';

const AuctionTokensTable = (props) => {
  const id = useParams().id;
  const { handleShow, auctionTokens, setShowHidden, showHidden } = props;

  const auth = useContext(AuthContext);

  const columns = [
    {
      title: 'Token'
    },
    {
      title: 'Actions'
    }
  ]

  const dataSet = auctionTokens.map(function (auctionToken) {
    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${auctionToken.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${auctionToken.id}>Hide</a>`

    if (auth.hasPolicy('AuctionToken', 'update')) {
      auctionToken.actions = `
        ${hiddenLink}
      `
    }

    return [
      auctionToken.token,
      auctionToken.actions || ''
    ]
  })

  const uncreateable = auth.hasPolicy('AuctionToken', 'create') ? false : true

  return (
    <>
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Auction API Tokens'}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
        uncreateable={uncreateable}
        handleShowModal={handleShow}
      />
    </>
  );
};

export default AuctionTokensTable;
