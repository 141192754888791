import React from 'react';
import AuthLink from 'components/src/components/AuthLink';
import { ColTitle, ReportLink } from '../index';

const Collections = () => {
  return (
    <>
      <ColTitle>Collections</ColTitle>

      <AuthLink
        to={'/reports/report/repoed-cars'}
        resource="FlooredCar"
        action="read"
        component={ReportLink}
      >
        Repoed Cars Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/nsf-payments'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        NSF Payments Report
      </AuthLink>
    </>
  );
};

export default Collections;
