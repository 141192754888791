import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

export const CUSTOM_NOTIFICATION_SELECTS = gql`
  query NoUserLogCustomNotificationSelects {
    markets { id, name }
  }
`;

const CUSTOM_NOTIFICATION_FIELDS = `
  id
  body
  link
  dealers
  employees
  markets { id, name }
  createdBy { fullName }
  createdAt
`;

const CUSTOM_NOTIFICATION_FIELDS_AND_ERRORS = `
  customNotification {
    ${CUSTOM_NOTIFICATION_FIELDS}
  }
  ${ERRORS}
`;

export const CUSTOM_NOTIFICATIONS = gql`
  query CustomNotifications {
    customNotifications {
      ${CUSTOM_NOTIFICATION_FIELDS}
    }
  }
`;

const CUSTOM_NOTIFICATION_VARIABLES = `
  $body: String!
  $link: String
  $dealers: Boolean
  $employees: Boolean
  $marketIds: [ID!]!
`;

const CUSTOM_NOTIFICATION_ARGUMENTS = `
  body: $body
  link: $link
  dealers: $dealers
  employees: $employees
  marketIds: $marketIds
`;

export const CREATE_CUSTOM_NOTIFICATION = gql`
  mutation CreateCustomNotification(
    ${CUSTOM_NOTIFICATION_VARIABLES}
  ) {
    createCustomNotification(
      ${CUSTOM_NOTIFICATION_ARGUMENTS}
    ) {
      ${CUSTOM_NOTIFICATION_FIELDS_AND_ERRORS}
    }
  }
`;
