import React from 'react';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import * as Colors from '../styles/colors';

const Loading = () => {
  return (
    <div style={{ marginTop: '100px' }}>
      <Dots size={50} color={Colors.xlGreen} />
    </div>
  )
}

export const RowLoading = ({style}) => {
  return (
    <div style={{ ...style, marginTop: '10px' }}>
      <Dots size={50} color={Colors.xlGreen} />
    </div>
  )
}

export const MediumRowLoading = ({style}) => {
  return (
    <div style={{ ...style, marginTop: '10px' }}>
      <Dots size={35} color={Colors.xlGreen} />
    </div>
  )
}

export const SmallRowLoading = ({style}) => {
  return (
    <div style={{ ...style, marginTop: '10px' }}>
      <Dots size={25} color={Colors.xlGreen} />
    </div>
  )
}

export default Loading;
