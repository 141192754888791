import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
  query {
    currentUser {
      id
      userType
      firstName
      lastName
      fullName
      email
      notificationsSms
      notificationsEmail
      currentMarketScope {
        id
        name
      }
      mobilePhone {
        formatted
      }
      userProvidedPhoneNumber {
        formatted
      }
      policies {
        actions {
          name
          params {
            name
          }
        }
        resource
      }
      marketIds
    }
    settingsForUi {
      currentFiscalYear
      currentFiscalQuarter
    }
  }
`;

export const MARKETS_BY_CURRENT_USER = gql`
  query MarketsByCurrentUser {
    currentUser {
      id
      currentMarketScope {
        id
        name
      }
      markets {
        id
        name
        dealers {
          id
        }
      }
      marketIds
    }
  }
`;
