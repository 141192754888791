import React from 'react';
import AuthLink from 'components/src/components/AuthLink';

const ResourceActionLinks = props => {
  const {
    prefix, resource, hide, unhide, children, noRead, noEdit, includeDeltas
  } = props;
  const resourceType = resource.__typename;

  return (
    <div style={{display: 'block'}}>
      {!noRead && (
        <AuthLink
          to={`${prefix}/${resource.id}`}
          resource={resourceType}
          action="read"
        >
          View
        </AuthLink>
      )}
      {!noEdit && (
        <AuthLink
          style={{paddingLeft: 5}}
          to={`${prefix}/${resource.id}/edit`}
          resource={resourceType}
          action="update"
        >
          {noRead ? 'Edit' : '| Edit'}
        </AuthLink>
      )}
      {includeDeltas && (
        <AuthLink
          style={{paddingLeft: 5}}
          to={`${prefix}/${resource.id}/deltas`}
          resource="Delta"
          action="read"
        >
          {noRead && noEdit ? 'Deltas' : '| Deltas'}
        </AuthLink>
      )}
      { children }
      {
        unhide && resource.hiddenAt &&
          <AuthLink
            style={{paddingLeft: 5}}
            to="#"
            resource={resourceType}
            action="hide"
            onClick={() => unhide({ variables: { id: resource.id } })}
          >
            {noRead && noEdit && !includeDeltas ? 'Unhide' : '| Unhide'}
          </AuthLink>
      }
      {
        hide && !resource.hiddenAt && resource.canBeHidden &&
          <AuthLink
            style={{paddingLeft: 5}}
            to="#"
            resource={resourceType}
            action="hide"
            onClick={() => hide({ variables: { id: resource.id } })}
          >
            {noRead && noEdit && !includeDeltas ? 'Hide' : '| Hide'}
          </AuthLink>
      }
    </div>
  );
};

export default ResourceActionLinks;
