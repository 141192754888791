import React, { useState } from "react";
import { Text } from "react-native";
import { useParams, Redirect } from "react-router-dom";
import DataState from "../../DataState";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import { Helmet } from 'react-helmet';
import { titleCase } from "../../../utils/stringHelpers";
import "../../../styles/feePrograms.css";
import { Typography, Spacing } from "../../../styles";
import { IntervalFeeProgramTable } from "./IntervalFeeProgramTable";
import { SingleFeeProgramTable } from "./SingleFeeProgramTable";
import {
  nullable,
  setExtensionAccountId,
  setTermAttributeAccountId,
  setNullAttributes,
} from "./functionality";
import Loading from '../../../elements/Loading';
import * as Formatting from '../../../styles/formatting';


const PROGRAM = gql`
  query PROGRAM($id: ID!) {
    feeProgram(id: $id) {
      id
      name
      type
      activeDealers { name, id }
      activeFlooredCars { name, id }
      feeLinesAttributes {
        id
        principalBelow
        extensionFeeFlat
        extensionInterval
        extensionAccount {
          id
        }
        extensionFeeInterval
        feeLineTermsAttributes {
          amount
          interval
          id
          account {
            id
          }
        }
      }
    }
  }
`;

const UPDATE_PROGRAM = gql`
  mutation UpdateFeeProgram(
    $id: ID!
    $name: String!
    $type: String!
    $feeLinesAttributes: [FeeLineInput!]!
  ) {
    updateFeeProgram(
      id: $id
      name: $name
      type: $type
      feeLinesAttributes: $feeLinesAttributes
    ) {
      feeProgram {
        id
        feeLines {
          id
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

const FeeProgram = () => {
  const { id } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  let { loading, error, data } = useQuery(PROGRAM, { variables: { id } });
  const [updateFeeProgram] = useMutation(UPDATE_PROGRAM, {
    onCompleted(data) {
      const key = Object.keys(data)[0];
      const errors = data[key].errors;
      if (errors?.length) {
        setFormSubmitting(false);
        let errorString = '';
        let errorName = '';
        let errorMessage = '';
        errors.forEach((error, index) => {
          errorName = error.path[1];
          errorMessage = error.message;
          const separator = index > 0 ? ', ' : ''
          errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`
        })
        setServerSideErrors(errorString)
      } else {
        setFormSubmitting(false);
        setRedirect(true);
      }
    },
  });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const formatData = () => {
    if (data.feeProgram.feeLinesAttributes[0].extensionAccount) {
      data.feeProgram.feeLinesAttributes = data.feeProgram.feeLinesAttributes.map(
        (attribute) => {
          attribute.extensionAccount = Number.parseInt(
            attribute.extensionAccount.id,
            10
          );
          attribute.feeLineTermsAttributes.map((term) => {
            if (term.account && term.account.id)
              term.account = Number.parseInt(term.account.id, 10);
            if (term.account && term.account.interval === null)
              term.account.interval = 0;
            term.id = Number.parseInt(term.id, 10);

            return term;
          });

          if (attribute.principalBelow === null) attribute.principalBelow = "";
          if (attribute.extensionFeeFlat === null)
            attribute.extensionFeeFlat = 0;
          if (attribute.extensionFeeInterval === null)
            attribute.extensionFeeInterval = 0;
          if (attribute.extensionInterval === null)
            attribute.extensionInterval = 0;

          return attribute;
        }
      );
    }
  };

  const schema = yup.object({
    name: yup
      .string()
      .max(80)
      .required("Required"),
    type: yup.string().required("Required"),
    feeLinesAttributes: yup.array().of(
      yup.object({
        principalBelow: yup
          .number()
          .min(0, "Must be positive")
          .typeError("Must be a number or blank")
          .nullable(),
        extensionFeeFlat: yup
          .number()
          .min(0, "Must be positive")
          .typeError("Must be a number")
          .nullable(),
        extensionInterval: yup
          .number()
          .min(0, "Must be positive")
          .integer("Must be an integer")
          .nullable(),
        extensionAccount: yup
          .mixed()
          .notOneOf([""])
          .required("Required")
          .typeError("Required"),
        extensionFeeInterval: yup
          .number()
          .min(0, "Must be positive")
          .nullable(),
        feeLineTermsAttributes: yup.array().of(
          yup.object({
            amount: yup
              .number()
              .min(1, "Must greater than 0")
              .required("Required"),
            interval: yup
              .number()
              .min(0, "Must be positive")
              .integer("Must be an integer")
              .nullable(),
            account: yup
              .mixed()
              .notOneOf([""])
              .typeError("Required"),
          })
        ),
      })
    ),
  });

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  function FeeProgramForm() {
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/system/fee-programs",
            state: { alert: true },
          }}
        />
      );
    }

    formatData();

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={data.feeProgram}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isValid,
          setValues,
        }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              style={{ paddingRight: 40, paddingLeft: 40 }}
            >
              <div style={Spacing.formWrapper}>
                <div style={Spacing.formHeader}>
                  <h1 style={Typography.formHeader}>{`Edit: ${titleCase(
                    data.feeProgram.name
                  )}`}</h1>
                </div>
              </div>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as="select"
                  type="text"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  isValid={!errors.type && touched.type}
                  isInvalid={!!errors.type && touched.type}
                  placeholder="select"
                  disabled
                >
                  <option value="">Please Select a Fee Type</option>
                  <option value="Single">Fees Added on First Day of Term</option>
                  <option value="Interval">Interval Fee</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.type}
                </Form.Control.Feedback>
                {!values.type && (
                  <Text>Please select a fee type to display options</Text>
                )}
                {!errors.type && values.type === "Interval" && (
                  <IntervalFeeProgramTable
                    feeLinesAttributes={values.feeLinesAttributes}
                    values={values}
                    setValues={setValues}
                    removedTerms={removedTerms}
                    removedPrincipalAmounts={removedPrincipalAmounts}
                  />
                )}
                {!errors.type && values.type === "Single" && (
                  <SingleFeeProgramTable
                    feeLinesAttributes={values.feeLinesAttributes}
                    values={values}
                    setValues={setValues}
                    removedTerms={removedTerms}
                    removedPrincipalAmounts={removedPrincipalAmounts}
                  />
                )}
              </Form.Group>
            </Form>
          )}
      </Formik>
    );
  }

  const removeTermsTypeName = (values) => {
    values.feeLinesAttributes.map((attribute) => {
      attribute.feeLineTermsAttributes.map((term) => {
        delete term.__typename;
      });
    });

    return values;
  };

  const removeAttributesTypeName = (values) => {
    values.feeLinesAttributes.map((attribute) => {
      delete attribute.__typename;
    });

    return values;
  };

  const formatAttributes = (values) => {
    return values.feeLinesAttributes.map((attribute) => {
      const termAttributes = [];
      attribute.feeLineTermsAttributes.forEach((term) => {
        let id = null;
        if (term.id) {
          id = term.id;
        }

        termAttributes.push({
          amount: term.amount,
          interval: term.interval,
          accountId: term.account,
          id: parseInt(id),
        });
      });

      attribute = {
        id: parseInt(attribute.id),
        principalBelow: attribute.principalBelow,
        extensionFeeFlat: attribute.extensionFeeFlat,
        extensionInterval: attribute.extensionInterval,
        extensionAccountId: attribute.extensionAccount,
        extensionFeeInterval: attribute.extensionFeeInterval,
        feeLineTermsAttributes: termAttributes,
      };

      return attribute;
    });
  };

  const removedTerms = [];
  const removedPrincipalAmounts = [];

  const handleSubmit = (values) => {
    values = setExtensionAccountId(values);
    values = setTermAttributeAccountId(values);
    values = removeTermsTypeName(values);
    values = removeAttributesTypeName(values);
    values.feeLinesAttributes = formatAttributes(values);
    values.feeLinesAttributes = setNullAttributes(
      values.feeLinesAttributes,
      nullable
    );

    delete values.__typename;

    if (removedPrincipalAmounts.length > 0) {
      removedPrincipalAmounts.forEach((item) => {
        const deletedTermsAttributes = item.feeLineTermsAttributes.map(
          (term) => {
            return { id: term.id, _destroy: true };
          }
        );
        values.feeLinesAttributes.push({
          id: item.id,
          _destroy: true,
          feeLineTermsAttributes: deletedTermsAttributes,
        });
      });
    }

    if (removedTerms.length > 0) {
      removedTerms.forEach((terms) => {
        terms.forEach((term, index2) => {
          values.feeLinesAttributes[index2].feeLineTermsAttributes.push({
            id: term.id,
            _destroy: true,
          });
        });
      });
    }

    setFormSubmitting(true)
    updateFeeProgram({
      variables: values,
    });
  };

  const LoadedContent = () => {
    return (
      <>
        <div>
          <ServerSideErrors />
          <FeeProgramForm />
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    // look at this
    <>
      <Helmet>
        <title>Edit {titleCase(data.feeProgram.name)} Fee Program</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default FeeProgram;
