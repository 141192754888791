import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { ChangeForm, ChangesIndex } from './components';

export const newProgramSchema = yup.object({
  name: yup
    .string()
    .required('Required'),
  expirable: yup
    .string()
    .required('Required'),
  insuranceProgramChangesAttributes: yup.object().shape({
    rate: yup
      .number()
      .max(100, "Can't exceed 100%")
      .min(0, 'Must be positive')
      .required('Required'),
    effectiveOn: yup
      .date()
      .min(new Date(), 'The date must be in the future')
      .required('Required'),
  })
});

export const editProgramSchema = yup.object({
  name: yup
    .string()
    .required('Required'),
  expirable: yup
    .string()
    .required('Required'),
})

export const Form = ({ type }) => {
  const { values, errors, isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.TextArea
        name="description"
        type="text"
        label="Description"
      />
      <BasicForm.Select
        name="expirable"
        label="Expirable?"
      >
        <option value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </BasicForm.Select>
      <BasicForm.TextInput
        name="gracePeriod"
        type="number"
        label="Grace Period"
      />
      {(type !== 'edit') && <ChangeForm />}
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>

      {type === 'edit' && <ChangesIndex />}
    </FormikForm>
  );
}
