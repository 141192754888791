import { Colors, Spacing } from '../../styles';

export const noVinAlertText = {
  width: '100%',
  color: 'red',
  paddingTop: '10px',
}

export const rowStyle = {
  paddingLeft: '15px',
  paddingRight: '15px',
  paddingBottom: '0px',
  paddingTop: '10px',
}

export const backgroundStyle = {
  backgroundColor: '#f1f1f1',
  paddingBottom: '40px',
  marginTop: '10px',
}

export const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })
}

export const imageContainer = {
  justifyContent: 'center',
  alignItems: 'center',
}

export const buttonRow = {
  ...Spacing.buttonRow,
  margin: '0%',
  width: '30%',
  marginLeft: '38.5%'
}

export const carRowStyle = {
  padding: '2px 4px',
  borderBottom: '1px solid #AAAAAA',
  display: 'flex',
  alignItems: 'center',
}

export const greenRowStyle = {
  ...carRowStyle,
  backgroundColor: '#C5DACF',
}

export const dataLabelStyle = {
  width: '75%',
  padding: '4px 2px',
  margin: '0 auto',
  lineHeight: 'normal',
}

export const dataValueStyle = {
  ...dataLabelStyle,
  textAlign: 'right',
  fontWeight: 'bold',
}

export const carImageStyle = {
  height: '100%',
  width: '100%'
}

export const noCarImageStyle = {
  ...carImageStyle,
  opacity: '0.6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}

export const linkStyle = {
  width: '100%',
}

export const hrStyle = {
  width: '100%',
}

export const toggleScopeButtonStyle = {
  marginLeft: '1.2%',
  paddingTop: '1%',
}

export const subNavStyle = {
  backgroundColor: Colors.xlYellow,
}

export const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 30px',
}

export const addRecord = {
  color: Colors.xlGreen,
  fontSize: 24,
  float: 'right',
  marginRight: 30,
}
