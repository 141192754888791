import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';

export const statusSchema = yup.object({
  name: yup.string().required('Required'),
});

export const Form = () => {
  const { isValid } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.TextInput
        name="info"
        type="text"
        label="Information"
      />
      <BasicForm.Checkbox
        name="isPresent"
        type="checkbox"
        label="Present?"
      />
      <BasicForm.Checkbox
        name="toDealer"
        type="checkbox"
        label="To Dealer?"
      />
      <BasicForm.Checkbox
        name="autoFeeTitleAdded"
        type="checkbox"
        label="Auto Fee for Title Added?"
      />
      <BasicForm.Checkbox
        name="autoFeeTitleBorrowed"
        type="checkbox"
        label="Auto Fee for Title Borrowed?"
      />
      <BasicForm.Checkbox
        name="autoFeeTitleShipped"
        type="checkbox"
        label="Auto Fee for Title Shipped?"
      />
      <BasicForm.Checkbox
        name="autoFeeTitleMarkedPresent"
        type="checkbox"
        label="Auto Fee for Title Marked Present?"
      />
      <BasicForm.Checkbox
        name="fund"
        type="checkbox"
        label="Fund?"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
