import * as DateHelper from '../../utils/dateHelpers';

export const getSearchValues = (car) => {
  let formattedLoanDate = car.loanDate?.split('-');
  formattedLoanDate.push(DateHelper.setDate(car.loanDate));

  let formattedDueDate = car.currentDueOn?.split('-');
  formattedDueDate.push(DateHelper.setDate(car.currentDueOn));

  return [
    car.currentCollateralCoverage,
    car.currentFees,
    car.currentPrincipal,
    car.make,
    car.model,
    formattedLoanDate,
    formattedDueDate,
    car.unitNumber,
    car.vin,
    car.year,
    car.termData?.totalDays,
  ].flat();
}


export const searchLookup = (searchValues, values) => {
  searchValues = searchValues.map((val) => val = val?.toString().toLowerCase());
  if (values.search && values.search !== '') {
    const searchTerm = values.search.toLowerCase();
    let matchCount = 0

    searchValues.forEach((value) => {
      if (value.includes(searchTerm)) matchCount += 1
    })

    if (matchCount === 0) return false;
  }
}
