import React, { useState, useEffect } from 'react';


export const FeeLineTermIntervalColumns = (props) => {
  const { feeLinesAttributes } = props;

  const rowValue = feeLinesAttributes[0].feeLineTermsAttributes.map((term, index) => {
    return [
      <th key={index}>{`Term ${index + 1} Fee`}</th>,
      <th key={index+1}>Interval</th>
     ]
  })

  return rowValue;
}

export const FeeLineTermSingleColumns = (props) => {
  const { feeLinesAttributes } = props;

  const rowValue = feeLinesAttributes[0].feeLineTermsAttributes.map((term, index) => {
    return <th key={index}>{ `Term ${index + 1} Fee`}</th> }
   )

  return rowValue;
}

export const addTerm = (values, setValues) => {
  if (values.feeLinesAttributes[0].feeLineTermsAttributes[0].account) {
      values.feeLinesAttributes.forEach((attribute) => {

      attribute.feeLineTermsAttributes = [...attribute.feeLineTermsAttributes, {amount: 0, interval: 0, account: ''}], setValues(values)
    })
  } else {
  values.feeLinesAttributes.forEach((attribute) => {
    attribute.feeLineTermsAttributes = [...attribute.feeLineTermsAttributes, {amount: 0, interval: 0, accountId: ''}], setValues(values)
  })
}

}

export const removeTerm = (values, setValues, removedTerms) => {
 if (removedTerms) {
    const currentRemovedTerms = values.feeLinesAttributes.map((attribute) => {
    const removedItem = attribute.feeLineTermsAttributes.pop()
      setValues(values)
      if (removedItem.id) return removedItem
    })

    removedTerms.push(currentRemovedTerms)
  } else {
    values.feeLinesAttributes.map((attribute) => {
      attribute.feeLineTermsAttributes.pop()
      setValues(values)
    })
  }
}

export const addPrincipalAmount = (values, setValues) => {
  const currentTermCount = values.feeLinesAttributes[0].feeLineTermsAttributes.length;
  let termAttributes = [];
  let term;

  if (values.feeLinesAttributes[0].feeLineTermsAttributes[0].account) {
    const id = values.feeLinesAttributes[0].feeLineTermsAttributes[0].id

    term = {
      amount: 0,
      interval: 0,
      account: 'tempAccountId'
    }

  } else {
      term = {
        amount: 0,
        interval: 0,
        accountId: 'tempAccountId'
      }
    }

  let i = 0;
  while (i < currentTermCount) {
    termAttributes.push(term)

    i += 1
  }


  if (values.feeLinesAttributes[0].feeLineTermsAttributes[0].account) {
    values.feeLinesAttributes = [...values.feeLinesAttributes,
      {
        principalBelow: '',
        extensionFeeFlat: 0,
        extensionInterval: 0,
        extensionAccount: 0,
        extensionFeeInterval: 0,
        feeLineTermsAttributes: termAttributes

          }], setValues(values)
  } else {
    values.feeLinesAttributes = [...values.feeLinesAttributes, {
                principalBelow: '',
                extensionFeeFlat: 0,
                extensionInterval: 0,
                extensionAccountId: 0,
                extensionFeeInterval: 0,
                feeLineTermsAttributes: termAttributes

          }], setValues(values)
  }

}

export const removePrincipalAmount = (values, setValues, removedPrincipalAmounts) => {
  if (removedPrincipalAmounts) {
    const removedItem = values.feeLinesAttributes.pop();
    removedPrincipalAmounts.push(removedItem);
    setValues(values);
  } else {
    values.feeLinesAttributes.pop()
    setValues(values);
  }
}

export const nullable = [
      'extensionFeeInterval',
      'principalBelow',
      'extensionInterval',
      'extensionFeeFlat',
      'interval' ];


export const toInteger = (number) => {
  return Number.parseInt(number, 10)
}

export const setExtensionAccountId = (values) => {
  let extensionId = values.feeLinesAttributes[0].extensionAccountId
  extensionId = toInteger(extensionId)

  if (values.feeLinesAttributes.length > 1) {
    values.feeLinesAttributes.map((attribute) => {
        attribute.extensionAccountId = extensionId
    })
  } else {
    values.feeLinesAttributes[0].extensionAccountId = extensionId
  }

  return values
}

export const setTermAttributeAccountId = (values) => {
  const accountIds = [];

  values.feeLinesAttributes[0].feeLineTermsAttributes.forEach((term) => {
    accountIds.push(toInteger(term.accountId));
  })

  values.feeLinesAttributes.map((attribute, index) => {
      attribute.feeLineTermsAttributes.map((term, index) => {
        term.accountId = accountIds[index];
      })
    })

  return values
}

export const setNullAttributes = (feeLinesAttributes, nullableTypes) => {
  nullableTypes.forEach((type) => {
      feeLinesAttributes.map((attribute) => {
      if (type !== 'interval') {
        attribute[type] = (attribute[type] === '')
          ? null
          : parseInt(attribute[type]);
      }

      if (type === 'interval') {
        attribute.feeLineTermsAttributes.map((term) => {
          term[type] = (term[type] === '' || term[type] === null)
            ? null
            : parseInt(term[type]);
        })
      }
    })
  })


  return feeLinesAttributes;
}
