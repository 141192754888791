import { gql } from '@apollo/client';

export const AUDITS_WITH_COUNT = gql`
  query(
    $limit: Int
    $offset: Int
    $sort: String
    $sortDirection: String
    $search: String
    $filterColumns: [String!]
    $filterNames: [JSON!]
    $scopes: [String!]
  ) {
    auditsWithCount(
      limit: $limit
      offset: $offset
      sort: $sort
      sortDirection: $sortDirection
      search: $search
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: $scopes
    ) {
      count
      markets
      dealers
      auditStatuses
      audits {
        auditClosedDate
        id
        unitsUnseen
        numberOfUnits
        missingUnitValue
        foundUnitValue
        totalCurrentBalance
        dealer {
          id
          auditStatus
          name
          legalName
          accountNumber
          creditLine
          currentPrincipal
          dealerCategories {
            name
          }
          dealerStatus {
            name
          }
          market {
            name
          }
        }
        inspectionOn
        foundPercentage
        averageFoundPercentage(last: 6)
        timeToClose
        unverifiedAuditCarsCount
      }
    }
  }
`;
