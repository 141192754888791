export const addFilters = (filters, variables) => {
  let columns = Object.keys(filters);

  const filterNames = columns.map(column => {
    let name = filters[column];
    if (!name) name = '';

    if (Array.isArray(name)) {
      // Check if sorting by date range
      if (name.length === 2 &&
        Date.parse(name[0]) ||
        Date.parse(name[1])) {
      } else name = name.map(obj => obj.value)
    }

    // This is an edge case for date range filters that are empty / not
    // completely filled out yet.
    if (Array.isArray(name) && name.includes(undefined)) name = '';

    return name
  })

  const isReset = filterNames.every(name=> name === '')
  if (isReset) return;

  variables.filterColumns = columns;
  variables.filterNames = filterNames

  return variables
}
