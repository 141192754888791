import React from 'react';
import Button from 'react-bootstrap/Button';
import { Colors } from '../styles'

export const AddRemoveTermButtons = (props) => {
  const { arrayHelpers, values, termLinesAttributes } = props;
  const termLines = values.termLines || values.termLinesAttributes
  if (termLines.length > 1) {
     return (
      [
        <Button
        type="button"
        style={buttonColor}
        onClick={() => arrayHelpers.insert((termLines.length + 1), {days: 0, curtailmentPercent: 0})}
      >
        Add Term
       </Button>,
       <Button
          type="button"
          style={buttonColor}
          onClick={() => arrayHelpers.pop()}
        >
          Remove Term
        </Button>
        ]
      )
    } else {
    return (
      <Button
        type="button"
        style={buttonColor}
        onClick={() => arrayHelpers.insert((termLines.length + 1), {days: 0, curtailmentPercent: 0})}
      >
        Add Term
    </Button>
   )
  }
}

const buttonColor = {
   backgroundColor: Colors.xlGreen,
   borderColor: Colors.xlGreen
}

