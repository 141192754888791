import React, { useContext, useEffect, useState, Fragment } from 'react';
import * as BasicForm from '../../../elements/forms';
import { useFormikContext } from "formik";

const FormBody = ({ data }) => {
  const { values, setFieldValue } = useFormikContext();
  return data.map((line, index) => {
    if (!values.txnLineAmounts[index]) {
        values.txnLineAmounts[index] = {
          amount: 0, txnLineId: parseInt(line.id), active: false
        };
      }

    useEffect(() => {
      if (values.txnLineAmounts[index].txnLineId === '')
        setFieldValue(`txnLineAmounts[${index}].amount`, 0);

    }, [values.txnLineAmounts[index].txnLineId])

    useEffect(() => {
      if (values.txnLineAmounts[index].active) {
        setFieldValue(`txnLineAmounts[${index}].amount`, parseFloat(line.amountRemaining))
      }
    }, [values.txnLineAmounts[index].active])

    return (
      <Fragment key={line.id}>
        <tr>
          <td>
            <BasicForm.JournalCheckbox
              name={`txnLineAmounts[${index}].active`}
              type="checkbox"
              id={`txnLineAmounts${index}active`}
              onClick={(event) => {
                const lineIsDisabled = values.txnLineAmounts[index].active === true;
                if (!lineIsDisabled)
                  // setFieldValue(`txnLineAmounts[${index}][${line.id}]`, parseFloat(line.amountRemaining))
                setFieldValue(`txnLineAmounts[${index}].active`, !lineIsDisabled)
              }}
            />
          </td>
          <td>{line.txn.name}</td>
          <td>{line.txn.reference}</td>
          <td>{line.txn.bankDate}</td>
          <td>${line.amountRemaining}</td>
          <td style={{display: 'inline-flex'}}>
            <BasicForm.TextInput
              value={values.txnLineAmounts[index].amount}
              name={`txnLineAmounts[${index}].amount`}
              type="number"
              disabled={!values.txnLineAmounts[index].active}
              onFocus={(e) => e.target.select()}
            />
          </td>
        </tr>
      </Fragment>
    )
  })
}

export default FormBody;
