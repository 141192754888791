import { gql } from '@apollo/client';

const SELECT_OPTIONS = `
  titleStatuses {
    id
    name
  }
  markets {
    id
    name
  }
  dealerStatuses {
    id
    name
  }
`;

export const FLOORED_CARS = gql`
  query(
    $filterColumns: [String!]
    $filterNames: [JSON!]
    $scopes: [String!]
  ) {
    flooredCars(
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: $scopes
    ) {
      id
      vin
      titleStatus
      dealer { id, name, dealerStatus { name, canFloor }, market { name } }
      market { id, name }
      year
      make
      model
      unitNumber
    }
    ${SELECT_OPTIONS}
  }
`;

export const CREATE_TITLE_LOG_BATCH = gql`
  mutation CreateTitleLogBatch(
    $flooredCarIds: [String!]!
    $titleStatusId: String!
  ) {
    createTitleLogBatch(
      flooredCarIds: $flooredCarIds
      titleStatusId: $titleStatusId
    ) {
      errors { path, message }
    }
  }
`;

