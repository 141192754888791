import React, { useEffect, useState } from 'react';
import { Form as FormikForm, useFormikContext } from 'formik';
import { useQuery, useLazyQuery } from '@apollo/client';
import * as BasicForm from '../../../../elements/forms';
import DataState from '../../../DataState';
import { DEALERS_BY_MARKET } from '../../../../queries/indexQueries';
import { useLocation } from 'react-router-dom';
import useEnum from 'components/src/hooks/useEnum';
import Select from './Select';
import {
  TXN_LINE_SELECTS,
  FLOORED_CARS_BY_DEALER,
  FEES_BY_FLOORED_CAR
} from '../Queries'

export const TxnLineFlooringInputs = (props) => {
  const {children, indexValue} = props;
  const { data, loading, error } = useQuery(TXN_LINE_SELECTS);
  const [getDealersByMarket, {
    loading: dealersLoading,
    error: dealersError,
    data: dealersData
  }] = useLazyQuery(DEALERS_BY_MARKET)

  const [getFlooredCarsByDealer, {
    loading: flooredCarsLoading,
    data: flooredCarsData
  }] = useLazyQuery(FLOORED_CARS_BY_DEALER);

  const [getFeesByFlooredCar, {
    loading: feesLoading,
    data: feesData
  }] = useLazyQuery(FEES_BY_FLOORED_CAR);

  const {values} = useFormikContext();

  const [purposes] = useEnum('TxnLine', 'purpose');
  const firstLine = 0 == indexValue;

  let location = useLocation();

  useEffect(() => {
    if (values.txnLinesAttributes[indexValue].marketId) {
      getDealersByMarket({variables: {
        id: values.txnLinesAttributes[indexValue].marketId
      }})
    }
  }, [values.txnLinesAttributes[indexValue].marketId])

  useEffect(() => {
    if (values.txnLinesAttributes[indexValue].dealerId) {
      getFlooredCarsByDealer({variables: {
        id: values.txnLinesAttributes[indexValue].dealerId
      }})
    }
  }, [values.txnLinesAttributes[indexValue].dealerId])

  useEffect(() => {
    if (values.txnLinesAttributes[indexValue].flooredCarId) {
      getFeesByFlooredCar({variables: {
        id: values.txnLinesAttributes[indexValue].flooredCarId
      }})
    }
  }, [values.txnLinesAttributes[indexValue].flooredCarId])

  if (loading) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  if (!(location.search.includes('detailed=t'))) {
    return null;
  }

  return (
    <>
      <div>
        {firstLine && 'Dealer'}
        {
          !dealersLoading ?
            <BasicForm.JournalProgrammaticSelect
              name={`txnLinesAttributes[${indexValue}].dealerId`}
              optionName="Dealer"
              options={dealersData?.market?.dealers || [{id: null, name: ""}]}
            /> : "Loading..."
        }
      </div>
      <div>
        {firstLine && 'Car'}
        {
          !flooredCarsLoading ?
          <BasicForm.JournalProgrammaticSelect
            name={`txnLinesAttributes[${indexValue}].flooredCarId`}
            optionName="FlooredCar"
            options={
              flooredCarsData?.dealer?.flooredCars || [{id: null, name: ''}]
            }
          /> : "Loading..."
        }
      </div>
      <div>
        {firstLine && 'Fee'}
        <BasicForm.JournalProgrammaticSelect
          name={`txnLinesAttributes[${indexValue}].feeId`}
          optionName="Fee"
          options = {feesData?.flooredCar.fees || [{id: null, name: ''}]}
        />
      </div>
      <Select
        fieldLabel="Purpose"
        field="purpose"
        lineIndex={indexValue}
        options={purposes}
      />
      <div>
        {
          firstLine &&
            <>
              <span>Earns Interest</span>
              <br />
            </>
        }
        <BasicForm.TransactionCheckbox
          name={`txnLinesAttributes[${indexValue}].earnsInterest`}
          type="checkbox"
          id={indexValue}
        />
      </div>
    </>
  );
};

export default TxnLineFlooringInputs;
