import styled from "@emotion/styled";

export const PaginationBar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 35px;
`;

export const ActionDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ActionButton = styled.a`
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
  &:hover {
    color: white !important;
    border: 1px solid #111;
    background-color: #585858;
    background: linear-gradient(to bottom, #585858 0%, #111 100%);
  }
`;

export const CurrentPage = styled.button`
  background: #2b6448 !important;
  color: white !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s linear;
  font-size: 0.9rem;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border: 0;
  border-radius: 0.125rem;
  &:hover {
    color: white !important;
    background: #d68e00 !important;
    transition: all 0.3s linear;
    border-radius: 0.125rem;
    border: #d68e00;
  }
`;

export const PaginateButton = styled.a`
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
  margin-left: 1px;
  padding: 0;
  line-height: 2.1;
  &:hover {
    color: white !important;
    border: 1px solid #111;
    background-color: #585858;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
    background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
    background: -moz-linear-gradient(top, #585858 0%, #111 100%);
    background: -ms-linear-gradient(top, #585858 0%, #111 100%);
    background: -o-linear-gradient(top, #585858 0%, #111 100%);
    background: linear-gradient(to bottom, #585858 0%, #111 100%);
  }
  }
`;
