import React from 'react';
import { useHistory } from 'react-router-dom';
import NewBankAccount from '../../BankAccounts/New';
import { Helmet } from 'react-helmet';

import { DEALER_BANK_ACCOUNTS } from '../Queries';

const New = (props) => {
  const currentPath = props.location.pathname;

  return (
    <>
      <Helmet>
        <title>New Bank Account</title>
      </Helmet>
      <NewBankAccount
        ownerType={'Dealer'}
        gqlQuery={DEALER_BANK_ACCOUNTS}
      />
    </>
  );
};


export default New;
