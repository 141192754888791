import React from 'react';
import * as Colors from './colors';
import * as Typography from './typography';
import * as Spacing from './spacing';
import * as Formatting from './formatting'

export const preflightTableStyles = {
  table: {
    marginLeft: 15,
    marginRight: 15,
    width: 'auto',
    minWidth: '98%',
  },
  functionRow: {
    paddingLeft: 15,
    paddingRight: 9,
  },
  data: {
    cell: {
      verticalAlign: 'top',
    }
  }
};

export const PageTitle = ({children}) => {
  return (
    <div style={Typography.pageTitle}>{children}</div>
  )
};

export { Colors, Typography, Spacing, Formatting };
