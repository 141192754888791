import React, { Fragment } from "react";
import { useFormikContext } from "formik";
import SingleFeeForm from "../SingleFee";

const MultipleFeeForm = ({ index, accounts }) => {
  return (
    <SingleFeeForm index={index} accounts={accounts} allowAddFee/>
  );
};

export default MultipleFeeForm;
