import React from 'react';
import { useParams, useRouteMatch, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import ApprovedSourceRoutes from '../../routes/ApprovedSourceRoutes';
import { titleCase } from '../../utils/stringHelpers';
import DataState from '../DataState';
import SubNav from '../../elements/SubNav';
import Contacts from './Contacts';
import { getLinks } from './Links';

const APPROVED_SOURCE = gql`
query APPROVED_SOURCE($id: ID!) {
  approvedSource(id: $id) {
    id
    name
    city
    state
    type
    street
    zip { value }
    phone { value }
  }
}`

const useURLQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const ApprovedSourceShow = (props) => {
  let checkRefresh = useURLQuery();
  const forceRefresh = checkRefresh.get("forceRefresh");
  if(forceRefresh) {
    window.location.assign(useLocation().pathname);
  }
  const { id } = useParams();
  const route = useRouteMatch();

  const { loading, error, data } = useQuery(APPROVED_SOURCE, { variables: { id } });

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { name } = data.approvedSource;

  const { title, navLinks } = getLinks(name, id);

  return (
    <div>
      <Helmet>
        <title>{titleCase(name)}</title>
      </Helmet>
      <SubNav title={title} navLinks={navLinks} />
      {
        route.isExact &&
        <>
          <Contacts />
        </>
      }
      <ApprovedSourceRoutes />
    </div>
  );
}

export default ApprovedSourceShow;
