import React, { useState, Fragment } from "react";
import { useMutation, gql } from "@apollo/client";
import { Formik } from "formik";
import { useHistory } from 'react-router-dom';
import { Spacing, Typography } from "../../styles";
import { dealerProgramSchema, Form } from "./Form";
import { useServerSideErrors } from "../../hooks/useServerSideErrors";
import Loading from "../../elements/Loading";
import { DEALER_PROGRAMS } from "./queries";

const CREATE_DEALER_PROGRAM = gql`
  mutation CreateDealerProgram(
    $code: String!
    $description: String!
    $termsAttributes: [TermInput!]!
  ) {
    createDealerProgram(
      code: $code
      description: $description
      termsAttributes: $termsAttributes
    ) {
      errors {
        message
        path
      }
    }
  }
`;

const NewDealerProgram = () => {
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createDealerProgram, { errors }] = useMutation(CREATE_DEALER_PROGRAM, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: DEALER_PROGRAMS
      }
    ],
   onCompleted(values) {
      setFormSubmitting(false);
      history.push({
        pathname: '/system/dealer-programs',
        state: { alert: true, type: 'created' }
      })
    }
  })

  const handleSubmit = (values) => {
    setFormSubmitting(true);

    values.termsAttributes = values.termsAttributes.map((attribute, index) => {
      if (attribute.feeType !== 'Interest') return attribute;

      attribute.termLinesAttributes = attribute.termLinesAttributes.map((attr, idx) => {
        delete attr.termLineFeesAttributes
        return attr
      })

      return attribute
    })

    createDealerProgram({ variables: values })
  }

  const New = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Create A Dealer Program</h1>
        </div>
        <Formik
          initialValues={{
            code: "",
            description: "",
            termsAttributes: [
              {
                order: 1,
                termLinesAttributes: [
                  {
                    principalBelow: null,
                    curtailmentPercentage: 0,
                    termLineFeesAttributes: [{}],
                  },
                ],
              },
            ],
          }}
          validationSchema={dealerProgramSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    );
  };

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <New />
      </div>
    );
  };

  return (
    <Fragment>
      <DisplayContent />
    </Fragment>
  );
};

export default NewDealerProgram;
