import React, { useState } from 'react';
import { Typography, preflightTableStyles } from '../../../styles';
import DataTable from '@preflighttech/preflight-tables';
import { FLOORED_CAR } from './queries';
import { useParams } from "react-router-dom";
import DataState from '../../DataState';
import { useQuery } from '@apollo/client';
import { columnDefinitions } from './columnDefinitions';
import Update from './Edit';
import BasicAlert from '../../../elements/BasicAlert';
import { Helmet } from 'react-helmet';
import AuditImage from 'components/src/elements/UploadPreview';
import * as Colors from '../../../styles/colors';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import UpdateSoldModal from '../../Modals/Sold/Edit';
import { FLOORED_CAR_NOTES } from '../Queries';
import Notes from '../../Notes';

const Audits = props => {
  const flooredCarId = parseInt(useParams().carId)

  const [auditId, setAuditId] = useState();
  const [quiktrakJobNumber, setQuiktrakJobNumber] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [editAuditCarAlert, setEditAuditCarAlert] = useState(false);
  const [showAuditImage, setShowAuditImage] = useState(false);
  const [imageId, setImageId] = useState();
  const [unverifiedTwice, setUnverifiedTwice] = useState(false);
  const [showUpdateSoldModal, setShowUpdateSoldModal] = useState(false);

  const { loading, error, data, refetch } = useQuery(FLOORED_CAR, {
    variables: { id: flooredCarId }
  })

  const handleCloseEdit = () => setShowEdit(false);
  const handleCloseImage = () => setShowAuditImage(false);
  const handleCloseUpdateSaleModal = () => setShowUpdateSoldModal(false);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const auditCars = data.flooredCar.auditCars

  const lastAuditId = auditCars.map(audit => audit.id)
    .reduce((max, current) => Math.max(current, max || 0), null)

  const columns = columnDefinitions({
    setShowEdit,
    setAuditId,
    setQuiktrakJobNumber,
    lastAuditId,
    setShowAuditImage,
    setImageId,
    setUnverifiedTwice,
    setShowUpdateSoldModal
  });

  let foundAudits = []

  const recentAudits = auditCars.slice(-2).map((audit) => {
    if (audit.qtFound === 0) {
      foundAudits.push(true)
    }
  })

  return (
    <>
      {editAuditCarAlert && (
        <BasicAlert componentType='Audit' type='Resolved' />
      )}
      <ResourcesHeader noNew title='Audits' />
      <DataTable.Simple
        data={auditCars}
        columns={columns}
        htmlTable
        styles={preflightTableStyles}
        pageLength={10}
        lengthMenu={ [10, 25, 50] }
        disableSearch={true}
      />
      <hr />
      {!props.noNotes && (
        <Notes
          query={FLOORED_CAR_NOTES}
          ownerType="flooredCar"
          ownerId={flooredCarId}
        />
      )}
      {showEdit && (
        <Update
          flooredCarId={flooredCarId}
          handleClose={handleCloseEdit}
          show={showEdit}
          auditId={auditId}
          quiktrakJobNumber={quiktrakJobNumber}
          refetch={refetch}
          setEditAuditCarAlert={setEditAuditCarAlert}
          showPageRefetch={props.showPageRefetch}
          setRefetchAudit={props.setRefetchAudit}
          unverifiedTwice={unverifiedTwice}
          foundAudits={foundAudits}
          currentAudit={auditCars[auditCars.length -1]}
        />
      )}
      {showAuditImage && (
        <AuditImage
          handleClose={handleCloseImage}
          show={showAuditImage}
          id={imageId}
        />
      )}
      <UpdateSoldModal
        show={showUpdateSoldModal}
        handleClose={handleCloseUpdateSaleModal}
        refetch={props.showPageRefetch}
        car={data.flooredCar}
        setRefetchAudit={props.setRefetchAudit}
      />
    </>
  )
}

export default Audits;
