import { gql } from '@apollo/client';

export const SCHEDULED_ACHS = gql`
  query(
    $direction: String!
    $onHold: Boolean!
  ) {
    scheduledAchs(direction: $direction) {
      id
      achAmount
      dealer { id, name }
      txnPurpose
      createdBy { id, fullName }
      bankDate
      bankAccount {
        owner {
          name
          ... on Auction { xlgAuction }
        }
      }
      vin
      onHold @include(if: $onHold)
    }
  }
`;

export const CANCEL_ACH = gql`
  mutation($id: ID!) {
    cancelAch(
      id: $id
    ) {
      quote { id }
      errors { path, message }
    }
  }
`;

export const CANCEL_DEALER_TXN = gql`
  mutation($id: ID!) {
    destroyDealerTxn(
      id: $id
    ) {
      dealerTxn { id }
      errors { path, message }
    }
  }
`;

export const CANCEL_PRINCIPAL_ADJUSTMENT = gql`
  mutation($id: ID!) {
    destroyPrincipalAdjustment(
      id: $id
    ) {
      principalAdjustment { id }
      errors { path, message }
    }
  }
`;

export const UPDATE_ACH_HOLDS = gql`
  mutation($achs: [AchHoldInput!]!) {
    updateAchHolds(
      achs: $achs
    ) {
      errors { path, message }
    }
  }
`;
