import React, { useState, useEffect } from "react";
import * as BasicForm from "../../../../elements/forms";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { divStyle, inputStyle } from "./AdvanceType";
import { useFormikContext } from "formik";
import DataState from "../../../DataState";
import { ErrorMessage } from "../../../../elements/ErrorMessage";
import { SourceType } from './SourceType';
import BankAccountInformation from './BankAccountInfo';
import DealerBankSelect from 'components/src/components/Dealers/BankAccounts/Select';

export const AdvanceTypeOptions = () => {
  const {
    setFieldValue,
    values,
  } = useFormikContext();

  const sourceType = values.sourceType;

  useEffect(() => {
    setFieldValue("sourceId", "");
    setFieldValue("bankAccountId", null);
  }, [sourceType]);

  return <>
    <SourceType key="sourceType" />
    <div style={divStyle}>
      <BasicForm.StyledLabel>Bank Account Information:</BasicForm.StyledLabel>
      {'Dealer' == values.sourceType ? (
        <DealerBankSelect
          id={values.sourceId}
          values={values}
          setFieldValue={setFieldValue}
          style={{ width: '350px', paddingLeft: '10px' }}
        />
      ) : (
        <BankAccountInformation />
      )}
    </div>
  </>
};
