import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { gql } from '@apollo/client';
import { ResponsivePie } from '@nivo/pie'
import * as yup from "yup";
import Form from './Form';
import * as StringHelper from '../../../utils/stringHelpers';
import Loading from '../../../elements/Loading';

const approvedSourceSchema = yup.object({
  averagePrincipal: yup.number().required('Required'),
  averageDays: yup.number().required('Required'),
});

const CALCULATE_YIELD = gql`
  mutation calculateYield(
    $averagePrincipal: Decimal!,
    $averageDays: Int!,
    $rateProgramId: Int!,
    $dealerProgramId: Int!,
    $automatedFeeResourcesAttributes: [AutomatedFeeResourceInput!]) {

    calculateYield (
      averagePrincipal: $averagePrincipal
      averageDays: $averageDays
      rateProgramId: $rateProgramId
      dealerProgramId: $dealerProgramId
      automatedFeeResourcesAttributes: $automatedFeeResourcesAttributes
    ) {
      grossYield
      feeIncome
      interestIncome
      netYield
      errors {
        message
        path
      }
    }
  }
`;


const wrapperDiv = {
  width: '90%',
  margin: 'auto',
  marginTop: '3%',
  display: 'flex',
  justifyContent: 'spaceEvenly',
  height: '100%'
}

const index = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [yieldData, setYieldData] = useState(null)

  const [calculateYield] = useMutation(CALCULATE_YIELD,
    {
      onCompleted(values) {
        const {feeIncome, interestIncome, grossYield, netYield} = values.calculateYield;
        setFormSubmitting(false);
        const totalIncome = feeIncome + interestIncome;
        setYieldData({
          grossYield: grossYield,
          totalIncome: totalIncome,
          feeIncome: feeIncome,
          interestIncome: interestIncome,
          netYield: netYield
        });
      }
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true);
    calculateYield({ variables: values });
  }

  const formWrapper = {
    width: '45%'
  }

  const infoContainer = {
    display: 'flex',
    justifyContent: 'spaceBetween',
    marginLeft: '5%',
    marginTop: '6%',
    width: '50%',
    height: '40%',
    flexDirection: 'column',
  }

  const infoCard = {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    marginLeft: '1%',
    width: '100%',
    backgroundColor: 'rgba(43, 100, 72, 1)',
    color: 'white',
    padding: '10%'
  }

  const dataRow = {
    marginTop: '1%',
    display: 'flex',
    flexDirection: 'row',
  }

  const infoCategory = {
    textAlign: 'center',
    fontSize: '0.8rem',
    color: '#D0DDD6',
    marginTop: '3%'
  }

  const infoData = {
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 'bold'
  }

  const loadingContainer = {
    marginLeft: '25%'
  }

  const YieldDataCard = ({dataName, data}) => {
    return (
      <div style={infoCard}>
        <div style={infoCategory}>
          {dataName}
        </div>
        <div style={infoData}>
          {data}
        </div>
      </div>
    )
  }

  const DisplayLoading = () => {
    return formSubmitting ? (
      <div style={loadingContainer}>
        <Loading />
      </div>
    )
    : null
  }

  const YieldData = () => {
    return (
      <div style={infoContainer}>
        <div style={dataRow}>
         
          <div style={infoCard}>
            <div style={infoCategory}>
              Gross Yield
            </div>
            <div style={infoData}>
              {(yieldData.grossYield * 100).toFixed(2)}%
            </div>
            <div style={infoCategory}>
              Net Yield
            </div>
            <div style={infoData}>
              {(yieldData.netYield * 100).toFixed(2)}%
            </div>
          </div>
          <div style={infoCard}>
            <div style={infoCategory}>
              Estimated Income Per Car
            </div>
            <div style={infoData}>
              {StringHelper.currency(yieldData.totalIncome)}
            </div>
            <div style={infoCategory}>
              Fees
            </div>
            <div style={infoData}>
              {StringHelper.currency(yieldData.feeIncome)}
            </div>
            <div style={infoCategory}>
              Interest
            </div>
            <div style={infoData}>
              {StringHelper.currency(yieldData.interestIncome)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={wrapperDiv}>
      <div style={formWrapper}>
        <Formik
          initialValues={{
            averagePrincipal: 0,
            averageDays: 0,
            interestProgramId: null,
            feeProgramId: null,
            termProgramId: null,
          }}
          validationSchema={approvedSourceSchema}
          onSubmit={handleSubmit}
        >
          <Form formSubmitting={formSubmitting} />
        </Formik>
      </div>
      { yieldData && !formSubmitting ? <YieldData />  : <DisplayLoading /> }
    </div>
  );
};

export default index;
