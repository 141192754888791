import React from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';

const Subject = styled.p`
  font-weight: bold;
  margin-bottom: 0;
`;

const Mention = styled.span`
  background-color: #cee4e5;
`;

const Note = styled.p`
  margin-bottom: 0;
`;

const Annotation = styled.p`
  font-size: 0.6rem;
  text-decoration: underline;
  margin-bottom: 0;
`;

const Icon = styled.i`
  --fa-primary-color: white;
  --fa-secondary-color: crimson;
  --fa-secondary-opacity: 1.0;
  margin-top: 15px;
`;

const StatusDetail = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0;
`;

const MENTION_REGEX_SPLIT = /(@\[[\w\s-]+\]\(\d+\))/g;
const MENTION_REGEX = /@\[([\w\s-]+)\]\((\d+)\)/;

const highlightMentions = (text) => {
  const array = text.split(MENTION_REGEX_SPLIT);

  return array.map((item, index) => {
    if (item.match(MENTION_REGEX)) {
      return (
        <Mention key={`match-${index}`}>
         {item.replace(MENTION_REGEX, '$1')}
        </Mention>
      );
    } else {
      return item;
    }
  });
};

export const columns = ({ hide, unhide, unhideable, prefix }) => {
  return [
    {
      key: 'createdAt',
      label: 'Date',
      sort: 'desc',
      content: ({value}) => moment(value).format('LLL'),
    },
    {
      key: 'subject',
      label: 'Note',
      value: (({entry}) => {
        let value = `${entry.subject}\n${entry.note}`;
        if (entry.corporate) {
          value = `${value}\ncorporate note`;
        }
        return value;
      }),
      content: (({entry}) => {
        return (
          <>
            {entry.subject && <Subject>{entry.subject}</Subject>}
            {entry.note && <Note>{highlightMentions(entry.note)}</Note>}
            {entry.corporate && <Annotation>coprporate note</Annotation>}
          </>
        )
      }),
    },
    {
      key: 'important',
      label: <i className="fad fa-exclamation-triangle" />,
      content: ({value}) => (
        value && <Icon className="fad fa-exclamation-triangle" />
      )
    },
    {
      key: 'collection',
      label: <i className="fad fa-comment-dollar" />,
      content: ({value}) => (
        value && <Icon className="fad fa-comment-dollar" />
      )
    },
    {
      key: 'createdBy',
      label: 'Added By',
      value: ({value}) => value?.fullName,
    },
    {
      key: 'statusBy',
      label: 'Status',
      value: (({entry}) => (
        `${entry.status} by ${(entry.statusBy || entry.createdBy)?.fullName}`
      )),
      content: (({entry}) => {
        return (
          <>
            <Note>{entry.status}</Note>
            <StatusDetail>
              By {(entry.statusBy || entry.createdBy)?.fullName}
            </StatusDetail>
            <StatusDetail>
              on {
                moment(entry.statusAt || entry.createdAt).
                  format('M/D/Y [at] hh:mm a')
              }
            </StatusDetail>
          </>
        );
      })
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: (({entry}) => (
        <ResourceActionLinks
          prefix={prefix}
          resource={entry}
          noRead
          hide={unhideable ? false : hide}
          unhide={unhideable ? false : unhide}
        />
      ))
    }
  ];
};

