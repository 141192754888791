import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Form, newProgramSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import { COLLATERAL_COVERAGES } from './queries';
import { CURRENT_USER } from '../../../queries/commonQueries'
import DataState from '../../DataState';
import Loading from '../../../elements/Loading';


const NEW_COLLATERAL_COVERAGE = gql`
  mutation CreateInsuranceProgram($name: String!, $description: String,
  $expirable: String!, $gracePeriod: Int, $createdById: Int,
  $insuranceProgramChangesAttributes: [InsuranceProgramChangeInput!]!)
  {
    createInsuranceProgram(
      name: $name
      description: $description
      expirable: $expirable
      gracePeriod: $gracePeriod
      createdById: $createdById
      insuranceProgramChangesAttributes: $insuranceProgramChangesAttributes
    ) {
      insuranceProgram { id }
      errors { message, path }
    }
  }
 `

const NewCollateralCoverage = () => {
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [newCollateralCoverage, { loading: mutationLoading, error: mutationError }] = useMutation(
    NEW_COLLATERAL_COVERAGE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: COLLATERAL_COVERAGES }],
      onCompleted(variables) {
        setFormSubmitting(false);
        setRedirect(true);
      }
    })

  const { loading, error, data } = useQuery(CURRENT_USER);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const createdById = parseInt(data.currentUser.id);

  const handleSubmit = (values) => {
    if (values.gracePeriod === '') values.gracePeriod = null;
    setFormSubmitting(true)
    newCollateralCoverage({ variables: values })
  };

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/collateral-coverages",
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create a Collateral Coverage Change</h1>
          </div>

          <Formik
            initialValues={{
              createdById,
              name: '',
              description: '',
              expirable: '',
              gracePeriod: 0,
              insuranceProgramChangesAttributes: {},
            }}
            validationSchema={newProgramSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }
  return (
    <>
      <Helmet>
        <title>New Collateral Coverage</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default NewCollateralCoverage;
