import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line'
import { xlGreen } from 'components/src/styles/colors';
import moment from 'moment';

const FlooredByMonth = ({ data }) => {
  const [ttm, setTtm] = useState(true);

  const endOn = ttm ?
    moment().startOf('month') : moment().endOf('year').startOf('month');
  const startOn = endOn.subtract(12, 'months');

  const points = []
  for (let i = 0; i < 12; i++) {
    startOn.add(1, 'month');
    const date = startOn.format('YYYY-MM-DD');

    points.push({
      x: startOn.format('M/YY'),
      y: (data.find(item => item.date === date)?.count || 0)
    });
  }

  const formattedData = [
    {
      id: 'flooredByMonth',
      data: points
    }
  ];

  const buttonOnClasses = 'btn btn-primary';
  const buttonOffClasses = 'btn btn-secondary';
  const buttonStyle = {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 6,
    paddingRight: 6,
    marginLeft: 10,
  };

  return (
    <>
      <span>Number of Units Floored By Month</span>

      <div style={{float: 'right'}}>
        <button
          type="button"
          className={ttm ? buttonOffClasses : buttonOnClasses}
          style={buttonStyle}
          onClick={() => setTtm(false)}
        >
          YTD
        </button>

        <button
          type="button"
          className={ttm ? buttonOnClasses : buttonOffClasses}
          style={buttonStyle}
          onClick={() => setTtm(true)}
        >
          TTM
        </button>
      </div>

      <ResponsiveLine
        data={formattedData}
        colors={[xlGreen]}
        height={170}
        margin={{ top: 5, right: 5, bottom: 30, left: 20 }}
        useMesh
        axisBottom={{
          tickSize: 0
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
        }}
      />
    </>
  );
};

export default FlooredByMonth;
