import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import DataTables from '@preflighttech/preflight-tables';
import Header from '../Header';
import Filter from 'components/src/elements/Filter';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';
import { UserContext } from 'components/src/contexts/UserContext';
import {
  FLOORED_CARS_WITH_COUNT
} from 'components/src/components/Cars/Queries';
import { preflightTableStyles } from 'components/src/styles';
import {
  columnDefinitions
} from 'components/src/components/Cars/columnDefinitions';

const columnKeys = [
  'market', 'dealer', 'legalName', 'dealerStatus',
  'dealerCategories', 'dealerCreditLine',
  'dealerCurrentPrincipal', 'unverifiedAuditCarsCount',
  'titleStatus', 'titleStatusOn', 'unitNumber', 'vin', 'achStatus',
  'borrowedTitlePastDue', 'borrowedTitleDays', 'comingDueActions',
];

const columnChanges = [
  {
    key: 'titleStatus',
    minViewportWidth: 1000,
  },
  {
    key: 'borrowedTitlePastDue',
    sort: 'desc',
  }
];

const BorrowedTitle = () => {
  const user = useContext(UserContext);
  const [filterOptions, setFilterOptions] = useState();
  const [filters, setFilters] = useState({});

  const columns = columnDefinitions({ columnKeys, columnChanges });

  const queryVariables = {
    filterColumns: ['marketId'],
    filterNames: [user.filterMarketIds() || ''],
    scopes: ['borrowedTitleDue'],
  };

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);
  };

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      queryVariables['filterColumns'].push(key);
      queryVariables['filterNames'].push(value);
    }
  });

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <Filter clearFilters={() => setFilters({})}>
        <Filter.MultiSelect
          columnKey='dealerStatus'
          label='Dealer Status'
          options={filterOptions?.dealerStatuses}
          filters={filters}
          setFilter={setFilter}
        />
      </Filter>
      <ResetSettings />
    </View>
  )

  return (
    <>
      <Header title="Unpaid Borrowed Titles" />
      <DataTables.Gql
        query={FLOORED_CARS_WITH_COUNT}
        queryName="flooredCarsWithCount"
        queryVariables={queryVariables}
        entriesName="flooredCars"
        columns={columns}
        buttons={buttons}
        dataUpdated={data => setFilterOptions(data?.flooredCarsWithCount)}
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default BorrowedTitle;
