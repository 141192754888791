import React, { useContext } from 'react';
import { setDate } from '../../utils/dateHelpers';
import AuthContext from '../../contexts/AuthContext';
import * as StringHelper from '../../utils/stringHelpers';
import { MediumRowLoading } from '../../elements/Loading';
import { useMutation } from '@apollo/client';
import { UPDATE_STATUS, CREATE_LABEL } from './Queries';

export const columnDefinitions = props => {
  const { refetch } = props;

  const auth = useContext(AuthContext);

  const definitions = [
    {
      key: 'shipmentAt',
      label: 'Shipment Date',
      content: ({ entry }) => setDate(entry.shipmentAt)
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'shipTo',
      label: 'Ship To',
      value: ({ entry }) => {
        return (
          `${entry.sourceType} - ${entry.toAddress.name}`
        )
      },
      content: ({ value }) => value
    },
    {
      key: 'shipmentStatus',
      label: 'Shipment Status',
      content: ({ entry }) => {
        const [updateStatus, {loading: statusLoading}] = useMutation(
          UPDATE_STATUS, { onCompleted(values) { refetch() } }
        );

        let refreshStatus = ''
        let trackingStatus = ''
        let refreshButton =
          <a
            href="#"
            onClick={
              () => updateStatus({ variables: { id: parseInt(entry.id) } })
            }
          >
            <i className="fas fa-redo-alt"></i>
          </a>

        if (entry.trackingStatus === 'Delivered') {
          refreshStatus = 'Delivered'
        } else if (entry.shipmentAt === null) {
          refreshStatus = ''
        } else if (entry.trackingStatus === null) {
          refreshStatus = refreshButton
        } else {
          refreshStatus = refreshButton
          trackingStatus = ` - ${entry.trackingStatus}`
        }

        return (
          statusLoading ? <MediumRowLoading /> :
            <span>{refreshStatus}{trackingStatus}</span>
        )
      }
    },
    {
      key: 'statusUpdateAt',
      label: 'Updated On',
      content: ({ entry }) => setDate(entry.statusUpdateAt)
    },
    {
      key: 'trackingNumber',
      label: 'Tracking Number'
    },
    {
      key: 'priceQuoted',
      label: 'Price Quoted',
      content: ({ entry }) => {
        if (entry.priceQuoted === null) {
          return null
        }

        return StringHelper.currency(entry.priceQuoted)
      }
    },
    {
      key: 'shipmentBy',
      label: 'Created By',
      value: ({ entry }) => entry.shipmentBy?.fullName,
      content: ({ value }) => value
    },
    {
      key: 'pmsiTitleCount',
      label: 'PMSI/Title Count'
    },
    {
      key: 'action',
      label: 'Action',
      sort: 'prevent',
      value: ({ entry }) => {
        if (
          entry.shipmentAt === null && auth.hasPolicy("Shipment", "update")
        ) {
          return 'Ship Now'
        } else {
          return 'Download'
        }
      },
      content: ({ entry }) => {
        let shipNow;

        const [updateStatus] = useMutation(UPDATE_STATUS, {
          onCompleted(values) { refetch() }
        });

        const [createLabel, { loading: labelLoading}] = useMutation(
          CREATE_LABEL, {
            update(cache, { data }) {
              window.open(data.createLabel.shipment.fileUpload.fileUrl)
              refetch()
              updateStatus({ variables: { id: data.createLabel.shipment.id } })
            }
          }
        );

        if (
          entry.shipmentAt === null && auth.hasPolicy("Shipment", "update")
        ) {
          shipNow =
            <a
              href="#"
              onClick={
                () => createLabel({ variables: { id: parseInt(entry.id) } })
              }
            >
              Ship Now
            </a>
        } else {
          shipNow =
            <a href="#" onClick={() => window.open(entry.fileUpload.fileUrl)}>
              Download
            </a>
        }

        return (
          labelLoading ? <MediumRowLoading /> : shipNow
        )
      }
    }
  ]

  return definitions
}
