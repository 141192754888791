import React, { useState, useEffect } from "react";
import * as BasicForm from "../../../../../elements/forms";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { divStyle, inputStyle } from "./AdvanceType";
import { useFormikContext } from "formik";
import DataState from "../../../../DataState";
import { ErrorMessage } from "../../../../../elements/ErrorMessage";
import { SourceType } from './SourceType';
import BankAccountInformation from './BankAccountInfo';

export const AdvanceTypeOptions = () => {
  const {
    setFieldValue,
    values: { sourceType },
  } = useFormikContext();

  useEffect(() => {
    setFieldValue("sourceId", "");
    setFieldValue("bankAccountId", "");
  }, [sourceType]);

  return <>
  <SourceType key="sourceType" />
  <div style={divStyle}>
    <BasicForm.StyledLabel>Bank Account Information:</BasicForm.StyledLabel>
    <BankAccountInformation />
  </div>
  </>
};
