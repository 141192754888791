import React from 'react';
import { Spacing, Typography } from 'components/src/styles';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import DataState from 'components/src/components/DataState';
import { ACH_BATCH_DECRYPTED } from './queries';
import AchBatchInfo from './AchBatchInfo';

const Decrypt = () => {
  const id = parseInt(useParams().id);

  const { loading, error, data } = useQuery(
    ACH_BATCH_DECRYPTED, { variables: { id } }
  );

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { achBatch } = data;

  return (
    <div style={Spacing.formWrapper}>
      <AchBatchInfo achBatch={achBatch} />
      <pre style={Spacing.showContainer}>{achBatch.decryptedData}</pre>
    </div>
  );
}

export default Decrypt;
