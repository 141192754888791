import React from 'react';
import { useHistory } from 'react-router-dom';
import NewBankAccount from '../../BankAccounts/New';
import { Helmet } from 'react-helmet';

import { APPROVED_SOURCE_BANK_ACCOUNTS } from '../queries';

const New = (props) => {
  const currentPath = props.location.pathname;

  return (
    <>
    <Helmet>
      <title>New Bank Account</title>
    </Helmet>
    <NewBankAccount
      ownerType={'ApprovedSource'}
      gqlQuery={APPROVED_SOURCE_BANK_ACCOUNTS}
    />
    </>
    );
};


export default New;
