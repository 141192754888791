import { addFilters } from './addFilters';

export const columnSort = (
  sortColumnIndex,
  columnIndex,
  sort,
  getColumnName,
  setQueryData,
  sortDirection,
  direction,
  offset,
  currentPage,
  fetchMore,
  limit,
  queryData,
  search,
  marketId=null,
  filters=null,
  setSorting,
  setQueryLoading,
  shownColumns
) => {
  sortColumnIndex.current = columnIndex;
  sort.current = getColumnName(columnIndex, shownColumns);

  if (sort.current === null) {
    setQueryData(queryData);
    return;
  }

  sortDirection.current = direction;

  offset.current = 0;
  currentPage.current = 1;

  const variables = {
      limit,
      sort: sort.current,
      sortDirection: sortDirection.current,
      offset: 0,
      search: search.current,
    }

  if (marketId) variables.marketId = parseInt(marketId);

  if (filters) addFilters(filters, variables);

  setQueryLoading(true);
  fetchMore({
    variables,
    updateQuery: (prev, { fetchMoreResult }) => {
      setQueryLoading(false);
      setSorting(false);
      setQueryData(fetchMoreResult);
    },
  });
};
