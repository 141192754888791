import { Colors } from '../styles';

export const infoContainer = {
  width: '100%',
  maxWidth: '100%',
  fontSize: 14,
  marginTop: '10px'
}

export const infoBar = {
  ...infoContainer,
  background: Colors.xlGreen,
  padding: '1%',
  color: 'white',
  fontWeight: 'bold',
  marginTop: '-0.01%'
}

export const infoBarImportantText = {
  borderStyle: 'solid',
  borderColor: '#40735A',
  background: '#55836C',
  marginLeft: '1%',
  padding: '0.5%'
}

export const serverSideErrorStyles = {
  color: 'red',
  fontWeight: 'bold',
  backgroundColor: '#fce4e4',
  border: '1px solid #fcc2c3',
  padding: '20px 30px',
  margin: 'auto',
  marginTop: '1%',
  marginBottom: '1%',
  width: '95%',
}

export const loadingText = {
  color: Colors.xlGreen,
  fontSize: 20,
  fontWeight: 'bold'
}

export const centeredLoadingDiv = {
  margin: 'auto',
  marginTop: '1%',
  width: '55%'
}

export const dashboardColumn = {
  padding: '10px',
}

export const addRecord = {
  color: Colors.xlGreen,
  fontSize: 24,
  float: 'right',
  marginRight: 30,
}
