import React, { useState, Fragment } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import { accountSchema, Form } from './Form';
import Loading from '../../../elements/Loading';
import { ACCOUNTS } from '../../../queries/indexQueries'
import { getServerSideErrors } from '../../../utils/errorHelpers';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors';
import * as Formatting from '../../../styles/formatting';

const CREATE_ACCOUNT = gql`
 mutation CreateAccount($gl: String!, $name: String!, $special: String,
  $parentId: Int, $nextCheckNumber: Int, $accountGroupId: Int)
  {
    createAccount(gl: $gl, name: $name, special: $special, parentId: $parentId,
      nextCheckNumber: $nextCheckNumber, accountGroupId: $accountGroupId)
    {
      account {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

let submittedValues = {};
const New = () => {
  const [showAlert, displayAlert] = useAlert();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const history = useHistory();

  const [createAccount] = useMutation(CREATE_ACCOUNT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ACCOUNTS
        }
      ],
      onCompleted(values) {
        setFormSubmitting(false);
        const serverErrors = getServerSideErrors(values);
        if (serverErrors) {
          setServerSideErrors(serverErrors);
        } else {
          history.push({
            pathname: `/financial/accounts`,
            state: { alert: true, type: 'created' },
          })
        }
      }
    }
  );

  const handleSubmit = (values) => {
    if (values.parentId === '') values.parentId = null;
    if (values.accountGroupId === '') values.accountGroupId = null;
    submittedValues = values;
    setFormSubmitting(true);
    createAccount({ variables: values })
  };

  const NewForm = () => {
    return (
      <Fragment>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Account</h1>
          </div>
          <Formik
            initialValues={{
              gl: submittedValues.gl || '',
              name: submittedValues.name || '',
              special: submittedValues.special || '',
              parentId: submittedValues.parentId || '',
              nextCheckNumber: submittedValues.nextCheckNumber || null,
              accountGroupId: submittedValues.accountGroupId || '',
            }}
            validationSchema={accountSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </Fragment>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    );
  };

  return (
    <Fragment>
      { serverSideErrors &&
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      }
      <div style={Spacing.formContainer}>
        <DisplayContent />
      </div>
    </Fragment>
  );
};

export default New;
