import { useHistory, useLocation } from 'react-router-dom';
import { StringHelpers } from '../../utils';


export const setOwnerType = () => {
  let ownerType = useLocation().pathname.split('/')[1]
  ownerType = StringHelpers.titleCase(ownerType).slice(0, -1);

  if (ownerType.includes('-')) {
    ownerType = ownerType.split('-');
    ownerType = ownerType.map((value) => StringHelpers.titleCase(value))
    ownerType = ownerType.join('');
  }

  return ownerType
}
