import React, { useState } from 'react';
import { useParams, useRouteMatch, Link } from 'react-router-dom';
import UploadPreview from 'components/src/elements/UploadPreview';

const carImage = {
  maxheight: '220px',
  width: '100%',
  marginTop: '-8px'
}

export const AddCarImage = (props) => {
  const { dealerId, carId } = props;
  const containerStyle = {
    width: '100%',
    textAlign: 'center',
    border: '1px solid #AAAAAA',
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const linkStyle = {
    color: '#AAAAAA',
    textDecoration: 'none'
  }

  return <Link
    style={linkStyle}
    to={{
      pathname: `/dealers/${dealerId}/cars/${carId}/new-car-image`
    }}
  >
    <div className='add-first-image-container' style={containerStyle}>
      <h5 className='add-first-image-text'>Upload a Car Image</h5>
    </div>
  </Link>
}

export const CarImage = (props) => {
  const { src, carId, dealerId, uploadId } = props;
  const [showPreview, setShowPreview] = useState(false);

  const containerStyle = {
    width: '100%',
    position: 'relative',
    textAlign: 'center'
  }

  return (
    <>
      <div
        className="image-container"
        style={containerStyle}
        onClick={() => setShowPreview(true)}
      >
        <img src={src} style={carImage} />
        <div className='overlay'></div>
      </div>

      <Link to={`/dealers/${dealerId}/cars/${carId}/new-car-image`}>
        Upload a New Car Image
      </Link>

      {showPreview && (
        <UploadPreview
          handleClose={() => setShowPreview(false)}
          show={showPreview}
          id={uploadId}
        />
      )}
    </>
  )
}

export const renderCarImage = (src, id, dealerId, uploadId) => {
  return (src)
    ? <CarImage src={src} carId={id} dealerId={dealerId} uploadId={uploadId} />
    : <AddCarImage carId={id} dealerId={dealerId} />
}
