import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import InputGroup from 'react-bootstrap/InputGroup';
import { titleCase } from '../utils/stringHelpers';
import { Colors } from '../styles';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Dropzone, PdfDropzone } from './Dropzone';
import Input from 'react-phone-number-input/input'

export const StyledSelect = styled.select`
  position: relative;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: -8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: white;
`;

export const StyledErrorMessage = styled.div`
  margin-top: 0.8%;
  color: #9f453d;
  font-weight: 550;
  font-style: oblique;
`;

export const InlineStyledErrorMessage = styled.div`
  color: #9f453d;
  font-weight: 550;
  font-style: oblique;
  margin-top: 0px;
  margin-left: 16px;
`

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  color: #2b6448;
  font-family: "Raleway", sans-serif;
  font-size: 0.8em;
  margin: 1.8em 0 0.5em 0;
  position: relative;
`;

export const StyledWriteOffLabel = styled.label`
  display: flex;
  flex-direction: column;
  color: #2b6448;
  font-family: "Raleway", sans-serif;
  font-size: 0.8em;
  margin: 0;
  position: relative;
`;

export const StyledTextInput = styled.input`
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  margin-bottom: -8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: white;
`;

const StyledTextArea = styled.textarea`
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  margin-bottom: -8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: white;
`;

const StyledCheckboxLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2b6448;
  font-family: "Raleway", sans-serif;
  font-size: 0.8em;
  margin: 1.8em 0 0.5em 0;
  position: relative;
`

const StyledCheckboxInput = styled.input`
  margin-left: 8px;
  transform: scale(1.3);
`

const StyledMultiSelectOptions = styled.input`
option: {
  color: 'green'
}
`

const styledEmailInput = {
  marginBottom: '-1px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0
}


const styledPasswordInput = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0
}

const StyledRadioInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: space-around;
  margin-left: 12px;
  margin-right: 10px;
 `

const StyledRadioLabel = styled.div`
 display: flex;
 align-items: center;
 font-size: 0.9em;
 input[type='radio'] { transform: scale(1.2); }
 `

const styledPrepend = {
  padding: '22px 15px'
}

const phoneInputStyle = {
  position: 'relative',
  boxSizing: 'border-box',
  height: 'auto',
  padding: '10px',
  fontSize: '16px',
  marginBottom: '-8px',
  borderBottomRightRadius: '0',
  borderBottomLeftRadius: '0',
  width: '100%',
  backgroundColor: 'white',
  border: '1px solid #ced4da',
}


export const TextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
      <StyledTextArea className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const WriteOffInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledWriteOffLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledWriteOffLabel>
      <StyledTextInput className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
      <StyledTextInput className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const JournalTextInput = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledTextInput className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const EmailInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledTextInput style={styledEmailInput} className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const PasswordInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledTextInput style={styledPasswordInput} className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const Select = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <StyledSelect className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const JournalSelect = ({ ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledSelect className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const StateAbbreviationSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <StyledSelect className="form-control" {...field} {...props}>
        <option value="">Select a State</option>
        <option value='AK'>AK</option>
        <option value='AL'>AL</option>
        <option value='AR'>AR</option>
        <option value='AS'>AS</option>
        <option value='AZ'>AZ</option>
        <option value='CA'>CA</option>
        <option value='CO'>CO</option>
        <option value='CT'>CT</option>
        <option value='DC'>DC</option>
        <option value='DE'>DE</option>
        <option value='FL'>FL</option>
        <option value='FM'>FM</option>
        <option value='GA'>GA</option>
        <option value='GU'>GU</option>
        <option value='HI'>HI</option>
        <option value='IA'>IA</option>
        <option value='ID'>ID</option>
        <option value='IL'>IL</option>
        <option value='IN'>IN</option>
        <option value='KS'>KS</option>
        <option value='KY'>KY</option>
        <option value='LA'>LA</option>
        <option value='MA'>MA</option>
        <option value='MD'>MD</option>
        <option value='ME'>ME</option>
        <option value='MH'>MH</option>
        <option value='MI'>MI</option>
        <option value='MN'>MN</option>
        <option value='MO'>MO</option>
        <option value='MP'>MP</option>
        <option value='MS'>MS</option>
        <option value='MT'>MT</option>
        <option value='NC'>NC</option>
        <option value='ND'>ND</option>
        <option value='NE'>NE</option>
        <option value='NH'>NH</option>
        <option value='NJ'>NJ</option>
        <option value='NM'>NM</option>
        <option value='NV'>NV</option>
        <option value='NY'>NY</option>
        <option value='OH'>OH</option>
        <option value='OK'>OK</option>
        <option value='OR'>OR</option>
        <option value='PA'>PA</option>
        <option value='PR'>PR</option>
        <option value='RI'>RI</option>
        <option value='SC'>SC</option>
        <option value='SD'>SD</option>
        <option value='TN'>TN</option>
        <option value='TX'>TX</option>
        <option value='UT'>UT</option>
        <option value='VA'>VA</option>
        <option value='VI'>VI</option>
        <option value='VT'>VT</option>
        <option value='WA'>WA</option>
        <option value='WI'>WI</option>
        <option value='WV'>WV</option>
        <option value='WY'>WY</option>
      </StyledSelect>
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const ProgrammaticSelect = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const options = props.options.map((option, index) => {
    return (
      <option key={option.id} value={option.id}>
        {titleCase(option.name || option.fullName || `${option.firstName} ${option.lastName}`)}
      </option>
    )
  })

  if (props.firstOption) {
    options.unshift(<option key={`initialValue`} value=''>{props.firstOption}</option>)
  } else {
    options.unshift(<option key={'initialValue'} value=''>Select {props.optionName} </option>)
  }

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <StyledSelect className="form-control" {...field} {...props} onChange={e => {
        setValue(parseInt(e.currentTarget.value) || '');
      }}>
        {options}
      </StyledSelect>
      {
        meta.touched && meta.error ? (
          <StyledErrorMessage>{meta.error}</StyledErrorMessage>
        ) : null
      }
    </>
  );
};

export const JournalProgrammaticSelect = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const options = props.options.map((option, index) => {
    return (
      <option key={option.id} value={option.id}>
        {titleCase(option.name || option.fullName || `${option.firstName} ${option.lastName}`)}
      </option>
    )
  })

  if (props.firstOption) {
    options.unshift(<option key={`initialValue`} value=''>{props.firstOption}</option>)
  } else {
    options.unshift(<option key={'initialValue'} value=''>Select {props.optionName} </option>)
  }

  return (
    <>
      <StyledSelect className="form-control" {...field} {...props} onChange={e => {
        setValue(parseInt(e.currentTarget.value) || '');
      }}>
        {options}
      </StyledSelect>
      {
        meta.touched && meta.error ? (
          <StyledErrorMessage>{meta.error}</StyledErrorMessage>
        ) : null
      }
    </>
  );
};

export const Checkbox = ({ label, children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <StyledCheckboxLabel htmlFor={props.id || props.name}>{label}
        <StyledCheckboxInput id="checkbox" type="checkbox" {...field} {...props} />
        {children}
      </StyledCheckboxLabel>
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="form-control is-invalid">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const JournalCheckbox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <StyledCheckboxInput id="checkbox" type="checkbox" {...field} {...props} />
      {children}
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="form-control is-invalid">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const TransactionCheckbox = ({ id, children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <StyledCheckboxInput id={id} type="checkbox" {...field} {...props} />
      {children}
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="form-control is-invalid">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const Radio = ({ label, children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'ra' });
  return (
    <>
      <StyledRadioLabel htmlFor={props.id || props.name}>
        <StyledRadioInput type="radio" {...field} {...props} />
        {children}
        {label}
      </StyledRadioLabel>
    </>
  );
};

export const AutoCompleteTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
      <Typeahead id="auto-complete" {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage style={props.styledError} className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export const DollarInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
      <InputGroup style={props.inputStyle || null}>
        <InputGroup.Prepend>
          <InputGroup.Text style={props.styledPrepend}>$</InputGroup.Text>
        </InputGroup.Prepend>
        <StyledTextInput style={props.inputStyle} className="form-control" {...field} {...props} />
        {meta.touched && meta.error ? (
          <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
        ) : null}
      </InputGroup>
    </>
  );
};

export const PercentInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
      <InputGroup style={props.inputStyle || null}>
        <InputGroup.Prepend>
          <InputGroup.Text style={props.styledPrepend}>%</InputGroup.Text>
        </InputGroup.Prepend>
        <StyledTextInput style={props.inputStyle} className="form-control" {...field} {...props} />
        {meta.touched && meta.error ? (
          <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
        ) : null}
      </InputGroup>
    </>
  );
};

export const ImageUpload = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
      <Dropzone {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  )
}

export const PdfUpload = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
      <PdfDropzone {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  )
}

export const Label = ({ label, ...props }) => {
  return <StyledLabel htmlFor={props.id || props.name} style={props.style || null}>{label}</StyledLabel>
}

export const CustomStyleTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div style={props.divStyle || null}>
      <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
      <StyledTextInput className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </div>
  );
};

export const PhoneInput = ({label, ...props}) => {
  const [field, meta] = useField(props);

  let formattedNumber = '+1'
  formattedNumber += props.value.replace(/[-()/\s+/]/g, '');

  return <>
    <StyledLabel htmlFor={props.id || props.name} style={props.labelStyle || null}>{label}</StyledLabel>
     <Input
      country="US"
      value={formattedNumber}
      onChange={props.onChange}
      style={phoneInputStyle}
      className='form-control'
      />
      {meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
   </>
}
