import React from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import DataState from '../../../DataState';
import * as BasicForm from '../../../../elements/forms';
import * as Buttons from '../../../../elements/buttons';
import { Spacing } from '../../../../styles';
import { MentionsInput, Mention } from 'react-mentions';
import { useField } from 'formik';
import './mentions.css';


export const noteSchema = yup.object({
  subject: yup.string().required('Required'),
});


export const Form = () => {
  const USERS = gql`
    query{
      users {
        id
        firstName
        lastName
      }
    }
  `

  const { loading, error, data } = useQuery(USERS)
  const [field, meta, helpers] = useField('note');

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />

  const userData = data.users.map(user => ({
    id: user.id,
    display: `${user.firstName} ${user.lastName}`
  }))

  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="subject"
        type="text"
        label="Subject"
      />
      <MentionsInput
        name="note"
        className="mentions"
        onChange={handleChange}
        value={value}
        allowSpaceInQuery
      >
        <Mention
          type="user"
          trigger="@"
          data={userData}
          markup='@[__display__](__id__)'
          className="mentions__mention"
        />
      </MentionsInput>
      <BasicForm.Checkbox
        name="important"
        type="checkbox"
        label="Important?"
      />
      <BasicForm.Checkbox
        name="collection"
        type="checkbox"
        label="Collection?"
      />
      <BasicForm.Checkbox
        name="corporate"
        type="checkbox"
        label="Corporate Only?"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm >
  );
}
