import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { DEALER_SELECTS } from '../../../queries/selects';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';
import Select from 'react-select';
import { Colors } from '../../../styles';
import { modalBodyStyle } from './Style';

export const ProcessEditTempProgram = props => {
  const { credit, programType, programName, showModal, setShowModal,
    handleSubmit, formSubmitting, effectiveOn
  } = props;

  const { setFieldValue, values, errors, isValid } = useFormikContext();
  const { data, loading, error } = useQuery(DEALER_SELECTS);

  const handleClose = () => setShowModal(false)

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const formattedAutomatedFees = data.automatedFees.map((fee) => {
    return { value: fee.id, label: fee.name };
  })

  const handleChange = (selectedList) => {
    if (!selectedList) {
      setFieldValue('automatedFeeIds', []);
      return
    }

    selectedList = selectedList.map((fee) => {
      return parseInt(fee.value)
    })

    setFieldValue('automatedFeeIds', selectedList);
  }

  const today = new Date()

  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Temp Program
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          {(() =>
            {if(credit === null) {
              return (
                <>
                  <h3>
                    {programType}
                  </h3>
                  <h4>
                    {programName}
                  </h4>
                </>
              )
            } else {
              return (
                <>
                  <h3>
                    Credit Limit Increase
                  </h3>
                  <h4>
                    ${credit}
                  </h4>
                </>
              )
            }
          })()}
          {(() =>
            {if(new Date(effectiveOn) > today) {
              return (
                <>
                  <BasicForm.TextInput
                    name='effectiveOn'
                    type='date'
                    label='Effective On'
                  />
                </>
              )
            }
          })()}
          <BasicForm.TextInput
            name='endOn'
            type='date'
            label='End On'
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel
              type='cancel'
              text='Cancel'
              handleClose={handleClose}
            />
            <Buttons.Standard
              type='submit'
              text='Submit'
              onClick={() => handleSubmit(values)}
              disabled={!isValid || formSubmitting}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
