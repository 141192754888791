import { gql } from '@apollo/client';
import { BANK_ACCOUNTS_SUBQUERY } from '../BankAccounts/queries';
import { NOTES_SUBQUERY } from '../Notes/queries';

const ERRORS = `
  errors {
    path
    message
  }
`;

export const DEALER_MAIN_FIELDS = `
  id
  name
  legalName
  accountNumber
  creditLine
  currentPrincipal
  tempIncreases
  unpaidCount
  unverifiedAuditCarsCount
  market {
    id
    name
  }
  dealerStatus {
    id
    name
    requireCorporateChange
  }
  dealerCategories {
    id
    name
  }
`;

export const DEALER_VARIABLES = `
  $name: String!
  $accountNumber: Int
  $marketId: Int!
  $branchId: Int!
  $accountRepId: Int
  $serviceRepId: Int
  $entityType: String
  $entityTypeOther: String
  $legalName: String
  $licenseNumber: String
  $contractedOn: ISO8601Date
  $licenseExpiresOn: ISO8601Date
  $annualReviewOn: ISO8601Date
  $internalCreditScore: String
  $licenseType: String
  $poaReceived: Boolean
  $recentBusinessTaxYear: String
  $businessFinancialsDate: ISO8601Date
  $recentPersonalTaxYear: String
  $personalFinancialsDate: ISO8601Date
  $dealerLinesAttributes: [DealerLineInput!]
  $dealerCategoryIds: [Int!]
  $borrowedTitleOverride: Boolean
  $borrowedTitleMinimumCars: Int
  $borrowedTitleCountLimit: Int
  $borrowedTitlePrincipalLimit: Decimal
`;

export const DEALER_ARGUMENTS = `
  name: $name
  accountNumber: $accountNumber
  marketId: $marketId
  branchId: $branchId
  accountRepId: $accountRepId
  serviceRepId: $serviceRepId
  entityType: $entityType
  entityTypeOther: $entityTypeOther
  legalName: $legalName
  licenseNumber: $licenseNumber
  contractedOn: $contractedOn
  licenseExpiresOn: $licenseExpiresOn
  annualReviewOn: $annualReviewOn
  internalCreditScore: $internalCreditScore
  licenseType: $licenseType
  poaReceived: $poaReceived
  recentBusinessTaxYear: $recentBusinessTaxYear
  businessFinancialsDate: $businessFinancialsDate
  recentPersonalTaxYear: $recentPersonalTaxYear
  personalFinancialsDate: $personalFinancialsDate
  dealerLinesAttributes: $dealerLinesAttributes
  dealerCategoryIds: $dealerCategoryIds
  borrowedTitleOverride: $borrowedTitleOverride
  borrowedTitleMinimumCars: $borrowedTitleMinimumCars
  borrowedTitleCountLimit: $borrowedTitleCountLimit
  borrowedTitlePrincipalLimit: $borrowedTitlePrincipalLimit
`;

export const HIDE_DEALER = gql`
  mutation HideDealer($id: ID!) {
    hideDealer(id: $id) {
      dealer { id }
      ${ERRORS}
    }
  }
`;

export const UNHIDE_DEALER = gql`
  mutation UnhideDealer($id: ID!) {
    unhideDealer(id: $id) {
      dealer { id }
      ${ERRORS}
    }
  }
`;

export const UPDATE_DEALER_AUTOPAY = gql`
  mutation UpdateDealerAutopay($id: ID!, $autopay: Boolean!) {
    updateDealerAutopay(id: $id, autopay: $autopay) {
      dealer { id }
      ${ERRORS}
    }
  }
`;

export const MAKE_BANK_ACCOUNT_DEALER_PRIMARY = gql`
  mutation MakeBankAccountDealerPrimary($id: ID!) {
    makeBankAccountDealerPrimary(id: $id) {
      bankAccount { id }
      ${ERRORS}
    }
  }
`;

export const DEALER_BANK_ACCOUNTS = gql`
  query Auction($id: ID!, $scopes: [String!]) {
    dealer(id: $id) {
      id
      name
      primaryBankAccount { id }
      ${BANK_ACCOUNTS_SUBQUERY}
    }
  }
`;

export const DEALER_NOTES = gql`
  query Dealer($id: ID!, $scopes: [String!]) {
    dealer(id: $id) {
      id
      name
      ${NOTES_SUBQUERY}
    }
  }
`;

export const DEALER_DELTAS = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      deltas {
        id
        action
        data
        isImport
        createdAt
        user
      }
    }
  }
`;

export const REQUEST_LINE_MOD = gql`
  mutation RequestLineMod(
    $dealerId: ID!
    $type: String!
    $amount: Int!
    $files: [Upload!]!
  ) {
    requestLineMod(
      dealerId: $dealerId
      type: $type
      amount: $amount
      files: $files
    ) {
      recipients
      ${ERRORS}
    }
  }
`;

export const REQUEST_NAP_QUOTE = gql`
  mutation RequestNapQuote(
    $dealerId: ID!
    $files: [Upload!]!
  ) {
    requestNapQuote(
      dealerId: $dealerId
      files: $files
    ) {
      recipients
      ${ERRORS}
    }
  }
`;
