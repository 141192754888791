import React, { useEffect, useState, Fragment } from 'react';
import * as Color from '../../styles/colors';
import * as BasicForm from '../../elements/forms';
import Form from 'react-bootstrap/Form';
import { useFormikContext } from 'formik';


let previousFeeAmount = []
export const handleFeeToggle = (feeDetails, fee, feeIndex, parentIndex, values, setFieldValue, index) => {
  const feeDue = parseFloat(fee.due);
  const feeId = fee.id;

  (feeDue === 0)
    ? (
        setFieldValue(`quoteCarsAttributes[${parentIndex}][feeDetails][${feeIndex}]`, { [feeId]: { amount: previousFeeAmount[index][feeIndex] }}),
        fee.due = parseFloat(previousFeeAmount[index][feeIndex])
      )
    : (
        setFieldValue(`quoteCarsAttributes[${parentIndex}][feeDetails][${feeIndex}]`, { [feeId]: { amount: 0 }}),
        fee.due = 0
      )
  }

const handleChangeFeeAmount = (feeDetails, fee, feeIndex, parentIndex, values, setFieldValue, event) => {
  const feeId = fee.id;
  let feeAmount = event.currentTarget.value

  if (feeAmount === '') feeAmount = 0;

  setFieldValue(`quoteCarsAttributes[${parentIndex}][feeDetails][${feeIndex}]`, { [feeId]: { amount: feeAmount }})
  fee.due = feeAmount;
}

export const FeeDetails = ({ feeDetails, index, disabledItems }) => {
  if (!feeDetails) return null;
  const { values, setFieldValue } = useFormikContext();

  if (!previousFeeAmount[index]) previousFeeAmount[index] = [];

  return feeDetails.map((fee, feeIndex) => {
    const feeName = fee.name;
    let checkedFee = true;

    const formattedAmount = (parseFloat(fee.due) === 0)
      ? null
      : parseFloat(fee.due)

    if (!previousFeeAmount[index][feeIndex] && previousFeeAmount[index][feeIndex] !== null) {
      previousFeeAmount[index][feeIndex] = formattedAmount;
    }

    (parseFloat(fee.due) === 0)
      ? checkedFee = false
      : checkedFee = true;

    return (
      <Fragment>
        <Form.Check
          label={`${fee.name} - $${previousFeeAmount[index][feeIndex]}` || 'Fee'}
          key={feeIndex}
          checked={checkedFee}
          onClick={() => handleFeeToggle(feeDetails, fee, feeIndex, index, values, setFieldValue, index)}
          disabled={disabledItems}
        />
        { values.quoteCarsAttributes[index].paymentType === 'partial' &&
        <BasicForm.WriteOffInput
          name={`quoteCarsAttributes[${index}][${feeDetails}][${feeIndex}].amount`}
          defaultValue={values.quoteCarsAttributes[index].feeDetails[feeIndex][fee.id].amount}
          type="number"
          onChange={(event) => handleChangeFeeAmount(feeDetails, fee, feeIndex, index, values, setFieldValue, event)}
          // onBlur={handleBlur}
          disabled={disabledItems}
        />
        }
      </Fragment>
    )
  })
}

export const feeButtonStyle = {
  color: Color.xlGreen,
  width: '20px',
}
