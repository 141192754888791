import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useMutation } from '@apollo/client';
import { CustomizableDropzone } from 'components/src/elements/Dropzone';
import { StyledLabel } from 'components/src/elements/forms';
import { Spacing } from 'components/src/styles';
import * as Buttons from 'components/src/elements/buttons';
import { REQUEST_NAP_QUOTE } from './Queries';

const RequestNAPQuote = ({closeModal, setAlertData}) => {
  const dealerId = useParams().id;
  const [files, setFiles] = useState([]);

  const [requestQuote, { loading }] = useMutation(
    REQUEST_NAP_QUOTE,
    {
      onCompleted: data => {
        closeModal();
        setAlertData({
          content: 'NAP Quote Request Email has been sent to ' +
            data.requestNapQuote.recipients.join(', ')
        });
      }
    }
  );

  const handleSubmit = e => {
    e.stopPropagation();
    requestQuote({ variables: { dealerId, files } });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={closeModal}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Request NAP Flooring Quote
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Submitting this form will send an email to Dealer's Account Manager,
        with the uploaded file(s) attached. You must upload the Bill of
        Sale/purchase document and a copy of the title.

        <StyledLabel>
          Bill of Sale/Purchase Document and Copy of Title
        </StyledLabel>
        <CustomizableDropzone
          accept=".pdf, image/*"
          description="Drag and drop files here, or click to choose files."
          setFiles={setFiles}
        />
        <div style={Spacing.buttonRow}>
          <Buttons.ModalCancel
            type="cancel"
            text="Cancel"
            handleClose={closeModal}
          />
          <Buttons.Standard
            type="submit"
            text="Submit"
            onClick={handleSubmit}
            disabled={0 === files.length}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestNAPQuote;
