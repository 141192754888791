import React, { useState } from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import { HIDE_BANK_ACCOUNT, UNHIDE_BANK_ACCOUNT } from './queries';
import {
  MAKE_BANK_ACCOUNT_DEALER_PRIMARY
} from 'components/src/components/Dealers/Queries';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { titleCase } from 'components/src/utils/stringHelpers';
import { preflightTableStyles } from 'components/src/styles';
import { setTime } from 'components/src/utils/dateHelpers';
import AuthLink from 'components/src/components/AuthLink';

const BankAccountsTable = props => {
  const { query, prefix, queryName, id, makePrimaryMutation } = props;

  const [showHidden, setShowHidden] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    query,
    { variables: { id, scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_BANK_ACCOUNT, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_BANK_ACCOUNT, { onCompleted: refetch });

  const [makePrimary] = useMutation(
    MAKE_BANK_ACCOUNT_DEALER_PRIMARY,
    { onCompleted: refetch }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const bankAccounts = data[queryName].bankAccounts;

  const columns = [
    {
      key: 'accountName',
      label: 'Account Name',
      sort: 'asc',
    },
    {
      key: 'bankName',
      label: 'Bank Name',
    },
    {
      key: 'routingNumberMasked',
      label: 'Routing Number',
    },
    {
      key: 'accountNumberMasked',
      label: 'Account Number',
    },
    {
      key: 'type',
      label: 'Type',
      value: ({ value }) => titleCase(value),
    },
    {
      key: 'createdBy',
      label: 'Created By',
      value: ({ value }) => value.fullName,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      value: ({ value }) => setTime(value),
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      value: ({ value }) => setTime(value),
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix={prefix}
          resource={entry}
          hide={hide}
          unhide={unhide}
          noRead
          noEdit
        >
          {data.dealer && entry.id !== data.dealer.primaryBankAccount?.id && (
            <AuthLink
              style={{paddingLeft: 5}}
              to="#"
              resource="BankAccount"
              action="update"
              onClick={() => makePrimary({ variables: { id: entry.id } })}
            >
              Make Primary |
            </AuthLink>
          )}
        </ResourceActionLinks>
      )
    },
  ];

  if ('dealer' === queryName) {
    const isPrimaryColumn = {
      key: 'isPrimary',
      label: 'Primary?',
      value: ({ entry }) => (
        entry.id === data.dealer.primaryBankAccount?.id ? 'Yes' : ''
      )
    }

    columns.splice(4, 0, isPrimaryColumn);
  }

  return (
    <>
      <ResourcesHeader
        title="Bank Accounts"
        prefix={prefix}
        resourceType="BankAccount"
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />

      <DataTables.Simple
        data={bankAccounts}
        columns={columns}
        pageLength={10}
        htmlTable
        movableColumns
        multiSort
        styles={preflightTableStyles}
      />
    </>
  );
};

export default BankAccountsTable;
