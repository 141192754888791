import React, { useContext, useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import DataState from '../../DataState';
import DataTable from '../../../elements/DataTable';
import New from './New';
import { DateHelpers } from '../../../utils';
import { TXN } from '../Show';
import Show from './Show';
import AuthContext from '../../../contexts/AuthContext';

const DESTROY_FILE_UPLOAD = gql`
  mutation destroyFileUpload($id: ID!) {
    destroyFileUpload(
      id: $id
    ) {
      errors { message, path }
    }
  }
`

const TransactionFiles = ({ data }) => {
  const [showView, setShowView] = useState(false);
  const [show, setShow] = useState(false);
  const [fileId, seFileId] = useState()
  const [creatable, setCreatable] = useState(true)
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const auth = useContext(AuthContext);

  const ownerId = parseInt(useParams().id)

  const [destroyFileUpload] = useMutation(DESTROY_FILE_UPLOAD,
    {
      awaitRefetchQueries: true,
      refetchQueries: TXN,
      onCompleted(data) {
        window.location.reload()
      }
    }
  )

  const columns = [
    {
      title: 'Name'
    },
    {
      title: 'Created By'
    },
    {
      title: 'Created At',
      render: DateHelpers.renderDate
    },
    {
      title: 'Actions'
    }
  ]


  $(document).unbind().on('click', '#deleteFile', function (event) {
    let id = parseInt(event.target.dataset.id)
    if (window.confirm('Are you sure you want to delete this file?')) {
      destroyFileUpload({ variables: { id: id } })
    } else {
      event.preventDefault();
    }
  })

  $(document).on('click', '#showFile', function (event) {
    let id = parseInt(event.target.dataset.id)
    setShowView(true)
    seFileId(id)
  })

  useEffect(() => {
    if (auth.hasPolicy('FileUpload', 'create')) {
      setCreatable(false)
    }
  })

  const dataSet = data.txn.fileUploads.map((file) => {
    const actions = []

    if (auth.hasPolicy('FileUpload', 'update')) {
      actions.push(`<a href="#" id="deleteFile" data-id="${file.id}">Delete</a>`)
    }

    if (auth.hasPolicy('FileUpload', 'read')) {
      actions.push(`<a href="#" id="showFile" data-id="${file.id}">View</a>`)
    }

    return [
      file.name,
      file.createdBy.fullName,
      DateHelpers.setDate(file.createdAt),
      actions.join(" | ")
    ]
  })

  return (

    <div>
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Files'}
        unhideable={true}
        handleShowModal={handleShow}
        uncreatable={creatable}
      />
      { showView && <Show showView={showView} setShowView={setShowView} fileId={fileId} /> }
      { show && <New handleClose={handleClose} show={show} ownerId={ownerId} /> }
    </div>
  )
}

export default TransactionFiles;
