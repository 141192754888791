import React from "react";
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import Deltas from 'components/src/components/Deltas';
import DataState from 'components/src/components/DataState';
import DealerLineDeltas from '../Lines/Deltas';
import { DEALER_DELTAS } from '../Queries';

const DealerDeltas = () => {
  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(
    DEALER_DELTAS, { variables: { id } }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

 const { name, deltas } = data.dealer;

  return (
    <>
      <Deltas
        resourceName={name}
        deltas={deltas}
      />

      <DealerLineDeltas />
    </>
  );
};

export default DealerDeltas;
