import React, { useContext, useEffect, useState } from 'react';
import BasicAlert from '../../../elements/BasicAlert';
import { useAlert } from '../../../hooks/useAlert';
import { gql } from '@apollo/client';
import DataTable from '../../../elements/DataTable';
import AuthContext from '../../../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import { useHideableResource } from '../../../hooks/useHideableResource';
import { setAlertData } from '../../../utils/setAlertData';
import DataState from '../../DataState';

export const FEE_PROGRAMS = gql`
  query {
    feePrograms {
      id
      name
      type
      activeDealerCount { name, id }
      activeFlooredCarCount { name, id }
      canBeHidden
      feeLines {
        id
        principalBelow
        extensionFeeFlat
        extensionFeeInterval
        extensionInterval
        extensionAccount { id, name }
        feeLineTerms {
          id
          amount
          interval
          account { id, name }
        }
      }
    }
  }
`

const HIDDEN_FEE_PROGRAMS = gql`
  query {
    feePrograms(scopes: ["hidden"]) {
      id
      name
      type
      activeDealers
      activeFlooredCars
      feeLines {
        id
        principalBelow
        extensionFeeFlat
        extensionFeeInterval
        extensionInterval
        extensionAccount { id, name }
        feeLineTerms {
          id
          amount
          interval
          account { id, name }
        }
      }
    }
  }
`

const HIDE_FEE_PROGRAM = gql`
  mutation HideFeeProgram($id: ID!) {
    hideFeeProgram(
      id: $id
    ) {
      feeProgram {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const UNHIDE_FEE_PROGRAM = gql`
  mutation UnhideFeeProgram($id: ID!) {
    unhideFeeProgram(
      id: $id
    ) {
      feeProgram {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const FeePrograms: (props) => React$Node = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const auth = useContext(AuthContext);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_FEE_PROGRAMS,
    VISIBLE_QUERY: FEE_PROGRAMS,
    HIDE_MUTATION: HIDE_FEE_PROGRAM,
    UNHIDE_MUTATION: UNHIDE_FEE_PROGRAM,
    variables: {},
  };

  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)

  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const columns = [
    {
      title: 'Name'
    },
    {
      title: 'Type'
    },
    {
      title: 'Number of Terms'
    },
    {
      title: 'Active Dealers'
    },
    {
      title: 'Active Cars'
    },
    {
      title: "Actions"
    }
  ]

  const dataSet = data.feePrograms.map((program) => {
    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${program.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${program.id}>Hide</a>`

    const canUseActions = program.canBeHidden;

    const actions = []

    if (auth.hasPolicy('FeeProgram', 'update')) {
      actions.push(`<a href="/system/fee-programs/${program.id}">Edit</a>`)
    }

    if (auth.hasPolicy('FeeProgram', 'hide')) {
      actions.push(hiddenLink)
    }

    if (actions === []){
      actions.push(' ')
    }

    let numberOfTerms = 0;

    if (program.feeLines[0] && program.feeLines[0].feeLineTerms) {
      numberOfTerms = program.feeLines[0].feeLineTerms.length;
    }

    return [
      program.name,
      program.type,
      numberOfTerms,
      program.activeDealers.length,
      program.activeFlooredCars.length,
      actions.join(" | "),
    ]
  })

  return (
    <>
      <Helmet>
        <title>Fee Programs</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="fee program" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Fee Programs'}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
    </>
  );
};

export default FeePrograms;
