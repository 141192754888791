import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { DEALER_SELECTS } from '../../../queries/selects';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';
import Select from 'react-select';
import { Colors } from '../../../styles';
import { modalBodyStyle, customStyles } from './Style';

export const ProcessTempProgram = props => {
  const { showModal, setShowModal, handleSubmit } = props;
  const { setFieldValue, values, errors, isValid, dirty } = useFormikContext();
  const { data, loading, error } = useQuery(DEALER_SELECTS);
  const [formSubmitting, setFormSubmitting] = useState()

  const handleClose = () => setShowModal(false)

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const formattedAutomatedFees = data.automatedFees.map((fee) => {
    return { value: fee.id, label: fee.name };
  })

  const handleChange = (selectedList) => {
    if (!selectedList) {
      setFieldValue('automatedFeeIds', []);
      return
    }

    selectedList = selectedList.map((fee) => {
      return parseInt(fee.value)
    })

    setFieldValue('automatedFeeIds', selectedList);
  }

  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            New Temp Program
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <BasicForm.ProgrammaticSelect
            name="rateProgramId"
            label="Rate Program"
            optionName="Rate Program"
            options={data.ratePrograms}
          />
          <BasicForm.ProgrammaticSelect
            name="dealerProgramId"
            label="Dealer Program"
            optionName="Dealer Program"
            options={data.dealerPrograms}
          />
          <BasicForm.StyledLabel>Automated Fees</BasicForm.StyledLabel>
          <Select
            options={formattedAutomatedFees}
            isMulti={true}
            onChange={handleChange}
            styles={customStyles}
          />
          <BasicForm.TextInput
            name='creditLimitIncrease'
            type='number'
            label='Credit Limit Increase'
          />
          <BasicForm.TextInput
            name='effectiveOn'
            type='date'
            label='Effective On'
          />
          <BasicForm.TextInput
            name='endOn'
            type='date'
            label='End On'
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel
              type='cancel'
              text='Cancel'
              handleClose={handleClose}
            />
            <Buttons.Standard
              type='submit'
              text='Submit'
              onClick={() => handleSubmit(values)}
              disabled={!isValid || !dirty}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
