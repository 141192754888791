import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import * as Buttons from '../../elements/buttons';
import * as BasicForm from '../../elements/forms';
import { Spacing, Typography } from '../../styles';
import DataState from '../DataState';
import { StringHelpers } from '../../utils/';
import { equalTo } from '../../utils/passwordMatchValidation';
import { CURRENT_USER } from '../../queries/currentUser';
import { CustomAlert } from '../../elements/BasicAlert';

const SEND_MOBILE_CODE = gql`
  mutation SendMobileCode($userProvidedPhoneNumber: String!, $code: String) {
    updateMobile(
      userProvidedPhoneNumber: $userProvidedPhoneNumber
      code: $code
    ) {
      codeSent
      twilioStatus
      newPhoneNumber
    }
  }
`;

const UpdateMobile = () => {
  const history = useHistory();
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [showError, setShowError] = useState(null);

  const [updateMobile, { data, loading, error }] = useMutation(SEND_MOBILE_CODE, {
    onCompleted(values) {
      const twilioStatus = values.updateMobile.twilioStatus;

      if (twilioStatus === 'blacklisted') {
        setShowError(
          'The phone number given has opted out of receiving messages from XL Funding - if this was in error, please text START to (317-751-2161)'
        )

        return
      } else if (twilioStatus === 'no-response') {
        setShowError(
          'The provided phone number was unable to receive texts - please check that it is correct, and is a mobile number capable of receiving SMS messages.'
        )
      }
      setShowCodeInput(true);
    }
  })

  const [updateMobileWithCode] = useMutation(SEND_MOBILE_CODE,
    {
      awaitRefetchQueries: true,
      retetchQueries: [
        {
          query: CURRENT_USER
        }
      ],
      onCompleted() {
        history.push({
          pathname: '/',
          state: { alert: true, type: 'changed', componentType: 'mobile number' },
        })
        window.location.reload()
      }
    }
  );

  const updateMobileSchema = yup.object({
    userProvidedPhoneNumber: yup.string().required("required").
      matches(StringHelpers.phoneRegExp, {
        message: "Please enter a valid phone number.",
        excludeEmptyString: false
      }),
  });

  const MobileForm = () => {
    // TODO: use a phone input instead of a text input for proper phone styling,
    // but getting an error I don't have time to solve right now.

    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Update Mobile Number</h1>
          <h2 style={Typography.formSubHeader}>
            This number will be used for SMS login validation - Please make sure
            it is a working mobile number
          </h2>
        </div>
        <Formik
          initialValues={{
            userProvidedPhoneNumber: data?.updateMobile?.newPhoneNumber,
            code: '',
          }}
          validationSchema={updateMobileSchema}
          onSubmit={handleSubmit}
        >
          <FormikForm>
            <BasicForm.TextInput
              format="(###) ###-####"
              name='userProvidedPhoneNumber'
              type='text'
              label="Mobile Phone"
            />
            {showCodeInput
              ?
              <BasicForm.TextInput
                name='code'
                type='text'
                label="Verification Code (texted to the number provided)"
              /> : null
            }
            <div style={Spacing.buttonRow}>
              <Buttons.Cancel type="cancel" text="Cancel" />
              <Buttons.Standard type="submit" text="Submit" />
            </div>
          </FormikForm>
        </Formik>
      </div>
    )
  }

  const handleSubmit = (values) => {
    if (showCodeInput) {
      updateMobileWithCode({ variables: values });
    } else {
      updateMobile({ variables: values })
    }
  };

  return (
    <>
      { showError && <CustomAlert message={showError} />}
      <div style={Spacing.formContainer}>
        <MobileForm />
      </div>
    </>
  );
}

export default UpdateMobile;
