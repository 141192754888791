import React, { useState } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { useAlert } from '../../../hooks/useAlert';
import BasicAlert from '../../../elements/BasicAlert';
import * as yup from 'yup';
import '../../../styles/feePrograms.css';
import { accountBySpecial } from '../../../utils/accountHelpers';
import { Typography, Spacing } from '../../../styles';
import { IntervalFeeProgramTable } from './IntervalFeeProgramTable';
import { SingleFeeProgramTable } from './SingleFeeProgramTable';
import { FEE_PROGRAMS } from './index';
import DataState from '../../DataState';
import Loading from '../../../elements/Loading';
import {
  nullable,
  setExtensionAccountId,
  setTermAttributeAccountId,
  setNullAttributes
} from './functionality';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'

const ACCOUNTS = gql`
query {
  accounts(scopes: ["income"])
  { id, gl, name, special { key } }
}`

const NEW_FEE_PROGRAM = gql`
  mutation CreateFeeProgram($name: String!, $type: String!, $feeLinesAttributes: [FeeLineInput!]!) {
    createFeeProgram(
      name: $name
      type: $type
      feeLinesAttributes: $feeLinesAttributes
    ) {
      feeProgram {
        id
        name
        feeLines {
          id
        }
      }
      errors {
        message
        path
      }
    }
  }
`

const NewFeeProgram = () => {
  const { loading, error, data } = useQuery(ACCOUNTS);
  const customSubmitHandler = (values) => {
    values = setExtensionAccountId(values);
    values = setTermAttributeAccountId(values);

    values.feeLinesAttributes = setNullAttributes(values.feeLinesAttributes, nullable);
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: NEW_FEE_PROGRAM,
    onCompleteRedirectTo: '/system/fee-programs',
    customSubmitHandler: customSubmitHandler
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const [showAlert, displayAlert] = useAlert();

  const schema = yup.object({
    name: yup.string().max(80).required('Required'),
    type: yup.string().required('Required'),
    feeLinesAttributes: yup.array().of(
      yup.object({
        principalBelow: yup.number().min(0, 'Must be positive').nullable(),
        extensionFeeFlat: yup.number().min(0, 'Must be positive').nullable(),
        extensionInterval: yup.number().min(0, 'Must be positive').integer('Must be an integer').nullable(),
        extensionAccountId: yup.mixed().notOneOf(['']).required('Required'),
        extensionFeeInterval: yup.number().min(0, 'Must be positive').nullable(),
        feeLineTermsAttributes: yup.array().of(
          yup.object({
            amount: yup.number().min(1, 'Must greater than 0').required('Required'),
            interval: yup.number().min(0, 'Must be positive').integer('Must be an integer').nullable(),
            accountId: yup.mixed().typeError('Required')
          })
        )
      })
    )
  });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />

  const termAccount = accountBySpecial(data.accounts, 'default_term_fee');
  const extensionAccount =
    accountBySpecial(data.accounts, 'default_extension_fee');

  function FeeProgramForm() {
    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          name: '',
          type: '',
          feeLinesAttributes: [
            {
              principalBelow: '',
              extensionFeeFlat: 0,
              extensionInterval: 0,
              extensionAccountId: extensionAccount.id,
              extensionFeeInterval: 0,
              feeLineTermsAttributes: [
                {
                  amount: 0,
                  interval: 0,
                  accountId: termAccount.id
                }
              ]
            }
          ]
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setValues
        }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
              <div style={Spacing.formWrapper}>
                <div style={Spacing.formHeader}>
                  <h1 style={Typography.formHeader}>Create a Fee Program</h1>
                </div>
              </div>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as="select"
                  type="text"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  isValid={!errors.type && touched.type}
                  isInvalid={!!errors.type && touched.type}
                  placeholder="select"
                >
                  <option value="">Please Select a Fee Type</option>
                  <option value="Single">Fees Added on First Day of Term</option>
                  <option value="Interval">Interval Fee</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.type}
                </Form.Control.Feedback>
                {(!values.type) && <Text>Please select a fee type to display options</Text>}
                {(!errors.type && values.type === 'Interval') && <IntervalFeeProgramTable feeLinesAttributes={values.feeLinesAttributes} values={values} setValues={setValues} />}
                {(!errors.type && values.type === 'Single') && <SingleFeeProgramTable feeLinesAttributes={values.feeLinesAttributes} values={values} setValues={setValues} />}
              </Form.Group>
            </Form>
          )}
      </Formik>
    )
  }

  const LoadedContent = () => {
    return (
      <>
        {showAlert &&
          <BasicAlert componentType="fee program" type="create" />
        }
        <ServerSideErrors />
        <FeeProgramForm />
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }


  return (
    <>
      <Helmet>
        <title>New Fee Program</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default NewFeeProgram;
