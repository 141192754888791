import React, { useState } from 'react';
import { setDate } from 'components/src/utils/dateHelpers';
import { currency } from 'components/src/utils/stringHelpers';
import { CANCEL_ACH } from './Queries'
import { useMutation } from '@apollo/client';
import { MediumRowLoading } from 'components/src/elements/Loading';
import moment from 'moment';
import {
  dealerColumn
} from 'components/src/components/Dealers/columnDefinitions';

export const columnDefinitions = props => {
  const { refetch, setAchAlert, columnKeys } = props;
  const columnChanges = props.columnChanges || [];

  const definitions = [
    {
      key: 'warnings',
      label: 'Warnings',
      value: ({ entry }) => {
        const carWarnings = []

        if (entry.repoSoldOn != null) {
          carWarnings.push('RL')
        } else if (entry.repoOn != null) {
          carWarnings.push('Repo')
        }

        if (entry.arbitratedOn != null) {
          carWarnings.push('Arbitrated')
        }

        if (entry.sotOn != null) {
          carWarnings.push('SOT')
        }

        if (entry.scheduledAchs > 0) {
          carWarnings.push('ACH')
        }

        const displayCarWarnings = carWarnings.join(" | ")

        return displayCarWarnings
      },
      content: ({ value }) => value
    },
    {
      key: 'newAuditCode',
      label: 'Audit',
      content: ({ entry, value }) => {
        return (
          <a
            title={entry.qtStatus}
            href={`${entry.dealer.id}/cars/${entry.id}/audits`}
          >
            {value}
          </a>
        )
      }
    },
    {
      key: 'unitNumber',
      label: 'Unit #',
    },
    {
      key: 'vin',
      label: 'VIN',
      content: ({ entry, value }) => (
        <a href={`/dealers/${entry.dealer.id}/cars/${entry.id}`}>{value}</a>
      )
    },
    {
      key: 'year',
      label: 'Year',
    },
    {
      key: 'make',
      label: 'Make',
    },
    {
      key: 'model',
      label: 'Model',
      minViewportWidth: 2300
    },
    {
      key: 'color',
      label: 'Color',
      minViewportWidth: 2300
    },
    {
      key: 'age',
      label: 'Days on Floorplan',
      cellStyle: { textAlign: 'right' },
      value: ({ entry }) => entry.termData.totalDays
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'payoffOn',
      label: 'Payoff Date',
      content: ({ entry }) => setDate(entry.payoffOn)
    },
    {
      key: 'fundedOn',
      label: 'Funded Date',
      minViewportWidth: 2200,
      content: ({ entry }) => setDate(entry.fundedOn)
    },
    {
      key: 'loanDate',
      label: 'Loan Date',
      content: ({ entry }) => setDate(entry.loanDate)
    },
    {
      key: 'currentDueOn',
      label: 'Next Due Date',
      sort: 'asc',
      content: ({ value, entry }) => {
        const nextDueDate = setDate(value) || '';

        if (moment().diff(value, 'days') > 0 && 'Unpaid' === entry.status) {
          return <span style={{color: 'red'}}>{nextDueDate}</span>;
        }

        return nextDueDate;
      }
    },
    {
      key: 'blackbookValue',
      label: 'Black Book Value',
      cellStyle: { textAlign: 'right' },
      minViewportWidth: 1800,
      value: ({ entry }) => (
        entry.blackbookValue ? parseFloat(entry.blackbookValue) : 0.0
      ),
      content: ({ value }) => value ? currency(value) : ''
    },
    {
      key: 'currentPrincipal',
      label: 'Remaining Principal',
      cellStyle: { textAlign: 'right' },
      value: ({ entry }) => parseFloat(entry.currentPrincipal),
      content: ({ value }) => currency(value)
    },
    {
      key: 'loanAmount',
      label: 'Loan Amount',
      cellStyle: { textAlign: 'right' },
      value: ({ entry }) => parseFloat(entry.loanAmount),
      content: ({ value }) => currency(value)
    },
    {
      key: 'currentFees',
      label: 'Fees Due',
      cellStyle: { textAlign: 'right' },
      minViewportWidth: 1600,
      value: ({ entry }) => parseFloat(entry.currentFees),
      content: ({ value }) => currency(value)
    },
    {
      key: 'currentInterest',
      label: 'Interest Due',
      cellStyle: { textAlign: 'right' },
      minViewportWidth: 1600,
      value: ({ entry }) => parseFloat(entry.currentInterest),
      content: ({ value }) => currency(value)
    },
    {
      key: 'currentCollateralCoverage',
      label: 'Collateral Coverage',
      cellStyle: { textAlign: 'right' },
      minViewportWidth: 1600,
      value: ({ entry }) => parseFloat(entry.currentCollateralCoverage),
      content: ({ value }) => currency(value)
    },
    {
      key: 'totalDue',
      label: 'Total Due',
      cellStyle: { textAlign: 'right' },
      value: ({ entry }) => {
        return (parseFloat(entry.currentFees) +
          parseFloat(entry.currentInterest) +
          parseFloat(entry.currentPrincipal) +
          parseFloat(entry.currentCollateralCoverage)
        )
      },
      content: ({ value }) => currency(value)
    },
    {
      key: 'sourceName',
      label: 'Source',
      minViewportWidth: 2000
    },
    {
      key: 'titleStatus',
      label: 'Title Status',
      minViewportWidth: 2000
    },
    {
      key: 'titleStatusOn',
      label: 'Title Status Date',
      content: ({ value }) => setDate(value),
    },
    {
      key: 'borrowedTitlePastDue',
      label: 'Days Past Due (Title)',
    },
    {
      key: 'borrowedTitleDays',
      label: 'Days Since Title Borrowed',
      value: ({ entry }) => entry.titleStatusDays,
    },
    {
      key: 'achStatus',
      label: 'ACH',
      value: ({ entry }) => entry.scheduledAchs > 0 ? 'Pending' : ''
    },
    {
      key: 'achButton',
      label: 'ACH',
      value: ({ entry }) => {
        if (entry.scheduledAchs > 0) {
          return 'Cancel ACH'
        }
      },
      content: ({ entry }) => {
        const [loading, setLoading] = useState(false)

        const [cancelAch] = useMutation(CANCEL_ACH, {
          onCompleted(values) {
            refetch().then(() => { setLoading(false); setAchAlert(true) })
          }
        })

        const handleAch = () => {
          if (confirm('Are you sure you want to cancel this pending ACH?')) {
            setLoading(true)
            cancelAch({ variables: { id: entry.scheduledAchId } })
          }
        }

        if (entry.scheduledAchs > 0) {
          return loading ? <MediumRowLoading /> :
            <a href="#" onClick={() => handleAch()}>Cancel ACH</a>
        }
      }
    },
    {
      key: 'market',
      label: 'Market',
      value: ({ value }) => value.name,
    },
    {
      key: 'dealer',
      label: 'Dealer Name',
      value: ({ value }) => value.name,
      content: ({ entry, value }) => (
        <a href={`/dealers/${entry.dealer.id}`}>{value}</a>
      )
    },
    {
      key: 'dealerNumber',
      label: 'Dealer Number',
      value: ({ entry }) => entry.dealer.accountNumber,
    },
    {
      key: 'dealerStatus',
      label: 'Dealer Status',
      value: ({ entry }) => entry.dealer.dealerStatus.name,
      minViewportWidth: 1200,
    },
    {
      key: 'functions',
      label: 'Functions',
      sort: 'prevent',
      content: ({ entry }) => (
        <a href={`/dealers/${entry.dealer.id}/cars/${entry.id}`}>View</a>
      )
    },
    {
      key: 'comingDueActions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <a href={`/dealers/${entry.dealer.id}/cars/${entry.id}/payments`}>
          Setup Payment
        </a>
      )
    },
    dealerColumn('legalName'),
    dealerColumn('dealerCategories'),
    dealerColumn('creditLine', { key: 'dealerCreditLine' }),
    dealerColumn('currentPrincipal', { key: 'dealerCurrentPrincipal' }),
    dealerColumn('unpaidCount', { key: 'dealerUnpaidCount' }),
    dealerColumn('unverifiedAuditCarsCount'),
  ];

  return columnKeys.map(key => {
    const column = definitions.find(definition => definition.key === key);
    const changes = columnChanges.find(definition => definition.key === key);

    return {...column, ...changes};
  });
};
