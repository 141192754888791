import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Formik, Form as FormikForm } from 'formik';
import Modal from 'react-bootstrap/Modal';
import DataState from '../DataState';
import { Spacing } from '../../styles';
import * as Buttons from '../../elements/buttons';
import * as BasicForm from "../../elements/forms";

const DEALER_STATUSES = gql`
  query{
    dealerStatuses {
      id
      name
    }
  }
`;

const UPDATE_DEALER_STATUS = gql`
  mutation UpdateDealerStatus(
    $dealerId: ID!
    $dealerStatusId: Int!
    $memo: String!
  ) {
    updateStatusOnDealer(
      dealerId: $dealerId
      dealerStatusId: $dealerStatusId
      memo: $memo
    ) {
    errors {
      message
      path
    }}
  }
`

const UpdateStatusForm = ({ setShowDealerStatusModal, currentStatusId, handleClose }) => {
  const id = parseInt(useParams().id) || parseInt(useParams().ownerId)
  const { loading: statusLoading, error: statusError, data: statusData } = useQuery(DEALER_STATUSES);
  const [updateDealerStatus] = useMutation(UPDATE_DEALER_STATUS, {
    onCompleted(values) {
      setShowDealerStatusModal(false)
      window.location.reload();
    }
  })

  if (statusLoading) return <DataState.Loading />
  if (statusError) return <DataState.Error error={error} />;
  const handleSubmit = (values) => {
    updateDealerStatus({ variables: values })
  }

  return (
    <Formik
      initialValues={{
        dealerId: id,
        dealerStatusId: parseInt(currentStatusId),
        memo: '',
      }}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <BasicForm.ProgrammaticSelect
          name="dealerStatusId"
          label="Dealer Status"
          optionName="Status"
          options={statusData.dealerStatuses}
        />
        <BasicForm.TextArea
          name="memo"
          type="text"
          label="Memo"
        />
        <div style={Spacing.buttonRow}>
          <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose} />
          <Buttons.Standard
            type="submit"
            text="Update Status"
          />
        </div>
      </FormikForm>
    </Formik >
  )
}

const UpdateDealerStatus = ({ showDealerStatusModal, setShowDealerStatusModal, currentStatus }) => {
  const handleClose = () => {
    setShowDealerStatusModal(false);
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={showDealerStatusModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {'Update Dealer Status'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UpdateStatusForm
          showDealerStatusModal={showDealerStatusModal}
          setShowDealerStatusModal={setShowDealerStatusModal}
          currentStatusId={currentStatus}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  )
}

export default UpdateDealerStatus;
