import React, { useEffect, useState } from 'react';
import { Typography } from '../../styles';
import Table from 'react-bootstrap/Table';
import { useFormikContext } from 'formik';
import { Form as FormikForm } from 'formik';
import * as StringHelper from '../../utils/stringHelpers';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import { Colors } from '../../styles';
import './payments.css';
import * as yup from 'yup';
import { StyledErrorMessage } from '../../elements/forms';
import { FormBody } from './FormBody';
import { SortButtons, handleSort } from './Sort';

export const schema = yup.object({
})

export const formNumberStyle = {
  textAlign: 'right',
}

const columnHeaderStyle = {
  minWidth: '130px',
  borderBottom: 'none',
}

const tableHeaderStyle = {
   padding: "0px",
   margin: "0px"
}

export const Form = ({ data, setOrderedData }) => {
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [orderedColumn, setOrderedColumn] = useState(null);
  const [reordered, setReordered] = useState(false);
  const { values, errors } = useFormikContext();
  const [checking, setChecking] = useState(false);
  const [checkPrincipal, setCheckPrincipal] = useState(true);
  const [zeroPrincipal, setZeroPrincipal] = useState(false);
  const [checkInterest, setCheckInterest] = useState(true);
  const [zeroInterest, setZeroInterest] = useState(false);
  const [checkInsurance, setCheckInsurance] = useState(true);
  const [zeroInsurance, setZeroInsurance] = useState(false);
  const [checkFee, setCheckFee] = useState(true);
  const [zeroFee, setZeroFee] = useState(false);

  if (reordered) {
    delete values.quoteCarsAttributes;
    delete values.maxPrincipal;
  }

  const checkAll = () => {
    setChecking(!checking)
  }

  const checkAllFees = () => {
    setZeroFee(!zeroFee)
  }

  const zeroPrincipalAmount = () => {
    setZeroPrincipal(!zeroPrincipal)
  }

  const zeroInterestAmount = () => {
    setZeroInterest(!zeroInterest)
  }

  const zeroInsuranceAmount = () => {
    setZeroInsurance(!zeroInsurance)
  }

  return (
    <FormikForm>
    <div style={tableTopDivStyle}>
      <BasicForm.CustomStyleTextInput
        name="paymentDate"
        type="date"
        divStyle={inputDivStyle}
        label="Write Off Date"
      />
      <BasicForm.CustomStyleTextInput
        name="search"
        type="search"
        style={searchStyle.input}
        divStyle={searchStyle.div}
        labelStyle={searchStyle.label}
        label="Search"
      />
    </div>
    <br/>
    <Table striped hover responsive border="0">
      <thead style={{ height: "2px" }}>
        <tr width="10px">
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}>
            <BasicForm.Checkbox
              id="principal"
              name="ZeroPrincipal"
              type="checkbox"
              label="Zero Out"
              onClick={() => zeroPrincipalAmount()}
            />
          </th>
          <th style={tableHeaderStyle}>
            <BasicForm.Checkbox
              id="fees"
              name="UncheckFees"
              type="checkbox"
              label="Uncheck"
              onClick={() => checkAllFees()}
            />
          </th>
          <th style={tableHeaderStyle}>
            <BasicForm.Checkbox
              id="interest"
              name="ZeroInterest"
              type="checkbox"
              label="Zero Out"
              onClick={() => zeroInterestAmount()}
            />
          </th>
          <th style={tableHeaderStyle}>
            <BasicForm.Checkbox
              id="collateral"
              name="ZeroCollateral"
              type="checkbox"
              label="Zero Out"
              onClick={() => zeroInsuranceAmount()}
            />
          </th>
          <th style={tableHeaderStyle}></th>
          <th style={tableHeaderStyle}>
            <BasicForm.Checkbox
              id="allCheckbox"
              name="CheckAll"
              type="checkbox"
              label="Check All"
              onClick={() => checkAll()}
            />
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th
            className='ordered-column'
            style={Object.assign({}, columnHeaderStyle, {minWidth: '90px'})}
            onClick={() => handleSort(data, 'unitNumber', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Unit
            <SortButtons
              columnName={'unitNumber'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th style={columnHeaderStyle}>VIN</th>
          <th
            className='ordered-column'
            style={Object.assign({}, columnHeaderStyle, {minWidth: '90px'})}
            onClick={() => handleSort(data, 'year', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Year
            <SortButtons
              columnName={'year'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th style={columnHeaderStyle}>Make</th>
          <th style={columnHeaderStyle}>Model</th>
          <th
            className='ordered-column'
            style={Object.assign({}, columnHeaderStyle, {minWidth: '150px'})}
            onClick={() => handleSort(data, 'loanDate', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Loan Date
            <SortButtons
              columnName={'loanDate'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th
            className='ordered-column'
            style={columnHeaderStyle}
            onClick={() => handleSort(data, 'currentDueOn', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Due Date
            <SortButtons
              columnName={'currentDueOn'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th
            className='ordered-column'
            style={Object.assign({}, formNumberStyle, {minWidth: '150px'})}
            onClick={() => handleSort(data, 'totalDays', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Age (days)
            <SortButtons
              columnName={'totalDays'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th>Arbitrated?</th>
          <th>Principal</th>
          <th>Fees Due</th>
          <th style={formNumberStyle}>Interest Due</th>
          <th style={formNumberStyle}>Collateral Coverage</th>
          <th style={Object.assign({}, formNumberStyle)}>Total Due</th>
        </tr>
      </thead>
      <tbody>
        <FormBody
          data={data}
          setPaymentTotal={setPaymentTotal}
          setDisabled={setDisabled}
          paymentTotal={paymentTotal}
          reordered={reordered}
          setReordered={setReordered}
          disabled={disabled}
          checking={checking}
          setCheckPrincipal={setCheckPrincipal}
          checkPrincipal={checkPrincipal}
          zeroPrincipal={zeroPrincipal}
          setCheckInterest={setCheckInterest}
          checkInterest={checkInterest}
          zeroInterest={zeroInterest}
          setCheckInsurance={setCheckInsurance}
          checkInsurance={checkInsurance}
          zeroInsurance={zeroInsurance}
          setCheckFee={setCheckFee}
          checkFee={checkFee}
          zeroFee={zeroFee}
          setZeroFee={setZeroFee}
        />
      </tbody>
    </Table>
    <div style={Spacing.buttonRow}>
      <Buttons.Cancel type="cancel" text="Cancel" />
      <Buttons.Standard type="submit" text="Write Off" disabled={disabled} />
    </div>
    </FormikForm>
  );
};

const searchStyle = {
  div: {
    display: 'flex',
    width: '20%',
    alignItems: 'center',
  },
  label: {
    paddingRight: '10px',
  },
  input: {
    border: `1px solid ${Colors.xlGreen}`,
  }
}

const tableTopDivStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}

const inputDivStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
}
