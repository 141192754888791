export const handleMultiSelect = (selectedList, type, setFieldValue) => {
  if (!selectedList) {
    setFieldValue(type, []);
    return
  }

  selectedList = selectedList.map((obj) => {
    return parseInt(obj.value)
  })

  setFieldValue(type, selectedList)
}

export const hasFinancialAuth = (auth, type) => {
  return auth.hasExactPolicy('Financial', type)
}

export const isFinancialReport = reportName => {
  return ['balance-sheet', 'income-statement', 'cash-flow', 'trial-balance']
    .includes(reportName);
}
