import React, { useEffect, useState } from 'react';
import { AUTH_TOKEN } from '../../constants';
import secrets from '../../secrets';
import Modal from 'react-bootstrap/Modal';
import { Spacing } from '../../styles';
import * as Buttons from '../../elements/buttons'
import * as BasicForm from '../../elements/forms';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import Loading from '../../elements/Loading';

export const sourcePath = source => {
  if ('Auction' === source.__typename) {
    return `/auctions/${source.id}`;
  } else if ('Approved Source' === source.__typename) {
    return `/approved-sources/${source.id}`;
  } else if ('Dealer' === source.__typename) {
    return`/dealers/${source.id}`;
  } else if (source.__typename === 'Vendor') {
    return`/vendors/${source.id}`;
  } else {
    return '/';
  }
};

export const canPrintReceipt = (txn, auth) => {
  return (
    auth.hasPolicy('Txn', 'read') && txn.purpose === 'Payment' &&
      txn.quote !== null
  );
}

export const canPrintCheck = (txn, auth) => {
  return (
    auth.hasPolicy('Txn', 'read') && txn.type === 'Check'
  );
}

let count = 0;
let src;

export const setupPrintCheckHandler = (txn, lastDownloadTime,
  setLastDownloadTime, lastDownloadId, setLastDownloadId, setShowCheckModal
) => {

  $(document).on('click', `#check-print${txn.id}`, function(event) {
    event.preventDefault();
    count += 1

    if (count > 1) return;
    const id = parseInt(event.target.dataset.id);
    let newDate = new Date();

    if ((!lastDownloadTime || newDate.getTime() - lastDownloadTime > 5000) &&
      lastDownloadId != id) {

      const axios = require('axios');
      const FileDownload = require('js-file-download');
      const token = localStorage.getItem(AUTH_TOKEN);
      axios({
        method: 'get',
        url: `${secrets.api.baseUri}/print-check`,
        responseType: 'blob',
        headers: {
          'Content-Type' : 'application/json',
          'Accept' : 'application/pdf',
          'Authorization' : `${token}`,
          'JWT_AUD' : 'react'
        },
        params: {
          'txn-id' : id,
        }
      })
      .then(res => {
        count = 0;
        var date = new Date();
        var timestamp = date.getTime();

        const file = new Blob(
          [res.data],
          {type: 'application/pdf'}
        )

        const fileURL = URL.createObjectURL(file)
        src = fileURL
        setShowCheckModal(true)
      })
    }
  });
};

const containerStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '600px',
  height: '900px'
}

export const PrintForm = ({ setShowCheckModal, showCheckModal }) => {
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onHide={() => setShowCheckModal(false)}
        show={showCheckModal}
      >
        <Modal.Header closeButton>
          Print Preview
        </Modal.Header>
        <Modal.Body>
          <embed src={src} style={containerStyle} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export const setupPrintReceiptHandler = (
  txn, lastDownloadTime, setLastDownloadTime, lastDownloadId, setLastDownloadId,
  setReceiptShowModal
) => {
  $(document).on('click', `.receipt-print${txn.quote.id}`, function(event) {
    event.preventDefault();
    count += 1
    if (count > 1) return;
    const id = parseInt(event.target.id);
    let newDate = new Date();
    if ((!lastDownloadTime || newDate.getTime() - lastDownloadTime > 5000) &&
        lastDownloadId != id) {
      const axios = require('axios');
      const FileDownload = require('js-file-download');
      const token = localStorage.getItem(AUTH_TOKEN);
      // setReceiptShowModal(true);
      axios({
        method: 'get',
        url: `${secrets.api.baseUri}/print-receipt`,
        responseType: 'blob',
        headers:  {
          'Content-Type' : 'application/json',
          'Accept' : 'application/pdf',
          'Authorization' : `${token}`,
          'JWT_AUD' : 'react'
        },
        params: {
          'quote-id': id,
        }
      })
      .then(res => {
        count = 0;
        var date = new Date();
        var timestamp = date.getTime();
        // setLastDownloadTime(date.getTime());
        // setLastDownloadId(id);
        FileDownload(res.data, `Payment_Receipt_${timestamp}.pdf`);
        // setReceiptShowModal(false);
      })
    }
  });
};
