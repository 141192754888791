import React, { useState, useEffect, Fragment } from 'react';
import { Text } from 'react-native';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { SubmitForm } from './SubmitForm';
import { renderOrdinalSuffix,
         FeeAmount,
         FlagAmount,
         renderSubmit,
         FeeExtensionInput,
         TypeOfPurchaseOption,PrincipalAmountOptions,
         BosOptions,
         resetValues,
         resetFeeType
        } from './functionality'

 export const CarsPastDueOptions = (props) => {
   const { values,
           errors,
           touched,
           handleBlur,
           handleChange,
           name,
           actionType,
           setFieldValue} = props;

  resetValues();

   return <div>
      <FlagAmount name="After How Many Days Past Due?" values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange}/>
      <FeeAmount name="Amount" values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange}/>
      {renderSubmit(values, errors, touched, handleBlur, handleChange, null, actionType)}
    </div>
  }

  export const CarExtensionOptions = (props) => {
    const { values,
             errors,
             touched,
             handleBlur,
             handleChange,
             name,
             actionType,
             setFieldValue} = props;

    const [ordinalValue, setOrdinalValue] = useState('st/nd/rd/th')

    resetValues();
    resetFeeType();

    return <div><Form.Group>
        <Form.Label>Do this for</Form.Label>
        <Form.Control
          as="select"
          name="option"
          value={values.option}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={touched.option && !errors.option}
          isInvalid={!!errors.option && touched.option}
        >
        <option>Select</option>
        <option value="all">All Extensions</option>
        <option value="specific">A Specific Extension</option>
        <option value="after">All Extensions After the Xth Extension</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.option}
        </Form.Control.Feedback>
      </Form.Group>
      { (values.option && values.option === 'all') && [
        <FeeAmount name="What is the price of the fee?" key={'all'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />,
        renderSubmit(values, errors, touched, handleBlur, handleChange, 'feeAmountOnly', actionType)
      ]}

      { (values.option && values.option === 'specific') && [
        <FeeExtensionInput ordinalValue={ordinalValue} setOrdinalValue={setOrdinalValue} name="Add this fee during the" key={'specificAmount'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} /> ,
        <FeeAmount name="What is the price of the fee?" key={'specificFee'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />,
        renderSubmit(values, errors, touched, handleBlur, handleChange, null, actionType)
      ]}
      { (values.option && values.option === 'after') && [
        <FeeExtensionInput ordinalValue={ordinalValue} setOrdinalValue={setOrdinalValue} name="Add this fee AFTER the" key={'afterAmount'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} /> ,
        <FeeAmount name="What is the price of the fee?" key={'afterFee'} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />,
        renderSubmit(values, errors, touched, handleBlur, handleChange, null, actionType)
      ]}
    </div>
  }

export const CarFloorOptions = (props) => {
  const { values,
           errors,
           touched,
           handleBlur,
           handleChange,
           name,
           actionType,
           setFieldValue} = props;

    resetValues();
    resetFeeType();

   return <div>
    <FeeAmount name='Amount of the Fee to add when a car is Floored' values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    {renderSubmit(values, errors, touched, handleBlur, handleChange, 'feeAmountOnly', actionType)}
  </div>
}

export const CarFlooringTypeOptions = (props) => {
  const { values,
           errors,
           touched,
           handleBlur,
           handleChange,
           name,
           actionType,
           setFieldValue} = props;

    resetValues();
    resetFeeType();

  return <div>
    <TypeOfPurchaseOption values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange}/>
    <FeeAmount name='Amount of the Fee to add when a car is Floored' values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    <i style={{fontSize: '13px', marginBottom: '10px'}}>*Please note that NAP will include any source other than an Auction in the Auctions system, even if it is an Auction or another Approved Source</i>
    {renderSubmit(values, errors, touched, handleBlur, handleChange, 'feeAmountOnly', actionType)}
  </div>
}

export const CarPrincipalAmountOptions = (props) => {
  const { values,
             errors,
             touched,
             handleBlur,
             handleChange,
             name,
             actionType,
             setFieldValue} = props;

  resetValues();
  resetFeeType();

  return <div>
    <PrincipalAmountOptions values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    <FeeAmount name='Amount of the Fee to add when a car is Floored' values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    {renderSubmit(values, errors, touched, handleBlur, handleChange, 'optionRequired', actionType)}
  </div>
}

export const CarBosOptions = (props) => {
  const { values,
             errors,
             touched,
             handleBlur,
             handleChange,
             name,
             actionType,
             setFieldValue} = props;

  resetValues();
  resetFeeType();

  return <div>
    <BosOptions values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    <FeeAmount name='Amount of the Fee to add when a car is Floored' values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    { renderSubmit(values, errors, touched, handleBlur, handleChange, 'allRequired', actionType)}
  </div>
}

export const CarAudit = (props) => {
  const { values,
           errors,
           touched,
           handleBlur,
           handleChange,
           name,
           actionType,
           setFieldValue} = props;

    resetValues();
    resetFeeType();

  return <div>
    <FlagAmount name={`After how many days past the Audit Date?`} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange}/>
    <FeeAmount name='What is the price of the fee?' values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    {renderSubmit(values, errors, touched, handleBlur, handleChange, 'feeAmountOnly', actionType)}
  </div>
}

export const PaymentAssistance = (props) => {
  const { values,
          errors,
          touched,
          handleBlur,
          handleChange,
          name,
          actionType,
          setFieldValue} = props;

  resetValues();
  resetFeeType();

  return <div>
    <FeeAmount name='What is the price of the fee?' values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    {renderSubmit(values, errors, touched, handleBlur, handleChange, 'feeAmountOnly', actionType)}
  </div>
}

export const CarSoldNotPaid = (props) => {
  const { values,
           errors,
           touched,
           handleBlur,
           handleChange,
           name,
           basedOn,
           actionType,
           setFieldValue} = props;

    resetValues();
    resetFeeType();

  return <div>
    <FlagAmount name={`After how many days past the ${basedOn} Date?`} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange}/>
    <FeeAmount name='What is the price of the fee?' values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    {renderSubmit(values, errors, touched, handleBlur, handleChange, 'bothAmounts', actionType)}
  </div>
}



export const BasicOptions = (props) => {
  const { values,
           errors,
           touched,
           handleBlur,
           handleChange,
           name,
           actionType,
           setFieldValue} = props;

    resetValues();
    resetFeeType();

  return <div>
    <FeeAmount name='What is the price of the fee?' values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
    {renderSubmit(values, errors, touched, handleBlur, handleChange, 'feeAmountOnly', actionType)}
  </div>
}

export const OverLine = (props) => {
  const { values,
           errors,
           touched,
           handleBlur,
           handleChange,
           name,
           actionType,
           setFieldValue} = props;

    resetValues();
    resetFeeType();

  return <div>
    <Form.Group>
      <Form.Label>Flat Fee or Percentage</Form.Label>
      <Form.Control
        as="select"
        name="option"
        value={values.option}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={touched.option && !errors.option}
        isInvalid={!!errors.option && touched.option}
      >
      <option>Select</option>
      <option value="$">$</option>
      <option value="%">%</option>
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {errors.option}
      </Form.Control.Feedback>
    </Form.Group>
    { values.option &&
      <Fragment>
        <FeeAmount
          name={`Fee ${values.option === '$' ? 'Amount' : 'Percentage'}`}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          symbol={values.option === '$' ? null : '%'}
        />
        {renderSubmit(values, errors, touched, handleBlur, handleChange, 'feeAmountOnly', actionType)}
      </Fragment>
    }
  </div>
}


