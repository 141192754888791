import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import Form from 'react-bootstrap/Form';
import DataState from '../../DataState';
import * as FormOptions from './FormOptions';

const ACTIONS = gql`
  query {
    automatedFeeActions {
      type
      flagAmountLabel
      action
      unit
    }
  }`

export const FeeTypeForm = (props) => {
  const { type,
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          actionType,
          setFieldValue } = props;

  const { loading, error, data } = useQuery(ACTIONS);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { automatedFeeActions } = data;

  const initializeOptions = () => {
    switch (values.action) {
      case 'Car Past Due':
        return <FormOptions.CarsPastDueOptions
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />;
      case 'Car Extension':
        return <FormOptions.CarExtensionOptions
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
           actionType={actionType}
           setFieldValue={setFieldValue}
           />
      case 'Car Floored':
        return <FormOptions.CarFloorOptions
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Car Flooring Type':
        return <FormOptions.CarFlooringTypeOptions
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Car Floored Principal':
        return <FormOptions.CarPrincipalAmountOptions
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Car Floor Date v BOS':
        return <FormOptions.CarBosOptions
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Over Line':
        return <FormOptions.OverLine
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Unverified Audit':
        return <FormOptions.CarAudit
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Payment Assistance':
        return <FormOptions.PaymentAssistance
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Car Sold Not Paid, Based on Sold Date':
        return <FormOptions.CarSoldNotPaid
          basedOn="Sold"
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Car Sold Not Paid, Based on Audit Date':
        return <FormOptions.CarSoldNotPaid
          basedOn="Audit"
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      case 'Dealer NSF':
      case 'Dealer Audit':
      case 'Dealer Multiple Audit Locations':
      case 'Title Added':
      case 'Title Marked Present':
      case 'Title Shipped':
      case 'Title Borrowed':
      case 'Unverified After Ten Days':
        return <FormOptions.BasicOptions
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          actionType={actionType}
          setFieldValue={setFieldValue}
        />
      default:
      return null;
    }
  }

  return <div>
    <Form.Group>
      <Form.Label>Action</Form.Label>
      <Form.Control
        as="select"
        name="action"
        value={values.action}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={touched.action && !errors.action}
        isInvalid={!!errors.action && touched.action}
      >
      <option></option>
      { automatedFeeActions.map((fee) => {
        if (fee.type === values.feeType) {
          return <option key={fee.action}>{fee.action}</option>
        }
      })}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {errors.feeType}
      </Form.Control.Feedback>
      </Form.Group>
      {initializeOptions()}
  </div>
}
