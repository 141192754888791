import React from 'react';
import { Form as FormikForm, useFormikContext } from 'formik';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import DataState from '../../DataState';
import * as BasicForm from "../../../elements/forms";
import { Spacing, Colors } from '../../../styles';
import * as Buttons from '../../../elements/buttons';

const CALC_SELECTS = gql`
  query {
    ratePrograms { id, name }
    dealerPrograms { id, name }
    automatedFees { id, name }
  }
`;

const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })
}

const Form = ({formSubmitting}) => {
  const { setFieldValue  } = useFormikContext();
  const { data: selectData, loading: selectLoading, error: selectError } = useQuery(CALC_SELECTS);

  if (selectLoading) return <DataState.Loading />
  if (selectError) return <DataState.Error error={selectError} />;

  const formattedAutomatedFees = selectData.automatedFees.map((fee) => {
    return { value: fee.id, label: fee.name };
  })

  const handleChange = (selectedList) => {
    if (!selectedList) {
      setFieldValue('automatedFeeResourcesAttributes', []);
      return
    }

    selectedList = selectedList.map((fee) => {
      return { automatedFeeId: parseInt(fee.value) };
    })

    setFieldValue('automatedFeeResourcesAttributes', selectedList);
  }

  const buttonRow = {
    ...Spacing.buttonRow,
    marginTop: '3%'
  }

  return (
    <FormikForm>
      <BasicForm.TextInput name="averagePrincipal" type="text" label="Average Principal" />
      <BasicForm.TextInput name="averageDays" type="number" label="Average Days" />
      <BasicForm.ProgrammaticSelect
        name="rateProgramId"
        label="Rate Program"
        optionName="Rate Program"
        options={selectData.ratePrograms}
      />
      <BasicForm.ProgrammaticSelect
        name="dealerProgramId"
        label="Dealer Program"
        optionName="Dealer Program"
        options={selectData.dealerPrograms}
      />
      <BasicForm.StyledLabel>Automated Fees</BasicForm.StyledLabel>
      <Select
        options={formattedAutomatedFees}
        isMulti={true}
        onChange={handleChange}
        styles={customStyles}
      />
      <div style={buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={formSubmitting}
        />
      </div>
    </FormikForm>
  );
};

export default Form;
