import React from 'react';
import * as BasicForm from '../../../elements/forms';
import Changes from './Changes';

export const ChangeForm = () => {
  return [
      <BasicForm.Label
        label='Collateral Coverage Changes'
        style={{fontWeight: 'bold', fontSize: '16px'}}
      />,
      <BasicForm.TextInput
        name="insuranceProgramChangesAttributes['rate']"
        type="number"
        label="Rate"
      />,
      <BasicForm.TextInput
        name="insuranceProgramChangesAttributes['effectiveOn']"
        type="date"
        label="Effective On"
      />,
    ]
}

export const ChangesIndex = () => {
return [
    <hr key='edit'/>,
    <Changes key='edit-changes'/>
  ]
}
