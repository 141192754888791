import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { gql, useQuery } from '@apollo/client';
import DataState from '../../DataState';
import { useFormikContext, Form as FormikForm } from 'formik';
import * as BasicForm from '../../../elements/forms';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';

const BANK_ACCOUNT_SELECT = gql`
  query {
    accounts(scopes: ["bank"]) {
      id
      gl
      name
      reconciles {
        id
        account {
          id
        }
        endBalance
        completedAt
      }
    }
  }
`;

const ACCOUNT = gql`
  query ACCOUNT($id: ID!) {
    account(id: $id) {
      gl,
      name,
      reconciles {
        id
        completedAt
        endBalance
        previouslyCompleted
      }
    }
  }
`;

export const reconcileSchema = yup.object({
  accountId: yup
    .number()
    .required('Required'),
  startOn: yup
    .string()
    .required('Required'),
  endOn: yup
    .string()
    .required('Required')
})

export const Form = () => {
  const [accountIdParam, setAccountIdParam] = useState(null);
  const { loading, error, data: selectData } = useQuery(BANK_ACCOUNT_SELECT);
  const { data: accountData, refetch } = useQuery(ACCOUNT, { variables: { id: accountIdParam },
    notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
  });

  const { values, setFieldValue } = useFormikContext();
  let { id } = useParams();
  const editAccountId = id ? true : false

  useEffect(() => {
    setAccountIdParam(values.accountId)
    refetch();
  }, [values.accountId])

  useEffect(() => {
    let amount = accountData?.account?.reconciles[0]?.previouslyCompleted
    if (!editAccountId) {
      if (amount === undefined) {
        setFieldValue('startBalance', 0)
      } else {
        setFieldValue('startBalance', amount)
      }
    }
  }, [accountData])

  if (loading && !selectData) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <FormikForm>
      {
        selectData &&
          <BasicForm.ProgrammaticSelect
            name='accountId'
            label='Account'
            options={selectData.accounts}
            disabled={editAccountId}
          />
      }
      <BasicForm.TextInput
        name='startBalance'
        type='number'
        label='Starting Balance'
        disabled={true}
      />
      <BasicForm.TextInput
        name='endBalance'
        type='number'
        label='Ending Balance'
      />
      <BasicForm.TextInput
        name='startOn'
        type='date'
        label='Start On'
      />
      <BasicForm.TextInput
        name='endOn'
        type='date'
        label='End On'
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type='cancel' text='Cancel' />
        <Buttons.Standard type='submit' text='Submit' />
      </div>
    </FormikForm>
  )
}
