import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { Spacing } from '../styles';
import * as Colors from '../styles/colors';
import * as StringHelper from '../utils/stringHelpers';
import * as DateHelper from '../utils/dateHelpers';

const previouslyFlooredStyle = {
  ...Spacing.alerts,
  backgroundColor: Colors.xlYellow,
  color: 'black',
}

const currentlyFlooredStyle = {
  ...Spacing.alerts,
  color: 'black',
}

export const CarFloored = ({ flooredCars, floorplanRequests }) => {
  if (flooredCars?.length < 1) return null;

  if (flooredCars?.length === 1) {
    const status = flooredCars[0].status
    const dealerName = flooredCars[0].dealer.name
    const payoffOn = flooredCars[0].payoffOn
    const loanAmount = flooredCars[0].loanAmount
    if (floorplanRequests?.length > 0) {
      return (status === 'Unpaid') ?
        [
          <CurrentlyFlooredCarAlert
            loanAmount={loanAmount}
            dealerName={dealerName}
          />,
          <PendingFlooredCarAlert
            dealerName={floorplanRequests[0].dealerLine.dealer.name}
          />
        ] :
        [
          <PreviouslyFlooredCarAlert
            loanAmount={loanAmount}
            dealerName={dealerName}
            payoffOn={payoffOn}
          />,
          <PendingFlooredCarAlert
            dealerName={floorplanRequests[0].dealerLine.dealer.name}
          />
        ]
    } else {
      return (status === 'Unpaid') ?
        <CurrentlyFlooredCarAlert
          loanAmount={loanAmount}
          dealerName={dealerName}
        /> :
        <PreviouslyFlooredCarAlert
          loanAmount={loanAmount}
          dealerName={dealerName}
          payoffOn={payoffOn}
        />
    }
  }

  const currentlyFlooredCar = flooredCars?.find((car) => car.status === 'Unpaid');
  const previouslyFlooredCars = flooredCars?.filter((car) => car.status !== 'Unpaid');

  const currentDealerName = currentlyFlooredCar?.dealer.name
  const currentLoanAmount = currentlyFlooredCar?.loanAmount

  if (previouslyFlooredCars?.length === 0) {
    if (floorplanRequests?.length > 0) {
      return [
        <CurrentlyFlooredCarAlert
          dealerName={currentDealerName}
          loanAmount={currentLoanAmount}
        />,
        <PendingFlooredCarAlert
          dealerName={floorplanRequests[0].dealerLine.dealer.name}
        />
      ]
    } else {
      return <CurrentlyFlooredCarAlert
        dealerName={currentDealerName}
        loanAmount={currentLoanAmount}
      />
    }
  }

  const floorCount = previouslyFlooredCars?.length;
  const latestFlooredCar = previouslyFlooredCars ? previouslyFlooredCars[previouslyFlooredCars.length - 1] : null;
  let latestDealerName = latestFlooredCar?.dealer.name;
  const latestPayoffOn = latestFlooredCar?.payoffOn;
  const latestLoanAmount = latestFlooredCar?.loanAmount;
  latestDealerName = (latestDealerName) ? StringHelper.titleCase(latestDealerName) : null;
  const previousMultipleMessage =`This car has been floored ${floorCount} times before.
      The most recent was for ${ latestDealerName || ''} and was floored on ${DateHelper.setDate(latestFlooredCar?.loanDate)}.`
  if (currentlyFlooredCar && previouslyFlooredCars) {
    let message = previousMultipleMessage
    if (previouslyFlooredCars.length === 1) message = null;
    if (floorplanRequests?.length > 0) {
      return [
        <CurrentlyFlooredCarAlert
          dealerName={currentDealerName}
          loanAmount={currentLoanAmount}
        />,
        <PreviouslyFlooredCarAlert
          message={message}
          payoffOn={latestPayoffOn}
          dealerName={latestDealerName}
          loanAmount={latestFlooredCar.loanAmount}
        />,
        <PendingFlooredCarAlert
          dealerName={floorplanRequests[0].dealerLine.dealer.name}
        />
      ]
    } else {
      return [
        <CurrentlyFlooredCarAlert
          dealerName={currentDealerName}
          loanAmount={currentLoanAmount}
        />,
        <PreviouslyFlooredCarAlert
          message={message}
          payoffOn={latestPayoffOn}
          dealerName={latestDealerName}
          loanAmount={latestFlooredCar.loanAmount}
        />
      ]
    }
  }

  if (currentlyFlooredCar) {
    if (floorplanRequests?.length > 0) {
      return [
        <CurrentlyFlooredCarAlert dealerName={currentDealerName} loanAmount={currentLoanAmount} />,
        <PendingFlooredCarAlert
          dealerName={floorplanRequests[0].dealerLine.dealer.name}
        />
      ]
    } else {
      return <CurrentlyFlooredCarAlert dealerName={currentDealerName} loanAmount={currentLoanAmount} />
    }
  }

  if (previouslyFlooredCars && previouslyFlooredCars.length > 1) {
    const message = previousMultipleMessage
    if (floorplanRequests?.length > 0) {
      return [
        <PreviouslyFlooredCarAlert
          message={message}
          payoffOn={latestPayoffOn}
          dealerName={latestDealerName}
        />,
        <PendingFlooredCarAlert
          dealerName={floorplanRequests[0].dealerLine.dealer.name}
        />
      ]
    } else {
      return <PreviouslyFlooredCarAlert
        message={message}
        payoffOn={latestPayoffOn}
        dealerName={latestDealerName}
      />
    }
  }

  if (previouslyFlooredCars && previouslyFlooredCars.length === 1) {
    const loanAmount = previouslyFlooredCars[0].loanAmount;
    const dealerName = previouslyFlooredCars[0].dealer.name
    if (floorplanRequests?.length > 0) {
      return [
        <PreviouslyFlooredCarAlert
          loanAmount={loanAmount}
          dealerName={dealerName}
        />,
        <PendingFlooredCarAlert
          dealerName={floorplanRequests[0].dealerLine.dealer.name}
        />
      ]
    } else {
      return <PreviouslyFlooredCarAlert
        loanAmount={loanAmount}
        dealerName={dealerName}
      />
    }
  }

  if (floorplanRequests?.length > 0) {
    return <PendingFlooredCarAlert
      dealerName={floorplanRequests[0].dealerLine.dealer.name}
    />
  }

  return null;
}

const CurrentlyFlooredCarAlert = ({ loanAmount, message, dealerName }) => {
  if (!message) message = `This car is currently floored by
    ${StringHelper.titleCase(dealerName)}`
  if (loanAmount) message += ` for ${StringHelper.currency(loanAmount)}`

  return (
    <Alert variant="danger" style={currentlyFlooredStyle}>
      {message}
    </Alert>
  );
}

const PreviouslyFlooredCarAlert = ({ loanAmount, message, dealerName, payoffOn }) => {
  if (!message) message = `This car was floored by
    ${StringHelper.titleCase(dealerName)}. It was paid off on ${DateHelper.setDate(payoffOn) || ''} for ${StringHelper.currency(loanAmount)}`

  // TODO - this can be removed when payoffOn data has been imported
  if (!payoffOn) message = `This car was floored by
    ${StringHelper.titleCase(dealerName)}`
  if (loanAmount) message += ` for ${StringHelper.currency(loanAmount)}`


  return (
    <Alert style={previouslyFlooredStyle}>
      {message}
    </Alert>
  );
}

const PendingFlooredCarAlert = ({ loanAmount, message, dealerName, payoffOn }) => {
  if (!message) message = `This car is currently pending approval from
    ${StringHelper.titleCase(dealerName)}`
  if (loanAmount) message += ` for ${StringHelper.currency(loanAmount)}`

  return (
    <Alert variant="danger" style={currentlyFlooredStyle}>
      {message}
    </Alert>
  );
}

export const BlackbookValue = ({ originalBlackbookValue, newBlackbookValue }) => {
  if (originalBlackbookValue === newBlackbookValue) {
    return null
  } else if (originalBlackbookValue !== newBlackbookValue) {
    return <BlackbookValueAlert originalBlackbookValue={originalBlackbookValue} newBlackbookValue={newBlackbookValue} />
  }
}

const BlackbookValueAlert = ({originalBlackbookValue, newBlackbookValue}) => {
  return (
    <Alert variant="danger" style={currentlyFlooredStyle}>
      A new black book value has been pulled for this car (new value: ${newBlackbookValue}) (old value: ${originalBlackbookValue}). Click Submit below to save new value on this car.
    </Alert>
  )
}

export const NoBlackbookValue = () => {
  return (
    <Alert variant="danger" style={currentlyFlooredStyle}>
      There is no blackbook value available for this car.
    </Alert>
  )
}
