import React from 'react';
import { Form as FormikForm } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';

export const Form = () => {
  return (
    <FormikForm>
      <BasicForm.TextInput
        name="sotOn"
        type="date"
        label="SOT On"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}
