import React, { useState } from 'react';
import { Text } from 'react-native';
import { useParams, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { useAlert } from '../../../hooks/useAlert';
import BasicAlert from '../../../elements/BasicAlert';
import * as yup from 'yup';
import { Colors } from '../../../styles';
import { FeeTypeForm } from './FeeTypeForm';
import { titleCase } from '../../../utils/stringHelpers';
import { Typography, Spacing } from '../../../styles';
import DataState from '../../DataState';
import '../../../styles/automatedFees.css';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'

const UPDATE_AUTOMATED_FEE = gql`
  mutation UpdateAutomatedFee($id: ID!, $name: String!, $fullyEarned: Boolean!,
    $action: String!, $flagAmount: Float, $accountId: Int!, $feeAmount: Float!,
    $displayName: String!, $option: String!, $earnsInterest: Boolean!,
    $active: Boolean!, $canBeWaived: Boolean) {
    updateAutomatedFee(
      id: $id
      name: $name
      displayName: $displayName
      action: $action
      flagAmount: $flagAmount
      accountId: $accountId
      feeAmount: $feeAmount
      fullyEarned: $fullyEarned
      option: $option
      earnsInterest: $earnsInterest
      active: $active
      canBeWaived: $canBeWaived
    ) {
      automatedFee {
        id
      }
      errors {
        message
        path
      }
    }
  }
`
const AUTOMATED_FEE = gql`
  query AUTOMATED_FEE($id: ID!){
    automatedFee(id: $id) {
      id
      name
      displayName
      action
      flagAmount
      fullyEarned
      option
      earnsInterest
      active
      account {
        id
      }
      feeAmount
      canBeWaived
    }
    accounts(scopes: ["income"])
    { id, gl, name }
  }
`

const AutomatedFee = () => {
  const { id } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [showAlert, displayAlert] = useAlert();

  const customSubmitHandler = (values) => {
    if (values.flagAmount === '') values.flagAmount = null
    values.accountId = parseInt(values.accountId, 10)

    values = {
      id: values.id,
      name: values.name,
      action: values.action,
      accountId: values.accountId,
      flagAmount: values.flagAmount,
      feeAmount: values.feeAmount,
      displayName: values.displayName,
      fullyEarned: values.fullyEarned,
      option: values.option,
      earnsInterest: values.earnsInterest,
      active: values.active,
      canBeWaived: values.canBeWaived
    }
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_AUTOMATED_FEE,
    onCompleteRedirectTo: "/system/automated-fees",
    customSubmitHandler: customSubmitHandler
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const { loading, error, data } = useQuery(AUTOMATED_FEE, { variables: { id } });

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { automatedFee } = data;

  if (automatedFee.option === null) automatedFee.option = ''

  function formatData() {
    if (automatedFee.option === null) automatedFee.option = ''

    if (
      automatedFee.action.includes('Car') ||
      automatedFee.action === 'Over Line' ||
      automatedFee.action === 'Payment Assistance' ||
      automatedFee.action === 'Unverified Audit'
    ) {
      automatedFee.feeType = 'car'
    }

    if (automatedFee.action.includes('Title')) {
      automatedFee.feeType = 'title'
    }

    if (automatedFee.action.includes('Dealer')) {
      automatedFee.feeType = 'dealer'
    }

    if (automatedFee.flagAmount === null) automatedFee.flagAmount = 0;

    automatedFee.accountId = automatedFee.account.id
  }

  const schema = yup.object({
    name: yup.string().max(80).required('Required'),
    displayName: yup.string().max(80).required('Required'),
    feeType: yup.string().required('Required'),
    action: yup.string().required('Required'),
    accountId: yup.string().required('Required'),
    feeAmount: yup.number().min(0, "Must be positive").required('Required'),
    flagAmount: yup.number().min(0, 'Must be positive').typeError('Required'),
    fullyEarned: yup.boolean().required('Required'),
    option: yup.string(),
    earnsInterest: yup.boolean().required('Required'),
    active: yup.boolean().required('Required'),
    canBeWaived: yup.boolean().required('Required')
  });

  function FeeProgramForm() {
    formatData();

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={automatedFee}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          isInvalid,
          errors,
          setValues
        }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
              <div style={Spacing.formWrapper}>
                <div style={Spacing.formHeader}>
                  <h1 style={Typography.formHeader}>{`Update: ${titleCase(automatedFee.name)}`}</h1>
                </div>
              </div>
              <Form.Group>
                <Form.Label>Automated Fee Program Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Name of Fee to Display to Dealers</Form.Label>
                <Form.Control
                  type="text"
                  name="displayName"
                  value={values.displayName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.displayName && !errors.displayName}
                  isInvalid={!!errors.displayName && touched.displayName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.displayName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as="select"
                  name="feeType"
                  value={values.feeType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.feeType && !errors.feeType}
                  isInvalid={!!errors.feeType && touched.feeType}
                >
                  <option value="">Select a Fee Type</option>
                  <option value="car">Car</option>
                  <option value="dealer">Dealer</option>
                  <option value="title">Title</option>
                </Form.Control>
                {(!values.feeType) && <Text>Please select a fee type to display options</Text>}
              </Form.Group>
              {(values.feeType && values.feeType !== '') && <FeeTypeForm
                type={values.feeType}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                actionType={'Update'}
              />
              }
            </Form>
          )}
      </Formik>
    )
  }

  const buttonColor = {
    backgroundColor: Colors.xlGreen,
    borderColor: Colors.xlGreen,
    color: 'white'
  }

  const LoadedContent = () => {
    return (
      <>
        {showAlert &&
          <BasicAlert componentType="automated fee" />
        }
        <ServerSideErrors />
        <FeeProgramForm />
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default AutomatedFee;
