import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { Form, programChangeSchema } from './Form';
import { Spacing, Typography } from '../../../../styles';
import { COLLATERAL_COVERAGE } from '../../../../queries/viewQueries';

const NEW_COLLATERAL_COVERAGE_CHANGE = gql`
  mutation CreateInsuranceProgramChange(
  $insuranceProgramId: Int, $rate: Decimal!, $effectiveOn: ISO8601Date!)
  {
    createInsuranceProgramChange(
      insuranceProgramId: $insuranceProgramId
      rate: $rate
      effectiveOn: $effectiveOn
    ) {
      insuranceProgramChange { id }
      errors { message, path }
    }
  }
 `

 const NewCollateralCoverageChange = () => {
   const [redirect, setRedirect] = useState(false);
   const collateralCoverageId = parseInt(useParams().id);

   const [newCollateralCoverageChange, { loading: mutationLoading, error: mutationError }] = useMutation(
    NEW_COLLATERAL_COVERAGE_CHANGE,
     {
       awaitRefetchQueries: true,
       refetchQueries: [{ query: COLLATERAL_COVERAGE, variables: { id: collateralCoverageId } }],
       onCompleted(variables) {
        setRedirect(true);
       }
     })

   const handleSubmit = (values) => {

     newCollateralCoverageChange({ variables: values })
   };

   const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/system/collateral-coverages/${collateralCoverageId}`,
          state: { alert: true, type: 'created' }
        }}
      />
    }

     return (
       <>
         <div style={Spacing.formWrapper}>
           <div style={Spacing.formHeader}>
             <h1 style={Typography.formHeader}>Create a Collateral Coverage Change</h1>
           </div>

           <Formik
             initialValues={{
               insuranceProgramId: collateralCoverageId,
               rate: '',
               effectiveOn: '',
             }}
             validationSchema={programChangeSchema}
             onSubmit={handleSubmit}
           >
             <Form />
           </Formik>
         </div>
       </>
     )
    }

  return (
    <>
    <Helmet>
      <title>New Collateral Coverage Change</title>
    </Helmet>
    <div style={Spacing.formContainer}>
      <NewForm />
    </div>
    </>
  );
};

export default NewCollateralCoverageChange;
