import React, { Fragment, useState } from 'react';
import { Spacing, Typography } from "../../styles";
import { Formik } from "formik";
import Form, { updatesSchema } from './Form';
import { gql, useMutation } from '@apollo/client';
import { getServerSideErrors } from '../../utils/errorHelpers';
import Loading from '../../elements/Loading';
import { CustomAlert } from '../../elements/BasicAlert';

const MASS_UPDATE = gql`
  mutation CreateMassUpdate(
    $userIdNew: Int!
    $userIdOld: Int!
  ) {
    createMassUpdate(
      userIdNew: $userIdNew
      userIdOld: $userIdOld
    ) {
      errors {
        path,
        message
      }
    }
  }
`

const MassUpdates = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);

  const [massUpdate] = useMutation(MASS_UPDATE, {
    onCompleted(values) {
      setFormSubmitting(false);
      const serverErrors = getServerSideErrors(values);
      if (serverErrors) {
        setServerSideErrors(serverErrors);
      } else {
        setDisplayAlert(true);
      }
    },
  });

  const handleSubmit = (variables) => {
    if (window.confirm(`Are you sure you want to update this user?`)) {
      setFormSubmitting(true);
      massUpdate({variables})
    }
  };

  const Wrapper = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Mass Updates for Personnel Changes</h1>
        </div>
        <Formik
          initialValues={{
            userIdNew: '',
            userIdOld: ''
          }}
          validationSchema={updatesSchema}
          onSubmit={handleSubmit}
        >
          <Form formSubmitting={formSubmitting} />
        </Formik>
      </div>
    );
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <Wrapper />
      </div>
    );
  };

  return (
    <Fragment>
      { serverSideErrors &&
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      }
      {
        displayAlert &&
        <CustomAlert variant='success' message={'Your update was successful.'} />
      }
      <DisplayContent />
    </Fragment>
  );
};


export default MassUpdates;
