import React, { Fragment } from 'react';
import Table from 'components/src/components/Transactions/Table';
import { Typography } from 'components/src/styles';

const index = () => {
  const shownColumns = [
    'bankDate', 'createdDate', 'market', 'dealer', 'dealerNumber', 'source',
    'type', 'reference', 'purpose', 'amount', 'flooredCars', 'createdBy',
    'actions'
  ];

  const availableFilters = [
    'dealer', 'payment_type', 'purpose', 'created_by', 'bank_date',
    'created_date'
  ];

  return (
    <Fragment>
      <div style={Typography.pageTitle}>Floorplan Transactions</div>
      <Table
        shownColumns={shownColumns}
        availableFilters={availableFilters}
        scopes={['flooring_core']}
        reportPath='transaction-lines-report'
      />
    </Fragment>
  );
};

export default index;
