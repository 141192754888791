import { addFilters } from './addFilters';

export const searchTable = (
  search,
  sortColumnIndex,
  sort,
  getColumnName,
  setQueryData,
  sortDirection,
  offset,
  currentPage,
  fetchMore,
  limit,
  queryData,
  marketId=null,
  filters=null,
  setQueryLoading,
) => {
  const variables = {
    limit,
    sort: sort.current,
    sortDirection: sortDirection.current,
    offset: 0,
    search: search.current,
  };

  if (marketId) variables.marketId = parseInt(marketId);
  if (filters) addFilters(filters, variables);

  setQueryLoading(true);
  fetchMore({
    variables,
    updateQuery: (prev, { fetchMoreResult }) => {
      setQueryLoading(false);
      currentPage.current = 1;

      setQueryData(fetchMoreResult);
    },
  });
};
