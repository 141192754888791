import React from 'react';
import { Form } from './Form';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { UPDATE_TITLE_LOG } from './Queries';
import { useMutation } from '@apollo/client';
import { editAddressSchema } from './Schema';

const Update = props => {
  const {
    handleClose,
    show,
    logId,
    dealerId,
    refetch,
    setEditTitleLogAlert
  } = props;

  const [updateTitleLog, { loading }] = useMutation(
    UPDATE_TITLE_LOG, {
      onCompleted(values) {
        handleClose();
        refetch();
        setEditTitleLogAlert(true);
      }
    }
  )

  const handleSubmit = (values) => {
    updateTitleLog({ variables: values })
  }

  const UpdateAddress = () => {
    return (
      <div>
        <Formik
          initialValues={{
            id: logId,
            shipToType: undefined,
            shipToId: undefined,
            dealerId: undefined,
            otherAddress: {
              name: undefined,
              street: undefined,
              city: undefined,
              state: undefined,
              zip: undefined,
              phone: undefined
            }
          }}
          validationSchema={editAddressSchema}
          onSubmit={handleSubmit}
        >
          <Form dealerId={dealerId} />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Change Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { loading ? <p>Loading...</p> : <UpdateAddress /> }
      </Modal.Body>
    </Modal>
  )
}

export default Update;
