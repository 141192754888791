import React from 'react';
import * as Formatting from 'components/src/styles/formatting';
import { numberWithDelimiter } from 'components/src/utils/stringHelpers';
import { Col } from 'react-bootstrap';
import { Card, Head, Data, Label, Value } from './Cards';
import './styles.css';

const percent = value => (value * 100).toFixed(2) + '%';

export const Status = ({ stats, label, warning }) => {
  const stat = stats.find(stat => stat.dealerStatus.name === label);

  if (!stat) {
    return null;
  }

  return (
    <tr className={warning ? 'warning' : 'normal'}>
      <td>
        <a
          href={`/dealers?status=${stat.dealerStatus.name}&collections=${warning}`}
          target="_blank"
        >
          {stat.dealerStatus.name}
        </a>
      </td>
      <td>{stat.count}</td>
      <td>{percent(stat.rate)}</td>
    </tr>
  );
};

const Category = ({ stats, label }) => {
  const stat = stats.find(stat => (
    stat.dealerCategory.name.toLowerCase() === label.toLowerCase()
  ));

  return (
    <tr className="normal">
      <td>{label}</td>
      <td>{stat?.count || 0}</td>
      <td>{percent(stat?.rate || 0)}</td>
    </tr>
  );
};

const Dealers = ({ stats }) => {
  const { statusStats, categoryStats } = stats;

  return (
    <Col className="dashboard-column dealer-stats-column" xs={12} xl={4}>
      <h2>Dealer Stats</h2>

      <Card style={{minHeight: '100px'}}>
        <Head>
          {numberWithDelimiter(stats.visibleDealerCount)}
          {' '}Dealers (excluding hidden)
        </Head>

        <Data className="dealer-stats">
          <table className="statuses">
            <tbody>
              <Status stats={statusStats} label="Active" />
              <Status stats={statusStats} label="On Hold" />
              <Status stats={statusStats} label="Title Hold" />
            </tbody>
          </table>

          <table className="statuses">
            <tbody>
              <Status stats={statusStats} label="Inactive" />
              <Status stats={statusStats} label="Written Off" />
            </tbody>
          </table>
        </Data>
      </Card>

      <Card style={{minHeight: '100px'}}>
        <Head>Dealer Types</Head>

        <Data className="dealer-stats">
          <table className="statuses">
            <tbody>
              <Category stats={categoryStats} label="Wholesaler" />
              <Category stats={categoryStats} label="BHPH" />
              <Category stats={categoryStats} label="Warehouse" />
            </tbody>
          </table>

          <table className="statuses">
            <tbody>
              <Category stats={categoryStats} label="Repair" />
              <Category stats={categoryStats} label="Appointment Only" />
            </tbody>
          </table>
        </Data>
      </Card>
    </Col>
  );
};

export default Dealers;
