import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const BRANCH_FIELDS = `
  id
  code
  name
  street
  city
  state
  canBeHidden
  zip {
    value
    formatted
  }
  phoneNumber {
    value
    formatted
  }
  attention
  email
  hiddenAt
`;

const BRANCH_FIELDS_AND_ERRORS = `
  branch {
    ${BRANCH_FIELDS}
  }
  ${ERRORS}
`;

export const BRANCHES = gql`
  query Branches($scopes: [String!]) {
    branches(
      scopes: $scopes
    ) {
      ${BRANCH_FIELDS}
    }
  }
`;

export const HIDE_BRANCH = gql`
  mutation HideBranch($id: ID!) {
    hideBranch(
      id: $id
    ) {
      ${BRANCH_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_BRANCH = gql`
  mutation UnhideBranch($id: ID!) {
    unhideBranch(
      id: $id
    )
    {
      ${BRANCH_FIELDS_AND_ERRORS}
    }
  }
`;
