import React, { useEffect, useState } from 'react';
import { Form as FormikForm, useFormikContext } from 'formik';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import * as BasicForm from '../../../../elements/forms';
import DataState from '../../../DataState';
import { renderChildrenWithProp } from '../../../../utils/hocHelpers';
import { useLocation } from 'react-router-dom';
import Select from './Select';
import { TXN_LINE_SELECTS } from '../Queries';

export const TxnLineInputs = ({ children, indexValue, ...props}) => {
  const { data, loading, error } = useQuery(TXN_LINE_SELECTS);
  const { values, setFieldValue, setValues } = useFormikContext();
  const [url, setUrl] = useState(true);
  const firstLine = 0 == indexValue;

  let location = useLocation();

  useEffect(() => {
    if (!(location.search.includes('detailed=t'))) {
      setUrl(false)
      setFieldValue(
        `txnLinesAttributes[${indexValue}].earnedOn`, values.bankDate
      )
    }
  }, [])

  if (loading) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const inputHeight = {
    height: '38px'
  }

  const childrenWithProps = renderChildrenWithProp(
    children, 'indexValue', indexValue
  );

  return (
    <>
      <Select
        fieldLabel="Account"
        field="accountId"
        lineIndex={indexValue}
        options={data.accounts}
        labelBuilder={account => `${account.gl} - ${account.name}`}
      />
      <Select
        fieldLabel="Market"
        field="marketId"
        lineIndex={indexValue}
        options={data.markets}
        isClearable
      />
      <Select
        fieldLabel="Department"
        field="departmentId"
        lineIndex={indexValue}
        options={data.departments}
        isClearable
      />
      {childrenWithProps}
      {url && <div>
        {firstLine && 'GL Date'}
        <BasicForm.JournalTextInput
          name={`txnLinesAttributes[${indexValue}].earnedOn`}
          type="date"
          style={inputHeight}
        />
      </div>}
      <div>
        {firstLine && 'Memo'}
        <BasicForm.JournalTextInput
          name={`txnLinesAttributes[${indexValue}].memo`}
          type="text"
          style={inputHeight}
        />
      </div>
    </>
  );
};

export default TxnLineInputs;
