import React, { useEffect, useState, useRef } from "react";
import * as BasicForm from "../../elements/forms";
import { useFormikContext } from "formik";
import { getQuoteData, PrincipalError } from "./Functions";
import * as StringHelper from "../../utils/stringHelpers";
import * as DateHelper from "../../utils/dateHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FeeDetails, feeButtonStyle, handleFeeToggle, handleChangeFeeAmount } from "./Fees";
import { formNumberStyle } from "./Form";
import { getSearchValues, searchLookup } from "./Search";
import Form from "react-bootstrap/Form";

const disabledArray = [];
const loadingArray = [];

const handleEnableItems = (disabledItems, setDisabledItems) => {
  setDisabledItems(!disabledItems);
};

const toggleWriteOff = (
  values,
  index,
  setFieldValue,
  disabledItems,
  setDisabledItems
) => {
  if (!values.quoteCarsAttributes[index]) return;
  handleEnableItems(disabledItems, setDisabledItems);

  let checked = values.quoteCarsAttributes[index]["paymentType"] === "writeoff";
  const paymentType = !checked ? "writeoff" : "";

  setFieldValue(`quoteCarsAttributes[${index}]['paymentType']`, paymentType);
};

export const FormBody = ({
  data,
  disabled,
  setPaymentTotal,
  setDisabled,
  paymentTotal,
  reordered,
  setReordered,
  checking,
  setCheckPrincipal,
  checkPrincipal,
  zeroPrincipal,
  setCheckInterest,
  checkInterest,
  zeroInterest,
  setCheckInsurance,
  checkInsurance,
  zeroInsurance,
  setCheckFee,
  checkFee,
  zeroFee
}) => {
  const {
    values,
    setValues,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormikContext();

  // Disable/Enable Submit Button
  if (
    disabledArray.includes(true) ||
    loadingArray.includes(true) ||
    paymentTotal === 0
  ) {
    setDisabled(true);
  } else setDisabled(false);

  let total = 0;

  data = data.map((car, index) => {
    let calculatedTotalDue = null;
    let principal = 0;
    let interest = 0;
    let insurance = 0;

    useEffect(() => {
      if (zeroPrincipal) {
        if (selected) {
          values.quoteCarsAttributes[index]["principal"] = 0
          principal = 0
          setFieldValue(`quoteCarsAttributes[${index}].principal`, principal)
        }
      } else {
        if (quoteData) {
          values.quoteCarsAttributes[index]["principal"] = quoteData.principal
          principal = quoteData.principal
          setFieldValue(`quoteCarsAttributes[${index}].principal`, principal)
        }
      }
    }, [zeroPrincipal])

    useEffect(() => {
      if (zeroInterest) {
        if (selected) {
          values.quoteCarsAttributes[index]["interest"] = 0
          interest = 0
          setFieldValue(`quoteCarsAttributes[${index}].interest`, interest)
        }
      } else {
        if (quoteData) {
          values.quoteCarsAttributes[index]["interest"] = quoteData.interest
          interest = quoteData.interest
          setFieldValue(`quoteCarsAttributes[${index}].interest`, interest)
        }
      }
    }, [zeroInterest])

    useEffect(() => {
      if (zeroInsurance) {
        if (selected) {
          values.quoteCarsAttributes[index]["insurance"] = 0
          insurance = 0
          setFieldValue(`quoteCarsAttributes[${index}].insurance`, insurance)
        }
      } else {
        if (quoteData) {
          values.quoteCarsAttributes[index]["insurance"] = quoteData.insurance
          insurance = quoteData.insurance
          setFieldValue(`quoteCarsAttributes[${index}].insurance`, insurance)
        }
      }
    }, [zeroInsurance])

    useEffect(() => {
      if (zeroFee) {
        if (selected) {
          quoteData?.feeDetails.forEach((fee, feeIndex) => {
            handleFeeToggle(feeDetails, fee, feeIndex, index, values, setFieldValue, index)
          })
        }
      } else {
        if (quoteData) {
          quoteData?.feeDetails.forEach((fee, feeIndex) => {
            if (fee.due === 0) {
              handleFeeToggle(feeDetails, fee, feeIndex, index, values, setFieldValue, index)
            }
          })
        }
      }
    }, [zeroFee])

    const [showFees, setShowFees] = useState(false);
    const [disabledItems, setDisabledItems] = useState(true);

    let selected = false;
    if (
      values.quoteCarsAttributes &&
      values.quoteCarsAttributes[index] &&
      values.quoteCarsAttributes[index]["paymentType"] === "writeoff"
    )
      selected = true;

    useEffect(() => {
      if (!selected) {
        if (checking) {
          toggleWriteOff(
            values,
            index,
            setFieldValue,
            disabledItems,
            setDisabledItems,
          )
        }
      } else if (!checking) {
        selected = false
        toggleWriteOff(
          values,
          index,
          setFieldValue,
          disabledItems,
          setDisabledItems,
        )
      }
    }, [checking]);

    useEffect(() => {
      setCheckPrincipal(!checkPrincipal)
    }, [selected])

    useEffect(() => {
      setCheckInterest(!checkInterest)
    }, [selected])

    useEffect(() => {
      setCheckInsurance(!checkInsurance)
    }, [selected])

    useEffect(() => {
      setCheckFee(!checkFee)
    }, [selected])

    let feeDetails;
    let quoteData = getQuoteData(car, index, reordered);

    let loading = (
      <p key={index} style={{ marginBottom: "0px" }}>
        loading...
      </p>
    );
    if (!values.quoteCarsAttributes || !values.quoteCarsAttributes[index])
      return loading;

    if (!quoteData) loadingArray[index] = true;

    if (quoteData) {
      quoteData = quoteData.quoteAmounts;
      loadingArray[index] = false;
    }

    const totalDue =
      StringHelper.currency(values.quoteCarsAttributes[index]?.totalDue) ||
      loading;

    if (
      values.quoteCarsAttributes &&
      values.quoteCarsAttributes[index] &&
      quoteData
    ) {
      insurance =
        values.quoteCarsAttributes[index]["insurance"] === ""
          ? 0
          : parseFloat(values.quoteCarsAttributes[index]["insurance"]);

      interest =
        values.quoteCarsAttributes[index]["interest"] === ""
          ? 0
          : parseFloat(values.quoteCarsAttributes[index]["interest"]);

      principal =
        values.quoteCarsAttributes[index]["principal"] === ""
          ? 0
          : parseFloat(values.quoteCarsAttributes[index]["principal"]);

      if (quoteData.feeDetails) {
        const currentFeeIds = Object.keys(
          values.quoteCarsAttributes[index]["feeDetails"]
        );

        if (quoteData.feeDetails.length === 0) {
          const feeDue = 0;
          currentFeeIds.forEach((fee, feeIndex) => {
            values.quoteCarsAttributes[index]["feeDetails"][feeIndex] = {
              [fee]: { amount: parseFloat(feeDue) },
            };
          });
        } else {
          quoteData.feeDetails.forEach((fee, feeIndex) => {
            const feeId = fee.id;
            let feeDue = parseFloat(fee.due);

            values.quoteCarsAttributes[index]["feeDetails"][feeIndex] = {
              [feeId]: { amount: feeDue },
            };
          });
        }
      }
    }

    // Fees
    let totalFees = 0;
    if (quoteData) {
      let currentFees = values.quoteCarsAttributes[index]["feeDetails"];
      currentFees = currentFees.filter((fee) => fee !== undefined);

      currentFees.map((fee) => {
        const amount = parseFloat(Object.values(fee)[0].amount);

        totalFees += amount;
      });

      feeDetails = quoteData.feeDetails;

      // Total Due
      calculatedTotalDue = principal + totalFees + interest + insurance;

      if (values.quoteCarsAttributes[index]["paymentType"] !== "") {
        total += calculatedTotalDue;
      }

      setPaymentTotal(total);

      calculatedTotalDue = StringHelper.currency(calculatedTotalDue);
    }

    // arbitrated
    let arbitrated = "";
    if (car.arbitratedOn === null || "") {
      arbitrated = "No";
    } else {
      arbitrated = "Yes";
    }

    // Search Functionality
    let searchValues = getSearchValues(car);
    let valueInSearch = searchLookup(searchValues, values);
    if (valueInSearch === false) return null;

    return (
      <>
        <tr key={index}>
          <td style={formNumberStyle}>{car.unitNumber}</td>
          <td>
            {car.vin}
            {
              car.scheduledAchId &&
                <div style={{color: 'red'}}>ACH PAYMENT SCHEDULED</div>
            }
          </td>
          <td>{car.year}</td>
          <td style={{ flexWrap: "wrap" }}>{car.make}</td>
          <td style={{ flexWrap: "wrap" }}>{car.model}</td>
          <td>{DateHelper.setDate(car.loanDate)}</td>
          <td>{DateHelper.setDate(car.currentDueOn)}</td>
          <td style={formNumberStyle}>{car.termData?.totalDays}</td>
          <td style={formNumberStyle}>{arbitrated}</td>
          <td>
            <BasicForm.WriteOffInput
              name={`quoteCarsAttributes[${index}][principal]`}
              type="number"
              touched={touched}
              onBlur={handleBlur}
              disabled={!selected}
              onFocus={(e) => e.target.select()}
            />
          </td>
          <td style={{ width: "110px" }}>
            {(quoteData && `${StringHelper.currency(totalFees)}`) || loading}
            {quoteData &&
              showFees &&
              values.quoteCarsAttributes[index]["paymentType"] !==
                "principal" && (
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  className="fee-details-button"
                  style={feeButtonStyle}
                  onClick={() => setShowFees(!showFees)}
                />
              )}
            {quoteData &&
              !showFees &&
              values.quoteCarsAttributes[index]["paymentType"] !==
                "principal" && (
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={feeButtonStyle}
                  className="fee-details-button"
                  onClick={() => setShowFees(!showFees)}
                />
              )}
            {showFees && (
              <FeeDetails
                feeDetails={feeDetails}
                index={index}
                disabledItems={!selected}
              />
            )}
          </td>
          <td>
            <BasicForm.WriteOffInput
              name={`quoteCarsAttributes[${index}][interest]`}
              type="number"
              touched={touched}
              onBlur={handleBlur}
              disabled={!selected}
              onFocus={(e) => e.target.select()}
            />
          </td>
          <td>
            <BasicForm.WriteOffInput
              name={`quoteCarsAttributes[${index}][insurance]`}
              type="number"
              touched={touched}
              onBlur={handleBlur}
              disabled={!selected}
              onFocus={(e) => e.target.select()}
            />
          </td>
          <td style={Object.assign({}, formNumberStyle, { minWidth: "120px" })}>
            {calculatedTotalDue || loading}
          </td>
          <td>
            <Form.Check
              label="Write Off"
              checked={
                (values.quoteCarsAttributes[index]["paymentType"] === "writeoff")
              }
              onChange={() =>
                toggleWriteOff(
                  values,
                  index,
                  setFieldValue,
                  disabledItems,
                  setDisabledItems
                )
              }
            />
          </td>
        </tr>
        <PrincipalError
          values={values}
          index={index}
          disabledArray={disabledArray}
        />
      </>
    );
  });

  setReordered(false);
  return data;
};
