import React from 'react';
import styled from '@emotion/styled';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';
import SelectFromEnum from 'components/src/elements/SelectFromEnum';

const Hint = styled.p`
  margin-top: 0.6em;
  font-size: 0.8em;
  color: #303030;
`;

export const accountGroupSchema = yup.object({
  name: yup
    .string()
    .required('Required')
    .max(50, 'Maximum length for Account Group name is 50 characters'),
  increasedBy: yup
    .string()
    .required('Required'),
})

const selects = [
  [
    'bank',
    'Bank',
    `Included in selects for "Pay From" or "Deposit To" options, including in
    manual payments and advances, and On Account and Dealer Reserve
    withdraws and deposits`
  ],
  [
    'accountsPayable',
    'Accounts Payable',
    'Available for A/P lines when entering bills.'
  ],
  [
    'income',
    'Income',
    'Income account options when setting up a fee.'
  ],
  [
    'expense',
    'Expense',
    'Expense account options for setting defaults on vendors.'
  ],
  [
    'loan',
    'Loan',
    'Loan payment bills'
  ],
  [
    'nonPosting',
    'Non-Posting'
  ]
];

export const Form = () => {
  const { isValid } = useFormikContext();
  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <SelectFromEnum
        name="increasedBy"
        label="Increased By"
        modelName="AccountGroup"
      />
      {
        selects.map(([key, label, usage]) =>
          <>
            <BasicForm.Checkbox
              name={key}
              key={key}
              type="checkbox"
              label={label}
            />
            <Hint>{usage}</Hint>
          </>
        )
      }
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" disabled={!isValid} />
      </div>
    </FormikForm>
  );
}
