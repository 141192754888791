import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import DataState from '../../DataState';
import { gql, useQuery } from '@apollo/client';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import { useLocation } from 'react-router-dom';
import { TXN_NAME_FIELD_SELECTS } from './Queries';

export const TxnForm = (props) => {
  const { referenceLabel, children, typeLabel, nameLabel, dateLabel } = props
  const [url, setUrl] = useState(true)
  let location = useLocation()

  const { values, setFieldValue, setValues, errors } = useFormikContext();
  const {
    data: selectData,
    loading: selectLoading,
    error: selectError
  } = useQuery(TXN_NAME_FIELD_SELECTS);

  useEffect(() => {
    values.txnLinesAttributes.map(line => {
      line.earnedOn = values.bankDate;
      setValues(values);
    })
  }, [values.bankDate] )

  let entities;

  useEffect(() => {
    if (values.typeName !== values.entityType) {
      values.name = '';
      values.entityType = values.typeName;
      values.entityId = '';
    }

    setValues(values);
  }, [values.typeName])

  useEffect(() => {
    if (entities && values.entityId) {
      const entity = entities.find(possible => possible.id === values.entityId);
      values.name = entity.name;

      setValues(values);
    } else if (values.entityType) {
      values.name = '';

      setValues(values);
    }

    if (entities) {
      if (values.entityType === 'Vendor' && values.entityId) {
        const accountEntity = entities.find(
          (value) => parseInt(value.id) === parseInt(values.entityId)
        )

        if (accountEntity.payableAccount) {
          setFieldValue('payableAccountId',
            parseInt(accountEntity.payableAccount.id)
          )
        } else {
          setFieldValue('payableAccountId', '')
        }
        if (!values.txnLinesAttributes[0].accountId) {
          if (accountEntity.expenseAccount) {
            values.txnLinesAttributes[0].accountId = parseInt(
              accountEntity.expenseAccount.id
            )
          }
        }
      }
    }
  }, [values.entityId]);

  useEffect(() => {
    if (location.pathname === '/financial/journal-entries/new') {
      if (!(location.search.includes('detailed=t'))) {
        setUrl(false)
      }
    }
  }, [])

  if (selectLoading) return <DataState.Loading />
  if (selectError) return <DataState.Error error={selectError} />;

  const { dealers, markets, auctions, approvedSources, vendors } = selectData;

  if (values.entityType === "Dealer") {
    entities = dealers
  } else if (values.entityType === "Auction") {
    entities = auctions
  } else if (values.entityType === "ApprovedSource") {
    entities = approvedSources
  } else if (values.entityType === "Vendor") {
    entities = vendors
  }

  const formattedEntities = entities?.map((entity) => {
    return {
      value: `${entity.__typename}.${entity.id}`,
      label: `${entity.name}  (${entity.__typename})`,
      type: entity.__typename,
      id: entity.id,
      name: entity.name,
      paymentTermId: entity.paymentTerm?.id
    }
  })

  return (
    <>
      <BasicForm.TextInput
        name="bankDate"
        type="date"
        label={dateLabel || "Bank Date"}
      />
      { url &&
        <BasicForm.Select
          name="typeName"
          type="text"
          label={typeLabel || 'Name Type'}
        >
          <option value="">Select a Type</option>
          <option>Dealer</option>
          <option>Vendor</option>
          <option>Auction</option>
          <option value="ApprovedSource">Approved Source</option>
        </BasicForm.Select>
      }
      {url && (() =>
        {if (values.typeName) {
          return (
            <BasicForm.Select
              name="entityId"
              type="text"
              label={nameLabel || "Name"}
            >
              <option></option>
              {
                entities && entities.map(entity => {
                  return (
                    <option key={entity.id} value={entity.id}>
                      {entity.name}
                    </option>
                  );
                })
              }
            </BasicForm.Select>
          );
        } else {
          return (
            <BasicForm.TextInput
              name="name"
              type="text"
              label={nameLabel || "Name"}
            />
          )
        }
      })()}
      { children }
      <BasicForm.TextInput
        name="reference"
        type="text"
        label={referenceLabel || "Check Number/Reference"}
      />
      <BasicForm.TextInput
        name="memo"
        type="text"
        label="Memo"
      />
    </>
  );
};

export default TxnForm;
