import React from 'react';
import Modal from 'react-bootstrap/Modal';
import * as BasicForm from "../../../elements/forms";
import * as Buttons from '../../../elements/buttons'
import { Spacing } from '../../../styles';
import { useFormikContext } from 'formik';
import { Form as FormikForm } from 'formik';

export const Form = ({ showModal, setShowModal, handleSubmit, txn }) => {
  const { values, isValid, dirty } = useFormikContext();

  const handleClose = () => setShowModal(false)

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Reverse Transaction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <FormikForm>
            <BasicForm.TextInput
              name="bankDate"
              type="date"
              label="Bank Date"
              labelStyle={labelStyle}
            />
            <BasicForm.TextInput
              name="reference"
              label="JE Number"
              type="text"
            />
            <BasicForm.TextArea
              name="memo"
              label="Memo"
              type="text"
            />
            <div style={Spacing.buttonRow}>
              <Buttons.ModalCancel
                type="cancel"
                text="Cancel"
                handleClose={handleClose}
              />
              <Buttons.Standard
                type="submit"
                text="Submit"
                onClick={() => handleSubmit(values)}
                disabled={!isValid || !dirty}
              />
            </div>
          </FormikForm>
        </Modal.Body>
      </Modal>
    </>
  )
}

let modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px',
}

const labelStyle = {
    marginTop: "0px",
};
