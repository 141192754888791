// https://benjaminbrooke.me/posts/react-form-validation-with-graphql-formik-and-yup/
import { titleCase } from './stringHelpers';

export const normalizeErrors = errors => {
  return errors.reduce((accumulator, value) => {
    if ('attributes' === value.path[0]) {
      accumulator[value.path[1]] = value.message;
    } else {
      accumulator[value.path] = value.message;
    }

    return accumulator;
  }, {});
};

export const formatError = error => {
  return error && error[0].toUpperCase() + error.slice(1)
};

export const getServerSideErrors = values => {
  const key = Object.keys(values)[0];
  const errors = values[key].errors;
  if (errors.length) {
    let errorString = '';
    let errorName = '';
    let errorMessage = '';
    errors.forEach((error, index) => {
      errorName = error.path[1];
      errorMessage = error.message;
      const separator = index > 0 ? ', ' : '';
      errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`;
    });

    return errorString;
  } else {
    return '';
  }
};
