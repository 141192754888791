import React, { useRef } from "react";

export const setParamaterData = ({initialSort, initialSortDirection}) => {
  let currentPage = useRef(1);
  let offset = useRef(0);
  let sort = useRef(initialSort || null);
  let sortDirection = useRef(initialSortDirection || 'asc');
  let sortColumnIndex = useRef(0);
  let search = useRef(null);

  return [
    offset,
    currentPage,
    sort,
    sortDirection,
    sortColumnIndex,
    search
  ];
};
