import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useAlert} from '../../../hooks/useAlert';
import Info from '../Info';
import DataState from '../../DataState';
import AuctionTokensTable from '../../AuctionTokens';
import { useHideableResource } from '../../../hooks/useHideableResource';
import { setUniqueAlertData } from '../../../utils/setAlertData';
import { APPROVED_SOURCES_AUCTION_TOKENS, HIDDEN_APPROVED_SOURCES_AUCTION_TOKENS } from '../../../queries/indexQueries';
import New from './New';

const HIDE_AUCTION_TOKEN = gql`
  mutation HideAuctionToken($id: ID!) {
    hideAuctionToken(
      id: $id
    ) {
      auctionToken {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const UNHIDE_AUCTION_TOKEN = gql`
  mutation UnhideAuctionToken($id: ID!) {
    unhideAuctionToken(
      id: $id
    ) {
      auctionToken {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const index = (props) => {
  const id = useParams().id;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const ownerType = 'ApprovedSource';

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_APPROVED_SOURCES_AUCTION_TOKENS,
    VISIBLE_QUERY: APPROVED_SOURCES_AUCTION_TOKENS,
    HIDE_MUTATION: HIDE_AUCTION_TOKEN,
    UNHIDE_MUTATION: UNHIDE_AUCTION_TOKEN,
    variables: { id }
  };

  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)

  let currentData = null;

  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const { approvedSource: { auctionTokens } } = data;

  return (
    <>
      <Helmet>
        <title>Auction Tokens</title>
      </Helmet>
      {
        show && <New
          show={show}
          handleClose={handleClose}
          ownerType={ownerType}
          ownerId={id}
          gqlQuery={APPROVED_SOURCES_AUCTION_TOKENS}
        />
      }
      <Info />
      <AuctionTokensTable auctionTokens={auctionTokens} setShowHidden={setShowHidden} showHidden={showHidden} handleShow={handleShow} />
    </>
  );
};

export default index;
