import React, { Fragment, useState } from 'react';
import Form from 'react-bootstrap/Form'
import DataState from '../DataState';
import { gql, useQuery } from '@apollo/client';
import DataTable from '../../elements/DataTable';
import { DateHelpers, StringHelpers } from '../../utils';
import { insertTableData } from '../../elements/Pagination/Functions';
import { responsiveDefaults } from '../../utils/datatableHelpers';
import { Spacing, Typography } from '../../styles';

const REPORTS = gql`
  query {
    reports {
      id
      reportName
      createdBy { firstName, lastName, fullName, id }
      fileUrl
      createdAt
      reportStartOn
      reportEndOn
      params
    }
    currentUser {
      id
    }
  }
`;

const columns = [
  {
    title: 'Report Name'
  },
  {
    title: 'Report Start On',
    render: DateHelpers.renderDate
  },
  {
    title: 'Report End On',
    render: DateHelpers.renderDate
  },
  {
    title: 'Created At',
    render: DateHelpers.renderDate
  },
  {
    title: 'Created By'
  },
  {
    title: 'Additional Params'
  },
  {
    title: '',
  }
]

const AllReports = () => {
  let { loading, error, data } = useQuery(REPORTS);
  const [displayMyReports, setDisplayMyReports] = useState(false);
  const tableId = '#all-reports';
  const datatable = $(tableId).DataTable(responsiveDefaults);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { reports, currentUser } = data;

  let dataSet = reports.map((report) => {
    const pulledByMe = (currentUser.id === report.createdBy.id)
    if (displayMyReports && !pulledByMe) return null;

    const createdAt = DateHelpers.setDate(report.createdAt);
    const createdBy = report.createdBy.fullName;

    let url = 'File Not Available'
    if (report.fileUrl) url = `<a href=${report.fileUrl}>Click to Download</a>`

    let params = [];
    if (report.params) {
     const keys = Object.keys(report.params)
      params = keys.map((key) => {
        return `<strong>${key}</strong>: ${report.params[key]}`
      })
    }

    return [
      report.reportName,
      report.reportStartOn || '',
      report.reportEndOn || '',
      createdAt,
      createdBy,
      params.join('<br>'),
      url,
    ]

  })

  dataSet = dataSet.filter((val) => val)

  insertTableData(datatable, dataSet);

  return (
    <Fragment>
    <div style={Object.assign({}, Spacing.formWrapper, {marginLeft: '20px'})}>
        <Form>
        <Form.Group controlId="pulledByMe" style={{marginBottom: '0px'}}>
          <Form.Check
            type="checkbox"
            label="Pulled by Me"
            onClick={() => setDisplayMyReports(!displayMyReports)}
          />
        </Form.Group>
        </Form>
      </div>
      <DataTable
        tableId={tableId}
        dataSet={dataSet}
        columns={columns}
        pageTitle={'All Reports'}
        unhideable={true}
        uncreatable={true}
      />
    </Fragment>
  )
}

export default AllReports;
