import React from 'react';
import AuthLink from 'components/src/components/AuthLink';
import { ColTitle, ReportLink } from '../index';

const General = () => {
  return (
    <>
      <ColTitle>General</ColTitle>
      <AuthLink
        to={'/reports/report/aging'}
        resource="FlooredCar"
        action="read"
        component={ReportLink}
      >
        Aging Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/cars'}
        resource="FlooredCar"
        action="read"
        component={ReportLink}
      >
        All Cars Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/cars-by-loan-date'}
        resource="FlooredCar"
        action="read"
        component={ReportLink}
      >
        Cars by Loan Date
      </AuthLink>

      <AuthLink
        to={'/reports/report/waivers'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Waivers Report
      </AuthLink>
    </>
  );
};

export default General;
