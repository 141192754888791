import { columns } from './index';

export const getColumnName = (columnIndex) => {
  const currentColumns = columns.map((column) => {
    let title = column.title.toLowerCase().split(' ').join('_');
    if (title === 'unit_#') title = 'unit_number';
    if (title === 'age_(days)') title = 'age';

    return title
  })

  const unFilterableColumns = ['actions', 'functions']


  const sortedColumn = currentColumns[columnIndex];

  return unFilterableColumns.includes(sortedColumn)
    ? null
    : sortedColumn
}
