import React from 'react';
import * as yup from 'yup';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';

export const fileSchema = yup.object({
  name: yup.string().required('Required'),
  type: yup.string().required('Required'),
  file: yup.object({
    path: yup.string().required('Required')
  }).nullable()
});

export const Form = () => {
  const { setFieldValue, values } = useFormikContext();

  const handleFile = (file) => {
    setFieldValue('file', file)
  }

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.ImageUpload
        name="file"
        handleFile={handleFile}
        label="Image"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}
