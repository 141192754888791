import React from 'react';
import { setDate } from 'components/src/utils/dateHelpers';
import { Link } from 'react-router-dom';
import { currency } from 'components/src/utils/stringHelpers';
import AuthLink from 'components/src/components/AuthLink';

export const columnDefinitions = props => {

  const columns = [
    {
      key: 'inspectionOn',
      label: 'Lot Audit Date',
      sort: 'desc',
      content: ({ value }) => setDate(value)
    },
    {
      key: 'accountNumber',
      label: 'Account Number',
      minViewportWidth: 1800,
      content: ({ entry }) => entry.dealer.accountNumber,
    },
    {
      key: 'market',
      label: 'Market',
      minViewportWidth: 800,
      content: ({ entry }) => entry.dealer.market.name
    },
    {
      key: 'name',
      label: 'Dealer Name',
      content: ({ entry }) =>
        <Link to={`/dealers/${entry.dealer.id}`}>{entry.dealer.name}</Link>
    },
    {
      key: 'legalName',
      label: 'Legal Name',
      minViewportWidth: 1900,
      content: ({ entry }) => entry.dealer.legalName
    },
    {
      key: 'status',
      label: 'Dealer Status',
      minViewportWidth: 800,
      content: ({ entry }) => entry.dealer.dealerStatus.name
    },
    {
      key: 'dealerCategory',
      label: 'Dealer Type',
      minViewportWidth: 1800,
      sort: 'prevent',
      content: ({ entry }) => {
        const dealerTypes = []

        entry.dealer.dealerCategories.map(category => {
          dealerTypes.push(category.name)
        })

        return dealerTypes.join(', ')
      }
    },
    {
      key: 'auditStatus',
      label: 'Lot Audit Status',
      content: ({ entry }) => {
        if (entry.auditClosedDate) {
          return "Closed"
        } else {
          return "Open"
        }
      }
    },
    {
      key: 'numberOfUnits',
      label: 'No. of Floored Cars'
    },
    {
      key: 'unitsUnseen',
      label: 'Units Missing At Audit',
    },
    {
      key: 'unverifiedAuditCarsCount',
      minViewportWidth: 1100,
      label: 'Currently Unverified / Unpaid Units',
    },
    {
      key: 'foundPercentage',
      label: 'Found Percentage',
      minViewportWidth: 1200,
      content: ({ value }) => value ? `${value}%` : '',
    },
    {
      key: 'averageFoundPercentage',
      label: 'Avg Found Last 6 Audits',
      minViewportWidth: 1200,
      content: ({ value }) => value ? `${value}%` : '',
    },
    {
      key: 'totalCurrentBalance',
      label: 'Outstanding Balance',
      minViewportWidth: 1400,
      content: ({ entry }) => currency(entry.totalCurrentBalance)
    },
    {
      key: 'creditLine',
      label: 'Credit Line',
      minViewportWidth: 1200,
      content: ({ entry }) => currency(entry.dealer.creditLine)
    },
    {
      key: 'timeToClose',
      label: 'Days to Close',
      content: ({ entry }) =>
        entry.timeToClose !== null ? entry.timeToClose : ''
    },
    {
      key: 'foundUnitValue',
      label: 'Found Units Value',
      minViewportWidth: 1600,
      content: ({ entry }) => currency(entry.foundUnitValue)
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => {
        return (
          <AuthLink
            to={`/dealers/${entry.dealer.id}/audits/${entry.id}`}
            resource="Audit"
            action="Read"
          >
            View
          </AuthLink>
        )
      }
    }
  ];

  return columns
}
