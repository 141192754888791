import React from "react";
import { useParams } from 'react-router-dom';
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import Deltas from "../../../Deltas/";
import DataState from '../../../DataState';

const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      dealerLine {
        deltas {
          id
          action
          data
          isImport
          createdAt
          updatedAt
          user
        }
      }
    }
  }
`;

const index = () => {
  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(DEALER, { variables: { id } });

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

 const { deltas } = data.dealer.dealerLine;

  return (
    <Deltas
    resourceName={'Dealer Line'}
    deltas={deltas}
    tableId="#dealerLineDeltas"
   />
   );
};

export default index;
