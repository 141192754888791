import React from "react";
import { Col, Row } from "react-bootstrap";
import FeeHeaders from "./FeeHeader";

const TableHeaders = ({ termLinesAttributes, values, index, allowInterval, columnStyle }) => {
  return (
    <Row>
      <Col style={columnStyle.smallDiv}>For Principal Value Below*</Col>
      <Col style={columnStyle.smallDiv}>Curtailment % at the end of the Term</Col>
      <FeeHeaders
        values={values}
        termLinesAttributes={termLinesAttributes}
        index={index}
        allowInterval={allowInterval}
        columnStyle={columnStyle}
      />
      {allowInterval && <Col style={columnStyle.smallDiv}>Interval</Col>}
    </Row>
  );
};

export default TableHeaders;
