const dasherizeKeys = (object) => {
  const retval = {};

  Object.keys(object).forEach(key => {
    const newKey = key.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

    if ('' === object[key]) {
      retval[newKey] = null;
    } else {
      retval[newKey] = object[key];
    }
  });

  return retval;
}

export default dasherizeKeys;
