import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const Select = ({columnKey, label, options, filters, setFilter}) => {
  return (
    <Row>
      <Col>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          value={filters[columnKey] || ''}
          onChange={event => setFilter(columnKey, event.currentTarget.value)}
        >
          <option key="0" value="">
            Filter by {label}
          </option>
          {
            options &&
              options.map(value => <option key={value}>{value}</option>)
          }
        </Form.Control>
      </Col>
    </Row>
  );
};

export default Select;
