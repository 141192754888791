import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as BasicForm from '../../../elements/forms';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';
import { TXN_NAME_FIELD_SELECTS } from './Queries';

const Hint = styled.p`
  margin-top: 0.6em;
  font-size: 0.8em;
  color: #303030;
`;

export const PaymentTermInput = () => {
  const { values, setFieldValue } = useFormikContext();
  const {
    data: selectData,
    loading: selectLoading,
    error: selectError
  } = useQuery(TXN_NAME_FIELD_SELECTS);
  const [calculatedDate, setCalculatedDate] = useState(null)

  useEffect(() => {
    if (values.paymentTermId) {
      setFieldValue('paymentTermId', parseInt(values.paymentTermId))
      let date = new Date(values.bankDate)
      let dueDate = new Date()
      date.setHours(23)
      date.setDate(date.getDate() + 1)
      selectData?.paymentTerms.map((term) => {
        if (parseInt(values.paymentTermId) === parseInt(term.id)) {
          date.setDate(date.getDate() + term.days)
          var dd = date.getDate();
          var mm = date.getMonth() + 1;
          var y = date.getFullYear();
          var someFormattedDate = mm + '/' + dd + '/' + y;
          setCalculatedDate(someFormattedDate)
        }
      })
    } else {
      setCalculatedDate(null)
    }
  }, [selectData, values])

  if (selectLoading) return <DataState.Loading />
  if (selectError) return <DataState.Error error={selectError} />;

  return (
    <>
      <BasicForm.Select
        name="paymentTermId"
        label="Payment Term"
        value={values.paymentTermId || ''}
      >
        <option value="">Select a Payment Term</option>
        { selectData.paymentTerms.map((term) => {
          return <option value={term.id} key={term.id}>{term.name}</option>
        })}
      </BasicForm.Select>
      {
        calculatedDate &&
        <Hint>Due Date: {calculatedDate}</Hint>
      }
    </>
  )
}

export default PaymentTermInput
