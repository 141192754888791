import React from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as Buttons from '../../../elements/buttons';
import * as BasicForm from '../../../elements/forms';
import { Spacing } from '../../../styles';

export const unsoldSchema = yup.object({
  note: yup.string().required('Required')
})

export const Form = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name='note'
        type='text'
        label='Note'
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Standard type='submit' text='Submit' />
      </div>
    </FormikForm>
  )
}
