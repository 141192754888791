import React, { useEffect, useRef } from 'react';
import * as BasicForm from '../../../../../elements/forms';
import { useFormikContext } from 'formik';
import { AdvanceTypeOptions } from './advanceTypeOptions';
import { ExternalTypeOptions } from './externalTypeOptions';
import OnAccount from './onAccountOptions';
import { ErrorMessage } from '../../../../../elements/ErrorMessage';
import { backgroundStyle } from '../../../../Cars/Style';

export const divStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  alignItems: 'baseline',
}

export const inputStyle = {
  marginLeft: '12px',
  width: '173px',
  height: '30px',
}

export const AdvanceType = () => {
  const { setFieldValue, values, values: {advanceType} } = useFormikContext();
  const advanceTypeRef = useRef('source')
  let displayAccountSelect = false;

  // for some reason initialValues doesn't recognize an initial advanceType value
  // so this is needed
  useEffect(() => {
    setFieldValue('advanceType', 'source')
  }, []);

  useEffect(() => {
      if (advanceTypeRef.current !== values.advanceType && values.advanceType !== '') {
        setFieldValue('sourceId', ''),
        setFieldValue('sourceType', '')
        setFieldValue('txnType', '')
        setFieldValue('name', '')
        setFieldValue('reference', '')
        setFieldValue('bankAccountId', '')
        advanceTypeRef.current = values.advanceType
      }

      if (advanceType === 'on_account') setFieldValue('sourceType', 'Dealer');

    }, [advanceType])

  return <div className="container" style={backgroundStyle}>

  <div style={divStyle}>
      <BasicForm.StyledLabel>Select Payment Type:</BasicForm.StyledLabel>
      <BasicForm.Radio
        name="advanceType"
        type="radio"
        label="Initiate an ACH Source From The System"
        value="source"
        checked={values.advanceType === 'source'}
        />
      <BasicForm.Radio
        name="advanceType"
        type="radio"
        label="Manual Payment Method"
        value="external"
        />
      <BasicForm.InlineStyledErrorMessage>
        <ErrorMessage name='advanceType'/>
      </BasicForm.InlineStyledErrorMessage>
    </div>
    { advanceType && advanceType === 'source' &&
      <AdvanceTypeOptions />
    }
    { advanceType && advanceType === 'external' &&
      <ExternalTypeOptions />
    }
    { advanceType && advanceType === 'on_account' &&
      <OnAccount />
    }
    </div>
}
