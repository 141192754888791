import React from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { FLOORED_CAR } from '../../../queries/viewQueries';
import { Spacing } from '../../../styles';
import { Form } from './Form';
import { SOT } from 'components/src/components/Cars/Queries.js';

const SotModal = (props) => {
  let {
    show,
    handleClose,
    name,
  } = props;

  const id = parseInt(useParams().carId);

  const [sot] = useMutation(SOT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FLOORED_CAR, variables: { id } }],
    onCompleted(variables) {
      handleClose();
    }
  });

  const handleSubmit = (values) => {
    if (values.sotOn === '') values.sotOn = null;

    sot({ variables: values })
  }

  const ModalBody = () => {
    return (
      <div style={Spacing.formWrapper}>
        <Formik
          initialValues={{
            id,
            sotOn: '',
          }}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`Mark as SOT`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalBody />
      </Modal.Body>
    </Modal>
  )
}

export default SotModal;

