import React from 'react';
import * as BasicForm from '../../../elements/forms';

export const TypeInput = () => {
  return (
    <>
      <BasicForm.Select name="type" label="Type">
        <option value="ACH">ACH</option>
        <option value="Check">Check</option>
        <option value="Journal Entry">Journal Entry</option>
        <option value="Wire">Wire</option>
        <option value="Cash">Cash</option>
        <option value="Bill">Bill</option>
        <option value="Vendor Credit">Vendor Credit</option>
      </BasicForm.Select>
    </>
  );
};

export default TypeInput;
