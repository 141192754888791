import { gql } from '@apollo/client';

export const MARKET_STATS = gql`
  query MarketStats(
    $marketIds: [ID!]
  ) {
    marketStats(
      marketIds: $marketIds
    ) {
      averageDaysOnAccountTTM: averageDaysOnAccount(months: 12)
      averageDaysOnAccount90Days: averageDaysOnAccount(days: 90)
      averageDaysOnAccountUnpaid
      borrowedTitles
      borrowedTitlesDue
      delinquentAmount
      delinquentRate
      grossYield
      netYield
      openAuditsCount
      openAuditsCount7Days: openAuditsCount(days: 7)
      openNsfs
      soldCount
      soldCount7Days: soldCount(days: 7)
      unverifiedCount
      unverifiedCount7Days: unverifiedCount(days: 7)
      poas
      cpps
      statusStats {
        dealerStatus {
          id
          name
        }
        count
        rate
      }
      categoryStats {
        dealerCategory {
          id
          name
        }
        count
        rate
      }
      visibleDealerCount
    }
  }
`;
