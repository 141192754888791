import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const NOTE_FIELDS = `
  id
  createdAt
  subject
  note
  statusAt
  status
  important
  collection
  corporate
  hiddenAt
  canBeHidden
`;

const NOTE_FIELDS_AND_ERRORS = `
  note {
    ${NOTE_FIELDS}
  }
  ${ERRORS}
`;

export const NOTES_SUBQUERY = `
  notes(scopes: $scopes) {
    ${NOTE_FIELDS}
    createdBy {
      id
      fullName
    }
    statusBy {
      id
      fullName
    }
  }
`;

export const NOTE = gql`
  query NOTE($id: ID!) {
    note(id: $id) {
      ${NOTE_FIELDS}
    }
  }
`;

const NOTE_VARIABLES = `
  $subject: String!
  $note: String
  $important: Boolean
  $collection: Boolean
  $corporate: Boolean
`;

const NOTE_ARGUMENTS = `
  subject: $subject
  note: $note
  important: $important
  collection: $collection
  corporate: $corporate
`;

export const CREATE_NOTE = gql`
  mutation CreateNote(
    $ownerId: Int!
    $ownerType: String!
    ${NOTE_VARIABLES}
  ) {
    createNote (
      ownerId: $ownerId
      ownerType: $ownerType
      ${NOTE_ARGUMENTS}
    ) {
      ${NOTE_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote(
    $id: ID!
    ${NOTE_VARIABLES}
  ) {
    updateNote(
      id: $id
      ${NOTE_ARGUMENTS}
    ) {
      ${NOTE_FIELDS_AND_ERRORS}
    }
  }
`;

export const HIDE_NOTE = gql`
  mutation HideNote($id: ID!) {
    hideNote(
      id: $id
    ) {
      ${NOTE_FIELDS_AND_ERRORS}
    }
  }
`;

export const UNHIDE_NOTE = gql`
  mutation UnhideNote($id: ID!) {
    unhideNote(
      id: $id
    ) {
      ${NOTE_FIELDS_AND_ERRORS}
    }
  }
`;
