import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import DataTables from '@preflighttech/preflight-tables';
import Header from '../Header';
import { preflightTableStyles } from 'components/src/styles';
import {
  columns as sharedAuditCarColumns
} from 'components/src/components/Dealers/Audits/Show';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';
import { rest } from 'components/src/utils/rest';
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import { ExportToExcelAlert } from 'components/src/elements/BasicAlert';
import { UserContext } from 'components/src/contexts/UserContext';
import {
  dealerColumn
} from 'components/src/components/Dealers/columnDefinitions';
import { AUDIT_CARS } from './queries';

const auditCarColumns = sharedAuditCarColumns.filter(column => (
  ![
    'qtFound', 'qtException', 'verified', 'verificationMethod', 'location'
  ].includes(column.key)
));

const columns = [
  dealerColumn('market'),
  dealerColumn('dealerName'),
  dealerColumn('legalName'),
  dealerColumn('dealerStatus'),
  dealerColumn('dealerCategories'),
  dealerColumn('creditLine'),
  dealerColumn('currentPrincipal'),
  dealerColumn('unverifiedAuditCarsCount'),
  ...auditCarColumns
]

columns[columns.length - 1] = {
  ...columns[columns.length - 1],
  content: ({ entry }) => (
    <>
      <Link to={`${entry.flooredCar.webPath}/payments`}>
        Setup Payment
      </Link>
      {!entry.flooredCar.soldOn && (
        <>
          {' | '}
          <Link to={`${entry.flooredCar.webPath}/audits`}>
            Verify
          </Link>
        </>
      )}
    </>
  )
};

const Unverified = () => {
  const user = useContext(UserContext);
  const [customAlert, displayCustomAlert] = useState(false);
  const [settings, setSettings] = useState({});

  const queryVariables = {
    filterColumns: ['marketId'],
    filterNames: [user.filterMarketIds() || ''],
    scopes: ['unverified'],
  };

  const { data } = useQuery(AUDIT_CARS, { variables: queryVariables });

  const exportOptions = {
    path: 'report/audit-cars-unverified',
    params: {
      sort: settings.order?.[0]?.key,
      sort_direction: settings.order?.[0]?.direction,
      current_filters: queryVariables
    }
  }

  const handleExportToExcel = () => {
    rest(exportOptions).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const handleExportToPdf = () => {
    const FileDownload = require('js-file-download');

    rest({
      path: exportOptions.path,
      params: {...exportOptions.params, immediate: 'y'}
    }).then(res => {
      var date = new Date();
      var timestamp = date.getTime();

      fetch(res.data.pdf)
        .then(response => response.blob())
        .then(blob => FileDownload(blob, `Unverified-Cars-${timestamp}.pdf`));
    });
  }

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <ExportToExcel handleExportToExcel={handleExportToExcel} />
      <ExportToExcel pdf handleExportToPdf={handleExportToPdf} />
      <ResetSettings />
    </View>
  );

  return (
    <>
      { customAlert && <ExportToExcelAlert /> }
      <Header title="Unverified and Sold Cars" />
      <DataTables.Simple
        data={data?.auditCars}
        columns={columns}
        buttons={buttons}
        htmlTable
        movableColumns
        styles={preflightTableStyles}
        setSettings={setSettings}
      />
    </>
  );
};

export default Unverified;
