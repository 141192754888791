/**
 * XLFunding
 *
 * @format
 * @flow
 */

import React from 'react';
import { AUTH_TOKEN } from './constants';
import secrets from './secrets';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/link-error';
import { createUploadLink } from 'apollo-upload-client'
import { AuthProvider } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';
import { VersionProvider, checkVersionLink } from './contexts/VersionContext';
import Login from './components/Login';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import TopNavigation from './components/TopNavigation';
import AuctionAPIDocs from './components/AuctionAPIDocs';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import DataTables from '@preflighttech/preflight-tables';

const uploadLink = createUploadLink({
  uri: secrets.api.baseUri + '/graphql',
  headers: {
    authorization: localStorage.getItem(AUTH_TOKEN),
    jwt_aud: secrets.api.jwtAud
  }
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if (networkError.statusCode === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      window.location.assign('/');
    }
  }
});

const client = new ApolloClient({
  link: from([checkVersionLink, errorLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    }
  }
});

const App: () => React$Node = () => {
  if ('/docs/auction-api' === window.location.pathname) {
    return <AuctionAPIDocs />
  }

  const token = localStorage.getItem(AUTH_TOKEN);
  const LoginInfo = token ? undefined : <Login />

  const Navigation = token ? <TopNavigation /> : undefined

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider token={token} client={client}>
          <UserProvider>
            <VersionProvider>
              <DataTables.Provider
                lengthMenu={ [10, 25, 50, 100, 200, 'All'] }
                pageLength={25}
                copyComponent
                alwaysSort
              >
                <DndProvider backend={HTML5Backend}>
                  <div>
                    {Navigation}
                    <div style={{ backgroundColor: '#f5f5f5', overflow: 'hidden' }} >
                      {LoginInfo}
                    </div>
                  </div>
                </DndProvider>
              </DataTables.Provider>
            </VersionProvider>
          </UserProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter >
  );
};

export default App;
