import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { useAlert } from '../../hooks/useAlert';
import DataState from '../DataState';
import { noteSchema, Form } from './Form';
import { StringHelpers } from '../../utils';
import Loading from '../../elements/Loading';
import { NOTE, UPDATE_NOTE } from './queries';

const Edit = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const redirectLink = pathname.split('/notes')[0] + '/notes';
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { loading, error, data } = useQuery(NOTE, { variables: { id } });

  const [showAlert, displayAlert] = useAlert();
  const history = useHistory();
  const [updateNote] = useMutation(UPDATE_NOTE,
    {
      onCompleted() {
        history.push({
          pathname: redirectLink,
          state: { alert: true, type: 'updated' }
        });
      }
    }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    subject,
    note,
    important,
    collection,
    corporate,
  } = data.note;

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    updateNote({ variables: values })
  };

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update Note: ${StringHelpers.titleCase(subject)}`}</h1>
        </div>
        <Formik
          initialValues={{
            id: id,
            subject: subject || '',
            note: note || '',
            important: important || false,
            collection: collection || false,
            corporate: corporate || false,
          }}
          validationSchema={noteSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default Edit;
