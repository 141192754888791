import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const RATE_PROGRAM_FIELDS = `
  id
  name
  description
  createdAt
  updatedAt
  hiddenAt
  rateProgramChanges {
    id
    rate
    effectiveOn
  }
  canBeHidden
`;

const RATE_PROGRAM_FIELDS_AND_ERRORS = `
  rateProgram {
    ${RATE_PROGRAM_FIELDS}
  }
  ${ERRORS}
`;

export const RATE_PROGRAMS = gql`
  query RatePrograms($scopes: [String!]) {
    ratePrograms(
      scopes: $scopes
    ) {
      ${RATE_PROGRAM_FIELDS}
    }
  }
`;

export const HIDE_RATE_PROGRAM = gql`
  mutation HideRateProgram($id: ID!) {
    hideRateProgram(
      id: $id
    ) {
      ${RATE_PROGRAM_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_RATE_PROGRAM = gql`
  mutation UnhideRateProgram($id: ID!) {
    unhideRateProgram(
      id: $id
    )
    {
      ${RATE_PROGRAM_FIELDS_AND_ERRORS}
    }
  }
`;
