import React, { useState, useEffect } from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { useQuery } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import { FISCAL_MONTHS } from './queries';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert, { CustomAlert } from 'components/src/elements/BasicAlert';
import { preflightTableStyles } from 'components/src/styles';
import moment from 'moment';

const columns = [
  {
    key: 'year',
    label: 'Year',
    sort: 'desc',
  },
  {
    key: 'periodName',
    label: 'Month/Period',
  },
  {
    key: 'startOn',
    label: 'Start On',
    sort: 'asc',
    content: ({ value }) => moment(value).format('ll'),
  },
  {
    key: 'endOn',
    label: 'End On',
    content: ({ value }) => moment(value).format('ll'),
  },
  {
    key: 'days',
    label: 'Days',
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <ResourceActionLinks
        prefix="/financial/fiscal-months"
        resource={entry}
        noRead
      />
    )
  }
];

const FiscalMonths = props => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data, refetch } = useQuery(FISCAL_MONTHS);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="Fiscal Month" type={alertType} />
      )}
      {data.fiscalMonthIssues.map(issue => (
        <CustomAlert message={issue} />
      ))}
      <ResourcesHeader
        title="Fiscal Month Calendar"
        prefix="/financial/fiscal-months"
        resourceType="FiscalMonth"
      />

      <DataTables.Simple
        data={data.fiscalMonths}
        columns={columns}
        pageLength={50}
        htmlTable
        movableColumns
        multiSort
        styles={preflightTableStyles}
      />
    </>
  );
};

export default FiscalMonths;
