import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  currencyNoCents,
  integerWithDelimiter
} from 'components/src/utils/stringHelpers';
import { Card, Head, Data, Label, Value } from '../Cards';
import { TableLink } from '../Tables';
import { Status } from '../Dealers';
import '../styles.css';

const percent = (value, scale) => (value * 100).toFixed(scale || 1) + '%';

const Collections = ({ stats }) => {
  const { statusStats } = stats;

  return (
    <Col className="dashboard-column market-stats" xs={12} xl={2}>
      <h2>Collection Tasks</h2>
      <TableLink label="Collections" to="/dashboard/collections" />

      <Card style={{paddingTop: 6}}>
        <Data className={stats.openNsfs ? 'warning' : ''}>
          <Label>Number of NSFs Unpaid/Unwaived</Label>
          <Value>{stats.openNsfs}</Value>
        </Data>

        <Data>
          <table className="statuses" style={{width: '100%'}}>
            <tbody>
              <Status stats={statusStats} label="Corporate Lock" warning />
              <Status stats={statusStats} label="Work Out - Audit" warning />
              <Status stats={statusStats} label="Work Out" warning />
              <Status stats={statusStats} label="Default" warning />
            </tbody>
          </table>
        </Data>
      </Card>
    </Col>
  );
}

export default Collections;
