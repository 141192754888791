import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { roleSchema, RoleForm } from './Form';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { titleCase } from '../../../utils/stringHelpers';
import { formatOptions, handleFormatting } from './functionality';
import DataState from '../../DataState';
import Loading from '../../../elements/Loading';
import * as Formatting from '../../../styles/formatting';

const ROLE = gql`
  query Role($id: ID!) {
    role(id: $id) {
      id
      name
      policies
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation UpdateRole($id: ID!, $name: String, $policies: JSON) {
    updateRole (
      id: $id
      name: $name
      policies: $policies
    ) {
      role { id, name, policies }
      errors { message, path }
    }
  }`

const Role = () => {
  const { id } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [updateRole] = useMutation(UPDATE_ROLE,
    {
      onCompleted(data) {
        const key = Object.keys(data)[0];
        const errors = data[key].errors;
        if (errors?.length) {
          setFormSubmitting(false);
          let errorString = '';
          let errorName = '';
          let errorMessage = '';
          errors.forEach((error, index) => {
            errorName = error.path[1];
            errorMessage = error.message;
            const separator = index > 0 ? ', ' : ''
            errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`
          })
          setServerSideErrors(errorString)
        } else {
          setFormSubmitting(false);
          setRedirect(true)
        }
      }
    }
  )

  const { loading, error, data } = useQuery(ROLE, { variables: { id } });

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { name, policies } = data.role;

  let updatedPolicies = [[]];
  let options = [[]];

  if (policies) {
    const sortPolicies = (a, b) => {
      const firstResourceName = a[0];
      const secondResourceName = b[0]

      if (firstResourceName < secondResourceName) {
        return -1;
      } else if (firstResourceName > secondResourceName) {
        return 1
      } return 0;
    }

    const policyData = formatOptions(policies);
    updatedPolicies = policyData.updatedPolicies.sort(sortPolicies);
    options = policyData.options
  }


  const handleSubmit = (values) => {
    if (values.options.flat().length > 0) {
      handleFormatting(values)
    }

    setFormSubmitting(true)
    updateRole({ variables: values })
  }

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  const UpdateForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/roles",
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <Formik
          initialValues={{
            id: data.role.id,
            name: name,
            policies: updatedPolicies || [[]],
            options: options || [[]],

          }}
          validationSchema={roleSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            isInvalid,
            errors,
            setValues
          }) => (
              <RoleForm />
            )}
        </Formik>
      </>
    )
  }

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formWrapper, { paddingLeft: '40px', marginTop: '40px' }}>
          <h1 style={Typography.formHeader}>{`Update Role: ${titleCase(name)}`}</h1>
        </div>
        <ServerSideErrors />
        <UpdateForm />
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
}

export default Role;
