import React from "react";
import { Spacing, Typography } from "../../../styles";
import { Container, Row, Col } from "react-bootstrap";
import * as DateHelpers from "../../../utils/dateHelpers";

const labelStyle = {
  fontWeight: "bold",
};

const leftSideInfo = {
  alignItems: 'center',
  paddingLeft: '0.6rem',
  width: '60%',
  marginTop: '0px',
}

const Info = (props) => {
  const { achBatch } = props;
  const id = parseInt(achBatch.id);

  return (
    <>
      <div>
        <div style={leftSideInfo}>
          <div style={Typography.pageTitle}>ACH Batch</div>
        </div>
      </div>
      <Container style={Spacing.showContainer}>
        <Row>
          <Col xl={3}>
            <span style={labelStyle}>Bank Date:</span>{" "}
            {DateHelpers.setDate(achBatch.bankDate)}
          </Col>
          <Col xl={3}>
            <span style={labelStyle}>Direction:</span> {achBatch.direction}
          </Col>
          {achBatch.filename && (
            <Col xl={4}>
              <span style={labelStyle}>Filename:</span> {achBatch.filename}
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Info;
