export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
export const zipRegExp = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/
export const specialCharacterRegExp = /(?=.*[!@#$%^&*-])/

const wordsToExcludeFromTitleCase = [
  'llc',
  'dba',
  'usa',
  'vip',
  'pa',
  'ny',
  'ca',
]

export const titleCase = (string, options) => {
  if (options?.onlyIfLower && /[A-Z]/.test(string)) return string;
  if (!string) return ''

  return string.toLowerCase().split(/[_\s]/).map(function (word) {
    if (wordsToExcludeFromTitleCase.includes(word)) {
      return word.toUpperCase();
    }

    if (!word[0]) return;

    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export const truncate = (word, limit, trailing) => {
  if (!word) return '';
  if (!limit) return word;

  let trailingChar = trailing || '...'

  if (word.length <= limit) {
    trailingChar = '';
  }

  return `${word.substring(0, limit)}${trailingChar}`
}

export const currency = (amount, options) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  });

  return formatter.format(amount);
}

export const currencyNoCents = amount => (
  currency(amount, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
);

export const numberWithDelimiter = (number) => {
  const formatter = new Intl.NumberFormat('en-US', {});

  return formatter.format(number);
}

export const integerWithDelimiter = (number) => {
  if ('string' === number) {
    number = parseFloat(number)
  }

  number = Math.round(number);
  const formatter = new Intl.NumberFormat('en-US', {});

  return formatter.format(number);
}

export const handleNumberChange = (event, name, setFieldValue) => {
  if (event && event[0] === '+') event = event.slice(2);

  setFieldValue(name, event)
}

export const dashToCamelCase = value => {
  return value
    .replace(/(-)([a-z])/i, (matches) => matches[1].toUpperCase())
    .replace(/^([a-z])/, letter => letter.toUpperCase());
}

export const yesNo = value => value ? 'Yes' : 'No';
