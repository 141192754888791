import React, { useState, useEffect } from "react";
import * as BasicForm from "../../../elements/forms";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { divStyle } from "./Style";
import { useFormikContext } from "formik";
import DataState from "../../DataState";
import { ErrorMessage } from "../../../elements/ErrorMessage";
import { SourceId } from "./SourceId";

export const SourceType = ({ includeOther }) => {
  const {
    values: { sourceType, sourceId, advanceType },
    values,
    setFieldValue,
  } = useFormikContext();

  if (advanceType === 'on_account') {
    return (
      <div style={divStyle}>
      <BasicForm.StyledLabel>Select Source Type:</BasicForm.StyledLabel>
      <BasicForm.Radio
        name="sourceType"
        type="radio"
        label="Dealer"
        value="Dealer"
        checked={sourceType === 'Dealer'}
      />
      </div>
    );
  }

  return (
    <div>
      <div style={divStyle}>
        <BasicForm.StyledLabel>Select Source Type:</BasicForm.StyledLabel>
        <BasicForm.Radio
          name="sourceType"
          type="radio"
          label="Dealer"
          value="Dealer"
        />
        <BasicForm.Radio
          name="sourceType"
          type="radio"
          label="Auction"
          value="Auction"
          checked={sourceType === "Auction"}
        />
        <BasicForm.Radio
          name="sourceType"
          type="radio"
          label="Approved Source"
          value="ApprovedSource"
        />
        {includeOther && (
          <BasicForm.Radio
            name="sourceType"
            type="radio"
            label="Other"
            value='other'
          />
        )}
        <BasicForm.InlineStyledErrorMessage>
          <ErrorMessage name="sourceType" />
        </BasicForm.InlineStyledErrorMessage>
      </div>
      <SourceId />
    </div>
  );
};
