import React, { useEffect, useState, useContext } from 'react';
import {
  Navbar,
  InputGroup,
  Nav,
  Dropdown,
  NavItem,
  NavLink,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationSquare, faCars } from '@fortawesome/pro-solid-svg-icons'
import {
  faFileChartPie, faQuestionSquare
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faHome,
  faLocationArrow,
  faCar,
  faGavel,
  faDollarSign,
  faServer,
  faUserCircle,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import * as Colors from '../styles/colors';
import AuthLink from './AuthLink';
import logo from '../components/images/logo.png';
import {
  useQuery, ApolloClient, HttpLink, ApolloLink, InMemoryCache
} from '@apollo/client';
import { ApolloProvider, Query, Mutation } from 'react-apollo'
import * as ActionCable from '@rails/actioncable'
import { ActionCableLink } from 'graphql-ruby-client';
import './topnavigation.css';
import Routes from '../routes/Routes';
import RequireMobileRoutes from '../routes/RequireMobileRoutes';
import {
  CURRENT_USER, MARKETS_BY_CURRENT_USER
} from '../queries/commonQueries';
import DataState from './DataState';
import { AUTH_TOKEN } from '../constants';
import secrets from '../secrets';
import ElasticsearchBox from './ElasticsearchBox';
import SwitchMarkets from './SwitchMarkets';
import { MARKET_SCOPE_NAME, MARKET_SCOPE_ID } from '../constants';
import { titleCase } from '../utils/stringHelpers';
import { UserContext } from '../contexts/UserContext';
import AuthContext from "../contexts/AuthContext";
import {ErrorBoundary} from 'react-error-boundary';
import featureEnabled from 'components/src/utils/featureEnabled';
import {
  GET_NOTIFICATIONS_FOR_NAVBAR,
  SUBSCRIPTION_ADD_NOTIFICATION_TO_NAVBAR
} from './Queries';
import {
  errorCenter,
  bottomBorder,
  navBar,
  navItem,
  navText,
  notificationItem,
  pStyle,
  preStyle,
  buttonStyle,
  spanColor,
  authStyle,
  h4Style,
  circleStyle,
  greenNav
} from './Style';

const cable = ActionCable.createConsumer(
  secrets.api.baseUri + '/cable'
)

const httpLink = new HttpLink({
  uri: secrets.api.baseUri + '/graphql',
  headers: {
    authorization: localStorage.getItem(AUTH_TOKEN),
    jwt_aud: secrets.api.jwtAud
  }
});

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(({ kind, operation }) =>
    kind === 'OperationDefinition' && operation === 'subscription'
  )
}

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const NotificationsCount = props => {
  const { ownerId, loading, error, data, subscribeToMore } = props;
  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_ADD_NOTIFICATION_TO_NAVBAR,
      variables: { ownerId: parseInt(ownerId) },
      updateQuery(prev, { subscriptionData }) {
        return {
          notifications: subscriptionData.data.notificationAdded
        }
      }
    })
  }, [ownerId])
  if (loading || error) return null
  return (data.notifications || []).length
}

const TopNavigation = () => {
  const { loading, error, data } = useQuery(CURRENT_USER);
  const {
    loading: marketsLoading,
    error: marketsError,
    data: marketsData } = useQuery(MARKETS_BY_CURRENT_USER)
  const [mobileMissing, setMobileMissing] = useState(false);
  const user = useContext(UserContext);
  const mobilePhone = data?.currentUser?.userProvidedPhoneNumber?.formatted;
  const [redirect, setRedirect] = useState(false);

  const marketName = user.currentMarket.name
    ? `${titleCase(user.currentMarket.name)} Home` :
    'Corporate Market Home'

  useEffect(() => {
    mobilePhone == null ? setMobileMissing(true) : setMobileMissing(false)
  }, [mobilePhone])

  const [showModal, toggleShowModal] = useState(false)

  const auth = useContext(AuthContext);

  const financialReportLinks = auth.hasExactPolicy('Financial', 'view') ||
    auth.hasExactPolicy('Financial', 'reports')

  const setMarketScope = () => {
    if (!marketsData) return;

    const presetMarketScope = marketsData.currentUser.currentMarketScope;

    if (presetMarketScope) {
      user.setCurrentMarket({
        id: presetMarketScope.id,
        name: presetMarketScope.name,
      })
    }

    const { markets, marketIds } = marketsData.currentUser;
    const defaultTimeMarketId = markets.length ? markets[0].id : null;
    const defaultTimeMarketName = markets.length ? markets[0].name : null;

    const setUserMarketToDefault = props => {
      const {defaultTimeMarketId, defaultTimeMarketName} = props;
      user.setCurrentMarket({
        id: defaultTimeMarketId,
        name: defaultTimeMarketName,
      })
    }

    const setUserMarketToMultiple = () => {
      user.setAllMarketUserMarkets(true)
      user.setCurrentMarket({
        id: null,
        name: 'My Markets'
      })
    }

    if (!presetMarketScope && markets.length > 1) {
      setUserMarketToMultiple();
    }

    if (!presetMarketScope && markets.length === 1) {
      setUserMarketToDefault(defaultTimeMarketId, defaultTimeMarketName);
    }
  }

  useEffect(() => setMarketScope(), [marketsData])

  if ((loading || marketsLoading) && (!data || !marketsData)) return (
    <DataState.Loading />
  )
  if (error || marketsError) return <DataState.Error error={error} />;

  const ownerId = data.currentUser.id

  const RenderRoutes = () => {
    if (mobileMissing) {
      return <RequireMobileRoutes />
    } return <Routes />
  }

  const handleSwitchMarkets = () => {
    toggleShowModal(!showModal)
  }

  if (showModal) {
    return <SwitchMarkets />
  }

  const newTransactions = {
    bills: 'Enter Bill',
    'vendor-credits': 'Enter Vendor Credit',
    'receive-funds': 'Receive Funds',
    'journal-entries': 'Make Journal Entry',
  }

  const ErrorFallback =({error, resetErrorBoundary}) => {
    const axios = require('axios');

    const environment =
      process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV;

    let tags = [environment];
    if (process.env.REACT_APP_ISSUE_TAGS) {
      tags = [...tags, ...process.env.REACT_APP_ISSUE_TAGS.split(',')];
    }

    let storage = {};
    Object.keys(localStorage).forEach((key) => {
      if (!key.startsWith('DataTable')) {
        storage[key] = localStorage.getItem(key);
      }
    });

    axios({
      method: 'post',
      url: (secrets.pti?.issueUrl || 'https://preflighttech.com/api/1/issues'),
      headers: {
        'Content-Type': 'application/json',
        Authorization: secrets.pti.token,
      },
      data: {
        issue: {
          tags,
          issue_type: 'react',
          app: {
            ...secrets.app,
            environment
          },
          exception_name: error.name,
          exception_message: error.message,
          backtrace: error.stack,

          user_name: auth.user?.fullName,
          user_email: auth.user?.email,
          user_type: (auth.user?.userType || auth.user?.__typename),
          user_id: auth.user?.id,
          user_log_id: localStorage.getItem('X-User-Log-Id'),

          referrer: window.document.referrer,
          url: window.document.documentURI,

          request_at: new Date(),

          request_data: {
            user_agent: window.navigator.userAgent,
            width: window.innerWidth,
            height: window.innerHeight,
            local_storage: storage,
          }
        }
      }
    });

    if (redirect) {
      window.location.assign('/')
    }

    return (
      <div role="alert" style={errorCenter}>
        <p
          style={pStyle}
        >
          We Have Been Notified of this Error:
        </p>
        <pre style={preStyle}>{error.message}</pre>
        <button
          style={buttonStyle}
          onClick={() => {resetErrorBoundary(); setRedirect(true)}}
        >
          Return to Homescreen
        </button>
      </div>
    )
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        className="navbar navbar-light"
        expand="xxl"
        bg="light"
        style={navBar}
      >
        <LinkContainer to="/" style={navItem}>
          <Navbar.Brand className="my-auto" href="#">
            <img src={logo} alt="Logo" height="40" />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="navbar-toggler-right"
        />
        <Navbar.Collapse className="flex-md-column" id="responsive-navbar-nav">
          <InputGroup>
            <Nav className="mr-auto medium">
              <Nav.Item>
                <Nav.Link href="/" style={notificationItem}>
                  <FontAwesomeIcon icon={faHome} />
                  <span style={navText}>{marketName}</span>
                </Nav.Link>
              </Nav.Item>
              {marketsData.currentUser.markets.length === 1 ?
                null :
                <Nav.Item>
                  <Nav.Link
                    href="#"
                    style={notificationItem}
                    onClick={handleSwitchMarkets}
                  >
                    <FontAwesomeIcon icon={faLocationArrow} />
                    <span style={navText}>Switch Markets</span>
                  </Nav.Link>
                </Nav.Item>
              }
              <Nav.Item>
                <Nav.Link href="/notifications" style={notificationItem}>
                  <FontAwesomeIcon icon={faExclamationSquare} />
                  <ApolloProvider client={client}>
                    <span style={navText}>
                      Notifications (
                        <span style={spanColor}>
                        <Query
                          query={GET_NOTIFICATIONS_FOR_NAVBAR}
                          variables={{ ownerId: parseInt(ownerId) }}
                        >
                          {
                            props =>
                              <NotificationsCount
                                ownerId={ownerId}
                                {...props}
                              />
                          }
                        </Query> new
                        </span>
                      )
                    </span>
                  </ApolloProvider>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </InputGroup>
          <Nav className="mr-auto">
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink} style={navItem}>
                <FontAwesomeIcon icon={faCar} />
                <span style={navText}>DEALERS</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <AuthLink
                  href={'/dealers'}
                  resource="Dealer"
                  action="read"
                  component={Dropdown.Item}
                >
                  View Dealers
                </AuthLink>
                <AuthLink
                  href={'/scheduled-achs-in'}
                  resource="Quote"
                  action="read"
                  component={Dropdown.Item}
                >
                  Scheduled ACHs - Incoming
                </AuthLink>
                <AuthLink
                  href={'/yield-calculator'}
                  resource="Dealer"
                  action="read"
                  component={Dropdown.Item}
                >
                  Yield Calculator
                </AuthLink>
                <AuthLink
                  href={'/mass-updates'}
                  resource="Dealer"
                  action="manage"
                  component={Dropdown.Item}
                >
                  Mass Updates
                </AuthLink>
                <AuthLink
                  href={'/audits'}
                  resource="Audit"
                  action="read"
                  component={Dropdown.Item}
                >
                  Audits
                </AuthLink>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink} style={navItem}>
                <FontAwesomeIcon icon={faCars} />
                <span style={navText}>CARS</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <AuthLink
                  href={'/reports/cars'}
                  resource="FlooredCar"
                  action="read"
                  component={Dropdown.Item}
                >
                  View All Cars
                </AuthLink>
                <AuthLink
                  href={'/reports/cars/coming-due'}
                  resource="FlooredCar"
                  action="read"
                  component={Dropdown.Item}
                >
                  View Coming Due
                </AuthLink>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink} style={navItem}>
                <FontAwesomeIcon icon={faGavel} />
                <span style={navText}>SOURCES</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <AuthLink
                  href={'/auctions'}
                  resource="Auction"
                  action="read"
                  component={Dropdown.Item}
                >
                  Auctions
                  </AuthLink>
                <AuthLink
                  href={'/auction-access/fprs/pending'}
                  resource="FloorplanRequest"
                  action="read"
                  component={Dropdown.Item}
                >
                  Pending FPRs
                </AuthLink>
                <AuthLink
                  href={'/auction-access/fprs/rejected'}
                  resource="FloorplanRequest"
                  action="read"
                  component={Dropdown.Item}
                >
                  Rejected FPRs
                </AuthLink>
                <AuthLink
                  href={'/auction-access/fprs/all'}
                  resource="FloorplanRequest"
                  action="read"
                  component={Dropdown.Item}
                >
                  All FPRs
                </AuthLink>
                <AuthLink
                  href={'/auction-availabilities'}
                  resource="Auction"
                  action="read"
                  component={Dropdown.Item}
                >
                  Auction Availabilities
                  </AuthLink>
                <AuthLink
                  href={'/reports/report/funding-detail'}
                  resource="Txn"
                  action="read"
                  component={Dropdown.Item}
                >
                  Funding Report
                </AuthLink>
                <AuthLink
                  href={'/reports/report/auction-addresses'}
                  resource="Auction"
                  action="read"
                  component={Dropdown.Item}
                >
                  Auction Addresses Report
                </AuthLink>
                <AuthLink
                  href={'/approved-sources'}
                  resource="ApprovedSource"
                  action="read"
                  component={Dropdown.Item}
                  style={authStyle}
                >
                  Approved Sources
                </AuthLink>
                <AuthLink
                  href={'/reports/report/approved-source-addresses'}
                  resource="ApprovedSource"
                  action="read"
                  component={Dropdown.Item}
                >
                  Approved Source Addresses Report
                </AuthLink>
              </Dropdown.Menu>
            </Dropdown>

            {auth.hasPolicy('Financial', 'read') &&
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={NavLink} style={navItem}>
                  <FontAwesomeIcon icon={faDollarSign} />
                  <span style={navText}>FINANCIAL</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <AuthLink
                    href={'/financial/accounts'}
                    resource="Account"
                    action="read"
                    component={Dropdown.Item}
                  >
                    Accounts
                  </AuthLink>
                  {
                    featureEnabled('doubleEntry', 'reconcile') &&
                      <AuthLink
                        href={'/financial/reconciles'}
                        resource="Reconcile"
                        action="read"
                        component={Dropdown.Item}
                      >
                        Reconcile
                      </AuthLink>
                  }
                  <AuthLink
                    href={'/financial/scheduled-achs'}
                    resource="Quote"
                    action="read"
                    component={Dropdown.Item}
                  >
                    Scheduled ACHs - Outgoing
                  </AuthLink>
                  <AuthLink
                    href={'/financial/scheduled-achs-in'}
                    resource="Quote"
                    action="read"
                    component={Dropdown.Item}
                  >
                    Scheduled ACHs - Incoming
                  </AuthLink>
                  <AuthLink
                    href={'/financial/ach-batches'}
                    resource="AchBatch"
                    action="read"
                    component={Dropdown.Item}
                  >
                    ACH Batches
                  </AuthLink>
                  <AuthLink
                    href={'/financial/decrypt-ach-token'}
                    resource="BankAccount"
                    action="decrypt_token"
                    exactPolicy
                    component={Dropdown.Item}
                  >
                    Decrypt ACH Token
                  </AuthLink>
                  <br />
                  <AuthLink
                    href={'/financial/transactions'}
                    resource="Txn"
                    action="read"
                    component={Dropdown.Item}
                  >
                    Transactions
                  </AuthLink>
                  <AuthLink
                    href={'/financial/accruals'}
                    resource="Txn"
                    action="read"
                    component={Dropdown.Item}
                  >
                    Accrual Transactions
                  </AuthLink>
                  {
                    featureEnabled('doubleEntry', 'operationsTxns') &&
                      <AuthLink
                        href={'/financial/operations-transactions'}
                        resource="Txn"
                        action="read"
                        component={Dropdown.Item}
                      >
                        Operations Transactions
                      </AuthLink>
                  }
                  <br />
                  {
                    featureEnabled('doubleEntry', 'vendors') &&
                      <AuthLink
                        href={'/vendors'}
                        resource="Vendor"
                        action="read"
                        component={Dropdown.Item}
                      >
                        Vendors
                      </AuthLink>
                  }
                  {
                    featureEnabled('doubleEntry', 'operationsTxns') &&
                      <AuthLink
                        href={'/financial/unpaid-bills'}
                        resource="Txn"
                        action="read"
                        component={Dropdown.Item}
                      >
                        Unpaid Bills
                      </AuthLink>
                  }
                  {
                    featureEnabled('doubleEntry', 'operationsTxns') &&
                      <>
                        <br />
                        <b>New Transactions</b>
                        {
                          Object.entries(newTransactions).map(
                            ([path, label]) => {
                              return (
                                <AuthLink
                                  key={path}
                                  href={`/financial/${path}/new`}
                                  resource="Txn"
                                  action="create"
                                  component={Dropdown.Item}
                                >
                                  {label}
                                </AuthLink>
                              );
                            }
                          )
                        }
                      </>
                  }
                  <br />
                  <b>Financial Settings</b>
                  {
                    featureEnabled('doubleEntry', 'accountGroups') &&
                      <AuthLink
                        href={'/financial/account-groups'}
                        resource="AccountGroup"
                        action="read"
                        component={Dropdown.Item}
                      >
                        Account Groups
                      </AuthLink>
                  }
                  {
                    featureEnabled('doubleEntry', 'departments') &&
                      <AuthLink
                        href={'/financial/departments'}
                        resource="Department"
                        action="read"
                        component={Dropdown.Item}
                      >
                        Departments
                      </AuthLink>
                  }
                  {
                    featureEnabled('doubleEntry', 'fiscalMonthCalendar') &&
                      <AuthLink
                        href={'/financial/fiscal-month-calendar'}
                        resource="FiscalMonth"
                        action="manage"
                        exactPolicy
                        component={Dropdown.Item}
                      >
                        Fiscal Month Calendar
                      </AuthLink>
                  }
                  {
                    featureEnabled('doubleEntry', 'paymentTerms') &&
                      <AuthLink
                        href="/system/payment-terms"
                        resource="PaymentTerm"
                        action="read"
                        component={Dropdown.Item}
                      >
                        Payment Terms
                      </AuthLink>
                  }
                </Dropdown.Menu>
              </Dropdown>
            }
            <Nav.Item>
              <Nav.Link href="/reports" style={navItem}>
                <FontAwesomeIcon icon={faFileChartPie} />
                <span style={navText}>REPORTS</span>
              </Nav.Link>
            </Nav.Item>
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink} style={navItem}>
                <FontAwesomeIcon icon={faServer} />
                <span style={navText}>SYSTEM</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <AuthLink
                  href={'/system/users'}
                  resource="User"
                  action="read"
                  component={Dropdown.Item}
                >
                  Users
                </AuthLink>
                <AuthLink
                  href={'/system/roles'}
                  resource="Role"
                  action="read"
                  component={Dropdown.Item}
                >
                  Roles
                </AuthLink>
                <br />
                <AuthLink
                  href={'/system/markets'}
                  resource="Market"
                  action="read"
                  component={Dropdown.Item}
                >
                  Markets
                </AuthLink>
                <AuthLink
                  href={'/system/branches'}
                  resource="Branch"
                  action="read"
                  component={Dropdown.Item}
                >
                  Branches
                </AuthLink>
                <AuthLink
                  href="/system/custom-notifications/new"
                  resource="CustomNotification"
                  action="create"
                  component={Dropdown.Item}
                >
                  Custom Notifications
                </AuthLink>
                <br />
                <AuthLink
                  href={'/system/dealer-programs'}
                  resource="DealerProgram"
                  action="read"
                  component={Dropdown.Item}
                >
                  Dealer Programs
                </AuthLink>
                <AuthLink
                  href={'/system/automated-fees'}
                  resource="AutomatedFees"
                  action="read"
                  component={Dropdown.Item}
                >
                  Automated Fees
                </AuthLink>
                <AuthLink
                  href={'/system/rate-programs'}
                  resource="RatePrograms"
                  action="read"
                  component={Dropdown.Item}
                >
                  Rate Programs
                </AuthLink>
                <AuthLink
                  href={'/system/base-rates'}
                  resource="BaseRates"
                  action="read"
                  component={Dropdown.Item}
                >
                  Base Rates
                  </AuthLink>
                <AuthLink
                  href={'/system/dealer-statuses'}
                  resource="DealerStatuses"
                  action="read"
                  component={Dropdown.Item}
                >
                  Dealer Statuses
                </AuthLink>
                <AuthLink
                  href={'/system/dealer-categories'}
                  resource="DealerCategories"
                  action="read"
                  component={Dropdown.Item}
                >
                  Dealer Types
                </AuthLink>
                <br />
                <AuthLink
                  href={'/system/title-statuses'}
                  resource="TitleStatuses"
                  action="read"
                  component={Dropdown.Item}
                >
                  Title Statuses
                </AuthLink>
                <br />
                <AuthLink
                  href={'/system/collateral-coverages'}
                  resource="CollateralCoverages"
                  action="read"
                  component={Dropdown.Item}
                >
                  Collateral Coverages
                </AuthLink>
                <AuthLink
                  href={'/system/insurance-states'}
                  resource="InsuranceStates"
                  action="read"
                  component={Dropdown.Item}
                >
                  Default Collateral Coverage Programs for Each State
                </AuthLink>
                <AuthLink
                  href={'/system/kpis/input'}
                  resource="KPI"
                  action="Set/Adjust KPIs"
                  exactPolicy
                  component={Dropdown.Item}
                >
                  Input KPI Goals
                </AuthLink>
                <AuthLink
                  href={'/system/kpis/yield'}
                  resource="KPI"
                  action="Set/Adjust KPIs"
                  exactPolicy
                  component={Dropdown.Item}
                >
                  Input KPI Yield Goals
                </AuthLink>
                <AuthLink
                  href={'/system/settings'}
                  resource="Settings"
                  action="update"
                  component={Dropdown.Item}
                >
                  Settings
                </AuthLink>
              </Dropdown.Menu>
            </Dropdown>

            { process.env.REACT_APP_HEADER_BACKGROUND &&
              <div class='nav-item'>
                <h4 style={Object.assign({}, navText, h4Style)}>
                  UAT
                </h4>
              </div>
            }
          </Nav>
        </Navbar.Collapse>
        <Navbar className="noBoxShadow">
          <ElasticsearchBox marketIds={marketsData.currentUser.marketIds} />
          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={NavLink} style={navItem}>
              <FontAwesomeIcon
                icon={faUserCircle}
                className="fa-2x"
                style={circleStyle}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Nav.Link
                href={'/trouble-ticket'}
                component={Dropdown.Item}
                target={'_blank'}
                >
                <FontAwesomeIcon
                  icon={faQuestionSquare}
                  className="fa-2x"
                  style={circleStyle}
                />
              </Nav.Link>
              <Nav.Link
                href={'/profile/edit'}
                style={greenNav}
              >
                Update Profile
              </Nav.Link>
              <Nav.Link
                href={'/profile/change-password'}
                style={greenNav}
              >
                Edit Password
              </Nav.Link>
              <Nav.Link
                href={'/profile/update-mobile'}
                style={greenNav}
              >
                Update Mobile
              </Nav.Link>
              <Nav.Link
                href={'/logout'}
                style={greenNav}
              >
                Logout
                </Nav.Link>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </Navbar>
      <div style={bottomBorder}></div>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
      >
        <RenderRoutes />
      </ErrorBoundary>
    </>
  );
}

export default TopNavigation;
