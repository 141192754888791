import React, { useState, useEffect, useContext } from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import { ROLES, HIDE_ROLE, UNHIDE_ROLE } from './queries';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert from 'components/src/elements/BasicAlert';
import { preflightTableStyles } from 'components/src/styles';

const Roles = props => {
  const [showHidden, setShowHidden] = useState(false);
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data, refetch } = useQuery(
    ROLES,
    { variables: { scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_ROLE, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_ROLE, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'name',
      label: 'Name',
      sort: 'asc',
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix="/system/roles"
          resource={entry}
          hide={hide}
          unhide={unhide}
          noRead
        />
      )
    }
  ];

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="Role" type={alertType} />
      )}
      <ResourcesHeader
        title="Roles"
        prefix="/system/roles"
        resourceType="Role"
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />

      <DataTables.Simple
        data={data.roles}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Roles;
