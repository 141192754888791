import React, { useState } from 'react';
import { Text } from 'react-native';
import * as Colors from 'components/src/styles/colors';
import Loading from 'components/src/elements/Loading';

const center = {
  textAlign: 'center',
  paddingTop: '50px'
}

const Error = ({error}) => {
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    window.location.assign('/')
  }

  const ErrorMessage = () => {
    if (error === undefined) {
      return (
        <Text style={{fontSize: '18px'}}>This Error is Undefined.</Text>
      )
    } else {
      return (
        <Text style={{fontSize: '18px'}}>{error.message}</Text>
      )
    }
  }

  return (
    <div role="alert" style={center}>
      <div>
        <Text style={{fontSize: '50px', color: Colors.xlGreen}}>Error:</Text>
        <br />
          <ErrorMessage />
        <br />
        <div>
          <button style={{margin: '20px', backgroundColor: 'white'}} onClick={() => setRedirect(true)}>Return to Homescreen</button>
        </div>
      </div>
    </div>
  )
};

export default { Loading, Error };
