import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import DataState from "../../DataState";
import { ErrorMessage } from "../../../elements/ErrorMessage";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../elements/forms";
import {
  labelStyle,
  divStyle,
  inlineInputStyle,
  advanceRefStyle
} from './Style';

export const Advance = ({ type }) => {
  const { values } = useFormikContext();

  let advanceToLabel = type;
  if (type !== "Check") advanceToLabel = `${type} Reference`;

  if (values.sourceType && values.sourceType !== "other")
    return (
      <AdvanceReference
        type={type}
        advanceToLabel={advanceToLabel}
        inlineInputStyle={inlineInputStyle}
      />
    );

  return (
    <div style={divStyle}>
      <div>
        <BasicForm.TextInput
          name="advanceTo"
          type="text"
          style={inlineInputStyle}
          label={`${type} From:`}
          labelStyle={labelStyle}
        />
      </div>
      <div style={advanceRefStyle}>
        <AdvanceReference
          type={type}
          advanceToLabel={advanceToLabel}
          inlineInputStyle={inlineInputStyle}
        />
      </div>
    </div>
  );
};

export const AdvanceReference = ({
  type,
  advanceToLabel,
  inlineInputStyle,
}) => {
  return (
    <BasicForm.TextInput
      name="advanceReference"
      type="text"
      label={`${advanceToLabel} Number:`}
      style={inlineInputStyle}
      labelStyle={labelStyle}
    />
  );
};
