import React from 'react';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { auctionSchema, Form } from './Form';
import Loading from '../../elements/Loading';
import { useServerSideErrors } from '../../hooks/useServerSideErrors'
import { NumberHelpers } from '../../utils';

const CREATE_AUCTION = gql`
  mutation CreateAuction(
    $name: String!,
    $street: String,
    $city: String,
    $state: String,
    $zip: String,
    $phone: String,
    $xlgAuction: Boolean!,
    $xlgAuctionFloat: Int,
    $auctionAccessId: Int,
    $status: String!,
    $url: String,
    $marketIds: [Int!],
    $titleFundingStatusIds: [Int!],
    $pmsiOption: Boolean,
    $pmsiAddress: String,
    $pmsiCity: String,
    $pmsiState: String,
    $pmsiZip: String,
    $pmsiCompanyName: String,
    $pmsiFilingLocation: String,
    $pmsiPhone: String
  ) {
    createAuction (
      name: $name
      street: $street
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      xlgAuction: $xlgAuction
      xlgAuctionFloat: $xlgAuctionFloat
      auctionAccessId: $auctionAccessId
      status: $status
      url: $url
      marketIds: $marketIds
      titleFundingStatusIds: $titleFundingStatusIds
      pmsiOption: $pmsiOption
      pmsiAddress: $pmsiAddress
      pmsiCity: $pmsiCity
      pmsiState: $pmsiState
      pmsiZip: $pmsiZip
      pmsiCompanyName: $pmsiCompanyName
      pmsiFilingLocation: $pmsiFilingLocation
      pmsiPhone: $pmsiPhone
    ) {
      auction {
        id
        name
        marketIds
      }
      errors {
        message
        path
      }
    }
  }
`;


const New = () => {
  const customSubmitHandler = (values) => {
    values.auctionAccessId = NumberHelpers.makeNullable(values.auctionAccessId)

    if(values.titleFundingStatus === '') {
      values.titleFundingStatus = 0
    }

    if (values.pmsiOption === false) {
      values.pmsiAddress = ''
      values.pmsiCity = ''
      values.pmsiState = ''
      values.pmsiZip = ''
      values.pmsiCompanyName = ''
    }
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_AUCTION,
    onCompleteRedirectTo: '/auctions',
    customSubmitHandler: customSubmitHandler,
  }


  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const NewForm = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Auction</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              name: '',
              city: '',
              state: '',
              street: '',
              zip: '',
              phone: '',
              xlgAuction: false,
              xlgAuctionFloat: 0,
              auctionAccessId: '',
              status: '',
              url: '',
              marketIds: [],
              titleFundingStatusIds: [],
              pmsiOption: false,
              pmsiAddress: '',
              pmsiCity: '',
              pmsiState: '',
              pmsiZip: '',
              pmsiCompanyName: '',
              pmsiPhone: ''
            }}
            validationSchema={auctionSchema}
            onSubmit={handleSubmit}
          >
            <Form formSubmitting={formSubmitting} />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default New;
