import React, { useEffect, useState } from 'react';
import * as BasicForm from '../../../elements/forms';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

const Hint = styled.p`
  margin-top: 0.6em;
  font-size: 0.8em;
  color: #303030;
`;

const TransactionPurpose = () => {
  const location = useLocation();

  if (!(location.search.includes('detailed=t'))) {
    return null;
  }

  return (
    <>
      <BasicForm.Select name="purpose" label="Purpose">
        <option value="">Select a Purpose</option>
        <option value="Payment">Payment</option>
        <option value="Advance">Advance</option>
        <option value="Principal Adjustment">Principal Adjustment</option>
        <option value="Advance Release">Advance Release</option>
        <option value="Daily Accrual">Daily Accrual</option>
        <option value="Immediate Accrual">Immediate Accrual</option>
        <option value="Void">Void</option>
        <option value="NSF">NSF</option>
        <option value="Waiver">Waiver</option>
        <option value="Writeoff">Writeoff</option>
        <option value="Reserves Deposit">Reserves Deposit</option>
        <option value="Reserves Withdraw">Reserves Withdraw</option>
        <option value="On Account Deposit">On Account Deposit</option>
        <option value="On Account Withdraw">On Account Withdraw</option>
        <option value="Advance Due Date">Advance Due Date</option>
        <option value="Advance Release (undone)">
          Advance Release (undone)
        </option>
        <option value="Undo Funding">Undo Funding</option>
        <option value="Collateral Coverage Adjustment">
          Collateral Coverage Adjustment
        </option>
      </BasicForm.Select>
      <Hint>
        Purposes are used to determine where transactions are displayed
        and can also affect Car due date calculations. Custom transaction
        should usually leave this blank
      </Hint>
    </>
  );
};

export default TransactionPurpose;
