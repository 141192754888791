export const formatInitialValues = (dealerProgram) => {
  const id = dealerProgram.id;
  const code = dealerProgram.code || '';
  const description = dealerProgram.description || '';
  const termsAttributes = dealerProgram.termsAttributes.map((attribute, attributeIdx) => {
    const order = attribute.order || '';
    const feeType = attribute.feeType || '';
    const termDays = attribute.termDays || '';
    const type = attribute.type || '';
    const termLinesAttributes = attribute.termLinesAttributes.map((line, lineIdx) => {
      const principalBelow = parseFloat(line.principalBelow) || null;
      const curtailmentPercentage = line.curtailmentPercentage;
      const termLineFeesAttributes = line.termLineFeesAttributes.map((fee, feeIdx) => {
        const accountId = fee.accountId || null;
        const amount = parseFloat(fee.amount);
        const day = fee.day || null;
        const earnType = fee.earnType || null;
        const interval = fee.interval || null;

        return { id: fee.id, accountId, amount, day, earnType, interval}
      })

      const values = { id: line.id, principalBelow, curtailmentPercentage };
      if (termLineFeesAttributes.length > 0)
        values.termLineFeesAttributes = termLineFeesAttributes;

      return values;
    })

    return { id: attribute.id, order, feeType, termDays, type, termLinesAttributes }
  })

  return  { id, code, description, termsAttributes }
}
