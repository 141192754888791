import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { modalStyle, firstRowStyle } from "../FilterStyles";

const inlineStyle = {
  display: "flex",
  alignItems: "center",
};

const textStyle = {
  marginBottom: '0px',
  fontSize: '12px',
}

const formatLabel = (label) => {
  return label = label
    .split(" ")
    .map((l) => l.toLowerCase())
    .join("_");
}

const errorHandling = (values) => {
  values = Object.values(values)

  let hasErrors = false;
  values.forEach((value) => {
    if (hasErrors) return;

    value = value.split('-');
    const year = value[0];
    const month = value[1];
    const day = value[2];

    if (parseInt(year) < 1900 || parseInt(month) < 0 || parseInt(day) < 0){
      hasErrors = true;
      return;
    }
  })

  return hasErrors;
}

const adjustFilterValues = (
  dates,
  currentFilters,
  handleFilters,
) => {
  // re-format labels to fit current filter label names
  dates = Object.keys(dates).map((label) => {
    const values = dates[label];
    // Early return if missing date field
    if (Object.values(values).includes('')) return;

    // Return early if full date not filled out
    const hasErrors = errorHandling(values);
    if (hasErrors) return;

    const formattedLabel = formatLabel(label);

    return { [formattedLabel]: values };
  }).filter(date => date)

  if (!dates.length) return;

  // update current filters
  dates.forEach((obj) => {
    const label = Object.keys(obj)[0];
    const values = Object.values(obj[label]);
    // somehow the order of to and from are getting switched brain hurts too much
    // to figure out where, this is to switch them back.
    const lastEle = values.pop();
    values.unshift(lastEle);

    const newCurrentFilters = { ...currentFilters.current };
    newCurrentFilters[label] = values;

    handleFilters({ ...newCurrentFilters })
  })
};

const DateRange = ({
  currentFilters,
  selectOptions,
  labels,
  handleFilters,
  dateRangeOptions,
  clearingToggle,
  setClearingToggle,
}) => {
  const filterKeys = dateRangeOptions.map((d) => Object.keys(d)).flat();
  const [dates, setDates] = useState({});

  useEffect(() => {
    // Only clears filters after first page load
    // Not necessary to set clearingToggle to false for now since this is a
    // static form. All future clear filter clicks will reset filter. The current
    // filter reset logic is being done in Filter.js in clearFilters function.
      setDates([]);
  }, [clearingToggle])

  useEffect(() => {
    adjustFilterValues(dates, currentFilters, handleFilters);
  }, [dates])

  const resolveDateUpdate = (value, label, direction) => {
    const newState = { ...dates };

    newState[label][direction] = value;
    setDates(Object.assign({ ...dates }, newState ))
  }


  return labels.map((label, index) => {

    if (!dates[label]) {
      dates[label] = { to: '', from: '' }
    }

    const curretFromDate = dates[label]['from'];
    const currentToDate = dates[label]['to'];

    return (
      <Row key={index} style={Object.assign({}, modalStyle.rows, inlineStyle)}>
        <Form.Label style={{ paddingRight: "20px" }}>{label}</Form.Label>
        <Col style={{ borderBottom: "none" }}>
          <p style={textStyle}>From</p>
          <Form.Control
            type="date"
            onBlur={(event) => {
              resolveDateUpdate(
              event.currentTarget.value,
              label,
              'from'
            )}
          }
          />
        </Col>
        <Col style={{ borderBottom: "none" }}>
          <p style={textStyle}>To</p>
          <Form.Control
           type="date"
            onBlur={(event) => {
              resolveDateUpdate(
              event.currentTarget.value,
              label,
              'to',
            )}
          }
          />
        </Col>
      </Row>
    );
  });
};

export default DateRange;
