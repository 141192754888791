import React from "react";
import { Col } from "react-bootstrap";
import * as BasicForm from "../../../../elements/forms";

const FeeEarned = ({ values, termLinesAttributes, index, columnStyle }) => {
  const feeTypeIsSingle = values.termsAttributes[index].feeType === "Single";
  const feeTypeIsMultiple =
    values.termsAttributes[index].feeType === "Multiple";
  const feeTypeIsInterval =
    values.termsAttributes[index].feeType === "Interval";

  return termLinesAttributes[0].termLineFeesAttributes.map(
    (attribute, attributeIdx) => {
      if (values.termsAttributes[index].termLinesAttributes[0]
          .termLineFeesAttributes[attributeIdx]._destroy) return null;

      if (
        feeTypeIsSingle &&
        !values.termsAttributes[index].termLinesAttributes[0]
          .termLineFeesAttributes[attributeIdx].earnType
      )
        values.termsAttributes[
          index
        ].termLinesAttributes[0].termLineFeesAttributes[attributeIdx].earnType =
          "Earns over Term";
      if (
        feeTypeIsMultiple &&
        !values.termsAttributes[index].termLinesAttributes[0]
          .termLineFeesAttributes[attributeIdx].earnType
      )
        values.termsAttributes[
          index
        ].termLinesAttributes[0].termLineFeesAttributes[attributeIdx].earnType =
          "Earns until next Fee";
          if (
            feeTypeIsInterval &&
            !values.termsAttributes[index].termLinesAttributes[0]
              .termLineFeesAttributes[attributeIdx].earnType
          )
            values.termsAttributes[
              index
            ].termLinesAttributes[0].termLineFeesAttributes[attributeIdx].earnType =
              "Fully Earned";
      return (
        <Col style={columnStyle.smallDiv} key={attributeIdx}>
          <BasicForm.Select
            name={`termsAttributes[${index}].termLinesAttributes[0].termLineFeesAttributes[${attributeIdx}].earnType`}
            value={
              values.termsAttributes[index].termLinesAttributes[0]
                .termLineFeesAttributes[attributeIdx].earnType || ""
            }
            style={columnStyle.dropdownStyle}
          >
            <option value="">Select</option>
            <option value="Fully Earned">Fully Earned</option>
            <option value="Earns over Term">Earned Over Full Term</option>
            <option value="Earns until next Fee">Earned Until Next Fee</option>
          </BasicForm.Select>
        </Col>
      );
    }
  );
};

export default FeeEarned;
