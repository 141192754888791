import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const DEALER_CATEGORY_FIELDS = `
  id
  name
  hiddenAt
  canBeHidden
`;

const DEALER_CATEGORY_FIELDS_AND_ERRORS = `
  dealerCategory {
    ${DEALER_CATEGORY_FIELDS}
  }
  ${ERRORS}
`;

export const DEALER_CATEGORIES = gql`
  query DealerCategories($scopes: [String!]) {
    dealerCategories(
      scopes: $scopes
    ) {
      ${DEALER_CATEGORY_FIELDS}
    }
  }
`;

export const HIDE_DEALER_CATEGORY = gql`
  mutation HideDealerCategory($id: ID!) {
    hideDealerCategory(
      id: $id
    ) {
      ${DEALER_CATEGORY_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_DEALER_CATEGORY = gql`
  mutation UnhideDealerCategory($id: ID!) {
    unhideDealerCategory(
      id: $id
    )
    {
      ${DEALER_CATEGORY_FIELDS_AND_ERRORS}
    }
  }
`;
