import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Formik, useFormikContext } from 'formik';
import { isoToday } from '../../../utils/dateHelpers';
import { Spacing, Typography } from '../../../styles';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'
import EditForm, { txnSchema } from './EditForm';
import DataState from '../../DataState';
import useClosedBooks from 'components/src/hooks/useClosedBooks';
import { TXN, UPDATE_TXN } from './Queries'

const EditTransaction = () => {
  const { id } = useParams();

  const customSubmitHandler = (values) => {
    values.typeName = undefined;

    if (values.entityId === '') {
      values.entityId = undefined;
    } else {
      values.entityId = parseInt(values.entityId)
    }

    if (values.entityType === '') {
      values.entityType = undefined;
    }

    if (values.purpose === '') {
      values.purpose = undefined
    }

    values.txnLinesAttributes.map((line, index) => {
      if (line.credit === '') {
        line.credit = undefined;
      } else if (line.debit === '') {
        line.debit = undefined;
      }

      if (line.accountId === '') {
        line.accountId = undefined;
      }

      if (line.marketId === '') {
        line.marketId = undefined;
      }

      if ((line.credit === undefined || line.credit === '') &&
        (line.debit === undefined || line.debit === '')) {
        values.txnLinesAttributes.splice(index, 1)
      }
    })
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_TXN,
    redirectToShowPageType: 'txn',
    customSubmitHandler: customSubmitHandler
  }

  const [
    ServerSideErrors,
    handleSubmit,
    formSubmitting
  ] = useServerSideErrors(serverSideErrorProps);

  const { loading, error, data } = useQuery(TXN, { variables: { id } });

  const checkClosedBooks = useClosedBooks(handleSubmit);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const {
    type,
    bankDate,
    name,
    reference,
    memo,
    entity,
    purpose
  } = data.txn;

  const paymentTermId = data.txn.paymentTerm?.id

  const Edit = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Edit Transaction</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              id: data.txn.id,
              type: type || undefined,
              bankDate: bankDate || undefined,
              name: name || undefined,
              paymentTermId: paymentTermId || undefined,
              entityType: entity?.__typename || undefined,
              typeName: entity?.__typename || undefined,
              entityId: entity?.id ? parseInt(entity.id) : undefined,
              reference: reference || '',
              memo: memo || '',
              purpose: purpose || undefined,
              txnLinesAttributes: [
                {
                  debit: '',
                  credit: '',
                  accountId: undefined,
                  marketId: undefined,
                  dealerId: undefined,
                  flooredCarId: undefined,
                  feeId: undefined,
                  earnedOn: undefined,
                  memo: '',
                  earnsInterest: undefined
                }
              ]
            }}
            validationSchema={txnSchema}
            onSubmit={checkClosedBooks}
          >
          <EditForm />
        </Formik>
        </div>
      </>
    );
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <Edit />
      </div>
    )
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default EditTransaction;
