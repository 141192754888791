import React, { Fragment, useState } from 'react';
import TransactionForm from '../Transactions/TransactionForm';
import TransactionLineForm from '../Transactions/TransactionLines/Form';
import CreditInput from '../Transactions/TransactionLines/CreditInput';
import TransactionLineInputs from
  '../Transactions/TransactionLines/TransactionLineInputs';
import TransactionLineFlooringInputs from
  '../Transactions/TransactionLines/TransactionLineFlooringInputs';
import { Form as FormikForm, useFormikContext } from 'formik';
import PaymentTermInput from '../Transactions/PaymentTermInput';
import OffsetTransactionLine from '../Transactions/OffsetTransactionLine';
import * as yup from 'yup';

export const txnSchema = yup.object({
  payableAccountId: yup
    .object()
    .nullable()
    .shape({
      value: yup
        .string()
        .required('required')
    })
  })
  .shape({
    txnLinesAttributes: yup
      .array()
      .of(
        yup.object().shape({
          credit: yup.number().test(
            'is-decimal',
            'invalid amount',
            value => !value || (value + "").match(/^\d*\.?\d{0,2}$/)
          ),
          accountId: yup
            .number()
            .when('credit', {
              is: '' || undefined,
              when: yup.number(),
              otherwise: yup.number().required('required')
            })
        }, ['credit'])
      )
})

const Form = () => {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const payableAccount = true

  let columns;

  if (!(location.search.includes('detailed=t'))) {
    columns = {
      display: 'grid',
      gridTemplateColumns: '2fr 2fr 2fr 1fr 4fr',
    }
  } else {
    columns = {
      display: 'grid',
      gridTemplateColumns: '2fr 2fr 2fr 1fr 1fr 4fr',
    }
  }

  return (
    <Fragment>
      <FormikForm>
        <TransactionForm
          referenceLabel='Credit Number'
          dateLabel='Credit Date'
        />
        <OffsetTransactionLine payableAccount={payableAccount} />
        <TransactionLineForm
          disableSubmit={disableSubmit}
          setDisableSubmit={setDisableSubmit}
          columns={columns}
        >
          <TransactionLineInputs>
            <CreditInput label="Amount" />
          </TransactionLineInputs>
        </TransactionLineForm>
      </FormikForm>
    </Fragment>
  )
}

export default Form;
