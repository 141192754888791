import { gql } from '@apollo/client';

export const CREATE_TITLE_LOG = gql`
  mutation CreateTitleLog(
    $flooredCarId: Int!
    $titleStatusId: Int!
    $createdById: Int!
    $trackingNumber: String
    $notes: String
    $fileUploadId: Int
    $shipTitle: Boolean
    $shipToId: Int
    $shipToType: String
    $otherAddress: AddressShipmentInput
  ) {
    createTitleLog(
      flooredCarId: $flooredCarId
      titleStatusId: $titleStatusId
      createdById: $createdById
      trackingNumber: $trackingNumber
      notes: $notes
      fileUploadId: $fileUploadId
      shipTitle: $shipTitle
      shipToId: $shipToId
      shipToType: $shipToType
      otherAddress: $otherAddress
    ) {
      errors {
        message
        path
      }
    }
  }
`;

export const TITLE_LOG_RESTRICTIONS = gql`
  query NoUserLogTitleLogRestrictions($dealerId: ID!) {
    titleLogs(scopes: ["unshipped"]) {
      flooredCar {
        vin
      }
    }
    dealer(id: $dealerId) {
      borrowedTitleRestrictions
    }
  }
`;

export const CREATE_FILE_UPLOAD = gql`
  mutation CreateFileUpload(
    $name: String!,
    $type: String!,
    $ownerType: String,
    $ownerId: Int,
    $createdById: Int,
    $file: Upload,
    $mainLotPhoto: Boolean,
  ) {
    createFileUpload(
      name: $name
      type: $type
      ownerType: $ownerType
      ownerId: $ownerId
      createdById: $createdById
      file: $file
      mainLotPhoto: $mainLotPhoto
    ) {
      fileUpload {
        id
        ownerType
        ownerId
        name
        type
        fileUrl
      }
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_TITLE_LOG = gql`
  mutation UpdateTitleLog(
    $id: ID!
    $flooredCarId: Int!
    $titleStatusId: Int!
    $trackingNumber: String
    $notes: String
    $fileUploadId: Int
    $otherAddress: AddressShipmentInput
  ) {
    updateTitleLog(
      id: $id
      flooredCarId: $flooredCarId
      titleStatusId: $titleStatusId
      trackingNumber: $trackingNumber
      notes: $notes
      fileUploadId: $fileUploadId
      otherAddress: $otherAddress
    ) {
      titleLog {
        id
        flooredCar { id }
        titleStatus { id, name }
        createdBy { id }
        trackingNumber
        notes
        fileUpload { id }
      }
      errors {
        message
        path
      }
    }
  }
`;

export const SELECT_OPTIONS = gql`
  query {
    dealers {
      id
      name
      dealerPlaces(scopes: ["visible"]) {
        id
        name
        street
        city
        state
        zip {
          value
        }
      }
      defaultMailingLocation {
        id
        name
        street
        city
        state
        zip {
          value
        }
      }
    }
    approvedSources {
      id
      name
      street
      city
      state
      zip { value }
      phone { value }
    }
    auctions {
      id
      name
      street
      city
      state
      zip { value }
      phone { value }
    }
    titleStatuses {
      id
      name
      info
      isPresent
      toDealer
      autoFeeTitleAdded
      autoFeeTitleBorrowed
      autoFeeTitleShipped
      autoFeeTitleMarkedPresent
      fund
      createdBy { firstName, lastName }
    }
  }
`;
