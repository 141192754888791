import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import { useParams, Redirect } from 'react-router-dom';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import Notes from './Notes';
import Select from 'react-select';
import { gql, useQuery } from "@apollo/client";

const PMSIS = gql`
  query {
    approvedSources(scopes: ["pmsi"]) {
      id
      name
      pmsiAddress
      pmsiCity
      pmsiState
      pmsiZip
      pmsiFilingLocation
      pmsiCompanyName
    }
    auctions(scopes: ["pmsi"]) {
      id
      name
      pmsiAddress
      pmsiCity
      pmsiState
      pmsiZip
      pmsiFilingLocation
      pmsiCompanyName
    }
  }
`

export const uccSchema = yup.object({
  filedOn: yup.string().required('Required'),
  originalFileDate: yup.string().required('Required'),
  filingLocation: yup.string().required('Required'),
  securedPartyName: yup.string().required('Required'),
  address: yup.string().required('Required'),
  city: yup.string().required('Required'),
  state: yup.string().max(2, 'Please enter the 2 letter state abbreviation').required('Required'),
  zip: yup.string().max(9, 'Please neter a valid zip code').required('Required'),
  pmsi: yup.string().when('pmsiOption', {
    is: 'manual',
    then: yup.string().required('Required'),
  }),
  pmsiTrackingNumber: yup.string().when('pmsiOption', {
    is: 'manual',
    then: yup.string().required('Required'),
  }),
  pmsiSendReceiveDate: yup.string().when('pmsiOption', {
    is: 'manual',
    then: yup.string().required('Required'),
  }),
});

export const Form = () => {
  const { values, setFieldValue, isValid, setValues } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();
  const dealerId = useParams().parentId;
  const uccId = useParams().ownerId;

  const { loading, error, data } = useQuery(PMSIS)

  useEffect(() => {
    if (values.pmsi === '') setFieldValue('pmsiSendReceiveDate', '')
  }, [values.pmsi])

  useEffect(() => {
    if (values.pmsiOption === 'create') {
      setFieldValue('pmsi', '')
      setFieldValue('pmsiSendReceiveDate', '')
      setFieldValue('pmsiTrackingNumber', '')
    }
  }, [values.pmsiOption])

  if (loading || !data) return null;
  if (error) return <DataState.Error error={error} />

  const approvedSources = data.approvedSources

  const auctions = data.auctions

  const partyOptions = [...approvedSources, ...auctions]

  const formattedParties = partyOptions.map((source) => {
    return { values: source.id, label: `${source.__typename} - ${source.name}` }
  })

  let getDefaultValues = partyOptions.find((value) => parseInt(value.id) === values.securedPartyId)

  let defaultValues = getDefaultValues ? { value: getDefaultValues?.id, label: `${getDefaultValues?.__typename} - ${getDefaultValues?.name}` } : null

  const handlePartyChange = (selected) => {
    let select = selected.values

    function search(select, partyOptions) {
      for(var i = 0; i < partyOptions.length; i++) {
        if (partyOptions[i].id === select) {
          return partyOptions[i]
        }
      }
    }

    var resultObject = search(select, partyOptions)

    values.securedPartyType = resultObject.__typename
    values.securedPartyId = parseInt(resultObject.id)

    values.address = resultObject.pmsiAddress
    values.city = resultObject.pmsiCity
    values.state = resultObject.pmsiState
    values.zip = resultObject.pmsiZip
    values.securedPartyName = resultObject.pmsiCompanyName

    setValues(values)
  }

  return (
    <FormikForm>
      <div>
        <BasicForm.Radio
          name="createOption"
          label="Create a PMSI to send to a Secured Party"
          type="radio"
          value="create"
          checked={values.createOption === 'create'}
        />
        <BasicForm.Radio
          name="createOption"
          label="Manually add a UCC Filing (use for XLF)"
          type="radio"
          value="manual"
          checked={values.createOption === 'manual'}
        />
      </div>
      {
        values.createOption === 'create' &&
          <>
            <BasicForm.StyledLabel>Secured Party</BasicForm.StyledLabel>
            <Select
              options={formattedParties}
              isMulti={false}
              onChange={handlePartyChange}
              defaultValue={defaultValues}
            />
          </>
      }
      <BasicForm.TextInput
        name="filedOn"
        type="date"
        label="Filed On"
      />
      <BasicForm.TextInput
        name="originalFileDate"
        type="date"
        label="Original File Date"
      />
      <BasicForm.TextInput
        name="filingLocation"
        type="text"
        label="Filing Location"
      />
      <BasicForm.TextInput
        name="securedPartyName"
        type="text"
        label="Secured Party Name"
      />
      <BasicForm.TextInput
        name="address"
        type="text"
        label="Address"
      />
      <BasicForm.TextInput
        name="city"
        type="text"
        label="City"
      />
      <BasicForm.StateAbbreviationSelect
        name="state"
        type="text"
        label="State"
      />
      <BasicForm.TextInput
        name="zip"
        type="text"
        label="Zip Code"
      />
      <div style={{marginTop: '25px'}}>
        <BasicForm.Radio
          name="pmsiOption"
          label="Create the PMSI and Fedex to send"
          type="radio"
          value="create"
          checked={values.pmsiOption === 'create'}
        />
        <BasicForm.Radio
          name="pmsiOption"
          label="I've already sent the PMSI"
          type="radio"
          value="manual"
          checked={values.pmsiOption === 'manual'}
        />
      </div>
      {
        values.pmsiOption === 'manual' &&
          <BasicForm.Select name="pmsi" label="PMSI Sent / Received">
            <option value="">Select</option>
            <option>Sent</option>
            <option>Received</option>
          </BasicForm.Select>
      }
      {values.pmsiOption === 'manual' && (values.pmsi === 'Sent') &&
        <BasicForm.TextInput
          name="pmsiSendReceiveDate"
          type="date"
          label="PMSI Sent On"
        />
      }
      {values.pmsiOption === 'manual' && (values.pmsi === 'Received') &&
        <BasicForm.TextInput
          name="pmsiSendReceiveDate"
          type="date"
          label="PMSI Received On"
        />
      }
      {
        values.pmsiOption === 'manual' &&
          <BasicForm.TextInput
            name="pmsiTrackingNumber"
            type="text"
            label="PMSI Tracking Number"
          />
      }
      {
        values.createOption === 'manual' &&
          <BasicForm.Checkbox
            name="xlf"
            type="checkbox"
            label="XLF?"
          />
      }
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={formSubmitting}
        />
      </div>
      {uccId && [<hr key={uccId} />, <Notes key={dealerId} />]}
    </FormikForm>
  );
}
