import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormikContext } from 'formik';
import * as BasicForm from "../../../elements/forms";
import * as Buttons from "../../../elements/buttons";
import { Spacing } from "../../../styles";
import { AdvanceType } from "./AdvanceType/AdvanceType";
import { Form } from "./ProcessPayment/Form";
import * as yup from 'yup';
import { currency } from '../../../utils/stringHelpers';

let modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px',
}

const labelStyle = {
  marginTop: "20px",
};

export const falsePrincipalSchema = yup.object({
  toAmount: yup
    .number()
    .required('ammount cannot be blank')
    .min(.01, 'ammount cannot be zero'),
})

export const principalSchema = yup.object({
  bankAccountId: yup
    .number()
    .when('advanceType', {
      is: 'source',
      then: yup.number().nullable().required('Account is required.')
    })
    .when('advanceType', {
      is: 'external',
      then: yup.number().nullable()
    })
    .when('advanceType', {
      is: 'On Account',
      then: yup.number().nullable()
    })
    .when('advanceType', {
      is: 'on_account',
      then: yup.number().nullable()
    })
    .when('advanceType', {
      is: 'Dealer Reserve',
      then: yup.number().nullable()
    }),

  accountId: yup
    .number()
    .when('advanceType', {
      is: 'source',
      then: yup.number().nullable()
    })
    .when('advanceType', {
      is: 'external',
      then: yup.number().nullable().required('Account is required.')
    })
    .when('advanceType', {
      is: 'On Account',
      then: yup.number().nullable()
    })
    .when('advanceType', {
      is: 'on_account',
      then: yup.number().nullable()
    })
    .when('advanceType', {
      is: 'Dealer Reserve',
      then: yup.number().nullable()
    }),

  txnType: yup
    .string()
    .when('advanceType', {
      is: 'external',
      then: yup.string().nullable().required()
    }),

  toAmount: yup
    .number()
    .required('ammount cannot be blank')
    .min(.01, 'ammount cannot be zero'),

  name: yup
    .string()
    .when('sourceType', {
      is: 'other',
      then: yup.string().required('A name is required.')
    }),

  reference: yup
    .string()
    .when('txnType', {
      is: 'Check',
      then: yup.string().required('A reference is required')
    })
    .when('txnType', {
      is: 'ACH',
      then: yup.string().required('A reference is required')
    })
    .when('txnType', {
      is: 'Wire',
      then: yup.string().required('A reference is required')
    })
    .when('txnType', {
      is: 'Cash',
      then: yup.string().required('A reference is required')
    }),
});

export const ProcessPrincipalAdjustment = ({ dealerName, reserveBalance, showModal, setShowModal, handleSubmit, funded, principal }) => {
  const { setFieldValue, values, errors, isValid, dirty } = useFormikContext();
  const handleClose = () => setShowModal(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledForm, setDisabledForm] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState()

  useEffect(() => {
    if (funded === false) {
      setFieldValue('txnType', 'Adjust Funding')
    }
  }, [funded])

  useEffect(() => {
    if (values.bankAccountId !== null && values.bankAccountId !== '') {
      setFieldValue('bankAccountId', parseInt(values.bankAccountId))
    }

    if (values.accountId !== null && values.accountId !== '') {
      setFieldValue('accountId', parseInt(values.accountId))
    }
  }, [values.bankAccountId, values.accountId])

  useEffect(() => {
    if (values.bankAccountId > 0) {
      setFieldValue('ach', true)
    }
  }, [values.bankAccountId])

  useEffect(() => {
    if (['Dealer Reserve', 'On Account', 'on_account', 'external'].includes(values.advanceType)) {
      setFieldValue('ach', false)
      setFieldValue('txnType', 'Journal Entry')
    }
  }, [values.advanceType])

  const amount = values.toAmount;

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            New Principal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <BasicForm.DollarInput
            name="toAmount"
            type="number"
            label="New Price"
            labelStyle={labelStyle}
          />
         {(() =>
            {if(funded === true) {
              return (
                <>
                {(() =>
                  {if(amount > principal) {
                    return (
                      <>
                        <AdvanceType key="advanceType" />
                      </>
                    )
                  } else {
                    return (
                      <>
                        <Form dealerName={dealerName} reserveBalance={reserveBalance} setDisabled={setDisabledForm} amount={amount} />
                      </>
                    )
                  }
                })()}
                </>
              )
            }
          })()}
          <BasicForm.TextInput
            name="memo"
            type="text"
            label="Memo"
            labelStyle={labelStyle}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose} />
            <Buttons.Standard
              type="submit"
              text="Submit"
              onClick={() => handleSubmit(values)}
              disabled={!isValid || formSubmitting}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
