import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  KPIS, UPDATE_KPI_NORMAL, CREATE_KPIS, DESTROY_KPIS
} from './queries';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Typography } from 'components/src/styles';
import DataState from 'components/src/components/DataState';
import moment from 'moment';
import MarketSelect from './MarketSelect';
import './style.css';

const INTS = [
  'totalRevenueGoal', 'activationsGoal', 'waiversGoal',
  'ebtdaGoal', 'ebtdaActual', 'flooredCarsGoal'
];

const CORPORATE_CALCULATED = [...INTS];

const FLOATS = [
  'delinquentPercentGoal', 'writeoffPercentGoal', 'auditFoundPercentGoal'
];

const ACTUAL = ['ebtdaActual'];

export const InputCell = ({ kpi, values, field, type, setField, save }) => {
  const handleChange = e => {
    setField(field, e.target.value);
  }

  const calculated = !values.market && CORPORATE_CALCULATED.includes(field);
  const tdClassName = ['input-cell'];
  if (calculated) { tdClassName.push('calculated'); }
  if (ACTUAL.includes(field)) { tdClassName.push('actual'); }

  return (
    <td className={tdClassName.join(' ')}>
      {'currency' === type && '$'}

      {calculated ? (
        <span>{kpi[field]}</span>
      ) : (
        <input
          type="number"
          value={values[field] || ''}
          onChange={handleChange}
          onBlur={save}
        />
      )}

      {'percent' === type && '%'}
    </td>
  );
}

const InputRow = ({ kpi, refetch }) => {
  const [values, setValues] = useState({...kpi});
  const [updateKPI] = useMutation(UPDATE_KPI_NORMAL, { onCompleted: refetch });

  const setField = (field, value) => {
    setValues({ ...values, [field]: value });
  }

  const save = () => {
    let changed = false;

    INTS.forEach(field => {
      values[field] = values[field] ? parseInt(values[field]) : null
      if (values[field] !== kpi[field]) { changed = true; }
    });

    FLOATS.forEach(field => {
      values[field] = values[field] ? parseFloat(values[field]) : null
      if (values[field] !== kpi[field]) { changed = true; }
    });

    if (changed) {
      const variables = {...values};
      if (!values.market) {
        CORPORATE_CALCULATED.forEach(field => delete variables[field]);
      }
      updateKPI({ variables });
    }
  }

  const inputProps = { kpi, values, setField, save };

  return (
    <tr>
      <td>{values.market?.name || 'CORPORATE'}</td>
      <td>{values.year}</td>
      <td>{values.period}</td>
      <InputCell field="totalRevenueGoal" type="currency" {...inputProps} />
      <InputCell field="activationsGoal" {...inputProps} />
      <InputCell field="delinquentPercentGoal" type="percent" {...inputProps} />
      <InputCell field="writeoffPercentGoal" type="percent" {...inputProps} />
      <InputCell field="waiversGoal" type="currency" {...inputProps} />
      <InputCell field="ebtdaGoal" type="currency" {...inputProps} />
      <InputCell field="ebtdaActual" type="currency" {...inputProps} />
      <InputCell field="auditFoundPercentGoal" type="percent" {...inputProps} />
      <InputCell field="flooredCarsGoal" {...inputProps} />
      <td>
        {kpi.goalsUpdatedBy && (
          <span>
            {moment(kpi.goalsUpdatedAt).format('M/D/YY')}-
            {kpi.goalsUpdatedBy.initials}
          </span>
        )}
      </td>
    </tr>
  );
};

const GoalsInputTable = ({ kpis, refetch }) => {
  return (
    <table className="kpi-input">
      <thead>
        <tr>
          <th>Market</th>
          <th>Year</th>
          <th>Month</th>
          <th>Total Revenue</th>
          <th>Activations</th>
          <th>Delinquent %</th>
          <th>Writeoff %</th>
          <th>Waivers/Month</th>
          <th>EBDTA Goal</th>
          <th>EBDTA Actual</th>
          <th>Lot Audit Found %</th>
          <th>Floored Cars</th>
          <th>Last Update</th>
        </tr>
      </thead>
      <tbody>
        {kpis.map(kpi => (
          <InputRow key={kpi.id} kpi={kpi} refetch={refetch} />
        ))}
      </tbody>
    </table>
  );
};

const Input = ({ InputTable, quartersOnly, title }) => {
  if (!InputTable) { InputTable = GoalsInputTable; }

  const [year, setYear] = useState(moment().year());
  const [period, setPeriod] = useState();
  const [marketId, setMarketId] = useState();
  const [addYear, setAddYear] = useState('');

  const { loading, error, data, refetch } = useQuery(
    KPIS, { variables: { marketId, period, year }}
  );

  const [createKPIs] = useMutation(CREATE_KPIS, { onCompleted: refetch });
  const [createYear] = useMutation(CREATE_KPIS, {
    onCompleted: () => refetch({ year: parseInt(addYear) })
  });
  const [destroyKPIs] = useMutation(DESTROY_KPIS, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const handleAddYear = () => {
    if (confirm(`Really add KPIs for ${addYear}?`)) {
      createYear({ variables: { year: parseInt(addYear), marketIds: null } });
    }
  };

  const removableMarkets = data.markets.filter(market => market.kpiCount > 0);
  const addableMarkets = data.markets.filter(market => market.kpiCount < 12);

  return (
    <>
      <Helmet>
        <title>{title || 'Input KPI Goals'}</title>
      </Helmet>
      <div style={Typography.pageTitle}>{title || 'Input KPI Goals'}</div>

      <Container>
        <Row className="display-selects">
          <Col>
            Display:

            <select
              defaultValue={year}
              onChange={e => setYear(parseInt(e.target.value)) }
            >
              {data.kpiYears.map(value => (
                <option key={value}>{value}</option>
              ))}
            </select>

            {!quartersOnly && (
              <select onChange={
                e => setPeriod(e.target.value ? parseInt(e.target.value) : null)
              }>
                <option value="">All Months</option>
                {Array.from({length: 12}, (_, i) => i + 1).map(value => (
                  <option key={value}>{value}</option>
                ))}
              </select>
            )}

            <select onChange={
              e => setMarketId(e.target.value ? parseInt(e.target.value) : null)
            }>
              <option value="">All Markets</option>
              {data.markets.map(value => (
                <option key={value.id} value={value.id}>{value.name}</option>
              ))}
            </select>
          </Col>
        </Row>

        <MarketSelect
          label="Remove"
          markets={removableMarkets}
          year={year}
          mutation={destroyKPIs}
        />
        <MarketSelect
          label="Add"
          markets={addableMarkets}
          year={year}
          mutation={createKPIs}
        />

        <Row className="year-add">
          <Col>Add Year:</Col>
          <Col>
            <input type="number" onChange={e => setAddYear(e.target.value)} />
          </Col>

          <Col>
            <button
              type="button"
              className="btn btn-primary kpi-btn"
              disabled={!addYear}
              onClick={handleAddYear}
            >
              Add Year
            </button>
          </Col>
        </Row>
      </Container>

      <InputTable kpis={data.kpis} refetch={refetch} />
    </>
  );
};

export default Input;
