import React, { useState } from 'react';
import DealerInfo from '../Dealers/DealerInfo';
import { useQuery } from '@apollo/client';
import { DEALER } from './Queries'
import DataState from '../DataState';
import { useParams } from "react-router-dom";
import UnpaidFees from '../Dealers/Fees/FeeTable';
import TempPrograms from '../Dealers/TempPrograms';
import * as Buttons from '../../elements/buttons';
import { toggleScopeButtonStyle, addRecord } from './Style';
import DataTable from '@preflighttech/preflight-tables';
import { columnDefinitions } from './columnDefinitions';
import { Typography, preflightTableStyles } from 'components/src/styles';
import { MediumRowLoading } from 'components/src/elements/Loading';
import BasicAlert from 'components/src/elements/BasicAlert';
import {
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthLink from 'components/src/components/AuthLink';

const unpaidColumns = [
  'warnings', 'newAuditCode', 'unitNumber', 'vin', 'year', 'make', 'model',
  'color', 'age', 'fundedOn', 'loanDate', 'currentDueOn',
  'blackbookValue', 'currentPrincipal', 'loanAmount', 'currentFees',
  'currentInterest', 'currentCollateralCoverage', 'totalDue',
  'sourceName', 'titleStatus', 'achButton', 'functions',
];

const allColumns = [
  ...unpaidColumns.slice(0, 9),
  'payoffOn',
  ...unpaidColumns.slice(9)
];

const Cars = props => {
  const id = parseInt(useParams().id);

  const [showAllCars, toggleCarScope] = useState(false);
  const [achAlert, setAchAlert] = useState(false);

  const scope = showAllCars ? 'ordered' : 'unpaid'

  const { loading, error, data, refetch } = useQuery(DEALER, { variables: {
    id, scopes: [scope]
  }});

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const toggleText = showAllCars ? 'Show Unpaid Cars' :
    'Show all cars ever floored, including paid off cars'

  const handleScopeClick = () => {
    toggleCarScope(!showAllCars)
  }

  const columnKeys = showAllCars ? allColumns : unpaidColumns;
  const columns = columnDefinitions({ refetch, setAchAlert, columnKeys });

  return (
    <>
      <DealerInfo />
      {
        data.dealer.unpaidFees.length > 0 &&
          <>
            <UnpaidFees />
            <hr />
          </>
      }
      {
        achAlert &&
          <BasicAlert componentType="Pending ACH" type="Cancelled" />
      }
      {
        loading ? <MediumRowLoading style={{marginLeft: '20px'}} /> :
          <Buttons.Link
            text={toggleText}
            style={toggleScopeButtonStyle}
            onClick={handleScopeClick}
          />
      }
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <span style={Typography.pageTitle}>Cars for {data.dealer.name}</span>
        <AuthLink
          to={`/dealers/${id}/cars/new`}
          resource="FlooredCar"
          action="create"
          style={addRecord}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Floor A Car
        </AuthLink>
      </div>
      <DataTable.Simple
        data={data.dealer.flooredCars}
        columns={columns}
        htmlTable
        pageLength={'All'}
        lengthMenu={ [10, 25, 50, 'All'] }
        movableColumns
        htmlTable
        styles={preflightTableStyles}
        copyComponent
      />
      <hr />
      <TempPrograms />
    </>
  )
}

export default Cars;
