import { gql } from '@apollo/client';
import { DEALER_MAIN_FIELDS } from '../components/Dealers/Queries';
export { TITLE_STATUSES } from '../components/System/TitleStatuses/queries';

/**
 * Here lie index queries - they are done here rather than within individual index files because
 * they need to be called in at least two places - both in the index, and within any refetchQueries
 * done through Edit/New.
 */

/**
 * Approved Sources
 */

 export const APPROVED_SOURCES_AUCTION_TOKENS = gql`
  query ApprovedSource($id: ID!) {
    approvedSource(id: $id) {
      id
      name
      auctionTokens { id, token }
    }
  }
`

export const HIDDEN_APPROVED_SOURCES_AUCTION_TOKENS = gql`
  query ApprovedSource($id: ID!) {
    approvedSource(id: $id) {
      id
      name
      auctionTokens(scopes: ["hidden"]) { id, token }
    }
  }
`

export const APPROVED_SOURCES_CONTACTS = gql`
  query ApprovedSource($id: ID!) {
    approvedSource(id: $id) {
      id
      name
      contacts {
        id
        firstName
        lastName
        hiddenAt
        officePhone {
          formatted
        }
        mobilePhone {
          formatted
        }
        email
        title
        primary
        receivesAuctionAvailability
        receivesPaymentInformation
      }
    }
  }
`

export const HIDDEN_APPROVED_SOURCES_CONTACTS = gql`
  query ApprovedSource($id: ID!) {
    approvedSource(id: $id) {
      id
      name
      contacts(scopes: ["hidden"]) {
        id
        firstName
        lastName
        hiddenAt
        officePhone {
          formatted
        }
        mobilePhone {
          formatted
        }
        email
        title
        primary
        receivesAuctionAvailability
        receivesPaymentInformation
      }
    }
  }
`

export const VENDOR_CONTACTS = gql`
  query Vendor($id: ID!) {
    vendor(id: $id) {
      id
      name
      contacts {
        id
        firstName
        lastName
        hiddenAt
        officePhone {
          formatted
        }
        mobilePhone {
          formatted
        }
        email
        title
        primary
        receivesAuctionAvailability
        receivesPaymentInformation
      }
    }
  }
`

export const HIDDEN_VENDOR_CONTACTS = gql`
  query Vendor($id: ID!) {
    vendor(id: $id) {
      id
      name
      contacts(scopes: ["hidden"]) {
        id
        firstName
        lastName
        hiddenAt
        officePhone {
          formatted
        }
        mobilePhone {
          formatted
        }
        email
        title
        primary
        receivesAuctionAvailability
        receivesPaymentInformation
      }
    }
  }
`

export const APPROVED_SOURCES_CARS = gql`
  query ApprovedSource($id: ID!) {
    approvedSource(id: $id) {
      id
      name
      flooredCars (scopes: ["unpaid"]) {
        id
        dealerLine { dealer { id, name } }
        market { id, name }
        rateProgram { id, name }

        termData { totalDays }

        vin, year, make, model, series, style, color
        status, loanAmount, loanDate, bosDate
        titleState, titleStatus, titleNumber, titleReleaseOn, unitNumber, stockNumber

        advanceType, sourceType, sourceId, externalPaymentMethod

        currentFees, currentInterest, currentPrincipal, currentDueOn,
        currentCollateralCoverage

        createdAt, updatedAt

        car { id  }
      }
    }
  }
`

/**
 * Auction related
 */

export const AUCTION_CONTACTS = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      id
      name
      contacts {
        id
        firstName
        lastName
        hiddenAt
        officePhone {
          formatted
        }
        mobilePhone {
          formatted
        }
        email
        title
        primary
        receivesAuctionAvailability
        receivesPaymentInformation
      }
    }
  }
`;

export const HIDDEN_AUCTION_CONTACTS = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      id
      name
      contacts(scopes: ["hidden"]) {
        id
        firstName
        lastName
        hiddenAt
        officePhone {
          formatted
        }
        mobilePhone {
          formatted
        }
        email
        title
        primary
        receivesAuctionAvailability
        receivesPaymentInformation
      }
    }
  }
`;

export const AUCTION_TOKENS = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      id
      name
      auctionTokens { id, token }
    }
  }
`

export const HIDDEN_AUCTION_TOKENS = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      id
      name
      auctionTokens(scopes: ["hidden"]) { id, token }
    }
  }
`

/**
 * Dealer Related
 */
export const DEALERS = gql`
  query {
    dealers {
      ${DEALER_MAIN_FIELDS}
      contractedOn
      auditStatus
      licenseNumber
      licenseExpiresOn
      insuranceExpiresOn
      bondExpiresOn
      annualReviewOn
      availableCredit
      branch {
        id
        name
        code
      }
    }
    currentUser {
      id
    }
  }
`;

export const DEALER_STATUSES = gql`
  query {
    dealerStatuses { id, name }
  }
`;

// TODO: Probably refactor these to query via Dealer, so I don't have to do a separate call just
//         to get the dealer name.
export const PLACES = gql`
  query DealerPlaces($dealerId: Int) {
    dealerPlaces(dealerId: $dealerId) {
      id

      dealer { id }

      name
      type

      phone { value, formatted }
      fax { value, formatted }

      attention
      street
      city
      state
      zip { value, formatted }

      latitude
      longitude
      canBeHidden

      hiddenAt, createdAt, updatedAt
    }
  }
`;

export const HIDDEN_PLACES = gql`
  query DealerPlaces($dealerId: Int) {
    dealerPlaces(dealerId: $dealerId, scopes: ["hidden"]) {
      id

      dealer { id }

      name
      type

      phone { value, formatted }
      fax { value, formatted }

      attention
      street
      city
      state
      zip { value, formatted }

      latitude
      longitude
      canBeHidden

      hiddenAt, createdAt, updatedAt
    }
  }
`;

/**
 * SYSTEM RELATED BELOW HERE
 */

/**
 * Markets
*/
export const MARKETS = gql`
  query {
    markets {
      id
      name
      timezone
      canBeHidden
      dealers {
        id
      }
    }
  }
`;

export const DEALERS_BY_MARKET = gql`
  query Market($id: ID!) {
    market(id: $id) {
      id
      dealers {
        ${DEALER_MAIN_FIELDS}
        contractedOn
        auditStatus
        licenseNumber
        licenseExpiresOn
        insuranceExpiresOn
        bondExpiresOn
        annualReviewOn
        availableCredit
        branch {
          id
          name
          code
        }
      }
    }
    currentUser {
      id
    }
  }
`

/**
 * Term Programs
 */
export const TERM_PROGRAMS = gql`
  query {
    termPrograms {
      id
      name
      extensionDays
      extensionLimit
      extensionPercent
      canBeHidden
      activeDealers { id, name }
      activeFlooredCars { id, name }
      nonNormalTerms
      averageDaysPerTerm
      termLines{
        id
        days
        curtailmentPercent
      }
    }
  }
`

export const HIDDEN_TERM_PROGRAMS = gql`
  query {
    termPrograms(scopes: ["hidden"]) {
      id
      name
      extensionDays
      extensionLimit
      extensionPercent
      activeDealers { name, id }
      activeFlooredCars { name, id }
      nonNormalTerms
      averageDaysPerTerm
      termLines{
        id
        days
        curtailmentPercent
      }
    }
  }
`

/**
 * User Related
 */

export const USER_SELECTS = gql`
  query {
    users {
      id
    }
    currentUser {
      id
    }
  }
`;

export const USER_LOGS_WITH_COUNT = gql`
  query (
    $id: ID!
    $userId: Int
    $limit: Int,
    $offset: Int,
    $sort: String,
    $sortDirection: String
    $search: String
    $filterColumns: [String!]
    $filterNames: [JSON!]
  ) {
    userLogsWithCount(
      userId: $userId
      limit: $limit,
      offset: $offset,
      sort: $sort,
      sortDirection: $sortDirection
      search: $search
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: ["user"]
    ) {
      userLogs {
        id, createdAt, userType, referrer, url, controller, action, userAgent,
        ipAddress, paramsId, params, formattedParams,
        user { fullName }
      }
      count
    }
    user(id: $id) {
      firstName
      lastName
    }
  }
`;

export const ALL_USER_LOGS_WITH_COUNT = gql`
  query (
    $limit: Int,
    $offset: Int,
    $sort: String,
    $sortDirection: String
    $search: String
    $filterColumns: [String!]
    $filterNames: [JSON!]
  ) {
    userLogsWithCount(
      limit: $limit,
      offset: $offset,
      sort: $sort,
      sortDirection: $sortDirection
      search: $search
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: ["user"]
    ) {
      userLogs {
        id, createdAt, userType, referrer, url, controller, action, userAgent,
        ipAddress, paramsId, params, formattedParams,
        user { fullName }
      }
      count
    }
  }
`;

//
// Title Statuses
//
export const TITLE_STATUSES_FOR_SELECT = gql`
  query {
    titleStatuses {
      id
      name
    }
  }
`;

// Transactions

export const TRANSACTIONS = gql`
  query {
    txns {
      id
    }
  }
`

// COLLATERAL COVERAGES CHANGES
export const COLLATERAL_COVERAGE_CHANGES = gql`
  query InsuranceProgram($id: ID!){
    insuranceProgram(id: $id){
        id
        insuranceProgramChanges { id, rate, effectiveOn }
    }
 }`

export const DEALER_LINE_COLLATERAL_COVERAGES = gql`
  query Dealer($id: ID!) {
    dealer(id: $id){
      id
      market { today }
      dealerLines {
        id
        dealerLineInsurancePrograms {
          id
          dealerLine { id }
          insuranceProgram { name }
          thirdParty
          effectiveOn
          expiresOn
          insuranceCompany
          createdBy { firstName, lastName }
          updatedBy { firstName, lastName }
          updatedAt
          activeRate
          activeProgram
        }
      }
    }
  }
`

export const HIDDEN_DEALER_LINE_COLLATERAL_COVERAGES = gql`
query Dealer($id: ID!) {
  dealer(id: $id){
    id
    market { today }
    dealerLines {
    dealerLineInsurancePrograms(scopes: ["hidden"]) {
      id
      dealerLine { id }
      insuranceProgram { name }
      thirdParty
      effectiveOn
      expiresOn
      insuranceCompany
      createdBy { firstName, lastName }
      updatedBy { firstName, lastName }
      updatedAt
      activeRate
      }
    }
  }
}
`

/**
 * FINANCE RELATED BELOW HERE
 */

/**
 * Accounts
*/

export const BANK_ACCOUNTS = gql`
  query {
    accounts(scopes: ["bank"]) {
      id
      name
    }
  }
`

export const RECONCILE = gql`
  query {
    reconciles {
      id
      account {
        id
        name
      }
      startOn
      endOn
      startBalance
      endBalance
      completedAt
    }
  }
`;

export const ACCOUNTS = gql`
  query {
    accounts {
      id
      name
      gl
      special { key, name }
      accountGroup { id, name }
    }
  }
`;

export const HIDDEN_ACCOUNTS = gql`
  query {
    accounts(scopes: ["hidden"]) {
      id
      name
      gl
      special { name }
      accountGroup { id, name }
    }
  }
`;

export const PAYMENTS = gql`
  query(
    $limit: Int,
    $offset: Int,
    $sort: String,
    $sortDirection: String,
    $search: String,
    $filterColumns: [String!],
    $filterNames: [JSON!],
  ){
    txnsWithCount(
    scopes: ["payment, advance"],
    offset: $offset,
    limit: $limit,
    sort: $sort,
    sortDirection: $sortDirection,
    search: $search,
    filterColumns: $filterColumns,
    filterNames: $filterNames) {
      txns {
        id
        purpose
        amount
        name
        memo
        type
        bankDate
        reference
        dealer { id, name }
        voidable
      }
      purposes
      paymentTypes
      dealers
      count
    }
  }
`;

export const DEALERS_WITH_COUNT = gql`
  query(
    $limit: Int,
    $offset: Int,
    $sort: String,
    $sortDirection: String
    $search: String
    $filterColumns: [String!]
    $filterNames: [JSON!]
    $scopes: [String!]
  ) {
    dealersWithCount(
      limit: $limit,
      offset: $offset,
      sort: $sort,
      sortDirection: $sortDirection
      search: $search
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: $scopes
    ) {
      count
      statuses
      auditStatuses
      branches
      markets
      dealerCategories
      dealers {
        ${DEALER_MAIN_FIELDS}
        contractedOn
        canBeHidden
        auditStatus
        licenseNumber
        licenseExpiresOn
        insuranceExpiresOn
        bondExpiresOn
        annualReviewOn
        availableCredit
        utilization
        branch {
          id
          name
          code
        }
      }
    }
  }
`

export const TXNS_WITH_COUNT = gql`
  query(
    $limit: Int,
    $offset: Int,
    $sort: String,
    $sortDirection: String,
    $search: String,
    $filterColumns: [String!],
    $filterNames: [JSON!],
    $scopes: [String!]
  ) {
    txnsWithCount(
      offset: $offset
      limit: $limit
      sort: $sort
      sortDirection: $sortDirection
      search: $search
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: $scopes
    ) {
      txns {
        id
        entity {
          id
          name
        }
        purpose
        amount
        name
        memo
        type
        bankDate
        createdBy { fullName }
        createdDate
        reference
        market { id, name }
        dealers { id, name }
        flooredCars { id, dealer { id }, vin }
        quote { id }
        voidBy { id, bankDate, createdBy { fullName } }
        voidOf { id, type, reference, purpose }
        voidable
        account { gl, name }
        bankAccount { owner { id, name } }
        sourceName
        dealerCount
        flooredCarCount
        dealer { accountNumber, name, id }
        voidBy { purpose }
      }
      createdBy
      purposes
      paymentTypes
      dealers
      count
    }
  }
`;

export const AUDITS = gql`
  query {
    audits {
      id
      dealer {name, id}
      quiktrakJobNumber
      inspector
      inspectionOn
      inspectorComment
      auditCars {
        id
        flooredCar { id, vin }
        rqid
        qtFound
        qtException
        qtStatus
        qtNotes
        qtExceptionType
        qtExceptionSubtype
        qtExceptionCode
        qtCustomerName
        qtFinanceName
        latitude
        longitude
        auditedByType
        auditedById
        auditedAt
      }
    }
  }
`;

export const PAYMENT_TERMS = gql`
  query {
  paymentTerms {
    id
    name
    days
  }
}`
