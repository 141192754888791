import { gql } from '@apollo/client';

export const REPORT_DATA = gql`
  query ReportData(
    $type: String!
    $params: JSON!
  ) {
    reportData(
      type: $type
      params: $params
    ) {
      headers
      entries {
        items {
          value
          style
          url
        }
        height
      }
    }
  }
`;
