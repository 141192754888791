import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '../../../hooks/useAlert';
import DataState from '../../DataState';
import { Helmet } from 'react-helmet';
import { setAlertData } from '../../../utils/setAlertData';
import DataTable from '../../../elements/DataTable';
import { RECONCILE } from '../../../queries/indexQueries';
import BasicAlert from '../../../elements/BasicAlert';
import * as DateHelpers from '../../../utils/dateHelpers';
import * as StringHelper from '../../../utils/stringHelpers';

const Reconciles: () => React$Node = (props) => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  const { loading, error, data } = useQuery(RECONCILE);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), []);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      title: "Account"
    },
    {
      title: "Statement Start Date",
      render: DateHelpers.renderDate
    },
    {
      title: "Statement End Date",
      render: DateHelpers.renderDate
    },
    {
      title: "Start Balance"
    },
    {
      title: "End Balance"
    },
    {
      title: "Completed At",
      render: DateHelpers.renderDate
    },
    {
      title: "Actions"
    }
  ]

  const dataSet = data.reconciles.map(function (reconcile) {
    if (reconcile.completedAt) {
      reconcile.actions = `
        <a href="/financial/reconciles/${reconcile.id}">View</a>
      `;
    } else {
      reconcile.actions = `
        <a href="/financial/reconciles/${reconcile.id}">Perform</a>
        | <a href="/financial/reconciles/${reconcile.id}/edit">Edit</a>
      `;
    }

    return [
      reconcile.account.name,
      DateHelpers.setDate(reconcile.startOn),
      DateHelpers.setDate(reconcile.endOn),
      StringHelper.currency(reconcile.startBalance),
      StringHelper.currency(reconcile.endBalance),
      DateHelpers.setDate(reconcile.completedAt),
      reconcile.actions
    ]
  })

  return (
    <>
      <Helmet>
        <title>Bank Reconciles</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="reconcile" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Bank Reconciles'}
        unhideable={true}
      />
    </>
  )
}

export default Reconciles;
