import React, { useContext } from 'react';
import moment from 'moment';
import { Row } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { useRouteMatch, Link } from 'react-router-dom';
import * as DateHelper from '../../../utils/dateHelpers';
import * as TimeHelper from '../../../utils/timeHelpers';
import * as Style from '../../../styles/Source/showInfo';

const HIDE_SALE_TIME = gql`
  mutation HideSaleTime($id: ID!) {
      hideSaleTime(
        id: $id
      ) {
        saleTime {
          id
        }
        errors {
          message
          path
        }
      }
    }`

export const AddSaleTime = ({ auth, saleTimes, auctionId }) => {
  const currentRoute = getCurrentRoute();
  const message = (saleTimes.length > 0)
    ? 'Add Another Sale Time'
    : 'Add A Sale Time'

 return (checkAuthPolicy(auth))
  ? (
    <Row>
      <Link
        style={Style.saleInfo, Style.blueText}
        to={{
          pathname: `/auctions/${auctionId}/sale-times/new`,
          state: { previousRoute: currentRoute }
        }}>
        {message}
      </Link>
    </Row>
    )
  : null;
};

export const renderSaleTimes = (saleTimes, auctionId, auth) => {
  const [hideSaleTime] = useMutation(HIDE_SALE_TIME,{
    onCompleted(values) {

      location.reload(true)
    }
  });

  $(document).on('click', '#hide', function(event) {
    let id = event.target.dataset.id
    id = parseInt(id, 10)

    hideSaleTime({ variables: { id } })
  })

  return saleTimes.map((saleTime) => {
      const { id, name, type, occursOn, dayOfWeek, saleStartAt, saleEndAt } = saleTime;

      if (saleTime.occursOn && DateHelper.isOneWeekOld(occursOn)) {
        return null;
      }

      let overDue = false;
      if (occursOn && DateHelper.isOlderThanToday(occursOn)) {
        overDue = true;
      }

      const currentRoute = getCurrentRoute();

      const edit = <Link
        style= {setLinkStyle(overDue)}
        to={{
          pathname: `/auctions/${auctionId}/sale-times/${id}/edit`,
          state: { previousRoute: currentRoute }
        }}>
        Edit
      </Link>
      const remove = <a style={setLinkStyle(overDue)} href='' data-id={id} id='hide'>Hide</a>

      let formattedOccursOn = DateHelper.setDate(occursOn);

      const startAt = TimeHelper.getTime(saleStartAt);
      const endAt = TimeHelper.getTime(saleEndAt);
      const day = DateHelper.getDayOfWeek(dayOfWeek);

    return <Row key={id} style={setStyle(overDue)}>
      <span style={Style.saleInfo}>{day || formattedOccursOn}:
        {` ${startAt} - ${endAt}`}
        { checkAuthPolicy(auth) && <EditRemoveFunctions edit={edit} remove={remove}/>}
      </span>
    </Row>
    })
}

export const sortTimes = (saleTimes) => {
  let occursOnArray = [];

  saleTimes = filterSaleTimes(saleTimes, occursOnArray);
  saleTimes = sortDayOfWeek(saleTimes);
  occursOnArray = sortOccursOn(occursOnArray);
  saleTimes.push(occursOnArray);
  return saleTimes.flat();
}

export const checkAuthPolicy = (auth) => {
  return auth.hasPolicy('Auction', 'update')
}

const filterSaleTimes = (saleTimes, occursOnArray) => {
  return saleTimes.filter((saleTime) => {
    if (saleTime.occursOn) occursOnArray.push(saleTime)
    if (saleTime.dayOfWeek || saleTime.dayOfWeek === 0) return saleTime
    },
  )
}

const sortDayOfWeek = (saleTimes) => {
  saleTimes = saleTimes.sort((a, b) => (a.dayOfWeek > b.dayOfWeek) ? 1 : -1 );
  saleTimes = saleTimes.sort((a, b) => (a.dayOfWeek === b.dayOfWeek) && (a.saleStartAt < b.saleStartAt) ? -1 : 1);
  return saleTimes.sort((a, b) => (b.dayOfWeek === null && a.dayOfWeek) ? -1 : 1);
}

const sortOccursOn = (occursOnArray) => {
  return occursOnArray.sort((a,b) => new moment(a.occursOn).format('YYYYMMDD') - new moment(b.occursOn).format('YYYYMMDD'));
}

const EditRemoveFunctions = ({ edit, remove }) => {
  return (
    [
      <span key={'edit'}> |
        {edit}
      </span>,
      <span key={'remove'}> |
        {remove}
      </span>
    ]
  )
}



const setStyle = (overDue) => {
  return (overDue)
    ? { color: 'red' }
    : {}
}

const setLinkStyle = (overDue) => {
  return (overDue)
    ? { paddingLeft: '3px', color: 'red'}
    : linkStyle
}


const linkStyle = {
  color: 'black',
  paddingLeft: '3px'
}

const getCurrentRoute = () => {
  return useRouteMatch().url;
}



