import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const PercentageRange = ({columnKey, label, filters, setFilter}) => {
  const columnFilter = filters[columnKey] || [];

  const [fromPercentage, setFromPercentage] = useState(columnFilter[0]);
  const [toPercentage, setToPercentage] = useState(columnFilter[1]);

  if ('undefined' === typeof filters[columnKey]) {
    setFilter(columnKey, null);
    if (fromPercentage) { setFromPercentage(undefined) };
    if (toPercentage) { setToPercentage(undefined) };
  }

  const updateFilter = () => {
    if (fromPercentage && toPercentage) {
      setFilter(columnKey, [fromPercentage, toPercentage]);
    }
  }

  const updateFromPercentage = value => {
    setFromPercentage(value);
    updateFilter();
  };

  const updateToPercentage = value => {
    setToPercentage(value);
    updateFilter();
  };

  return (
    <Row key={columnKey}>
      <Col>
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col>
        From %
        <Form.Control
          type="text"
          value={fromPercentage || ''}
          onBlur={event => updateFromPercentage(event.currentTarget.value)}
          onChange={event => setFromPercentage(event.currentTarget.value)}
        />
      </Col>
      <Col>
        To %
        <Form.Control
          type="text"
          value={toPercentage || ''}
          onBlur={event => updateToPercentage(event.currentTarget.value)}
          onChange={event => setToPercentage(event.currentTarget.value)}
        />
      </Col>
    </Row>
  );
};

export default PercentageRange;
