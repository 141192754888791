import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { approvedSourceSchema, Form } from './Form';
import {
  APPROVED_SOURCES, CREATE_APPROVED_SOURCE, CREATE_FILE_UPLOAD
} from './queries';
import Loading from '../../elements/Loading';
import { nullifyBlanks } from 'components/src/utils/nullifyBlanks';

const NewApprovedSource = (props) => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [createApprovedSource] = useMutation(CREATE_APPROVED_SOURCE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: APPROVED_SOURCES }],
      onCompleted(values) {
        setFormSubmitting(false);
        setRedirect(true);
      }
    }
  );

  let submittedValues = useRef();

  const [createFileUpload, { loading: fileLoading }] = useMutation(
    CREATE_FILE_UPLOAD, {
      onCompleted(values) {
        const id = parseInt(values.createFileUpload.fileUpload.id)
        submittedValues.current.agreementUploadId = id
        createApprovedSource({ variables: submittedValues.current })
      }
    }
  )

  const handleSubmit = (values) => {
    values.state = values.state.toUpperCase();
    nullifyBlanks(values, ['agreementDate']);

    if (values.pmsiOption === false) {
      values.pmsiAddress = ''
      values.pmsiCity = ''
      values.pmsiState = ''
      values.pmsiZip = ''
      values.pmsiCompanyName = ''
    }

    setFormSubmitting(true)

    if (values.file !== undefined) {
      submittedValues.current = values;
      createFileUpload({ variables: values });
    } else {
      createApprovedSource({ variables: values })
    }
  };

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/approved-sources`,
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create An Approved Source</h1>
          </div>
          <Formik
            initialValues={{
              name: '',
              city: '',
              state: '',
              type: '',
              street: '',
              zip: '',
              phone: '',
              titleFundingStatusIds: [],
              agreementOnFile: false,
              agreementDate: '',
              pmsiOption: false,
              pmsiAddress: '',
              pmsiCity: '',
              pmsiState: '',
              pmsiZip: '',
              pmsiCompanyName: '',
              pmsiPhone: ''
            }}
            validationSchema={approvedSourceSchema}
            onSubmit={handleSubmit}
          >
            <Form formSubmitting={formSubmitting} />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default NewApprovedSource;
