import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataTables from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import AuthLink from 'components/src/components/AuthLink';
import { AUCTIONS, HIDE_AUCTION, UNHIDE_AUCTION } from './queries';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { titleCase } from 'components/src/utils/stringHelpers';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert from 'components/src/elements/BasicAlert';
import { preflightTableStyles } from 'components/src/styles';

const Auctions = props => {
  const [showHidden, setShowHidden] = useState(false);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data, refetch } = useQuery(
    AUCTIONS,
    { variables: { scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_AUCTION, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_AUCTION, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'name',
      label: 'Name',
      sort: 'asc',
      content: ({ entry, value }) => (
        <Link to={`/auctions/${entry.id}`}>{value}</Link>
      ),
    },
    {
      key: 'xlgAuction',
      label: 'XLG Auction',
      content: ({ value }) => (
        value && <i className="fas fa-check" style={{color: '#2b6448'}}></i>
      )
    },
    {
      key: 'primaryContact',
      label: 'Primary Contact',
      value: ({ value }) => value?.fullName,
    },
    {
      key: 'officePhone',
      label: 'Contact Phone #',
      value: ({ entry }) => entry.primaryContact?.officePhone?.formatted,
    },
    {
      key: 'auctionAccessId',
      label: 'Auction Access ID#',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'url',
      label: 'Website',
      value: ({ value }) => (
        value && <a href={value} target="_blank">{value}</a>
      ),
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix="/auctions"
          resource={entry}
          hide={hide}
          unhide={unhide}
        />
      )
    },
  ]

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="Auction" type={alertType} />
      )}
      <ResourcesHeader
        title="Auctions"
        prefix="/auctions"
        resourceType="Auction"
        showHidden={showHidden}
        setShowHidden={setShowHidden}
      />

      <DataTables.Simple
        data={data.auctions}
        columns={columns}
        pageLength={10}
        htmlTable
        movableColumns
        multiSort
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Auctions;
