import React from 'react';
import { formNumberStyle } from "./Form";
import * as StringHelper from "../../utils/stringHelpers";

const StaticData = ({ quoteData, tableLoading, types}) => {
  if (types && !Array.isArray(types)) types = [types];
  if (!types) types = ['interest', 'insurance', 'reserves'];

  return types.map((type, index) =>
    <td key={index} style={formNumberStyle}>
      {(quoteData && StringHelper.currency(quoteData[type])) || tableLoading}
    </td>
  )
}

export default StaticData;
