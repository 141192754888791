import { gql } from '@apollo/client';
import { BANK_ACCOUNTS_SUBQUERY } from '../BankAccounts/queries';
import { NOTES_SUBQUERY } from '../Notes/queries';

const ERRORS = `errors { path, message }`;

const APPROVED_SOURCE_FIELDS = `
  id
  type
  name
  street
  city
  state
  zip { value, formatted }
  phone { formatted }
  agreementOnFile
  agreementDate
  agreementUpload { fileUrl }
  titleFundingStatusIds
  pmsiOption
  pmsiAddress
  pmsiCity
  pmsiState
  pmsiZip
  pmsiCompanyName
  pmsiFilingLocation
  pmsiPhone { formatted }
  createdAt
  updatedAt
  hiddenAt
  canBeHidden
  primaryContact {
    id
    fullName
    officePhone {
      value
      formatted
    }
    email
  }
`;

const APPROVED_SOURCE_FIELDS_AND_ERRORS = `
  approvedSource {
    ${APPROVED_SOURCE_FIELDS}
  }
  ${ERRORS}
`;

export const APPROVED_SOURCE = gql`
  query ApprovedSource($id: ID!) {
    approvedSource(id: $id) {
      ${APPROVED_SOURCE_FIELDS}
      flooredCarsAmount
    }
  }
`;

export const APPROVED_SOURCES = gql`
  query ApprovedSources($scopes: [String!]) {
    approvedSources(
      scopes: $scopes
    ) {
      ${APPROVED_SOURCE_FIELDS}
    }
  }
`;

const APPROVED_SOURCE_VARIABLES = `
  $name: String!
  $city: String
  $state: String
  $type: String!
  $street: String
  $zip: String
  $phone: String
  $agreementOnFile: Boolean
  $agreementDate: ISO8601Date
  $agreementUploadId: Int
  $titleFundingStatusIds: [Int!]
  $pmsiOption: Boolean
  $pmsiAddress: String
  $pmsiCity: String
  $pmsiState: String
  $pmsiZip: String
  $pmsiCompanyName: String
  $pmsiFilingLocation: String
  $pmsiPhone: String
`;

const APPROVED_SOURCE_ARGUMENTS = `
  name: $name
  city: $city
  state: $state
  type: $type
  street: $street
  zip: $zip
  phone: $phone
  agreementOnFile: $agreementOnFile
  agreementDate: $agreementDate
  agreementUploadId: $agreementUploadId
  titleFundingStatusIds: $titleFundingStatusIds
  pmsiOption: $pmsiOption
  pmsiAddress: $pmsiAddress
  pmsiCity: $pmsiCity
  pmsiState: $pmsiState
  pmsiZip: $pmsiZip
  pmsiCompanyName: $pmsiCompanyName
  pmsiFilingLocation: $pmsiFilingLocation
  pmsiPhone: $pmsiPhone
`;

export const CREATE_APPROVED_SOURCE = gql`
  mutation CreateApprovedSource(
    ${APPROVED_SOURCE_VARIABLES}
  ) {
    createApprovedSource (
      ${APPROVED_SOURCE_ARGUMENTS}
    ) {
      ${APPROVED_SOURCE_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_APPROVED_SOURCE = gql`
  mutation UpdateApprovedSource(
    $id: ID!
    ${APPROVED_SOURCE_VARIABLES}
  ) {
    updateApprovedSource (
      id: $id
      ${APPROVED_SOURCE_ARGUMENTS}
    ) {
      ${APPROVED_SOURCE_FIELDS_AND_ERRORS}
    }
  }
`;

export const HIDE_APPROVED_SOURCE = gql`
  mutation HideApprovedSource($id: ID!) {
    hideApprovedSource(
      id: $id
    ) {
      ${APPROVED_SOURCE_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_APPROVED_SOURCE = gql`
  mutation UnhideApprovedSource($id: ID!) {
    unhideApprovedSource(
      id: $id
    ) {
      ${APPROVED_SOURCE_FIELDS_AND_ERRORS}
    }
  }
`;

export const APPROVED_SOURCE_BANK_ACCOUNTS = gql`
  query ApprovedSource($id: ID!, $scopes: [String!]) {
    approvedSource(id: $id) {
      id
      name
      ${BANK_ACCOUNTS_SUBQUERY}
    }
  }
`;

export const APPROVED_SOURCE_NOTES = gql`
  query ApprovedSource($id: ID!, $scopes: [String!]) {
    approvedSource(id: $id) {
      id
      name
      ${NOTES_SUBQUERY}
    }
  }
`;

export const CREATE_FILE_UPLOAD = gql`
  mutation CreateFileUpload(
    $ownerId: Int
    $file: Upload!
  ) {
    createFileUpload(
      name: "Intercreditor Agreement"
      type: "AWS Storage"
      ownerType: "ApprovedSource"
      ownerId: $ownerId
      file: $file
    ) {
      fileUpload {
        id
      }
      ${ERRORS}
    }
  }
`;
