import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import '../styles.css';
import styled from '@emotion/styled';

const Card = styled.div`
  background-color: #d48a00;
  border-radius: 6px;
  border: 2px solid black;
  margin-bottom: 10px;
  min-height: 52px;
  color: black;
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const TableLink = ({ label, to }) => {
  return (
    <Card>
      <Link to={to} target="_blank">{label}</Link>
    </Card>
  );
};

const DashboardTables = () => {
  return (
    <Col className="dashboard-column tasks" xs={12} lg={6}>
      <h2>Tasks</h2>

      <Row>
        <Col xs={6}>
          <TableLink label="Open Audits" to="/audits" />
          <TableLink
            label="Upcoming Payments & Past Due"
            to="/dashboard/payments-due"
          />
          <TableLink
            label="Unpaid Borrowed Titles"
            to="/dashboard/borrowed-titles"
          />
        </Col>

        <Col xs={6}>
          <TableLink label="POA & CPP" to="/dashboard/poa-cpp" />
          <TableLink label="Collections" to="/dashboard/collections" />
          <TableLink
            label="Unverified and Sold Cars"
            to="/dashboard/unverified"
          />
        </Col>
      </Row>
    </Col>
  );
};

export default DashboardTables;
