import React from 'react';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';
import * as BasicForm from '../../../elements/forms';

export const fileUploadSchema = yup.object({
  name: yup.string().required('Required'),
  file: yup.string().required('Required')
})

export const Form = ({ handleClose, show }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleFile = (file) => {
    setFieldValue('file', file)
  }

  return  <Modal
     size="lg"
     aria-labelledby="contained-modal-title-vcenter"
     centered
     onHide={handleClose}
     show={show}
    >
    <Modal.Header closeButton>
      <Modal.Title>
        Upload A File
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormikForm>
        <BasicForm.TextInput
          name="name"
          type="text"
          label="Name"
        />
        <BasicForm.PdfUpload
          name='file'
          label='File'
          handleFile={handleFile}
        />
        <div style={Spacing.buttonRow}>
          <Buttons.ModalCancel handleClose={handleClose} type="cancel" text="Cancel" />
          <Buttons.Standard type="submit" text="Submit" />
        </div>
      </FormikForm>
    </Modal.Body>
  </Modal>
}

