import { gql } from '@apollo/client';

const ACH_BATCH_FIELDS = `
  id
  direction
  sentAt
  bankDate
  batchId
  filename
`;

export const ACH_BATCHES = gql`
  query AchBatches {
    achBatches(scopes: ["hasTxns"]) {
      ${ACH_BATCH_FIELDS}
    }
  }
`;

export const ACH_BATCH = gql`
  query AchBatch($id: ID!) {
    achBatch(id: $id) {
      ${ACH_BATCH_FIELDS}
      txns {
        id
        bankAccount {
          owner { id, name }
          accountName
        }
        amount
        dealer { id, name }
        flooredCar { id, vin }
        voidBy { purpose }
      }
      data
    }
  }
`;

export const ACH_BATCH_DECRYPTED = gql`
  query AchBatch($id: ID!) {
    achBatch(id: $id) {
      ${ACH_BATCH_FIELDS}
      decryptedData
    }
  }
`;

export const CREATE_INCOMING_ACH = gql`
  mutation CreateIncomingAch {
    createIncomingAch {
      achBatch { id }
    }
  }
`;

export const CREATE_OUTGOING_ACH = gql`
  mutation CreateOutgoingAch {
    createOutgoingAch {
      achBatch { id }
    }
  }
`;

export const PROCESS_AUTOPAY = gql`
  mutation ProcessAutopay {
    processAutopay {
      quoteCars { id }
    }
  }
`;
