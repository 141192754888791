import React from 'react';
import {
  currency, numberWithDelimiter
} from 'components/src/utils/stringHelpers';
import { renderDate } from 'components/src/utils/dateHelpers';

const columnDefinitions = [
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'vin',
    label: 'VIN',
  },
  {
    key: 'year',
    label: 'Year',
    minViewportWidth: 1200,
  },
  {
    key: 'make',
    label: 'Make',
    minViewportWidth: 1200,
  },
  {
    key: 'model',
    label: 'Model',
    minViewportWidth: 1200,
  },
  {
    key: 'color',
    label: 'Color',
    minViewportWidth: 1600,
  },
  {
    key: 'mileage',
    label: 'Mileage',
    content: ({ value }) => numberWithDelimiter(value),
  },
  {
    key: 'loanAmount',
    label: 'Loan Amount',
    content: ({ value }) => currency(value),
  },
  {
    key: 'type',
    label: 'Type',
    minViewportWidth: 1400,
  },
  {
    key: 'source',
    label: 'Source',
    value: ({ value }) => value?.name,
    minViewportWidth: 800,
  },
  {
    key: 'dealer',
    label: 'Dealer',
    value: ({ entry }) => entry.dealerLine?.dealer?.name,
    minViewportWidth: 800,
  },
  {
    key: 'purchaseType',
    label: 'Purchase Type',
    minViewportWidth: 1400,
  },
  {
    key: 'floorplanDate',
    label: 'Floorplan Date',
    content: ({ value }) => renderDate(value),
    sort: 'desc',
    minViewportWidth: 800,
  },
  {
    key: 'purchaseDate',
    label: 'Purchase Date',
    content: ({ value }) => renderDate(value),
    minViewportWidth: 800,
  },
  {
    key: 'titlePresent',
    label: 'Title Present?',
    value: ({ value }) => value ? 'Yes' : 'No',
    minViewportWidth: 800,
  },
  {
    key: 'issueMessages',
    label: 'Issues',
    minViewportWidth: 1000,
    content: ({ value }) => (
      <>
        {(value || []).map(message => (
          <span key={message}>{message}<br /></span>
        ))}
      </>
    )
  },
];

export default columnDefinitions;
