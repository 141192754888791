import React, { useContext, useState } from 'react';
import { UserContext } from 'components/src/contexts/UserContext';
import { useLazyQuery } from '@apollo/client';
import { kpiResultsQuery, FIELD_MAP } from './queries';
import './style.css';
import {
  numberWithDelimiter as delimited
} from 'components/src/utils/stringHelpers';

const currency = value => `$${delimited(value)}`;

const ProgressBar = ({ progress }) => {
  const percent = Math.min(100, Math.max(0, (progress * 100))).toFixed(1);

  return (
    <div className="kpi-progress">
      <span>{percent}%</span>
      <div className="kpi-progress-bar">
        <div className="kpi-progress-complete" style={{width: `${percent}%`}}>
          &nbsp;
        </div>
      </div>
    </div>
  );
};

const KPIRow = props => {
  const { field, label, type, results, years, ...defaults } = props;

  const [year, setYear] = useState(defaults.year);
  const [startQuarter, setStartQuarter] = useState(defaults.startQuarter);
  const [endQuarter, setEndQuarter] = useState(defaults.endQuarter);

  const today = new Date();
  const user = useContext(UserContext);
  const isCorporate = user.isCorporate();

  const startQuarterOptions = [1,2,3,4].filter(quarter => {
    if (quarter > endQuarter) { return false; }
    if (isCorporate) { return true; }

    if (year == today.getFullYear() && (quarter - 1) * 3 > today.getMonth()) {
      return false;
    }

    return true;
  });

  const endQuarterOptions = [1,2,3,4].filter(quarter => {
    if (quarter < startQuarter) { return false; }
    if (isCorporate) { return true; }

    if (year == today.getFullYear() && (quarter - 1) * 3 > today.getMonth()) {
      return false;
    }

    return true;
  });

  const yearOptions = years.filter(year => {
    return isCorporate || year <= today.getFullYear();
  });

  const kpiRowResults = kpiResultsQuery(FIELD_MAP[field]);
  const [getRowData, { data: rowData }] = useLazyQuery(kpiRowResults);

  const update = ({ newYear, newStartQuarter, newEndQuarter }) => {
    if (newYear) { setYear(newYear); }
    if (newStartQuarter) { setStartQuarter(newStartQuarter); }
    if (newEndQuarter) { setEndQuarter(newEndQuarter); }

    getRowData({
      variables: {
        year: newYear || year,
        startQuarter: newStartQuarter || startQuarter,
        endQuarter: newEndQuarter || endQuarter,
        marketIds: defaults.marketIds
      }
    });
  };

  const data = rowData?.kpiResults || results;

  let goal = data[`${field}Goal`];
  let actual = data[`${field}Actual`];

  let progress = 0.0;
  if (goal) {
    progress = actual / goal;
  } else if (actual) {
    progress = 1; // No goal
  }

  let detail;

  if ('currency' === type) {
    goal = currency(goal);
    actual = currency(actual);
    detail = (<ProgressBar progress={progress} />);
  } else if (['percent', 'delinquent', 'writeoff'].includes(type) ) {
    goal = goal ? `${goal.toFixed(1)}%` : '';
    actual = actual ? `${actual.toFixed(1)}%` : '0.0%';

    if ('delinquent' === type) {
      detail = `${data.delinquentTimesExceeded} times exceeded`;
    } else if ('writeoff' === type) {
      detail =
        `Amount for past 180 Days: ${currency(data.writeoffAmount180Days)}`;
    }
  } else if ('waivers' === type) {
    goal = `${currency(goal)}/mo`;
    actual = `${currency(data.waiversActualMonth)} (mo)`;
    detail = `${currency(data.waiversActualYtd)} (ytd)`;
  } else {
    goal = delimited(goal);
    actual = delimited(actual);
    detail = (<ProgressBar progress={progress} />);
  }

  return (
    <tr>
      <td>{label}</td>
      <td>
        <select
          value={year}
          style={{width: '100%'}}
          onChange={e => update({newYear: parseInt(e.target.value)})}
        >
          {yearOptions.map(year => <option key={year}>{year}</option>)}
        </select>
      </td>
      <td>
        <select
          value={startQuarter}
          style={{width: '100%'}}
          onChange={e => update({newStartQuarter: parseInt(e.target.value)})}
        >
          {startQuarterOptions.map(quarter => (
            <option key={quarter}>{quarter}</option>
          ))}
        </select>
      </td>
      <td>
        <select
          value={endQuarter}
          style={{width: '100%'}}
          onChange={e => update({newEndQuarter: parseInt(e.target.value)})}
        >
          {endQuarterOptions.map(quarter => (
            <option key={quarter}>{quarter}</option>
          ))}
        </select>
      </td>
      <td className="goal">{goal}</td>
      <td className="actual">{actual}</td>
      <td className="detail">{detail}</td>
    </tr>
  );
};

export default KPIRow;
