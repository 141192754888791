import React from 'react';
import { Typography } from '../../../styles';
import Info from '../Info';

const index = () => {
  return (
    <>
      <Info />
      <div style={Typography.pageTitle}>FPRs</div>
    </>
  );
};

export default index;
