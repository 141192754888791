import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { vendorSchema, Form } from './Form';
import { VENDORS } from './index';
import Loading from '../../elements/Loading';

const CREATE_VENDOR = gql`
  mutation CreateVendor($name: String!, $city: String, $state: String,
    $street: String, $zip: String, $phone: String, $tin: String,
    $tinType: String, $federalClassification: String,
    $taxClassification: String, $eligibleFor1099: String,
    $paymentTermId: String, $expenseAccountId: Int, $payableAccountId: Int) {
    createVendor (
      name: $name,
      city: $city,
      state: $state,
      street: $street,
      zip: $zip,
      phone: $phone,
      tin: $tin,
      tinType: $tinType,
      federalClassification: $federalClassification,
      taxClassification: $taxClassification,
      eligibleFor1099: $eligibleFor1099,
      paymentTermId: $paymentTermId,
      expenseAccountId: $expenseAccountId,
      payableAccountId: $payableAccountId
    ) {
      vendor { id, name }
      errors { message, path }
    }
  }
`;

const NewVendor = (props) => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [createVendor] = useMutation(CREATE_VENDOR,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: VENDORS }],
      onCompleted(values) {
        setFormSubmitting(false);
        setRedirect(true);
      }
    }
  );

  const handleSubmit = (values) => {
    values.state = values.state.toUpperCase();
    setFormSubmitting(true)
    createVendor({ variables: values })
  };

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/vendors`,
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A Vendor</h1>
          </div>
          <Formik
            initialValues={{
              name: '',
              city: '',
              state: '',
              street: '',
              zip: '',
              phone: '',
              tin: '',
              tinType: undefined,
              federalClassification: undefined,
              taxClassification: undefined,
              eligibleFor1099: undefined,
              paymentTermId: undefined,
              expenseAccountId: undefined,
              payableAccountId: undefined
            }}
            validationSchema={vendorSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default NewVendor;
