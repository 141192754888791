import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DealerPlaces from '../components/DealerPlaces';
import NewDealerPlace from '../components/DealerPlaces/New';
import EditDealerPlace from '../components/DealerPlaces/Edit';
import DealerPeople from '../components/DealerPeople';
import NewDealerPerson from '../components/DealerPeople/New';
import EditDealerPerson from '../components/DealerPeople/Edit';
import Cars from '../components/Cars';
import CollateralCoverage from '../components/Dealers/Collateral';
import NewCollateralCoverage from '../components/Dealers/Collateral/New';
import UpdateCollateralCoverage from '../components/Dealers/Collateral/Edit';
import NewCar from '../components/Cars/New';
import ShowCar from '../components/Cars/Show';
import EditDealer from '../components/Dealers/Edit';
import DealerTransactions from '../components/Dealers/Transactions';
import Accruals from '../components/Dealers/Transactions/Accruals';
import Payments from '../components/Dealers/Payments';
import Audits from '../components/Dealers/Audits';
import Audit from '../components/Dealers/Audits/Show';
import Notes from '../components/Dealers/Notes';
import NewNote from '../components/Notes/New';
import EditNote from '../components/Notes/Edit';
import Files from '../components/Dealers/Files';
import UCCs from '../components/Dealers/UCCs';
import NewUCC from '../components/Dealers/UCCs/New';
import EditUCC from '../components/Dealers/UCCs/Edit';
import NewUccNote from '../components/Dealers/UCCs/Notes/New';
import BankAccounts from '../components/Dealers/BankAccounts';
import NewBankAccount from '../components/Dealers/BankAccounts/new';
import NewLotImage from '../components/Dealers/LotImage/New';
import WriteOffs from '../components/Dealers/WriteOffs';
import OnAccount from '../components/Dealers/Accounts/OnAccount/OnAccount';
import Reserves from '../components/Dealers/Accounts/Reserves/Reserves';
import CreditHistory from '../components/Dealers/CreditHistory';
import DealerDeltas from '../components/Dealers/Deltas';
import NsfHistory from '../components/Dealers/NsfHistory';
import PaymentsReport from '../components/Dealers/PaymentsReport';

const DealerRoutes = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          path={`${match.path}/people/new`}
          render={(props) => <NewDealerPerson {...props} showInfo={false} />}
          exact
        />
        <Route path="/dealers/:id/people" component={DealerPeople} exact />
        <Route path="/dealers/:dealerId/people/:id/edit" component={EditDealerPerson} exact />
        <Route path="/dealers/:id/places" component={DealerPlaces} exact />
        <Route path="/dealers/:id/places/new" component={NewDealerPlace} exact />
        <Route path="/dealers/:dealerId/places/:id/edit" component={EditDealerPlace} exact />
        <Route path="/dealers/:id/transactions" component={DealerTransactions} exact />
        <Route path="/dealers/:id/accruals" component={Accruals} />
        <Route path="/dealers/:id/collateral-coverages" component={CollateralCoverage} exact />
        <Route path="/dealers/:id/collateral-coverages/new" component={NewCollateralCoverage} />
        <Route path="/dealers/:id/collateral-coverages/:collateralCoverageId" component={UpdateCollateralCoverage} />
        <Route path="/dealers/:id/cars" component={Cars} exact />
        <Route path="/dealers/:id/cars/new" component={NewCar} />
        <Route path="/dealers/:id/cars/:carId" component={ShowCar} />
        <Route path="/dealers/:id/payments" component={Payments} exact />
        <Route path="/dealers/:id/audits" component={Audits} exact />
        <Route path="/dealers/:id/audits/:id" component={Audit} exact />
        <Route path="/dealers/:ownerId/notes" component={Notes} exact />
        <Route path="/dealers/:ownerId/notes/new" component={NewNote} exact />
        <Route path="/dealers/:ownerId/notes/:id/edit" component={EditNote} exact />
        <Route path="/dealers/:id/files" component={Files} exact />
        <Route path="/dealers/:id/uccs" component={UCCs} exact />
        <Route path="/dealers/:id/uccs/new" component={NewUCC} />
        <Route path="/dealers/:parentId/uccs/:ownerId/edit" component={EditUCC} />
        <Route path="/dealers/:parentId/uccs/:ownerId/notes/new" component={NewUccNote} />
        <Route path="/dealers/:parentId/uccs/:ownerId/notes/:id/edit" component={EditNote} />
        <Route path="/dealers/:id/bank-accounts" component={BankAccounts} exact />
        <Route path="/dealers/:id/bank-accounts/new" component={NewBankAccount} />
        <Route path="/dealers/:id/new-lot-image" component={NewLotImage} />
        <Route path="/dealers/:id/edit" component={EditDealer} />
        <Route path="/dealers/:id/write-offs" component={WriteOffs} exact />
        <Route path="/dealers/:id/accounts/on-account" component={OnAccount} exact />
        <Route path="/dealers/:id/accounts/reserves" component={Reserves} exact />
        <Route path="/dealers/:id/credit-history" component={CreditHistory} />
        <Route path="/dealers/:id/deltas" component={DealerDeltas} exact />
        <Route path="/dealers/:id/nsf-history" component={NsfHistory} />
        <Route path="/dealers/:id/payments-report" component={PaymentsReport} exact />
      </Switch>
    </>
  )
}

export default DealerRoutes;
