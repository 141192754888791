import React, { useContext, useState, useEffect, useRef } from "react";
import { useAlert } from "../../../hooks/useAlert";
import DataTable from "../../../elements/DataTable";
import { useParams, Redirect } from "react-router-dom";
import secrets from "../../../secrets";
import DataState from "../../DataState";
import { AUTH_TOKEN } from "../../../constants";
import { ExportToExcelAlert } from "../../../elements/BasicAlert";
import { setAlertData } from "../../../utils/setAlertData";
import {
  FLOORED_CARS_WITH_COUNT
} from 'components/src/components/Cars/Queries';
import { useQuery } from "@apollo/client";
import * as StringHelper from "../../../utils/stringHelpers";
import * as DateHelpers from "../../../utils/dateHelpers";
import { getColumnName } from "./ColumnSort";
import {
  initialPaginationData,
  insertTableData,
  reSortTableData,
} from "../../../elements/Pagination/Functions";
import { useLazyLoader } from "../../../hooks/useLazyLoader";
import { UserContext } from "../../../contexts/UserContext";
import {
  responsiveDefaults,
  getInitialColumnSortIndex,
  createOrder,
} from "../../../utils/datatableHelpers";
import {
  columnDefinitions
} from 'components/src/components/Cars/columnDefinitions';
import ReactDOMServer from 'react-dom/server';

const columnKeys = [
  'dealer', 'dealerNumber', 'market', 'vin', 'unitNumber',
  'year', 'make', 'model', 'color', 'loanDate', 'currentDueOn',
  'fundedOn', 'age', 'status', 'achStatus', 'currentPrincipal',
  'currentFees', 'currentInterest', 'currentCollateralCoverage', 'totalDue',
  'titleStatus', 'sourceName', 'functions'
];

const columnChanges = [
  {
    key: 'currentPrincipal',
    label: 'Principal'
  }
];

const columnData = columnDefinitions(
  { columnKeys, columnChanges }
);

export const columns = columnData.map(({ label, cellStyle }) => (
  {
    title: label,
    className: cellStyle ? 'text-right' : undefined
  }
));

const cell = (entry, { key, value, content }) => {
  let retval = entry[key];

  if (value) {
    retval = value({ entry, value: retval });
  }

  if (content) {
    retval = ReactDOMServer.renderToString(content({ entry, value: retval }));
  }

  return retval;
};

const row = (entry, columnDefs) => {
  return columnDefs.map(columnDef => cell(entry, columnDef));
};

const Cars = (props) => {
  const isComingDue = useParams().comingDue;

  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const [queryLoading, setQueryLoading] = useState(false);
  const [sorting, setSorting] = useState(false);

  const [customAlert, displayCustomAlert] = useState(false);
  const user = useContext(UserContext);

  let tableId = "#car-table";

  if (isComingDue) {
    tableId = "#coming-due-table";
  }

  const { screen } = props

  //** Pagination **//
  // In addition to these functions, remember to add the Lazy Loading functions
  // that are set before DataSet in order to update the table with new data.
  let flooredCarCount = useRef(0);

  const [initialPageLimit, initialOffset] = initialPaginationData();

  //** Initial Sort **//
  // This must be the same as in the sort_by method in the model.
  const initialSort = (isComingDue)
    ? "next_due_date"
    : "dealer";

  const initialSortColumnTitle = (isComingDue)
    ? 'Next Due Date'
    : "Dealer";

  const initialSortDirection = (isComingDue)
    ? "desc"
    : "asc";

  const columnId = getInitialColumnSortIndex(columns, initialSortColumnTitle);

  const order = createOrder(columnId, initialSortDirection);

  responsiveDefaults.order = order;
  responsiveDefaults.columns = columns;
  responsiveDefaults.columnDefs =  [
    { responsivePriority: 1, targets: [9, 10] },
    { responsivePriority: 2, targets: -1 },
  ];

  const initializeData = {
    initialPageLimit,
    initialSortDirection,
    initialSort,
  };
  //  **  //

  const datatable = $(tableId).DataTable(responsiveDefaults);

  const [limit, setLimit] = useState(initialPageLimit);
  const [queryData, setQueryData] = useState(null);

  let filterButtonText = null;
  let currentFilters = useRef({
    market: null,
    dealer: null,
    title_status: null,
    source: null,
    source_type: null,
    status: null,
    funded: null,
    dealer_statuses: null,
    loan_date: ["", ""],
    funded_date: ['', ''],
  });

  let pageTitle = "Cars";

  if (screen === 'titleStatus') {
    currentFilters = useRef({
      title_status: null,
    });

    filterButtonText = "Select Statuses"
    pageTitle = "Cars By Title Status"
  }

  //** Filter Tracking ** //
  // For corporate market only //
  let filterMarket = useRef([]);
  //
  let filterDealers = useRef([]);
  let filterTitleStatuses = useRef([]);
  let filterSources = useRef([]);
  let filterSourceTypes = useRef([]);
  let filterStatuses = useRef([]);
  let filterFunded = useRef([]);
  let filterSold = useRef([]);
  let filterDealerStatuses = useRef([]);
  let filterLoanDates = useRef([]);
  let filterFundedDates = useRef([]);
  //**//
  //***//

  const marketId = parseInt(user.currentMarket.id) || null;

  const variables = {
    limit: initialPageLimit,
    offset: initialOffset,
    sort: initialSort,
    sortDirection: initialSortDirection,
    marketId: user.filterMarketIds(),
  };

  // Put default, static filters for coming due page here - must include market.
  if (isComingDue) {
    variables.filterNames = ["", ["Unpaid"], ''];
    variables.filterColumns = ["market", "status", "next_due_date"];
  }

  const { loading, error, data, fetchMore } = useQuery(
    FLOORED_CARS_WITH_COUNT,
    { variables }
  );

  // Initialize Lazy Loader //
  const [
    handlePaginate,
    handleColumnSort,
    handleFilters,
    handleSearch,
    currentPage,
    sort,
    sortColumnIndex,
    sortDirection,
    offset,
    search,
  ] = useLazyLoader(
    limit,
    fetchMore,
    setQueryData,
    flooredCarCount,
    marketId,
    currentFilters,
    getColumnName,
    queryData,
    setQueryLoading,
    datatable,
    initializeData,
    setSorting
  );

  // Export To Excel
  const handleExportToExcel = () => {
    const axios = require("axios");
    const token = localStorage.getItem(AUTH_TOKEN);
    const FileDownload = require("js-file-download");
    axios({
      method: "get",
      url: `${secrets.api.baseUri}/cars_report`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/xlsx",
        Authorization: `${token}`,
        JWT_AUD: "react",
      },
      params: {
        search: search.current,
        market_id: [marketId],
        sort: sort.current,
        offset: offset.current,
        current_page: currentPage.current,
        sort_column_index: sortColumnIndex.current,
        current_filters: currentFilters.current,
        floored_car_count: flooredCarCount.current,
      },
    }).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };
  //

  useEffect(() => setAlertData(props, setAlertType, displayAlert), []);

  // Query Next Results For Lazy Loading Pagination
  useEffect(() => {
    setQueryData(data);
  }, [data]);

  if (loading || !data || !queryData) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  //*** Set Filter Drop Down Data ***//
  flooredCarCount.current = queryData.flooredCarsWithCount.count;
  filterDealers.current = queryData.flooredCarsWithCount.dealerNames;
  filterTitleStatuses.current = queryData.flooredCarsWithCount.titleStatuses;
  filterSources.current = queryData.flooredCarsWithCount.sources.map((type) =>
    JSON.parse(type)
  );
  filterSourceTypes.current = queryData.flooredCarsWithCount.sourceTypes;
  filterStatuses.current = ["Paid", "Unpaid", "Void", "Write Off"];
  filterFunded.current = ["Funded", "Not Funded"];
  filterSold.current = ['Sold - Unpaid', 'Sold', 'Not Sold'];
  filterDealerStatuses.current = queryData.flooredCarsWithCount.dealerStatuses;
  //****//

  if (!marketId)
    filterMarket.current = queryData.markets.map((market) => market.name);

  let dataSet = [];

  if (!sorting) {
    dataSet = queryData.flooredCarsWithCount.flooredCars.map(entry => (
      row(entry, columnData)
    ))
  }

  //** Lazy Loading Functions **//
  // Reset datatable values on data change;
  insertTableData(datatable, dataSet);
  // Since we're programmatically setting the datatable order, datatables
  // acts weird and tries to interject, this allows us to sort correctly.
  reSortTableData(datatable, sortColumnIndex, sortDirection);

  let filters = {};

  if (!isComingDue)
    filters = {
      filterData: [
        {
          source: filterSources.current,
        },
        {
          funded: filterFunded.current,
        },
        {
          sold: filterSold.current,
        },
      ],
      filterMultiSelectData: [
        {
          dealer: filterDealers.current,
        },
        {
          title_status: filterTitleStatuses.current,
        },
        {
          status: filterStatuses.current,
        },
        {
          source_type: filterSourceTypes.current,
        },
        {
          dealer_status: filterDealerStatuses.current,
        },
      ],
      filterDateRange: [
        {
          loan_date: filterLoanDates.current,
          funded_date: filterFundedDates.current,
        },
      ],
    };

  if (screen === 'titleStatus') {
    filters = {
      filterData: [
      ],
      filterMultiSelectData: [

        {
          title_status: filterTitleStatuses.current,
        },
      ]
    }
  }

  const filterData = [
    isComingDue ? null : currentFilters, //maybe
    isComingDue ? null : handleFilters,
    queryLoading,
    filters,
  ];

  if (!marketId && !isComingDue && screen !== 'titleStatus') {
    filters.filterMultiSelectData.unshift({
      market: filterMarket.current,
    });
  }

  const columnDefs = [
    { responsivePriority: 1, targets: [9, 10] },
    { responsivePriority: 2, targets: -1 },
  ]

  return (
    <>
      <div style={{ maxHeight: "100px" }}>
        {customAlert && <ExportToExcelAlert />}
        <DataTable
          handleExportToExcel={handleExportToExcel}
          tableId={tableId}
          dataSet={dataSet}
          columns={columns}
          pageTitle={pageTitle}
          unhideable={true}
          uncreatable={true}
          handlePaginate={handlePaginate}
          currentPage={currentPage.current}
          paginateLimit={limit}
          setPaginateLimit={setLimit}
          paginateItemsCount={flooredCarCount.current}
          handleColumnSort={handleColumnSort}
          handleSearch={handleSearch}
          handleFilter={filterData}
          setSorting={setSorting}
          filterButtonText={filterButtonText}
          columnDefs={columnDefs}
        />
      </div>
    </>
  );
};

export default Cars;
