export const columnHeaderStyle = {
  minWidth: "130px",
  borderBottom: "none",
};

export const summaryRow = {
  display: "flex",
  justifyContent: "space-evenly",
};

export const summaryText = {
  fontSize: "40",
  fontWeight: "bold",
};
