import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import DataTables from '@preflighttech/preflight-tables';
import Header from '../Header';
import Filter from 'components/src/elements/Filter';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';
import { rest } from 'components/src/utils/rest';
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert, {
  ExportToExcelAlert
} from 'components/src/elements/BasicAlert';
import { UserContext } from 'components/src/contexts/UserContext';
import { POA_CPP } from './queries';
import { preflightTableStyles } from 'components/src/styles';
import { setDate } from 'components/src/utils/dateHelpers';

const columns = [
  {
    key: 'dealer',
    label: 'Dealer',
    value: ({ entry }) => entry.dealer.name,
    content: ({ entry }) => (
      <Link to={`/dealers/${entry.dealer.id}`}>{entry.dealer.name}</Link>
    )
  },
  {
    key: 'market',
    label: 'Market',
    value: ({ entry }) => entry.dealer.market.name
  },
  {
    key: 'type',
    label: 'Missing Type',
  },
  {
    key: 'insuranceExpiresOn',
    label: 'CPP Expiration Date',
    sort: 'asc',
    value: ({ entry }) => (
      'CPP' === entry.type && entry.dealer.insuranceExpiresOn ?
        entry.dealer.insuranceExpiresOn : '9999-12-31'
    ),
    content: ({ value }) => '9999-12-31' === value ? '' : setDate(value),
  }
];

const PoaCpp = () => {
  const user = useContext(UserContext);
  const [filters, setFilters] = useState({});
  const [alertType, setAlertType] = useState();
  const [customAlert, displayCustomAlert] = useState(false);
  const [showAlert, displayAlert] = useAlert();
  const [settings, setSettings] = useState({});

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);
  };

  const queryVariables = {...filters};
  queryVariables.sessionMarketIds = user.filterMarketIds() || [];

  if (!queryVariables.marketIds) {
    queryVariables.marketIds = user.filterMarketIds() || [];
  }

  const { loading, error, data } = useQuery(
    POA_CPP,
    { variables: queryVariables }
  );

  const handleExportToExcel = () => {
    rest({
      path: 'report/poa-cpp-dealers',
      params: {
        search: settings.search,
        sort: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.direction,
        ...queryVariables
      },
    }).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <ExportToExcel handleExportToExcel={handleExportToExcel} />

      <Filter clearFilters={() => setFilters({})}>
        <Filter.MultiSelect
          columnKey="marketIds"
          label="Market"
          options={data?.poaCpp?.markets}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.MultiSelect
          columnKey="types"
          label="Missing Type"
          options={['POA', 'CPP']}
          filters={filters}
          setFilter={setFilter}
        />
      </Filter>
      <ResetSettings />
    </View>
  )

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="POA & CPP" type={alertType} />
      )}
      { customAlert && <ExportToExcelAlert /> }
      <Header title="POA & CPP" />
      <DataTables.Simple
        data={data?.poaCpp?.entries}
        columns={columns}
        buttons={buttons}
        htmlTable
        movableColumns
        styles={preflightTableStyles}
        setSettings={setSettings}
      />
    </>
  );
};

export default PoaCpp;
