import React, { useContext, useState, useEffect } from 'react'
import DataTable from '../../../elements/DataTable';
import * as DateHelpers from '../../../utils/dateHelpers';
import * as StringHelpers from '../../../utils/stringHelpers';
import AuthContext from '../../../contexts/AuthContext';
import {
  canPrintCheck,
  PrintForm,
  setupPrintCheckHandler
} from '../../Transactions/functions';
import Edit from '../../Transactions/CheckNumbers/Edit';

const ReturnedTxns = ({ txns, showCheckModal, setShowCheckModal }) => {
  const [checkNumberForm, setCheckNumberForm] = useState(false);
  const [lastDownloadId, setLastDownloadId] = useState(null);
  const [lastDownloadTime, setLastDownloadTime] = useState(null);
  const [txnId, setTxnId] = useState();

  const auth = useContext(AuthContext);

  const columns = [
    {
      title: 'Bank Date',
      render: DateHelpers.renderDate,
    },
    {
      title: 'Type'
    },
    {
      title: 'Name'
    },
    {
      title: 'Amount',
      className: 'text-right'
    },
    {
      title: 'Actions',
    }
  ]

  const dataSet = txns.map((txn) => {
    const id = txn.id

    $(document).on('click', `#check-print${id}`, function(event) {
      setTxnId(id)
      setCheckNumberForm(true)
      setupPrintCheckHandler(
        txn,
        lastDownloadId, lastDownloadTime,
        setLastDownloadId, setLastDownloadTime, setShowCheckModal
      );
    });

    const bankDate = DateHelpers.setDate(txn.bankDate);

    const actions = [];

    if (txn.type === "Check") {
      actions.push(`<a href="#" id='check-print${id}' data-id=${id} data-txn='${txn}'>Print Check</a>`)
    }

    actions.push(`<a href="/transactions/${txn.id}"/>View</a>`);

    return [
      bankDate,
      txn.type || '',
      txn.name || '',
      StringHelpers.currency(txn.amount),
      actions.join(" | ")
    ]
  })

  return (
    <>
      {
        checkNumberForm &&
          <Edit
            checkNumberForm={checkNumberForm}
            setCheckNumberForm={setCheckNumberForm}
            txnId={txnId}
            setShowCheckModal={setShowCheckModal}
            lastDownloadTime={lastDownloadTime}
            setLastDownloadTime={setLastDownloadTime}
            lastDownloadId={lastDownloadId}
            setLastDownloadId={setLastDownloadId}
          />
      }
      <DataTable
        uncreatable
        unhideable
        tableId={'#txns'}
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Transactions'}
      />
    </>
  )
}

export default ReturnedTxns;
