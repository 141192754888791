import React, { useState } from 'react';
import { View } from 'react-native';
import styled from '@emotion/styled';
import DataTables from '@preflighttech/preflight-tables';
import Filter from 'components/src/elements/Filter';
import { rest } from 'components/src/utils/rest';
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import BasicAlert, {
  ExportToExcelAlert
} from 'components/src/elements/BasicAlert';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';
import { Typography, Spacing } from 'components/src/styles';
import { columnDefinitions, allColumns } from './columnDefinitions';
import { TXN_LINES_WITH_COUNT } from './queries';
import featureEnabled from 'components/src/utils/featureEnabled';

export const Label = styled.label`
  display: block;
  flex-direction: row;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
`;

const TxnLinesTable = props => {
  let { title, shownColumns } = props; // All tables
  const { txnLines } = props; // Simple table
  const { defaultFilters } = props; // Gql table

  const [count, setCount] = useState();
  const [customAlert, displayCustomAlert] = useState(false);
  const [filterOptions, setFilterOptions] = useState();
  const [filters, setFilters] = useState(defaultFilters || {});
  const [settings, setSettings] = useState({});
  const [showCurrency, setShowCurrency] = useState(true)

  if (!shownColumns) {
    shownColumns = allColumns;
  }

  const columns = columnDefinitions(
    { columns: shownColumns, rawAmount: !showCurrency }
  );

  const dataUpdated = data => {
    setFilterOptions(data?.txnLinesWithCount);
    setCount(data?.txnLinesWithCount.count);
  }

  const queryVariables = {
    filterColumns: [], filterNames: [], scopes: []
  };

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);
  };

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      queryVariables['filterColumns'].push(key);
      queryVariables['filterNames'].push(value);
    }
  });

  const handleExportToExcel = () => {
    rest({
      path: 'report/general-ledger',
      params: {
        search: settings.search,
        sort: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.sort,
        current_filters: queryVariables,
      },
    }).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <Label>
        <input
          type="checkbox"
          checked={showCurrency}
          onChange={() => setShowCurrency(!showCurrency)}
        />
        <span style={{marginLeft: 10}}>Show Currency</span>
      </Label>
      {
        !txnLines &&
          <>
            <ExportToExcel
              handleExportToExcel={handleExportToExcel}
              disabled={!count || count > 100000}
              disabledMessage="There must be between 1 and 100,000 entries to export"
            />
            <Filter clearFilters={() => setFilters({})}>
              <Filter.MultiSelect
                columnKey="account_id"
                label="Account"
                options={filterOptions?.accounts}
                filters={filters}
                setFilter={setFilter}
              />
              <Filter.MultiSelect
                columnKey="market_id"
                label="Market"
                options={filterOptions?.markets}
                filters={filters}
                setFilter={setFilter}
              />
              {
                featureEnabled('doubleEntry', 'departments') &&
                  <Filter.MultiSelect
                    columnKey="department_id"
                    label="Department"
                    options={filterOptions?.departments}
                    filters={filters}
                    setFilter={setFilter}
                  />
              }
              <Filter.DateRange
                columnKey="earned_on"
                label="GL Date"
                filters={filters}
                setFilter={setFilter}
              />
              <Filter.DateRange
                columnKey="created_date"
                label="Created Date"
                filters={filters}
                setFilter={setFilter}
              />
            </Filter>
            <ResetSettings />
          </>
      }
    </View>
  );

  return (
    <>
      { customAlert && <ExportToExcelAlert /> }
      {
        title !== false &&
          <div style={Typography.pageTitle}>{title || 'Lines'}</div>
      }
      <div style={Spacing.tableWrapper}>
        {
          txnLines ?
            <DataTables.Simple
              data={txnLines}
              columns={columns}
              buttons={buttons}
              pageLength={10}
              lengthMenu={ [10, 25, 50, 'All'] }
              htmlTable
            />
          :
            <DataTables.Gql
              query={TXN_LINES_WITH_COUNT}
              queryName="txnLinesWithCount"
              queryVariables={queryVariables}
              columns={columns}
              buttons={buttons}
              pageLength={10}
              lengthMenu={ [10, 25, 50, 100] }
              dataUpdated={dataUpdated}
              setSettings={setSettings}
              htmlTable
            />
        }
      </div>
    </>
  );
};

export default TxnLinesTable;
