import React, { useContext } from 'react';
import { Typography } from '../../../styles';
import DealerInfo from '../DealerInfo';
import { useParams, Redirect } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import NewQuote from '../../WriteOffs/New';
import { DEALER_FLOORED_CARS } from '../../../queries/viewQueries';
import DataState from '../../DataState';
import AuthContext from '../../../contexts/AuthContext';


const index = () => {
  const id = parseInt(useParams().id);
  const auth = useContext(AuthContext);

  const { loading, error, data } = useQuery(DEALER_FLOORED_CARS, {
    variables: { id }
  })

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { dealer: { flooredCars } } = data;

  if (!auth.hasExactPolicy('Quote', 'writeoff')) {
    return (
      <Redirect
        to={{
          pathname: `/dealers/${id}`,
          state: { alert: true, unauthorized: true }
        }}
      />
    )
  }
  return (
    <>
      <div style={Typography.pageTitle}>Write Off A Car</div>
      <NewQuote data={flooredCars} />
    </>
  );
};

export default index;
