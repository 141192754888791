import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { useAlert } from '../../hooks/useAlert';
import DataState from '../DataState';
import { personSchema, Form } from './Form';
import { titleCase } from '../../utils/stringHelpers';
import Loading from '../../elements/Loading';
import { useServerSideErrors } from '../../hooks/useServerSideErrors'
import { DEALER_PERSON, DEALER_PERSON_FIELDS_AND_ERRORS } from './queries';

const UPDATE_DEALER_PERSON = gql`
  mutation UpdateDealerPerson(
    $id: ID!,
    $firstName: String,
    $lastName: String,
    $title: String,
    $role: String,
    $email: String,
    $mobilePhone: String,
    $homePhone: String,
    $officePhone: String,
    $homeStreet: String,
    $homeCity: String,
    $creditScore: String,
    $homeState: String,
    $homeZip: String,
   ) {
    updateDealerPerson(
      id: $id
      firstName: $firstName
      lastName: $lastName
      title: $title
      role: $role
      email: $email
      mobilePhone: $mobilePhone
      homePhone: $homePhone
      officePhone: $officePhone
      homeStreet: $homeStreet
      homeCity: $homeCity
      creditScore: $creditScore
      homeState: $homeState
      homeZip: $homeZip
    ) {
      ${DEALER_PERSON_FIELDS_AND_ERRORS}
    }
  }
`;

const Edit = () => {
  const { id, dealerId } = useParams();

  const { loading, error, data } = useQuery(DEALER_PERSON, { variables: { id } });

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_DEALER_PERSON,
    onCompleteRedirectTo: `/dealers/${dealerId}/people`,
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    firstName,
    lastName,
    title,
    homeStreet,
    homeCity,
    homeState,
    creditScore,
    role,
  } = data.dealerPerson;

  const mobilePhone = data.dealerPerson.mobilePhone.formatted || '';
  const officePhone = data.dealerPerson.officePhone.formatted || '';
  const homePhone = data.dealerPerson.homePhone.formatted || '';
  const homeZip = data.dealerPerson.homeZip.value || '';

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update ${titleCase(firstName)} ${titleCase(lastName)}`}</h1>
        </div>
        <ServerSideErrors />
        <Formik
          initialValues={{
            id: id,
            role: role || 'Normal',
            firstName: firstName || '',
            lastName: lastName || '',
            title: title || '',
            email: data.dealerPerson.dealerUser?.email || '',
            mobilePhone: mobilePhone || '',
            homePhone: homePhone || '',
            officePhone: officePhone || '',
            creditScore: creditScore || '',
            homeStreet: homeStreet || '',
            homeCity: homeCity || '',
            homeState: homeState || '',
            homeZip: homeZip || '',
          }}
          validationSchema={personSchema}
          onSubmit={handleSubmit}
        >
          <Form dealerUserId={data.dealerPerson.dealerUser?.id} />
        </Formik>
      </div>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default Edit;
