import React, { useState, useEffect, useContext } from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import AuthLink from 'components/src/components/AuthLink';
import {
  ACCOUNT_GROUPS, HIDE_ACCOUNT_GROUP, UNHIDE_ACCOUNT_GROUP
} from './queries';
import { titleCase } from 'components/src/utils/stringHelpers';
import { Typography } from 'components/src/styles';
import { addRecord } from 'components/src/styles/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { preflightTableStyles } from 'components/src/styles';

const AccountGroups = () => {
  const [showHidden, setShowHidden] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    ACCOUNT_GROUPS,
    { variables: { scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_ACCOUNT_GROUP, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_ACCOUNT_GROUP, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const checkbox = ({ value }) => {
    return value ? <FontAwesomeIcon icon={faCheck} /> : ''
  };

  const columns = [
    {
      key: 'name',
      label: 'Name',
      content: ({ entry }) => {
        return (
          <AuthLink
            style={{paddingRight: 5}}
            to={`/financial/account-groups/${entry.id}`}
            resource="AccountGroup"
            action="read"
          >
            { entry.name }
          </AuthLink>
        );
      }
    },
    {
      key: 'increasedBy',
      label: 'Increased By',
      content: ({value}) => titleCase(value)
    },
    {
      key: 'bank',
      label: 'Bank',
      content: checkbox
    },
    {
      key: 'accountsPayable',
      label: 'A/P',
      content: checkbox
    },
    {
      key: 'income',
      label: 'Income',
      content: checkbox
    },
    {
      key: 'expense',
      label: 'Expense',
      content: checkbox
    },
    {
      key: 'loan',
      label: 'Loan',
      content: checkbox
    },
    {
      key: 'nonPosting',
      label: 'Non-Posting',
      content: checkbox
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => {
        return (
          <div style={{display: 'block'}}>
            <AuthLink
              to={`/financial/account-groups/${entry.id}`}
              resource="AccountGroup"
              action="read"
            >
              View
            </AuthLink>
            <AuthLink
              style={{paddingLeft: 5}}
              to={`/financial/account-groups/${entry.id}/edit`}
              resource="AccountGroup"
              action="update"
            >
              | Edit
            </AuthLink>
            {
              showHidden &&
                <AuthLink
                  to="#"
                  resource="AccountGroup"
                  action="hide"
                  onClick={() => unhide({ variables: { id: entry.id } })}
                >
                  | Unhide
                </AuthLink>
            }
            {
              !showHidden && entry.canBeHidden &&
                <AuthLink
                  to="#"
                  resource="AccountGroup"
                  action="hide"
                  onClick={() => hide({ variables: { id: entry.id } })}
                >
                  | Hide
                </AuthLink>
            }
          </div>
        )
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Account Groups</title>
      </Helmet>
      <div style={Typography.pageTitle}>Account Groups</div>
      <div style={{marginLeft: 10}}>
        {
          <AuthLink
            to="#"
            resource="AccountGroup"
            action="hide"
            onClick={() => setShowHidden(!showHidden)}
          >
            {showHidden ? 'Show Visible' : 'Show Hidden'}
          </AuthLink>
        }
      </div>
      <AuthLink
        to={`/financial/account-groups/new`}
        resource="AccountGroup"
        action="create"
        style={addRecord}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </AuthLink>

      <DataTables.Simple
        data={data.accountGroups}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable={true}
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default AccountGroups;
