import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const TITLE_STATUS_FIELDS = `
  id
  name
  info
  isPresent
  toDealer
  autoFeeTitleAdded
  autoFeeTitleBorrowed
  autoFeeTitleShipped
  autoFeeTitleMarkedPresent
  fund
  createdBy { fullName }
  hiddenAt
  canBeHidden
`;

const TITLE_STATUS_FIELDS_AND_ERRORS = `
  titleStatus {
    ${TITLE_STATUS_FIELDS}
  }
  ${ERRORS}
`;

export const TITLE_STATUSES = gql`
  query TitleStatuses($scopes: [String!]) {
    titleStatuses(
      scopes: $scopes
    ) {
      ${TITLE_STATUS_FIELDS}
    }
  }
`;

export const HIDE_TITLE_STATUS = gql`
  mutation HideTitleStatus($id: ID!) {
    hideTitleStatus(
      id: $id
    ) {
      ${TITLE_STATUS_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_TITLE_STATUS = gql`
  mutation UnhideTitleStatus($id: ID!) {
    unhideTitleStatus(
      id: $id
    )
    {
      ${TITLE_STATUS_FIELDS_AND_ERRORS}
    }
  }
`;
