import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import featureEnabled from 'components/src/utils/featureEnabled';
import Group from './Group';

export const Form = ({ titleStatuses, paymentTerms }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleFile = (file) => {
    setFieldValue('file', file)
  }

  return (
    <FormikForm>
      <Group title="ACH">
        <BasicForm.Checkbox
          name="achUseTokens"
          label="ACH - use tokens instead of account numbers"
        />
        <BasicForm.TextInput
          name="achIncomingCompanyName"
          type="text"
          label="ACH Incoming Company Name"
        />
        <BasicForm.TextInput
          name="achIncomingTokenName"
          type="text"
          label="ACH Incoming Company Name - TOKEN files"
        />
        <BasicForm.TextInput
          name="achIncomingCompanyId"
          type="text"
          label="ACH Incoming Company Id"
        />
        <BasicForm.TextInput
          name="achOutgoingCompanyName"
          type="text"
          label="ACH Outgoing Company Name"
        />
        <BasicForm.TextInput
          name="achOutgoingTokenName"
          type="text"
          label="ACH Outgoing Company Name - TOKEN files"
        />
        <BasicForm.TextInput
          name="achOutgoingCompanyId"
          type="text"
          label="ACH Outgoing Company Id"
        />
        <BasicForm.TextInput
          name="achOriginRouting"
          type="text"
          label="ACH Origin Routing"
        />
      </Group>

      <Group title="Auction Access">
        <BasicForm.ProgrammaticSelect
          label="Auction Access - Title Status in ATLAS When Title Marked Present"
          name="auctionAccessTitlePresentId"
          options={titleStatuses}
          optionName='Present Title Status'
        />
        <BasicForm.ProgrammaticSelect
          label="Auction Access - Title Status in ATLAS When Title Marked Absent"
          name="auctionAccessTitleAbsentId"
          options={titleStatuses}
          optionName='Absent Title Status'
        />
      </Group>

      <Group title="Accounting">
        <BasicForm.PercentInput
          name="costOfFunds"
          type="float"
          label="Cost of Funds Percentage for Net Yield"
        />

        {featureEnabled('doubleEntry', 'booksClosed') && (
          <>
            <BasicForm.TextInput
              name="booksClosedThrough"
              label="Books Closed Through"
              type="date"
            />
            <BasicForm.TextInput
              name="booksClosedPassword"
              label="Books Closed Password"
              type="password"
            />
          </>
        )}
        {featureEnabled('doubleEntry', 'paymentTerms') && (
          <BasicForm.ProgrammaticSelect
            label="Default Payment Terms"
            name="paymentTermId"
            options={paymentTerms}
            optionName='Default Payment Term'
          />
        )}
        {featureEnabled('doubleEntry', 'operationsTxns') && (
          <BasicForm.TextInput
            name="nextJeNumber"
            label="Next JE Number"
            type="text"
          />
        )}
      </Group>

      <Group title="Autopay">
        <BasicForm.Checkbox
          name="autopayEnabled"
          label="Autopay - Force Autopay Enabled for past due or borrowed titles?"
        />
        <BasicForm.TextInput
          name="autopayDays"
          label="Autopay - Number of Days Late for Forcing Curtailment (blank to disable)"
          type="number"
        />
        <BasicForm.TextInput
          name="autopayBorrowedTitleNotificationDays"
          label="Autopay - Borrowed Title - Send Notification (Number of Days Late After Title Borrowed)"
          type="number"
        />
        <BasicForm.TextInput
          name="autopayBorrowedTitlePullDays"
          label="Autopay - Borrowed Title - Pull Payoff (days to pull after borrowed title)"
          type="number"
        />
      </Group>

      <Group title="Borrowed Title Limits">
        <BasicForm.TextInput
          name="borrowedTitleMinimumCars"
          label="Minimum number of cars a Dealer has floored to be allowed to borrow titles"
          type="number"
        />
        <BasicForm.TextInput
          name="borrowedTitleCountLimit"
          label="The total maximum number of titles a Dealer can have borrowed at one time"
          type="number"
        />
        <BasicForm.TextInput
          name="borrowedTitlePrincipalLimit"
          label="The total maximum A/R dollar amount of the cars for the titles a Dealer has borrowed at one time"
          type="number"
        />
      </Group>

      <Group title="KPIs">
        <BasicForm.TextInput
          name="delinquentThreshold"
          label="Delinquency Percent Threshold - used to determines times exceeded on the KPI results"
          type="number"
        />
      </Group>

      <div style={Spacing.buttonRow}>
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}
