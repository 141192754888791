import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const DEALER_FIELDS = `
  id
  name
  principalPerson {
    id
  }
`;

const DEALER_USER_FIELDS = `
  id
  email
  userProvidedPhoneNumber { value, formatted }
  dealerPeople {
    id
    dealer { name }
  }
`;

const DEALER_PERSON_FIELDS = `
  id

  dealer { id }

  dealerUser {
    ${DEALER_USER_FIELDS}
  }

  firstName
  lastName
  fullName
  email
  title

  mobilePhone { value, formatted }
  officePhone { value, formatted }
  homePhone { value, formatted }

  homeStreet
  homeCity
  homeState
  homeZip { value, formatted }

  userStatus
  role

  bornOn
  creditScore

  canBeHidden
  hiddenAt
  createdAt
  updatedAt
`;

export const DEALER_PERSON_FIELDS_AND_ERRORS = `
  dealerPerson {
    ${DEALER_PERSON_FIELDS}
  }
  ${ERRORS}
`;

export const DEALER_PERSON = gql`
  query DealerPerson($id: ID!) {
    dealerPerson(id: $id) {
      ${DEALER_PERSON_FIELDS}
    }
  }
`;

export const DEALER_PEOPLE = gql`
  query DealerPeople($id: ID!, $scopes: [String!]) {
    dealer(id: $id) {
      ${DEALER_FIELDS}

      dealerPeople(
        scopes: $scopes
      ) {
        ${DEALER_PERSON_FIELDS}
      }
    }
  }
`;

export const HIDE_DEALER_PERSON = gql`
  mutation HideDealerPerson($id: ID!) {
    hideDealerPerson(
      id: $id
    ) {
      ${DEALER_PERSON_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_DEALER_PERSON = gql`
  mutation UnhideDealerPerson($id: ID!) {
    unhideDealerPerson(
      id: $id
    )
    {
      ${DEALER_PERSON_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_DEALER_PRINCIPAL = gql`
  mutation UpdateDealer(
    $id: ID!
    $principalPersonId: ID!
  ) {
    updateDealer(
      id: $id
      principalPersonId: $principalPersonId
    ) {
      dealer {
        ${DEALER_FIELDS}
      }
    }
  }
`;

export const INVITE_DEALER_USER = gql`
  mutation InviteDealerUser($id: ID!) {
    inviteDealerUser(
      id: $id
    ) {
      dealerUser {
        ${DEALER_USER_FIELDS}
      }
    }
  }
`;

export const DEALER_USER_BY_EMAIL = gql`
  query NoUserLogDealerUserByEmail($email: String!) {
    dealerUserByEmail(email: $email) {
      ${DEALER_USER_FIELDS}
    }
  }
`;
