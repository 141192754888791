import React from 'react';
import Cars from '../Cars';

const carsTable = {
  marginTop: '2%'
}

const index = () => {
  return (
    <>
      <div style={carsTable}>
        <Cars screen="titleStatus" />
      </div>
    </>
  );
};

export default index;
