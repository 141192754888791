import React, { useState } from 'react';
import { Form } from './Form';
import { gql, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import DataState from '../DataState';

const CREATE_AUCTION_TOKEN = gql`
  mutation CreateAuctionToken(
    $ownerType: String!
    $ownerId: Int!
  ) {
    createAuctionToken(
      ownerType: $ownerType
      ownerId: $ownerId
    ) {
      auctionToken { id }
      errors {
        message
        path
      }
    }
  }
`

const New = (props) => {
  let { gqlQuery, ownerType, ownerId, handleClose } = props;

  const [redirect, setRedirect] = useState(false);

  const handleRedirect = () => {
    if (handleClose) {
      handleClose();
      window.location.reload();
    } else {
      setRedirect(true);
    }
  }

  const [createAuctionToken] = useMutation(CREATE_AUCTION_TOKEN,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: gqlQuery, variables: { id: ownerId } }],
      onCompleted(values) {
        handleRedirect();
      }
    }
  )

  const handleSubmit = (values) => {
    createAuctionToken({ variables: values })
  }

  const NewAuctionToken = () => {
    return (
      <div>
        <Formik
          initialValues={{
            ownerType: ownerType,
            ownerId: parseInt(ownerId)
          }}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <div>
      <NewAuctionToken />
    </div>
  )
}

export default New;
