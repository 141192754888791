import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Form, editInsuranceStateSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import { COLLATERAL_COVERAGES, INSURANCE_STATES } from '../../../queries/indexQueries';
import { COLLATERAL_COVERAGE, INSURANCE_STATE } from '../../../queries/viewQueries';
import DataState from '../../DataState';
import { useAlert } from '../../../hooks/useAlert';
import { setAlertData } from '../../../utils/setAlertData';
import BasicAlert from '../../../elements/BasicAlert';

const UPDATE_INSURANCE_STATE = gql`
  mutation UpdateInsuranceState($id: ID!, $name: String!, $state: String!, $defaultInsuranceProgramId: Int!)
  {
    updateInsuranceState(id: $id, name: $name, state: $state, defaultInsuranceProgramId: $defaultInsuranceProgramId)
    {
      insuranceState {
        id
      }
      errors { message, path }
    }
  }
 `

 const Edit = (props) => {
  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(INSURANCE_STATE, { variables: { id } });

  const [showAlert, displayAlert] = useAlert();
  const history = useHistory();


  const [updateInsuranceState] = useMutation(UPDATE_INSURANCE_STATE,
    {
      onCompleted() {
        history.push(`/system/insurance-states/`)
        displayAlert()
      }
    }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

   const { insuranceState } = data;

   const handleSubmit = (values) => {
     updateInsuranceState({ variables: values })
   };

   const UpdateForm = () => {

    if (insuranceState.defaultInsuranceProgram === null ) {
      insuranceState.programId = ""
    } else {
      insuranceState.programId = insuranceState.defaultInsuranceProgram.id
    }

     return (
       <>
         <div style={Spacing.formWrapper}>
           <div style={Spacing.formHeader}>
             <h1 style={Typography.formHeader}>{`Edit Default Collateral Coverage for: ${insuranceState.name}`}</h1>
           </div>

           <Formik
             initialValues={{
               id: id,
               name: insuranceState.name || '',
               state: insuranceState.state || '',
               deafultInsuranceProgramId: insuranceState.programId  || '',
             }}
             validationSchema={editInsuranceStateSchema}
             onSubmit={handleSubmit}
           >
             <Form type='edit'/>
           </Formik>
         </div>
       </>
     )
    }

  return (
    <>
    <Helmet>
      <title>Edit Insurance State</title>
    </Helmet>
    {showAlert &&
      <BasicAlert componentType="insruance state change" type={alertType} />
    }
    <div style={Spacing.formContainer}>
      <UpdateForm />
    </div>
    </>
  );
};

export default Edit;
