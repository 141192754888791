import React, { useEffect } from 'react';
import { useFormikContext } from "formik";
import * as Buttons from '../../../../elements/buttons';
import * as Colors from '../../../../styles/colors';
import { renderChildrenWithProp } from '../../../../utils/hocHelpers';
import { Spacing } from '../../../../styles';
import { CustomAlert } from '../../../../elements/BasicAlert';
import * as BasicForm from '../../../../elements/forms';

const TransactionLineForm = (props) => {
  const {
    children,
    disableSubmit,
    errorAlert,
    setDisableSubmit,
    setErrorAlert,
    columns,
    journalEntry
  } = props

  const { values, setValues } = useFormikContext();

  const handleAutoAddLine = () => {
    const txnLinesAttributes = values.txnLinesAttributes;
    const newLine = { ...txnLinesAttributes.slice(-1)[0] }

    if (journalEntry) {
      newLine.accountId = '';
    }

    newLine.id = undefined;
    if (newLine.debit) { newLine.debit = ''; }
    if (newLine.credit) { newLine.credit = ''; }

    txnLinesAttributes.push(newLine);

    setValues(values);
  }

  const handleAddLine = () => {
    const txnLinesAttributes = values.txnLinesAttributes;
    const newLine = { ...txnLinesAttributes.slice(-1)[0] }

    newLine.id = undefined;
    if (newLine.debit) { newLine.debit = ''; }
    if (newLine.credit) { newLine.credit = ''; }

    txnLinesAttributes.push(newLine);

    setValues(values);
  }

  const container = {
    marginTop: '20px',
    marginBottom: '10px',
    display: 'grid',
  }

  const centerButton = {
    margin: 'auto',
    width: '50%',
    border: '3px',
    padding: '10px',
    backgroundColor: Colors.xlOrange
  }

  useEffect(() => {
    let credit = 0.0;
    let debit = 0.0;
    let maxDecimals = 2;

    values.txnLinesAttributes.map((line) => {
      if (line.debit) {
        debit += parseFloat(line.debit);
        maxDecimals = Math.max(
          maxDecimals, (line.debit.split('.')[1]?.length || 0)
        );
      }
      if (line.credit) {
        credit += parseFloat(line.credit);
        maxDecimals = Math.max(
          maxDecimals, (line.credit.split('.')[1]?.length || 0)
        );
      }
    });

    const lastItem = values.txnLinesAttributes.length - 1;
    const lastLine = values.txnLinesAttributes[lastItem];

    if (
      journalEntry && lastLine.accountId &&
      !lastLine.debit && !lastLine.credit
    ) {
      if (credit - debit !== 0) {
        if (credit < debit) {
          lastLine.credit = Math.abs(credit - debit).toFixed(maxDecimals);
        } else if (debit < credit) {
          lastLine.debit = Math.abs(credit - debit).toFixed(maxDecimals);
        }
      }
    }

    if (lastLine.debit || lastLine.credit) {
      handleAutoAddLine();
    }

    if (setErrorAlert) {
      if (debit.toFixed(9) !== credit.toFixed(9)) {
        setDisableSubmit(true)
        setErrorAlert('Transaction not in balance.')
      } else {
        setDisableSubmit(false)
        setErrorAlert(null)
      }
    } else {
      setDisableSubmit(false)
      if (setErrorAlert) {
        setErrorAlert(null)
      }
    }
  })

  return (
    <>
      <div style={container}>
        {
          values.txnLinesAttributes.map((line, index) => {
            const childrenWithProps = renderChildrenWithProp(
              children, 'indexValue', index
            );

            return (
              <div key={index} style={columns}>
                {childrenWithProps}
              </div>
            )
          })
        }
      </div>
      { errorAlert && <CustomAlert message={errorAlert} /> }
      <Buttons.Standard
        text="Add a Line"
        onClick={handleAddLine}
        style={centerButton}
        type='button'
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={disableSubmit || false}
        />
      </div>
    </>
  );
};

export default TransactionLineForm;
