import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { useAlert } from '../../hooks/useAlert';
import { noteSchema, Form } from './Form';
import { StringHelpers } from '../../utils';
import Loading from '../../elements/Loading';
import { CREATE_NOTE } from './queries'

const New = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);

  const dismissAlert = () => displayAlert(false)

  const [showAlert, displayAlert] = useAlert();
  const history = useHistory();
  const ownerPath = useLocation().pathname.replace(/\/notes\/new\/?$/, '');

  const [ ownerTable, ownerId ] = ownerPath.split('/').slice(-2);
  let ownerType = StringHelpers.dashToCamelCase(ownerTable).slice(0, -1);
  if ('Car' === ownerType) { ownerType = 'FlooredCar'; }

  const redirectLink = ownerPath + '/notes';

  let [createNote] = useMutation(CREATE_NOTE,
    {
      onCompleted(data) {
        history.push({
          pathname: redirectLink,
          state: { alert: true, type: 'created' }
        });
      }
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createNote({ variables: values })
  };

  if (formSubmitting) {
    return (
      <div style={Spacing.formContainer}>
        <Loading />
      </div>
    )
  }

  return (
    <div style={Spacing.formContainer}>
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Create A New Note</h1>
        </div>
        <Formik
          initialValues={{
            ownerId: parseInt(ownerId),
            ownerType: ownerType,
            subject: '',
            note: '',
            important: false,
            collection: false,
            corporate: false,
          }}
          validationSchema={noteSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    </div>
  )
};

export default New;
