import React, { useContext } from 'react';
import {
  Nav,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from 'components/src/contexts/AuthContext';

const textSizing = {
  fontWeight: 500,
  fontSize: 13
}

const navIcon = {
  ...textSizing
}

const navText = {
  ...textSizing,
  marginLeft: 6,
};

const notificationItem = {
  color: 'white',
  fontSize: 17,
  marginLeft: 20
};

function SubNavLink(props) {
  const { link, icon, text, resource, action, handleClick } = props;
  const auth = useContext(AuthContext);

  if (resource && action) {
    if (!auth.hasPolicy(resource, action)) {
      return null;
    }
  }

  return (
    <Nav.Item>
      <LinkContainer to={link} onClick={handleClick}>
        <Nav.Link style={notificationItem}>
          <FontAwesomeIcon icon={icon} style={navIcon} />
          <span style={navText}>{text}</span>
        </Nav.Link>
      </LinkContainer>
    </Nav.Item>
  );
}

export default SubNavLink;
