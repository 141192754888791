import React, { useContext, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import DataTable from '../../../elements/DataTable';
import AuthContext from '../../../contexts/AuthContext';
import { useHideableResource } from '../../../hooks/useHideableResource';
import DataState from '../../DataState';
import { setAlertData } from '../../../utils/setAlertData';
import { ACCOUNTS, HIDDEN_ACCOUNTS } from '../../../queries/indexQueries'
import { useAlert } from '../../../hooks/useAlert';
import BasicAlert from '../../../elements/BasicAlert';
import featureEnabled from 'components/src/utils/featureEnabled';

const HIDE_ACCOUNT = gql`
  mutation HideBranch($id: ID!) {
    hideAccount(
      id: $id
    ) {
      account {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const UNHIDE_ACCOUNT = gql`
  mutation UnhideAccount($id: ID!) {
    unhideAccount(
      id: $id
    )
    {
      account {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const Accounts: () => React$Node = (props) => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_ACCOUNTS,
    VISIBLE_QUERY: ACCOUNTS,
    HIDE_MUTATION: HIDE_ACCOUNT,
    UNHIDE_MUTATION: UNHIDE_ACCOUNT,
    variables: {},
  };

  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)
  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])
  const auth = useContext(AuthContext);

  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const columns = [
    {
      title: "GL"
    },
    {
      title: "Name"
    },
    {
      title: "Special"
    },
    {
      title: 'Actions'
    },
  ]

  if (featureEnabled('doubleEntry', 'accountGroups')) {
    columns.splice(3, 0, { title: 'Account Group' });
  }

  const dataSet = data.accounts.map(function (account) {
    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${account.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${account.id}>Hide</a>`

    if (auth.hasPolicy('Role', 'update')) {
      account.actions = `
        <a href="/financial/accounts/${account.id}"/>View</a> |
        <a href="/financial/accounts/${account.id}/edit"/>Edit</a> |
        ${hiddenLink}
      `
    }

    const accountGroup = `<a href="/financial/account-groups/${account.accountGroup?.id}"/>${account.accountGroup?.name}</a>`
    const accountName = `<a href="/financial/accounts/${account.id}"/>${account.name}</a>`
    const special = account.special?.name || '';

    const content = [
      account.gl,
      accountName,
      special,
      account.actions
    ]

    if (featureEnabled('doubleEntry', 'accountGroups')) {
      content.splice(3, 0, accountGroup);
    }

    return content;
  })

  return (
    <>
      <Helmet>
        <title>Accounts</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="account" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Accounts'}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
    </>
  );
};

export default Accounts;
