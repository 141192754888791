import React, { useState, useEffect, useContext } from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import AuthLink from 'components/src/components/AuthLink';
import {
  DEPARTMENTS, HIDE_DEPARTMENT, UNHIDE_DEPARTMENT
} from './queries';
import { titleCase } from 'components/src/utils/stringHelpers';
import { Typography } from 'components/src/styles';
import { addRecord } from 'components/src/styles/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { preflightTableStyles } from 'components/src/styles';

const Departments = () => {
  const [showHidden, setShowHidden] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    DEPARTMENTS,
    { variables: { scopes: [showHidden ? 'hidden' : 'visible'] } }
  );

  const [hide] = useMutation(HIDE_DEPARTMENT, { onCompleted: refetch });
  const [unhide] = useMutation(UNHIDE_DEPARTMENT, { onCompleted: refetch });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const checkbox = ({ value }) => {
    return value ? <FontAwesomeIcon icon={faCheck} /> : ''
  };

  const columns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => {
        return (
          <div style={{display: 'block'}}>
            <AuthLink
              style={{paddingLeft: 5}}
              to={`/financial/departments/${entry.id}/edit`}
              resource="Department"
              action="update"
            >
              Edit
            </AuthLink>
            {
              showHidden &&
                <AuthLink
                  to="#"
                  resource="Department"
                  action="hide"
                  onClick={() => unhide({ variables: { id: entry.id } })}
                >
                  {' | Unhide'}
                </AuthLink>
            }
            {
              !showHidden && entry.canBeHidden &&
                <AuthLink
                  to="#"
                  resource="Department"
                  action="hide"
                  onClick={() => hide({ variables: { id: entry.id } })}
                >
                  {' | Hide'}
                </AuthLink>
            }
          </div>
        )
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Departments</title>
      </Helmet>
      <div style={Typography.pageTitle}>Departments</div>
      <div style={{marginLeft: 10}}>
        {
          <AuthLink
            to="#"
            resource="Department"
            action="hide"
            onClick={() => setShowHidden(!showHidden)}
          >
            {showHidden ? 'Show Visible' : 'Show Hidden'}
          </AuthLink>
        }
      </div>
      <AuthLink
        to={`/financial/departments/new`}
        resource="Department"
        action="create"
        style={addRecord}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </AuthLink>

      <DataTables.Simple
        data={data.departments}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable={true}
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Departments;
