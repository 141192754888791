import React  from 'react';
import useEnum from 'components/src/hooks/useEnum';
import { Select } from './forms';

const SelectFromEnum = ({ name, label, modelName }) => {
  const [values] = useEnum(modelName, name);

  if (values) {
    return (
      <Select name={name} label={label}>
        <option value="">Select</option>
        { values.map(value => <option key={value}>{value}</option>) }
      </Select>
    );
  } else {
    return <span></span>
  }
};

export default SelectFromEnum;
