import React from 'react';
import DataTable from '@preflighttech/preflight-tables';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DEALER } from './Queries';
import DataState from '../../DataState';
import { columnDefinitions } from './columnDefinitions';
import { Typography, preflightTableStyles } from 'components/src/styles';

const TempPrograms = () => {
  const id = parseInt(useParams().id);

  const { loading, error, data } = useQuery(DEALER, { variables: { id } });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = columnDefinitions();

  return (
    <>
      <div style={Typography.pageTitle}>Temp Programs</div>
      <DataTable.Simple
        data={data.dealer.tempPrograms}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        disableSearch={true}
        htmlTable
        styles={preflightTableStyles}
      />
    </>
  )
}

export default TempPrograms;
