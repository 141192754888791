import React from 'react';
import * as Colors from '../styles/colors';
import { useHistory } from 'react-router-dom';

const paymentButton = {
  marginTop: '6%',
  fontWeight: 550,
  width: '28%'
}

const paymentStandard = {
  ...paymentButton,
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen,
}

const cancelPaymentButton = {
  ...paymentButton,
  backgroundColor: 'white',
  borderColor: Colors.xlGreen,
  color: Colors.xlGreen,
}

const normalButton = {
  marginTop: '6%',
  fontWeight: 550,
  width: '47%'
}

const standardButton = {
  ...normalButton,
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen,
}

export const cancelButton = {
  ...normalButton,
  backgroundColor: 'white',
  borderColor: Colors.xlGreen,
  color: Colors.xlGreen,
}

const signInButton = {
  marginTop: '8%',
  marginBottom: '3%',
  fontWeight: 550,
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen
}

const linkStyle = {
  background: 'none',
  border: 'none',
  padding: 0,
  color: Colors.xlGreen,
  cursor: 'pointer'
}

const inlineStyle = {
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen,
  padding: 2,
  marginLeft: 6,
  marginRight: 6
};

const topRowButtons = {
  ...standardButton,
  width: '10%',
  fontSize: '0.8em',
  marginRight: '0.8%',
  height: '2rem',
  padding: '0%'
}

export const PaymentStandard = (props) => {
  const buttonStyle = props.topRowButtons ? topRowButtons : standardButton;

  return (
    <button type={props.type} className="btn btn-lg btn-primary btn-block" style={paymentStandard} {...props}>
      {props.text}
    </button>
  )
};

export const PaymentCancel = (props) => {
  const history = useHistory();

  return (
    <a href="#" onClick={history.goBack} type={props.type} className="btn btn-lg btn-primary btn-block" style={cancelPaymentButton} >
      {props.text}
    </a >
  )
};

export const Standard = ({ topRowButtons: topRowStyle, ...props }) => {
  let buttonStyle = topRowStyle ? topRowButtons : standardButton;
  buttonStyle = {...buttonStyle, ...props.buttonStyle};

  return (
    <button type={props.type} className="btn btn-lg btn-primary btn-block" style={buttonStyle} {...props}>
      {props.text}
    </button>
  )
};

export const Link = (props) => {
  return (
    <button className='btn-Link' type={props.type} style={{ ...linkStyle, ...props.style }} onClick={props.onClick}>
      {props.text}
    </button >
  )
}

export const Cancel = (props) => {
  const history = useHistory();

  return (
    <a href="#" onClick={history.goBack} type={props.type} className="btn btn-lg btn-primary btn-block" style={cancelButton} >
      {props.text}
    </a >
  )
};

export const ModalCancel = (props) => {
  const history = useHistory();

  return (
    <a href="#" onClick={props.handleClose} type={props.type} className="btn btn-lg btn-primary btn-block" style={cancelButton} >
      {props.text}
    </a >
  )
};

export const SignIn = (props) => {
  return (
    <button type={props.type} className="btn btn-lg btn-primary btn-block" style={signInButton} >
      {props.text}
    </button>
  )
};

export const Inline = (props) => {
  const buttonStyle = {...inlineStyle, ...props.buttonStyle};

  return (
    <button type={props.type} className="btn btn-primary" style={buttonStyle} {...props}>
      {props.text}
    </button>
  )
};
