import React from "react";
import Button from "react-bootstrap/Button";

const buttonStyle = {
  marginRight: "20px",
};

export const ExportToExcel = props => {
  const {
    handleExportToExcel,
    disabled,
    disabledMessage,
    pdf,
    handleExportToPdf
  } = props;

  if (pdf) {
    return (
      <Button  onClick={handleExportToPdf} style={buttonStyle}>
        Export To Pdf
      </Button>
    );
  } else if (disabled) {
    const displayMessage = () => {
      if (disabledMessage) { alert(disabledMessage) };
    }

    return (
      <Button
        onClick={displayMessage}
        style={{backgroundColor: 'gray', ...buttonStyle}}
      >
        Export to Excel
      </Button>
    );
  } else {
    return (
      <Button onClick={handleExportToExcel} style={buttonStyle}>
        Export to Excel
      </Button>
    );
  }
};
