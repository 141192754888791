import React, { useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../../elements/forms";

const handleChange = (e, index, attributeIdx, values, setValues) => {
  const target = parseInt(e.currentTarget.value);
  values.termsAttributes[index].termLinesAttributes[0].termLineFeesAttributes[
    attributeIdx
  ].day = target === "" || !target ? null : target;

  setValues(values);
};

const FeeAccrued = ({ values, termLinesAttributes, index, columnStyle }) => {
  const { setValues, setFieldValue } = useFormikContext();
  const feeTypeIsSingle = values.termsAttributes[index].feeType === "Single";

  return termLinesAttributes[0].termLineFeesAttributes.map(
    (attribute, attributeIdx) => {

      if (values.termsAttributes[index].termLinesAttributes[0]
          .termLineFeesAttributes[attributeIdx]._destroy) return null;

      if (
        feeTypeIsSingle &&
        !values.termsAttributes[index].termLinesAttributes[0]
          .termLineFeesAttributes[attributeIdx].day
      )
        setFieldValue(
          `termsAttributes[${index}].termLinesAttributes[0].termLineFeesAttributes[${attributeIdx}].day`,
          1
        );
      return (
        <Col style={columnStyle.smallDiv} key={attributeIdx}>
          <BasicForm.TextInput
            type="number"
            disabled={feeTypeIsSingle}
            name={`termsAttributes[${index}].termLinesAttributes[0].termLineFeesAttributes[${attributeIdx}].day`}
            value={
              feeTypeIsSingle
                ? 1
                : values.termsAttributes[index].termLinesAttributes[0]
                    .termLineFeesAttributes[attributeIdx].day || ""
            }
            onChange={(e) =>
              handleChange(e, index, attributeIdx, values, setValues)
            }
            style={columnStyle.dropdownStyle}
          />
        </Col>
      );
    }
  );
};

export default FeeAccrued;
