import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import DataState from '../../DataState';
import { Spacing, Typography } from '../../../styles';
import { titleCase } from '../../../utils/stringHelpers';
import { Formik } from 'formik';
import { accountGroupSchema, Form } from './Form';
import { ACCOUNT_GROUP, UPDATE_ACCOUNT_GROUP } from './queries';

const Edit = () => {
  const id = parseInt(useParams().id);
  const history = useHistory();

  const { loading, error, data } = useQuery(ACCOUNT_GROUP, { variables: { id } });

  const [updateAccountGroup] = useMutation(UPDATE_ACCOUNT_GROUP,
    {
      onCompleted() {
        history.push({
          pathname: `/financial/account-groups`,
          state: { alert: true },
        })
      }
    }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const {
    name,
    incomeBalance,
    cashFlow,
    sort
  } = data.accountGroup;

  const handleSubmit = (values) => {
    updateAccountGroup({ variables: values })
  };

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update Account Group: ${titleCase(name)}`}</h1>
        </div>
        <Formik
          initialValues={data.accountGroup}
          validationSchema={accountGroupSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <>
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    </>
  )
}

export default Edit;
