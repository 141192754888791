import React, { Fragment, useEffect } from "react";
import { useFormikContext } from "formik";
import { Container, Row } from "react-bootstrap";
import TableHeaders from "./TableHeaders";
import { BaseAmounts } from "../SingleFee/PrincipalAmounts";
import { columnStyle, smallTable } from "../SingleFee";
import { PrincipalButtons } from "../../Buttons";

const InterestOnlyForm = ({ index }) => {
  const { values, setValues } = useFormikContext();
  const termLinesAttributes = values.termsAttributes[index].termLinesAttributes;

  useEffect(() => {
    delete values.termsAttributes[index].termLinesAttributes[0].termLineFeesAttributes;
    setValues(values)
  }, [])

  return (
    <Container style={smallTable}>
      <TableHeaders columnStyle={columnStyle} />
      {termLinesAttributes.map((attribute, attributeIdx) => {
        if (attribute._destroy) return null;
        return (
          <Row>
            <BaseAmounts
              index={index}
              attributeIdx={attributeIdx}
              columnStyle={columnStyle}
              values={values}
              setValues={setValues}
            />
          </Row>
        );
      })}
      <Row>
        <PrincipalButtons values={values} index={index} setValues={setValues} />
      </Row>
      *Leave the value blank for all Principal Values, or for the last principal
      value
    </Container>
  );
};

export default InterestOnlyForm;
