import React from 'react';
import { useQuery } from '@apollo/client';
import * as yup from 'yup';
import { Form, useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';

import * as BasicForm from 'components/src/elements/forms';
import { Spacing } from 'components/src/styles';
import * as Buttons from 'components/src/elements/buttons';
import DataState from 'components/src/components/DataState';
import { customStyles } from 'components/src/components/Dealers/Form';

import { CUSTOM_NOTIFICATION_SELECTS } from './queries';

export const customNotificationSchema = yup.object({
  body: yup
    .string()
    .required('Required')
});

const CustomNotificationForm = () => {
  const { isValid, setFieldValue, values } = useFormikContext();

  const { data, loading, error } = useQuery(CUSTOM_NOTIFICATION_SELECTS);

  if (!data && loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const marketOptions = data.markets.map(market => (
    { value: market.id, label: market.name }
  ));

  const handleMarketChange = selected => {
    if (!selected) {
      setFieldValue('marketIds', []);
    } else {
      setFieldValue('marketIds', selected.map(market => market.value));
    }
  };

  return (
    <Form>
      <Row>
        <Col sm>
          <BasicForm.StyledLabel>
            Select who receives the notification:
          </BasicForm.StyledLabel>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: -20,
          }}>
            <div>
              <BasicForm.Checkbox
                name="dealers"
                label="Dealers"
              />
            </div>
            <div style={{marginLeft: 40}}>
              <BasicForm.Checkbox
                name="employees"
                label="Employees"
              />
            </div>
          </div>
        </Col>
        <Col sm>
          <BasicForm.StyledLabel>
            Markets (Leave blank for All)
          </BasicForm.StyledLabel>
          <Select
            options={marketOptions}
            isMulti={true}
            onChange={handleMarketChange}
            styles={customStyles}
            defaultValue={values.marketIds}
          />
        </Col>
      </Row>
      <BasicForm.TextArea
        name="body"
        label="Notification"
        rows="4"
      />
      <BasicForm.TextInput
        name="link"
        type="text"
        label="Link (optional)"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" disabled={!isValid} />
      </div>
    </Form>
  );
};

export default CustomNotificationForm;
