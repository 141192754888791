import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as Buttons from '../../../elements/buttons';
import * as BasicForm from '../../../elements/forms';
import { Spacing } from '../../../styles';
import { AUCTIONS } from './Queries';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';

export const soldSchema = yup.object({
  soldOn: yup
    .date()
    .required('Required')
    .max(new Date(), "Date cannot be in the future")
})

export const Form = () => {
  const { values, setFieldValue } = useFormikContext();

  const { loading, error, data } = useQuery(AUCTIONS);

  useEffect(() => {
    if (values.saleType !== 'Finance') {
      setFieldValue('financeCompanyName', undefined)
    }
    if (values.saleType !== 'Auction') {
      setFieldValue('soldToAuctionId', undefined)
    }
    if (values.saleType !== 'Other') {
      setFieldValue('other', undefined)
    }
  }, [values.saleType])

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <FormikForm>
      <BasicForm.TextInput
        name='soldOn'
        type='date'
        label='Date Sold'
      />
      <BasicForm.TextInput
        name='soldTo'
        type='text'
        label='Sold To'
      />
      <BasicForm.Select name='saleType' label='Sale Type'>
        <option value="">Select a Sale Type</option>
        <option>Finance</option>
        <option>BHPH</option>
        <option>Cash</option>
        <option>Auction</option>
        <option>Other</option>
      </BasicForm.Select>
      {values.saleType === 'Finance' && (
        <BasicForm.TextInput
          name='financeCompanyName'
          type='text'
          label='Finance Company Name'
        />
      )}
      {values.saleType === 'Auction' && (
        <BasicForm.ProgrammaticSelect
          name='soldToAuctionId'
          label='Auction'
          options={data.auctions}
          optionName='Auction'
        />
      )}
      {values.saleType === 'Other' && (
        <BasicForm.TextInput
          name='other'
          type='text'
          label='Other'
        />
      )}
      <div style={Spacing.buttonRow}>
        <Buttons.Standard type='submit' text='Submit' />
      </div>
    </FormikForm>
  )
}
