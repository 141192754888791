import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import BasicAlert from '../../../elements/BasicAlert';
import { useAlert } from '../../../hooks/useAlert';
import { setAlertData } from '../../../utils/setAlertData';
import { DEALER_BANK_ACCOUNTS } from '../Queries';
import BankAccountsTable from '../../BankAccounts';
import DealerInfo from '../DealerInfo';

const BankAccounts = (props) => {
  const id = useParams().id;

  const [showHidden, setShowHidden] = useState(false);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  return (
    <>
      <Helmet>
        <title>Dealer Bank Accounts</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="bank account" type={alertType} />
      }
      <DealerInfo />
      <BankAccountsTable
        query={DEALER_BANK_ACCOUNTS}
        queryName="dealer"
        id={id}
        prefix={`/dealers/${id}/bank-accounts`}
      />
    </>
  );
};

export default BankAccounts;
