import React from 'react';
import NewBankAccount from '../../BankAccounts/New';
import { Helmet } from 'react-helmet';
import { AUCTION_BANK_ACCOUNTS } from '../queries';

const New = (props) => {
  return (
    <>
    <Helmet>
      <title>New Bank Account</title>
    </Helmet>
    <NewBankAccount
      ownerType={'Auction'}
      gqlQuery={AUCTION_BANK_ACCOUNTS}
    />
    </>
    );
  };


export default New;
