import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'components/src/styles';
import { Container, Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import { xlGreen, xlOrange } from 'components/src/styles/colors';
import AuthContext from 'components/src/contexts/AuthContext';

import General from './Landing/General';
import Dealer from './Landing/Dealer';
import Collections from './Landing/Collections';
import Audits from './Landing/Audits';
import Titles from './Landing/Titles';
import Admin from './Landing/Admin';
import Financial from './Landing/Financial';

export const ColTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  color: ${xlOrange};
`;

export const ReportLink = styled(Link)`
  display: block;
  margin-top: 6px;
  font-size: 1.1rem;
  color: ${xlGreen} !important;
`;

const Reports = () => {
  const auth = useContext(AuthContext);

  return (
    <Container>
      <Row>
        <Col style={{border: 'none'}}>
          <div style={Typography.pageTitle}>Reports Landing Page</div>
        </Col>
      </Row>

      <Row>
        <Col xs={2}>
          <General />
        </Col>

        <Col xs={2}>
          <Dealer />
        </Col>

        <Col xs={2}>
          <Collections />
        </Col>

        <Col xs={2}>
          <Audits />
        </Col>

        <Col xs={2}>
          {auth.hasExactPolicy('Report', 'view_titles_shipments') && (
            <Titles />
          )}
        </Col>
      </Row>

      <Row style={{marginTop: '2em'}}>
        <Col xs={2}>
          <Admin />
        </Col>

        <Col xs={6}>
        </Col>

        <Col xs={2}>
          {auth.hasExactPolicy('Report', 'view_financial') && (
            <Financial />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Reports;
