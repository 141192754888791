import React, { useState, useContext, useEffect } from 'react';
import {
  useParams,
  Redirect,
  useRouteMatch,
  useLocation
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Spacing } from '../../styles';
import AuthContext from '../../contexts/AuthContext';
import { FLOORED_CAR } from '../../queries/viewQueries';
import FlooredCarRoutes from '../../routes/FlooredCarRoutes';
import SubNav from '../../elements/SubNav'
import { useAlert } from '../../hooks/useAlert';
import BasicAlert, {
  RepoAlert,
  ArbitrationAlert,
  SotAlert,
  AchAlert
} from '../../elements/BasicAlert';
import DataTable from '../../elements/DataTable';
import * as StringHelper from '../../utils/stringHelpers';
import * as DateHelper from '../../utils/dateHelpers';
import CarInfo from './CarInfo'
import './car-show.css';
import { canWaiveFee } from '../Fees/functions';
import FeeModal from '../Modals/Fee/New';
import { UserContext } from '../../contexts/UserContext';
import DataState from '../DataState';
import NewFeeWaiver from './Fees/Modal';
import { setAlertData } from '../../utils/setAlertData';
import { setNavOptions } from './SubNavOptions';
import TitleLogs from './TitleLogs';
import Notes from './Notes';
import FloorplanMessages from '../AuctionAccess/FloorplanMessages/';
import { CANCEL_ACH } from './Queries';
import { hrStyle, subNavStyle, containerStyle } from './Style';
import Audits from './Audits';

let source;
let query;
let amountDue;
let feeName;

const useURLQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const ShowCar = props => {
  let checkRefresh = useURLQuery();
  const forceRefresh = checkRefresh.get("forceRefresh");
  if (forceRefresh) {
    window.location.assign(useLocation().pathname);
  }

  const auth = useContext(AuthContext);
  const user = useContext(UserContext);

  const [cancelAch] = useMutation(CANCEL_ACH)

  const dealerId = parseInt(useParams().id);
  const id = parseInt(useParams().carId);
  const route = useRouteMatch();
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalFee, setModalFee] = useState();
  const [refetchAudit, setRefetchAudit] = useState();

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [showFeeModal, setShowFeeModal] = useState(false)
  const handleFeeClose = () => setShowFeeModal(false)
  const handleFeeShow = () => setShowFeeModal(true)

  const { loading, error, data, refetch: showPageRefetch } = useQuery(
    FLOORED_CAR, { variables: { id } }
  )

  if (loading && !data)
    return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const flooredCar = { ...data.flooredCar }

  const { car: { year, make, model } } = flooredCar;
  const name = `${year} ${make} ${model}`

  const userMarket = user.currentMarket.id;
  const carMarkets = [flooredCar.market.id, flooredCar.dealer.market.id];
  const outOfMarketCar = userMarket && !carMarkets.includes(userMarket);

  const [navLinks, title] = setNavOptions(id, dealerId, name, auth);

  const componentType = props.location.state?.componentType;

  const FeesPaid = () => {
    const feeColumns = [
      {
        title: 'Fee'
      },
      {
        title: 'Original Amount'
      },
      {
        title: 'Amount Due'
      },
      {
        title: 'Assessed On'
      },
      {
        title: 'Term'
      },
      {
        title: 'Status'
      },
      {
        title: 'Earned'
      },
      {
        title: 'Amount Paid'
      },
      {
        title: 'Amount Waived'
      },
      {
        title: 'Earns Interest?'
      },
      {
        title: 'Actions'
      },
      {
        title: 'Waivers'
      }
    ];

    $(document).on('click', '#modal', function (event) {
      const id = parseInt(event.target.dataset.id);
      const fee = flooredCar.fees.find(fee => parseInt(fee.id) === id);
      setModalFee(fee);
      handleShow();
    })

    const flooredCarId = data.flooredCar.id

    const newRoute = useRouteMatch().url

    const feesDataSet = flooredCar.fees.map((fee) => {
      if (auth.hasPolicy('Txn', 'read')) {
        fee.actions = `
          <a href="/fee/${fee.id}/transactions"/>Transactions</a>
        `
      }

      const [canWaive, waiveIssue] = canWaiveFee(fee, auth);
      let waiveButton = waiveIssue;
      if (canWaive) {
        waiveButton = `
          <button
            data-id="${fee.id}"
            data-due="${fee.due}"
            data-fee="${fee.name}"
            id="modal"
          >
            Waive Fee
          </button>
        `
      }

      return ([
        fee.name,
        StringHelper.currency(fee.amount),
        StringHelper.currency(fee.due),
        DateHelper.setDate(fee.chargeOn),
        (
          'none' == fee.termType ?
            '' : `${StringHelper.titleCase(fee.termType)} ${fee.termNumber}`
        ),
        fee.status,
        StringHelper.currency(fee.earned),
        StringHelper.currency(fee.paidAmount),
        StringHelper.currency(fee.waivedAmount),
        StringHelper.yesNo(fee.earnsInterest),
        fee.actions,
        waiveButton
      ])
    })

    return (
      <>
        <DataTable
          tableId='#fees-table'
          dataSet={feesDataSet}
          columns={feeColumns}
          pageTitle={`Fees`}
          unhideable={true}
          newRoute={newRoute}
          modal={true}
          handleShowModal={handleFeeShow}
        />
        {
          showFeeModal && <FeeModal
            showModal={showFeeModal}
            setShowModal={setShowFeeModal}
            handleClose={handleFeeClose}
            flooredCarId={flooredCarId}
            dealerId={dealerId}
          />
        }
      </>
    )
  };

  if (redirect) {
    return <Redirect
      to={{
        pathname: `/dealers/${dealerId}/cars`
      }}
    />
  }

  let achId = parseInt(flooredCar.scheduledAchId)

  const handleSubmit = (achId) => {
    if (confirm('Are you sure you want to cancel this pending ACH?')) {
      cancelAch({ variables: { id: achId } })
      window.location.reload();
    }
  }

  let achButton;

  achButton =
    <button name={achId} onClick={() => handleSubmit(achId)}>
      here
    </button>;

  if (dealerId !== parseInt(flooredCar.dealer.id)) {
    return <span>Car does not exist on this Dealer</span>;
  }

  const DisplayFloorplanMessages = () => {
    if (auth.hasPolicy('FloorplanMessage', 'Read')) {
      return <FloorplanMessages />
    } return null;
  }

  return (
    <>
      {
        outOfMarketCar ?
          <Redirect
            to={{
              pathname: "/dealers",
              state: { alert: true, outOfMarket: true }
            }}
          /> : null
      }
      <Helmet>
        <title>{flooredCar.name} | {flooredCar.dealer.name}</title>
      </Helmet>
      <SubNav title={title} navLinks={navLinks} style={subNavStyle} />
      {flooredCar.repoLocation && (
        <RepoAlert
          location={flooredCar.repoLocation}
          repoOn={flooredCar.repoOn}
          repoSoldOn={flooredCar.repoSoldOn}
        />
      )}
      {
        flooredCar.scheduledAchs > 0 &&
        <AchAlert
          achButton={achButton}
          date={flooredCar.scheduledAchDate}
          amount={flooredCar.scheduledAchAmount}
          carAmount={flooredCar.scheduledAchCarAmount}
          type={flooredCar.scheduledAchType}
        />
      }
      {
        flooredCar.arbitratedOn &&
          <ArbitrationAlert
            reason={flooredCar.arbitrationReason}
            name={flooredCar.arbitratedByName}
          />
      }
      {
        flooredCar.sotOn &&
          <SotAlert
            date={flooredCar.sotOn}
            name={flooredCar.sotByName}
          />
      }
      <FlooredCarRoutes />
      <div style={Spacing.formContainer}>
        {
          route.isExact &&
          <div className="container" style={containerStyle}>
            <CarInfo
              showPageRefetch={showPageRefetch}
              flooredCar={flooredCar}
              refetchAudit={refetchAudit}
            />
            <hr style={hrStyle} />
            <TitleLogs />
            <hr style={hrStyle} />
            <Audits
              setRefetchAudit={setRefetchAudit}
              showPageRefetch={showPageRefetch}
              noNotes={true}
            />
            <hr style={hrStyle} />
            <Notes id={id} />
            <hr style={hrStyle} />
            <FeesPaid />
            <hr style={hrStyle} />
            <DisplayFloorplanMessages />
          </div>
        }
      </div>
      {
        showModal &&
        <NewFeeWaiver
          showModal={showModal}
          setShowModal={setShowModal}
          fee={modalFee}
        />
      }
    </>
  );
};

export default ShowCar;
