import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useQuery, useMutation } from '@apollo/client';
import { MARKETS } from '../queries/indexQueries'
import { MARKETS_BY_CURRENT_USER } from '../queries/commonQueries'
import DataState from './DataState';
import { UserContext } from '../contexts/UserContext';
import { gql } from '@apollo/client';

import './switchMarkets.css'

const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($currentMarketScopeId: Int) {
    updateCurrentUser(
      currentMarketScopeId: $currentMarketScopeId
    ) {
      user {
        id
        currentMarketScope {
          id
          name
        }
      }
    }
  }
`;

const SwitchMarkets = () => {
  const [showModal, toggleShowModal] = useState(true)
  const { loading, error, data } = useQuery(MARKETS_BY_CURRENT_USER);
  const { loading: allMarketsLoading, error: allMarketsError, data: allMarketsData } = useQuery(MARKETS);
  const user = useContext(UserContext);
  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER,
    {
      onCompleted(data) {
        const { currentMarketScope } = data.updateCurrentUser.user;
        user.setCurrentMarket({
          id: currentMarketScope.id,
          name: currentMarketScope.name,
        })
      }
    }
  );


  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  if (allMarketsLoading && !allMarketsData) return <DataState.Loading />;
  if (allMarketsError) return <DataState.Error error={error} />;

  currentData = data;

  const markets = data.currentUser.markets.length ? data.currentUser.markets : allMarketsData.markets

  const handleClose = () => {
    toggleShowModal(false);
    window.location.reload();
  }

  const badge = {
    border: 'none',
    color: 'white',
    padding: '10px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    margin: '4px 4px',
    cursor: 'pointer',
    borderRadius: '8px',
    width: '32.5%'
  }

  const nameRow = {
    fontSize: '1.4em',
  }

  const infoRow = {
    fontSize: '0.7em'
  }

  const handleClick = (id) => {
    updateCurrentUser({ variables: { currentMarketScopeId: id } });
    window.location.reload()
  }

  const handleClickAll = () => {
    updateCurrentUser({ variables: { currentMarketScopeId: null } })
    window.location.reload();
  }

  const MarketBadge = (props) => {
    const { id, name, dealerCount } = props;
    return (
      <>
        <button onClick={() => handleClick(parseInt(id))} style={badge} className="color">
          <div style={nameRow}>
            <span>{name}</span>
          </div>
          <div style={infoRow}>
            <span>{dealerCount} Active Dealers</span>
          </div>
        </button>
      </>
    )
  }

  const AllBadge = () => {
    return (
      <>
        <button onClick={() => handleClickAll()} style={badge} className="color">
          <div style={nameRow}>
            <span>All</span>
          </div>
          <div style={infoRow}>
            <span>Display All My Markets</span>
          </div>
        </button>
      </>
    )
  }

  const MarketSwitchBody = () => {

    return (
      <>
        {data.currentUser.markets.length === 1 ? null : <AllBadge />}
        {
          markets.map((market, i) => {
            return (
              <MarketBadge
                key={i}
                id={market.id}
                name={market.name}
                dealerCount={market.dealers.length}
              />)
          })
        }
      </>
    )
  }

  return (
    <>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {'Switch Markets'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MarketSwitchBody />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SwitchMarkets;
