import React from 'react';
import { Helmet } from 'react-helmet';
import { gql } from '@apollo/client';
import { Formik, FieldArray, Field, getIn } from 'formik';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { useAlert } from '../../../hooks/useAlert';
import BasicAlert from '../../../elements/BasicAlert';
import * as yup from 'yup';
import { Colors } from '../../../styles'
import '../../../styles/termPrograms.css'
import { Typography, Spacing } from '../../../styles';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import { AddRemoveTermButtons } from '../../../elements/AddRemoveTermButtons';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'

const NEW_TERM_PROGRAM = gql`
mutation CreateTermProgram($name: String!, $extensionDays: Int!, $extensionPercent: Float!, $extensionLimit: Int!, $termLinesAttributes: [TermLineInput!]!) {
  createTermProgram(
    name: $name
    extensionDays: $extensionDays
    extensionPercent: $extensionPercent
    extensionLimit: $extensionLimit
    termLinesAttributes: $termLinesAttributes
  ) {
    termProgram {
      id
      name
      extensionDays
      extensionLimit
      extensionPercent
      termLines{
        id
        days
        curtailmentPercent
      }
    }
    errors {
      message
      path
    }
  }
}`

const NewTermProgram = () => {
  const [showAlert, displayAlert] = useAlert();

  const serverSideErrorProps = {
    SAVE_MUTATION: NEW_TERM_PROGRAM,
    onCompleteRedirectTo: '/system/term-programs',
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const handleAddTerm = (values) => {
    let newValues = values
    const newTermLine = [...values.termLinesAttributes, { days: 0, curtailmentPercent: 0 }]
    newValues.termLinesAttributes = newTermLine
    setFormValues(Object.assign({}, newValues))
  }

  const schema = yup.object({
    name: yup.string().max(60).required('Required'),
    extensionDays: yup.number().min(0, 'Must be positive').integer('Must be an integer').required('Required'),
    extensionPercent: yup.number().min(0, 'Must be positive').max(100, "Can't exceed 100").required('Required'),
    extensionLimit: yup.number().integer('Must be an integer').min(0).required('Required'),
    termLinesAttributes: yup.array().of(
      yup.object({
        days: yup.number("Must be a number").min(0, "Must be positive").integer('Must be an integer').required('Required').typeError("Must be a number"),
        curtailmentPercent: yup.number("Must be a number").min(0, "Must be positive").max(100, "Can't exceed 100").required('Required').typeError("Must be a number")
      })
    )
  });

  function TermProgramForm() {
    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          name: '',
          extensionDays: 0,
          extensionPercent: 0.0,
          extensionLimit: 0,
          termLinesAttributes: [
            {
              days: 0,
              curtailmentPercent: 0
            }
          ]
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          isInvalid,
          errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
              <div style={Spacing.formWrapper}>
                <div style={Spacing.formHeader}>
                  <h1 style={Typography.formHeader}>Create a Term Program</h1>
                </div>
              </div>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <div style={termLinesStyle}>
                <FieldArray
                  name="termLinesAttributes"
                  render={arrayHelpers => (
                    <div className='term-lines-container'>
                      {values.termLinesAttributes.map((termLine, index) => (
                        <div key={index}>
                          {`Term ${(index + 1)} Days`}
                          <Form.Group className='term-line-errors' >
                            <Field className='form-control' type="number" name={`${'termLinesAttributes'}[${index}].days`} />
                            <ErrorMessage name={`${'termLinesAttributes'}[${index}].days`} />
                          </Form.Group>
                         Curtailment %
                          <Form.Group className='term-line-errors'>
                            <Field className='form-control' step="0.1" type="number" name={`${'termLinesAttributes'}[${index}].curtailmentPercent`} />
                            <ErrorMessage name={`${'termLinesAttributes'}[${index}].curtailmentPercent`} />
                          </Form.Group>
                        </div>
                      ))
                      }
                      <div style={termButtonsContainer}>
                        <AddRemoveTermButtons arrayHelpers={arrayHelpers} values={values} />
                      </div>
                    </div>
                  )}
                />
              </div>
              <Form.Group>
                <Form.Label>Extension Term Days</Form.Label>
                <Form.Control
                  type="number"
                  name="extensionDays"
                  value={values.extensionDays}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.extensionDays && !errors.extensionDays}
                  isInvalid={!!errors.extensionDays && touched.extensionDays}
                  min='0'
                />
                <Form.Control.Feedback type="invalid">
                  {errors.extensionDays}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Extension Curtailment %</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    name="extensionPercent"
                    value={values.extensionPercent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.extensionPercent && !errors.extensionPercent}
                    min='0'
                    max='100'
                    step='0.1'
                    isInvalid={!!errors.extensionPercent && touched.extensionPercent}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.extensionPercent}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label>Max Number of Extensions</Form.Label>
                <Form.Control
                  type="number"
                  name="extensionLimit"
                  value={values.extensionLimit}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isValid={touched.extensionLimit && !errors.extensionLimit}
                  min='0'
                  isInvalid={!!errors.extensionLimit && touched.extensionLimit}

                />
                <Form.Control.Feedback type="invalid">
                  {errors.extensionLimit}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                type="submit"
                style={buttonColor}
                disabled={!isValid || formSubmitting}
              >
                Create Term Program
            </Button>
            </Form>
          )
        }
      </Formik>
    )

  }

  const termLinesStyle = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '40px',
    marginTop: '40px'
  }

  const termButtonsContainer = {
    marginLeft: '30px',
    justifyContent: 'space-around',
    marginTop: '20px',
    marginBottom: '40px',
    color: Colors.xlGreen,
    display: 'flex',
    flexDirection: 'column'
  }

  const buttonColor = {
    backgroundColor: Colors.xlGreen,
    borderColor: Colors.xlGreen,
    color: 'white'
  }

  const LoadedContent = () => {
    return (
      <>
        {showAlert &&
          <BasicAlert componentType="term program" type="create" />
        }
        <ServerSideErrors />
        <TermProgramForm />
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <Helmet>
        <title>New Term Program</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default NewTermProgram;
