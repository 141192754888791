import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import $ from 'jquery';

const BOOKS_CLOSED_THROUGH = gql`
  query SettingsForUi {
    settingsForUi {
      booksClosedThrough
    }
  }
`;

export const useClosedBooksThrough = () => {
  const { data } = useQuery(BOOKS_CLOSED_THROUGH);

  return data ? data.settingsForUi.booksClosedThrough : '2000-01-01';
}

const useClosedBooks = (handleSubmit) => {
  const { data } = useQuery(BOOKS_CLOSED_THROUGH);

  const closedThrough =
    data ? data.settingsForUi.booksClosedThrough : undefined;

  const checkClosedBooks = (values) => {
    const dates = [values.bankDate];

    if (values.txnLinesAttributes) {
      values.txnLinesAttributes.forEach(line => dates.push(line.earnedOn) );
    }

    if (closedThrough && dates.find(date => date && date < closedThrough)) {
      // NOTE(Jared Morgan):
      // I'm using jquery here instead of react because if I try to use useState
      // to determine whether the dialog displays, the date refreshes in edit
      // screens and I can't figure out what to do about it.
      const labelText = 'Books are closed through ' + closedThrough +
        '. Enter password to continue.';

      const modal = $('<div class="closed-books-password">');
      const label = $('<p>').text(labelText);
      const input = $('<input type="password">');
      const submit = $('<button>Submit</button>');
      const cancel = $('<button>Cancel</button>');
      cancel.on('click', () => modal.remove());
      submit.on('click', () => {
        values.booksClosedPassword = input.val();

        if (values.txnLinesAttributes) {
          values.txnLinesAttributes.forEach(line => {
            line.booksClosedPassword = input.val();
          });
        }

        handleSubmit(values);
        modal.remove();
      });

      modal.append(label).append(input).append('<br>').
        append(submit).append(cancel);

      $('body').append(modal);
    } else {
      handleSubmit(values);
    }
  };

  return checkClosedBooks;
};

export default useClosedBooks;
