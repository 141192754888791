import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import DataState from '../../../DataState';
import { Helmet } from 'react-helmet';
import { Redirect, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Field } from 'formik';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '../../../../elements/ErrorMessage';
import { classNames } from '../functionality';
import * as yup from 'yup';
import { Typography, Spacing } from '../../../../styles';
import { Colors } from '../../../../styles';
import '../../../../styles/ratePrograms.css';

const NEW_RATE_PROGRAM_CHANGE = gql`
  mutation CreateRateProgramChange($rateProgramId: Int!, $rate: Float!, $effectiveOn: ISO8601Date!) {
      createRateProgramChange(
        rateProgramId: $rateProgramId
        effectiveOn: $effectiveOn
        rate: $rate
      ) {
        rateProgramChange { id, rate, effectiveOn }
        errors { message, path }
       }
      }
     `
const RATE_PROGRAM = gql`
  query RATE_PROGRAM($id: ID!) {
    rateProgram(id: $id) {
      id
      name
      description
      rateProgramChanges { id, rate, effectiveOn }
    }
  }
`

const RATE_PROGRAMS = gql`
  query {
    ratePrograms {
      rateProgramChanges {
        id
        rate
        effectiveOn
      }
    }
  }
`

const NewRateProgramChange = () => {
  const rateProgramId = parseInt(useParams().id)
  const [redirect, setRedirect] = useState(false);
  const [newRateProgramChange] = useMutation(
    NEW_RATE_PROGRAM_CHANGE,
    {
      update(cache, { data })
      {
        cache.writeQuery({
          query: RATE_PROGRAMS,
          data: {
            ratePrograms:{
              id: rateProgram.id,
              name: rateProgram.name,
              description: rateProgram.description,
              __typename: rateProgram.__typename,
              rateProgramChanges: [
                ...rateProgram.rateProgramChanges,
                {
                  id: data.createRateProgramChange.rateProgramChange.id,
                  rate: data.createRateProgramChange.rateProgramChange.rate,
                  effectiveOn: data.createRateProgramChange.rateProgramChange.effectiveOn,
                  __typename: data.createRateProgramChange.rateProgramChange.__typename
                }
              ]}
            }
          })

      setRedirect(true);
    }

   }
  );

  const { loading, error, data } = useQuery(RATE_PROGRAM, { variables: { id: rateProgramId} })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { rateProgram } = data;

  const today = new Date()

  const schema = yup.object({
    rate: yup
      .number()
      .min(0, 'Must be positive')
      .required('Required'),
    effectiveOn: yup
      .date()
      .min(today, 'The date must be in the future')
      .required('Required')
  });

  const initialValues = {
    rateProgramId,
    rate: 0,
    effectiveOn: ''
  }

  function RateProgramChangeForm() {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/system/rate-programs/${rateProgramId}`,
          state: { alert: true, type: 'created'}
        }}
      />
    }

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isInvalid,
        errors,
        setValues
      }) => (
        <Form noValidate onSubmit={handleSubmit} style={{paddingRight: 40, paddingLeft: 40}}>
          <div style={Spacing.formWrapper}>
            <div style={Spacing.formHeader}>
              <h1 style={Typography.formHeader}>{`Create A Rate Program Change For: ${rateProgram.name}`}</h1>
            </div>
          </div>
            <Form.Label>Rate</Form.Label>
            <Form.Group className='term-line-errors'>
              <Field className={classNames(errors, touched, 'rate')} type="number" name="rate" />
              <ErrorMessage name={"rate"} />
            </Form.Group>
            <Form.Label>Effective On</Form.Label>
            <Form.Group className='term-line-errors'>
              <Field className={classNames(errors, touched, 'effectiveOn')} type="date" name="effectiveOn" />
              <ErrorMessage name={"effectiveOn"} />
            </Form.Group>
             <Button
              type="submit"
              style={buttonColor}
            >
              Create Rate Program Change
            </Button>
          </Form>
        )}
      </Formik>
    )
  }

  const handleSubmit = (values) => {
    newRateProgramChange({
      variables: values
    })
  }

  const buttonColor = {
     backgroundColor: Colors.xlGreen,
     borderColor: Colors.xlGreen,
     color: 'white'
  }

  return (
    <>
    <Helmet>
      <title>New Rate Program</title>
    </Helmet>
    <RateProgramChangeForm />
    </>
  );
};

export default NewRateProgramChange;
