import React, { useState } from 'react';
import TransactionForm from '../Transactions/TransactionForm';
import TransactionLineForm from '../Transactions/TransactionLines/Form';
import DebitAndCreditInputs from
  '../Transactions/TransactionLines/DebitAndCreditInputs';
import TransactionLineInputs from
  '../Transactions/TransactionLines/TransactionLineInputs';
import TransactionLineFlooringInputs from
  '../Transactions/TransactionLines/TransactionLineFlooringInputs';
import { Form as FormikForm, useFormikContext } from 'formik';
import TransactionPurpose from '../Transactions/TransactionPurpose'
import * as yup from 'yup';

export const txnSchema = yup.object().shape({
  txnLinesAttributes: yup
    .array()
    .of(
      yup.object().shape({
        debit: yup.number(),
        credit: yup.number(),
        accountId: yup
          .number()
          .when('debit', {
            is: '' || undefined,
            then: yup.number(),
            otherwise: yup.number().required('required')
          })
          .when('credit', {
            is: '' || undefined,
            then: yup.number(),
            otherwise: yup.number().required('required')
          })
      }, ['debit', 'credit'])
    )
})

const Form = () => {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [errorAlert, setErrorAlert] = useState(null);
  const journalEntry = true

  let columns;

  if (!(location.search.includes('detailed=t'))) {
    columns = {
      display: 'grid',
      gridTemplateColumns: '2fr 2fr 2fr 1fr 1fr 4fr',
    }
  } else {
    columns = {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2.5fr',
    }
  }

  return (
    <>
      <FormikForm>
        <TransactionForm
          referenceLabel='JE Number'
          dateLabel='GL Date'
        />
        <TransactionPurpose />
        <TransactionLineForm
          setDisableSubmit={setDisableSubmit}
          disableSubmit={disableSubmit}
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          columns={columns}
          journalEntry={journalEntry}
        >
          <TransactionLineInputs>
            <TransactionLineFlooringInputs />
            <DebitAndCreditInputs />
          </TransactionLineInputs>
        </TransactionLineForm>
      </FormikForm>
    </>
  )
}

export default Form;
