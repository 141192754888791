import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import Table from 'components/src/components/Transactions/Table';
import { Typography } from 'components/src/styles';

const Accruals = () => {
  const id = parseInt(useParams().carId);

  const shownColumns = [
    'bankDate', 'createdDate', 'purpose', 'amount',
    'dealerCount', 'flooredCarCount', 'createdBy', 'actions'
  ];

  const availableFilters = [
    'purpose', 'created_by', 'bank_date', 'created_date'
  ];

  return (
    <Fragment>
      <div style={Typography.pageTitle}>Accrual Transactions</div>
      <Table
        shownColumns={shownColumns}
        availableFilters={availableFilters}
        scopes={['accrual']}
        referrerType="car"
        referrerId={id}
        reportPath="accruals-report"
      />
    </Fragment>
  );
};

export default Accruals;
