import React, { useState, useContext, useEffect } from "react";
import { Text } from "react-native";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import DataState from "../../DataState";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, FieldArray, Field } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import { Colors } from "../../../styles";
import { titleCase } from "../../../utils/stringHelpers";
import "../../../styles/termPrograms.css";
import { ErrorMessage } from "../../../elements/ErrorMessage";
import { Typography, Spacing } from "../../../styles";
import Loading from '../../../elements/Loading';
import { useHistory } from 'react-router-dom';
import * as Formatting from '../../../styles/formatting';
import AuthContext from '../../../contexts/AuthContext';
import DataTable from '../../../elements/DataTable';

const PROGRAM = gql`
  query PROGRAM($id: ID!) {
    termProgram(id: $id) {
      id
      name
      extensionDays
      extensionLimit
      extensionPercent
      activeDealers { id, name, dealer { name, id}, feeProgram { name, id } }
      termLines {
        id
        days
        curtailmentPercent
      }
    }
  }
`;

const UPDATE_PROGRAM = gql`
  mutation UpdateTermProgram(
    $id: ID!
    $name: String!
    $extensionDays: Int!
    $extensionPercent: Float!
    $extensionLimit: Int!
    $termLinesAttributes: [TermLineInput!]!
  ) {
    updateTermProgram(
      id: $id
      name: $name
      extensionDays: $extensionDays
      extensionPercent: $extensionPercent
      extensionLimit: $extensionLimit
      termLinesAttributes: $termLinesAttributes
    ) {
      termProgram {
        id
        name
        extensionDays
        extensionLimit
        extensionPercent
        termLines {
          id
          days
          curtailmentPercent
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

const TermProgram = () => {
  const { id } = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');
  const [formValues, setFormValues] = useState();
  const history = useHistory();

  const { loading, error, data } = useQuery(PROGRAM, { variables: { id } });
  const [updateTermProgram] = useMutation(UPDATE_PROGRAM, {
    onCompleted(data) {
      const key = Object.keys(data)[0];
      const errors = data[key].errors;
      if (errors?.length) {
        setFormSubmitting(false);
        let errorString = '';
        let errorName = '';
        let errorMessage = '';
        errors.forEach((error, index) => {
          errorName = error.path[1];
          errorMessage = error.message;
          const separator = index > 0 ? ', ' : ''
          errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`
        })
        setServerSideErrors(errorString)
      } else {
        setFormSubmitting(false);
        history.push({
          pathname: "/system/term-programs",
          state: { alert: true },
        });
      }
    },
  });

  useEffect(() => {
    if (data && data.termProgram) {
      setFormValues(data.termProgram);
    }
  }, [data]);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const auth = useContext(AuthContext);

  const DisplayDealers = () => {

      const dealerColumns = [
      {
        title: 'Dealer'
      },
      {
        title: 'Fee Program'
      },
      {
        title: 'Actions'
      }
    ];

    const dealerDataSet = data.termProgram.activeDealers.map((dealer) => {
      
      const actions = []

      if (auth.hasPolicy('Dealer', 'read')) {
        actions.push(`<a href="/dealers/${dealer.dealer.id}"/>View</a>`)
      }

      if (actions === []) {
        actions.push(' ')
      }
      return ([
        dealer.dealer.name,
        dealer.feeProgram.name,
        actions.join(" | ")
      ])
    })

    return (
      <>
        <DataTable
          tableId='#fees-table'
          dataSet={dealerDataSet}
          columns={dealerColumns}
          pageTitle={`Fees`}
          unhideable={true}
          uncreatable={true}
        />
      </>
      )
  };

  const schema = yup.object({
    name: yup
      .string()
      .max(60)
      .required(),
    extensionDays: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .integer("Must be an integer")
      .required("Required"),
    extensionPercent: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .max(100, "Must be less than or equal to 100")
      .required("Required"),
    extensionLimit: yup
      .number()
      .integer("Must be an integer")
      .min(0)
      .required("Required"),
    termLines: yup.array().of(
      yup.object().shape({
        days: yup
          .number()
          .min(0, "Must be positive")
          .integer("Must be an integer")
          .required("Required")
          .typeError("Must be a number"),
        curtailmentPercent: yup
          .number()
          .min(0, "Must be positive")
          .max(100, "Can't exceed 100")
          .required("Required")
          .typeError("Must be a number"),
      })
    ),
  });

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  function TermProgramForm() {
    if (formValues) {
      return (
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={formValues}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setValues,
            touched,
            errors,
            isValid,
          }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                style={{ paddingRight: 40, paddingLeft: 40 }}
              >
                <div style={Spacing.formWrapper}>
                  <div style={Spacing.formHeader}>
                    <h1 style={Typography.formHeader}>{`Update: ${titleCase(
                      data.termProgram.name
                    )}`}</h1>
                  </div>
                </div>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isValid={touched.name && !errors.name}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={termLinesStyle}>
                  <FieldArray
                    name="termLines"
                    render={(arrayHelpers) => (
                      <div className="term-lines-container">
                        {values.termLines.map((termLine, index) => (
                          <div key={index}>
                            {`Term ${index + 1} Days`}
                            <Form.Group className="term-line-errors">
                              <Field
                                className="form-control"
                                type="number"
                                name={`${"termLines"}[${index}].days`}
                              />
                              <ErrorMessage
                                name={`${"termLines"}[${index}].days`}
                              />
                            </Form.Group>
                          Curtailment %
                            <Form.Group className="term-line-errors">
                              <Field
                                className="form-control"
                                type="number"
                                step="0.1"
                                name={`${"termLines"}[${index}].curtailmentPercent`}
                              />
                              <ErrorMessage
                                name={`${"termLines"}[${index}].curtailmentPercent`}
                              />
                            </Form.Group>
                          </div>
                        ))}
                        <div style={termButtonsContainer}>
                          {values.termLines.length > 1 ? (
                            [
                              <Button
                                key='add-term'
                                type="button"
                                style={buttonColor}
                                onClick={() =>
                                  arrayHelpers.insert(
                                    values.termLines.length + 1,
                                    { days: 0, curtailmentPercent: 0 }
                                  )
                                }
                              >
                                Add Term
                            </Button>,
                              <Button
                                key='remove-term'
                                type="button"
                                style={buttonColor}
                                onClick={() => removeTerm(values, setValues)}
                              >
                                Remove Term
                            </Button>,
                            ]
                          ) : (
                              <Button
                                type="button"
                                style={buttonColor}
                                onClick={() =>
                                  arrayHelpers.insert(values.termLines.length + 1, {
                                    days: 0,
                                    curtailmentPercent: 0,
                                  })
                                }
                              >
                                Add Term
                              </Button>
                            )}
                        </div>
                      </div>
                    )}
                  />
                </div>
                <Form.Group>
                  <Form.Label>Extension Term Days</Form.Label>
                  <Form.Control
                    type="number"
                    name="extensionDays"
                    value={values.extensionDays}
                    onChange={handleChange}
                    isValid={touched.extensionDays && !errors.extensionDays}
                    isInvalid={!!errors.extensionDays}
                    min="0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.extensionDays}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Extension Curtailment %</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      name="extensionPercent"
                      value={values.extensionPercent}
                      onChange={handleChange}
                      isValid={
                        touched.extensionPercent && !errors.extensionPercent
                      }
                      min="0"
                      max="100"
                      step="0.1"
                      isInvalid={!!errors.extensionPercent}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.extensionPercent}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Max Number of Extensions</Form.Label>
                  <Form.Control
                    type="number"
                    name="extensionLimit"
                    value={values.extensionLimit}
                    onChange={handleChange}
                    isValid={touched.extensionLimit && !errors.extensionLimit}
                    min="0"
                    isInvalid={!!errors.extensionLimit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.extensionLimit}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" style={buttonColor}>
                  Update Term Program
              </Button>
              </Form>
            )}
        </Formik>
      );
    } else {
      return <Text>Loading...</Text>;
    }
  }

  let removedItems = [];
  const removeTerm = (values, setValues) => {
    const removedItem = values.termLines.pop();
    if (removedItem.id) removedItems.push(removedItem);
    setValues(values);
  };

  const handleSubmit = (values) => {
    const updatedTermLines = values.termLines.map((termLine) => {
      let id = null;
      if (termLine.id) {
        id = termLine.id;
      }
      return {
        id: id,
        days: termLine.days,
        curtailmentPercent: termLine.curtailmentPercent,
      };
    });

    if (removedItems.length > 0) {
      removedItems.forEach((item) => {
        updatedTermLines.push({ id: item.id, _destroy: true });
      });
    }

    const newValues = {
      name: values.name,
      id: values.id,
      extensionDays: values.extensionDays,
      extensionLimit: values.extensionLimit,
      extensionPercent: values.extensionPercent,
      termLinesAttributes: updatedTermLines,
    };

    setFormSubmitting(true)
    updateTermProgram({
      variables: newValues,
    });
  };

  const termLinesStyle = {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "40px",
    marginTop: "40px",
  };

  const termButtonsContainer = {
    marginLeft: "30px",
    justifyContent: "space-around",
    marginTop: "20px",
    marginBottom: "40px",
    color: Colors.xlGreen,
    display: "flex",
    flexDirection: "column",
  };

  const buttonColor = {
    backgroundColor: Colors.xlGreen,
    borderColor: Colors.xlGreen,
  };

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <>
        <ServerSideErrors />
        <TermProgramForm />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Edit {titleCase(data.termProgram.name)} Term Program</title>
      </Helmet>
      <DisplayContent />
      <DisplayDealers dealers={data.termProgram.activeDealers} />
    </>
  );
};

export default TermProgram;
