import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { useAlert } from '../../hooks/useAlert';
import { placeSchema, Form } from './Form';
import { PLACES } from '../../queries/indexQueries'
import Loading from '../../elements/Loading';
import { CREATE_DEALER_PLACE } from './Queries';

const New = (props) => {
  const history = useHistory();
  const dealerId = parseInt(useParams().id);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [createDealerPlace] = useMutation(CREATE_DEALER_PLACE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PLACES,
          variables: { dealerId }
        }
      ],
      onCompleted() {
        setFormSubmitting(false);
        history.push({
          pathname: `/dealers/${dealerId}/places`,
          state: { alert: true, type: 'created' },
        })
      }
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createDealerPlace({ variables: values })
  };

  const NewForm = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Dealer Place</h1>
          </div>
          <Formik
            initialValues={{
              name: '',
              type: '',
              phone: '',
              fax: '',
              attention: '',
              street: '',
              city: '',
              state: '',
              zip: '',
              dealerId: parseInt(dealerId)
            }}
            validationSchema={placeSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default New;
