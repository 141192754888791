import React, { useState, useEffect } from 'react';
import secrets from '../../../secrets';
import GoogleMapReact from 'google-map-react';
import booleanOverlap from '@turf/boolean-overlap';
import booleanWithin from '@turf/boolean-within';
import * as turf from '@turf/turf';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { Helmet } from 'react-helmet';
import { Spacing } from '../../../styles';
import * as Colors from '../../../styles/colors';
import DataState from '../../DataState';


// update ID on submits based on params

const MARKETS = gql`
query {
  markets {
    id
    coordinates {
      lat
      lng
    }
  }
}`;

const UPDATE_MARKET = gql`
mutation UpdateMarket($id: ID!, $coordinates: [CoordinateInput!]) {
    updateMarket(
      id: $id
      coordinates: $coordinates
    ) {
      market {
        id
        coordinates {
          lat
          lng
        }
      }
      errors {
        message
        path
      }
    }
  }
`

const MarketsMap = (props) => {
  const id = parseInt(useParams().id);

  let isUpdate = false;
  let initialUpdateCoords = [];

  const marketCoords = [];
  let newPolygon;
  let currentCoords;
  let googleMap;
  const boundaries = [];
  const [updateMarket] = useMutation(UPDATE_MARKET);
  const [showAlert, toggleAlert] = useState();
  const dismissAlert = () => toggleAlert(false)

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const setExistingMarketColor = () => {
    return '#2a6548'
  }

  const formatCoords = (coords) => {
    return coords.map((coord) => {
      return { "lat": coord.lat(), "lng": coord.lng() };
    })
  }

  const marketId = parseInt(useParams().id)
  const handleGoogleMapApi = (google) => {
    googleMap = google;

    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polygon']
      }
    });

    google.maps.event.addListener(drawingManager, 'overlaycomplete', event => {

      if (event.type == 'polygon') {
        const coords = event.overlay.latLngs.i[0].i
        const formattedCoords = formatCoords(coords)
        const overlap = hasOverlap(formattedCoords)

        if (overlap) {
          event.overlay.setMap(null)
        } else {
          addCoordsToState(formattedCoords);
          event.overlay.setMap(null);

          if (drawingManager.getDrawingMode()) {
            drawingManager.setDrawingMode(null);
          }
        }
      }
    }
    );

    google.maps.event.addListener(drawingManager, "drawingmode_changed", function () {

      if (drawingManager.getDrawingMode() != null && newPolygon) {
        newPolygon.setMap(null);
        currentCoords = null;
      }
    }
    );

    drawingManager.setMap(google.map);

    for (const market of marketCoords) {
      let coords = Object.values(market)[0]
      const id = Object.keys(market)[0]

      const editable = (marketId === parseInt(id))
        ? true
        : false

      if (!editable) {
        const newPolygon = new google.maps.Polygon({
          map: google.map,
          paths: coords,
          strokeColor: 'black',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: setExistingMarketColor(),
          fillOpacity: 0.5,
          draggable: false,
          editable: false,
          geodesic: false,
          id: id
        })

        setBoundaries(coords)
      }

      if (editable) {
        coords = coords.map((coord) => {
          return {lat: coord.lat, lng: coord.lng}
        })

        isUpdate = true;
        if (initialUpdateCoords.length === 0) initialUpdateCoords = coords;
        currentCoords = coords;
        addCoordsToState(currentCoords, 'red', marketId);
      }
    }
  }

  const setBoundaries = (coords) => {
    let existingMarketCoords = coords
    existingMarketCoords = formatTurfCoords(existingMarketCoords)
    const market = turf.polygon(existingMarketCoords)

    boundaries.push(market)
  }

  const formatTurfCoords = (coords) => {
    let subArrays = []
    coords.forEach((coord) => {
      subArrays.push([coord.lat, coord.lng])
    })

    subArrays.push(subArrays[0])

    return [subArrays]
  }

  const hasOverlap = (newMarket) => {
    let newPolyMarket = formatTurfCoords(newMarket);

    newPolyMarket = turf.polygon(newPolyMarket)

    const existingMarketBoundaries = boundaries

    for (let market of existingMarketBoundaries) {
      if (turf.booleanWithin(market, newPolyMarket) || turf.booleanWithin(newPolyMarket, market)) {
        return true;
      }

      if (turf.booleanOverlap(market, newPolyMarket)) {
        return true;
      }
    }

    return false
  }

  const updateCoordinates = (coords, id, polygon) => {
    let formattedCoords = formatCoords(coords);
    const overlap = hasOverlap(formattedCoords);

    if (overlap) {
      newPolygon.setMap(null);
      addCoordsToState(currentCoords, newPolygon.fillColor, id)

    } else {

      currentCoords = formattedCoords
    }
  }

  const addCoordsToState = (coords, fillColor = false, id = false) => {
    if (!id) {
      id = (Object.keys(marketCoords).length + 1)
      currentCoords = coords
    }

    fillColor ? fillColor : fillColor = getRandomColor()

    newPolygon = new googleMap.maps.Polygon({
      map: googleMap.map,
      paths: currentCoords,
      strokeColor: 'black',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: fillColor,
      fillOpacity: 0.5,
      draggable: true,
      editable: true,
      geodesic: true,
      id: id
    })

    googleMap.maps.event.addListener(newPolygon, 'click', function () {
      const coords = (this.latLngs.i[0].i);

      // Click - show box that details all locations inside market
      // self.updateCoordinates(coords, id);
    })

    let isBeingDragged = false;

    googleMap.maps.event.addListener(newPolygon, 'dragstart', function () {
      isBeingDragged = true;
    });

    googleMap.maps.event.addListener(newPolygon, 'dragend', function () {
      const coords = (this.latLngs.i[0].i);
      const id = newPolygon.id

      isBeingDragged = false;

      updateCoordinates(coords, id, newPolygon);
    });

    googleMap.maps.event.addListener(newPolygon.getPath(), "insert_at", function () {
      const coords = this.i
      const id = newPolygon.id

      updateCoordinates(coords, id, newPolygon);
    });


    let coordsArr = []

    googleMap.maps.event.addListener(newPolygon.getPath(), "set_at", function () {

      let coords = this.i;
      const id = newPolygon.id;

      if (!isBeingDragged) {
        updateCoordinates(coords, id, newPolygon)
      }
    });
  }

  const setSubmittedPolygon = (currentCoords, id) => {
    newPolygon = new googleMap.maps.Polygon({
      map: googleMap.map,
      paths: currentCoords,
      strokeColor: 'black',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: setExistingMarketColor(),
      fillOpacity: 0.5,
      draggable: false,
      editable: false,
      geodesic: true,
      id: id
    })
  }

  async function submitCoords() {
    if (id) {
      const { data } = await updateMarket(
        {
          variables:
          {
            "id": id,
            "coordinates": currentCoords
          }
        }
      )

    if (data) {
      if (newPolygon) newPolygon.setMap(null);
      setSubmittedPolygon(currentCoords, id);
      toggleAlert(true);
      setTimeout(dismissAlert, 5000);
      window.location = '/system/markets';
    }

    // return error alert
    return null
  }
}

  const resetCoords = () => {
    if (newPolygon) {
      newPolygon.setMap(null)
      if (isUpdate) addCoordsToState(initialUpdateCoords, 'red');
    }
  }

  const { loading, error, data } = useQuery(MARKETS);

  if (loading || !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const marketsWithCoords = data.markets.filter((market) => market.coordinates)

  marketsWithCoords.forEach((market) => {
    const marketId = market.id
    marketCoords.push({
      [marketId]: market.coordinates
    })
  })

  const alert = () => {
    if (showAlert) {
      return <Alert variant='success' style={Spacing.alerts}>
        This market has been created.
    </Alert>
    }

    return null
  }


  return (
    <>
      <Helmet>
        <title>Markets Map</title>
      </Helmet>
      <div className="container" style={style.container}>
        {alert()}
        <div style={style.map}>
          <GoogleMapReact
            defaultCenter={{ lat: 35.4676, lng: -97.568 }}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${secrets.googleMaps.api}.exp&libraries=geometry,drawing,places`}
            onGoogleApiLoaded={handleGoogleMapApi}
          />
          <div className="footer" style={style.footer}>
            <div className="btn btn-danger" style={style.resetButton} onClick={resetCoords}>Reset</div>
            <div className="btn btn-primary" style={style.submitButton} onClick={submitCoords}>Submit</div>
          </div>
        </div>
      </div>
    </>
  )
}

const style = {
  map: {
    height: '70vh',
    width: '100%'
  },
  submitButton: {
    backgroundColor: Colors.xlGreen,
    borderColor: Colors.xlGreen,
    width: '120px',
  },
  resetButton: {
    marginRight: '10px',
    width: '120px',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '3%'
  },
  container: {
    marginTop: '1%'
  }
}



export default MarketsMap;

