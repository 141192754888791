import {
  faMoneyCheckEditAlt,
  faHandHoldingUsd,
  faClipboardListCheck,
  faCommentAltLines,
  faFileContract,
  faUsers,
  faTriangle,
  faFileInvoiceDollar
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCar,
  faEdit,
  faUniversity,
  faMoneyCheckAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFileAlt,
} from '@fortawesome/free-regular-svg-icons';
import {
  faMapMarked,
  faShieldAlt
} from '@fortawesome/pro-duotone-svg-icons';
import { faCars, faFileInvoice } from '@fortawesome/pro-solid-svg-icons'

export const getDealerSubNavLinks = (id, auth, setShowReportingModal) => {
  const navLinks = [
    {
      link: `/dealers/${id}/cars`,
      icon: faCars,
      text: 'Cars'
    },
    {
      link: `/dealers/${id}/collateral-coverages`,
      icon: faShieldAlt,
      text: 'Collateral Coverage'
    },
    {
      link: `/dealers/${id}/transactions`,
      icon: faMoneyCheckAlt,
      text: 'Transactions'
    },
    {
      link: `/dealers/${id}/payments`,
      icon: faMoneyCheckEditAlt,
      text: 'Set Up Payment'
    },
    {
      link: `/dealers/${id}/payments-report`,
      icon: faFileInvoiceDollar,
      text: 'Payments Report'
    },
    {
      link: `/dealers/${id}`,
      icon: faCar,
      text: 'Payments Report - Single Car',
      handleClick: e => {
        e.preventDefault();
        e.stopPropagation();
        setShowReportingModal(true);
      }
    },
    {
      link: `/dealers/${id}/bank-accounts`,
      icon: faUniversity,
      text: 'Bank Accounts'
    },
    {
      link: `/dealers/${id}/people`,
      icon: faUsers,
      text: 'People'
    },
    {
      link: `/dealers/${id}/places`,
      icon: faMapMarked,
      text: 'Locations'
    },
    {
      link: `/dealers/${id}/audits`,
      icon: faClipboardListCheck,
      text: 'Audits'
    },
    {
      link: `/dealers/${id}/notes`,
      icon: faCommentAltLines,
      text: 'Notes'
    },
    {
      link: `/dealers/${id}/files`,
      icon: faFileAlt,
      text: 'Files'
    },
    {
      link: `/dealers/${id}/uccs`,
      icon: faFileContract,
      text: 'UCC/PMSI'
    },
  ]

  if (auth.hasPolicy("Dealer", "read")) {
    navLinks.splice(
      0, 0, {
        link: `/dealers/${id}/edit`,
        icon: faEdit,
        text: 'Edit'
      }
    )
  }

  if (auth.hasExactPolicy('Txn', 'view_accruals')) {
    navLinks.splice(
      4, 0, {
      link: `/dealers/${id}/accruals`,
      icon: faFileInvoice,
      text: 'Accruals'
    }
    );
  }

  if (auth.hasExactPolicy('Quote', 'writeoff')) {
    navLinks.splice(
      5, 0, {
      link: `/dealers/${id}/write-offs`,
      icon: faHandHoldingUsd,
      text: 'Write Offs'
    }
    );
  }

  if (auth.hasPolicy('Delta', 'view')) {
    navLinks.push(
      {
        link: `/dealers/${id}/deltas`,
        icon: faTriangle,
        text: 'Deltas',
      }
    )
  }

  return navLinks;
}
