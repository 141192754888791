import React, { useState } from 'react';
import { Form, reverseSchema } from './Form';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import DataState from "../../DataState";
import * as yup from 'yup';

const NEXT_JE_NUMBER = gql`
  query SettingsForUi {
    settingsForUi {
      nextJeNumber
    }
  }
`

const VOID_TRANSACTION = gql`
  mutation VoidTxn(
    $id: ID!
    $bankDate: ISO8601Date
    $memo: String
    $reference: String
  ) {
    voidTxn(
      id: $id
      bankDate: $bankDate
      reverse: true
      reference: $reference
      memo: $memo
    ) {
      txn {
        id
      }
      errors {
        path
        message
      }
    }
  }
`;

const NewReverse = (props) => {
  const [serverSideErrors, setServerSideErrors] = useState("");

  const { data } = useQuery(NEXT_JE_NUMBER)

  const [reverseTransaction, { errors, loading }] = useMutation(
    VOID_TRANSACTION,
    {
      onCompleted(values) {
        const key = Object.keys(values)[0];
        const errors = values[key].errors;
        if (errors.length) {
          let errorString = "";
          let errorName = "";
          let errorMessage = "";
          errors.forEach((error, index) => {
            errorName = error.path[1];
            errorMessage = error.message;
            const separator = index > 0 ? ", " : "";
            errorString += `${separator} ${titleCase(
              errorName
            )} ${errorMessage}`;
          });
          setServerSideErrors(errorString);
        } else {
          props.setShowModal(false);
          props.displayAlert();
          window.location.reload();
        }
      }
    }
  );

  if (!data) return <DataState.Loading />
  const nextJeNumber = data.settingsForUi.nextJeNumber.toString()

  const handleSubmit = (values) => {
    reverseTransaction({ variables: values });
  };

  if (loading)
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={loading}
      >
        <Modal.Header>
          <Modal.Title>
            <DataState.Loading />
          </Modal.Title>
        </Modal.Header>
      </Modal>
    )

  return (
    <>
      { serverSideErrors && <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div> }
      <Formik
        initialValues={{
          id: props.txn.id,
          bankDate: '',
          reference: nextJeNumber || '',
          memo: props.txn.memo
        }}
        onSubmit={handleSubmit}
        validationSchema={yup.object({
          bankDate: yup.date().required('Required'),
          memo: yup.string().required('Required')
        })}
      >
        <Form {...props} handleSubmit={handleSubmit} />
      </Formik>
    </>
  );
};

export default NewReverse;
