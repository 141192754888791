import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from '../../../styles';
import DealerInfo from '../DealerInfo';
import CollateralCoverages from './CollateralCoverage';
import BasicAlert from '../../../elements/BasicAlert';
import { useAlert } from '../../../hooks/useAlert';
import { setAlertData } from '../../../utils/setAlertData';

const index = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  return (
    <>
      <Helmet>
        <title>Collateral Coverages</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="collateral coverage" type={alertType} />
      }
      <DealerInfo />
      <CollateralCoverages />
    </>
  );
};

export default index;
