import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import DataState from '../DataState';
import '../../styles/Source/showInfo.css';
import BasicAlert from '../../elements/BasicAlert';
import * as Style from '../../styles/Source/showInfo';
import * as Colors from '../../styles/colors';
import { SaleTimes } from './SaleTimes';
import { setAlertData } from '../../utils/setAlertData';
import { useAlert } from '../../hooks/useAlert';
import * as StringHelper from '../../utils/stringHelpers';

export const AUCTION = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      id
      name
      street
      city
      state
      zip {
        value
        formatted
      }
      status
      auctionAccessId
      flooredCarsCount
      flooredCarsAmount
      saleTimes { id, name, type, occursOn, dayOfWeek, saleStartAt, saleEndAt }
      primaryContact {
        id
        fullName
        email
         officePhone {
          formatted
        }
      }
    }
  }
`;

const Info = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const id = parseInt(useParams().ownerId) || parseInt(useParams().id);
  const { loading, error, data } = useQuery(AUCTION, { variables: { id } });

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />

  const {
    name,
    saleTimes,
    accountNumber,
    auctionAccessId,
    status
  } = data.auction;

  const contact = data.auction.primaryContact?.fullName;
  const contactPhone = data.auction.primaryContact?.officePhone?.formatted;
  const contactEmail = data.auction.primaryContact?.email;
  const formattedZip = data.auction.zip.formatted;

  return (
    <>
      {showAlert &&
        <BasicAlert componentType="sale time" type={alertType} />
      }
      <Container style={Style.auctionInfoContainer}>
        <Row>
          <div className="col-xl-2 dealer-info">
            <Row className="info-row">
              <Col>Auction Name:</Col>
              <Col align="right">{name}</Col>
            </Row>
            <Row className="info-row">
              <Col>Primary Contact:</Col>
              <Col align="right">{contact}</Col>
            </Row>
            <Row className="info-row">
              <Col>Primary Contact Phone::</Col>
              <Col align="right">{contactPhone}</Col>
            </Row>
            <Row className="info-row">
              <Col>Primary Contact Email:</Col>
              <Col align="right">{contactEmail}</Col>
            </Row>
          </div>
          <div className="col-xl-2 dealer-info">
            <Row className="info-row">
              <Col>Current Principal Floored From Auction</Col>
              <Col align="right">{StringHelper.currency(data.auction.flooredCarsAmount)}</Col>
            </Row>
            <Row className="info-row">
              <Col>Current Cars Floored From Auction</Col>
              <Col align="right">{data.auction.flooredCarsCount}</Col>
            </Row>
            <Row className="info-row">
              <Col>Auction Access ID</Col>
              <Col align="right">{auctionAccessId}</Col>
            </Row>
            <Row>
              <Col>Status</Col>
              <Col align="right">{status}</Col>
            </Row>
          </div>
          {(() => {
            if ((data.auction.street || data.auction.city || data.auction.street || formattedZip) !== null) {
              return (
                <div className="col-xl-2 dealer-info">
                  <Row className="info-row">
                    <Col>Address</Col>
                    <Col align="right">{data.auction.street}<br/>{data.auction.city}<br/>{data.auction.state}, {formattedZip}</Col>
                  </Row>
                </div>
              )
            }})()}
          <SaleTimes saleTimes={saleTimes} />
        </Row>
      </Container>
      <div style={Style.bottomBorder} />
    </>
  )
}

export default Info;
