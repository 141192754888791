import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from 'react-bootstrap/Modal';
import { Spacing } from '../../../styles';
import { Formik } from 'formik';
import { Form } from './Form';
import { getServerSideErrors } from '../../../utils/errorHelpers';

const PAYMENT_ASSISTANCE = gql`
  mutation PaymentAssistance($id: ID!, $memo: String) {
    paymentAssistance(id: $id, memo: $memo) {
      flooredCar { id }
      errors { path, message }
    }
  }
`

const PaymentAssistance = ({handleClose, show, flooredCar}) => {
  const id = parseInt(flooredCar.id);

  const [serverSideErrors, setServerSideErrors] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  const [paymentAssistance] = useMutation(PAYMENT_ASSISTANCE, {
    onCompleted(values) {
      const serverErrors = getServerSideErrors(values);
      if (serverErrors) {
        setServerSideErrors(serverErrors)
      } else {
        handleClose();
        window.location.reload();
      }
    }
  });

  const handleSubmit = (values) => {
    setSubmitting(true);
    paymentAssistance({ variables: values })
  }

  const ModalBody = () => {
    return (
      <div style={Spacing.formWrapper}>
        { serverSideErrors &&
          <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
        }
        {isSubmitting && <span>Submitting...</span>}
        {!isSubmitting &&
          <Formik
            initialValues={{
              id,
              memo: ''
            }}
            onSubmit={handleSubmit}
          >
            <Form
              hanldeClose={handleClose}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </Formik>
        }
      </div>
    )
  };

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Payment Assistance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalBody />
      </Modal.Body>
    </Modal>
  )
}

export default PaymentAssistance;
