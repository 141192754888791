import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

const searchBarStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '-10px',
  marginTop: '10px',
  alignItems: 'flex-end'
}

const labelStyle = {
  marginRight: '10px',
}

export const SearchBar = ({ handleSearch, hasFilter }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (value === null) return;

    const timeout = setTimeout(() => {
      handleSearch(value);
    }, 300)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <div style={searchBarStyle}>
      <Form.Label style={labelStyle}>Search:</Form.Label>
      <Form.Control
        style={ hasFilter ? { width: '400px' } : { width: '20%' }}
        type="text"
        onChange={(value) => setValue(value.currentTarget.value)}
      />
    </div>
    )
}
