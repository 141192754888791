import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Transactions from '../components/Cars/Transactions';
import Accruals from '../components/Cars/Transactions/Accruals';
import Payments from '../components/Cars/Payments';
import Audits from '../components/Cars/Audits';
import Notes from '../components/Cars/Notes';
import NewNote from '../components/Notes/New';
import EditNote from '../components/Notes/Edit';
import Files from '../components/Cars/Files';
import NewTitleLog from '../components/Cars/TitleLogs/New';
import UpdateTitleLog from '../components/Cars/TitleLogs/Edit';
import EditCar from '../components/Cars/Edit';
import Deltas from '../components/Cars/Deltas';
import NewCarImage from '../components/Cars/CarImage/New';
import TitlePdf from '../components/Cars/TitleLogs/title/Show';
import WatermarkedTitlePdf from '../components/Cars/TitleLogs/watermarkedTitle/Show';

const FlooredCarRoutes = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path="/dealers/:id/cars/:carId/title-logs/:titleLogId/title-pdf/:id" component={TitlePdf} exact />
        <Route path="/dealers/:id/cars/:carId/title-logs/:titleLogId/watermarked-title-pdf/:id" component={WatermarkedTitlePdf} exact />
        <Route path="/dealers/:id/cars/:carId/transactions" component={Transactions} />
        <Route path="/dealers/:id/cars/:carId/accruals" component={Accruals} />
        <Route path="/dealers/:id/cars/:carId/payments" component={Payments} />
        <Route path="/dealers/:id/cars/:carId/audits" component={Audits} />
        <Route path="/dealers/:parentId/cars/:ownerId/notes" component={Notes} exact />
        <Route path="/dealers/:parentId/cars/:ownerId/notes/new" component={NewNote} />
        <Route path="/dealers/:parentId/cars/:ownerId/notes/:id/edit" component={EditNote} />
        <Route path="/dealers/:id/cars/:carId/files" component={Files} />
        <Route path="/dealers/:id/cars/:carId/title-logs/new" component={NewTitleLog} />
        <Route path="/dealers/:id/cars/:carId/title-logs/:titleLogId" component={UpdateTitleLog} />
        <Route path="/dealers/:id/cars/:carId/edit" component={EditCar} exact />
        <Route path="/dealers/:id/cars/:carId/deltas" component={Deltas} exact />
        <Route path="/dealers/:dealerId/cars/:carId/new-car-image" component={NewCarImage} />
      </Switch>
    </>
  )
}

export default FlooredCarRoutes;
