import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AccountGroups from '../components/Financial/AccountGroups';
import Reconciles from '../components/Financial/Reconciles';
import NewReconcile from '../components/Financial/Reconciles/New';
import EditReconcile from '../components/Financial/Reconciles/Edit';
import ShowReconcile from '../components/Financial/Reconciles/Show';
import Accounts from '../components/Financial/Accounts';
import FlooringCore from '../components/Financial/FlooringCore';
import OperationsTransactions from
  '../components/Financial/OperationsTransactions';
import Accruals from '../components/Financial/Accruals';
import TransactionLines from '../components/Transactions/Lines';
import ScheduledAchs from '../components/Financial/ScheduledAchs';
import ScheduledAch from '../components/Financial/ScheduledAchs/Show';
import AchBatches from '../components/Financial/AchBatches';
import ShowAchBatch from '../components/Financial/AchBatches/Show';
import DecryptAchBatch from '../components/Financial/AchBatches/Decrypt';
import DecryptAchToken from '../components/Financial/DecryptAchToken';
import NewAccountGroup from '../components/Financial/AccountGroups/New';
import EditAccountGroup from '../components/Financial/AccountGroups/Edit';
import ShowAccountGroup from '../components/Financial/AccountGroups/Show';
import NewAccount from '../components/Financial/Accounts/New';
import EditAccount from '../components/Financial/Accounts/Edit';
import ShowAccount from '../components/Financial/Accounts/Show';
import Departments from '../components/Financial/Departments';
import NewDepartment from '../components/Financial/Departments/New';
import EditDepartment from '../components/Financial/Departments/Edit';
import FiscalMonths from '../components/Financial/FiscalMonths';
import NewFiscalMonth from '../components/Financial/FiscalMonths/New';
import EditFiscalMonth from '../components/Financial/FiscalMonths/Edit';
import Users from '../components/System/Users';
import NewUser from '../components/System/Users/New';
import EditUser from '../components/System/Users/Edit';
import UserDeltas from '../components/System/Users/Deltas';
import UserLogs from '../components/System/Users/Logs';
import UserLogsAll from '../components/System/Users/Logs/All';
import Markets from '../components/System/Markets';
import EditMarket from '../components/System/Markets/Edit';
import NewMarket from '../components/System/Markets/New';
import AdminDocuments from '../components/System/AdminDocuments';
import NewAdminDocument from '../components/System/AdminDocuments/New';
import EditAdminDocument from '../components/System/AdminDocuments/Edit';
import Branches from '../components/System/Branches';
import NewBranch from '../components/System/Branches/New';
import EditBranch from '../components/System/Branches/Edit';
import CustomNotifications from '../components/System/CustomNotifications';
import NewCustomNotification from '../components/System/CustomNotifications/new';
import KPIsInput from '../components/System/KPIs/Input';
import KPIsInputYieldGoals from '../components/System/KPIs/InputYieldGoals';
import Roles from '../components/System/Roles';
import EditRole from '../components/System/Roles/Edit';
import NewRole from '../components/System/Roles/New';
import Vendors from '../components/Vendors';
import ShowVendor from '../components/Vendors/Show';
import NewVendor from '../components/Vendors/New';
import DealerTable from '../components/Dealers';
import NewDealer from '../components/Dealers/New';
import Dealer from '../components/Dealers/Dealer';
import MarketsMap from '../components/System/Markets/Map';
import TermPrograms from '../components/System/TermPrograms';
import NewTermProgram from '../components/System/TermPrograms/New';
import TermProgram from '../components/System/TermPrograms/TermProgram';
import FeePrograms from '../components/System/FeePrograms';
import NewFeeProgram from '../components/System/FeePrograms/New';
import FeeProgram from '../components/System/FeePrograms/FeeProgram';
import AutomatedFees from '../components/System/AutomatedFees';
import NewAutomatedFee from '../components/System/AutomatedFees/New';
import AutomatedFee from '../components/System/AutomatedFees/AutomatedFee';
import FeeTxns from '../components/Fees/Transactions';
import RatePrograms from '../components/System/RatePrograms';
import NewRateProgram from '../components/System/RatePrograms/New';
import RateProgram from '../components/System/RatePrograms/RateProgram';
import RateProgramDeltas from '../components/System/RatePrograms/Deltas';
import NewRateProgramChange from
  '../components/System/RatePrograms/RateProgramChanges/New';
import UpdateRateProgramChange from
  '../components/System/RatePrograms/RateProgramChanges/Edit';
import RateProgramChangeDeltas from
  '../components/System/RatePrograms/RateProgramChanges/Deltas';
import BaseRates from '../components/System/BaseRates';
import BaseRateDeltas from '../components/System/BaseRates/Deltas';
import BaseRate from '../components/System/BaseRates/BaseRate';
import NewBaseRate from '../components/System/BaseRates/New';
import NewBaseRateChange from
  '../components/System/BaseRates/BaseRateChanges/New';
import EditBaseRateChange from
    '../components/System/BaseRates/BaseRateChanges/Edit';
import BaseRateChangeDeltas from
  '../components/System/BaseRates/BaseRateChanges/Deltas';
import Auctions from '../components/Auctions';
import NewAuction from '../components/Auctions/New';
import ShowAuction from '../components/Auctions/Show';
import AuctionAvailabilities from '../components/AuctionAvailabilities';
import DealerStatuses from '../components/System/DealerStatuses';
import NewDealerStatus from '../components/System/DealerStatuses/New';
import DealerStatus from '../components/System/DealerStatuses/DealerStatus';
import DealerCategories from '../components/System/DealerCategories';
import NewDealerCategory from '../components/System/DealerCategories/New';
import DealerCategory from
  '../components/System/DealerCategories/DealerCategory';
import ApprovedSources from '../components/ApprovedSources';
import NewApprovedSource from '../components/ApprovedSources/New';
import ShowApprovedSource from '../components/ApprovedSources/Show';
import Logout from '../components/Logout';
import NewUserNote from '../components/Notes/New';
import EditUserNote from '../components/Notes/Edit';
import ChangePassword from '../components/Profile/ChangePassword';
import EditProfile from '../components/Profile/Edit';
import TitleStatuses from '../components/System/TitleStatuses';
import NewTitleStatus from '../components/System/TitleStatuses/New';
import UpdateTitleStatus from '../components/System/TitleStatuses/Edit';
import CollateralCoverages from '../components/System/CollateralCoverages';
import NewCollateralCoverage from
  '../components/System/CollateralCoverages/New';
import EditCollateralCoverage from
  '../components/System/CollateralCoverages/Edit';
import NewCollateralCoverageChange from
  '../components/System/CollateralCoverages/Changes/New';
import EditCollateralCoverageChange from
  '../components/System/CollateralCoverages/Changes/Edit';
import PaymentTerms from '../components/System/PaymentTerms';
import NewPaymentTerm from '../components/System/PaymentTerms/New';
import UpdatePaymentTerm from '../components/System/PaymentTerms/Edit';
import Settings from '../components/System/Settings/Settings';
import Home from '../components/Dashboard/Home';
import KPIs from '../components/Dashboard/KPIs';
import Notifications from '../components/Notifications';
import AllReports from '../components/Reports/AllReports';
import Reports from '../components/Reports';
import Cars from '../components/Reports/Cars';
import StatementOfAccount from '../components/Reports/StatementOfAccount';
import CarsByTitleStatus from '../components/Reports/CarsByTitleStatus';
import Report from '../components/Reports/Report';
import InsuranceStates from '../components/System/InsuranceStates';
import EditInsuranceState from '../components/System/InsuranceStates/Edit';
import ShowTransaction from '../components/Transactions/Show';
import DealerPrograms from '../components/DealerPrograms';
import NewDealerProgram from '../components/DealerPrograms/New';
import EditDealerProgram from '../components/DealerPrograms/Edit';
import DealerProgramDeltas from '../components/DealerPrograms/Deltas';
import PendingFPRs from '../components/AuctionAccess/FPRs/Pending'
import AllFPRs from '../components/AuctionAccess/FPRs'
import RejectedFPRs from '../components/AuctionAccess/FPRs/Rejected'
import UpdateMobile from '../components/Profile/UpdateMobile';
import YieldCalculator from '../components/Dealers/YieldCalculator';
import TroubleTicket from '../components/TroubleTicket/New';
import MassUpdates from '../components/MassUpdates';
import NewJournalEntry from '../components/Financial/JournalEntries/New';
import NewPayment from '../components/Financial/Payments/New';
import NewReceiveFund from '../components/Financial/ReceiveFunds/New'
import Audits from '../components/Audits';
import ShipmentTable from '../components/Shipments';
import UnshippedTitles from '../components/UnshippedTitles';
import SetupUnshippedTitles from '../components/SetupUnshippedTitles';
import NewBill from '../components/Financial/Bills/New';
import NewVendorCredit from '../components/Financial/VendorCredits/New';
import UnpaidBills from '../components/Financial/UnpaidBills';
import EditTransaction from '../components/Financial/Transactions/Edit';
import DashboardTableRoutes from '../components/Dashboard/Tables/Routes';
import MarketStats from '../components/Dashboard/MarketStats';
import DealerPaymentsReport from '../components/Dealers/PaymentsReport';
import PortalReporting from '../components/Dealers/Reporting/Show';
import PortalReportingSingleCar from '../components/Dealers/Reporting/SingleCarAnyDealer';

const Routes = () => {
  return (
    <>
      <Helmet titleTemplate="XL Funding | %s" defaultTitle="XL Funding">
        <meta charSet="utf-8" />
      </Helmet>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/dashboard/kpis" component={KPIs} />
        <Route path="/dashboard/market-stats" component={MarketStats} />
        <Route path="/dashboard">
          <DashboardTableRoutes />
        </Route>
        <Route path="/audits" component={Audits} exact />
        <Route path="/notifications" component={Notifications} exact />
        <Route path="/all-reports" component={AllReports} exact />
        <Route path="/reports" component={Reports} exact />
        <Route path="/reports/cars" component={Cars} exact />
        <Route path="/reports/cars/:comingDue" component={Cars} exact />
        <Route
          path="/reports/statement-of-account"
          component={StatementOfAccount}
          exact
        />
        <Route
          path="/reports/cars-by-title-status"
          component={CarsByTitleStatus}
          exact
        />
        <Route path="/reports/report/:report" component={Report} />
        <Route
          path="/system/users"
          title="XL Funding - Users"
          component={Users}
          exact
        />
        <Route path="/system/users/new" component={NewUser} />
        <Route path="/system/users/:ownerId/edit" component={EditUser} />
        <Route path="/system/users/:ownerId/deltas" component={UserDeltas} />
        <Route path="/system/users/:ownerId/notes" component={EditUser} exact />
        <Route
          path="/system/users/:ownerId/notes/new"
          component={NewUserNote}
        />
        <Route
          path="/system/users/:ownerId/notes/:id/edit"
          component={EditUserNote}
        />
        <Route path="/system/users/:ownerId/logs" component={UserLogs} />
        <Route path="/system/userlogs" component={UserLogsAll} />
        <Route path="/profile/change-password" component={ChangePassword} />
        <Route path="/profile/update-mobile" component={UpdateMobile} />
        <Route path="/profile/edit" component={EditProfile} />
        <Route path="/system/markets" component={Markets} exact />
        <Route path="/system/markets/new" component={NewMarket} exact />
        <Route path="/system/markets/:id/edit" component={EditMarket} />
        <Route
          path="/system/admin-documents"
          component={AdminDocuments}
          exact
        />
        <Route
          path="/system/admin-documents/new"
          component={NewAdminDocument}
          exact
        />
        <Route
          path="/system/admin-documents/:id/edit"
          component={EditAdminDocument}
        />
        <Route path="/system/branches" component={Branches} exact />
        <Route path="/system/branches/new" component={NewBranch} exact />
        <Route path="/system/branches/:id/edit" component={EditBranch} />
        <Route
          path="/system/custom-notifications"
          component={CustomNotifications}
          exact
        />
        <Route
          path="/system/custom-notifications/new"
          component={NewCustomNotification}
          exact
        />
        <Route path="/system/kpis/input" component={KPIsInput} />
        <Route path="/system/kpis/yield" component={KPIsInputYieldGoals} />
        <Route path="/system/roles" component={Roles} exact />
        <Route path="/system/roles/new" component={NewRole} exact />
        <Route path="/system/roles/:id/edit" component={EditRole} />
        <Route path="/scheduled-achs-in" component={() => <ScheduledAchs direction="In" />} exact />
        <Route path="/dealers" component={DealerTable} exact />
        <Route path="/scheduled-achs-in" component={() => <ScheduledAchs direction="In" />} exact />
        <Route path="/dealers/new" component={NewDealer} exact />
        <Route path="/dealers/:id" component={Dealer} />
        <Route path="/yield-calculator" component={YieldCalculator} exact />
        <Route path="/auctions" component={Auctions} exact />
        <Route path="/auctions/new" component={NewAuction} exact />
        <Route path="/auctions/:id" component={ShowAuction} />
        <Route
          path="/auction-availabilities"
          component={AuctionAvailabilities}
          exact
        />
        <Route path="/auction-access/fprs/pending" component={PendingFPRs} />
        <Route path="/auction-access/fprs/all" component={AllFPRs} />
        <Route path="/auction-access/fprs/rejected" component={RejectedFPRs} />
        <Route path="/system/markets/:id/map" component={MarketsMap} />
        <Route path="/system/term-programs" component={TermPrograms} exact />
        <Route path="/system/term-programs/new" component={NewTermProgram} />
        <Route path="/system/term-programs/:id" component={TermProgram} />
        <Route
          path="/system/dealer-programs"
          component={DealerPrograms}
          exact
        />
        <Route
          path="/system/dealer-programs/new"
          component={NewDealerProgram}
        />
        <Route
          path="/system/dealer-programs/:id"
          component={EditDealerProgram}
          exact
        />
        <Route
          path="/system/dealer-programs/:id/edit"
          component={EditDealerProgram}
          exact
        />
        <Route
          path="/system/dealer-programs/:id/deltas"
          component={DealerProgramDeltas}
        />
        <Route path="/vendors" component={Vendors} exact />
        <Route path="/vendors/new" component={NewVendor} />
        <Route path="/vendors/:id" component={ShowVendor} />
        <Route path="/system/fee-programs" component={FeePrograms} exact />
        <Route path="/system/fee-programs/new" component={NewFeeProgram} />
        <Route path="/system/fee-programs/:id" component={FeeProgram} />
        <Route path="/system/automated-fees" component={AutomatedFees} exact />
        <Route path="/system/automated-fees/new" component={NewAutomatedFee} />
        <Route path="/system/automated-fees/:id/edit" component={AutomatedFee} />
        <Route path="/fee/:id/transactions" component={FeeTxns} />
        <Route path="/system/rate-programs" component={RatePrograms} exact />
        <Route path="/system/rate-programs/new" component={NewRateProgram} />
        <Route
          path="/system/rate-programs/:id"
          component={RateProgram}
          exact
        />
        <Route
          path="/system/rate-programs/:id/edit"
          component={RateProgram}
          exact
        />
        <Route
          path="/system/rate-programs/:id/deltas"
          component={RateProgramDeltas}
        />
        <Route
          path="/system/rate-programs/:id/changes/new"
          component={NewRateProgramChange}
        />
        <Route
          path="/system/rate-programs/:id/changes/:changeId/edit"
          component={UpdateRateProgramChange}
          exact
        />
        <Route
          path="/system/rate-programs/:id/changes/:changeId/deltas"
          component={RateProgramChangeDeltas}
        />
        <Route path="/system/base-rates" component={BaseRates} exact />
        <Route path="/system/base-rates/new" component={NewBaseRate} />
        <Route path="/system/base-rates/:id" component={BaseRate} exact />
        <Route path="/system/base-rates/:id/edit" component={BaseRate} exact />
        <Route
          path="/system/base-rates/:id/deltas"
          component={BaseRateDeltas}
        />
        <Route
          path="/system/base-rates/:id/changes/new"
          component={NewBaseRateChange}
        />
        <Route
          path="/system/base-rates/:id/changes/:changeId/edit"
          component={EditBaseRateChange}
          exact
        />
        <Route
          path="/system/base-rates/:id/changes/:changeId/deltas"
          component={BaseRateChangeDeltas}
        />
        <Route
          path="/system/dealer-statuses"
          component={DealerStatuses}
          exact
        />
        <Route
          path="/system/dealer-statuses/new"
          component={NewDealerStatus}
        />
        <Route path="/system/dealer-statuses/:id" component={DealerStatus} />
        <Route
          path="/system/dealer-categories/new"
          component={NewDealerCategory}
        />
        <Route
          path="/system/dealer-categories/:id"
          component={DealerCategory}
        />
        <Route
          path="/system/dealer-categories"
          component={DealerCategories}
          exact
        />
        <Route path="/approved-sources" component={ApprovedSources} exact />
        <Route path="/approved-sources/new" component={NewApprovedSource} />
        <Route path="/approved-sources/:id" component={ShowApprovedSource} />
        <Route
          path="/collateral-coverages/new"
          component={NewCollateralCoverage}
        />
        <Route path="/system/title-statuses" component={TitleStatuses} exact />
        <Route path="/system/title-statuses/new" component={NewTitleStatus} />
        <Route
          path="/system/title-statuses/:id"
          component={UpdateTitleStatus}
        />
        <Route
          path="/system/collateral-coverages"
          component={CollateralCoverages}
          exact
        />
        <Route
          path="/system/collateral-coverages/new"
          component={NewCollateralCoverage}
        />
        <Route
          path="/system/collateral-coverages/:id"
          component={EditCollateralCoverage}
          exact
        />
        <Route
          path="/system/collateral-coverages/:id/edit"
          component={EditCollateralCoverage}
          exact
        />
        <Route
          path="/system/collateral-coverages/:id/changes/new"
          component={NewCollateralCoverageChange}
        />
        <Route
          path="/system/collateral-coverages/:id/changes/:changeId"
          component={EditCollateralCoverageChange}
        />
        <Route
          path="/system/insurance-states"
          component={InsuranceStates}
          exact
        />
        <Route
          path="/system/insurance-states/:id/edit"
          component={EditInsuranceState}
          exact
        />
        <Route path="/system/payment-terms" component={PaymentTerms} exact/>
        <Route path="/system/payment-terms/new" component={NewPaymentTerm} />
        <Route
          path="/system/payment-terms/:id"
          component={UpdatePaymentTerm}
        />
        <Route path="/system/settings" component={Settings} exact />
        <Route path="/financial/reconciles" component={Reconciles} exact />
        <Route
          path="/financial/reconciles/new"
          component={NewReconcile}
          exact
        />
        <Route
          path="/financial/reconciles/:id"
          component={ShowReconcile}
          exact
        />
        <Route
          path="/financial/reconciles/:id/edit"
          component={EditReconcile}
          exact
        />
        <Route path="/financial/accounts" component={Accounts} exact />
        <Route
          path="/financial/account-groups"
          component={AccountGroups}
          exact
        />
        <Route path="/financial/transactions" component={FlooringCore} exact />
        <Route
          path="/financial/operations-transactions"
          component={OperationsTransactions}
          exact
        />
        <Route path="/financial/accruals" component={Accruals} exact />
        <Route
          path="/financial/account-groups/new"
          component={NewAccountGroup}
          exact
        />
        <Route
          path="/financial/account-groups/:id"
          component={ShowAccountGroup}
          exact
        />
        <Route
          path="/financial/account-groups/:id/edit"
          component={EditAccountGroup}
          exact
        />
        <Route path="/financial/transactions/lines"
          component={TransactionLines}
          exact
        />
        <Route path="/financial/accounts/new" component={NewAccount} exact />
        <Route path="/financial/accounts/:id" component={ShowAccount} exact />
        <Route
          path="/financial/accounts/:id/edit"
          component={EditAccount}
          exact
        />
        <Route
          path="/financial/scheduled-achs"
          component={() => <ScheduledAchs direction="Out" />} exact
        />
        <Route
          path="/financial/scheduled-achs-in"
          component={() => <ScheduledAchs direction="In" />}
          exact
        />
        <Route
          path="/financial/scheduled-achs/:id"
          component={ScheduledAch}
          exact
        />
        <Route path="/financial/ach-batches" component={AchBatches} exact />
        <Route
          path="/financial/ach-batches-decrypt/:id"
          component={DecryptAchBatch}
        />
        <Route path="/financial/ach-batches/:id" component={ShowAchBatch} />
        <Route
          path="/financial/decrypt-ach-token"
          component={DecryptAchToken}
        />
        <Route path="/financial/departments" component={Departments} exact />
        <Route path="/financial/departments/new" component={NewDepartment} exact />
        <Route path="/financial/departments/:id/edit" component={EditDepartment} exact />
        <Route
          path="/financial/fiscal-month-calendar"
          component={FiscalMonths}
        />
        <Route
          path="/financial/fiscal-months/new"
          component={NewFiscalMonth}
          exact
        />
        <Route
          path="/financial/fiscal-months/:id/edit"
          component={EditFiscalMonth}
          exact
        />
        <Route path="/mass-updates" component={MassUpdates} />
        <Route
          path="/financial/journal-entries/new"
          component={NewJournalEntry}
        />
        <Route path="/financial/payments/new" component={NewPayment} />
        <Route
          path="/financial/receive-funds/new"
          component={NewReceiveFund}
        />
        <Route path="/financial/bills/new" component={NewBill} />
        <Route
          path="/financial/vendor-credits/new"
          component={NewVendorCredit}
        />
        <Route path="/financial/unpaid-bills" component={UnpaidBills} />
        <Route path="/logout" component={Logout} />
        <Route path="/trouble-ticket" component={TroubleTicket} />
        <Route path="/shipments" component={ShipmentTable} exact />
        <Route path="/unshipped-titles" component={UnshippedTitles} exact />
        <Route
          path="/setup-unshipped-titles"
          component={SetupUnshippedTitles}
          exact
        />
        <Route path="/transactions/:id" component={ShowTransaction} exact />
        <Route
          path="/transactions/:id/edit"
          component={EditTransaction}
          exact
        />
        <Route
          path="/reports/dealer-payments"
          component={DealerPaymentsReport}
          exact
        />
        <Route
          path="/portal-report/single-car"
          component={PortalReportingSingleCar}
          exact
        />
        <Route
          path="/portal-report/:type/:id"
          component={PortalReporting}
          exact
        />
      </Switch>
    </>
  );
};

export default Routes;
