import React, { useState } from 'react';
import DataTable from '@preflighttech/preflight-tables';
import { SHIPMENTS_WITH_COUNT } from './Queries';
import { columnDefinitions } from './columnDefinitions';
import { useQuery } from '@apollo/client';
import DataState from '../DataState';
import { Helmet } from 'react-helmet';
import { Typography, preflightTableStyles } from 'components/src/styles';
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import { ExportToExcelAlert } from 'components/src/elements/BasicAlert';
import Filter from 'components/src/elements/Filter';
import { rest } from 'components/src/utils/rest';
import { View } from 'react-native';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';

const ShipmentTable = () => {
  const [customAlert, displayCustomAlert] = useState(false);

  const [filterOptions, setFilterOptions] = useState();
  const [filters, setFilters] = useState({});
  const [settings, setSettings] = useState({});
  const [refetch, setRefetch] = useState();

  const queryVariables = {filterColumns: [], filterNames: []};

  const columns = columnDefinitions({ refetch });

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);
  }

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      queryVariables['filterColumns'].push(key);
      queryVariables['filterNames'].push(value);
    }
  });

  const handleExportToExcel = () => {
    const filterData = {filterColumns: [], filterNames: []};

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        filterData['filterColumns'].push(key);
        filterData['filterNames'].push(value);
      }
    });

    rest({
      path: 'report/shipments',
      params: {
        search: settings.search,
        current_filters: filterData,
        sort: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.sort,
      },
    }).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <ExportToExcel handleExportToExcel={handleExportToExcel} />
      <Filter clearFilters={() => setFilters({})}>
        <Filter.Select
          columnKey='type'
          label='Type'
          options={['Titles', 'PMSIs']}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.Select
          columnKey='shipment_at'
          label='Shipment?'
          options={['Shipped', 'Not Shipped']}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.MultiSelect
          columnKey='ship_to'
          label='Ship To'
          options={filterOptions?.shipTos}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.MultiSelect
          columnKey='shipment_by'
          label='Created By'
          options={filterOptions?.users}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.DateRange
          columnKey="shipment_at"
          label="Shipment Date"
          filters={filters}
          setFilter={setFilter}
        />
      </Filter>
      <ResetSettings />
    </View>
  )

  return (
    <>
      { customAlert && <ExportToExcelAlert /> }
      <Helmet>
        <title>Shipments</title>
      </Helmet>
      <div style={Typography.pageTitle}>Shipments</div>
      <DataTable.Gql
        query={SHIPMENTS_WITH_COUNT}
        queryName="shipmentsWithCount"
        queryVariables={queryVariables}
        columns={columns}
        buttons={buttons}
        pageLength={10}
        dataUpdated={data => setFilterOptions(data?.shipmentsWithCount)}
        setSettings={setSettings}
        setRefetch={setRefetch}
        htmlTable
        styles={preflightTableStyles}
      />
    </>
  )
}

export default ShipmentTable;
