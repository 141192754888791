import React, { useEffect, useState } from 'react';
import DataTables from '@preflighttech/preflight-tables';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@apollo/client';

import DataState from 'components/src/components/DataState';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import { setDate } from 'components/src/utils/dateHelpers';
import { preflightTableStyles } from 'components/src/styles';
import { useAlert } from 'components/src/hooks/useAlert';
import { setAlertData } from 'components/src/utils/setAlertData';
import BasicAlert from 'components/src/elements/BasicAlert';

import { CUSTOM_NOTIFICATIONS } from './queries';

const CustomNotifications = props => {
  const { loading, error, data } = useQuery(CUSTOM_NOTIFICATIONS);
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'createdBy',
      label: 'Created By',
      value: ({ value }) => value?.fullName,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sort: 'desc',
      content: ({ value }) => setDate(value),
    },
    {
      key: 'body',
      label: 'Notification Text',
    },
    {
      key: 'link',
      label: 'Link',
    },
    {
      key: 'receivers',
      label: 'Receivers',
      value: ({ entry }) => (
        [
          (entry.dealers ? 'Dealers' : null),
          (entry.employees ? 'Employees' : null)
        ].filter(item => item).join(' & ')
      ),
    },
    {
      key: 'markets',
      label: 'Markets',
      value: ({ value }) => (
        value.map(market => market.name).join(', ') || 'ALL'
      ),
    },
  ]

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="Custom Notification" type={alertType} />
      )}

      <ResourcesHeader
        title="Past Custom Notifications"
        prefix="/system/custom-notifications"
        resourceType="CustomNotification"
      />

      <DataTables.Simple
        data={data.customNotifications}
        columns={columns}
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default CustomNotifications;
