import styled from '@emotion/styled';

export const Card = styled.div`
  background-color: #e0e0e0;
  border-radius: 20px 6px;
  margin-bottom: 10px;
  min-height: 140px;
`;

export const TaskCard = styled.div`
  padding-top: 6px;
  background-color: #e0e0e0;
  border-radius: 20px 6px;
  margin-bottom: 10px;
  min-height: 80px;
`;

export const Head = styled.div`
  background-color: #2b6448;
  border-radius: 20px 6px 0 0;
  color: white;
  padding: 6px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const Data = styled.div`
  margin: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  font-weight: 700;
  margin-right: 12px;
`;

export const Value = styled.div`
  font-weight: 700;
`;
