import React, { useEffect, useState } from 'react';
import { gql, useQuery } from "@apollo/client";
import DataState from "../../DataState";
import { ErrorMessage } from '../../../elements/ErrorMessage';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import { inputStyle } from './index';
import Select from 'react-select';
import { ACCOUNTS } from './Queries';

const AccountSelect = () => {
  const { setValues, setFieldValue, values } = useFormikContext();
  const { loading, error, data } = useQuery(ACCOUNTS);

  useEffect(() => {
    if (values.externalPaymentMethod === "Check") {
      setFieldValue('advanceAccountId', '3')
    }
  }, [values.externalPaymentMethod])

  useEffect(() => {
    if (values.externalPaymentMethod === "ACH") {
      setFieldValue('advanceAccountId', '1')
    }
  }, [values.externalPaymentMethod])

  useEffect(() => {
    if (values.externalPaymentMethod === "Wire") {
      setFieldValue('advanceAccountId', '1')
    }
  }, [values.externalPaymentMethod])

  if (loading || !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  const formattedAccounts = accounts.map((account) => {
    return { value: account.id, label: `${account.gl} - ${account.name}` }
  })

  const handleAccountChange = (selected) => {
    values.advanceAccountId = parseInt(selected.value)

    setValues(values)
  }

  return (
    <div>
      <BasicForm.StyledLabel>
        Payment Made from Bank Account
      </BasicForm.StyledLabel>
        <Select
          options={formattedAccounts}
          isMulti={false}
          onChange={handleAccountChange}
        />
    </div>
  )
}

export default AccountSelect;
