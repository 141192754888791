import React, { useEffect, useRef } from 'react';
import * as BasicForm from '../../../elements/forms';
import { useFormikContext } from 'formik';
import { AdvanceTypeOptions } from './advanceTypeOptions';
import { ExternalTypeOptions } from './externalTypeOptions';
import OnAccount from './onAccountOptions';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import { backgroundStyle } from '../Style';
import { divStyle } from './Style';

export const AdvanceType = () => {
  const { setFieldValue, values, values: {advanceType} } = useFormikContext();
  const advanceTypeRef = useRef('source')
  let displayAccountSelect = false;

  // for some reason initialValues doesn't recognize an initial advanceType value
  // so this is needed
  useEffect(() => {
    setFieldValue('advanceType', 'source')
  }, []);

  useEffect(() => {
    if (advanceTypeRef.current !== values.advanceType
      && values.advanceType !== ''
    ) {
      setFieldValue('sourceId', ''),
      setFieldValue('sourceType', '')
      setFieldValue('externalPaymentMethod', '')
      setFieldValue('advanceTo', '')
      setFieldValue('advanceReference', '')
      setFieldValue('bankAccountId', '')
      setFieldValue('advanceAccountId', '')
      advanceTypeRef.current = values.advanceType
    }

    if (advanceType === 'on_account') setFieldValue('sourceType', 'Dealer');

  }, [advanceType])

  return (
    <div className="container" style={backgroundStyle}>
      <div style={divStyle}>
        <BasicForm.StyledLabel>Select Payment Type:</BasicForm.StyledLabel>
        <BasicForm.Radio
          name="advanceType"
          type="radio"
          label="Initiate an ACH Source From The System"
          value="source"
          checked={values.advanceType === 'source'}
          />
        <BasicForm.Radio
          name="advanceType"
          type="radio"
          label="Manual Payment Method"
          value="external"
          />
        <BasicForm.Radio
          name="advanceType"
          type="radio"
          label="Advance to Account"
          value="on_account"
          />
        <BasicForm.InlineStyledErrorMessage>
          <ErrorMessage name='advanceType'/>
        </BasicForm.InlineStyledErrorMessage>
      </div>
      { advanceType && advanceType === 'source' &&
        <AdvanceTypeOptions />
      }
      { advanceType && advanceType === 'external' &&
        <ExternalTypeOptions />
      }
      { advanceType && advanceType === 'on_account' &&
        <OnAccount />
      }
    </div>
  )
}
