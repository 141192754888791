import { gql } from '@apollo/client'
import {
  AUDIT_CAR_FIELDS
} from 'components/src/components/Dealers/Audits/queries';
import { DEALER_MAIN_FIELDS } from 'components/src/components/Dealers/Queries';

export const AUDIT_CARS = gql`
  query UnverifiedAuditCars (
    $filterColumns: [String!]
    $filterNames: [JSON!]
    $scopes: [String!]
  ) {
    auditCars(
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: $scopes
    ) {
      ${AUDIT_CAR_FIELDS}
      flooredCar {
        id
        dealer {
          ${DEALER_MAIN_FIELDS}
        }
      }
    }
  }
`;
