import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { Spacing } from '../styles';
import { currency } from '../utils/stringHelpers';
import { DateHelpers } from '../utils';

const BasicAlert = (props) => {
  if (!props.componentType) return null;

  let type;
  if (props.type === 'update' || !props.type) {
    type = 'updated'
  } else {
    type = props.type
  }

  return (
    <Alert variant='success' style={Spacing.alerts}>
      This {props.componentType} has been {type}.
    </Alert>
  );
}

export const RepoAlert = ({ location, repoOn, repoSoldOn }) => {
  return (
    <Alert variant='danger'>
      This car has been marked repo on {DateHelpers.setDate(repoOn)}.
      Its current location is: {location}.
      {repoSoldOn && ' This car has been marked Repo Sold.'}
    </Alert>
  )
}

export const ArbitrationAlert = ({ reason, name }) => {
  return (
    <Alert variant='danger'>
      This car has been marked arbitrated by {name}.  The reason for arbitration was: {reason}.
    </Alert>
  )
}

export const SotAlert = ({ date, name }) => {
  return (
    <Alert variant='danger'>
      This car has been marked SOT by {name}.  The SOT date was {DateHelpers.renderDate(date)}.
    </Alert>
  )
}

export const ExportToExcelAlert = () => {
  return (
    <Alert variant="success" style={Spacing.alerts}>
      Your request is being processed. We will send you a notification when it’s finished.
    </Alert>
  )
}

export const ExportToPdfAlert = () => {
  return (
    <Alert variant="success" style={Spacing.alerts}>
      Your request is being processed. Your report will begin downloading shortly.
    </Alert>
  )
}

export const OutOfMarketAlert = ({ resource }) => {
  return (
    <Alert variant="danger" style={Spacing.alerts}>
      {resource} was outside of your current market, you've been re-directed to
        the {resource.toLowerCase()}s index.
    </Alert>
  )
}

export const UnauthorizedAlert = ({ resource }) => {
  return (
    <Alert variant="danger" style={Spacing.alerts}>
      You are not authorized to view {resource}.
    </Alert>
  )
}

export const ReportProcessingAlert = () => {
  return (
    <Alert variant="success" style={Spacing.alerts}>
      Your report is being created, you will be notified when it is complete.
    </Alert>
  )
}

export const StatementOfAccountAlert = () => {
  return (
    <Alert variant="success" style={Spacing.alerts}>
      Statement of Account has been sent to selected dealers.
    </Alert>
  )
}

export const AchAlert = ({ achButton, date, amount, carAmount, type }) => {
  return (
    <Alert variant='danger'>
      This car has a Pending ACH.
      The payment is a {type} for {currency(carAmount)}&nbsp;
      (Total including other cars and fees: {currency(amount)})
      scheduled for {DateHelpers.renderDate(date)}.
      Click {achButton} to Cancel.
    </Alert>
  )
}

export const CustomAlert = ({ message, variant }) => {
  return (
    <Alert variant={variant || 'danger'}>{message}</Alert>
  )
}

export default BasicAlert;
