import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import * as Buttons from '../../../elements/buttons';
import * as Formatting from '../../../styles/formatting';
import Modal from 'react-bootstrap/Modal';
import { getServerSideErrors } from '../../../utils/errorHelpers';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors';
import { Spacing } from '../../../styles';

const UPDATE_DEALER_PROGRAM_FEES = gql`
  mutation UpdateDealerProgramFees($id: ID!) {
    updateDealerProgramFees(id: $id) {
      flooredCar { id }
      errors { path, message }
    }
  }
`;

const UpdateDealerProgramFeesModal = ({show, handleClose, name, flooredCar}) => {
  const id = flooredCar.id;

  const [serverSideErrors, setServerSideErrors] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  const [updateDealerProgramFees] = useMutation(UPDATE_DEALER_PROGRAM_FEES, {
    onCompleted(values) {
      const serverErrors = getServerSideErrors(values);
      if (serverErrors) {
        setServerSideErrors(serverErrors);
      } else {
        handleClose();
        window.location.reload();
      }
    }
  });

  const handleSubmit = (values) => {
    setSubmitting(true);
    updateDealerProgramFees({ variables: values });
  };

  const ModalBody = () => {
    return (
      <div style={Spacing.formWrapper}>
        Submitting will mark any current term fees from the dealer program
        as removed and zero out their balance. Then fees from the current dealer
        program will be added and earned through today. It is important to
        review the Car's fees after submitting.
        { serverSideErrors &&
          <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
        }
        {isSubmitting && <span>Submitting...</span>}
        {!isSubmitting &&
          <>
            <Buttons.ModalCancel
              type="cancel"
              text="Cancel"
              handleClose={handleClose}
            />
            <Buttons.Standard
              type="submit"
              onClick={() => handleSubmit({id})}
              text="Submit"
            />
          </>
        }
      </div>
    )
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`Update Dealer Program Fees ${name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalBody />
      </Modal.Body>
    </Modal>
  )
}

export default UpdateDealerProgramFeesModal;
