import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EditApprovedSource from '../components/ApprovedSources/Edit';
import Contacts from '../components/ApprovedSources/Contacts';
import NewContact from '../components/ApprovedSources/Contacts/New';
import EditContact from '../components/ApprovedSources/Contacts/Edit';
import Notes from '../components/ApprovedSources/Notes';
import NewNote from '../components/Notes/New';
import EditNote from '../components/Notes/Edit';
import BankAccounts from '../components/ApprovedSources/BankAccounts/index';
import NewBankAccount from '../components/ApprovedSources/BankAccounts/New';
import Cars from '../components/ApprovedSources/Cars';
import AuctionTokens from '../components/ApprovedSources/AuctionTokens';

const ApprovedSourceRoutes = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path="/approved-sources/:id/edit" component={EditApprovedSource} />
        <Route path="/approved-sources/:id/contacts" component={Contacts} exact/>
        <Route path="/approved-sources/:id/contacts/new" component={NewContact} />
        <Route path="/approved-sources/:id/contacts/:contactId" component={EditContact} />
        <Route path="/approved-sources/:id/notes" component={Notes} exact />
        <Route path="/approved-sources/:id/notes/new" component={NewNote} exact />
        <Route path="/approved-sources/:ownerId/notes/:id/edit" component={EditNote} exact />
        <Route path="/approved-sources/:id/bank-accounts" component={BankAccounts} exact />
        <Route path="/approved-sources/:id/bank-accounts/new" component={NewBankAccount} />
        <Route path="/approved-sources/:id/cars" component={Cars} />
        <Route path="/approved-sources/:id/auction-tokens" component={AuctionTokens} exact />
      </Switch>
    </>
  )
}

export default ApprovedSourceRoutes;
