import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import DataState from '../../DataState';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Colors } from '../../../styles';
import '../../../styles/feePrograms.css';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import {
  FeeLineTermSingleColumns,
  addTerm,
  removeTerm,
  addPrincipalAmount,
  removePrincipalAmount
} from './functionality';

const ACCOUNTS = gql`
query {
  accounts(scopes: ["income"])
  { id, gl, name }
}`

export const SingleFeeProgramTable = (props) => {
  const { loading, error, data } = useQuery(ACCOUNTS);
  const [formSubmitting, setFormSubmitting] = useState();
  const { isValid } = useFormikContext();

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />

  const { feeLinesAttributes, values, setValues, removedPrincipalAmounts, removedTerms } = props;

  const buttonColor = {
    backgroundColor: Colors.xlGreen,
    borderColor: Colors.xlGreen,
  }

  const buttonSpacing = {
    marginLeft: '1%'
  }

  return <div className='fee-table'>
    <Table>
      <thead>
        <tr>
          <th>For Principal Value Below*</th>
          <FeeLineTermSingleColumns feeLinesAttributes={feeLinesAttributes} />
          <th> Extension Floor Plan Fee</th>

          <th className='fee-button-header'>
            {values.feeLinesAttributes[0].feeLineTermsAttributes.length > 1
              ? (
                [
                  <Button
                    className='fee-add-remove-button'
                    style={buttonColor}
                    onClick={() => addTerm(values, setValues)}
                  >
                    Add Term
                    </Button>,
                  <Button
                    className='fee-add-remove-button'
                    style={buttonColor}
                    onClick={() => removeTerm(values, setValues, removedTerms)}
                  >
                    Remove Term
                    </Button>
                ]
              )
              : (
                <Button
                  className='fee-add-remove-button'
                  style={buttonColor}
                  onClick={() => addTerm(values, setValues)}
                >
                  Add Term
                </Button>
              )
            }
          </th>
        </tr>
      </thead>
      <tbody>
        <AccountRow feeLinesAttributes={feeLinesAttributes} data={data} />
        <FeeLineRows feeLinesAttributes={feeLinesAttributes} />
      </tbody>
    </Table>
    {values.feeLinesAttributes.length > 1
      ? (
        [
          <Button style={buttonColor} onClick={() => addPrincipalAmount(values, setValues)}>Add Principal Amount</Button>,
          <Button style={Object.assign({}, buttonColor, buttonSpacing)}
            onClick={() => removePrincipalAmount(values, setValues, removedPrincipalAmounts)}>
            Remove Principal Amount
            </Button>
        ]
      )
      : (
        <Button style={buttonColor} onClick={() => addPrincipalAmount(values, setValues)}>Add Principal Amount</Button>
      )
    }
    <br></br>
    <Text>*Leave the value blank for all Principal Values, or for the last principal value</Text>
    <div className="fee-table-footer">
      <Button
        style={buttonColor}
        disabled={!isValid || formSubmitting}
        type="submit"
      >
        Submit
      </Button>
    </div>
  </div>
}

const FeeLineRows = (props) => {
  let { feeLinesAttributes } = props;
  return feeLinesAttributes.map((attribute, index) => {
    return <tr key={index}>
      <td>
        <Form.Group className='term-line-errors'>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className='adjustable-prepend'>$</InputGroup.Text>
            </InputGroup.Prepend>
            <Field className='form-control dollar-input' type="number" name={`feeLinesAttributes[${index}].principalBelow`} />
          </InputGroup>
          <ErrorMessage name={`${'feeLinesAttributes'}[${index}].principalBelow`} />
        </Form.Group>
      </td>
      <FeeLineTermRows attribute={attribute} index={index} />
      <td>
        <Form.Group className='term-line-errors'>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className='adjustable-prepend'>$</InputGroup.Text>
            </InputGroup.Prepend>
            <Field className='form-control dollar-input' type="number" name={`feeLinesAttributes[${index}].extensionFeeFlat`} />
          </InputGroup>
          <ErrorMessage name={`${'feeLinesAttributes'}[${index}].extensionFeeFlat`} />
        </Form.Group>
      </td>
      <td></td>
    </tr>
  })
}

const FeeLineTermRows = (props) => {
  const { attribute, index } = props;

  return attribute.feeLineTermsAttributes.map((term, index2) => {
    return <td key={index2}>
      <Form.Group className='term-line-errors'>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className='adjustable-prepend'>$</InputGroup.Text>
          </InputGroup.Prepend>
          <Field className='form-control dollar-input' type="number" name={`feeLinesAttributes[${index}]feeLineTermsAttributes[${index2}].amount`} />
        </InputGroup>
        <ErrorMessage name={`feeLinesAttributes[${index}]feeLineTermsAttributes[${index2}].amount`} />
      </Form.Group>
    </td>
  })
}

const AccountOptions = (props) => {
  const { accounts } = props;
  return accounts.map((account, index) => {
    return <option key={index} value={account.id}>GL {account.gl} - {account.name}</option>
  })
}

const AccountRow = (props) => {
  let { feeLinesAttributes, data } = props;
  let accounts = data.accounts;

  let extensionVarName = 'extensionAccountId'
  let accountVarName = 'accountId'

  if (feeLinesAttributes[0].extensionAccount || feeLinesAttributes[0].extensionAccount === '') {
    extensionVarName = 'extensionAccount'
  }

  if (feeLinesAttributes[0].feeLineTermsAttributes[0].account || feeLinesAttributes[0].feeLineTermsAttributes[0].account === '') {
    accountVarName = 'account'
  }

  return <tr>
    <td></td>
    {feeLinesAttributes[0].feeLineTermsAttributes.map((term, index) => {
      return <td key={index}>
        <Form.Group className='term-line-errors'>
          <Field className='form-control dollar-input' as="select" name={`feeLinesAttributes[0].feeLineTermsAttributes[${index}][${accountVarName}]`}>
            <AccountOptions accounts={accounts} />
          </Field>
          <ErrorMessage name={`feeLinesAttributes[0].feeLineTermsAttributes[${index}][${accountVarName}]`} />
        </Form.Group>
      </td>
    })}
    <td>
      <Form.Group className='term-line-errors'>
        <Field className='form-control dollar-input' as="select" name={`feeLinesAttributes[0][${extensionVarName}]`}>
          <AccountOptions accounts={accounts} />
        </Field>
        <ErrorMessage name={`feeLinesAttributes[0][${extensionVarName}]`} />
      </Form.Group>
    </td>
  </tr>
}
