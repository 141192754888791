import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  Button
} from 'react-native';
import logo from '../components/images/logo.png';
import { Formik, Form } from 'formik';
import { Redirect } from 'react-router-dom';
import { AUTH_TOKEN } from '../constants';
import secrets from '../secrets';

const Logout: () => React$Node = () => {
  function handleLogout() {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'JWT_AUD': secrets.api.jwtAud
      }
    };

    fetch(secrets.api.baseUri + '/users/sign_out', options)
      .then(response => {
        if (!response.ok) {
          alert('Logout failed, please retry');
        } else {
          localStorage.setItem(AUTH_TOKEN, response.headers.get('authorization'));
          window.location.reload();
        }

        return response.json();
      });
  };

  handleLogout();

  return <Redirect
    to={{
      pathname: "/"
    }}
  />
}

export default Logout;
