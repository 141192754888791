import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import Table from 'components/src/components/Transactions/Table';
import { Typography } from 'components/src/styles';

const index = () => {
  const id = parseInt(useParams().id);

  const shownColumns = [
    'bankDate', 'createdDate', 'market', 'source',
    'type', 'reference', 'purpose', 'amount', 'flooredCars', 'createdBy',
    'actions'
  ];

  const availableFilters = [
    'payment_type', 'purpose', 'created_by', 'bank_date', 'created_date'
  ];

  return (
    <Fragment>
      <div style={Typography.pageTitle}>Floorplan Transactions</div>
      <Table
        shownColumns={shownColumns}
        availableFilters={availableFilters}
        scopes={['flooring_core']}
        referrerType="dealer"
        referrerId={id}
        reportPath="transaction-lines-report"
      />
    </Fragment>
  );
};

export default index;
