import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import BasicAlert from '../../../elements/BasicAlert';
import { useAlert } from '../../../hooks/useAlert';
import { AUCTION_BANK_ACCOUNTS } from '../queries';
import Info from '../Info';
import BankAccountsTable from '../../BankAccounts';
import { setUniqueAlertData } from '../../../utils/setAlertData';

const index = (props) => {
  const id = useParams().id;

  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  let saleTimeAlertData = setUniqueAlertData(props, setAlertType, displayAlert);

  return (
    <>
      <Helmet>
        <title>Auction Bank Accounts</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="bank account" type={alertType} />
      }
      <Info {...saleTimeAlertData} />
      <BankAccountsTable
        query={AUCTION_BANK_ACCOUNTS}
        queryName="auction"
        id={id}
        prefix={`/auctions/${id}/bank-accounts`}
      />
    </>
  );
};

export default index;
