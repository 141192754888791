import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ProcessWithdrawal, reserveSchema } from './ProcessWithdrawal';
import { Formik } from 'formik';
import { CREATE_DEALER_TXN } from '../../../../queries/mutations';
import DataState from '../../../DataState';
import * as yup from 'yup';

const NewWithdrawalAdjustment = ({ showWithdrawModal, setShowWithdrawModal, dealerId, balance, type }) => {
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [createDealerTxn, { loading, error }] = useMutation(CREATE_DEALER_TXN, {
    onCompleted(values) {
      setFormSubmitting(false)
      setShowWithdrawModal(false)
      window.location.reload();
    }
  })

  let today = new Date();
   today = today.toISOString().split('T')[0];

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createDealerTxn({ variables: values})
  }

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <Formik
      initialValues={{
        dealerId: dealerId,
        direction: 'Withdraw',
        type: type,
        accountId: null,
        bankAccountId: null,
        bankDate: today,
        amount: '',
        ach: '',
        memo: '',
        name: '',
        txnType: '',
        reference: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={yup.object({
        bankAccountId: yup
          .number('')
          .when('advanceType', {
            is: 'source',
            then: yup.number('').nullable().required('required')
          })
          .when('advanceType', {
            is: 'external',
            then: yup.number('').nullable()
          })
          .when('advanceType', {
            is: 'On Account',
            then: yup.number('').nullable()
          }),
        txnType: yup
          .string()
          .when('advanceType', {
            is: 'external',
            then: yup.string().nullable().required()
          }),
        amount: yup
          .number()
          .required()
          .max(balance),
        name: yup
          .string()
          .when('sourceType', {
            is: 'other',
            then: yup.string().required()
          }),
        reference: yup
          .string()
          .when('txnType', {
            is: 'Check',
            then: yup.string().required()
          })
          .when('txnType', {
            is: 'ACH',
            then: yup.string().required()
          })
          .when('txnType', {
            is: 'Wire',
            then: yup.string().required()
          })
          .when('txnType', {
            is: 'Cash',
            then: yup.string().required()
          }),
      })}
    >
      <ProcessWithdrawal
        type={type}
        showWithdrawModal={showWithdrawModal}
        setShowWithdrawModal={setShowWithdrawModal}
        handleSubmit={handleSubmit}
        formSubmitting={formSubmitting}
        balance={balance}
      />
    </Formik>
  )
}

export default NewWithdrawalAdjustment;
