import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import {
  multiSelectStyle
} from 'components/src/elements/Pagination/FilterStyles';
import 'components/src/elements/Pagination/SelectTypes/multiSelect.css';

export const optionsFromValues = (values, selected) => {
  if (!values) { return values; }
  const options = values.map(value => {
    if (value.value && value.label) {
      return value;
    } else if (value.id && value.name) {
      return ({ value: value.id, label: value.name });
    } else if (value.id && value.fullName) {
      return ({ value: value.id, label: value.fullName });
    } else {
      return ({ value, label: value });
    }
  });

  if (selected) {
    return options.filter(option => (
      selected.find(value => value == option.value)
    ));
  } else {
    return options;
  }
};

const MultiSelect = ({ columnKey, label, options, filters, setFilter }) => {
  return (
    <Row>
      <Col>
        <Form.Label>{label}</Form.Label>
        <Select
          options={optionsFromValues(options)}
          value={optionsFromValues(options, filters[columnKey] || [])}
          isMulti={true}
          isClearable={false}
          onChange={selected => {
            const values = selected ? selected.map(({value}) => value) : null;
            setFilter(columnKey, values);
          }}
          styles={multiSelectStyle}
          className="multi-select"
        />
      </Col>
    </Row>
  );
};

export default MultiSelect;
