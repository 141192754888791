import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ProcessTempProgram } from './Form';
import { Formik } from 'formik';
import DataState from '../../DataState';
import { DEALER_DEALER_LINE } from '../../../queries/viewQueries';
import * as yup from 'yup';
import { CREATE_TEMP_PROGRAM } from './Queries';

const NewTempProgram = props => {
  const { showModal, setShowModal } = props

  const id = parseInt(useParams().id)

  const { loading: loadingDealer, error: dealerError, data } = useQuery(
    DEALER_DEALER_LINE, { variables: { id } }
  );

  const [createTempProgram, { loading, error }] = useMutation(
    CREATE_TEMP_PROGRAM, { onCompleted(values) {
        setShowModal(false);
        window.location.reload();
      }
    }
  )

  const handleSubmit = (values) => {
    if (values.rateProgramId === '') {
      values.rateProgramId = null
    }

    if (values.dealerProgramId === '') {
      values.dealerProgramId = null
    }

    if (values.automatedFeeIds === '') {
      values.automatedFeeIds = null
    }

    if (values.creditLimitIncrease === '') {
      values.creditLimitIncrease = null
    }

    createTempProgram({ variables: values })
  }

  if (loading || loadingDealer) return <DataState.Loading />;
  if (error || dealerError) return
    <DataState.Error error={error || dealerError} />;

  const dealerLineId = parseInt(data.dealer.dealerLines[0].id)
  const today = new Date()
  const yesterday = new Date(today)

  yesterday.setDate(yesterday.getDate() - 1)

  return (
    <Formik
      initialValues={{
        dealerLineId: dealerLineId,
        effectiveOn: '',
        endOn: '',
        rateProgramId: '',
        dealerProgramId: '',
        automatedFeeIds: '',
        creditLimitIncrease: ''
      }}
      onSubmit={handleSubmit}
      validationSchema={yup.object({
        effectiveOn: yup
          .date()
          .min(yesterday, 'date cannot be in the past')
          .required('required'),
        endOn: yup
          .date()
          .min(yup.ref('effectiveOn'), ({ min }) =>
            'date must be after the effective on date'
          )
          .required('required'),
        creditLimitIncrease:
          yup.number().test(function (value) {
            const {
              rateProgramId, automatedFeeIds,
              creditLimitIncrease, dealerProgramId
            } = this.parent;

            if (( rateProgramId || automatedFeeIds ||
              creditLimitIncrease || dealerProgramId) === undefined
            ) {
              return value != null
            } else if (automatedFeeIds !== undefined) {
              if (( rateProgramId || automatedFeeIds || creditLimitIncrease ||
                dealerProgramId ) === undefined || automatedFeeIds.length === 0
              ) {
                return value != null
              } else {
                return true
              }
            } else {
              return true
            }
          }),
      })}
    >
      <ProcessTempProgram
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
      />
    </Formik>
  )
}

export default NewTempProgram;
