import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { NumberHelpers } from '../../utils';
import DataState from '../DataState';
import { auctionSchema, Form } from './Form';
import Loading from '../../elements/Loading';
import { useServerSideErrors } from '../../hooks/useServerSideErrors'

const AUCTION = gql`
  query AUCTION($id: ID!) {
    auction(id: $id) {
      id,
      name,
      street,
      city,
      state,
      zip {
        value
        formatted
      },
      phone { formatted },
      xlgAuction,
      xlgAuctionFloat,
      auctionAccessId,
      status,
      url,
      marketIds,
      titleFundingStatusIds,
      pmsiOption,
      pmsiAddress,
      pmsiCity,
      pmsiState,
      pmsiZip,
      pmsiCompanyName,
      pmsiFilingLocation,
      pmsiPhone { formatted },
      primaryContact {
        id,
        fullName,
        officePhone {
          value,
          formatted
        }
      }
    }
  }
`;

const UPDATE_AUCTION = gql`
  mutation UpdateAuction(
    $id: ID!,
    $name: String!,
    $street: String,
    $city: String,
    $state: String,
    $zip: String,
    $phone: String,
    $xlgAuction: Boolean!,
    $xlgAuctionFloat: Int,
    $status: String!,
    $auctionAccessId: Int,
    $url: String,
    $marketIds: [Int!],
    $titleFundingStatusIds: [Int!],
    $pmsiOption: Boolean,
    $pmsiAddress: String,
    $pmsiCity: String,
    $pmsiState: String,
    $pmsiZip: String,
    $pmsiCompanyName: String,
    $pmsiFilingLocation: String,
    $pmsiPhone: String
  )
  {
    updateAuction(
      id: $id
      name: $name
      street: $street
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      xlgAuction: $xlgAuction
      xlgAuctionFloat: $xlgAuctionFloat
      auctionAccessId: $auctionAccessId
      status: $status
      url: $url
      marketIds: $marketIds
      titleFundingStatusIds: $titleFundingStatusIds
      pmsiOption: $pmsiOption
      pmsiAddress: $pmsiAddress
      pmsiCity: $pmsiCity
      pmsiState: $pmsiState
      pmsiZip: $pmsiZip
      pmsiCompanyName: $pmsiCompanyName
      pmsiFilingLocation: $pmsiFilingLocation
      pmsiPhone: $pmsiPhone
    ) {
      auction {
        id
        name
        marketIds
        titleFundingStatusIds
      }
      errors {
        message
        path
      }
    }
  }
`;

const Edit = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(AUCTION, { variables: { id } });

  const customSubmitHandler = (values) => {
    values.auctionAccessId = NumberHelpers.makeNullable(values.auctionAccessId)
    if(values.titleFundingStatus === '') {
      values.titleFundingStatus = null
    }
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_AUCTION,
    onCompleteRedirectTo: '/auctions',
    customSubmitHandler: customSubmitHandler,
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { name } = data.auction;
  const zip = data.auction.zip.value || '';

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Edit Auction: {name}</h1>
        </div>
        <ServerSideErrors />
        <Formik
          initialValues={{
            id: id,
            name: name || '',
            street: data.auction.street || '',
            city: data.auction.city || '',
            state: data.auction.state || '',
            zip: zip || '',
            phone: data.auction.phone?.formatted || '',
            xlgAuction: data.auction.xlgAuction || false,
            xlgAuctionFloat: data.auction.xlgAuctionFloat || 0,
            auctionAccessId: data.auction.auctionAccessId || '',
            status: data.auction.status || '',
            url: data.auction.url || '',
            marketIds: data.auction.marketIds || [],
            titleFundingStatusIds: data.auction.titleFundingStatusIds || [],
            pmsiOption: data.auction.pmsiOption || false,
            pmsiAddress: data.auction.pmsiAddress || '',
            pmsiCity: data.auction.pmsiCity || '',
            pmsiState: data.auction.pmsiState || '',
            pmsiZip: data.auction.pmsiZip || '',
            pmsiPhone: data.auction.pmsiPhone?.formatted || '',
            pmsiCompanyName: data.auction.pmsiCompanyName || '',
          }}
          validationSchema={auctionSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    )
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default Edit;
