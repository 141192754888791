import React from 'react';
import { currency } from 'components/src/utils/stringHelpers';
import { setDate } from 'components/src/utils/dateHelpers';
import AuthLink from 'components/src/components/AuthLink';
import { Link } from 'react-router-dom';

const getDealer = entry => {
  if ('Dealer' === entry.__typename) {
    return entry;
  } else if ('AuditCar' === entry.__typename) {
    return entry.flooredCar.dealer;
  } else {
    return entry.dealer;
  }
};

const sharedDefinitions = [
  {
    key: 'market',
    label: 'Market',
    value: ({ entry }) => getDealer(entry).market.name
  },
  {
    key: 'dealerName',
    label: 'Dealer Name',
    value: ({ entry }) => getDealer(entry).name,
    content: ({ entry }) => (
      <Link to={`/dealers/${getDealer(entry).id}`}>
        {getDealer(entry).name}
      </Link>
    )
  },
  {
    key: 'legalName',
    label: 'Legal Name',
    minViewportWidth: 1600,
    value: ({ entry }) => getDealer(entry).legalName,
  },
  {
    key: 'dealerStatus',
    label: 'Dealer Status',
    minViewportWidth: 800,
    value: ({ entry }) => getDealer(entry).dealerStatus.name,
  },
  {
    key: 'dealerCategories',
    label: 'Dealer Type',
    minViewportWidth: 1800,
    value: ({ entry }) => {
      const dealerTypes = []

      getDealer(entry).dealerCategories.map(category => {
        dealerTypes.push(category.name)
      })

      return dealerTypes.join(', ')
    }
  },
  {
    key: 'creditLine',
    label: 'Credit Line',
    minViewportWidth: 800,
    value: ({ entry }) => parseFloat(getDealer(entry).creditLine),
    content: ({ value }) => currency(value),
  },
  {
    key: 'currentPrincipal',
    label: 'Outstanding Balance',
    minViewportWidth: 800,
    value: ({ entry }) => parseFloat(getDealer(entry).currentPrincipal),
    content: ({ value }) => currency(value),
  },
  {
    key: 'tempIncreases',
    label: 'Temp Increases',
    minViewportWidth: 1200,
    value: ({ entry }) => parseFloat(getDealer(entry).tempIncreases),
    content: ({ value }) => currency(value),
  },
  {
    key: 'unpaidCount',
    label: 'Open/Unpaid Units',
    minViewportWidth: 800,
    value: ({ entry }) => getDealer(entry).unpaidCount,
  },
  {
    key: 'unverifiedAuditCarsCount',
    label: 'Currently Unverified / Unpaid Units',
    minViewportWidth: 800,
    value: ({ entry }) => getDealer(entry).unverifiedAuditCarsCount,
  },
  {
    key: 'accountNumber',
    label: 'Account Number',
    minViewportWidth: 800,
    content: ({ entry }) => getDealer(entry).accountNumber,
  },
];

export const dealerColumn = (key, overrides) => {
  return ({
    ...sharedDefinitions.find(definition => definition.key === key),
    ...overrides
  });
};

export const columnDefinitions = props => {
  const {
    auth,
    lastDownloadTime,
    setLastDownloadTime,
    hideDealer,
    unhideDealer,
    showHidden
  } = props;

  const definitions = [
    dealerColumn('market'),
    dealerColumn('dealerName', { key: 'name' }),
    dealerColumn('legalName'),
    dealerColumn('dealerStatus'),
    dealerColumn('dealerCategories'),
    {
      key: 'availableCredit',
      label: 'Available Credit',
      minViewportWidth: 800,
      value: ({ entry }) => parseFloat(entry.availableCredit),
      content: ({ value }) => currency(value)
    },
    dealerColumn('creditLine'),
    dealerColumn('currentPrincipal'),
    dealerColumn('tempIncreases'),
    dealerColumn('unverifiedAuditCarsCount'),
    dealerColumn('accountNumber'),
    {
      key: 'contractedOn',
      label: 'Contracted On',
      minViewportWidth: 1200,
      content: ({ entry }) => setDate(entry.contractedOn)
    },
    {
      key: 'auditStatus',
      label: 'Audit Status',
      minViewportWidth: 1200,
      content: ({ entry }) => entry.auditStatus
    },
    {
      key: 'licenseNumber',
      label: 'License Number',
      minViewportWidth: 1800,
      content: ({ entry }) => entry.licenseNumber
    },
    {
      key: 'licenseExpiresOn',
      label: 'License Expires On',
      minViewportWidth: 1600,
      content: ({ entry }) => setDate(entry.licenseExpiresOn)
    },
    {
      key: 'insuranceExpiresOn',
      label: 'Insurance Expires On',
      minViewportWidth: 1800,
      content: ({ entry }) => setDate(entry.insuranceExpiresOn)
    },
    {
      key: 'annualReviewOn',
      label: 'Annual Review Date',
      minViewportWidth: 1800,
      content: ({ entry }) => setDate(entry.annualReviewOn)
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => {
        return (
          <div style={{display: 'block'}}>
            <AuthLink
              style={{paddingRight: 5}}
              to={`/dealers/${entry.id}`}
              resource="Dealer"
              action="read"
            >
              View
            </AuthLink>
            |
            <AuthLink
              style={{paddingLeft: 5, paddingRight: 5}}
              to={`/dealers/${entry.id}/edit`}
              resource="Dealer"
              action="update"
            >
              Edit
            </AuthLink>
            {showHidden && (
              <AuthLink
                to="#"
                resource="Dealer"
                action="update"
                onClick={() => unhideDealer({ variables: { id: entry.id } })}
              >
                | Unhide
              </AuthLink>
            )}
            {!showHidden && entry.canBeHidden && (
              <AuthLink
                to="#"
                resource="Dealer"
                action="update"
                onClick={() => hideDealer({ variables: { id: entry.id } })}
              >
               | Hide
              </AuthLink>
            )}
          </div>
        )
      }
    }
  ];

  return definitions;
}
