import { addFilters } from './addFilters';

export const handleLimitChange = (
  fetchMore,
  limit,
  sort,
  sortDirection,
  currentPage,
  datatable,
  setQueryData,
  search,
  marketId=null,
  filters=null,
  setQueryLoading,
) => {
  const variables = {
      limit,
      sort: sort.current,
      sortDirection: sortDirection.current,
      offset: 0,
      search: search.current,
    }

  if (marketId) variables.marketId = parseInt(marketId);
  if (filters) addFilters(filters, variables);

  setQueryLoading(true);
  fetchMore({
    variables,
    updateQuery: (prev, { fetchMoreResult }) => {
      setQueryLoading(false);
      currentPage.current = 1;
      datatable.page.len(limit).draw();

      setQueryData(fetchMoreResult);
    },
  });
};
