import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import BasicAlert from '../../../elements/BasicAlert';
import { useAlert } from '../../../hooks/useAlert';
import { APPROVED_SOURCE_BANK_ACCOUNTS } from '../queries';
import Info from '../Info';
import BankAccountsTable from '../../BankAccounts';
import { setAlertData } from '../../../utils/setAlertData';

const BankAccounts = (props) => {
  const id = useParams().id;

  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  return (
    <>
      <Helmet>
        <title>Approved Source Bank Accounts</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="bank account" type={alertType} />
      }
      <Info />
      <BankAccountsTable
        query={APPROVED_SOURCE_BANK_ACCOUNTS}
        queryName="approvedSource"
        id={id}
        prefix={`/approved-sources/${id}/bank-accounts`}
      />
    </>
  );
};

export default BankAccounts;
