import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import * as Formatting from '../../styles/formatting';
import { MARKET_DETAILS } from '../../queries/viewQueries';
import {
  currency, numberWithDelimiter as delimited
} from 'components/src/utils/stringHelpers';
import Loading from '../../elements/Loading';
import { UserContext } from '../../contexts/UserContext';
import DataState from '../DataState';
import { Col } from 'react-bootstrap';
import { Card, Head, Data, Label, Value } from './Cards';
import './styles.css';

const Credit = () => {
  const user = useContext(UserContext);
  const marketId = parseInt(user.currentMarket.id);

  const { loading, error, data } = useQuery(
    MARKET_DETAILS, { variables: { marketId: marketId } }
  );

  if (loading && !data) return <Loading />
  if (error) return <DataState.Error error={error} />;

  const creditLimit = data.marketDetails.creditLimit;
  const currentAr = data.marketDetails.currentAr;
  const pendingPrincipal = data.marketDetails.pendingPrincipal;
  const utilization = parseFloat(data.marketDetails.utilization).toFixed(2);
  const available = parseFloat(100.0 - utilization).toFixed(2);

  return (
    <Col className="dashboard-column" xs={12} xl={3}>
      <h2>Credit Stats</h2>

      <Card style={{minHeight: 170}}>
        <Head>Credit</Head>
        <Data>
          <Label>Total Lines of Credit</Label>
          <Value>{currency(creditLimit)}</Value>
        </Data>
        <Data>
          <Label>Temp Lines of Credit</Label>
          <Value>{currency(data.marketDetails.tempIncreases)}</Value>
        </Data>
        <Data>
          <Label>Outstanding A/R</Label>
          <Value>{currency(currentAr)}</Value>
        </Data>
        <Data>
          <Label>Current Utilization Compared to Lines of Credit</Label>
          <Value>{utilization}%</Value>
        </Data>
        <Data>
          <Label>Unit Count</Label>
          <Value>{delimited(data.marketDetails.unpaidCount)}</Value>
        </Data>
      </Card>

      <Card style={{minHeight: 110}}>
        <Head>Payments</Head>
        <Data>
          <Label>Pending Payments Not Reflected in Aging Total</Label>
          <Value>{currency(pendingPrincipal)}</Value>
        </Data>
        <Data>
          <Label>Pending Outgoing Funding</Label>
          <Value>{currency(data.marketDetails.pendingOutgoing)}</Value>
        </Data>
        <Data>
          <Label>Outstanding TAs Net of Pending Outgoing</Label>
          <Value>{currency(data.marketDetails.outstandingTa)}</Value>
        </Data>
      </Card>
    </Col>
  );
};

export default Credit;
