import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Notes from '../../Notes';
import { FLOORED_CAR_NOTES } from '../Queries';

const CarNotes = ({id}) => {
  if (!id) {
    id = parseInt(useParams().ownerId);
  }

  let prefix = useLocation().pathname;
  if (!(/notes/.test(prefix))) {
    prefix = prefix + '/notes';
  }

  return (
    <Notes
      query={FLOORED_CAR_NOTES}
      ownerType="flooredCar"
      ownerId={id}
      prefix={prefix}
    />
  );
};

export default CarNotes;
