import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as Buttons from 'components/src/elements/buttons';
import './styles.css';
import { ChooseDates, ChooseVIN } from './Options';

const reportLinkStyle = {
  color: 'black',
  textDecoration: 'underline',
  display: 'block',
};

const SelectReport = ({ handleCancel, setSelectedReport }) => {
  const selectReport = (e, report) => {
    e.preventDefault();
    setSelectedReport(report);
  };

  return (
    <>
      <div style={{fontSize: '1.3rem'}}>
        <h2 style={{fontSize: '1.3rem'}}>
          Select Report
        </h2>

        <a
          href="#"
          style={reportLinkStyle}
          onClick={e => selectReport(e, 'Payment')}
        >
          Payment Report
        </a>

        <a
          href="#"
          style={reportLinkStyle}
          onClick={e => selectReport(e, 'Payment Car')}
        >
          Payment Report&mdash;Single Car
        </a>

        <a
          href="#"
          style={reportLinkStyle}
          onClick={e => selectReport(e, 'Floored Car')}
        >
          Floored Car Report
        </a>
      </div>

      <button
        onClick={handleCancel}
        type="button"
        className="btn btn-lg btn-primary btn-block"
        style={{...Buttons.cancelButton, width: '100%'}}
      >
        Cancel
      </button>
    </>
  );
};

const Reporting = ({ dealer, handleCancel }) => {
  const [selectedReport, setSelectedReport] = useState('Payment Car');

  const ChooseOptions = 'Payment Car' === selectedReport ?
    ChooseVIN : ChooseDates;

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      onHide={handleCancel}
      dialogClassName="modal-600"
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>{dealer.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign: 'center'}}>
        {selectedReport ? (
          <ChooseOptions
            dealer={dealer}
            handleCancel={handleCancel}
            report={selectedReport}
          />
        ) : (
          <SelectReport
            handleCancel={handleCancel}
            setSelectedReport={setSelectedReport}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Reporting;
