import React from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../../elements/forms';
import * as Buttons from '../../../../elements/buttons';
import { Spacing } from '../../../../styles';
import { ChangeForm } from '../components';

export const programChangeSchema = yup.object({
  rate: yup
    .number()
    .min(0, 'Must be positive')
    .max(100, "Can't exceed 100%")
    .required('Required'),
  effectiveOn: yup
    .date()
    .min(new Date(), 'The date must be in the future')
    .required('Required'),
});

export const Form = ({ type }) => {
  return <FormikForm>
    <BasicForm.TextInput
      name="rate"
      type="number"
      label="Rate"
    />
    <BasicForm.TextInput
      name="effectiveOn"
      type="date"
      label="Effective On"
    />
    <div style={Spacing.buttonRow}>
      <Buttons.Cancel type="cancel" text="Cancel" />
      <Buttons.Standard type="submit" text="Submit" />
    </div>
  </FormikForm>
}
