import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import DataState from '../../DataState';
import { accountSchema, Form } from './Form';
import { titleCase } from '../../../utils/stringHelpers';
import DataTable from '../../../elements/DataTable';
import { DateHelpers, StringHelpers } from '../../../utils';
import AuthContext from '../../../contexts/AuthContext';

const ACCOUNT = gql`
  query ACCOUNT($id: ID!) {
    account(id: $id) {
      gl,
      name,
      special { key }
      txnLines {
        dealer { name, id }
        flooredCar { name, id }
        account { name, id }
        debit
        credit
        txn { id, bankDate }
      }
      parent {
        id
      },
    }
  }
`;


const Show = () => {
  const id = parseInt(useParams().id);
  const history = useHistory();

  const { loading, error, data } = useQuery(ACCOUNT, { variables: { id } });

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    gl,
    name,
    special,
  } = data.account;

  const parentId = data.account.parent?.id;
  const account = data.account

  const columns = [
    {
      title: "Date",
      render: DateHelpers.renderDate,
    },
    {
      title: "Dealer"
    },
    {
      title: "Car"
    },
    {
      title: 'Account'
    },
    {
      title: 'Debit',
      className: 'text-right'
    },
    {
      title: 'Credit',
      className: 'text-right'
    },
    {
      title: 'Functions'
    }
  ]

  const dataSet = data.account.txnLines.map(function (line) {
    line.dealerName = line.dealer.name;
    line.accountName = line.account.name;

    let flooredCarName
    if (line.flooredCar != null) {
      flooredCarName = line.flooredCar.name
    } else {
      flooredCarName = ""
    }

    const lineDebit = `${StringHelpers.currency(line.debit)}` || '';
    const lineCredit = `${StringHelpers.currency(line.credit)}` || '';
    const txnDate = DateHelpers.setDate(line.txn.bankDate);

    const auth = useContext(AuthContext);
    if (auth.hasPolicy('Txn', 'read')) {
      line.actions = `
        <a href="/transactions/${line.txn.id}"/>View</a>
      `
    }

    return [
      txnDate,
      line.dealerName || '',
      flooredCarName,
      line.accountName || '',
      lineDebit,
      lineCredit,
      line.actions
    ]
  })

  return (
    <>
      <Helmet>
        <title>{account.name}</title>
      </Helmet>
       <DataTable
        uncreatable
        dataSet={dataSet}
        columns={columns}
        pageTitle={`Transactions for GL${account.gl} - ${account.name}`}
        addFilter={false}
        addColvis={false}
      />
    </>
  );
};

export default Show;
