import { gql } from '@apollo/client';

const SETTINGS_FIELDS = `
  id
  achUseTokens
  achIncomingCompanyName
  achIncomingTokenName
  achIncomingCompanyId
  achOutgoingCompanyName
  achOutgoingTokenName
  achOutgoingCompanyId
  achOriginRouting
  auctionAccessTitlePresentId
  auctionAccessTitleAbsentId
  autopayEnabled
  autopayDays
  autopayBorrowedTitleNotificationDays
  autopayBorrowedTitlePullDays
  booksClosedThrough
  booksClosedPassword
  paymentTerm { id }
  nextJeNumber
  costOfFunds
  delinquentThreshold
  borrowedTitleMinimumCars
  borrowedTitleCountLimit
  borrowedTitlePrincipalLimit
`;

export const SETTINGS = gql`
query {
  settings {
    ${SETTINGS_FIELDS}
  }
  titleStatuses {
    id
    name
  }
  paymentTerms {
    id
    name
  }
}
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings(
    $achUseTokens: Boolean
    $achIncomingCompanyName: String
    $achIncomingTokenName: String
    $achIncomingCompanyId: String
    $achOutgoingCompanyName: String
    $achOutgoingTokenName: String
    $achOutgoingCompanyId: String
    $achOriginRouting: String
    $auctionAccessTitlePresentId: Int
    $auctionAccessTitleAbsentId: Int
    $autopayEnabled: Boolean
    $autopayDays: Int
    $autopayBorrowedTitleNotificationDays: Int
    $autopayBorrowedTitlePullDays: Int
    $booksClosedThrough: ISO8601Date
    $booksClosedPassword: String
    $paymentTermId: Int
    $nextJeNumber: Int
    $costOfFunds: Float
    $delinquentThreshold: Float
    $borrowedTitleMinimumCars: Int
    $borrowedTitleCountLimit: Int
    $borrowedTitlePrincipalLimit: Decimal
  ) {
    updateSettings(
      achUseTokens: $achUseTokens
      achIncomingCompanyName: $achIncomingCompanyName
      achIncomingTokenName: $achIncomingTokenName
      achIncomingCompanyId: $achIncomingCompanyId
      achOutgoingCompanyName: $achOutgoingCompanyName
      achOutgoingTokenName: $achOutgoingTokenName
      achOutgoingCompanyId: $achOutgoingCompanyId
      achOriginRouting: $achOriginRouting
      auctionAccessTitlePresentId: $auctionAccessTitlePresentId
      auctionAccessTitleAbsentId: $auctionAccessTitleAbsentId
      autopayEnabled: $autopayEnabled
      autopayDays: $autopayDays
      autopayBorrowedTitleNotificationDays: $autopayBorrowedTitleNotificationDays
      autopayBorrowedTitlePullDays: $autopayBorrowedTitlePullDays
      booksClosedThrough: $booksClosedThrough
      booksClosedPassword: $booksClosedPassword
      paymentTermId: $paymentTermId
      nextJeNumber: $nextJeNumber
      costOfFunds: $costOfFunds
      delinquentThreshold: $delinquentThreshold
      borrowedTitleMinimumCars: $borrowedTitleMinimumCars
      borrowedTitleCountLimit: $borrowedTitleCountLimit
      borrowedTitlePrincipalLimit: $borrowedTitlePrincipalLimit
    ) {
      settings {
        ${SETTINGS_FIELDS}
      }
    }
  }
`;
