import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Spacing } from '../../styles';
import * as Buttons from '../../elements/buttons';
import * as BasicForm from "../../elements/forms";
import { StringHelpers } from '../../utils';

const RESET_DEALER_USER = gql`
  mutation resetDealerUser($id: ID!, $userProvidedPhoneNumber: String!) {
    resetDealerUser(
      id: $id,
      userProvidedPhoneNumber: $userProvidedPhoneNumber
    ) {
      errors { message, path}
    }
  }
`;

const resetDealerPersonSchema = yup.object({
  userProvidedPhoneNumber: yup.string().required("required").
    matches(StringHelpers.phoneRegExp, {
      message: "Please enter a valid phone number.",
      excludeEmptyString: false
    }),
})

const ResetDealerPersonModal = ({ handleClose, id }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [resetDealerUser] = useMutation(RESET_DEALER_USER, {
    onCompleted: handleClose
  });

  const handleSubmit = (values, actions, event) => {
    setFormSubmitting(true)
    resetDealerUser({ variables: values })
  }

  const resetBody = {
    color: '#2b6448',
    fontSize: "1em",
  }

  const resetBodyTopParagraph = {
    ...resetBody,
    marginTop: '2%'
  }

  return (
    <>
      <div style={Spacing.formWrapper}>
        <Formik
          initialValues={{
            id,
            userProvidedPhoneNumber: ''
          }}
          validationSchema={resetDealerPersonSchema}
          onSubmit={handleSubmit}
        >
          <FormikForm>
            <BasicForm.TextInput
              name="userProvidedPhoneNumber"
              type="text"
              label="Please enter the phone number the dealer wishes to receive Two Factor Authentication Messages at:"
            />

            <div style={resetBodyTopParagraph}>
              To complete a reset:
              <ul>
                <li>
                  Clicking the link will send a reset email to the email address specified for the dealer user.
                </li>
              </ul>
            </div>
            <div style={Spacing.buttonRow}>
              <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose} />
              <Buttons.Standard
                type="submit"
                text='Send Password Reset Email'
                disabled={formSubmitting}
              />
            </div>
          </FormikForm>
        </Formik>
      </div>
    </>
  )
}


const ResetDealerPerson = ({ dealerUserId }) => {
  const [showModal, toggleShowModal] = useState(true)

  const handleClose = () => {
    toggleShowModal(false);
    window.location.reload();
  }

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {'Reset Dealer User'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResetDealerPersonModal handleClose={handleClose} id={dealerUserId} />
        </Modal.Body>
      </Modal>
    </>
  )
};

export default ResetDealerPerson;
