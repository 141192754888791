import React, { useState } from 'react';
import Form from "react-bootstrap/Form";

export const shippingOptions = [
  {
    label: "Priority Overnight, Delivered by 10:30am",
    value: "PRIORITY_OVERNIGHT"
  },
  {
    label: "Standard Overnight, Delivered by 4:30pm",
    value: "STANDARD_OVERNIGHT"
  },
  {
    label: "2 Day, Delivered by 4:30pm",
    value: "FEDEX_2_DAY"
  },
  {
    label: "2 Day, Delivered by 10:30am",
    value: "FEDEX_2_DAY_AM"
  },
  {
    label: "Fedex Express Saver - 3 Day",
    value: "FEDEX_EXPRESS_SAVER"
  },
  {
    label: "First Overnight, Delivered by 9:00am",
    value: "FIRST_OVERNIGHT"
  }
];

export const columnDefinitions = props => {
  const {
    checkedEntries,
    shipData,
    setCheckedEntries,
    forceUpdate,
    cancelTitleShipment,
    setShowEdit,
    setLogId,
    setDealerId
  } = props;

  const definitions = [
    {
      key: 'ship',
      label: 'Ship',
      sort: 'prevent',
      content: ({ entry }) => {
        const toggleShipment = () => {
          const checked = checkedEntries.includes(entry.id)

          if (checked === false) {
            checkedEntries.unshift(entry.id)
          } else if (checked === true) {
            var index = checkedEntries.indexOf(entry.id);

            if (index >= 0) {
              checkedEntries.splice(index, 1);
            }
          }

          setCheckedEntries(checkedEntries)
          forceUpdate()
        }

        return (
          <Form.Check
            checked={checkedEntries.includes(entry.id)}
            onChange={() => toggleShipment()}
          />
        )
      }
    },
    {
      key: 'market',
      label: 'Market',
      value: ({ entry }) => entry.flooredCar.market.name,
    },
    {
      key: 'dealer',
      label: 'Dealer',
      sort: 'asc',
      value: ({ entry }) => entry.flooredCar.dealer.name,
      content: ({ entry, value }) =>
        <a href={`/dealers/${entry.flooredCar.dealer.id}`}>
          {value}
        </a>
    },
    {
      key: 'vin',
      label: 'VIN',
      value: ({ entry }) => entry.flooredCar.vin,
      content: ({ entry, value }) =>
        <a
          href={
            `/dealers/${entry.flooredCar.dealer.id}/cars/${entry.flooredCar.id}`
          }
        >
          {value}
        </a>
    },
    {
      key: 'shipTo',
      label: 'Ship To',
      value: ({ entry, value }) => {
        let typeName;

        if ('DealerPlace' === entry.shipToType) {
          typeName = 'Dealer'
        } else if ('ApprovedSource' === entry.shipToType) {
          typeName = 'Approved Source'
        } else  {
          typeName = entry.shipToType
        }

        return (
          typeName + ' - ' + value?.shipToName + ' - ' + value?.address
        );
      },
      content: ({ value, entry }) => {
        if (entry.shipToType === 'Other') {
          return value;
        } else {
          return (
            <a href={entry.shipTo?.webpath}>{value}</a>
          )
        }
      }
    },
    {
      key: 'shipToPhone',
      label: 'Phone',
      value: ({ entry }) => entry.shipTo?.phone?.formatted,
    },
    {
      key: 'createdBy',
      label: 'Created By',
      value: ({ entry }) => entry.createdBy.fullName,
      content: ({ value }) => value
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      cellStyle: { display: 'block' },
      content: ({ entry }) => {
        const handleCancel = () => {
          if (window.confirm("Are you sure you want to cancel this shipment? "
            + "note: this will not delete the Title Log.")) {
            cancelTitleShipment({variables: { id: entry.id }})
          }
        }

        const handleEdit = () => {
          setShowEdit(true)
          setLogId(parseInt(entry.id))
          setDealerId(parseInt(entry.flooredCar.dealer.id))
        }

        return (
          <>
            <a
              href="#"
              onClick={() => handleCancel()}
            >
              Cancel Shipment
            </a>
            &nbsp;|&nbsp;
            <a
              href="#"
              onClick={() => handleEdit()}
            >
              Change Address
            </a>
          </>
        )
      }
    }
  ];

  return definitions
}
