import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import DataState from '../../DataState';
import { branchSchema, Form } from './Form';
import { titleCase } from '../../../utils/stringHelpers';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'

const BRANCH = gql`
  query BRANCH($id: ID!) {
    branch(id: $id) {
      code,
      name,
      phoneNumber { value, formatted },
      attention,
      street,
      city,
      state,
      zip { value, formatted },
      email,
    }
  }
`;

const UPDATE_BRANCH = gql`
  mutation UpdateBranch($id: ID!, $code: String!, $name: String!, $street: String!,
    $city: String!, $state: String!, $zip: String!, $phoneNumber: String!,
    $attention: String! $email: String!)
  {
    updateBranch(id: $id, code: $code, name: $name, street: $street,
      city: $city, state: $state, zip: $zip, phoneNumber: $phoneNumber,
      attention: $attention, email: $email)
    {
      branch {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const Edit = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(BRANCH, { variables: { id } });

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_BRANCH,
    onCompleteRedirectTo: '/system/branches'
  }
  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    code,
    name,
    attention,
    street,
    state,
    city,
    email,
  } = data.branch;

  const phoneNumber = data.branch.phoneNumber.value || '';
  const zip = data.branch.zip.value || '';

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update Branch: ${titleCase(name)}`}</h1>
        </div>
        <ServerSideErrors />
        <Formik
          initialValues={{
            id: id,
            code: code || '',
            name: name || '',
            phoneNumber: phoneNumber || '',
            email: email || '',
            attention: attention || '',
            street: street || '',
            city: city || '',
            state: state || '',
            zip: zip || '',
          }}
          validationSchema={branchSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formContainer}>
          <EditForm />
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default Edit;
