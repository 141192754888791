import React, { useState } from 'react';
import AuthContext from 'components/src/contexts/AuthContext';
import { DECRYPT_TOKEN } from './queries';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Spacing, Typography } from 'components/src/styles';
import * as BasicForm from 'components/src/elements/forms';
import * as Buttons from 'components/src/elements/buttons';

export const decryptTokenSchema = yup.object({
  token: yup.string().required('Required')
});

const DecryptAchToken = () => {
  const [result, setResult] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [decryptToken] = useMutation(
    DECRYPT_TOKEN,
    {
      onCompleted: data => {
        setResult(data.decryptToken);
        setIsSubmitting(false);
      }
    }
  );

  let resultComponent = null;

  if (result) {
    if (result.accountNumber || result.routingNumber) {
      resultComponent = (
        <div>
          <b>Routing Number</b>: {result.routingNumber}<br />
          <b>Account Number</b>: {result.accountNumber}<br />
          <b>Account Type</b>: {result.type}<br />
          <hr />
          Raw Response from Decryption API:<br />
          <pre>{result.response}</pre>
        </div>
      );
    } else {
      resultComponent = (
        <div>
          <b>Unable to Decrypt</b>
          <hr />
          Raw Response from Decryption API:<br />
          <pre>{result.response || 'No response details'}</pre>
        </div>
      );
    }
  }

  return (
    <div style={Spacing.formContainer}>
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Decrypt ACH Token</h1>
        </div>
        <Formik
          initialValues={{token: ''}}
          validationSchema={decryptTokenSchema}
          onSubmit={values => {
            setIsSubmitting(true);
            decryptToken({ variables: values });
          }}
        >
          {({ isValid }) => (
            <Form>
              <BasicForm.TextInput
                name="token"
                type="text"
                label="Token"
              />
              <div style={Spacing.buttonRow}>
                <Buttons.Standard
                  type="submit"
                  text={isSubmitting ? 'Querying API' : 'Submit'}
                  disabled={!isValid || isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>

        {resultComponent}
      </div>
    </div>
  )
};

export default DecryptAchToken;
