import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../../elements/forms";
import IntervalInput from "../IntervalFees/intervalInput";

const whiteBackground = {
  backgroundColor: "#F1F1F1",
};

const handleChange = (e, index, attributeIdx, type, values, setValues) => {
  const target = parseFloat(e.currentTarget.value);

  if (type === 'principalBelow') {
    values.termsAttributes[index].termLinesAttributes[attributeIdx][type] =
      target === "" || !target ? null : target;

    setValues(values)
    return;
  }


  values.termsAttributes[index].termLinesAttributes[attributeIdx][type] =
    target === "" || !target ? 0 : target;

  setValues(values);
};

const PrincipalAmounts = ({
  index,
  columnStyle,
  allowInterval,
  termLinesAttributes,
}) => {
  const { setValues, values } = useFormikContext();
  return termLinesAttributes.map((attribute, attributeIdx) => {
    if (attribute._destroy) return null;
    return (
      <Row key={attributeIdx}>
        <BaseAmounts
          index={index}
          attributeIdx={attributeIdx}
          columnStyle={columnStyle}
          values={values}
          setValues={setValues}
        />
        {attribute.termLineFeesAttributes.map((atr, idx3) => {
          if (values.termsAttributes[index].termLinesAttributes[attributeIdx]._destroy || values.termsAttributes[index].termLinesAttributes[attributeIdx]
              .termLineFeesAttributes[idx3]._destroy) return null;

          const currentFeeAttribute =
            values.termsAttributes[index].termLinesAttributes[attributeIdx]
              .termLineFeesAttributes[idx3];

          const parentFeeAttribute =
            values.termsAttributes[index].termLinesAttributes[0]
              .termLineFeesAttributes[idx3];

          currentFeeAttribute.accountId = parentFeeAttribute.accountId || null;
          currentFeeAttribute.day = parentFeeAttribute.day || null;
          currentFeeAttribute.earnType = parentFeeAttribute.earnType || null;
          currentFeeAttribute.amount =
            parseFloat(
              values.termsAttributes[index].termLinesAttributes[attributeIdx]
                .termLineFeesAttributes[idx3].amount
            ) || 0;

          if (!allowInterval) currentFeeAttribute.interval = null;

          return (
            <Col style={columnStyle.smallDiv} key={idx3}>
              <BasicForm.DollarInput
                type="number"
                name={`termsAttributes[${index}].termLinesAttributes[${attributeIdx}].termLineFeesAttributes[${idx3}].amount`}
                value={
                  values.termsAttributes[index].termLinesAttributes[
                    attributeIdx
                  ].termLineFeesAttributes[idx3].amount || ""
                }
                inputStyle={columnStyle.dropdownStyle}
                styledPrepend={{padding: '10px'}}
              />
            </Col>
          );
        })}
        {allowInterval && (
          <Col style={Object.assign({}, columnStyle.smallDiv, columnStyle.whiteBackground)} key={attributeIdx}>
            <IntervalInput
              index={index}
              attributeIdx={attributeIdx}
              values={values}
              columnStyle={columnStyle}
            />
          </Col>
        )}
      </Row>
    );
  });
};

export const BaseAmounts = ({
  index,
  attributeIdx,
  columnStyle,
  values,
  setValues,
}) => {
  return (
    <Fragment key={attributeIdx}>
      <Col style={columnStyle.smallDiv}>
        <BasicForm.DollarInput
          type="number"
          name={`termsAttributes[${index}].termLinesAttributes[${attributeIdx}].principalBelow`}
          value={
            values.termsAttributes[index].termLinesAttributes[attributeIdx]
              .principalBelow || ""
          }
          onChange={(e) =>
            handleChange(
              e,
              index,
              attributeIdx,
              "principalBelow",
              values,
              setValues
            )
          }
          inputStyle={columnStyle.dropdownStyle}
          styledPrepend={{padding: '10px'}}
        />
      </Col>
      <Col style={Object.assign({}, columnStyle.smallDiv, whiteBackground)}>
        <BasicForm.PercentInput
          type="number"
          name={`termsAttributes[${index}].termLinesAttributes[${attributeIdx}].curtailmentPercentage`}
          value={
            values.termsAttributes[index].termLinesAttributes[attributeIdx]
              .curtailmentPercentage || ""
          }
          onChange={(e) =>
            handleChange(
              e,
              index,
              attributeIdx,
              "curtailmentPercentage",
              values,
              setValues
            )
          }
          inputStyle={columnStyle.dropdownStyle}
          styledPrepend={{padding: '10px'}}
        />
      </Col>
    </Fragment>
  );
};

export default PrincipalAmounts;
