import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Form, editProgramSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import { COLLATERAL_COVERAGES } from './/queries';
import { COLLATERAL_COVERAGE } from '../../../queries/viewQueries';
import DataState from '../../DataState';
import { useAlert } from '../../../hooks/useAlert';
import { setAlertData } from '../../../utils/setAlertData';
import BasicAlert from '../../../elements/BasicAlert';
import Loading from '../../../elements/Loading';

const UPDATE_COLLATERAL_COVERAGE = gql`
  mutation UpdateInsuranceProgram($id: ID!, $name: String!, $description: String,
  $expirable: String!, $gracePeriod: Int)
  {
    updateInsuranceProgram(
      id: $id
      name: $name
      description: $description
      expirable: $expirable
      gracePeriod: $gracePeriod
    ) {
      insuranceProgram { id }
      errors { message, path }
    }
  }
 `

const UpdateCollateralCoverage = (props) => {
  const id = parseInt(useParams().id);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const [updateCollateralCoverage, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_COLLATERAL_COVERAGE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: COLLATERAL_COVERAGES }],
      onCompleted(variables) {
        setFormSubmitting(false);
        setRedirect(true);
      }
    })


  const { loading, error, data } = useQuery(COLLATERAL_COVERAGE, {
    variables: { id },
  });

  if (loading || mutationLoading) return <DataState.Loading />;
  if (error || mutationError) return <DataState.Error error={error || mutationError} />;

  const { insuranceProgram } = data;

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    updateCollateralCoverage({ variables: values })
  };

  const UpdateForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/collateral-coverages",
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>{`Edit Collateral Coverage: ${insuranceProgram.name}`}</h1>
          </div>

          <Formik
            initialValues={{
              id,
              name: insuranceProgram.name || '',
              description: insuranceProgram.description || '',
              expirable: insuranceProgram.expirable || '',
              gracePeriod: insuranceProgram.gracePeriod || 0,
            }}
            validationSchema={editProgramSchema}
            onSubmit={handleSubmit}
          >
            <Form type='edit' />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <UpdateForm />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>Edit Collateral Coverage</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default UpdateCollateralCoverage;
