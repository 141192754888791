import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { gql, useQuery } from '@apollo/client';
import DataState from '../../DataState';

const FILE_UPLOAD = gql`
  query FileUpload($id: ID!) {
    fileUpload(id: $id) {
      id
      fileUrl
      name
    }
  }
`

const containerStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '600px',
  height: '900px'
}

const Show = ({ showView, setShowView, fileId }) => {
  const { loading, error, data } = useQuery(FILE_UPLOAD, { variables: { id: fileId } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const src = data.fileUpload.fileUrl

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onHide={() => setShowView(false)}
        show={showView}
      >
        <Modal.Header closeButton>
          {data.fileUpload.name}
        </Modal.Header>
        <Modal.Body>
          <embed src={src} style={containerStyle} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Show;
