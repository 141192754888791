// Add names here when released to production.
const productionFeatures = {
  doubleEntry: [
    'fiscalMonthCalendar',
  ],
  autopay: [],
};

// Check if feature is enabled. True if REACT_APP_ALL_FEATURES env var is
// 'true'. Otherwise, check the productionFeatures object. If the key that
// matches groupName is the true object, all features for that group are
// enabled. Otherwise, the feature is enabled if its name is included in the
// Array of featureNames for that object.
export const featureEnabled = (groupName, featureName) => {
  if (true === productionFeatures[groupName]) {
    return true;
  } else if (productionFeatures[groupName].includes(featureName)) {
    return true;
  } else if ('true' === process.env.REACT_APP_ALL_FEATURES) {
    return true;
  }

  return false;
}

export default featureEnabled;
