import { gql } from '@apollo/client';

export const DECRYPT_TOKEN = gql`
  mutation DecryptToken($token: String!) {
    decryptToken(
      token: $token
    ) {
      response
      result
      routingNumber
      accountNumber
      type
    }
  }
`;
