import React from 'react';
import { useParams } from 'react-router-dom';
import Notes from '../../../Notes';
import { USER_NOTES } from '../Queries';

const UserNotes = () => {
  const id = parseInt(useParams().ownerId);

  return (
    <Notes
      query={USER_NOTES}
      ownerType="user"
      ownerId={id}
      prefix={`/system/users/${id}/notes`}
      unhideable
    />
  );
};

export default UserNotes;
