import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import DataState from '../../DataState';
import { collateralCoverageSchema, Form } from './Form';
import { DEALER_LINE_COLLATERAL_COVERAGES } from '../../../queries/indexQueries';
import { DEALER_LINE_COLLATERAL_COVERAGE } from '../../../queries/viewQueries';
import Loading from '../../../elements/Loading';

const UPDATE_COLLATERAL_COVERAGE = gql`
  mutation UpdateDealerLineInsuranceProgram($id: ID!, $insuranceCompany: String,
  $dealerLineId: Int!, $insuranceProgramId: Int, $thirdParty: Boolean,
  $effectiveOn: ISO8601Date, $expiresOn: ISO8601Date) {
      updateDealerLineInsuranceProgram (
        id: $id
        insuranceCompany: $insuranceCompany
        dealerLineId: $dealerLineId
        insuranceProgramId: $insuranceProgramId
        thirdParty: $thirdParty
        effectiveOn: $effectiveOn
        expiresOn: $expiresOn
      ) {
        dealerLineInsuranceProgram {
          insuranceCompany
          thirdParty
          effectiveOn
          expiresOn
        }
        errors { message, path }
      }
    }
`

const UpdateCollateralCoverage = () => {
  const id = parseInt(useParams().collateralCoverageId);
  const dealerId = parseInt(useParams().id);
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const history = useHistory();
  const [updateColalteralCoverage] = useMutation(
    UPDATE_COLLATERAL_COVERAGE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: DEALER_LINE_COLLATERAL_COVERAGES, variables: { id: dealerId } }],
      onCompleted() {
        setFormSubmitting(false);
        setRedirect(true);
      }
    }
  )

  const { loading, error, data } = useQuery(DEALER_LINE_COLLATERAL_COVERAGE, { variables: { id } })

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { dealerLineInsuranceProgram } = data;

  const dealerLineId = parseInt(dealerLineInsuranceProgram.dealerLine.id);

  const insuranceProgramId = dealerLineInsuranceProgram.insuranceProgram?.id || '';

  const handleSubmit = (values) => {
    if (values.insuranceProgramId === '') values.insuranceProgramId = null;
    if (values.insuranceProgramId !== '') values.insuranceProgramId = parseInt(values.insuranceProgramId);
    if (values.effectiveOn === '') values.effectiveOn = null;
    if (values.expiresOn === '') values.expiresOn = null;

    setFormSubmitting(true)
    updateColalteralCoverage({ variables: values });
  };

  const UpdateForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/dealers/${dealerId}/collateral-coverages`,
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Update Collateral Coverage</h1>
          </div>
          <Formik
            initialValues={{
              id,
              dealerLineId,
              insuranceProgramId,
              thirdParty: dealerLineInsuranceProgram.thirdParty || false,
              effectiveOn: dealerLineInsuranceProgram.effectiveOn || '',
              expiresOn: dealerLineInsuranceProgram.expiresOn || '',
              insuranceCompany: dealerLineInsuranceProgram.insuranceCompany || '',
            }}
            validationSchema={collateralCoverageSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <UpdateForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default UpdateCollateralCoverage;
