import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { gql } from "@apollo/client";
import { Formik } from "formik";
import { Spacing, Typography } from "../../../styles";
import DataState from "../../DataState";
import { Form } from "./Form";
import Loading from "../../../elements/Loading";
import { useServerSideErrors } from "../../../hooks/useServerSideErrors";
import { SETTINGS, UPDATE_SETTINGS } from './queries';
import BasicAlert from '../../../elements/BasicAlert';
import { setAlertData } from '../../../utils/setAlertData';
import { useAlert } from '../../../hooks/useAlert';

const INT_VALUES = [
  'paymentTermId',
  'nextJeNumber',
  'autopayDays',
  'costOfFunds',
  'autopayBorrowedTitleNotificationDays',
  'autopayBorrowedTitlePullDays',
];

const Edit = () => {
  const [showAlert, displayAlert] = useAlert();

  const { loading, error, data, refetch } = useQuery(SETTINGS);

  const [updateSettings, { loading: formSubmitting }] = useMutation(
    UPDATE_SETTINGS,
    {
      onCompleted() { refetch(); displayAlert(); }
    }
  );

  const handleSubmit = (values) => {
    if (values.booksClosedPassword === '') delete values.booksClosedPassword

    INT_VALUES.forEach(key => {
      if (values[key] === '') {
        values[key] = null;
      } else {
        values[key] = parseInt(values[key]);
      }
    });

    updateSettings({ variables: values });
  }

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const {
    settings: {
      achUseTokens,
      achIncomingCompanyName,
      achIncomingTokenName,
      achIncomingCompanyId,
      achOutgoingCompanyName,
      achOutgoingTokenName,
      achOutgoingCompanyId,
      achOriginRouting,
      auctionAccessTitleAbsentId,
      auctionAccessTitlePresentId,
      autopayEnabled,
      autopayDays,
      autopayBorrowedTitleNotificationDays,
      autopayBorrowedTitlePullDays,
      booksClosedThrough,
      booksClosedPassword,
      paymentTerm,
      nextJeNumber,
      costOfFunds,
      delinquentThreshold,
      borrowedTitleMinimumCars,
      borrowedTitleCountLimit,
      borrowedTitlePrincipalLimit,
    },
    titleStatuses,
    paymentTerms
  } = data;

  const EditForm = ({ showAlert }) => {
    if (formSubmitting) {
      return <Loading />;
    } else {
      return (
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Edit Settings</h1>
          </div>
          <Formik
            initialValues={{
              achUseTokens: achUseTokens || false,
              achIncomingCompanyName: achIncomingCompanyName || "",
              achIncomingTokenName: achIncomingTokenName || "",
              achIncomingCompanyId: achIncomingCompanyId || "",
              achOutgoingCompanyName: achOutgoingCompanyName || "",
              achOutgoingTokenName: achOutgoingTokenName || "",
              achOutgoingCompanyId: achOutgoingCompanyId || "",
              achOriginRouting: achOriginRouting || "",
              auctionAccessTitleAbsentId: auctionAccessTitleAbsentId || "",
              auctionAccessTitlePresentId: auctionAccessTitlePresentId || "",
              autopayEnabled: autopayEnabled || false,
              autopayDays: autopayDays || "",
              autopayBorrowedTitleNotificationDays:
                autopayBorrowedTitleNotificationDays || '',
              autopayBorrowedTitlePullDays:
                autopayBorrowedTitlePullDays || '',
              booksClosedThrough: booksClosedThrough || '',
              booksClosedPassword: booksClosedPassword || '',
              paymentTermId: paymentTerm?.id || '',
              nextJeNumber: nextJeNumber || '',
              costOfFunds: costOfFunds || '',
              delinquentThreshold: delinquentThreshold || '',
              borrowedTitleMinimumCars: borrowedTitleMinimumCars,
              borrowedTitleCountLimit: borrowedTitleCountLimit,
              borrowedTitlePrincipalLimit: borrowedTitlePrincipalLimit,
            }}
            onSubmit={handleSubmit}
          >
            <Form titleStatuses={titleStatuses} paymentTerms={paymentTerms} />
          </Formik>
        </div>
      );
    }
  };

  return (
    <>
      { showAlert && <BasicAlert componentType='setting' type='saved' />}
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    </>
  );
};

export default Edit;
