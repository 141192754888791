import { gql } from '@apollo/client';
import { DEALER_MAIN_FIELDS } from '../components/Dealers/Queries';
import {
  TXN_LINE_FIELDS
} from 'components/src/components/Transactions/Lines/queries';
export {
  TXN_LINE_FIELDS
} from 'components/src/components/Transactions/Lines/queries';

export const NOTIFICATIONS = gql`
  query($ownerId: Int!, $ownerType: String) {
    notifications(ownerId: $ownerId, ownerType: $ownerType) {
      id
      notification
      link
      ownerId
      ownerType
      createdAt
    }
  }
`

export const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      ${DEALER_MAIN_FIELDS}
      annualReviewOn
      contractedLine
      availableCredit
      onAccountBalance
      reserveBalance
      autopay
      lotPhotoUpload { id, name, fileUrl }
      flooredCars(scopes: ["unpaid"]) { id }
      internalCreditScore
      licenseExpiresOn
      onTimePay
      nsfCount
      unverifiedAuditCarsCount
      unverifiedAuditCarsCount7Days: unverifiedAuditCarsCount(days: 7)
      averageDaysOnAccountTTM: averageDaysOnAccount(months: 12)
      averageDaysOnAccount90Days: averageDaysOnAccount(days: 90)
      averageDaysOnAccountUnpaid
      averageLoanTTM: averageLoanAmount(months: 12)
      averageLoan90Days: averageLoanAmount(days: 90)
      blackbookMinusFlooredAmount
      borrowedTitleDueCount(days: 7)
      grossYieldTTM: grossYield(months: 12)
      netYieldTTM: netYield(months: 12)
      netYield90Days: netYield(days: 90)
      timesLocked
      utilizationYoy
      flooredByMonthCounts {
        date
        count
      }
      recentAudits {
        id
        inspectionOn
        unitsSeen
        numberOfUnits
      }
      tempPrograms {
        id
        dealerLine {
          id
          dealer {
            id
          }
        }
        programName
        programType
        effectiveOn
        endOn
        creditLimitIncrease
        createdBy {
          id
          firstName
          lastName
        }
      }
      accountRep {
        id
        firstName
        lastName
        fullName
      }
      serviceRep {
        id
        firstName
        lastName
      }
      branch {
        id
        name
        code
      }
      dealerLine {
        autopayDisabled
      }
      dealerLinesAttributes {
        rateProgram {
          name
          id
        }
        dealerProgram {
          name
          id
        }
        activeRateProgram {
          id
          name
        }
        activeDealerProgram {
          id
          name
        }
        automatedFeeResourcesAttributes {
          name
          id
          automatedFee { id }
        }
        tempRateProgramEnd
        tempDealerProgramEnd
      }
      dealerPeople(scopes: ["visible"]) {
        creditScore
      }
    }
  }
`;

export const DEALER_TXN_LINES = gql`
query Dealer($id: ID!) {
  dealer(id: $id) {
    dealerTxns {
      id,
      type,
      reference,
      bankDate,
      purpose,
      voidable,
      amount
    }
  }
}
`

export const DEALER_FLOORED_CARS = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      name
      payableFees {
        id
        name
        amount
        due
      }
      unpaidFees {
        id
        name
        amount
        due
      }
      flooredCars(scopes: ["unpaid"]) {
        id
        dealer {
          id
          payableFees {
            id
            amount
            name
            due
          }
          unpaidFees {
            id
            amount
            name
            due
          }
        }
        unitNumber
        vin
        year
        make
        model
        loanDate
        currentDueOn
        termData { totalDays }
        currentPrincipal
        currentFees
        currentInterest
        currentCollateralCoverage
        arbitratedOn
        canCurtail
        scheduledAchId
      }
    }
  }
`
export const QUOTE_AMOUNTS = gql`
  query QuoteAmounts(
    $flooredCarId: Int!,
    $date: ISO8601Date,
    $paymentType: String
  ) {
    quoteAmounts(
      flooredCarId: $flooredCarId
      date: $date
      paymentType: $paymentType
    ) {
      principal, interest, insurance, fees, reserves
      feeDetails { due, id, name}
    }
  }
`

export const MARKET_DETAILS = gql`
  query MarketDetails($marketId: Int ) {
    marketDetails(
      marketId: $marketId
    ) {
      creditLimit
      tempIncreases
      currentAr
      utilization
      pendingPrincipal
      pendingOutgoing
      outstandingTa
      unpaidCount
    }
  }
`

export const DASHBOARD_CARS = gql`
  query DashboardCars($marketId: Int ) {
    dashboardCars(
      marketId: $marketId
    ) {
      flooredCarsCount
      pastDueCars {
        id,
        vin,
        scheduledAchs,
        currentDueOn,
        dealer {
          name,
          id
        }
      }
    }
  }
`

export const FEE_FIELDS = `
  id
  name
  chargeOn
  termType
  termNumber
  amount
  due
  earned
  paidAmount
  waivedAmount
  paid
  status
  earnsInterest
  canUserWaive
  flooredCar { id }
  dealer { id }
  createdAt, createdBy { fullName }
  updatedAt, updatedBy { fullName }
`;

export const FLOORED_CAR = gql`
query FlooredCar($id: ID!) {
  flooredCar(id: $id) {
    id
    car { year, make, model, color }
    market { id, timezone }
    loanDate
    name
    bosDate
    atlasDate
    termData { term, type, totalDays, day }
    carPhotoUpload { id, name, fileUrl }
    maxPayoffOn
    mileage
    blackbookValue
    sourceType
    sourceId
    sourceName
    bankAccountId
    loanAmount
    currentPrincipal
    currentDueOn
    externalPaymentMethod
    advanceType
    currentFees
    currentInterest
    currentCollateralCoverage
    currentReserves
    advanceTo
    repoLocation
    repoOn
    repoSoldOn
    arbitratedOn
    arbitrationReason
    arbitratedByName
    sotOn
    sotByName
    vin
    status
    statusOn
    funded
    fundingMethod
    scheduledAchs
    scheduledAchId
    scheduledAchDate
    scheduledAchAmount
    scheduledAchCarAmount
    scheduledAchType
    titleStatus
    fundedOn
    unitNumber
    make
    model
    year
    soldOn
    soldTo
    saleType
    financeCompanyName
    other
    soldToAuction {
      id
      name
    }
    soldToAuctionId
    fees {
      ${FEE_FIELDS}
    }
    rateProgram { name, id }
    dealerProgram { code, name, id }
    automatedFees { name, id }
    dealer {
      id
      name
      reserveBalance
      market { id }
      payableFees { id, name, amount, chargeOn, due }
    }
    lastAudit
    automatedFeeResourcesAttributes {
      name
      id
      automatedFee { id }
    }
    hasEverFunded
    canCurtail
  }
}`

export const FLOORED_CAR_TITLE_LOGS = gql`
  query FlooredCar($id: ID!) {
    flooredCar(id: $id) {
      vin
      titleLogs {
        shipment { shipmentAt }
        shipTo {
          shipToName, street, city, state, zip { value, formatted }
        }
        id
        titleStatus { name }
        createdBy { firstName, lastName, fullName }
        trackingNumber
        notes
        fileUpload { id, fileUrl }
        watermarkedFileUpload { id, fileUrl }
        createdAt
      }
    }
  }
`

export const HIDDEN_FLOORED_CAR_TITLE_LOGS = gql`
  query FlooredCar($id: ID!) {
    flooredCar(id: $id) {
      titleLogs(scopes: ["hidden"]) {
        id
        titleStatus { name }
        createdBy { firstName, lastName }
        trackingNumber
        notes
        createdAt
      }
    }
  }`

export const TITLE_LOG = gql`
  query TitleLog($id: ID!) {
    titleLog(id: $id) {
      id
      titleStatus { id }
      trackingNumber
      notes
      fileUpload { id, fileUrl, name, ownerId, ownerType }
      watermarkedFileUpload { id, fileUrl, name, ownerId, ownerType }
      shipTitle
      shipToType
      shipToId
    }
    currentUser { id }
  }
`

export const AUCTION = gql`
query Auction($id: ID!) {
  auction(id: $id) {
    id
    name
  }
}`

export const APPROVED_SOURCE = gql`
query ApprovedSource($id: ID!) {
  approvedSource(id: $id) {
    id
    name
  }
}`

export const TITLE_STATUS = gql`
query TitleStatus($id: ID!) {
  titleStatus(id: $id) {
    id
    name
    info
    isPresent
    toDealer
    autoFeeTitleAdded
    autoFeeTitleBorrowed
    autoFeeTitleShipped
    autoFeeTitleMarkedPresent
    fund
  }
}`

export const COLLATERAL_COVERAGE = gql`
query InsuranceProgram($id: ID!) {
  insuranceProgram(id: $id) {
    id
    name
    description
    expirable
    gracePeriod
  }
}`

export const COLLATERAL_COVERAGE_CHANGE = gql`
query InsuranceProgramChange($id: ID!) {
  insuranceProgramChange(id: $id) {
    rate
    effectiveOn
  }
}`

export const INSURANCE_STATE = gql`
query InsuranceState($id: ID!) {
  insuranceState(id: $id) {
    id
    name
    state
    defaultInsuranceProgram { id, name }
  }
}`

export const DEALER_LINE_COLLATERAL_COVERAGE = gql`
  query DealerLineInsuranceProgram($id: ID!) {
    dealerLineInsuranceProgram(id: $id) {
      id
      insuranceCompany
      dealerLine { id }
      thirdParty
      insuranceProgram { id }
      expiresOn
      effectiveOn
    }
  }
`

export const DEALER_DEALER_LINE = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      market { today }
      dealerLines { id }
    }
  }
`

export const ON_ACCOUNT_TXN_LINES = gql`
query TxnLines($dealerId: Int, $accountId: Int) {
  txnLines(dealerId: $dealerId, accountId: $accountId) {
    ${TXN_LINE_FIELDS}
  }
}`

export const DEALER_PROGRAM = gql`
  query DealerProgram($id: ID!) {
    dealerProgram(id: $id) {
      id
      description
      code
      termsAttributes {
        id
        order
        type
        termDays
        feeType
        deltas {
          id
          action
          data
          isImport
          createdAt
          updatedAt
          user
        }
        termLinesAttributes {
          id
          principalBelow
          curtailmentPercentage
          termLineFeesAttributes {
            id
            amount
            interval
            day
            earnType
            accountId
          }
        }
     }
    }
  }
`;
