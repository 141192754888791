import React, { useContext, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useHideableResource } from '../../../hooks/useHideableResource';
import { useAlert } from '../../../hooks/useAlert';
import DataTable from '../../../elements/DataTable';
import AuthContext from '../../../contexts/AuthContext';
import DataState from '../../DataState';
import AuctionInfo from '../Info';
import { AUCTION_CONTACTS, HIDDEN_AUCTION_CONTACTS } from '../../../queries/indexQueries'
import BasicAlert from '../../../elements/BasicAlert';
import { setUniqueAlertData } from '../../../utils/setAlertData';

const HIDE_CONTACT = gql`
  mutation HideContact($id: ID!) {
    hideContact(
      id: $id
    )
    {
      contact {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const UNHIDE_CONTACT = gql`
  mutation UnhideContact($id: ID!) {
    unhideContact(
      id: $id
    )
    {
      contact {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const MAKE_PRIMARY_CONTACT = gql`
  mutation MakePrimaryContact($id: ID!) {
    makePrimaryContact(
      id: $id
    )
    {
      contact {
        id
      }
      errors {
        message
        path
      }
    }
  }
`
const Contacts: () => React$Node = (props) => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();
  const auctionId = parseInt(useParams().id);
  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_AUCTION_CONTACTS,
    VISIBLE_QUERY: AUCTION_CONTACTS,
    HIDE_MUTATION: HIDE_CONTACT,
    UNHIDE_MUTATION: UNHIDE_CONTACT,
    variables: { id: auctionId }
  };
  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)
  const [makePrimary] = useMutation(MAKE_PRIMARY_CONTACT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AUCTION_CONTACTS,
        variables: { id: auctionId },
      }
    ],
  });

  const auth = useContext(AuthContext);

  let saleTimeAlertData = setUniqueAlertData(props, setAlertType, displayAlert);

  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const columns = [
    {
      title: 'First Name',
      visible: true
    },
    {
      title: 'Last Name',
      visible: true
    },
    {
      title: 'Mobile Phone',
      visible: true
    },
    {
      title: 'Office Phone',
      visible: true,
    },
    {
      title: 'Email',
      visible: true,
    },
    {
      title: 'Primary',
      visible: true,
    },
    {
      title: 'Title',
      visible: true,
    },
    {
      title: 'Receives Auction Availability?',
      visible: true,
    },
    {
      title: 'Send Funding Emails?',
      visible: true,
    },
    {
      title: 'Actions',
      visible: true
    },
  ]

  const dataSet = data.auction.contacts.map((contact) => {
    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${contact.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${contact.id}>Hide</a>`

    const actions = []
    if (auth.hasPolicy('Contact', 'update')) {
      actions.push(`<a href="/auctions/${auctionId}/contacts/${contact.id}/edit"/>Edit</a>`)
    }
    if (auth.hasPolicy('Contact', 'hide')) {
      actions.push(hiddenLink)
    }
    if (auth.hasPolicy('Contact', 'manage')) {
      contact.primary ? null : actions.push(`<a href='' id="make-primary" data-id=${contact.id}>Make Primary</a>`)
    }

    if (actions === []) {
      actions.push(' ')
    }

    $(document).on('click', '#make-primary', function (event) {
      let id = event.target.dataset.id
      id = parseInt(id, 10)
      makePrimary({ variables: { id: id } })
    })

    const formattedOfficePhone = contact.officePhone?.formatted || '';
    const formattedCellPhone = contact.mobilePhone?.formatted || '';
    const formattedEmail = `<a href="mailto:${contact.email}">${contact.email}</a>`
    const primary = contact.primary ? 'Yes' : ''
    const auctionAvailabilityIcon = contact.receivesAuctionAvailability ?
      `<i class="fas fa-check" style="color: #2b6448;"></i>` : ``
    const outgoingACHIcon = contact.receivesPaymentInformation ?
      `<i class="fas fa-check" style="color: #2b6448;"></i>` : ``
    return [
      contact.firstName,
      contact.lastName,
      formattedCellPhone,
      formattedOfficePhone,
      formattedEmail,
      primary,
      contact.title,
      auctionAvailabilityIcon,
      outgoingACHIcon,
      actions.join(" | ")
    ]
  })

  return (
    <>
      <Helmet>
        <title>Contacts</title>
      </Helmet>
      <AuctionInfo {...saleTimeAlertData} />
      {showAlert &&
        <BasicAlert componentType="Auction contact" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Contacts'}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
    </>
  );
};

export default Contacts;
