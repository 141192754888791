import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import DataState from '../DataState';
import carlot from '../images/carlot.webp';
import '../../styles/Source/showInfo.css';
import * as Style from '../../styles/Source/showInfo';
import * as StringHelper from '../../utils/stringHelpers';
import { setDate } from 'components/src/utils/dateHelpers';
import { APPROVED_SOURCE } from './queries';

const Info = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(APPROVED_SOURCE, { variables: { id } });
  const temp = useRouteMatch();

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { approvedSource } = data;
  const { name, phone } = approvedSource;

  const contactPhone = phone?.formatted;
  const primaryContact = approvedSource.primaryContact?.fullName || '';
  const email = approvedSource.email || '';
  const currentPrincipal = StringHelper.currency(approvedSource.flooredCarsAmount)

  return (
    <>
      <Container style={Style.auctionInfoContainer}>
        <Row>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>Approved Source:</Col>
              <Col align="right">{name}</Col>
            </Row>
            <Row className="info-row">
              <Col>Primary Contact:</Col>
              <Col align="right">{primaryContact}</Col>
            </Row>
            <Row className="info-row">
              <Col>Primary Contact Phone:</Col>
              <Col align="right">{contactPhone}</Col>
            </Row>
            <Row className="info-row">
              <Col>Primary Contact Email:</Col>
              <Col align="right">{email}</Col>
            </Row>
          </div>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>Current Principal Floored:</Col>
              <Col align="right">{currentPrincipal}</Col>
            </Row>
            <Row className="info-row">
              <Col>Intercreditor Agreement on File:</Col>
              <Col align="right">
                {approvedSource.agreementOnFile ? 'Yes' : 'No'}
              </Col>
            </Row>
            {approvedSource.agreementDate && (
              <Row className="info-row">
                <Col>Intercreditor Agreement Date:</Col>
                <Col align="right">
                  {setDate(approvedSource.agreementDate)}
                </Col>
              </Row>
            )}
            {approvedSource.agreementUpload && (
              <Row className="info-row">
                <Col>Intercreditor Agreement Upload:</Col>
                <Col align="right">
                  <a
                    href={approvedSource.agreementUpload.fileUrl}
                    target="_blank"
                  >
                    View
                  </a>
                </Col>
              </Row>
            )}
          </div>
        </Row>
      </Container>
      <div style={Style.bottomBorder} />
    </>
  )
}

export default Info;
