import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import AuthContext from '../../../contexts/AuthContext';
import AuthLink from 'components/src/components/AuthLink';
import DataState from '../../DataState';
import DataTables from '@preflighttech/preflight-tables';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import * as Buttons from '../../../elements/buttons';
import { useAlert } from '../../../hooks/useAlert';
import { titleCase } from '../../../utils/stringHelpers';
import { Typography, Spacing } from '../../../styles';
import * as yup from 'yup';
import moment from 'moment';
import '../../../styles/baseRates.css';
import { DateHelpers } from '../../../utils';
import { setAlertData } from '../../../utils/setAlertData';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { preflightTableStyles } from 'components/src/styles';

const UPDATE_BASE_RATE = gql`
  mutation UpdateBaseRate($id: ID!, $name: String!) {
    updateBaseRate(
      id: $id
      name: $name
    ) {
      baseRate {
        id
        name
      }
      errors {
        message
        path
      }
    }
  }
`

const BASE_RATE = gql`
  query BASE_RATE($id: ID!) {
    baseRate(id: $id) {
      id
      name
      createdBy { firstName lastName }
      baseRateChanges {
        id
        rate
        effectiveOn
        createdBy { fullName }
        updatedBy { fullName }
      }
      currentBaseRateChange {
        rate
      }
    }
  }
`;


const BaseRate = (props) => {
  const { id } = useParams();
  const newRoute = `/system/base-rates/${id}/changes/new`
  const [redirect, setRedirect] = useState(false);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_BASE_RATE,
    onCompleteRedirectTo: "/system/base-rates",
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);


  const { loading, error, data } = useQuery(BASE_RATE, { variables: { id } })
  const auth = useContext(AuthContext);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  let { baseRate } = data;

  const schema = yup.object({
    name: yup.string().max(80).required('Required')
  });

  const formatBaseRateAttributes = () => {
    if (!baseRate.baseRateChanges[0]) {

      baseRate.baseRateChanges = []
      baseRate.baseRateChanges.push({ id: '' })
      baseRate.baseRateChanges.push({ rate: 0 })
      baseRate.baseRateChanges.push({ effectiveOn: '' })
    }
  }

  // Sort by effectiveOn
  baseRate.baseRateChanges = baseRate.baseRateChanges.sort(function (a, b) {

    return new Date(b.effectiveOn) - new Date(a.effectiveOn);
  });

  const changeColumns = [
    {
      key: 'rate',
      label: 'Rate',
      value: ({value}) => `${value}%`,
    },
    {
      key: 'effectiveOn',
      label: 'Effective On',
      content: ({value}) => DateHelpers.setDate(value),
    },
    {
      key: 'createdBy',
      label: 'Created By',
      value: ({value}) => value.fullName,
    },
    {
      key: 'updatedBy',
      label: 'Updated By',
      value: ({value}) => value.fullName,
    },
    {
      key: 'actions',
      label: 'Actions',
      content: ({ entry }) => {
        const noEdit = new Date(entry.effectiveOn) <= new Date();

        return (
          <ResourceActionLinks
            prefix={`/system/base-rates/${id}/changes`}
            resource={entry}
            noRead
            noEdit={noEdit}
          >
            <AuthLink
              style={{paddingLeft: 5}}
              to={`/system/base-rates/${id}/changes/${entry.id}/deltas`}
              resource="Delta"
              action="read"
            >
              {noEdit ? '' : '| '} Deltas
            </AuthLink>
          </ResourceActionLinks>
        )
      }
    }
  ];

  const currentRate = baseRate.currentBaseRateChange?.rate;

  function BaseRateForm() {
    formatBaseRateAttributes();

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          id: baseRate.id,
          name: baseRate.name,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
              <div style={Spacing.formWrapper}>
                <div style={Spacing.formHeader}>
                  <h1 style={Typography.formHeader}>{`Update Base Rate: ${titleCase(baseRate.name)}`}</h1>
                </div>
              </div>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <div style={Spacing.buttonRow}>
                <Buttons.Cancel type="cancel" text="Cancel" />
                <Buttons.Standard type="submit" text="Submit" />
              </div>
            </Form>
          )}
      </Formik>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <>
        <ServerSideErrors />
        <BaseRateForm />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Edit {titleCase(baseRate.name)} Base Rate</title>
      </Helmet>
      <DisplayContent />
      <hr />
      <ResourcesHeader
        title="Base Rate Changes"
        subtitle={`Current Rate: ${currentRate || 0}%`}
        prefix={`/system/base-rates/${id}/changes`}
        resourceType="BaseRateChange"
      />
      <DataTables.Simple
        data={data.baseRate.baseRateChanges}
        columns={changeColumns}
        pageLength={10}
        htmlTable
        movableColumns
        multiSort
        styles={preflightTableStyles}
      />
    </>
  );
};

export default BaseRate;
