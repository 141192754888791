export const classNames = (errors, touched, name) => {
  if ((errors['baseRateChangesAttributes'] && errors['baseRateChangesAttributes'][0][name]) && (touched['baseRateChangesAttributes'] && touched['baseRateChangesAttributes'][0][name])) {
    return 'form-control is-invalid'
  } else if (touched['baseRateChangesAttributes'] && touched['baseRateChangesAttributes'][0][name]) {
    return 'form-control is-valid'
  }
  else {
    return 'form-control'
  }
}
