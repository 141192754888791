import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import * as Buttons from '../../elements/buttons';
import * as BasicForm from '../../elements/forms';
import { Spacing, Typography } from '../../styles';
import DataState from '../DataState';
import { StringHelpers } from '../../utils/';
import { CURRENT_USER } from '../../queries/commonQueries';

const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($firstName: String, $lastName: String,
    $notificationsSms: Boolean!, $notificationsEmail: Boolean! ) {
    updateCurrentUser(
      firstName: $firstName
      lastName: $lastName
      notificationsSms: $notificationsSms
      notificationsEmail: $notificationsEmail
    ) {
      user {
        id
      }
    }
  }
`;

function Profile() {
  const { loading, error, data } = useQuery(CURRENT_USER);
  const history = useHistory();
  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER,
    {
      onCompleted() {
        history.push({
          pathname: '/',
          state: { alert: true, type: 'changed', componentType: 'profile' },
        })
      }
    });

  if (loading) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    id,
    firstName,
    lastName,
    notificationsEmail,
    notificationsSms
  } = data.currentUser;

  const ProfileForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Update Profile</h1>
        </div>
        <Formik
          initialValues={{
            id: id,
            firstName: firstName || '',
            lastName: lastName || '',
            notificationsEmail: notificationsEmail || false,
            notificationsSms: notificationsSms || false,
          }}
          onSubmit={handleSubmit}
        >
          <FormikForm>
            <BasicForm.TextInput
              name="firstName"
              type="text"
              label="First Name"
            />
            <BasicForm.TextInput
              name='lastName'
              type='text'
              label="Last Name"
            />
            <BasicForm.Checkbox
              name="notificationsSms"
              type="checkbox"
              label="Receive Notifications Via Text Message?"
            />
            <BasicForm.Checkbox
              name="notificationsEmail"
              type="checkbox"
              label="Receive Notifications Via Email?"
            />
            <div style={Spacing.buttonRow}>
              <Buttons.Cancel type="cancel" text="Cancel" />
              <Buttons.Standard type="submit" text="Submit" />
            </div>
          </FormikForm>
        </Formik>
      </div>
    )
  }

  const handleSubmit = (values) => {
    updateCurrentUser({ variables: values });
  };

  return (
    <>
      <div style={Spacing.formContainer}>
        <ProfileForm />
      </div>
    </>
  );
}

export default Profile;
