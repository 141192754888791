import React, { createContext, Component } from 'react';

import { CURRENT_USER } from 'components/src/queries/commonQueries';

const AuthContext = createContext();

export class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined
    }

    this.getUser().then(user => this.setState({user}));
  }

  async getUser() {
    if (!this.props.token) return undefined;
    if (this.state.user) return this.state.user;

    const { data } = await this.props.client.query({query: CURRENT_USER});

    if (data.settingsForUi) {
      data.currentUser.currentFiscalYear =
        data.settingsForUi.currentFiscalYear;
      data.currentUser.currentFiscalQuarter =
        data.settingsForUi.currentFiscalQuarter;
    }

    return data.currentUser;
  }

  hasPolicy(resource, action) {
    if (!this.state.user) return false;

    const policies = this.state.user.policies;

    const resource_policies = policies
      .find(policy_data => policy_data.resource === resource);

    const resource_action = resource_policies && resource_policies.actions.
      find(action_data => {
        return (
          (action_data.name === action || action_data.name === 'manage') &&
          action_data.params?.length
        );
      });

    const all_policies = policies
      .find(policy_data => policy_data.resource === 'All');

    const all_action = all_policies && all_policies.actions.
      find(action_data => {
        return (
          (action_data.name === action || action_data.name === 'manage') &&
          action_data.params?.length
        );
      });

    return (!!(resource_action || all_action));
  }

  hasExactPolicy(resource, action) {
    if (!this.state.user) return false;

    const policies = this.state.user.policies;

    const resource_policies = policies
      .find(policy_data => policy_data.resource === resource);

    const resource_action = resource_policies && resource_policies.actions.
      find(action_data => (
        action_data.name === action && action_data.params?.length)
      );

    return (!!(resource_action));
  }

  render() {
    const value = {
      hasPolicy: (resource, action) => this.hasPolicy(resource, action),
      hasExactPolicy:
        (resource, action) => this.hasExactPolicy(resource, action),
      user: this.state.user,
      currentFiscalYear: this.state.user?.currentFiscalYear,
      currentFiscalQuarter: this.state.user?.currentFiscalQuarter,
    };

    return (
      <AuthContext.Provider value={value}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;
