import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery} from '@apollo/client';
import { Form as FormikForm } from 'formik';
import { useFormikContext } from 'formik';
import { Spacing, Typography } from '../../../styles';
import * as BasicForm from '../../../elements/forms';
import { Colors } from '../../../styles';
import { DateHelpers } from '../../../utils';
import { repoSchema, Form } from './Form';
import { FLOORED_CAR } from '../../../queries/viewQueries';
import { CURRENT_USER } from '../../../queries/currentUser';
import DataState from '../../DataState';
import AuthContext from 'components/src/contexts/AuthContext';

import { REPO } from 'components/src/components/Cars/Queries';

const RepoModal = (props) => {
  let {
    show,
    handleClose,
    name,
    createRepo,
    refetch,
    showPageRefetch,
    flooredCar
  } = props;

  const id = parseInt(useParams().carId);

  const auth = useContext(AuthContext);

  const [repo] = useMutation(REPO, {
    onCompleted(variables) {
      refetch();
      showPageRefetch();
      handleClose();
    }
  });

  const handleSubmit = (values) => {
    if (values.repoOn === '') values.repoOn = null;

    repo({variables: values})
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`Repo ${name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={Spacing.formWrapper}>
          <Formik
            initialValues={{
              id,
              repoOn: flooredCar.repoOn || '',
              repoLocation: flooredCar.repoLocation || '',
              repoBy: auth.user.fullName,
              repoSold: !!flooredCar.repoSoldOn,
            }}
            validationSchema={
              repoSchema({
                repoOn: flooredCar.repoOn, loanDate: flooredCar.loanDate
              })
            }
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RepoModal;
