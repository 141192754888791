import { gql } from '@apollo/client';

export const ACCOUNTS = gql`
  query {
    accounts(scopes: ["bank"]) {
      id
      name
      gl
    }
  }
`;

export const AUCTION_BANK_ACCOUNTS = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      id
      bankAccounts {
        id
        accountName
        accountNumberMasked
      }
    }
  }
`;

export const APPROVED_SOURCE_BANK_ACCOUNTS = gql`
  query ApprovedSource($id: ID!) {
    approvedSource(id: $id) {
      id
      bankAccounts {
        id
        accountName
        accountNumberMasked
      }
    }
  }
`;

export const DEALER_BANK_ACCOUNTS = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      bankAccounts {
        id
        accountName
        accountNumberMasked
      }
    }
  }
`;

export const AUCTIONS = gql`
  query {
    auctions {
      id
      name
    }
  }
`;

export const APPROVED_SOURCES = gql`
  query {
    approvedSources {
      id
      name
    }
  }
`;

export const DEALERS = gql`
  query {
    dealers {
      id
      name
    }
  }
`;
