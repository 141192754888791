import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import * as StringHelpers from '../../../utils/stringHelpers';
import { Colors } from '../../../styles';
import { Spacing } from '../../../styles';
import DataState from '../../DataState';
import { useFormikContext } from 'formik';
import { equalTo } from '../../../utils/passwordMatchValidation';
import Select from 'react-select';

const ROLES = gql`
  query {
    roles { id, name }
    markets { id, name }
    users { id, fullName }
  }
`;

yup.addMethod(yup.string, 'equalTo', equalTo);

export const newUserSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Required'),
  firstName: yup
    .string()
    .required('Required'),
  lastName: yup
    .string()
    .required('Required'),
  mobilePhone: yup
    .string()
    .matches(StringHelpers.phoneRegExp, { message: "Please enter a valid phone number.", excludeEmptyString: false }),
  password: yup
    .string()
    .required('Required')
    .min(8, 'Password must have 8 or more characters')
    .matches(StringHelpers.specialCharacterRegExp, { message: "Password must contain at least one special character" }),
  passwordConfirmation: yup
    .string()
    .equalTo(yup.ref('password'), 'Passwords must match')
    .required('Passwords must match')
});

export const updateUserSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Required'),
  firstName: yup
    .string()
    .required('Required'),
  lastName: yup
    .string()
    .required('Required'),
  mobilePhone: yup
    .string()
    .matches(StringHelpers.phoneRegExp, { message: "Please enter a valid phone number.", excludeEmptyString: false }),
  password: yup
    .string()
    .min(8, 'Password must have 8 or more characters')
    .matches(StringHelpers.specialCharacterRegExp, { message: "Password must contain at least one special character" }),
  passwordConfirmation: yup
    .string()
    .equalTo(yup.ref('password'), 'Passwords must match'),
  hasWaiverLimit: yup
    .boolean(),
  waiverLimit: yup
    .number()
    .when('hasWaiverLimit', {
      is: false,
      then: yup.number().max(0)
        .required('field is required')
    }),
});

export const UserForm = () => {
  const { values, setFieldValue, setValues, errors, isValid } = useFormikContext();
  const { loading, error, data } = useQuery(ROLES);
  const [formSubmitting, setFormSubmitting] = useState();
  const [disabledItems, setDisabledItems] = useState(true);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const handleEnableItems = (disabledItems, setDisabledItems) => {
    setDisabledItems(!disabledItems);
  }

  const handleChangeRole = (selectedList) => {
    if (!selectedList) {
      values.roleIds = [];
      setValues(values)
      return
    }

    selectedList = selectedList.map((role) => {
      return parseInt(role.value, 10)
    })

    values.roleIds = selectedList
    setValues(values)
  }

  const preSelectedRoleIds = values.roleIds.map((roleId) => {
    const role = data.roles.find((value) => parseInt(value.id, 10) === roleId)
    return { value: role.id, label: role.name }
  })

  const formattedRoles = data.roles.map((role) => {
    return { value: role.id, label: role.name }
  })

  const handleChangeMarket = (selectedList) => {
    if (!selectedList) {
      values.marketIds = [];
      setValues(values)
      return
    }

    selectedList = selectedList.map((market) => {
      return parseInt(market.value, 10)
    })

    values.marketIds = selectedList
    setValues(values)
  }

  const preSelectedMarketIds = values.marketIds.map((marketId) => {
    const market = data.markets.find((value) => parseInt(value.id, 10) === marketId)
    return { value: market.id, label: market.name }
  })

  const formattedMarkets = data.markets.map((market) => {
    return { value: market.id, label: market.name }
  })

  const customStyles = {
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: Colors.xlGreen,
      color: 'white',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      backgroundColor: Colors.xlGreen,
      color: 'white'
    })
  }

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="firstName"
        type="text"
        label="First name"
      />
      <BasicForm.TextInput
        name="lastName"
        type="text"
        label="Last Name"
      />
      <BasicForm.PhoneInput
        name="mobilePhone"
        label="Mobile Number"
        onChange={(event) =>
          StringHelpers.handleNumberChange(event, 'mobilePhone', setFieldValue)
        }
        value={values.mobilePhone || ''}
      />
      <BasicForm.TextInput
        name="email"
        type="text"
        label="Email"
      />
      <BasicForm.TextInput
        name="password"
        type="password"
        label="Password"
      />
      <BasicForm.TextInput
        name='passwordConfirmation'
        type='password'
        label="Retype Password"
      />
      <BasicForm.StyledLabel>Roles</BasicForm.StyledLabel>
      <Select
        options={formattedRoles}
        defaultValue={preSelectedRoleIds}
        isMulti={true}
        onChange={handleChangeRole}
        styles={customStyles}
      />
      <BasicForm.StyledLabel>Markets</BasicForm.StyledLabel>
      <Select
        options={formattedMarkets}
        defaultValue={preSelectedMarketIds}
        isMulti={true}
        onChange={handleChangeMarket}
        styles={customStyles}
      />
      <BasicForm.ProgrammaticSelect
        name="supervisorId"
        label="Supervisor"
        optionName="Supervisor"
        options={data.users}
      />
      <BasicForm.Checkbox
        name="hasWaiverLimit"
        type="checkbox"
        label="Waiver Limit?"
        onClick={() => handleEnableItems(disabledItems, setDisabledItems)}
      />
      <BasicForm.DollarInput
        name="waiverLimit"
        type="float"
        label="Waiver Limit Amount"
        disabled={disabledItems}
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}


