import { gql } from '@apollo/client';
import {
  WITH_COUNT_VARIABLES, WITH_COUNT_ARGUMENTS
} from 'components/src/queries/withCount';

export const TXN_LINE_FIELDS = `
  id
  earnedOn
  market { id, name }
  dealer { id, name }
  flooredCar { id, vin, dealer { id } }
  fee { id, name }
  txn { createdDate, id, type, reference, purpose }
  purpose
  account { id, gl, name }
  department { id, name }
  debitLessCredit
  debit
  credit
  earnsInterest
  memo
`;

export const TXN_LINES_WITH_COUNT = gql`
  query(
    ${WITH_COUNT_VARIABLES}
  ) {
    txnLinesWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ${TXN_LINE_FIELDS}
      }
      count
      accounts { value, label }
      departments { value, label }
      markets { value, label }
    }
  }
`;
