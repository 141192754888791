import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { useAlert } from '../../hooks/useAlert';
import DataState from '../DataState';
import { dealerSchema, Form } from './Form';
import Loading from '../../elements/Loading';
import { useServerSideErrors } from '../../hooks/useServerSideErrors'
import AuthContext from '../../contexts/AuthContext';
import { DEALER_VARIABLES, DEALER_ARGUMENTS } from './Queries';

const DEALER = gql`
  query DEALER($id: ID!) {
    dealer(id: $id) {
      id,
      name,
      accountNumber,
      legalName,
      licenseNumber,
      contractedOn,
      licenseExpiresOn,
      annualReviewOn,
      internalCreditScore,
      licenseType
      poaReceived
      recentBusinessTaxYear
      businessFinancialsDate
      recentPersonalTaxYear
      personalFinancialsDate
      dealerLine {
        id
        creditLine
        contractedLine
        reservesAmount
        autopayDisabled
      },
      dealerLinesAttributes {
        rateProgram {
          name
          id
        }
        dealerProgram {
          name
          id
        }
        automatedFeeResourcesAttributes {
          name
          id
          automatedFee { id }
        }
      }
      serviceRep {
        id
        firstName
        lastName
      }
      accountRep {
        id
        firstName
        lastName
      }
      branch {
        id
        name
        code
      }
      market {
        id
        name
      }
      entityType
      entityTypeOther
      dealerCategoryIds
      borrowedTitleOverride
      borrowedTitleMinimumCars
      borrowedTitleCountLimit
      borrowedTitlePrincipalLimit
    }
  }
`;

export const UPDATE_DEALER = gql`
  mutation UpdateDealer(
    $id: ID!
    ${DEALER_VARIABLES}
   ) {
    updateDealer(
      id: $id
      ${DEALER_ARGUMENTS}
    ) {
      dealer {
        id
        name
      }
      errors {
        message
        path
      }
    }
  }
`;

const UPDATE_AUTOPAY_DISABLED = gql`
  mutation UpdateDealerLineAutopayDisabled(
    $id: ID!
    $autopayDisabled: Boolean!
  ) {
    updateDealerLineAutopayDisabled(
      id: $id
      autopayDisabled: $autopayDisabled
    ) {
      dealerLine { id }
      errors { message, path }
    }
  }
`;

const Edit = () => {
  const auth = useContext(AuthContext);

  const { id } = useParams();
  const { loading, error, data, refetch } = useQuery(DEALER, { variables: { id } });

  const useAutopayDisabledMutation = (
    !auth.hasPolicy('Dealer', 'update') &&
    auth.hasExactPolicy('Dealer', 'exclude_from_autopay')
  );

  const customSubmitHandler = (values) => {
    if (useAutopayDisabledMutation) {
      values.id = parseInt(data.dealer.dealerLine.id);
      return;
    }

    if ('Other' !== values.entityType) {
      values.entityTypeOther = '';
    }

    if ('' === values.entityType) {
      values.entityType = null;
    }

    if (values.automatedFeeResourcesAttributes?.length === 0) delete values.automatedFeeResourcesAttributes;

    if (values.automatedFeeResourcesAttributes) {
      values.dealerLinesAttributes = [{
        name: 'Main',
        id: parseInt(data.dealer.dealerLine.id),
        rateProgramId: values.rateProgramId,
        dealerProgramId: values.dealerProgramId,
        creditLine: parseFloat(values.creditLine),
        contractedLine: (
          values.contractedLine ? parseFloat(values.contractedLine) : null
        ),
        reservesAmount: parseFloat(values.reservesAmount),
        autopayDisabled: values.autopayDisabled,
        automatedFeeIds: values.automatedFeeResourcesAttributes.map(fee => {
          return parseInt(fee.id)
        })
      }]
    } else {
      values.dealerLinesAttributes = [{
        name: 'Main',
        id: parseInt(data.dealer.dealerLine.id),
        rateProgramId: values.rateProgramId,
        dealerProgramId: values.dealerProgramId,
        creditLine: parseFloat(values.creditLine),
        contractedLine: (
          values.contractedLine ? parseFloat(values.contractedLine) : null
        ),
        reservesAmount: parseFloat(values.reservesAmount),
        autopayDisabled: values.autopayDisabled,
      }]
    }
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: (
      useAutopayDisabledMutation ? UPDATE_AUTOPAY_DISABLED : UPDATE_DEALER
    ),
    onCompleteRedirectTo: `/dealers/${id}`,
    customSubmitHandler,
    componentType: 'dealer',
    REFETCH_QUERY: DEALER,
    refetchVariable: id,
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const [showAlert, displayAlert] = useAlert();

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    name,
    accountNumber,
    auditStatus,
    legalName,
    licenseNumber,
    contractedOn,
    licenseExpiresOn,
    insuranceExpiresOn,
    annualReviewOn,
    internalCreditScore,
    licenseType,
    poaReceived,
    recentBusinessTaxYear,
    businessFinancialsDate,
    recentPersonalTaxYear,
    personalFinancialsDate,
  } = data.dealer;

  const creditLine = parseFloat(data.dealer.dealerLine?.creditLine);
  const contractedLine = data.dealer.dealerLine?.contractedLine ?
    parseFloat(data.dealer.dealerLine.contractedLine) : null
  const reservesAmount = parseFloat(data.dealer.dealerLine?.reservesAmount);
  const branchId = parseInt(data.dealer.branch.id);
  const marketId = parseInt(data.dealer.market.id);
  const accountRepId = parseInt(data.dealer.accountRep?.id);
  const serviceRepId = parseInt(data.dealer.serviceRep?.id);
  const rateProgramId = parseInt(data.dealer.dealerLinesAttributes[0].rateProgram?.id)
  const dealerProgramId = parseInt(data.dealer.dealerLinesAttributes[0].dealerProgram?.id)
  const preSelectedAutomatedFees = data.dealer.dealerLinesAttributes[0].automatedFeeResourcesAttributes.map(
    fee => {
      return { value: parseInt(fee.automatedFee.id), label: fee.name }
    }
  )

  const EditForm = () => {
    if (formSubmitting) {
      return (
        <Loading />
      )
    } else {

      return (
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Edit Dealer: {name}</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              id: id,
              name: name || '',
              legalName: legalName || '',
              accountNumber: accountNumber || null,
              licenseNumber: licenseNumber || '',
              contractedOn: contractedOn || null,
              licenseExpiresOn: licenseExpiresOn || null,
              annualReviewOn: annualReviewOn || null,
              branchId: branchId || null,
              marketId: marketId || null,
              accountRepId: accountRepId || null,
              serviceRepId: serviceRepId || null,
              internalCreditScore: internalCreditScore || '',
              licenseType: licenseType || '',
              poaReceived: poaReceived,
              recentBusinessTaxYear: recentBusinessTaxYear || '',
              businessFinancialsDate: businessFinancialsDate || null,
              recentPersonalTaxYear: recentPersonalTaxYear || '',
              personalFinancialsDate: personalFinancialsDate || null,
              creditLine: creditLine || 0,
              contractedLine: contractedLine || null,
              reservesAmount: reservesAmount || 0,
              autopayDisabled: data.dealer.dealerLine?.autopayDisabled,
              rateProgramId: rateProgramId || null,
              dealerProgramId: dealerProgramId || null,
              automatedFeeIds: preSelectedAutomatedFees || null,
              entityType: data.dealer.entityType || '',
              entityTypeOther: data.dealer.entityTypeOther || '',
              dealerCategoryIds: data.dealer.dealerCategoryIds || [],
              borrowedTitleOverride: data.dealer.borrowedTitleOverride,
              borrowedTitleMinimumCars: data.dealer.borrowedTitleMinimumCars,
              borrowedTitleCountLimit: data.dealer.borrowedTitleCountLimit,
              borrowedTitlePrincipalLimit:
                data.dealer.borrowedTitlePrincipalLimit,
            }}
            validationSchema={dealerSchema}
            onSubmit={handleSubmit}
          >
            <Form
              preSelectedAutomatedFees={preSelectedAutomatedFees}
            />
          </Formik>
        </div>
      )
    }
  }

  return (
    <>
      {showAlert &&
        <Alert variant='success' style={Spacing.alerts}>
          This dealer has been updated.
        </Alert>
      }
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    </>
  );
};

export default Edit;
