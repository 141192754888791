import * as Colors from '../colors';

export const auctionInfoContainer = {
  width: '100%',
  maxWidth: '100%',
  fontSize: '0.95rem',
  marginTop: '10px'
}

export const greenText = {
  color: Colors.xlGreen,
}

export const blueText = {
  color: 'blue',
  fontSize: '0.9rem',
  marginLeft: '2%'
}

export const boldText = {
  fontWeight: 750,
  marginLeft: '2%',
}

export const saleInfo = {
  fontSize: '0.9rem',
  marginLeft: '2%'
}

export const bottomBorder = {
  height: '5px',
  width: '100%',
  backgroundColor: Colors.xlOrange,
  marginTop: '20px',
}
