import { gql } from '@apollo/client'

const ERRORS = `errors { path, message }`;

const AUDIT_FIELDS = `
  id
  quiktrakJobNumber
  inspector
  inspectionOn
  inspectorComment
  missingCars
  numberOfUnits
  unitsUnseen
  unverifiedAuditCarsCount
  unverifiedAuditCarsValue
  missingUnitValue
  totalCurrentBalance
  auditClosedDate
  foundPercentage
  dealer {
    id
    name
    dealerStatus { name }
    auditStatus
  }
`;

const AUDIT_FIELDS_AND_ERRORS = `
  audit {
    ${AUDIT_FIELDS}
  }
  ${ERRORS}
`;

export const AUDIT_CAR_FIELDS = `
  id
  verified
  companyName
  personName
  flooredCar {
    id
    financeCompanyName
    financeCompanyContact
    soldOn
    unitNumber
    vin
    name
    webPath
    dealer {
      id
      name
      primaryPlace {
        street
        city
        state
        zip { value }
      }
    }
    termData {
      totalDays
    }
  }
  rqid
  qtFound
  qtException
  qtStatus
  qtNotes
  qtExceptionType
  qtExceptionSubtype
  qtExceptionCode
  qtExcpAddress1
  qtExcpAddress2
  qtCustomerName
  qtFinanceName
  latitude
  longitude
  auditedByType
  auditedById
  auditedAt
  verificationMethod
  location
`;

export const AUDITS = gql`
  query Audit($dealerId: Int) {
    audits(dealerId: $dealerId) {
      ${AUDIT_FIELDS}
      auditCars {
        ${AUDIT_CAR_FIELDS}
      }
    }
  }
`;

export const AUDIT = gql`
  query Audit($id: ID!) {
    audit(id: $id) {
      ${AUDIT_FIELDS}
      auditCars {
        ${AUDIT_CAR_FIELDS}
      }
    }
  }
`;

export const CLOSE_AUDIT = gql`
  mutation CloseAudit(
    $id: ID!
    $verificationMethod: String!
  ) {
    closeAudit(
      id: $id,
      verificationMethod: $verificationMethod
    ) {
      ${AUDIT_FIELDS_AND_ERRORS}
    }
  }
`;

export const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      dealerStatus { name }
      dealerCategories { name }
      legalName
      lastSixFoundPercentage
    }
  }
`
