import React from 'react';
import * as BasicForm from "../../../../elements/forms";

const IntervalInput = ({ index, attributeIdx, values, columnStyle }) => {
  return <BasicForm.TextInput
    type="number"
    style={columnStyle.dropdownStyle}
    name={`termsAttributes[${index}].termLinesAttributes[${attributeIdx}].termLineFeesAttributes[0].interval`}
  />
}

export default IntervalInput
