import React, { createContext, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { View, Button, Text, TouchableHighlight } from 'react-native';
import { ApolloLink } from '@apollo/client';
import { VERSION_KEY, VERSION_AT_KEY } from 'components/src/constants';
import secrets from 'components/src/secrets';
import axios from 'axios';

const VERSION = secrets.app.version;
const VERSION_DEBUG_ENABLED = false;

const FORCE_CHECK_MS = 15 * 60 * 60 * 1000;
const FORCE_FIRST_CHECK_MS = 30 * 1000;
const LOADED = new Date().getTime();
const CHECK_VERSION_MS = 10000;
const DISMISS_MAX_MS = 60 * 60 * 1000;

const versionDebug = message => {
  if (VERSION_DEBUG_ENABLED) {
    console.log(`${new Date().getTime()} ${message}`);
  }
};

const VersionContext = createContext();

const VersionAlert = styled(View)`
  z-index: 1000;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 300px;
  height: 100px;
  border: 2px solid black;
  background-color: white;
  padding: 10px;
`;

const DismissLink = styled(Text)`
  text-align: right;
  color: gray;
`;

export const checkVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const context = operation.getContext();

    if (context.response?.headers) {
      const version = context.response.headers.get(VERSION_KEY);

      if (version) {
        localStorage.setItem(VERSION_KEY, version);
        localStorage.setItem(VERSION_AT_KEY, new Date().getTime().toFixed());
        localStorage.setItem(
          'X-User-Log-Id',
          context.response.headers.get('X-User-Log-Id')
        );
      }
    }

    return data;
  });
});

/**
 * Call /health api path to get version headers. This is intended for use if no
 * action is being taken by the user to generate API calls, but should only be
 * checked periodically, such as by checkVersionInLocalStorage based on
 * FORCE_CHECK_MS or FORCE_FIRST_CHECK_MS (which might happen when the page is
 * loaded and sitting at the login screen, but no requests have been made.
 */
const restCheck = () => {
  versionDebug('restCheck');

  axios({ method: 'get', url: `${secrets.api.baseUri}/health` })
    .then(response => {
      const version = response.headers[VERSION_KEY.toLowerCase()];

      if (version) {
        localStorage.setItem(VERSION_KEY, version);
        localStorage.setItem(VERSION_AT_KEY, new Date().getTime().toFixed());
      }
    });
};

export const VersionProvider = ({ children }) => {
  const [updateAvailable, setUpdateAvailable] = useState();
  const [dismissedAt, setDismissedAt] = useState();

  /**
   * Called when VERSION is out of date. Tries to force an un-cached reload.
   */
  const loadNewVersion = () => {
    if (['/', ''].includes(window.location.pathname)) {
      window.location.reload(true);
    } else {
      window.location.assign(`/?version=${updateAvailable || VERSION}`);
    }
  };

  /**
   * Given the newVersion, determine is an update is available by comparing to
   * the current VERSION. Assumes that version numbers are ints or floats, not
   * semantic version strings. If the newVersion ends in !, that implies that
   * an update should be forced and a force reload is attempted. Other, the
   * updateAvailable state variable is set in order to display the new Version
   * Available Alert.
   */
  const checkUpdateAvailable = newVersion => {
    versionDebug('checkUpdateAvailable');

    if (dismissedAt && (new Date() - dismissedAt > DISMISS_MAX_MS)) {
      setDismissedAt(false);
    }

    if (!newVersion) { return; }

    if (parseFloat(newVersion) <= VERSION) {
      return;
    }

    setUpdateAvailable(newVersion);

    if (newVersion.endsWith('!')) {
      loadNewVersion();
    }
  };

  /**
   * Check the version value stored in localStorage from the headers of the most
   * recent API request, and the timestamp of that request.
   *
   * - If there is a previous check but it has been too long ago (based on
   *   FORCE_CHECK_MS), make a rest call to a cimply endpoint to get updated
   *   headers.
   * - If there is a recent previous check, pass to checkUpdateAvailable to see
   *   if alert should be displayed.
   * - If no checks recorded and time has elapsed beyond FORCE_FIRST_CHECK_MS,
   *   call simple rest check.
   *
   * rest checks will update localStorage if successful which this call will see
   * on its next run.
   */
  const checkVersionInLocalStorage = () => {
    versionDebug('checkVersionInLocalStorage');

    const versionFromAPI = localStorage.getItem(VERSION_KEY);
    const versionFromAPIAt = localStorage.getItem(VERSION_AT_KEY);
    const now = new Date().getTime();

    if (versionFromAPIAt) {
      if (now - parseInt(versionFromAPIAt) > FORCE_CHECK_MS) {
        localStorage.setItem(VERSION_AT_KEY, now.toFixed());
        restCheck();
      } else {
        checkUpdateAvailable(versionFromAPI);
      }
    } else {
      if (now - LOADED > FORCE_FIRST_CHECK_MS) {
        localStorage.setItem(VERSION_AT_KEY, now.toFixed());
        restCheck();
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(checkVersionInLocalStorage, CHECK_VERSION_MS);
    return () => clearInterval(interval);
  });

  let showAlert = updateAvailable && !dismissedAt;

  return (
    <VersionContext.Provider>
      {children}

      {showAlert && (
        <VersionAlert>
          <Text>A New Version of ATLAS is available.</Text>

          <Button onPress={loadNewVersion} title="Click here to update" />

          <TouchableHighlight onPress={() => setDismissedAt(new Date())}>
            <DismissLink>Dismiss</DismissLink>
          </TouchableHighlight>
        </VersionAlert>
      )}
    </VersionContext.Provider>
  );
};

export default VersionContext;
