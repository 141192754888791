import React, { useState } from 'react';
import { View, Pressable, ScrollView, Text } from 'react-native';
import MediaQuery from 'react-native-web-responsive';
import SimpleSortedTable from './SimpleSortedTable';
import { useParams } from 'react-router-dom';
import DataState from 'components/src/components/DataState';
import { useQuery } from '@apollo/client';
import { DEALER_REPORT } from './queries';
import dasherizeKeys from 'components/src/utils/dasherizeKeys';
import { currency, dashToCamelCase } from 'components/src/utils/stringHelpers';
import styled from 'styled-components/native';
import { xlGreen } from 'components/src/styles/colors';
import { rest } from 'components/src/utils/rest';
import fileDownload from 'js-file-download';

const itemValue = ({ value, style }) => {
  if (style) {
    if ('accounting' === style.format || 'currency' === style.format) {
      value = parseFloat(value);
    }
  }

  return value;
};

const Item = ({ value, style, color }) => {
  const itemStyle = {};

  if (style) {
    if ('accounting' === style.format || 'currency' === style.format) {
      itemStyle.display = 'block';
      itemStyle.textAlign = 'right';
      value = currency(value);
    }

    if (style.b) {
      itemStyle.fontWeight = 700;
    }
  }

  if (color) {
    itemStyle.color = color;
  }

  return (
    <Text style={itemStyle}>{value}</Text>
  );
};

const Title = styled(View)`
  text-align: center;
  margin: 10px;
`;

const ReportName = styled(Text)`
  font-size: 1.5em;
`;

const Summary = styled(View)`
  background-color: ${xlGreen};
  width: 300px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
`;

const SummaryLine = styled(Text)`
  color: white;
  font-size: 1.1em;
  margin-bottom: 3px;
`;

const Info = styled(View)`
  margin-top: 20px;
  text-align: center;
`;

const InfoText = styled(Text)`
  font-size: 1.5em;
`;

const ButtonGroup = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`;

const Button = styled(Pressable)`
  background-color: ${xlGreen};
  padding: 6px;
`;

const ButtonLabel = styled(Text)`
  color: white;
  font-size: 1.2em;
`;

const MobileRowView = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px;
`;

const MobileEntry = styled(View)`
  margin-bottom: 12px;
  border: 2px solid black;
`;

const MobileRow = ({ entry, options, index }) => {
  const stripe = 0 === index % 2;
  const value = itemValue({
    value: entry.items[index].value, style: options.style
  });

  const style = { color: stripe ? 'white' : 'black' };

  return (
    <MobileRowView style={{backgroundColor: stripe ? xlGreen : 'white' }}>
      <View>
        <Text style={style}>{options.label}</Text>
      </View>
      <View>
        <Item
          value={value}
          style={entry.items[index].style}
          color={style.color}
        />
      </View>
    </MobileRowView>
  );
};

const MobileData = ({ entries, fields }) => {
  return (
    <ScrollView style={{margin: '10px', height: 'calc(100vh - 460px)'}}>
      {entries.map(entry => (
        <MobileEntry key={entry.id}>
          {fields.map((field, index) => (
            <MobileRow
              key={field.key}
              entry={entry}
              options={field}
              index={index}
            />
          ))}
        </MobileEntry>
      ))}
    </ScrollView>
  );
};

const Report = () => {
  const params = useParams();
  const [downloading, setDownloading] = useState(false);

  const { loading, error, data } = useQuery(
    DEALER_REPORT, { variables: params }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { summary, headers } = data.dealerReportData;

  const entries = data.dealerReportData.entries.map((entry, index) => (
    {id: index, ...entry}
  ));

  const download = ({ format, label }) => {
    setDownloading(label);

    const pathPrefix = `dealer-reports/${params.type}/`;
    const path = params.id ?
      `${pathPrefix}/${params.id}.${format}` :
      `${pathPrefix}/${params.startOn}/${params.endOn}.${format}`;

    rest({
      path,
      responseType: 'blob',
      Accept: `application/${format}`
    }).then(response => {
      fileDownload(
        response.data,
        `${params.type}-${new Date().getTime()}.${format}`
      );

      setDownloading(false);
    });
  };

  const columns = headers.map((label, index) => {
    return {
      key: `col-${index}`,
      label: label,
      value: ({ entry }) => itemValue(entry.items[index]),
      content: ({ entry }) => <Item {...(entry.items[index])} />
    };
  });

  return (
    <>
      <Title>
        <ReportName>{summary[0]}</ReportName>
      </Title>
      <Summary>
        {summary.slice(1).map(line => (
          <SummaryLine key={line}>{line}</SummaryLine>
        ))}
      </Summary>

      {entries.length ? (
        <>
          <MediaQuery minWidth={992}>
            <SimpleSortedTable
              data={entries}
              columns={columns}
              pageLength={10}
              lengthMenu={ [10, 25, 50, 'All'] }
              htmlTable
            />
          </MediaQuery>
          <MediaQuery maxWidth={992}>
            <MobileData entries={entries} fields={columns} />
          </MediaQuery>

          {downloading ? (
            <Info><InfoText>Downloading {downloading}...</InfoText></Info>
          ) : (
            <ButtonGroup>
              <Button
                accessibilityRole="button"
                onPress={() => download({ format: 'xlsx', label: 'Excel' })}
              >
                <ButtonLabel>Download Excel</ButtonLabel>
              </Button>

              <Button
                accessibilityRole="button"
                onPress={() => download({ format: 'pdf', label: 'PDF' })}
              >
                <ButtonLabel>Download PDF</ButtonLabel>
              </Button>
            </ButtonGroup>
          )}
        </>
      ) : (
        <Info><InfoText>No Data Found.</InfoText></Info>
      )}
    </>
  );
};

export default Report;
