import React, { useRef, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Text } from 'react-native';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Form as FormikForm } from 'formik';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import * as Options from './Options';
import DataState from '../DataState';
import { titleCase } from '../../utils/stringHelpers';
import * as Alert from '../../elements/FloorCarAlerts';
import Select from 'react-select';
import moment from 'moment';
import { PROGRAMS, GET_CAR } from './Queries';
import {
  noVinAlertText,
  rowStyle,
  backgroundStyle,
  customStyles
} from './Style';

let currentMarketDate;

const validateBosDate = (value) => {
  let error;

  if (!value) {
    error = 'Required';
  } else if (moment(value).isBefore('01/01/2019')) {
    error = "Date can't be before 01/01/2019"
  } else if (moment(value).isAfter(currentMarketDate)) {
    error = 'Date must be today or earlier'
  }

  return error;
}

export const Form = (props) => {
  const [formSubmitting, setFormSubmitting] = useState();
  const { values, setFieldValue, isValid } = useFormikContext();
  const vinRef = useRef(null);
  const [getCar, { loading, error, data }] = useLazyQuery(GET_CAR);

  currentMarketDate = props.currentMarketDate;

  if (values.vin.length === 17 && (!vinRef.current || vinRef.current &&
    values.vin !== vinRef.current && vinRef.current !== 'loading')
  ) {
    getCar({
      variables: {
        vin: values.vin,
        mileage: values.mileage,
        state: 'OK'
      }
    })
  }

  if (loading) vinRef.current = "loading";
  if (data) vinRef.current = values.vin;
  if (values.vin.length !== 17 && vinRef.current) vinRef.current = null;

  useEffect(() => {
    if (vinRef.current && data) {
      setFieldValue('year', data.vinDecode.year),
      setFieldValue('make', titleCase(data.vinDecode.make)),
      setFieldValue('model', titleCase(data.vinDecode.model)),
      setFieldValue('series', titleCase(data.vinDecode.series)),
      (data.vinDecode.car)
        ? setFieldValue('color', titleCase(data.vinDecode.car.color))
        : setFieldValue('color', ''),
      (data.vinDecode.value)
        ? setFieldValue('blackbookValue', data.vinDecode.value)
        : setFieldValue('blackbookValue', '')
    } else {
      setFieldValue('year', '')
      setFieldValue('make', '')
      setFieldValue('model', '')
      setFieldValue('series', '')
      setFieldValue('color', '')
    }
  }, [vinRef.current]);

  const {
    loading: programLoading,
    error: programError,
    data: dataPrograms
  } = useQuery(PROGRAMS);

  if (programLoading) return <DataState.Loading />
  if (programError) return <DataState.Error error={error} />;

  const {
    ratePrograms,
    dealerPrograms,
    automatedFees,
    titleStatuses
  } = dataPrograms;

  let list = [];

  const formattedAutomatedFees = automatedFees.map((fee) => {
    const feeList = props.dealerDefaultAutomatedFeeIds.map((feeId) => {
      if (parseInt(fee?.id) !== feeId?.value) {
        list.push({ value: parseInt(fee.id), label: fee.name });
      }
    })
  })

  const key = 'label';

  const arrayUniqueByKey = [...new Map(list.map(item =>
    [item[key], item])).values()];

  const handleChange = (selectedList) => {
    if (!selectedList) {
      setFieldValue('automatedFeeResourcesAttributes', []);
      return
    }

    selectedList = selectedList.map((fee) => {
      return { automatedFeeId: parseInt(fee.value) };
    })

    setFieldValue('automatedFeeResourcesAttributes', selectedList);
  }

  return (
    <FormikForm>
      <Options.AdvanceType key="advanceType" />
      <hr />
      {vinRef.current && data && data.vinDecode &&
        <CarFlooredAlert
          key="CarFlooredAlert"
          flooredCars={data.vinDecode.flooredCars}
          floorplanRequests={data.vinDecode.floorplanRequests}
        />
      }
      <Container fluid>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="unitNumber"
              label="Unit Number"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="stockNumber"
              type="text"
              label="Stock Number"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="mileage"
              type="number"
              label="Mileage"
            />
          </Col>
          <Col lg style={{ flexGrow: '2' }}>
            <BasicForm.TextInput
              name="vin"
              type="text"
              label="VIN"
            />
            {vinRef.current && data &&
              <NoVinInformationAlert
                backgroundStyle={backgroundStyle}
                data={data.vinDecode}
              />
            }
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="year"
              type="number"
              label="Year"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="make"
              type="text"
              label="Make"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="model"
              type="text"
              label="Model"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="series"
              type="text"
              label="Series"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="color"
              type="text"
              label="Color"
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="blackbookValue"
              type="number"
              label="Blackbook Value"
              disabled={true}
            />
          </Col>
          <Col sm>
            <BasicForm.ProgrammaticSelect
              name="titleStatusId"
              label="Title Status"
              options={titleStatuses}
              optionName='Title Status'
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="titleState"
              type="text"
              label="Title State"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="titleNumber"
              type="text"
              label="Title Number"
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.DollarInput
              name="loanAmount"
              type="number"
              label="Loan Amount"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="bosDate"
              type="date"
              label="Bill of Sale Date"
              validate={validateBosDate}
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.ProgrammaticSelect
              label="Rate Program"
              name="rateProgramId"
              options={ratePrograms}
              optionName='Rate Program'
            />
          </Col>
          <Col sm>
            <BasicForm.ProgrammaticSelect
              label="Dealer Program"
              name="dealerProgramId"
              options={dealerPrograms}
              optionName='Dealer Program'
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col lg>
            <BasicForm.StyledLabel>Automated Fees</BasicForm.StyledLabel>
            <Select
              options={arrayUniqueByKey}
              isMulti={true}
              onChange={handleChange}
              styles={customStyles}
              defaultValue={props.dealerDefaultAutomatedFeeIds}
            />
          </Col>
        </Row>
      </Container>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}

const CarFlooredAlert = (props) => {
  const { flooredCars, floorplanRequests } = props;

  if (!flooredCars && !floorplanRequests) return null;

  if (flooredCars.length > 0 && floorplanRequests.length > 0) {
    return <Alert.CarFloored
      flooredCars={flooredCars}
      floorplanRequests={floorplanRequests}
    />
  } else if (flooredCars.length > 0) {
    return <Alert.CarFloored
      flooredCars={flooredCars}
      loanAmount={flooredCars[0].loanAmount}
    />
  } else if (floorplanRequests.length > 0) {
    return <Alert.CarFloored
      floorplanRequests={floorplanRequests}
    />
  } else
    return null;
}

const NoVinInformationAlert = (props, data) => {
  const { backgroundStyle } = props;
  const { year, make, model, car, result, errorMessage } = props.data;

  return (result === 'success')
    ? null
    : <Row style={Object.assign({}, backgroundStyle, rowStyle)}>
      <Text style={noVinAlertText}>{errorMessage}</Text>
    </Row>
}
