import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import * as Formatting from '../../styles/formatting';
import { Container, Row, Col } from 'react-bootstrap';
import DataState from '../DataState';
import AuthContext from '../../contexts/AuthContext';
import * as Buttons from '../../elements/buttons';
import '../Dealers/dealershow.css';
import RepoModal from '../Modals/Repo/New';
import SotModal from '../Modals/Sot/Sot';
import ArbitrationModal from '../Modals/Arbitration/New';
import SoldModal from '../Modals/Sold/New';
import UndoFundingModal from '../Modals/UndoFunding/New';
import ChangeSourceModal from '../Modals/ChangeSource/New';
import UpdateDealerProgramFeesModal from
  '../Modals/UpdateDealerProgramFees/New';
import PaymentAssistance from '../Modals/PaymentAssistance/New';
import * as StringHelper from '../../utils/stringHelpers';
import { titleCase } from '../../utils/stringHelpers';
import * as DateHelper from '../../utils/dateHelpers';
import { FLOORED_CAR } from '../../queries/viewQueries';
import NewPrincipalAdjustment from './Principals/Modal';
import { renderCarImage } from './CarImage/CarImageDetails';
import { ARBITRATE, REPO, SOT } from './Queries';

import {
  imageContainer,
  buttonRow,
  carRowStyle,
  greenRowStyle,
  carImageStyle,
  noCarImageStyle
} from './Style';
import UnsoldModal from '../Modals/Unsold/New';
import UpdateSoldModal from '../Modals/Sold/Edit';
import { formatSourceType } from './functions';

const CarInfo = (props) => {
  const id = props.flooredCar.id
  const auth = useContext(AuthContext);
  const dealerId = props.flooredCar.dealer.id
  const { loading, error, data, refetch } = useQuery(FLOORED_CAR, {
    variables: { id }
  });

  const [arbitrate] = useMutation(ARBITRATE, {
    onCompleted(variables) {
      props.showPageRefetch();
      refetch();
    }
  });

  const [repo] = useMutation(REPO, {
    onCompleted(variables) {
      props.showPageRefetch();
      refetch();
    }
  });

  useEffect(() => {
    refetch();
  }, [props.refetchAudit])

  const [sot] = useMutation(SOT, {
    onCompleted() {
      props.showPageRefetch()
      refetch()
    }
  });

  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showArbitrationModal, setShowArbitrationModal] = useState(false);
  const [showChangeSourceModal, setShowChangeSourceModal] = useState(false);
  const [showMarkAsSoldModal, setShowMarkAsSoldModal] = useState(false);
  const [showUpdateSoldModal, setShowUpdateSoldModal] = useState(false);
  const [showMarkAsNotSoldModal, setShowMarkAsNotSoldModal] = useState(false);
  const [showSotModal, setShowSotModal] = useState(false);
  const [showUndoFundingModal, setShowUndoFundingModal] = useState(false);
  const [
    showUpdateDealerProgramFeesModal, setShowUpdateDealerProgramFeesModal
  ] = useState(false);
  const [
    showPaymentAssistanceModal, setShowPaymentAssistanceModal
  ] = useState(false);

  let currentData = null;

  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShow(false);
  const handleArbitrationClose = () => setShowArbitrationModal(false);
  const handleChangeSourceClose = () => setShowChangeSourceModal(false);
  const handleMarkAsSoldClose = () => setShowMarkAsSoldModal(false)
  const handleMarkAsNotSoldClose = () => setShowMarkAsNotSoldModal(false);
  const handleCloseUpdateSaleModal = () => setShowUpdateSoldModal(false);
  const handleSotClose = () => {
    setShowSotModal(false);
    props.showPageRefetch();
    refetch();
  };

  const undoSot = () => {
    if (confirm('Really undo SOT?')) {
      sot({ variables: { id, sotOn: null } });
    }
  }

  const car = data.flooredCar;
  const {
    vin,
    currentFees,
    currentInterest,
    currentCollateralCoverage,
    unitNumber
  } = car;
  const loanDate = DateHelper.setDate(car.loanDate);
  const bosDate = DateHelper.setDate(car.bosDate);
  const floorPlanDays = car.termData.totalDays || '';
  const term = car.termData.term || '';
  const daysOnCurrentTerm = car.termData.day || '';
  const payoffOn = DateHelper.setDate(car.maxPayoffOn) || '';
  const sourceType = formatSourceType(car.sourceType);
  const funded = car.funded;
  const carImage = car.carPhotoUpload?.fileUrl || '';

  let fundedOn = DateHelper.setDate(car.fundedOn) || '';

  if (car.funded && auth.hasPolicy('FlooredCar', 'undo_funding')) {
    fundedOn = (
      <span>
        {fundedOn}
        <Buttons.Link style={{ marginLeft: '1em', color: "#000" }}
          text="Undo Funding"
          onClick={() => setShowUndoFundingModal(!showUndoFundingModal)}
        />
      </span>
    );
  }

  const source = (car.sourceType)
    ? <a
      href={`/${sourceType}/${car.sourceId}`}
    >
      {car.sourceName}
    </a>
    : 'Advance To Account';

  const fundingMethod = car.advanceTo || 'Coming Soon';
  const totalDue = (
    parseFloat(currentFees) + parseFloat(currentInterest) +
    parseFloat(car.currentPrincipal) + parseFloat(currentCollateralCoverage)
  )
  const loanAmount = StringHelper.currency(car.loanAmount) || '';
  const currentPrincipal = StringHelper.currency(car.currentPrincipal) || '';
  const nextPaymentDate = DateHelper.setDate(car.currentDueOn) || '';
  const atlasDate = DateHelper.setDate(car.atlasDate) || '';
  const statusOn = DateHelper.setDate(car.statusOn) || '';
  const principal = parseInt(car.currentPrincipal);
  const dealerName = data.flooredCar.dealer.name
  const reserveBalance = data.flooredCar.dealer.reserveBalance

  let termProgram;

  if (car.termProgram?.name.length > 20) {
    termProgram =
      <a href={'/system/term-programs/' + car.termProgram?.id}>
        {titleCase(car.termProgram?.name.substring(0, 20))}...
      </a>
  } else {
    termProgram =
      <a href={'/system/term-programs/' + car.termProgram?.id}>
        {titleCase(car.termProgram?.name)}
      </a>
  }

  let rateProgram;

  if (car.rateProgram?.name.length > 20) {
    rateProgram =
      <a href={'/system/rate-programs/' + car.rateProgram?.id}>
        {titleCase(car.rateProgram?.name.substring(0, 20))}...
      </a>
  } else {
    rateProgram =
      <a href={'/system/rate-programs/' + car.rateProgram?.id}>
        {titleCase(car.rateProgram?.name)}
      </a>
  }

  let dealerProgram;

  if (car.dealerProgram?.name.length > 20) {
    dealerProgram =
      <a href={'/system/dealer-programs/' + car.dealerProgram?.id}>
        {titleCase(car.dealerProgram?.code)}
      </a>
  } else {
    dealerProgram =
      <a href={'/system/dealer-programs/' + car.dealerProgram?.id}>
        {titleCase(car.dealerProgram?.code)}
      </a>
  }

  const automatedFees = car.automatedFees.map((fee) => {
    return (
      <Fragment key={fee.id}>
        <a href={'/system/automated-fees/' + fee.id}>{fee.name}</a><br />
      </Fragment>
    );
  });

  const currentPayoff = (
    parseFloat(car.currentCollateralCoverage) +
    parseFloat(car.currentPrincipal) +
    parseFloat(car.currentReserves) +
    parseFloat(car.currentFees) +
    parseFloat(car.currentInterest)
  )

  const flooredCarId = parseInt(data.flooredCar.id)

  const Arbitrate = () => {
    if (car.arbitratedOn) {
      return (
        <Buttons.Link
          text="Unmark as Arbitrated"
          onClick={() => {
            arbitrate({
              variables: {
                id: car.id,
                arbitratedOn: null,
                arbitrationReason: null,
                arbitratedByName: null
              }
            });
          }}
        />
      )
    } else {
      return (
        <Buttons.Link
          text="Mark as Arbitrated"
          onClick={() => setShowArbitrationModal(true)}
        />
      )
    }
  }

  const ChangeSource = () => {
    if (!funded && (car.advanceType === 'source') &&
      (car.hasEverFunded === false)
    ) {
      return (
        <Buttons.Link
          style={{ width: '100%' }}
          text="Change Source"
          onClick={() => setShowChangeSourceModal(true)}
        />
      )
    } else {
      return null
    }
  }

  const MarkAsSold = () => {
    if (!car.soldOn) {
      return (
        <Buttons.Link
          style={{ width: '100%' }}
          text='Mark as Sold'
          onClick={() => setShowMarkAsSoldModal(true)}
        />
      )
    } else {
      return (
        <Buttons.Link
          style={{ width: '100%' }}
          text='Mark as Not Sold'
          onClick={() => setShowMarkAsNotSoldModal(true)}
        />
      )
    }
  }

  const UpdateSale = () => {
    return (
      <Buttons.Link
        text='Update Sale'
        onClick={() => setShowUpdateSoldModal(true)}
      />
    )
  }

  let saleTypeName = 'No Information'
  let saleTypeLabel = 'Finance Company'

  if (car.saleType === 'Finance') {
    saleTypeName = car.financeCompanyName
    saleTypeLabel = 'Finance Company'
  } else if (car.saleType === 'Other') {
    saleTypeName = car.other
    saleTypeLabel = 'Other'
  } else if (car.saleType === 'Auction') {
    saleTypeName = car.soldToAuction?.name
    saleTypeLabel = 'Auction'
  }

  const date1 = new Date();
  const date2 = new Date(car.soldOn);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return (
    <>
    <Fragment>
      <Container style={Formatting.infoContainer}>
        <Row>
          <div className="col-xl-2" style={imageContainer} >
            {renderCarImage(carImage, id, dealerId, car.carPhotoUpload?.id)}
          </div>
          <div className="col-xl-3 info-section">
            {
              car.soldOn &&
                <>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <th className="car-info-header">Sale Status</th>
                      </tr>
                      <tr>
                        <td className="car-status-sold">
                          SOLD - {DateHelper.setDate(car.soldOn)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <th className="car-info-header">Sold To</th>
                        <th className="car-info-header">Sale Type</th>
                      </tr>
                      <tr>
                        <td className="car-info-green">
                          {car.soldTo ? car.soldTo : 'No Information'}
                        </td>
                        <td className="car-info-green">
                          {car.saleType ? car.saleType : 'No Information'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <th className="car-info-header">{saleTypeLabel}</th>
                        <th className="car-info-header">Days Sold</th>
                      </tr>
                      <tr>
                        <td className="car-info-green">
                          {saleTypeName}
                        </td>
                        <td
                          className={
                            diffDays > 7 ? "car-info-sold" : "car-info-green"
                          }
                        >
                          {`${diffDays} days`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width='100%'>
                    <tbody>
                      <tr>
                        <th className="car-info-header">
                          Functions
                        </th>
                      </tr>
                      <tr>
                        <td className="car-info-green">
                          <UpdateSale />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </>
            }
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">VIN</th>
                </tr>
                <tr>
                  <td className="car-info-green">{vin}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Unit</th>
                  <th className="car-info-header">Age</th>
                  <th className='car-info-header'>Color</th>
                </tr>
                <tr>
                  <td className="car-info-green">{unitNumber}</td>
                  <td className="car-info-green">
                    {car.termData.totalDays} Days
                  </td>
                  <td className="car-info-green">{car.car.color}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Loan Date</th>
                  <th className="car-info-header">BOS Date</th>
                </tr>
                <tr>
                  <td className="car-info-green">{loanDate}</td>
                  <td className="car-info-green">{bosDate}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">
                    Date Car Floored in ATLAS
                  </th>
                </tr>
                <tr>
                  <td className="car-info-green">{atlasDate}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Source</th>
                </tr>
                <tr>
                  <td className="car-info-green">{source}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Date Funded</th>
                </tr>
                <tr>
                  <td className="car-info-green">{fundedOn}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Funding Method</th>
                </tr>
                <tr>
                  <td className="car-info-green">{car.fundingMethod}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Functions</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {
                      car.repoLocation ?
                        <Buttons.Link style={{ width: '100%' }}
                          text="Edit Repo"
                          onClick={() => setShow(!show)}
                        /> :
                        <Buttons.Link style={{ width: '100%' }}
                          text="Mark as Repo"
                          onClick={() => setShow(!show)}
                        />
                    }
                    {
                      car.repoLocation &&
                        <Buttons.Link style={{ width: '100%' }}
                          text='Undue Repo'
                          onClick={() =>
                            repo({
                              variables: {
                                id: car.id,
                                repoOn: null,
                                repoLocation: null,
                                repoBy: null,
                                repoSold: false,
                              }
                            })
                          }
                        />
                    }
                    <Arbitrate />
                    {car.sotOn ? (
                      auth.hasPolicy('FlooredCar', 'undo_sot') && (
                        <Buttons.Link style={{ width: '100%' }}
                          text="Undo SOT"
                          onClick={() => undoSot()}
                        />
                      )
                    ) : (
                      <Buttons.Link style={{ width: '100%' }}
                        text="Mark as SOT"
                        onClick={() => setShowSotModal(true)}
                      />
                    )}
                    <ChangeSource />
                    <MarkAsSold />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Title Status</th>
                </tr>
                <tr>
                  <td className="car-status-Paid">{car.titleStatus}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Loan Amount</th>
                  <th className="car-info-header">
                    Current Principal Balance
                  </th>
                </tr>
                <tr>
                  <td className="car-info-green">{loanAmount}</td>
                  <td className="car-info-green">{currentPrincipal}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Current Interest Owed</th>
                  <th className="car-info-header">Current Fees Owed</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {StringHelper.currency(car.currentInterest)}
                  </td>
                  <td className="car-info-green">
                    {StringHelper.currency(car.currentFees)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">
                    Current Collateral Coverage Owed
                  </th>
                  <th className="car-info-header">Current Reserves Owed</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {StringHelper.currency(car.currentCollateralCoverage)}
                  </td>
                  <td className="car-info-green">
                    {StringHelper.currency(car.currentReserves)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Current Payoff</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {StringHelper.currency(currentPayoff)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Next Payment Due</th>
                  <th className="car-info-header">Estimated Amount</th>
                </tr>
                <tr>
                  <td className="car-info-green">{nextPaymentDate}</td>
                  <td className="car-info-green">
                    {StringHelper.currency(totalDue)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Mileage</th>
                  <th className="car-info-header">Blackbook Value</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {StringHelper.numberWithDelimiter(car.mileage)}
                  </td>
                  <td className="car-info-green">
                    {StringHelper.currency(car.blackbookValue)}
                  </td>
                </tr>
              </tbody>
            </table>
            {
              'Unpaid' === car.status &&
                <table width="100%">
                  <tbody>
                    <tr>
                      <th className="car-info-header">
                        Functions
                      </th>
                    </tr>
                    <tr>
                      <td className="car-info-green">
                        <Buttons.Link
                          style={{ width: '100%' }}
                          text="Adjust Principal"
                          onClick={() => handleShow()}
                        />
                        {
                          auth.hasExactPolicy(
                            'FlooredCar', 'payment_assistance'
                          ) &&
                            <Buttons.Link
                              style={{ width: '100%' }}
                              text='Payment Assistance'
                              onClick={
                                () => setShowPaymentAssistanceModal(true)
                              }
                            />
                        }
                        {
                          auth.hasExactPolicy(
                            'FlooredCar', 'update_dealer_program_fees'
                          ) &&
                            <Buttons.Link
                              text="Update Dealer Program Fees"
                              onClick={() =>
                                setShowUpdateDealerProgramFeesModal(true)
                              }
                            />
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
            }
          </div>
          <div className="col-xl-4 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Status</th>
                </tr>
                <tr>
                  <td className={"car-status-" + car.status}>
                    {car.status} {statusOn}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Rate Program</th>
                </tr>
                <tr>
                  <td className="car-info-green">{rateProgram}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Dealer Program</th>
                </tr>
                <tr>
                  <td className="car-info-green">{dealerProgram}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Current Term</th>
                  <th className="car-info-header">Days on Term</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {titleCase(car.termData.type)} {term}
                  </td>
                  <td className="car-info-green">{daysOnCurrentTerm}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Payoff Required On</th>
                </tr>
                <tr>
                  <td className="car-info-green">{payoffOn}</td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Automated Fees</th>
                </tr>
                <tr>
                  <td className="car-info-green">{automatedFees}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Most Recent Audit</th>
                </tr>
                <tr>
                  <td className="car-info-green">{car.lastAudit}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
      <RepoModal
        show={show}
        handleclose={() => setShow(false)}
        name={name}
        handleClose={handleClose}
        showPageRefetch={props.showPageRefetch}
        refetch={refetch}
        flooredCar={car}
      />
      {showModal && <NewPrincipalAdjustment
        showModal={showModal}
        setShowModal={setShowModal}
        funded={funded}
        principal={principal}
        flooredCarId={flooredCarId}
        dealerName={dealerName}
        reserveBalance={reserveBalance}
      />
      }
      <ArbitrationModal
        show={showArbitrationModal}
        name={name}
        handleClose={handleArbitrationClose}
        refetch={refetch}
        showPageRefetch={props.showPageRefetch}
      />
      <SoldModal
        show={showMarkAsSoldModal}
        handleClose={handleMarkAsSoldClose}
        refetch={refetch}
        showPageRefetch={props.showPageRefetch}
      />
      <UpdateSoldModal
        show={showUpdateSoldModal}
        handleClose={handleCloseUpdateSaleModal}
        refetch={refetch}
        car={car}
      />
      <UnsoldModal
        show={showMarkAsNotSoldModal}
        handleClose={handleMarkAsNotSoldClose}
        refetch={refetch}
      />
      <ChangeSourceModal
        show={showChangeSourceModal}
        handleClose={handleChangeSourceClose}
        vin={vin}
        color={car.car.color}
        mileage={car.mileage}
        sourceType={sourceType}
        sourceId={car.sourceId}
        bankAccountId={car.bankAccountId}
      />
      <SotModal
        show={showSotModal}
        name={name}
        handleClose={handleSotClose}
      />
      <UndoFundingModal
        show={showUndoFundingModal}
        name={name}
        flooredCar={data.flooredCar}
        handleClose={() => setShowUndoFundingModal(false)}
      />
      <UpdateDealerProgramFeesModal
        show={showUpdateDealerProgramFeesModal}
        name={name}
        flooredCar={data.flooredCar}
        handleClose={() => setShowUpdateDealerProgramFeesModal(false)}
      />
      <PaymentAssistance
        show={showPaymentAssistanceModal}
        flooredCar={data.flooredCar}
        handleClose={() => setShowPaymentAssistanceModal(false)}
      />
    </Fragment>
    </>
  )
};

export default CarInfo;
