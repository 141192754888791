import React from 'react';
import * as yup from 'yup';
import { useQuery, gql } from '@apollo/client';
import { Form as FormikForm } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import * as StringHelpers from '../../../utils/stringHelpers';
import { Spacing } from '../../../styles';
import DataState from '../../DataState';

export const fiscalMonthSchema = yup.object({
  year: yup
    .number()
    .required('Required'),
  period: yup
    .number()
    .required('Required'),
  startOn: yup
    .date()
    .required('Required'),
  endOn: yup
    .date()
    .required('Required')
});

export const Form = () => {
  return (
    <FormikForm>
      <BasicForm.Select
        name="year"
        type="number"
        label="Year"
      >
        <option value=''>Select Fiscal Year</option>
        { fiscalYears() }
      </BasicForm.Select>
      <BasicForm.Select
        name="period"
        type="number"
        label="Month/Period"
      >
        <option value="">Select Fiscal Month</option>
        { fiscalMonths() }
      </BasicForm.Select>
      <BasicForm.CustomStyleTextInput
        name="startOn"
        type="date"
        divStyle={inputDivStyle}
        label="Start On"
      />
      <BasicForm.CustomStyleTextInput
        name="endOn"
        type="date"
        divStyle={inputDivStyle}
        label="End On"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}

const fiscalYears = () => {
  let currentYear = new Date().getFullYear();
  let options = [];

  options.push(<option key={currentYear - 3} value={currentYear - 3}>{currentYear - 3}</option>);
  options.push(<option key={currentYear - 2} value={currentYear - 2}>{currentYear - 2}</option>);
  options.push(<option key={currentYear - 1} value={currentYear - 1}>{currentYear - 1}</option>);

  for(let x = 0; x < 10; x++) {
    options.push(<option key={x} value={currentYear + x}>{currentYear + x}</option>);
  }
  return options;
}

const fiscalMonths = () => {
  let options = [
    <option key={1} value={1}>January</option>,
    <option key={2} value={2}>February</option>,
    <option key={3} value={3}>March</option>,
    <option key={4} value={4}>April</option>,
    <option key={5} value={5}>May</option>,
    <option key={6} value={6}>June</option>,
    <option key={7} value={7}>July</option>,
    <option key={8} value={8}>August</option>,
    <option key={9} value={9}>September</option>,
    <option key={10} value={10}>October</option>,
    <option key={11} value={11}>November</option>,
    <option key={12} value={12}>December</option>,
    <option key={13} value={13}>Period 13</option>,
    <option key={14} value={14}>Period 14</option>,
  ];
  return options;
};

const inputDivStyle = {
  display: "flex",
  flexDirection: "column",
  width: "40%",
};
