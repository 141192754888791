import React, { useState, useContext } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useParams, useRouteMatch } from 'react-router-dom';
import AuthContext from '../../../contexts/AuthContext';
import * as Style from '../../../styles/Source/showInfo';
import * as TimeHelper from '../../../utils/timeHelpers';
import * as SaleTimeHelper from './functions';
import moment from 'moment';

export const SaleTimes = ({ saleTimes }) => {
  const auth = useContext(AuthContext);
  const auctionId = parseInt(useParams().id || useParams().ownerId)

  saleTimes = SaleTimeHelper.sortTimes(saleTimes);
  saleTimes = SaleTimeHelper.renderSaleTimes(saleTimes, auctionId, auth);

  return <div className="col-xl-3 internal-info">
    <Row>
      <span style={Style.boldText}>Sale Times</span>
    </Row>
    {saleTimes}
    <SaleTimeHelper.AddSaleTime
      auth={auth}
      saleTimes={saleTimes}
      auctionId={auctionId}
    />
  </div>
}

