import React, { useState, useEffect } from 'react';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../../elements/forms';

const CreditInput = (props) => {
  const { label, indexValue } = props;
  const { values } = useFormikContext();

  const [disable, setDisable] = useState(false);

  const inputHeight = {
    height: '38px'
  }

  useEffect(() => {
    if (parseFloat(values.txnLinesAttributes[indexValue].debit) > 0.01) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  })

  return (
    <div>
      { 0 == indexValue && (label || 'Credit') }
      <BasicForm.JournalTextInput
        name={`txnLinesAttributes[${indexValue}].credit`}
        type="text"
        style={inputHeight}
        disabled={disable}
      />
    </div>
  );
};

export default CreditInput;
