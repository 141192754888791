import React from 'react';
import { useParams } from 'react-router-dom';
import { SOLD } from './Queries';
import { useMutation } from '@apollo/client';
import Modal from 'react-bootstrap/Modal';
import { Spacing } from '../../../styles';
import { Formik } from 'formik';
import { soldSchema, Form } from './Form';

const SoldModal = props => {
  const { handleClose, show, refetch, showPageRefetch } = props;
  const id = parseInt(useParams().carId)

  const [sold, { loading, error }] = useMutation(SOLD, {
    onCompleted(variables) {
      handleClose();
      refetch();
      showPageRefetch();
    }
  })

  const handleSubmit = values => {
    sold({ variables: values })
  }

  let today = new Date();

  today = today.toISOString().split('T')[0];

  const ModalBody = () => {
    return (
      <div style={Spacing.formWrapper}>
        <Formik
          initialValues={{
            id,
            dateMarkedSold: today,
            soldOn: '',
            soldTo: undefined,
            saleType: undefined,
            financeCompanyName: undefined,
            soldToAuctionId: undefined,
            other: undefined
          }}
          validationSchema={soldSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Mark as Sold
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(loading) ? <p>Loading...</p> : <ModalBody />}
      </Modal.Body>
    </Modal>
  )
}

export default SoldModal
