import { setParamaterData } from "./paramaterData";
import { addFilters } from './addFilters';

export const paginate = (
  direction,
  limit,
  fetchMore,
  setQueryData,
  offset,
  currentPage,
  count,
  sort,
  sortDirection,
  search,
  marketId=null,
  filters=null,
  setQueryLoading,
) => {
  if (direction === "Next") currentPage.current += 1;
  if (direction === "Previous") {
    currentPage.current -= 1;
    if (currentPage.current < 1) currentPage.current = 1;
  }
  if (direction === "First") currentPage.current = 1;
  if (direction === "Last")
    currentPage.current = Math.ceil(count.current / limit);

  if (Number.isInteger(direction)) currentPage.current = direction;

  const offsetLimit = limit * (currentPage.current - 1);

  offset.current = currentPage.current === 1 ? 0 : offsetLimit;

  const variables = {
      limit,
      offset: offset.current,
      sort: sort.current,
      sortDirection: sortDirection.current,
      search: search.current,
    }

  if (marketId) variables.marketId = parseInt(marketId);
  if (filters) addFilters(filters, variables);

  setQueryLoading(true);
  fetchMore({
    variables,
    updateQuery: (prev, { fetchMoreResult }) => {
      setQueryLoading(false);

      setQueryData(fetchMoreResult);
    },
  });
};
