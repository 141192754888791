import React, { useEffect, useRef, useState } from 'react';
import * as BasicForm from '../../../../../elements/forms';
import { divStyle } from './AdvanceType';
import { useFormikContext } from 'formik';
import { ErrorMessage } from '../../../../../elements/ErrorMessage';
import AccountSelect from '../../../../../elements/AccountSelect';
import { SourceType } from './SourceType';
import { Advance } from './Advance';

export const ExternalTypeOptions = () => {
  let [paymentMethod, setPaymentMethod] = useState(null);

  const {
    setFieldValue,
    values,
    values: { sourceType, txnType, advanceType },
  } = useFormikContext();

  useEffect(() => {
    setPaymentMethod(txnType)
  }, [txnType])

  useEffect(() => {
    setFieldValue("bankAccountId", "");
  }, [advanceType]);

  useEffect(() => {
    values.accountId = parseInt(values.accountId) || '';
  }, [values.accountId])

  return (
    [
      <PaymentMethod key="txnType" />,
      <AccountSelect
        scopes={['bank']}
        label="Payment Made from Bank Account"
        values={values}
        defaultSpecial="funding_bank"
      />,
      <SourceType includeOther />,
      <Advance key='check' type={paymentMethod} />,
    ]
  )
}

 const PaymentMethod = () => {
  return <div style={divStyle}>
    <BasicForm.StyledLabel>
      What will the method of payment be?
    </BasicForm.StyledLabel>
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Check"
        value="Check"
      />
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="ACH"
        value="ACH"
      />
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Wire Transfer"
        value="Wire"
      />
      <BasicForm.InlineStyledErrorMessage>
        <ErrorMessage name='txnType'/>
      </BasicForm.InlineStyledErrorMessage>
    </div>
}
