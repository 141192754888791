import React from "react";
import { Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { modalStyle } from "../FilterStyles";

const Checkbox = ({
  currentFilters,
  filterKeys,
  adjustFilterValues,
  filterData,
  checkboxLabels,
  handleFilters,
}) => {
  return checkboxLabels.map((label, index) => {
    const adjustedIndex = filterData.length + index;
    return (
      <Row style={modalStyle.checkboxRow}>
        <Form.Label>{`${label}?`}</Form.Label>
        <Form.Control
          type="checkbox"
          style={modalStyle.checkbox}
          checked={currentFilters.current[filterKeys[adjustedIndex]] === "true"}
          onChange={(event) =>
            adjustFilterValues(
              event.target.checked,
              label,
              currentFilters,
              handleFilters
            )
          }
        />
      </Row>
    );
  });
};

export default Checkbox;
