import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { useFormikContext } from 'formik';

const Select = props => {
  const { field, fieldLabel, options, lineIndex, isClearable } = props;
  const { values, setValues, errors } = useFormikContext();
  const [fieldError, setFieldError] = useState('');

  const labelBuilder = props.labelBuilder || (option => option.name);

  const selectOptions = options.map(option => {
    if (option.id) {
      return { value: parseInt(option.id), label: labelBuilder(option) };
    } else {
      return { value: option, label: labelBuilder({ name: option }) };
    }
  });

  const handleChange = selected => {
    if (options[0]?.id) {
      values.txnLinesAttributes[lineIndex][field] = parseInt(selected?.value);
    } else {
      values.txnLinesAttributes[lineIndex][field] = selected?.value;
    }
    setValues(values);
  };

  useEffect(() => {
    if (errors.txnLinesAttributes?.[lineIndex]) {
      const fieldError = errors.txnLinesAttributes[lineIndex][field];

      if (fieldError) {
        setFieldError(fieldError.toString());
      } else {
        setFieldError('');
      }
    } else {
      setFieldError('');
    }
  }, [errors.txnLinesAttributes])

  const currentValue = (typeof lineIndex === 'undefined') ?
    null : values.txnLinesAttributes[lineIndex][field];

  const currentSelected =
    selectOptions.find(option => option.value === currentValue);

  return (
    <div>
      {0 == lineIndex && fieldLabel}
      <ReactSelect
        options={selectOptions}
        isMulti={false}
        onChange={handleChange}
        defaultValue={currentSelected}
        isClearable={isClearable}
      />
      <div
        style={{
          fontWeight: 'bold',
          color: '#A1473F',
          fontStyle: 'italic',
          fontSize: '16px'
        }}
      >
        { fieldError }
      </div>
    </div>
  );
}

export default Select;
