import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import DataState from '../../../DataState';
import DataTable from '../../../../elements/DataTable';
import * as DateHelpers from '../../../../utils/dateHelpers';
import * as StringHelper from '../../../../utils/stringHelpers';

const RECONCILE = gql`
  query Reconcile($id: ID!) {
    reconcile(id: $id) {
      id
      completedAt
      account {
        id
        name
        clearedBalance
      }
      possibleTxnLines {
        id
        reconcileId
        amount
        txn {
          id
          name
          type
          reference
          bankDate
          cachedSourceName
        }
      }
    }
  }
`

const CLEAR_TXN_LINE = gql`
  mutation ClearTxnLine($id: ID!, $reconcileId: Int) {
    clearTxnLine(id: $id, reconcileId: $reconcileId) {
      txnLine {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const Txns = ({ id, refetch }) => {
  const { loading, error, data } = useQuery(RECONCILE, { variables: { id } });
  const [clearTxnLine] = useMutation(CLEAR_TXN_LINE)

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const txnDataSet = data.reconcile.possibleTxnLines.map(function (txns) {
    let txn = { ...txns.txn };

    $(document).on('change', `#clear-txn-${txn.id}`, function (event) {
      let txnLineId = event.target.dataset.id
      txnLineId = parseInt(txnLineId)

      let reconcileId = event.target.dataset.reconcile
      reconcileId = parseInt(reconcileId)

      const checkbox = document.getElementById(`clear-txn-${txn.id}`);

      if (checkbox.checked) {
        clearTxnLine({ variables: { id: txnLineId, reconcileId: reconcileId } });
        refetch()
      }

      if (!checkbox.checked) {
        clearTxnLine({ variables: { id: txnLineId, reconcileId: null } });
        refetch()
      }
    })

    const preCheck = txns.reconcileId ? "checked" : null;
    const disabled = data.reconcile.completedAt ? "disabled" : null;

    txn.actions = `
      <input
        type="checkbox"
        id="clear-txn-${txn.id}"
        data-id=${txns.id}
        data-reconcile=${id}
        ${preCheck}
        ${disabled}
      />
    `

    return ([
      txn.cachedSourceName,
      txn.name,
      txn.type,
      StringHelper.currency(txns.amount),
      txn.reference,
      DateHelpers.setDate(txn.bankDate),
      txn.actions
    ])
  })

  const txnColumns = [
    {
      title: 'Source'
    },
    {
      title: 'Name'
    },
    {
      title: 'Type'
    },
    {
      title: 'Amount',
      className: 'text-right'
    },
    {
      title: 'Reference'
    },
    {
      title: 'Bank Date',
      render: DateHelpers.renderDate
    },
    {
      title: 'Clear',
      className: 'text-center'
    }
  ];

  return (
    <>
      <DataTable
        pageTitle={`Transactions on ${data.reconcile.account.name}`}
        dataSet={txnDataSet}
        columns={txnColumns}
        unhideable
        uncreatable
      />
    </>
  )
};

export default Txns
