import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { Form, statusSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import { TITLE_STATUS } from '../../../queries/viewQueries';
import DataState from '../../DataState';
import Loading from '../../../elements/Loading';


const UPDATE_TITLE_STATUS = gql`
  mutation UpdateTitleStatus($id: ID!, $name: String!, $info: String,
    $isPresent: Boolean!, $toDealer: Boolean!, $autoFeeTitleAdded: Boolean!
    $autoFeeTitleBorrowed: Boolean!, $autoFeeTitleShipped: Boolean!,
    $autoFeeTitleMarkedPresent: Boolean!, $fund: Boolean!) {
    updateTitleStatus(
      id: $id
      name: $name
      info: $info
      isPresent: $isPresent
      toDealer: $toDealer
      autoFeeTitleAdded: $autoFeeTitleAdded
      autoFeeTitleBorrowed: $autoFeeTitleBorrowed
      autoFeeTitleShipped: $autoFeeTitleShipped
      autoFeeTitleMarkedPresent: $autoFeeTitleMarkedPresent
      fund: $fund
    ) {
      titleStatus { id }
      errors { message, path }
    }
  }
 `

const UpdateTitleStatus = () => {
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const id = parseInt(useParams().id);
  const [updateTitleStatus, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_TITLE_STATUS,
    {
      onCompleted(variables) {
        setFormSubmitting(false);
        setRedirect(true);
      }
    })

  const { loading, error, data } = useQuery(TITLE_STATUS, { variables: { id } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { titleStatus } = data;
  const { createdById,
    name,
    info,
    isPresent,
    toDealer,
    autoFeeTitleAdded,
    autoFeeTitleBorrowed,
    autoFeeTitleShipped,
    autoFeeTitleMarkedPresent,
    fund } = titleStatus;

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    updateTitleStatus({ variables: values })
  };

  const UpdateForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/title-statuses",
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>{`Edit Title Status: ${name}`}</h1>
          </div>

          <Formik
            initialValues={{
              id,
              name,
              isPresent,
              toDealer,
              autoFeeTitleAdded,
              autoFeeTitleBorrowed,
              autoFeeTitleShipped,
              autoFeeTitleMarkedPresent,
              info: info || '',
              fund: fund || false,
            }}
            validationSchema={statusSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <UpdateForm />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`Edit Title Status: ${name}`}</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default UpdateTitleStatus;
