import React from 'react';
import AuthLink from 'components/src/components/AuthLink';
import { ColTitle, ReportLink } from '../index';
import styled from '@emotion/styled';
import { xlGreen } from 'components/src/styles/colors';
import { useQuery } from '@apollo/client';
import DataState from 'components/src/components/DataState';
import {
  ADMIN_DOCUMENTS
} from 'components/src/components/System/AdminDocuments/queries';

export const AdminLink = styled.a`
  display: block;
  margin-top: 6px;
  font-size: 1.1rem;
  color: ${xlGreen} !important;
`;

const Admin = () => {
  const { loading, error, data } = useQuery(ADMIN_DOCUMENTS);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <>
      <ColTitle>Admin</ColTitle>

      {data.adminDocuments.map(adminDocument => (
        <AdminLink href={adminDocument.fileUpload?.fileUrl} target="_blank">
          {adminDocument.name}
        </AdminLink>
      ))}

      <AuthLink
        to="/system/admin-documents"
        resource="AdminDocument"
        action="edit"
        component={ReportLink}
      >
        Admin Maintenance
      </AuthLink>
    </>
  );
};

export default Admin;
