import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import * as Colors from "../../styles/colors";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as BasicForm from "../forms";
import {
  renderLabels,
  renderSelectOptions,
  renderMultiSelectOptions,
  renderCheckboxOptions,
} from "./Functions/setFilterOptions";
import DisplayLoading from "./DisplayLoading";
import * as Filters from "./SelectTypes";
import { modalStyle, filterButton } from "./FilterStyles";

const adjustFilterValues = (value, label, currentFilters, handleFilters) => {
  label = label
    .split(" ")
    .map((l) => l.toLowerCase())
    .join("_");

  handleFilters({ ...currentFilters.current, [label]: value.toString() });
};

const clearFilters = (currentFilters, handleFilters, dateFilters, setClearingToggle, clearingToggle) => {
  Object.keys(currentFilters.current).forEach(
    (column) => {
      let resetAttribute = null;

      if (dateFilters.includes(column)) resetAttribute = ['', ''];

      // To persist referrer filters IE market_id, user_id, etc..
      if (column.includes('_id')) return;

      currentFilters.current[column] = resetAttribute;
    }
  );

  setClearingToggle(!clearingToggle);
  handleFilters({ ...currentFilters.current });
};

export const Filter = ({ handleFilter, dataSet, filterButtonText }) => {
  const [showModal, setShowModal] = useState(false);
  const [clearingToggle, setClearingToggle] = useState(false);
  const filterButtonContent = filterButtonText ? filterButtonText : 'Filter Options'
  if (!handleFilter[0]) return null;

  const currentFilters = handleFilter[0];
  const handleFilters = handleFilter[1];
  const queryLoading = handleFilter[2];
  const filterData = handleFilter[3].filterData;
  const multiSelectData = handleFilter[3].filterMultiSelectData;
  const filterCheckboxData = handleFilter[3].filterCheckboxData;
  const filterDateRange = handleFilter[3].filterDateRange;

  const selectLabels = renderLabels(filterData);
  const multiSelectLabels = renderLabels(multiSelectData);
  const checkboxLabels = filterCheckboxData
    ? renderLabels(filterCheckboxData)
    : [];
  const dateRangeLabels = renderLabels(filterDateRange);

  const selectOptions = renderSelectOptions(filterData);
  const multiSelectOptions = renderMultiSelectOptions(multiSelectData);
  const checkboxOptions = filterCheckboxData
    ? renderCheckboxOptions(filterCheckboxData)
    : [];
  const dateRangeOptions = filterDateRange || [];

  let filterKeys = filterData.map((d) => Object.keys(d)).flat();
  if (filterCheckboxData)
    filterCheckboxData.map((d) => filterKeys.push(Object.keys(d)));

  let filterDateKeys = [];
  if (filterDateRange) {
    filterDateKeys = filterDateRange.map((d) => Object.keys(d)).flat();
    filterKeys.push(filterDateKeys)
  }

  filterKeys = filterKeys.flat();

  const emptyTable = dataSet.length < 1;

  return (
    <>
      <Button style={filterButton} onClick={() => setShowModal(true)}>
        {filterButtonContent}
      </Button>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header style={modalStyle.header} closeButton>
          <Modal.Title style={modalStyle.title}>
            Filter Options
            {emptyTable && !queryLoading && (
              <p style={{ color: "red" }}>No Records Found </p>
            )}
            {queryLoading && <DisplayLoading style={{ marginTop: "10px" }} />}
            <Button
              variant="danger"
              onClick={() => clearFilters(currentFilters, handleFilters, filterDateKeys, setClearingToggle, clearingToggle)}
            >
              Clear Filters
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalStyle.body}>
          <Form>
            <Filters.Normal
              currentFilters={currentFilters}
              filterKeys={filterKeys}
              selectOptions={selectOptions}
              adjustFilterValues={adjustFilterValues}
              selectLabels={selectLabels}
              handleFilters={handleFilters}
            />
            <Filters.MultiSelect
              currentFilters={currentFilters}
              multiSelectLabels={multiSelectLabels}
              multiSelectOptions={multiSelectOptions}
              handleFilters={handleFilters}
              multiSelectData={multiSelectData}
            />
            <Filters.Checkbox
              currentFilters={currentFilters}
              filterKeys={filterKeys}
              adjustFilterValues={adjustFilterValues}
              filterData={filterData}
              checkboxLabels={checkboxLabels}
              handleFilters={handleFilters}
            />
            <Filters.DateRange
              currentFilters={currentFilters}
              dateRangeOptions={dateRangeOptions}
              filterData={filterData}
              labels={dateRangeLabels}
              handleFilters={handleFilters}
              clearingToggle={clearingToggle}
              setClearingToggle={setClearingToggle}
            />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
