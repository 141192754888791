import React, { useState } from 'react';
import * as yup from "yup";
import { Form as FormikForm, useFormikContext } from "formik";
import * as BasicForm from "../../elements/forms";
import * as Buttons from "../../elements/buttons";
import { Spacing } from "../../styles";
import { phoneRegExp, handleNumberChange } from "../../utils/stringHelpers";
import Input from 'react-phone-number-input/input';
import { gql, useQuery } from '@apollo/client';
import Select from 'react-select';
import { TITLE_STATUSES_FOR_SELECT } from '../../queries/indexQueries';
import DataState from '../DataState';
import { Colors } from '../../styles';
import { optionsFromValues } from 'components/src/elements/Filter/MultiSelect';

const autofill = {
  marginTop: '1%',
  marginBottom: '1%',
  fontWeight: 550,
  color: 'white',
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen
}

const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })
}

export const approvedSourceSchema = yup.object({
  name: yup.string().required("Required"),
  type: yup.string().required("Required"),
  state: yup
    .string()
    .max(2, "Please enter the 2 letter state abbreviation")
    .required("Required"),
  phone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    }),
  pmsiPhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    })
    .when('pmsiOption', {
      is: true,
      then: yup.string().required('Required')
    }),
  pmsiAddress: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  }),
  pmsiCity: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  }),
  pmsiState: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  }),
  pmsiZip: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  }),
  pmsiCompanyName: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  })
});

export const Form = ({ formSubmitting, approvedSource }) => {
  const { values, setFieldValue, isValid, setValues } = useFormikContext();
  const { loading, error, data } = useQuery(TITLE_STATUSES_FOR_SELECT);

  const setOptionValues = (titleStatusData) => {
    return titleStatusData.map((status) => {
      const name = status.name;
      const id = parseInt(status.id);

      return <option value={id}>{name}</option>;
    });
  };

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const handleAgreementUpload = file => {
    setFieldValue('file', file);
  }

  const handleChangeTitleFundingStatuses = selected => {
    if (selected) {
      values.titleFundingStatusIds =
        selected.map(option => parseInt(option.value, 10));
    } else {
      values.titleFundingStatusIds = [];
    }

    setValues(values);
  }

  const { titleStatuses } = data;

  const handleFill = () => {
    values.pmsiAddress = values.street
    values.pmsiCity = values.city
    values.pmsiState = values.state
    values.pmsiZip = values.zip
    values.pmsiCompanyName = values.name
    values.pmsiPhone = values.phone

    setValues(values)
  }

  return (
    <FormikForm>
      <BasicForm.TextInput name="name" type="text" label="Name" />
      <BasicForm.Select name="type" label="Type">
        <option value="">Select a Type</option>
        <option>Dealership</option>
        <option>Lender</option>
        <option>Floor Plan</option>
        <option>Other</option>
      </BasicForm.Select>
      <BasicForm.TextInput name="street" type="text" label="Street" />
      <BasicForm.TextInput name="city" type="text" label="City" />
      <BasicForm.StateAbbreviationSelect
        name="state"
        type="text"
        label="State"
      />
      <BasicForm.TextInput name="zip" type="text" label="Zip Code" />
      <BasicForm.PhoneInput
        name="phone"
        country="US"
        value={values.phone || ''}
        onChange={event => handleNumberChange(event, 'phone', setFieldValue)}
        label='Phone Number'
      />
      <BasicForm.Checkbox
        name="agreementOnFile"
        type="checkbox"
        label="Intercreditor Agreement on File?"
      />
      <BasicForm.TextInput
        name="agreementDate"
        type="date"
        label="Intercreditor Agreement Signed Date"
      />
      {approvedSource && (
        <>
          {approvedSource?.agreementUpload?.fileUrl && (
            <>
              <br />
              <a href={approvedSource.agreementUpload.fileUrl} target="_blank">
                [View Current Intercreditor Agreement Upload]
              </a>
            </>
          )}
          <BasicForm.PdfUpload
            name="file"
            label="Intercreditor Agreement Upload"
            handleFile={handleAgreementUpload}
          />
        </>
      )}
      <BasicForm.StyledLabel>Title Funding Statuses</BasicForm.StyledLabel>
      <Select
        options={optionsFromValues(titleStatuses)}
        defaultValue={
          optionsFromValues(titleStatuses, values.titleFundingStatusIds)
        }
        isMulti={true}
        onChange={handleChangeTitleFundingStatuses}
        styles={customStyles}
      />
      <BasicForm.Checkbox
        name="pmsiOption"
        type="checkbox"
        label="PMSI?"
      />
      {
        values.pmsiOption &&
          <button type="button" style={autofill} onClick={() => handleFill()}>
            Auto Fill PMSI Information
          </button>
      }
      {
        values.pmsiOption &&
          <BasicForm.TextInput
            name="pmsiAddress"
            type="text"
            label="PMSI Address"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.TextInput
            name="pmsiCity"
            type="text"
            label="PMSI City"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.StateAbbreviationSelect
            name="pmsiState"
            type="text"
            label="PMSI State"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.TextInput
            name="pmsiZip"
            type="text"
            label="PMSI Zip"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.PhoneInput
            name="pmsiPhone"
            country="US"
            value={values.pmsiPhone || ''}
            onChange={ event => handleNumberChange(event, 'pmsiPhone', setFieldValue) }
            label="PMSI Phone Number"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.TextInput
            name="pmsiCompanyName"
            type="text"
            label="PMSI Company Name"
          />
      }
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={formSubmitting}
        />
      </div>
    </FormikForm>
  );
};
