import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useFormikContext } from 'formik';
import Modal from 'react-bootstrap/Modal';
import * as BasicForm from "../../../elements/forms";
import * as Buttons from '../../../elements/buttons'
import { Spacing } from '../../../styles';
import * as StringHelper from '../../../utils/stringHelpers';

let modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px',
}

const labelStyle = {
    marginTop: "20px",
};

export const ProcessFeeWaiver = ({ showModal, setShowModal, handleSubmit, fee, amountDue, feeName }) => {
  const { values, errors } = useFormikContext();
  const handleClose = () => setShowModal(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    (Object.keys(errors)[0])
       ? setDisabled(true)
       : setDisabled(false)
  }, [errors])

  useEffect(() => {
    (values.amount > amountDue || values.amount < 1)
      ? setDisabled(true)
      : setDisabled(false);
  }, [values.amount])

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Waive Fee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          The amount due for the {fee?.name || feeName} is {StringHelper.currency(fee?.due || amountDue)}.<br />
          <BasicForm.TextInput
            name="amount"
            type="number"
            label="Amount"
            labelStyle={labelStyle}
          />

          { (values.amount > fee?.due || amountDue) && <BasicForm.StyledErrorMessage>
              Cannot be more than amount due
            </BasicForm.StyledErrorMessage>
          }
          <BasicForm.TextInput
            name="memo"
            type="text"
            label="Memo"
            labelStyle={labelStyle}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose} />
            <Buttons.Standard
              type="submit"
              onClick={() => handleSubmit(values)}
              text="Submit"
              disabled={disabled}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
