import React from 'react';
import { setDate } from 'components/src/utils/dateHelpers';

export const columnDefinitions = props => {
  const {
    setShowEdit,
    setAuditId,
    lastAuditId,
    setQuiktrakJobNumber,
    setShowAuditImage,
    setImageId,
    setShowUpdateSoldModal
  } = props;

  const definitions = [
    {
      key: 'inspectionOn',
      label: 'Audit Date',
      sort: 'desc',
      value: ({ entry }) => entry.audit.inspectionOn,
      content: ({ value }) => setDate(value)
    },
    {
      key: 'found',
      label: 'Found?',
      value: ({ entry }) => {
        if (entry.qtFound === 0) {
          return 'No'
        } else if (entry.qtFound === 1) {
          return 'Yes'
        }
      },
      content: ({ value }) => value
    },
    {
      key: 'qtStatus',
      label: 'Exception'
    },
    {
      key: 'auditCode',
      label: 'Original Audit Status',
      value: ({ entry }) => entry.auditCode,
      content: ({ value }) => value
    },
    {
      key: 'verificationMethod',
      label: 'Verification Method'
    },
    {
      key: 'updatedAt',
      label: 'Date Verified',
      content: ({ entry }) => {
        if (entry.verificationMethod) {
          return setDate(entry.updatedAt)
        }
      }
    },
    {
      key: 'location',
      label: 'Location',
      content: ({ entry, value }) => {
        if (entry.qtExcpAddress1 || entry.qtExcpAddress2) {
          return (
            <a
              href={`https://maps.google.com/?q=${entry.qtExcpAddress1},${entry.qtExcpAddress2}`}
              target="_blank"
            >
              {value}
            </a>
          )
        } else if (entry.qtStatus === 'SN') {
          return (
            <a
              href={`https://maps.google.com/?q=${entry.flooredCar.dealer.primaryPlace.street},${entry.flooredCar.dealer.primaryPlace.city},${entry.flooredCar.dealer.primaryPlace.state},${entry.flooredCar.dealer.primaryPlace.zip.value}`}
              target="_blank"
            >
              {value}
            </a>
          )
        } else {
          return value
        }
      }
    },
    {
      key: 'qtNotes',
      label: 'Notes'
    },
    {
      key: 'user',
      label: 'System User Who Verified',
      value: ({ entry }) => entry.updatedBy.fullName,
      content: ({ value, entry }) => {
        if (entry.verificationMethod) {
          return value
        }
      }
    },
    {
      key: 'auditPictures',
      label: 'Pictures',
      sort: 'prevent',
      content: ({ entry }) => (
        <>
          {entry.fileUploads.map((file) => (
            <a
              key={file.id}
              style={{ paddingLeft: '10px' }}
              onClick={e => {
                e.preventDefault();
                setShowAuditImage(true);
                setImageId(file.id);
              }}
            >
              <i className="fas fa-file-contract"></i>
            </a>
          ))}
        </>
      )
    },
    {
      key: 'functions',
      label: 'Functions',
      sort: 'prevent',
      content: ({ entry }) => {
        let button = null;

        if (!entry.verified) {
          if (entry.flooredCar.soldOn) {
            button = (
              <a href="#" onClick={() => setShowUpdateSoldModal(true)}>
                Update Sale
              </a>
            )
          } else {
            button = (
              <a
                href="#"
                onClick={
                  () => {
                    setShowEdit(true);
                    setAuditId(entry.id);
                    setQuiktrakJobNumber(entry.audit.quiktrakJobNumber);
                  }
                }
              >
                Resolve
              </a>
            )
          }
        }

        return button
      }
    }
  ]

  return definitions
}
