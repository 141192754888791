import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const FISCAL_MONTH_FIELDS = `
  id
  year
  period
  periodName
  startOn
  endOn
  days
`;

const FISCAL_MONTH_FIELDS_AND_ERRORS = `
  fiscalMonth {
    ${FISCAL_MONTH_FIELDS}
  }
  ${ERRORS}
`;

export const FISCAL_MONTHS = gql`
  query {
    fiscalMonths {
      ${FISCAL_MONTH_FIELDS}
    }
    fiscalMonthIssues
  }
`;

export const FISCAL_MONTH = gql`
  query FiscalMonth($id: ID!) {
    fiscalMonth(id: $id) {
      ${FISCAL_MONTH_FIELDS}
    }
  }
`;

const FISCAL_MONTH_VARIABLES = `
  $year: Int!
  $period: Int!
  $startOn: ISO8601Date!
  $endOn: ISO8601Date!
`;

const FISCAL_MONTH_ARGUMENTS = `
  year: $year
  period: $period
  startOn: $startOn
  endOn: $endOn
`;

export const CREATE_FISCAL_MONTH = gql`
  mutation CreateFiscalMonth(
    ${FISCAL_MONTH_VARIABLES}
  ) {
    createFiscalMonth(
      ${FISCAL_MONTH_ARGUMENTS}
    ) {
      ${FISCAL_MONTH_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_FISCAL_MONTH = gql`
  mutation updateFiscalMonth(
    $id: ID!
    ${FISCAL_MONTH_VARIABLES}
  ) {
    updateFiscalMonth(
      id: $id
      ${FISCAL_MONTH_ARGUMENTS}
    ) {
      ${FISCAL_MONTH_FIELDS_AND_ERRORS}
    }
  }
`;
