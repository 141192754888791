import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Formik, Field } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useAlert } from '../../../hooks/useAlert';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import { classNames } from './functionality';
import { Typography, Spacing } from '../../../styles';
import * as yup from 'yup';
import { Colors } from '../../../styles';
import Loading from '../../../elements/Loading';
import '../../../styles/baseRates.css';
import * as Formatting from '../../../styles/formatting';
import { titleCase } from '../../../utils/stringHelpers';

const NEW_BASE_RATE = gql`
   mutation CreateBaseRate($name: String!, $baseRateChangesAttributes: [BaseRateChangeInput!]!) {
     createBaseRate(
       name: $name
       baseRateChangesAttributes: $baseRateChangesAttributes
     ) {
       baseRate {
         id
         name
         baseRateChanges {
           id
           rate
           effectiveOn
         }
       }
       errors {
         message
         path
       }
     }
   }
`
const BASE_RATES = gql`
  query {
      baseRates {
        id
        name
      }
    }
`

const NewBaseRate = () => {
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [newBaseRate] = useMutation(
    NEW_BASE_RATE,
    {
      update(cache, { data }) {
        const key = Object.keys(data)[0];
        const errors = data[key].errors;
        if (errors?.length) {
          setFormSubmitting(false);
          const errorName = errors[0].path[1];
          const errorMessage = errors[0].message;
          setServerSideErrors(`${titleCase(errorName)} ${errorMessage}`)
        } else {
          cache.writeQuery({
            query: BASE_RATES,
            data: {
              baseRates: {
                id: data.createBaseRate.baseRate.id,
                name: data.createBaseRate.baseRate.name,
                __typename: data.createBaseRate.baseRate.__typename
              }
            }
          })
          setFormSubmitting(false);
          setRedirect(true);
        }
      }
    }
  );

  const today = new Date()

  const schema = yup.object({
    name: yup
      .string()
      .max(80)
      .required('Required'),
    baseRateChangesAttributes: yup.array().of(
      yup.object().shape({
        rate: yup
          .number()
          .min(0, 'Must be positive')
          .required('Required'),
        effectiveOn: yup
          .date().min(today, 'The date must be in the future')
          .required('Required')
      })
    )
  });

  function BaseRateForm() {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/base-rates",
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          name: '',
          baseRateChangesAttributes: [
            {
              rate: 0,
              effectiveOn: ''
            }]
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ paddingRight: 40, paddingLeft: 40 }}>
              <div style={Spacing.formWrapper}>
                <div style={Spacing.formHeader}>
                  <h1 style={Typography.formHeader}>Create A Base Rate</h1>
                </div>
              </div>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Label className='base-rate-changes-label'>Base Rate Changes</Form.Label>
              <Form.Label>Rate</Form.Label>
              <Form.Group className='term-line-errors'>
                <Field className={classNames(errors, touched, 'rate')} type="number" name="baseRateChangesAttributes[0].rate" />
                <ErrorMessage name={"baseRateChangesAttributes[0].rate"} />
              </Form.Group>
              <Form.Label>Effective On</Form.Label>
              <Form.Group className='term-line-errors'>
                <Field className={classNames(errors, touched, 'effectiveOn')} type="date" name="baseRateChangesAttributes[0].effectiveOn" />
                <ErrorMessage name={"baseRateChangesAttributes[0].effectiveOn"} />
              </Form.Group>
              <Button
                type="submit"
                style={buttonColor}
                disabled={!isValid || formSubmitting}
              >
                Create Base Rate
          </Button>
            </Form>
          )}
      </Formik>
    )
  }

  const buttonColor = {
    backgroundColor: Colors.xlGreen,
    borderColor: Colors.xlGreen,
    color: 'white'
  }

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    newBaseRate({
      variables: values
    })
  }

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <>
        <ServerSideErrors />
        <BaseRateForm />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>New Base Rate</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default NewBaseRate;
