import React, { useState, useEffect } from "react";
import * as BasicForm from "../../../../../elements/forms";
import { gql } from "@apollo/client";
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { divStyle, inputStyle } from "./AdvanceType";
import { useFormikContext } from "formik";

const AUCTIONS = gql`
  query {
    auctions {
      id
      name
    }
  }
`;

const APPROVED_SOURCES = gql`
  query {
    approvedSources {
      id
      name
    }
  }
`;

const DEALERS = gql`
  query {
    dealers {
      id
      name
    }
  }
`;

const styledError = {
  marginLeft: "16px",
  marginTop: "0px",
};

export const SourceId = () => {
  const {
    values: { sourceType, sourceId },
    values,
  } = useFormikContext();

  const input = {
    display: 'block',
    width: '30%',
    marginLeft: '10px',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'paddingBox',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'borderColor .15s easeInOut, boxShadow .15s easeInOut',
  }

  let formattedSourceType = `${sourceType} Source`;

    if (sourceType === "ApprovedSource") {
       formattedSourceType =  "Approved Source"
    }

  if (sourceType === 'other') {
    return null;
  }

  return <div style={divStyle}>
    <BasicForm.StyledLabel>Select the {formattedSourceType}:</BasicForm.StyledLabel>
    {
      !sourceType && <p style={{paddingLeft: '10px'}}>Please select a Source Type</p>
    }
    {sourceType && sourceType === "Auction" && (
      <Auctions key="auctions" />
    )}
    {sourceType && sourceType === "ApprovedSource" && (
      <ApprovedSource key="approved-source" />
      )}
    {sourceType && sourceType === "Dealer" && (
      <Dealer key="dealer" />
    )}
  </div>
}

const Auctions = () => {
  const {
    handleBlur,
    setFieldValue,
  } = useFormikContext();
  const { loading, error, data } = useQuery(AUCTIONS);

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { auctions } = data;

  return (
    <BasicForm.AutoCompleteTextInput
      clearButton
      styledError={styledError}
      name="sourceId"
      type="text"
      style={Object.assign({}, inputStyle, { width: "350px", paddingLeft: "10px" })}
      options={auctions}
      labelKey="name"
      handleBlur={handleBlur}
      id="source-data"
      onChange={(selected) => {
        selected.length > 0
          ? setFieldValue("sourceId", selected[0].id)
          : setFieldValue("sourceId", "");
      }}
    />
  );
};

const ApprovedSource = () => {
  const { handleBlur, setFieldValue } = useFormikContext();

  const { loading, error, data } = useQuery(APPROVED_SOURCES);
  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { approvedSources } = data;

  return (
    <BasicForm.AutoCompleteTextInput
      clearButton
      styledError={styledError}
      name="sourceId"
      type="text"
      options={approvedSources}
      labelKey="name"
      style={Object.assign({}, inputStyle, { width: "350px", paddingLeft: "10px" })}
      handleBlur={handleBlur}
      id="source-data"
      onChange={(selected) => {
        selected.length > 0
          ? setFieldValue("sourceId", selected[0].id)
          : setFieldValue("sourceId", "");
      }}
    />
  );
};

const Dealer = () => {
  const id = parseInt(useParams().id);
  const { handleBlur, setFieldValue, values } = useFormikContext();

  values.sourceId = id;

  const { loading, error, data } = useQuery(DEALERS);

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { dealers } = data;

  const defaultSelected = dealers.filter((dealer) => {
    return parseInt(dealer.id) === id;
  })

  return (
    <BasicForm.AutoCompleteTextInput
      clearButton
      styledError={styledError}
      defaultSelected={defaultSelected}
      name="sourceId"
      type="text"
      style={Object.assign({}, inputStyle, { width: "350px", paddingLeft: "10px" })}
      options={dealers}
      labelKey="name"
      handleBlur={handleBlur}
      id="source-data"
      onChange={(selected) => {
        selected.length > 0
          ? setFieldValue("sourceId", selected[0].id)
          : setFieldValue("sourceId", "");
      }}
    />
  );
};
