import React, { Fragment } from 'react';
import * as BasicForm from '../../elements/forms';
import * as yup from "yup";
import { phoneRegExp } from "../../utils/stringHelpers";
import { Spacing, Typography } from '../../styles';
import * as Buttons from '../../elements/buttons';

export const troubleTicketSchema = yup.object({
  name: yup
    .string(),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required('Required'),
  phone: yup
    .string()
    .required("Required")
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    }),
});


export const Form = ({ formSubmitting }) => {
  return (
    <Fragment>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Your Name:"
      />
      <BasicForm.TextInput
        name="phone"
        type="tel"
        label="Phone Number*:"
      />
      <BasicForm.TextInput
        name="email"
        type="email"
        label="Email*:"
      />
      <BasicForm.TextInput
        name="url"
        type="text"
        label="What was the URL of the issue?"
      />
      <BasicForm.TextInput
        name="subject"
        type="text"
        label="In just a few words, what isn't working correctly?"
      />
      <BasicForm.TextInput
        name="problem"
        type="text"
        label="Please describe in detail what you were trying to do and what went wrong:"
      />
      <BasicForm.TextInput
        name="expectation"
        type="text"
        label="What were you expecting to happen?"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={formSubmitting}
        />
      </div>
    </Fragment>
  )
}
