import React from "react";
import { useParams } from 'react-router-dom';
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import Deltas from "../../../../Deltas/";
import DataState from '../../../../DataState';

const BASE_RATE_CHANGE = gql`
  query BaseRateChange($id: ID!) {
    baseRateChange(id: $id) {
      deltas {
        id
        action
        data
        isImport
        createdAt
        updatedAt
        user
      }
    }
  }
`;

const index = () => {
  const id = parseInt(useParams().changeId);
  const { loading, error, data } = useQuery(BASE_RATE_CHANGE, { variables: { id } });

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

 const { deltas } = data.baseRateChange;

  return <Deltas
    deltas={deltas}
    resourceName={name}
   />;
};

export default index;
