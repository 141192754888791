import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { ProcessPrincipalAdjustment } from './ProcessPrincipalAdjustment';
import { Formik } from 'formik';
import { principalSchema, falsePrincipalSchema } from './ProcessPrincipalAdjustment';
import DataState from '../../DataState';

const CREATE_PRINCIPAL_ADJUSTMENT = gql`
  mutation CreatePrincipalAdjustment(
    $flooredCarId: Int!
    $accountId: Int
    $bankAccountId: Int
    $bankDate: ISO8601Date
    $toAmount: Decimal!
    $ach: Boolean
    $memo: String
    $name: String
    $txnType: String
    $advanceType: String
    $paymentType: String
    $reference: String
  ) {
    createPrincipalAdjustment(
      flooredCarId: $flooredCarId
      accountId: $accountId
      bankAccountId: $bankAccountId
      bankDate: $bankDate
      toAmount: $toAmount
      ach: $ach
      memo: $memo
      name: $name
      txnType: $txnType
      advanceType: $advanceType
      paymentType: $paymentType
      reference: $reference
    ) {
      principalAdjustment { id }
      txn {
        id
        txnLines { credit, debit, account { gl } }
      }
      errors { path, message }
    }
  }
`

const NewPrincipalAdjustment = ({ reserveBalance, dealerName, showModal, setShowModal, funded, principal, flooredCarId }) => {
  const [createPrincipalAdjustment, { loading, error }] = useMutation(CREATE_PRINCIPAL_ADJUSTMENT, {
    onCompleted(values) {
      setShowModal(false)
      window.location.reload();
    }
  })

  let today = new Date();
  today = today.toISOString().split('T')[0];

  const handleSubmit = (values) => {
    createPrincipalAdjustment({ variables: values})
  }

  let schema;


  if (funded === true) {
    schema = principalSchema;
  } else {
    schema = falsePrincipalSchema;
  }

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <Formik
      initialValues={{
        flooredCarId: flooredCarId,
        toAmount: '',
        ach: false,
        accountId: null,
        bankAccountId: null,
        bankDate: today,
        name: '',
        memo: '',
        txnType: null,
        reference: ''
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <ProcessPrincipalAdjustment
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        funded={funded}
        principal={principal}
        dealerName={dealerName}
        reserveBalance={reserveBalance}
      />
    </Formik>
  )
}

export default NewPrincipalAdjustment;
