import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_ADMIN_DOCUMENT } from './queries';
import { Spacing, Typography } from 'components/src/styles';
import { Formik } from 'formik';
import Form, { adminDocumentSchema } from './Form';
import Loading from 'components/src/elements/Loading';
import { useServerSideErrors } from 'components/src/hooks/useServerSideErrors'

const NewAdminDocument = () => {
  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_ADMIN_DOCUMENT,
    onCompleteRedirectTo: '/system/admin-documents'
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] =
    useServerSideErrors(serverSideErrorProps);

  return (
    <div style={Spacing.formContainer}>
      {formSubmitting ? (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      ) : (
        <div style={Spacing.formContainer}>
          <div style={Spacing.formWrapper}>
            <div style={Spacing.formHeader}>
              <h1 style={Typography.formHeader}>
                Create New Admin Entry
              </h1>
            </div>
            <ServerSideErrors />
            <Formik
              initialValues={{
                name: '',
              }}
              validationSchema={adminDocumentSchema}
              onSubmit={handleSubmit}
            >
              <Form />
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewAdminDocument;
