import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import { marketSchema, Form } from './Form';
import Loading from '../../../elements/Loading';
import * as Formatting from '../../../styles/formatting';
import { titleCase } from '../../../utils/stringHelpers';

const CREATE_MARKET = gql`
    mutation CreateMarket($name: String!, $timezone: String!) {
      createMarket(
        name: $name
        timezone: $timezone
      ) {
        market {
          id
          name
          timezone
        }
        errors {
          message
          path
        }
      }
    }
`;
//
const New = () => {
  const dismissAlert = () => displayAlert(false)

  const [showAlert, displayAlert] = useAlert();
  const [toMapID, setToMap] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [createMarket] = useMutation(CREATE_MARKET,
    {
      onCompleted(data) {
        const key = Object.keys(data)[0];
        const errors = data[key].errors;
        if (errors?.length) {
          setFormSubmitting(false);
          let errorString = '';
          let errorName = '';
          let errorMessage = '';
          errors.forEach((error, index) => {
            errorName = error.path[1];
            errorMessage = error.message;
            const separator = index > 0 ? ', ' : ''
            errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`
          })
          setServerSideErrors(errorString)
        } else {
          setFormSubmitting(false);
          displayAlert(true);
          setTimeout(dismissAlert, 3000)
          setToMap(data.createMarket.market.id)
        }
      }
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createMarket({ variables: values })
  };

  const ServerSideErrors = () => {
    if (serverSideErrors) {
      return (
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      )
    } return null
  }

  const NewForm = () => {
    if (toMapID) {
      return <Redirect
        to={{
          pathname: `/system/markets/${toMapID}/map`,
          state: { toMapID }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Market</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              name: '',
              timezone: '',
            }}
            validationSchema={marketSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formContainer}>
          <NewForm />
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default New;
