import React, { useEffect } from "react";
import {
  paginate,
  columnSort,
  setParamaterData,
  filterTable,
  searchTable,
  handleLimitChange,
} from "../elements/Pagination/Functions";

export const useLazyLoader = (
  limit,
  fetchMore,
  setQueryData,
  paymentCount,
  marketId,
  currentFilters,
  getColumnName,
  queryData,
  setQueryLoading,
  datatable,
  initializeData,
  setSorting,
  shownColumns,
) => {

  const { initialPageLimit, initialSortDirection, initialSort } = initializeData;

  let [
    offset,
    currentPage,
    sort,
    sortDirection,
    sortColumnIndex,
    search,
  ] = setParamaterData({initialSort, initialSortDirection });

  const handlePaginate = (direction) => {
    paginate(
      direction,
      limit,
      fetchMore,
      setQueryData,
      offset,
      currentPage,
      paymentCount,
      sort,
      sortDirection,
      search,
      marketId,
      currentFilters.current,
      setQueryLoading,
    );
  };

  const handleColumnSort = (columnIndex, direction) => {
    columnSort(
      sortColumnIndex,
      columnIndex,
      sort,
      getColumnName,
      setQueryData,
      sortDirection,
      direction,
      offset,
      currentPage,
      fetchMore,
      limit,
      queryData,
      search,
      marketId,
      currentFilters.current,
      setSorting,
      setQueryLoading,
      shownColumns,
    );
  };

  const handleFilters = (filters) => {
    currentFilters.current = filters;

    filterTable(
      currentFilters.current,
      search,
      sortColumnIndex,
      sort,
      getColumnName,
      setQueryData,
      sortDirection,
      offset,
      currentPage,
      fetchMore,
      limit,
      queryData,
      marketId,
      setQueryLoading,
    );
  };

  const handleSearch = (searchValue) => {
    search.current = searchValue;

    searchTable(
      search,
      sortColumnIndex,
      sort,
      getColumnName,
      setQueryData,
      sortDirection,
      offset,
      currentPage,
      fetchMore,
      limit,
      queryData,
      marketId,
      currentFilters.current,
      setQueryLoading,
    );
  };

  const initialData = 10;
  useEffect(() => {
  if (limit !== initialSort) {
    handleLimitChange(
      fetchMore,
      limit,
      sort,
      sortDirection,
      currentPage,
      datatable,
      setQueryData,
      search,
      marketId,
      currentFilters.current,
      setQueryLoading,
    );
  }
  }, [limit]);

  return [
    handlePaginate,
    handleColumnSort,
    handleFilters,
    handleSearch,
    currentPage,
    sort,
    sortColumnIndex,
    sortDirection,
    offset,
    search,
  ];
};
