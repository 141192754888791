import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const DEALER_PROGRAM_FIELDS = `
  id
  description
  code
  totalTerms
  totalDays
  termDays
  totalExtensions
  terms {
    order,
    type,
    termDays,
    feeType,
    termLines { principalBelow, curtailmentPercentage }
  }
  updatedAt
  createdAt
  activeDealers
  activeFlooredCars
  hiddenAt
  canBeHidden
`;

const DEALER_PROGRAM_FIELDS_AND_ERRORS = `
  dealerProgram {
    ${DEALER_PROGRAM_FIELDS}
  }
  ${ERRORS}
`;

export const DEALER_PROGRAMS = gql`
  query DealerPrograms($scopes: [String!]) {
    dealerPrograms(
      scopes: $scopes
    ) {
      ${DEALER_PROGRAM_FIELDS}
    }
  }
`;

export const HIDE_DEALER_PROGRAM = gql`
  mutation HideDealerProgram($id: ID!) {
    hideDealerProgram(
      id: $id
    ) {
      ${DEALER_PROGRAM_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_DEALER_PROGRAM = gql`
  mutation UnhideDealerProgram($id: ID!) {
    unhideDealerProgram(
      id: $id
    )
    {
      ${DEALER_PROGRAM_FIELDS_AND_ERRORS}
    }
  }
`;
