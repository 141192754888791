import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import * as yup from 'yup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Formik, useFormikContext, Form as FormikForm } from 'formik';
import * as BasicForm from '../../elements/forms';
import { DEALER_SELECTS } from '../../queries/selects';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import Select from 'react-select';
import DataState from '../DataState';
import { Colors } from '../../styles';
import { handleNumberChange } from '../../utils/stringHelpers';
import SelectFromEnum from 'components/src/elements/SelectFromEnum';

export const dealerSchema = yup.object({
  name: yup.string().required('Required').max(100, 'Must be 100 characters or less'),
  legalName: yup.string('Required').max(100, 'Must be 100 characters or less'),
  licenseNumber: yup.string('Required').max(30, 'Must be 30 characters or less'),
  accountNumber: yup.string('Required').max(9, 'Must be 9 characters or less'),
  branchId: yup.number().required('Required').typeError('You must select a branch'),
  marketId: yup.number().required('Required').typeError('You must select a market'),
  dealerStatusId: yup.number().required('Required').typeError('You must select a status for the dealer'),
  street: yup.string().required('Required'),
  city: yup.string().required('Required'),
  state: yup.string().required('Required'),
  zip: yup.string().required('Required'),
  locationName: yup.string().required('Required'),
  reservesAmount: yup.number().required('Required'),
});

const backgroundStyle = {
  backgroundColor: '#f1f1f1',
  paddingBottom: '40px',
  marginTop: '10px',
}

export const Form = (props) => {
  const { setFieldValue, values, isValid, setValues } = useFormikContext();
  const { data: selectData, loading: selectLoading, error: selectError } = useQuery(DEALER_SELECTS);
  const [formSubmitting, setFormSubmitting] = useState();

  if (selectLoading) return <DataState.Loading />
  if (selectError) return <DataState.Error error={error} />;
  const formattedAutomatedFees = selectData.automatedFees.map((fee) => {
    return { value: fee.id, label: fee.name };
  })

  const formattedDealerCategories = selectData.dealerCategories.map((type) => {
    return { value: type.id, label: type.name };
  })

  const handleTypeChange = (selectedList) => {
    if (!selectedList) {
      values.dealerCategoryIds = [];
      setValues(values)
      return
    }

    selectedList = selectedList.map((type) => {
      return parseInt(type.value, 10);
    })

    values.dealerCategoryIds = selectedList
    setValues(values)
  }

  const handleChange = (selectedList) => {
    if (!selectedList) {
      setFieldValue('automatedFeeResourcesAttributes', []);
      return
    }

    selectedList = selectedList.map((fee) => {
      return { id: parseInt(fee.value) };
    })

    setFieldValue('automatedFeeResourcesAttributes', selectedList);
  }

  return (
    <FormikForm>
      <Container fluid>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="name"
              type="text"
              label="Dealer Name"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="legalName"
              type="text"
              label="Legal Name"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="accountNumber"
              type="number"
              label="Account Number"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="contractedOn"
              type="date"
              label="Contracted On"
            />
          </Col>
          <Col sm>
            <BasicForm.Checkbox
              name="poaReceived"
              label="POA Received?"
            />
          </Col>
        </Row>
        {
          selectData &&
          <>
            <Row style={backgroundStyle}>
              <Col sm>
                <BasicForm.ProgrammaticSelect name="marketId" label="Market" optionName="Market" options={selectData.markets} />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect name="branchId" label="Branch" optionName="Branch" options={selectData.branches} />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect name="accountRepId" label="Account Rep" optionName="Account Rep" options={selectData.users} />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect name="serviceRepId" label="Service Rep" optionName="Service Rep" options={selectData.users} />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect name="dealerStatusId" label="Dealer Status" optionName="Dealer Status" options={selectData.dealerStatuses} />
              </Col>
            </Row>
            <Row style={Object.assign({}, backgroundStyle, { alignItems: 'center' })}>
              <Col sm>
                <BasicForm.TextInput
                  name="reservesAmount"
                  type="number"
                  label="Add to Reserves"
                />
              </Col>
              <Col sm>
                <BasicForm.TextInput
                  name="creditLine"
                  type="number"
                  label="Credit Line"
                />
              </Col>
              <Col sm>
                <BasicForm.TextInput
                  name="contractedLine"
                  type="number"
                  label="Contracted Line"
                />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect
                  name="rateProgramId"
                  label="Rate Program"
                  optionName="Rate Program"
                  options={selectData.ratePrograms}
                />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect
                  name="dealerProgramId"
                  label="Dealer Program"
                  optionName="Dealer Program"
                  options={selectData.dealerPrograms}
                />
              </Col>
            </Row>
            <Row style={backgroundStyle}>
              <Col sm>
                <BasicForm.StyledLabel>Automated Fees</BasicForm.StyledLabel>
                <Select
                  options={formattedAutomatedFees}
                  isMulti={true}
                  onChange={handleChange}
                  styles={customStyles}
                  defaultValue={props.preSelectedAutomatedFees}

                />
              </Col>
            </Row>
            <Row style={backgroundStyle}>
              <Col sm={8}>
                <BasicForm.StyledLabel>Dealer Type</BasicForm.StyledLabel>
                <Select
                  options={formattedDealerCategories}
                  isMulti={true}
                  onChange={handleTypeChange}
                  styles={customStyles}
                />
              </Col>
              <Col sm={2}>
                <SelectFromEnum
                  name="entityType"
                  label="Entity Type"
                  modelName="Dealer"
                />
              </Col>
              {'Other' === values.entityType && (
                <Col sm>
                  <BasicForm.TextInput
                    name="entityTypeOther"
                    type="text"
                    label="Other Entity Type - Describe"
                  />
                </Col>
              )}
            </Row>
          </>
        }
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="licenseNumber"
              type="text"
              label="License Number"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="licenseType"
              type="text"
              label="License Type"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="licenseExpiresOn"
              type="date"
              label="License Expires On"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="annualReviewOn"
              type="date"
              label="Annual Review Date"
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="internalCreditScore"
              type="text"
              label="Internal Credit Score"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="recentBusinessTaxYear"
              type="text"
              label="Recent Business Tax Year"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="businessFinancialsDate"
              type="date"
              label="Business Financials Date"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="recentPersonalTaxYear"
              type="text"
              label="Recent Personal Tax Year"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="personalFinancialsDate"
              type="date"
              label="Personal Financials Date"
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="locationName"
              type="text"
              label="Primary Location Name"
            />
          </Col>
          <Col sm>
            <BasicForm.Select name="locationType" label="Type">
              <option value="">Select a Place Type</option>
              <option value="Lot">Lot</option>
              <option value="Storage">Storage</option>
              <option value="Repair Facility">Repair Facility</option>
              <option value="Dealer's Home">Dealer's Home</option>
              <option value="Other">Other</option>
            </BasicForm.Select>
          </Col>
          <Col sm>
            <BasicForm.PhoneInput
              name="phone"
              label="Phone Number"
              onChange={event => handleNumberChange(event, 'phone', setFieldValue)}
              value={values.phone || ''}
            />
          </Col>
          <Col sm>
            <BasicForm.PhoneInput
              name="fax"
              label="Fax #"
              onChange={event => handleNumberChange(event, 'fax', setFieldValue)}
              value={values.fax || ''}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="attention"
              type="text"
              label="Attention To:"
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="street"
              type="text"
              label="Primary Location Street"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="city"
              type="text"
              label="City"
            />
          </Col>
          <Col sm>
            <BasicForm.StateAbbreviationSelect
              name="state"
              type="text"
              label="State"
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="zip"
              type="text"
              label="Zip"
            />
          </Col>
        </Row>
      </Container>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  )
}

const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })

}
