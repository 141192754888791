import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';

export const bankAccountSchema = yup.object({
  accountName: yup.string().required('Required'),
  routingNumber: yup.string()
    .test('len', 'Must be exactly 9 digits', val => val.length === 9)
});

export const Form = () => {
  const { isValid, values } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="accountName"
        type="text"
        label="Account Name"
      />
      <BasicForm.TextInput
        name="bankName"
        type="text"
        label="Bank Name"
      />
      <BasicForm.TextInput
        name="routingNumber"
        type="text"
        label="Routing Number"
      />
      <BasicForm.TextInput
        name="accountNumber"
        type="text"
        label="Account Number"
      />
      <BasicForm.Select
        name="type"
        label="Type"
        value={values.type || ''}
      >
      <option value="">Select a Type</option>
      <option value='checking'>Checking</option>
      <option value='savings'>Savings</option>
      </BasicForm.Select>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid || formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
