import { gql } from '@apollo/client'

export const CREATE_NOTE = gql`
  mutation CreateNote(
    $subject: String!
    $note: String
    $ownerType: String!
    $ownerId: Int!
  ) {
    createNote(
      subject: $subject
      note: $note
      ownerId: $ownerId
      ownerType: $ownerType
    ) {
      note {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

export const UNSOLD = gql`
  mutation Unsold(
    $id: ID!
    $note: String
  ) {
    unsold(
      id: $id
      note: $note
    ) {
      errors {
        message
        path
      }
    }
  }
`
