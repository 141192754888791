import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../contexts/AuthContext';
import { AUTH_TOKEN } from '../../../constants';
import * as Buttons from '../../../elements/buttons';
import secrets from '../../../secrets';
import axios from 'axios';
import Edit from './Edit';
import Group from './Group';

const showHiddenLink = {
  alignSelf: 'flex-end',
  paddingBottom: '0.65rem',
  paddingLeft: '0.7rem',
  fontSize: '0.8rem',
}

const Settings = (props) => {
  const indexResource = (resource) => {
    let path = `index_${resource}`
    const token = localStorage.getItem(AUTH_TOKEN);
    let formattedUserIds;
    axios({
      method: 'put',
      url: `${secrets.api.baseUri}/${path}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'JWT_AUD': 'react'
      }
    })
      .then(res => {
        console.log('res', res)
      });
  }

  return (
    <>
      <Edit />
      <Group title="Elastic Search">
        <div>
          <div>
            <Buttons.Link text="Index Approved Sources" style={showHiddenLink} onClick={() => indexResource('approved_sources')} />
          </div>
          <div>
            <Buttons.Link text="Index Auctions" style={showHiddenLink} onClick={() => indexResource('auctions')} />
          </div>
          <div>
            <Buttons.Link text="Index Dealers" style={showHiddenLink} onClick={() => indexResource('dealers')} />
          </div>
          <div>
            <Buttons.Link text="Index Dealer People" style={showHiddenLink} onClick={() => indexResource('dealer_people')} />
          </div>
          <div>
            <Buttons.Link text="Index Floored Cars" style={showHiddenLink} onClick={() => indexResource('floored_cars')} />
          </div>
          <div>
            <Buttons.Link text="Index Users" style={showHiddenLink} onClick={() => indexResource('users')} />
          </div>
          <div>
            <Buttons.Link text="Index Vendors" style={showHiddenLink} onClick={() => indexResource('vendors')} />
          </div>
        </div>
      </Group>
    </>
  )

}

export default Settings;
