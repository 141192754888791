import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DataState from 'components/src/components/DataState';
import {
  currencyNoCents,
  integerWithDelimiter
} from 'components/src/utils/stringHelpers';
import { TaskCard, Head, Data, Label, Value } from '../Cards';
import '../styles.css';
import { TableLink } from '../Tables';

const percent = (value, scale) => (value * 100).toFixed(scale || 1) + '%';

const Tasks = ({ stats }) => {
  return (
    <Container className="market-stats">
      <h2>Tasks</h2>

      <Row>
        <Col xs={6} lg={2}>
          <TableLink label="Open Audits" to="/audits" />
          <TaskCard>
            <Data>
              <Label>Number Open</Label>
              <Value>{stats.openAuditsCount}</Value>
            </Data>
            <Data>
              <Label>Open > 7 Days</Label>
              <Value>{stats.openAuditsCount7Days}</Value>
            </Data>
          </TaskCard>
        </Col>

        <Col xs={6} lg={2}>
          <TableLink
            label="Upcoming Payments & Past Due"
            to="/dashboard/payments-due"
          />
          <TaskCard>
            <Data>
              <Label>Delinquency > 8 Days</Label>
              <Value>{currencyNoCents(stats.delinquentAmount)}</Value>
            </Data>
            <Data>
              <Label>Percent Delinquent vs Book Total</Label>
              <Value>{percent(stats.delinquentRate, 3)}</Value>
            </Data>
          </TaskCard>
        </Col>

        <Col xs={6} lg={2}>
          <TableLink
            label="Unpaid Borrowed Titles"
            to="/dashboard/borrowed-titles"
          />
          <TaskCard>
            <Data>
              <Label>Borrowed Titles</Label>
              <Value>{stats.borrowedTitles}</Value>
            </Data>
            <Data>
              <Label>Past Due Borrowed Title</Label>
              <Value>{stats.borrowedTitlesDue}</Value>
            </Data>
          </TaskCard>
        </Col>

        <Col xs={12} lg={4}>
          <TableLink
            label="Unverified and Sold Cars"
            to="/dashboard/unverified"
          />

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <TaskCard style={{width: '48%'}}>
              <Data>
                <Label>Unverified Units</Label>
                <Value>{stats.unverifiedCount}</Value>
              </Data>
              <Data>
                <Label>Unverified > 7 Days</Label>
                <Value>{stats.unverifiedCount7Days}</Value>
              </Data>
            </TaskCard>

            <TaskCard style={{width: '48%'}}>
              <Data>
                <Label>Units Sold</Label>
                <Value>{stats.soldCount}</Value>
              </Data>
              <Data>
                <Label>Sold > 7 Days</Label>
                <Value>{stats.soldCount7Days}</Value>
              </Data>
            </TaskCard>
          </div>
        </Col>

        <Col xs={6} lg={2}>
          <TableLink label="POA & CPP" to="/dashboard/poa-cpp" />

          <TaskCard>
            <Data>
              <Label>POAs</Label>
              <Value>{stats.poas}</Value>
            </Data>
            <Data>
              <Label>CPPs</Label>
              <Value>{stats.cpps}</Value>
            </Data>
          </TaskCard>
        </Col>
      </Row>
    </Container>
  );
}

  export default Tasks;
