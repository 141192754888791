import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import Table from 'components/src/components/Transactions/Table';
import { Typography } from 'components/src/styles';

const index = () => {
  const id = parseInt(useParams().id);

  const shownColumns = [
    'bankDate', 'createdDate', 'type', 'purpose', 'amount', 'flooredCars',
    'createdBy', 'actions'
  ];

  const availableFilters = false;

  return (
    <Fragment>
      <div style={Typography.pageTitle}>NSF History</div>
      <Table
        shownColumns={shownColumns}
        availableFilters={availableFilters}
        scopes={['nsf']}
        referrerType="dealer"
        referrerId={id}
        reportPath="transaction-lines-report"
        disableExportToExcel
      />
    </Fragment>
  );
};

export default index;
