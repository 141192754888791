import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Spacing } from '../../../styles';
import { arbitrationSchema, Form } from './Form';
import { FLOORED_CAR } from '../../../queries/viewQueries';

const ARBITRATE = gql`
  mutation Arbitrate(
    $id: ID!,
    $arbitratedOn: ISO8601Date,
    $arbitrationReason: String
  ) {
    arbitrate(
      id: $id
      arbitratedOn: $arbitratedOn
      arbitrationReason: $arbitrationReason
    ) {
      errors { message, path }
    }
  }
`

const ArbitrationModal = props => {
  let {
    show,
    handleClose,
    name,
    refetch,
    showPageRefetch
  } = props;

  const id = parseInt(useParams().carId);

  const [arbitrate, { loading }] = useMutation(ARBITRATE, {
    onCompleted(variables) {
      refetch();
      showPageRefetch();
      handleClose();
    }
  });

  const handleSubmit = (values) => {
    if (values.arbitratedOn === '') values.arbitratedOn = null;

    arbitrate({ variables: values })
  }

  const ModalBody = () => {
    return (
      <div style={Spacing.formWrapper}>
        <Formik
          initialValues={{
            id,
            arbitratedOn: '',
            arbitrationReason: '',
          }}
          validationSchema={arbitrationSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`Arbitrate ${name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(loading) ? <p>Loading...</p> : <ModalBody />}
      </Modal.Body>
    </Modal>
  )
}

export default ArbitrationModal;
