import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { getServerSideErrors } from 'components/src/utils/errorHelpers';

import { Spacing, Typography } from 'components/src/styles';
import Loading from 'components/src/elements/Loading';
import { xlGreen } from 'components/src/styles/colors';

import { CREATE_CUSTOM_NOTIFICATION } from './queries';
import Form, { customNotificationSchema } from './Form';

const NewCustomNotification = () => {
  const [serverSideErrors, setServerSideErrors] = useState('');
  const [formSubmitting, setFormSubmitting] = useState(false);

  const history = useHistory();

  const [createCustomNotification] = useMutation(CREATE_CUSTOM_NOTIFICATION,
    {
      onCompleted(values) {
        setFormSubmitting(false);
        const serverErrors = getServerSideErrors(values);
        if (serverErrors) {
          setServerSideErrors(serverErrors);
        } else {
          history.push({
            pathname: '/system/custom-notifications',
            state: { alert: true, type: 'created' },
          })
        }
      }
    }
  );

  const handleSubmit = values => {
    setFormSubmitting(true);
    createCustomNotification({ variables: values });
  };

  return (
    <>
      <Helmet>
        <title>Custom Notification</title>
      </Helmet>
      { serverSideErrors &&
        <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
      }
      <div style={Spacing.formContainer}>
        {formSubmitting ? <Loading /> : (
          <div style={Spacing.formWrapper}>
            <div style={Spacing.formHeader}>
              <h1 style={Typography.formHeader}>
                Create a Custom Notification
              </h1>
              <Link to="/system/custom-notifications" style={{color: xlGreen}}>
                View past notifications
              </Link>
            </div>
            <Formik
              initialValues={{
                body: '',
                link: '',
                dealers: false,
                employees: false,
                marketIds: []
              }}
              validationSchema={customNotificationSchema}
              onSubmit={handleSubmit}
            >
              <Form />
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default NewCustomNotification;
