import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useRouteMatch, useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import DataState from '../../DataState';
import { Form, undoFundingSchema } from './Form';
import * as Formatting from '../../../styles/formatting';
import Modal from 'react-bootstrap/Modal';
import { isoToday } from '../../../utils/dateHelpers';
import { titleCase } from '../../../utils/stringHelpers';
import { FLOORED_CAR } from '../../../queries/viewQueries';
import { TITLE_STATUSES } from '../../../queries/indexQueries';
import { getServerSideErrors } from '../../../utils/errorHelpers';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors';
import { Spacing } from '../../../styles';

const UNDO_FUNDING = gql`
  mutation UndoFunding(
    $id: ID!
    $bankDate: ISO8601Date
    $type: String
    $accountId: Int!
    $reference: String
    $memo: String
    $titleStatusId: Int
  ) {
    undoFunding(
      id: $id
      bankDate: $bankDate
      type: $type
      accountId: $accountId
      reference: $reference
      memo: $memo
      titleStatusId: $titleStatusId
    ) {
      txn {
        id
      }
      errors { path, message }
    }
  }
`;

const UndoFundingModal = ({show, handleClose, name, flooredCar}) => {
  const id = flooredCar.id;

  const [serverSideErrors, setServerSideErrors] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  const [undoFunding] = useMutation(UNDO_FUNDING, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FLOORED_CAR, variables: { id } }],
    onCompleted(values) {
      const serverErrors = getServerSideErrors(values);
      if (serverErrors) {
        setServerSideErrors(serverErrors);
      } else {
        handleClose();
        window.location.reload();
      }
    }
  });

  const handleSubmit = (values) => {
    setSubmitting(true);
    undoFunding({ variables: values });
  };

  const ModalBody = () => {
    return (
      <div style={Spacing.formWrapper}>
        { serverSideErrors &&
          <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
        }
        {isSubmitting && <span>Submitting...</span>}
        {!isSubmitting &&
          <Formik
            initialValues={{
              id,
              bankDate: isoToday(),
              type: '',
              reference: '',
              memo: '',
              accountId: null,
            }}
            validationSchema={undoFundingSchema}
            onSubmit={handleSubmit}
          >
            <Form
              flooredCar={flooredCar}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </Formik>
        }
      </div>
    )
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`Undo Funding ${name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalBody />
      </Modal.Body>
    </Modal>
  )
}

export default UndoFundingModal;
