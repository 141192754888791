import React from 'react'

const MissingEmails = ({ names }) => {
  if (!names.length) return null;

  return (
    <div style={{paddingTop: '20px'}}>
      <p>The following people do not have emails in ATLAS:</p>
      <ul>
        { names.map((name) => {
           return <li>{name}</li>
         })
        }
      </ul>
    </div>
  )
}

export default MissingEmails;
