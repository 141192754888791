import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import * as yup from 'yup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useFormikContext, Form as FormikForm } from 'formik';
import * as BasicForm from '../../elements/forms';
import { DEALER_SELECTS } from '../../queries/selects';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import Select from 'react-select';
import DataState from '../DataState';
import { Colors } from '../../styles';
import AuthContext from '../../contexts/AuthContext';
import featureEnabled from 'components/src/utils/featureEnabled';
import SelectFromEnum from 'components/src/elements/SelectFromEnum';

export const dealerSchema = yup.object({
  name: yup.string().required('Required')
    .max(100, 'Must be 100 characters or less'),
  legalName: yup.string('Required')
    .max(100, 'Must be 100 characters or less'),
  licenseNumber: yup.string('Required')
    .max(30, 'Must be 30 characters or less'),
  branchId: yup.number().required('Required')
    .typeError('You must select a branch'),
  marketId: yup.number().required('Required')
    .typeError('You must select a market'),
  accountNumber: yup.string('Required')
    .max(9, 'Must be 9 characters or less')
});

const backgroundStyle = {
  backgroundColor: '#f1f1f1',
  paddingBottom: '40px',
  marginTop: '10px',
}

export const Form = (props) => {
  const { setFieldValue, values, isValid, setValues } = useFormikContext();
  const {
    data: selectData,
    loading: selectLoading,
    error: selectError
  } = useQuery(DEALER_SELECTS);
  const [formSubmitting, setFormSubmitting] = useState();

  const auth = useContext(AuthContext);

  if (selectLoading) return <DataState.Loading />
  if (selectError) return <DataState.Error error={selectError} />;

  let list = [];

  const formattedAutomatedFees = selectData.automatedFees.map((fee) => {
    const feeList = props.preSelectedAutomatedFees.map((feeId) => {
      if (parseInt(fee?.id) !== feeId?.value) {
        list.push({ value: parseInt(fee.id), label: fee.name });
      }
    })
  })

  const key = 'label';

  const arrayUniqueByKey = [...new Map(list.map(item =>
    [item[key], item])).values()];

  const handleTypeChange = (selectedList) => {
    if (!selectedList) {
      values.dealerCategoryIds = [];
      setValues(values)
      return
    }

    selectedList = selectedList.map((type) => {
      return parseInt(type.value, 10)
    })

    values.dealerCategoryIds = selectedList
    setValues(values)
  }

  const handleChange = (selectedList) => {
    if (!selectedList) {
      setFieldValue('automatedFeeResourcesAttributes', []);
      return
    }

    selectedList = selectedList.map((fee) => {
      return { id: parseInt(fee.value) };
    })

    setFieldValue('automatedFeeResourcesAttributes', selectedList);
  }

  const preSelectedCategoryIds = values.dealerCategoryIds.map((typeId) => {
    const dealerType = selectData.dealerCategories.find(
      (value) => parseInt(value.id, 10) === typeId
    )
    return { value: dealerType.id, label: dealerType.name }
  })

  const formattedCategories = selectData.dealerCategories.map((type) => {
    return { value: type.id, label: type.name }
  })

  const disabled = !auth.hasPolicy('Dealer', 'update');

  const titleLimitsDisabled =
    !auth.hasExactPolicy('Dealer', 'title_exceptions') ||
    !values.borrowedTitleOverride

  let submitOptions = (
    <Buttons.Standard
      type="submit"
      text="Submit"
      disabled={
        !isValid ||
        formSubmitting ||
        (disabled && !(auth.hasExactPolicy('Dealer', 'title_exceptions')))
      }
    />
  );

  if (disabled && auth.hasExactPolicy('Dealer', 'exclude_from_autopay')) {
    submitOptions = (
      <Buttons.Standard
        type="submit"
        text="Submit (Exclude from Autopay only)"
        disabled={formSubmitting}
      />
    );
  }

  return (
    <FormikForm>
      <Container fluid>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="name"
              type="text"
              label="Dealer Name"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="legalName"
              type="text"
              label="Legal Name"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="accountNumber"
              type="number"
              label="Account Number"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="contractedOn"
              type="date"
              label="Contracted On"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.Checkbox
              name="poaReceived"
              label="POA Received?"
              disabled={disabled}
            />
          </Col>
        </Row>
        {
          selectData &&
          <>
            <Row style={backgroundStyle}>
              <Col sm>
                <BasicForm.ProgrammaticSelect
                  disabled={disabled}
                  name="marketId"
                  label="Market"
                  optionName="Market"
                  options={selectData.markets}
                />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect
                  disabled={disabled}
                  name="branchId"
                  label="Branch"
                  optionName="Branch"
                  options={selectData.branches}
                />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect
                  disabled={disabled}
                  name="accountRepId"
                  label="Account Rep"
                  optionName="Account Rep"
                  options={selectData.users}
                />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect
                  disabled={disabled}
                  name="serviceRepId"
                  label="Service Rep"
                  optionName="Service Rep"
                  options={selectData.users}
                />
              </Col>
            </Row>
            <Row
              style={Object.assign(
                {}, backgroundStyle, { alignItems: 'center' }
              )}
            >
              <Col sm>
                <BasicForm.TextInput
                  name="reservesAmount"
                  type="number"
                  label="Add to Reserves"
                  disabled={disabled}
                />
              </Col>
              <Col sm>
                <BasicForm.TextInput
                  name="creditLine"
                  type="number"
                  label="Credit Line"
                  disabled={disabled}
                />
              </Col>
              <Col sm>
                <BasicForm.TextInput
                  name="contractedLine"
                  type="number"
                  label="Contracted Line"
                  disabled={disabled}
                />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect
                  name="rateProgramId"
                  label="Rate Program"
                  optionName="Rate Program"
                  options={selectData.ratePrograms}
                  disabled={disabled}
                />
              </Col>
              <Col sm>
                <BasicForm.ProgrammaticSelect
                  name="dealerProgramId"
                  label="Dealer Program"
                  optionName="Dealer Program"
                  options={selectData.dealerPrograms}
                  disabled={disabled}
                />
              </Col>
              <Col sm>
                <BasicForm.Checkbox
                  name="autopayDisabled"
                  label="Exclude From Autopay?"
                  disabled={!auth.hasExactPolicy('Dealer', 'exclude_from_autopay')}
                />
              </Col>
            </Row>
            <Row style={backgroundStyle}>
              <Col sm>
                <BasicForm.StyledLabel>Automated Fees</BasicForm.StyledLabel>
                <Select
                  options={arrayUniqueByKey}
                  isMulti={true}
                  onChange={handleChange}
                  styles={customStyles}
                  defaultValue={props.preSelectedAutomatedFees}
                  isDisabled={disabled}
                />
              </Col>
            </Row>
            <Row style={backgroundStyle}>
              <Col sm={8}>
                <BasicForm.StyledLabel>Dealer Types</BasicForm.StyledLabel>
                <Select
                  options={formattedCategories}
                  defaultValue={preSelectedCategoryIds}
                  isMulti={true}
                  onChange={handleTypeChange}
                  styles={customStyles}
                  isDisabled={disabled}
                />
              </Col>
              <Col sm={2}>
                <SelectFromEnum
                  name="entityType"
                  label="Entity Type"
                  modelName="Dealer"
                />
              </Col>
              {'Other' === values.entityType && (
                <Col sm>
                  <BasicForm.TextInput
                    name="entityTypeOther"
                    type="text"
                    label="Other Entity Type - Describe"
                    disabled={disabled}
                  />
                </Col>
              )}
            </Row>
          </>
        }
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="licenseNumber"
              type="text"
              label="License Number"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="licenseType"
              type="text"
              label="License Type"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="licenseExpiresOn"
              type="date"
              label="License Expires On"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="annualReviewOn"
              type="date"
              label="Annual Review Date"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="internalCreditScore"
              type="text"
              label="Internal Credit Score"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="recentBusinessTaxYear"
              type="text"
              label="Recent Business Tax Year"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="businessFinancialsDate"
              type="date"
              label="Business Financials Date"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="recentPersonalTaxYear"
              type="text"
              label="Recent Personal Tax Year"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="personalFinancialsDate"
              type="date"
              label="Personal Financials Date"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.Checkbox
              name="borrowedTitleOverride"
              label="Override Borrowed Title Limits?"
              disabled={!auth.hasExactPolicy('Dealer', 'title_exceptions')}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="borrowedTitleMinimumCars"
              type="number"
              label="Title Limits - Minimum Cars Floored"
              disabled={titleLimitsDisabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="borrowedTitleCountLimit"
              type="number"
              label="Title Limits - Max Borrowed Titles"
              disabled={titleLimitsDisabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="borrowedTitlePrincipalLimit"
              type="number"
              label="Title Limits - Max Total A/R of Cars With Borrowed Titles"
              disabled={titleLimitsDisabled}
            />
          </Col>
        </Row>
      </Container>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        {submitOptions}
      </div>
    </FormikForm>
  )

}

export const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })

}
