import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import AuthContext from 'components/src/contexts/AuthContext';
import { Helmet } from 'react-helmet';
import DataState from 'components/src/components/DataState';
import DataTables from '@preflighttech/preflight-tables';
import {
  AUCTION_AVAILABILITIES,
  RESEND_AUCTION_AVAILABILITIES
} from './queries';
import { CustomAlert } from 'components/src/elements/BasicAlert';
import { preflightTableStyles, Typography } from 'components/src/styles';

const AuctionAvailabilities: (props) => React$Node = (props) => {
  const auth = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState();

  const { loading, error, data, refetch } = useQuery(AUCTION_AVAILABILITIES);

  const [resendAvailabilities] = useMutation(
    RESEND_AUCTION_AVAILABILITIES,
    {
      onCompleted: () => {
        refetch();
        setAlertMessage('Availability Resent');
      }
    }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const handleResend = (e, entry) => {
    e.preventDefault();
    e.stopPropagation();
    setAlertMessage('Resending...');
    resendAvailabilities({ variables: { id: entry.id } });
  };

  const columns = [
    {
      key: 'name',
      label: 'Name',
      sort: 'asc',
      content: ({ entry, value }) => (
        <Link to={`/auctions/${entry.id}`}>{value}</Link>
      )
    },
    {
      key: 'auctionAccessId',
      label: 'Auction Access ID',
    },
    {
      key: 'markets',
      label: 'Markets',
      value: ({ value }) => value.map(auction => auction.name).join(' | '),
    },
    {
      key: 'contactsWhoReceiveAvailability',
      label: 'Contacts Set to Receive Availabilities',
    },
    {
      key: 'lastEmailAvailabilitySentAt',
      label: 'Last Availability Email Sent At',
    },
    {
      key: 'actions',
      label: 'Resend Availabilities',
      sort: 'prevent',
      content: ({ entry }) => {
        if (auth.hasPolicy('Auction', 'manage')) {
          return (
            <a href="#" onClick={e => handleResend(e, entry)}>
              Resend
            </a>
          );
        } else {
          return null;
        }
      }
    }
  ]

  return (
    <>
      <Helmet>
        <title>Auction Availabilities sent by Email</title>
      </Helmet>
      {alertMessage &&
        <CustomAlert message={alertMessage} variant="success" />
      }
      <div style={Typography.pageTitle}>Auctions</div>
      <DataTables.Simple
        data={data.auctions}
        columns={columns}
        pageLength={10}
        htmlTable
        movableColumns
        multiSort
        styles={preflightTableStyles}
      />
    </>
  );
};

export default AuctionAvailabilities;
