import React from 'react';
import { Route, Switch } from "react-router-dom";
import EditVendor from '../components/Vendors/Edit';
import Contacts from '../components/Vendors/Contacts';
import NewContact from '../components/Vendors/Contacts/New';
import EditContact from '../components/Vendors/Contacts/Edit';
import Notes from '../components/Vendors/Notes';
import NewNote from '../components/Notes/New';
import EditNote from '../components/Notes/Edit';
import Transactions from '../components/Vendors/Transactions';

const VendorRoutes = () => {
  return (
    <>
      <Switch>
        <Route path="/vendors/:id/edit" component={EditVendor} />
        <Route path="/vendors/:id/contacts" component={Contacts} exact />
        <Route path="/vendors/:id/contacts/new" component={NewContact} />
        <Route path="/vendors/:id/contacts/:contactId" component={EditContact} />
        <Route path="/vendors/:id/notes" component={Notes} exact />
        <Route path="/vendors/:id/notes/new" component={NewNote} exact />
        <Route path="/vendors/:ownerId/notes/:id/edit" component={EditNote} exact />
        <Route path="/vendors/:id/transactions" component={Transactions} exact />
      </Switch>
    </>
  )
}

export default VendorRoutes;
