import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const DateRange = ({columnKey, label, filters, setFilter}) => {
  const columnFilter = filters[columnKey] || [];

  const [fromDate, setFromDate] = useState(columnFilter[0]);
  const [toDate, setToDate] = useState(columnFilter[1]);

  if ('undefined' === typeof filters[columnKey]) {
    setFilter(columnKey, null);
    if (fromDate) { setFromDate(undefined) };
    if (toDate) { setToDate(undefined) };
  }

  const updateFilter = () => {
    if (
      fromDate && !isNaN(Date.parse(fromDate)) && Date.parse(fromDate) > 0 &&
      toDate && !isNaN(Date.parse(toDate)) && Date.parse(toDate) > 0
    ) {
      setFilter(columnKey, [fromDate, toDate]);
    }
  }

  const updateFromDate = value => {
    setFromDate(value);
    updateFilter();
  };

  const updateToDate = value => {
    setToDate(value);
    updateFilter();
  };

  return (
    <Row key={columnKey}>
      <Col>
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col>
        From
        <Form.Control
          type="date"
          value={fromDate || ''}
          onBlur={event => updateFromDate(event.currentTarget.value)}
          onChange={event => updateFromDate(event.currentTarget.value)}
        />
      </Col>
      <Col>
        To
        <Form.Control
          type="date"
          value={toDate || ''}
          onBlur={event => updateToDate(event.currentTarget.value)}
          onChange={event => updateToDate(event.currentTarget.value)}
        />
      </Col>
    </Row>
  );
};

export default DateRange;
