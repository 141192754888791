import React, { useState, useEffect, useContext } from 'react';
import { useParams, useRouteMatch, useLocation, Redirect } from
  'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { titleCase } from '../../utils/stringHelpers';
import { Helmet } from 'react-helmet';
import DataState from '../DataState';
import './dealershow.css';
import DealerRoutes from '../../routes/DealerRoutes';
import Cars from '../Cars';
import Notes from './Notes';
import { useAlert } from '../../hooks/useAlert';
import BasicAlert, { UnauthorizedAlert } from '../../elements/BasicAlert';
import { setAlertData } from '../../utils/setAlertData';
import { getDealerSubNavLinks } from './functions'
import SubNav from '../../elements/SubNav'
import * as Formatting from '../../styles/formatting';
import { UserContext } from '../../contexts/UserContext';
import AuthContext from '../../contexts/AuthContext';
import { hrStyle } from '../Cars/Style';
import Reporting from './Reporting';

export const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      dealerStatus {
        id
        name
        canFloor
      }
      market {
        id
        name
      }
    }
  }
`;

const useURLQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Dealer = (props) => {
  let checkRefresh = useURLQuery();
  const forceRefresh = checkRefresh.get("forceRefresh");
  if (forceRefresh) {
    window.location.assign(useLocation().pathname);
    return null;
  }
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const [showReportingModal, setShowReportingModal] = useState(false);

  const { loading, error, data } = useQuery(DEALER, { variables: { id } });
  const componentType = props.location.state?.componentType || '';

  const user = useContext(UserContext);

  useEffect(() => setAlertData(props, setAlertType, displayAlert),
    [props.location.state?.alert]
  )

  const route = useRouteMatch();

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { name, dealerStatus } = data.dealer;

  // Set up variables to determine whether dealer is out of market
  // Trigger redirect if so.
  const userMarket = user.currentMarket.id;
  const dealerMarket = data.dealer.market.id;
  const outOfMarketDealer = userMarket !== dealerMarket && userMarket;

  const title = {
    link: `/dealers/${id}`,
    text: name,
  }

  const navLinks = getDealerSubNavLinks(id, auth, setShowReportingModal);

  const canFloor = dealerStatus.canFloor ? true : false;

  const NoFloorInfoBar = ({ dealerStatus }) => {
    const noFloor = {
      ...Formatting.infoBar,
      backgroundColor: '#d62300'
    }

    const noFloorText = {
      ...Formatting.infoBarImportantText,
      borderColor: '#de381c',
      background: '#e64a31'
    }

    return !canFloor ? (
      <>
        <div style={noFloor}>
          Dealer can not floor cars due to their current status:
            &nbsp;<span style={noFloorText}>{dealerStatus.name}</span>
        </div>
      </>
    ) : null
  }

  const unauthorizedRedirect = props.history.location.state?.unauthorized;

  const Alert = () => {
    if (showAlert && unauthorizedRedirect) {
      return <UnauthorizedAlert resource={'Write Offs'} />
    } else if (showAlert) {
      return <BasicAlert componentType={componentType} type={alertType} />
    } else {
      return null;
    }
  }

  return (
    <>
      {
        outOfMarketDealer ?
          <Redirect
            to={{
              pathname: "/dealers",
              state: { alert: true, outOfMarket: true }
            }}
          /> :
          null
      }
      {showReportingModal && (
        <Reporting
          dealer={data.dealer}
          handleCancel={() => setShowReportingModal(false)}
        />
      )}
      <Helmet>
        <title>{titleCase(name)}</title>
      </Helmet>
      <SubNav title={title} navLinks={navLinks} canFloor={canFloor} />
      <DealerRoutes />
      <NoFloorInfoBar dealerStatus={dealerStatus} />
      {
        route.isExact &&
          <>
            <Alert />
            <Cars textForCreate={'Floor A Car'} />
            <hr style={hrStyle} />
            <Notes id={id} />
          </>
      }
    </>
  );
}

export default Dealer;
