import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const BASE_RATE_FIELDS = `
  id
  name
  createdAt
  updatedAt
  hiddenAt
  baseRateChanges {
    id
    rate
    effectiveOn
  }
  canBeHidden
`;

const BASE_RATE_FIELDS_AND_ERRORS = `
  baseRate {
    ${BASE_RATE_FIELDS}
  }
  ${ERRORS}
`;

export const BASE_RATES = gql`
  query BaseRates($scopes: [String!]) {
    baseRates(
      scopes: $scopes
    ) {
      ${BASE_RATE_FIELDS}
    }
  }
`;

export const HIDE_BASE_RATE = gql`
  mutation HideBaseRate($id: ID!) {
    hideBaseRate(
      id: $id
    ) {
      ${BASE_RATE_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_BASE_RATE = gql`
  mutation UnhideBaseRate($id: ID!) {
    unhideBaseRate(
      id: $id
    )
    {
      ${BASE_RATE_FIELDS_AND_ERRORS}
    }
  }
`;
