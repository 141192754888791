import React from 'react';
import AuthLink from 'components/src/components/AuthLink';
import { Helmet } from 'react-helmet';
import { Typography } from 'components/src/styles';
import * as Colors from 'components/src/styles/colors';
import { addRecord } from 'components/src/styles/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheck } from '@fortawesome/pro-regular-svg-icons';

const ResourcesHeader = props => {
  const {
    title,
    subtitle,
    prefix,
    resourceType,
    showHidden,
    setShowHidden,
    noNew,
    noHelmet
  } = props;

  return (
    <>
      {!noHelmet && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <div style={Typography.pageTitle}>{title}</div>
      <div style={Typography.formSubHeader}>{subtitle}</div>
      {setShowHidden && (
        <div style={{marginLeft: 10}}>
          <AuthLink
            to="#"
            resource={resourceType}
            action="hide"
            onClick={() => setShowHidden(!showHidden)}
            style={{
              paddingLeft: '0.5rem', fontSize: '0.8rem', color: Colors.xlGreen
            }}
          >
            {showHidden ? 'Show Visible' : 'Show Hidden'}
          </AuthLink>
        </div>
      )}
      {!noNew && (
        <AuthLink
          to={`${prefix}/new`}
          resource={resourceType}
          action="create"
          style={addRecord}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
        </AuthLink>
      )}
    </>
  )
};

export default ResourcesHeader;
