import React, { useRef, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Formik, useFormikContext } from 'formik';
import { isoToday } from '../../../utils/dateHelpers';
import { Spacing, Typography } from '../../../styles';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'
import Form, { txnSchema } from './Form';
import useClosedBooks from 'components/src/hooks/useClosedBooks';
import {
  CREATE_TXN as CREATE_JOURNAL_ENTRY
} from 'components/src/queries/mutations';
import DataState from "../../DataState";

const NEXT_JE_NUMBER = gql`
  query SettingsForUi {
    settingsForUi {
      nextJeNumber
    }
  }
`

const JournalEntry = () => {
  const { data } = useQuery(NEXT_JE_NUMBER)

  const customSubmitHandler = (values) => {
    values.typeName = undefined

    if (values.entityId === '') {
      values.entityId = undefined;
    } else {
      values.entityId = parseInt(values.entityId);
    }

    if (values.entityType === '') {
      values.entityType = undefined;
    }

    values.txnLinesAttributes.map((line, index) => {
      if (line.credit === '') {
        line.credit = undefined;
      } else if (line.debit === '') {
        line.debit = undefined;
      }

      if (line.accountId === '') {
        line.accountId = undefined;
      }

      if (line.marketId === '') {
        line.marketId = undefined;
      }

      if((line.credit === undefined || line.credit === '') && (line.debit === undefined || line.debit === '')) {
        values.txnLinesAttributes.splice(index, 1)
      }
    })
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_JOURNAL_ENTRY,
    redirectToShowPageType: 'txn',
    customSubmitHandler: customSubmitHandler
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);
  const checkClosedBooks = useClosedBooks(handleSubmit);

  if (!data) return <DataState.Loading />

  const NewForm = () => {
    const nextJeNumber = data.settingsForUi.nextJeNumber.toString()

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Journal Entry</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              type: 'Journal Entry',
              createdDate: isoToday(),
              bankDate: isoToday(),
              name: '',
              reference: nextJeNumber || '',
              memo: '',
              purpose: undefined,
              entityType: undefined,
              entityId: undefined,
              txnLinesAttributes: [
                {
                  debit: '',
                  credit: '',
                  accountId: undefined,
                  marketId: undefined,
                  dealerId: undefined,
                  flooredCarId: undefined,
                  feeId: undefined,
                  purpose: undefined,
                  earnedOn: isoToday(),
                  earnsInterest: false,
                  memo: ''
                }
              ]
            }}
            validationSchema={txnSchema}
            onSubmit={checkClosedBooks}
          >
          <Form />
        </Formik>
        </div>
      </>
    );
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default JournalEntry;
