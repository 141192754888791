const filter = (rawData, filteredData) => {
  return rawData.filter((data) => {
    // Filter has date
    if (!filteredData.dueDate.includes('')) {
      const dateFrom = filteredData.dueDate[0].split('-');
      const dateTo = filteredData.dueDate[1].split('-');

      // NOTE This is currently using test data for bankDate - i'm not quite sure
      // how the date is queried yet, so we may need to change this and the 'check'
      // variable to be similar to the ones above.
      const currentDate = data.txn.bankDate.split('/');
      const from = new Date(dateFrom[0], parseInt(dateFrom[2])-1, dateFrom[1]);  // -1 because months are from 0 to 11
      const to = new Date(dateTo[0], parseInt(dateTo[2])-1, dateTo[1]);
      const check = new Date(currentDate[2], parseInt(currentDate[1])-1, currentDate[0]);

     // Filter has date and Payee
     if (filteredData.payee !== '') {

       // Payee is correct && date is correct
       if (data.txn.entity?.name === filteredData.payee && (check >= from && check <= to))
         return data;
       } else if ( filteredData.payee === '' && check >= from && check <= to) {
         return data;
       }

       return;
     }

     // Filter only has payee
     if (filteredData.payee !== '') {
       // Filter Payee is correct
       if (data.txn.name === filteredData.payee) return data;
     }
   })
}

export default filter;
