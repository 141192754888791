import React, { useState, Fragment } from "react";
import { useMutation, gql } from "@apollo/client";
import { Formik } from "formik";
import { useHistory } from 'react-router-dom';
import { Spacing, Typography } from "../../../styles";
import { paymentTermSchema, Form } from "./Form";
import { useServerSideErrors } from "../../../hooks/useServerSideErrors";
import Loading from "../../../elements/Loading";
import { PAYMENT_TERMS } from './index';

const CREATE_PAYMENT_TERM = gql`
  mutation CreatePaymentTerm(
    $name: String!,
    $days: Int!
  ) {
    createPaymentTerm(
      name: $name
      days: $days
     ) {
      paymentTerm { name, days }
      errors {
        message
        path
      }
    }
  }
`

const NewPaymentTerm = () => {
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createPaymentTerm, { errors }] = useMutation(CREATE_PAYMENT_TERM, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PAYMENT_TERMS,
      }
    ],
   onCompleted(values) {
      setFormSubmitting(false);
      history.push({
        pathname: '/system/payment-terms',
        state: { alert: true, type: 'created' }
      })
    }
  })

  const handleSubmit = (values) => {
    setFormSubmitting(true);


    createPaymentTerm({ variables: values })
  }

  const New = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Create A Payment Term</h1>
        </div>
        <Formik
          initialValues={{
            name: '',
            days: 0,
          }}
          validationSchema={paymentTermSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    );
  };

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <New />
      </div>
    );
  };

  return (
    <Fragment>
      <DisplayContent />
    </Fragment>
  );
};

export default NewPaymentTerm;
