import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: '20px 0px'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const removeItemStyle = {
  paddingLeft: '10px',
}

const descriptionStyle = {
  margin: '0px',
  padding: '40px 0px',
}

export const Dropzone = (props) => {
    // onDrop returns a function that will return our file.
    const onDrop = useCallback((files) => {
        files.forEach(file => props.handleFile(file));
    });

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'image/jpeg, image/png', onDrop });

    const handleRemove = (file) => {
        if (props.handleRemove) {
           props.handleRemove(file)
        }

      acceptedFiles.pop()
    }

    const files = acceptedFiles.map(file => (
        <li style={descriptionStyle} key={file.path}>
            {file.path} – {file.size} bytes
            <span style={removeItemStyle}><a href="#" onClick={() => handleRemove(file.path)}>Remove</a></span>
        </li>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    return (
        <section className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                { (files.length) ? files
                  : <p style={descriptionStyle}>Drag and drop an image here, or click to select an image.</p> }
            </div>
        </section>
    );
}

export const PdfDropzone = (props) => {

    // onDrop returns a function that will return our file.
    const onDrop = useCallback(
        ([file]) => {

          props.handleFile(file);
        }

    );

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: '.pdf', onDrop });

    const handleRemove = () => {
      acceptedFiles.pop()
    }

    const files = acceptedFiles.map(file => (
        <li style={descriptionStyle} key={file.path}>
            {file.path} – {file.size} bytes
            <span style={removeItemStyle}><a href="#" onClick={handleRemove}>Remove</a></span>
        </li>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    return (
        <section className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                { (files.length === 1) ? files
                  : <p style={descriptionStyle}>Drag and drop PDF file here, or click to select a file.</p> }
            </div>
        </section>
    );
}

export const CustomizableDropzone = props => {
  const [files, setFiles] = useState([]);

  const accept = props.accept || 'image/jpeg, image/png'
  const description = props.description ||
    'Drag and drop an image here, or click to select an image.';

  const onDrop = useCallback(droppedFiles => {
    const newFiles = files.concat(droppedFiles);
    if (props.setFiles) { props.setFiles(newFiles); }
    setFiles(newFiles);
  });

  const handleRemove = useCallback((file, e) => {
    e.stopPropagation();

    const index = files.indexOf(file);
    files.splice(index, 1);
    if (props.setFiles) { props.setFiles([...files]); }
    setFiles([...files]);
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept, onDrop });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragActive, isDragReject]);

  const filesList = (
    <ul style={descriptionStyle}>
      {files.map(file => (
        <li key={file.path}>
          {file.path} – {file.size} bytes
          <a
            onClick={e => handleRemove(file, e)}
            style={removeItemStyle}
          >
            Remove
          </a>
        </li>
      ))}
    </ul>
  );

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {files.length ? filesList : (
          <p style={descriptionStyle}>{description}</p>
        )}
      </div>
    </section>
  );
};
