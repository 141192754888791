import React, { useState } from 'react';
import EmailQuote from './Modal';
import { Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';

const SEND_QUOTE_EMAIL = gql`
  mutation sendQuoteEmail($dealerPeopleIds: [Int!],
    $copy: Boolean $quoteId: Int, $dealerId: Int,
    $paymentDate: ISO8601Date
  ) {
    sendQuoteEmail(
      dealerPeopleIds: $dealerPeopleIds
      copy: $copy
      quoteId: $quoteId
      dealerId: $dealerId
      paymentDate: $paymentDate
    ) {
      errors { message, path }
    }
  }
`

const NewEmailQuote = ({setSendQuoteModal, sendQuoteModal, dealerId, setEmailButton, quoteId, paymentDate}) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [sendQuoteEmail] = useMutation(SEND_QUOTE_EMAIL, {
    onCompleted() {
      setFormSubmitting(false);
      setRedirect(true)
    }
  })
  const handleSubmit = (values) => {
    setFormSubmitting(true)
    sendQuoteEmail({ variables: values })
  }

  if (redirect)
    return (
      <Redirect
        to={{
          pathname: `/dealers/${dealerId}`,
          state: { alert: true, type: 'processed', componentType: 'quote' },
        }}
      />
    );

  return (
    <Formik
      initialValues={{
        dealerPeopleIds: undefined,
        copy: false,
        quoteId: quoteId,
        dealerId: dealerId,
        paymentDate: paymentDate
      }}
      onSubmit={handleSubmit}
    >
      <EmailQuote
        setSendQuoteModal={setSendQuoteModal}
        sendQuoteModal={sendQuoteModal}
        dealerId={dealerId}
        setEmailButton={setEmailButton}
        handleSubmit={handleSubmit}
        formSubmitting={formSubmitting}
      />
    </Formik>
  )
}

export default NewEmailQuote;
