import React from 'react';
import { PageTitle } from 'components/src/styles';
import { ChooseVIN } from './Options';

const SingleCarAnyDealer = () => {
  return (
    <>
      <PageTitle>Payments Report - Single Car</PageTitle>
      <div style={{marginLeft: 20, marginRight: 20}}>
        <ChooseVIN report="Payment Car" handleCancel={() => {}} />
      </div>
    </>
  );
};

export default SingleCarAnyDealer;
