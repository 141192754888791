import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import DataState from '../../DataState';
import { Helmet } from 'react-helmet';
import DataTable from '../../../elements/DataTable';
import AuthContext from '../../../contexts/AuthContext';
import { Container, Row, Col } from 'react-bootstrap';
import * as Style from '../../../styles/Source/showInfo';
import '../../../styles/Source/showInfo.css';
import { ACCOUNT_GROUP } from './queries';
import { titleCase } from 'components/src/utils/stringHelpers';

const Show = () => {
  const id = parseInt(useParams().id);
  const auth = useContext(AuthContext);

  const { loading, error, data, refetch } = useQuery(
    ACCOUNT_GROUP, { variables: { id } }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const accountGroup = data.accountGroup;
  const accounts = accountGroup.accounts;

  const columns = [
    {
      title: "GL"
    },
    {
      title: "Name"
    },
    {
      title: "Special"
    },
    {
      title: "Actions"
    }
  ]

  const dataSet = accounts.map(function (account) {
    if (auth.hasPolicy('Role', 'update')) {
      account.actions = `
        <a href="/financial/accounts/${account.id}"/>View</a> |
        <a href="/financial/accounts/${account.id}/edit"/>Edit</a>
      `
    }

    const special = account.special?.name || '';

    const accountName = `<a href="/financial/accounts/${account.id}"/>${account.name}</a>`;

    return [
      account.gl,
      accountName,
      special,
      account.actions
    ]
  }).filter((data) => data)

  return (
    <>
      <Helmet>
        <title>Account Group</title>
      </Helmet>
      <Container style={Style.auctionInfoContainer}>
        <Row>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>Increased By:</Col>
              <Col align="right">{titleCase(accountGroup.increasedBy)}</Col>
            </Row>
            <Row className="info-row">
              <Col>Scopes:</Col>
              <Col align="right">{accountGroup.listedScopes.join(', ')}</Col>
            </Row>
          </div>
        </Row>
      </Container>
      <div style={Style.bottomBorder} />
      <DataTable
        dataSet={dataSet}
        columns={columns}
        uncreatable={true}
        unhideable={true}
        pageTitle={`Account Group: ${accountGroup.name}`}
      />
    </>
  )
}

export default Show;
