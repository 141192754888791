import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { ADMIN_DOCUMENT, UPDATE_ADMIN_DOCUMENT } from './queries';
import { Spacing, Typography } from 'components/src/styles';
import { Formik } from 'formik';
import Form, { editAdminDocumentSchema } from './Form';
import DataState from 'components/src/components/DataState';
import Loading from 'components/src/elements/Loading';
import { useServerSideErrors } from 'components/src/hooks/useServerSideErrors'

const EditAdminDocument = () => {
  const id = parseInt(useParams().id);

  const { loading, error, data } =
    useQuery(ADMIN_DOCUMENT, { variables: { id } });

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_ADMIN_DOCUMENT,
    onCompleteRedirectTo: '/system/admin-documents'
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] =
    useServerSideErrors(serverSideErrorProps);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <div style={Spacing.formContainer}>
      {formSubmitting ? (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      ) : (
        <div style={Spacing.formContainer}>
          <div style={Spacing.formWrapper}>
            <div style={Spacing.formHeader}>
              <h1 style={Typography.formHeader}>
                Replace Admin Document
              </h1>
            </div>
            <ServerSideErrors />
            <Formik
              initialValues={{
                id: data.adminDocument.id,
                name: data.adminDocument.name,
              }}
              validationSchema={editAdminDocumentSchema}
              onSubmit={handleSubmit}
            >
              <Form />
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAdminDocument;
