import React from 'react';
import { useParams } from 'react-router-dom';
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import Deltas from "../../Deltas/";
import DataState from '../../DataState';

const CAR = gql`
  query FlooredCar($id: ID!) {
    flooredCar(id: $id) {
      deltas {
        id
        action
        data
        isImport
        createdAt
        updatedAt
        user
      }
    }
  }
`;

const index = () => {
  const id = parseInt(useParams().carId);
  const { loading, error, data } = useQuery(CAR, { variables: { id }});

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { deltas } = data.flooredCar;

  return <Deltas
    deltas={deltas}
  />

}

export default index;
