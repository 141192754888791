import { gql } from '@apollo/client'
import { DEALER_MAIN_FIELDS } from 'components/src/components/Dealers/Queries';

export const NSFS = gql`
  query OpenNsfs(
    $marketIds: [ID!]
  ) {
    openNsfs(
      marketIds: $marketIds
    ) {
      entries {
        txn {
          bankDate
        }
        dealer {
          ${DEALER_MAIN_FIELDS}
          recentAudits {
            inspectionOn
            foundPercentage
            unverifiedAuditCarsCount
            unverifiedAuditCarsValue
          }
          openNsfCount
          pastDueCount
          sotCount
          repoCount
        }
        fee {
          id
          name
        }
        flooredCar {
          id
          vin
          unitNumber
          scheduledAchs
        }
        amount
      }
    }
  }
`;
