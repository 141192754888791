import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import DataState from '../DataState';
import { Colors } from '../../styles';
import { TITLE_STATUSES_FOR_SELECT } from '../../queries/indexQueries';
import { phoneRegExp, handleNumberChange } from "../../utils/stringHelpers";
import { optionsFromValues } from 'components/src/elements/Filter/MultiSelect';

const ROLES = gql`
  query {
    markets { id, name }
  }
`

const autofill = {
  marginTop: '1%',
  marginBottom: '1%',
  fontWeight: 550,
  color: 'white',
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen
}

export const auctionSchema = yup.object({
  name: yup.string().required('Required.'),
  status: yup
    .string()
    .oneOf(['Approved', 'Unapproved', 'Inactive', "Closed", 'On Hold'])
    .required('Required'),
  phone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    }),
  pmsiPhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    })
    .when('pmsiOption', {
      is: true,
      then: yup.string().required('Required')
    }),
  pmsiAddress: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  }),
  pmsiCity: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  }),
  pmsiState: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  }),
  pmsiZip: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  }),
  pmsiCompanyName: yup.string().when('pmsiOption', {
    is: true,
    then: yup.string().required('Required')
  })
});

export const Form = ({ formSubmitting }) => {
  const { values, setFieldValue, setValues, errors, isValid } = useFormikContext();
  const { loading, error, data } = useQuery(ROLES);
  const { loading: tsLoading, error: tsError, data: tsData } = useQuery(TITLE_STATUSES_FOR_SELECT);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  if (tsLoading && !tsData) return <DataState.Loading />
  if (tsError) return <DataState.Error error={tsError} />;

  const { titleStatuses } = tsData;

  const handleChangeMarket = selected => {
    if (selected) {
      values.marketIds = selected.map(option => parseInt(option.value, 10));
    } else {
      values.marketIds = [];
    }

    setValues(values);
  }

  const handleChangeTitleFundingStatuses = selected => {
    if (selected) {
      values.titleFundingStatusIds =
        selected.map(option => parseInt(option.value, 10));
    } else {
      values.titleFundingStatusIds = [];
    }

    setValues(values);
  }

  const customStyles = {
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: Colors.xlGreen,
      color: 'white',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      backgroundColor: Colors.xlGreen,
      color: 'white'
    })
  }

  const handleFill = () => {
    values.pmsiAddress = values.street
    values.pmsiCity = values.city
    values.pmsiState = values.state
    values.pmsiZip = values.zip
    values.pmsiCompanyName = values.name
    values.pmsiPhone = values.phone

    setValues(values)
  }

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.TextInput
        name="street"
        type="text"
        label="Street"
      />
      <BasicForm.TextInput
        name="city"
        type="text"
        label="City"
      />
      <BasicForm.StateAbbreviationSelect
        name="state"
        type="text"
        label="State"
      />
      <BasicForm.TextInput
        name="zip"
        type="text"
        label="Zip"
      />
      <BasicForm.PhoneInput
        name="phone"
        country="US"
        value={values.phone || ''}
        onChange={ event => handleNumberChange(event, 'phone', setFieldValue) }
        label="Phone Number"
      />
      <BasicForm.Checkbox
        id="checkbox1"
        name="xlgAuction"
        type="checkbox"
        label="XLG Auction?"
      />
      <BasicForm.TextInput
        name="xlgAuctionFloat"
        type="number"
        label="XLG Auction Float Days"
      />
      <BasicForm.Select name="status" label="Status">
        <option value="">Select a Status</option>
        <option value="Approved">Approved</option>
        <option value="Unapproved">Unapproved</option>
        <option value="Inactive">Inactive</option>
        <option value="Closed">Closed</option>
        <option value="On Hold">On Hold</option>
      </BasicForm.Select>
      <BasicForm.TextInput
        name="auctionAccessId"
        type="number"
        label="Auction Access ID#"
      />
      <BasicForm.TextInput
        name="url"
        type="text"
        label="Website"
      />
      <BasicForm.StyledLabel>Markets</BasicForm.StyledLabel>
      <Select
        options={optionsFromValues(data.markets)}
        defaultValue={optionsFromValues(data.markets, values.marketIds)}
        isMulti={true}
        onChange={handleChangeMarket}
        styles={customStyles}
      />
      <BasicForm.StyledLabel>Title Funding Statuses</BasicForm.StyledLabel>
      <Select
        options={optionsFromValues(titleStatuses)}
        defaultValue={
          optionsFromValues(titleStatuses, values.titleFundingStatusIds)
        }
        isMulti={true}
        onChange={handleChangeTitleFundingStatuses}
        styles={customStyles}
      />
      <BasicForm.Checkbox
        id="checkbox2"
        name="pmsiOption"
        type="checkbox"
        label="PMSI?"
      />
      {
        values.pmsiOption &&
          <button type="button" style={autofill} onClick={() => handleFill()}>
            Auto Fill PMSI Information
          </button>
      }
      {
        values.pmsiOption &&
          <BasicForm.TextInput
            name="pmsiAddress"
            type="text"
            label="PMSI Address"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.TextInput
            name="pmsiCity"
            type="text"
            label="PMSI City"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.StateAbbreviationSelect
            name="pmsiState"
            type="text"
            label="PMSI State"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.TextInput
            name="pmsiZip"
            type="text"
            label="PMSI Zip"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.PhoneInput
            name="pmsiPhone"
            country="US"
            value={values.pmsiPhone || ''}
            onChange={ event => handleNumberChange(event, 'pmsiPhone', setFieldValue) }
            label="PMSI Phone Number"
          />
      }
      {
        values.pmsiOption &&
          <BasicForm.TextInput
            name="pmsiCompanyName"
            type="text"
            label="PMSI Company Name"
          />
      }
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={formSubmitting}
        />
      </div>
    </FormikForm>
  );
}
