import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import ProcessCredit from './ProcessCredit';
import { Formik } from 'formik';
import { CREATE_DEALER_TXN } from '../../../../../queries/mutations';
import DataState from '../../../../DataState';
import * as yup from 'yup';

const NewCredit = ({ showModal, setShowModal, dealer }) => {
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [createDealerTxn, { loading, error }] = useMutation(CREATE_DEALER_TXN, {
    onCompleted(values) {
      setFormSubmitting(false)
      setShowModal(false)
      window.location.reload();
    }
  })

  let today = new Date();
  today = today.toISOString().split('T')[0];

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createDealerTxn({ variables: values})
  }

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    <Formik
      initialValues={{
        dealerId: parseInt(dealer.id),
        direction: 'Deposit',
        type: 'On Account',
        txnType: 'Journal Entry',
        accountId: null,
        bankDate: today,
        amount: '',
        name: dealer.name || '',
        reference: '',
        memo: '',
      }}

      onSubmit={handleSubmit}

      validationSchema={yup.object({
        amount: yup
          .number()
          .required('required'),
        accountId: yup
          .number()
          .required('required'),
      })}
    >
      <ProcessCredit
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        formSubmitting={formSubmitting}
        dealerName={dealer.name}
      />
    </Formik>
  )
}

export default NewCredit;
