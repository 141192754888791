import { gql } from '@apollo/client';

export const REPORTS = gql`
  query Report(
    $reportName: String
    $scopes: [String!]
  ) {
    reports(
      reportName: $reportName
      scopes: $scopes
    ) {
      id
      reportName
      createdBy { id, fullName }
      fileUrl
      createdAt
      reportStartOn
      reportEndOn
      params
    }
  }
`;
