import React, { useContext, useEffect, useState } from 'react';
import BasicAlert from '../../../elements/BasicAlert';
import { useAlert } from '../../../hooks/useAlert';
import { useQuery } from '@apollo/client';
import DataTables from '@preflighttech/preflight-tables';
import AuthContext from '../../../contexts/AuthContext';
import DataState from '../../DataState';
import { setAlertData } from '../../../utils/setAlertData';
import { INSURANCE_STATES } from './queries';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import ResourceActionLinks from 'components/src/elements/ResourceActionLinks';
import { preflightTableStyles } from 'components/src/styles';

const InsuranceStates = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const { loading, error, data } = useQuery(INSURANCE_STATES);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      key: 'name',
      label: 'Name',
      sort: 'asc',
    },
    {
      key: 'state',
      label: 'State',
    },
    {
      key: 'default',
      label: 'Default',
      value: ({entry}) => entry.defaultInsuranceProgram?.name
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <ResourceActionLinks
          prefix="/system/insurance-states"
          resource={entry}
          noRead
        />
      )
    }
  ];

  return (
    <>
      {showAlert && (
        <BasicAlert componentType="Branch" type={alertType} />
      )}
      <ResourcesHeader
        title="Default Collateral Coverage Programs for Each State"
        resourceType="Collateral Coverage"
        noNew
      />

      <DataTables.Simple
        data={data.insuranceStates}
        columns={columns}
        pageLength={10}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        movableColumns
        multiSort
        styles={preflightTableStyles}
      />
    </>
  )
};

export default InsuranceStates;
