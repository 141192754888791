import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useParams } from "react-router-dom";
import moment from 'moment'
import AuthContext from '../../../contexts/AuthContext';
import DataState from '../../DataState';
import DataTable from '../../../elements/DataTable';
import { currency } from '../../../utils/stringHelpers';
import { DateHelpers } from '../../../utils';

export const FLOORPLAN_MESSAGES = gql`
  query FlooredCar($id: ID!) {
    flooredCar(id: $id) {
      floorplanMessages {
        id
        createdAt
        processedAt
        vin
        type
        formattedBody
      }
    }
  }
`

const index = () => {
  const id = parseInt(useParams().carId);
  const { loading, error, data } = useQuery(FLOORPLAN_MESSAGES, {
    variables: { id }
  });
  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const columns = [
    {
      title: "Time Created",
      visible: true
    },
    {
      title: "Time Processed",
      visible: true
    },
    {
      title: "VIN",
      visible: true
    },
    {
      title: "Type",
      visible: true
    },
    {
      title: "Body",
      visible: true
    }
  ]
  const dataSet = data.flooredCar.floorplanMessages.map(function (message) {
    const createdAt = DateHelpers.setTime(message.createdAt)
    const processedAt = DateHelpers.setTime(message.processedAt)
    const body = `<pre>${message.formattedBody}</pre>`
    return [
      createdAt,
      processedAt,
      message.vin,
      message.type,
      body,
    ]
  })

  return (
    <>
      <Helmet>
        <title>Floorplan Messages</title>
      </Helmet>
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Floorplan Messages'}
        tableId='#fpm-table'
        unhideable
        uncreatable
      />
    </>
  );
};

export default index;
