import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment'
import { useParams } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import { DateHelpers } from '../../utils';
import { AUTH_TOKEN } from '../../constants';
import Preview from './Preview';
import ReportFilter from './ReportsFilter';
import * as Buttons from '../../elements/buttons';
import secrets from '../../secrets';
import { useAlert } from '../../hooks/useAlert';
import { useQuery } from '@apollo/client';
import { REPORTS } from './queries';
import DataState from '../DataState';
import { setAlertData } from '../../utils/setAlertData';
import BasicAlert, { ReportProcessingAlert, UnauthorizedAlert } from "../../elements/BasicAlert";
import { hasFinancialAuth, isFinancialReport } from './functions';
import DataTables from '@preflighttech/preflight-tables';
import { preflightTableStyles, Typography } from 'components/src/styles';
import { columnDefinitions } from './columnDefinitions';

const container = {
  paddingLeft: '0.5%',
  paddingRight: '0.5%'
}

const reportsFilterTitle = {
  fontWeight: 'bold',
  fontSize: '1.5em',
  color: 'rgb(214, 142, 0)',
  marginTop: '1%',
  paddingLeft: '0.5%'
}

const filterContainer = {
  width: '40%',
  paddingLeft: '1.5%'
}

const filterHeader = {
  fontWeight: 'bold',
  fontSize: '1em',
  color: 'rgb(214, 142, 0)',
  marginTop: '1%'
}

export const allowFilterOptions = (reportName) => {
  // Add report names that don't have filters if necessary.
  const unfilterableReports = ['cars']

  return !unfilterableReports.includes(reportName)
}

const getRedirectLink = (reportName) => {
  return <a href={`/reports/${reportName}`}>
    {`Click here to generate a new report from the ${reportName} page`}
  </a>
}

const REPORT_BUILDER_NAMES = [
  'auction-addresses',
  'audit-exception-detail',
  'annual-review',
  'approved-source-addresses',
  'autopay-payments',
  'other-fees-detail',
  'transactions-summary',
];

const Report = (props) => {
  const [filterResources, setFilterResources] = useState({
    startOn: '', endOn: ''
  });
  const [processing, setProcessing] = useState(false);
  const [preview, setPreview] = useState(false);

  const handleFilters = (resources) => {
    setProcessing(true);
    setFilterResources({ ...resources });
    printReport(resources);
  }

  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  let reportName = props.match.params.report;
  const [customAlert, displayCustomAlert] = useState(false);

  const queryVariables = { reportName: reportName };
  if ('transactions' === reportName) {
    queryVariables.scopes = ['system_wide'];
  }

  let { loading, error, data, refetch } = useQuery(
    REPORTS, { variables: queryVariables }
  );

  const auth = useContext(AuthContext);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const printReport = resources => {
    const axios = require('axios');
    const token = localStorage.getItem(AUTH_TOKEN);
    const FileDownload = require('js-file-download');
    const path = REPORT_BUILDER_NAMES.includes(reportName) ?
      `report/${reportName}` :
      `${reportName}-report`;

    const handleBlank = value => value || undefined;

    axios({
      method: 'get',
      url: `${secrets.api.baseUri}/${path}`,
      // responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/xlsx',
        'Authorization': `${token}`,
        'JWT_AUD': 'react'
      },
      params: {
        'start-on': handleBlank(resources.startOn),
        'end-on': handleBlank(resources.endOn),
        'compare-start-on': handleBlank(resources.compareToStartOn),
        'compare-end-on': handleBlank(resources.compareToEndOn),
        'report-period': handleBlank(resources.reportPeriod),
        'calculate-retained-earnings': resources.calculateRetainedEarnings,
        'date-type': handleBlank(resources.dateType),
        'dealer-statuses': handleBlank(resources['dealer-statuses']),
        'dealer-id': handleBlank(resources.dealerId),
        'dealer-ids': resources.dealerIds,
        'automated-fee-ids': resources.automatedFeeIds,
        'market-id': handleBlank(resources.marketId),
        'query-type': handleBlank(resources.queryType),
        'annual-review-start-on':
          handleBlank(resources.annualReviewStartOn),
        'annual-review-end-on': handleBlank(resources.annualReviewEndOn),
        'ucc-expires-start-on': handleBlank(resources.uccExpiresStartOn),
        'ucc-expires-end-on': handleBlank(resources.uccExpiresEndOn),
        'user-ids': resources.userIds,
        'year': handleBlank(resources.year),
        'kpi-period': handleBlank(resources.kpiPeriod),
        'calculate-retained-earnings': filterResources.calculateRetainedEarnings,
      }
    })
      .then(res => {
        const response = res.data;
        var date = new Date();
        var timestamp = date.getTime();
        if (parseInt(res.status) < 300) displayCustomAlert(true);
        setProcessing(false);
        refetch();
      });
  }

  const filterTitle = () => {
    let re = /(\b[a-z](?!\s))/g;
    return reportName.replace(re, function (x) { return x.toUpperCase(); }).replace(/-/g, ' ');
  }

  let reportTitle = filterTitle();

  if ('kpis' === reportName) { reportTitle = 'KPI'; }

  if (preview) {
    return <Preview {...preview} />;
  }

  const ShowDataTable = () => {
    if (reportName === 'payments') {
      return null;
    } else if (isFinancialReport(reportName) && !hasFinancialAuth(auth, 'reports')) {
        return null;
    } else {
      const columns = columnDefinitions({entries: data.reports});

      return (
        <>
          <div style={Typography.pageTitle}>Previous {reportTitle} Reports</div>
          <DataTables.Simple
            data={data.reports}
            columns={columns}
            pageLength={10}
            htmlTable
            movableColumns
            multiSort
            styles={preflightTableStyles}
          />
        </>
      )
    }
  }

  const allowFiltering = allowFilterOptions(reportName)

  if (isFinancialReport(reportName)) {
    if (!hasFinancialAuth(auth, 'view') && !hasFinancialAuth(auth, 'reports')) {
      return <UnauthorizedAlert resource={reportTitle + ' reports'} />
    }
  }

  return (
    <div style={container}>
      {customAlert && <ReportProcessingAlert />}
      <div style={reportsFilterTitle}>{reportTitle} Report</div>
      <div style={filterContainer}>
      { allowFiltering &&
        <div style={filterHeader}>Report Filter Options</div> ||
        getRedirectLink(reportName)
      }
        <ReportFilter
          auth={auth}
          handlefilters={handleFilters}
          processing={processing}
          reportName={reportName}
          allowFiltering={allowFiltering}
          setPreview={setPreview}
        />
      </div>
      <ShowDataTable />
    </div>
  )
}

export default Report;
