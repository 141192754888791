import React, { useContext, useEffect, useState, useRef } from "react";
import BasicAlert from "../../../elements/BasicAlert";
import { useAlert } from "../../../hooks/useAlert";
import { gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import DataTable from "../../../elements/DataTable";
import AuthContext from "../../../contexts/AuthContext";
import { Helmet } from "react-helmet";
import { setAlertData } from "../../../utils/setAlertData";
import DataState from "../../DataState";
import * as StringHelpers from '../../../utils/stringHelpers';

const Transactions: () => React$Node = (props) => {
  const { txns } = props;
  const auth = useContext(AuthContext);
  const batchId = parseInt(useParams().id);

  const columns = [
    {
      title: "Owner",
    },
    {
      title: "Bank Account",
    },
    {
      title: "Amount",
      className: 'text-right'
    },
    {
      title: "Dealer",
    },
    {
      title: "VIN",
    },
    {
      title: "Actions",
    },
  ];

  const dataSet = txns.map((txn) => {
    if (auth.hasPolicy("Txn", "update")) {
      txn.actions = `<a href="/transactions/${
        txn.id
      }">View Transaction</a>`;
    }

    let owner = txn.bankAccount?.owner?.name || null;
    if (owner) owner = `<a href="/dealers/${txn.bankAccount.owner.id}">${owner}</a>`

    const bankAccount = txn.bankAccount?.accountName || "";
    const amount = StringHelpers.currency(txn.amount);

    let dealer = txn.dealer?.name || null;
    if (dealer) dealer = `<a href="/dealers/${txn.dealer.id}">${dealer}</a>`;

    let vin = txn.flooredCar?.vin || null;
    if (vin && dealer) vin = `<a href="/dealers/${txn.dealer.id}/cars/${txn.flooredCar.id}">
      ${vin}</a>`

    return [owner, bankAccount, amount, dealer, vin, txn.actions || ""];
  });

  return (
    <>
      <Helmet>
        <title>ACH Batch Transactions</title>
      </Helmet>
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={"Transactions"}
        uncreatable
        unhideable
      />
    </>
  );
};

export default Transactions;
