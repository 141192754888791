import { gql } from '@apollo/client';

const FLOORPLAN_REQUEST_FIELDS = `
  id
  status
  vin
  year
  make
  model
  series
  color
  source {
    id
    name
  }
  dealerLine {
    id
    dealer {
      id
      name
    }
  }
  mileage
  loanAmount
  purchaseType
  purchaseDate
  floorplanDate
  titlePresent
  issueMessages
  memo
  type
`;

export const FLOORPLAN_REQUESTS_WITH_COUNT = gql`
  query (
    $limit: Int,
    $offset: Int,
    $sort: String,
    $sortDirection: String
    $search: String
    $filterColumns: [String!]
    $filterNames: [JSON!]
  ) {
    floorplanRequestsWithCount(
      limit: $limit,
      offset: $offset,
      sort: $sort,
      sortDirection: $sortDirection
      search: $search
      filterColumns: $filterColumns
      filterNames: $filterNames
    ) {
      floorplanRequests {
        ${FLOORPLAN_REQUEST_FIELDS}
      }
      auctionNames
      dealerNames
      count
    }
  }
`;

export const REJECTED_FLOORPLAN_REQUESTS = gql`
  query {
    floorplanRequests(scopes: ["rejected"]) {
      ${FLOORPLAN_REQUEST_FIELDS}
    }
  }
`;

export const PENDING_FLOORPLAN_REQUESTS = gql`
  query {
    floorplanRequests(scopes: ["pending"]) {
      ${FLOORPLAN_REQUEST_FIELDS}
    }
  }
`;
