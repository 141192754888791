import React from 'react';
import { Typography } from '../../../styles';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import NewQuote from '../../Payments/New';
import { FLOORED_CAR } from '../../../queries/viewQueries';
import DataState from '../../DataState';
import { Helmet } from 'react-helmet';
import { DateHelpers } from '../../../utils';
import { currency } from '../../../utils/stringHelpers';

const Payments = () => {
  const id = parseInt(useParams().id);
  const carId = parseInt(useParams().carId);
  const { loading, error, data } = useQuery(FLOORED_CAR, {
    variables: { id: carId }
  })

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { flooredCar } = data;

  const hrStyle = {
    width: '100%',
  }

  let total = 0;

  const payableFees = data.flooredCar.dealer.payableFees.map((fee) => {
    total += parseFloat(fee.due)
    return <>{fee.name}: {currency(fee.due)} - {DateHelpers.setDate(fee.chargeOn)}<br /></>
  })

  return (
    <>
    	<Helmet>
        <title>{`Set Up Payment For ${flooredCar.dealer?.name}`}</title>
      </Helmet>
      <div style={Typography.pageTitle}>Set Up Payment</div>
        {(() =>
          {if (data.flooredCar.dealer.payableFees.length > 0) {
            return (
              <>
                <hr style={hrStyle} />
                  <div style={{paddingLeft: "20px"}}>
                    <h4>The following Dealer Fee(s) will be added to this payment:</h4>
                    <div>{payableFees}</div>
                    <h5>total: {currency(total)}</h5>
                  </div>
                <hr style={hrStyle} />
              </>
            )
          }
        })()}
      <NewQuote data={[flooredCar]} total={total} />
    </>
  );
};

export default Payments;
