import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { Col } from "react-bootstrap";
import * as BasicForm from "../../../../elements/forms";

const handleChange = (e, index, attributeIdx, values, setValues) => {
  const accountId = parseInt(e.currentTarget.value);

  values.termsAttributes[index].termLinesAttributes[0].termLineFeesAttributes[
    attributeIdx
  ].accountId = accountId;
  setValues(values);
};

const GlAccounts = ({ index, columnStyle, accounts }) => {
  const { setValues, values } = useFormikContext();

  return values.termsAttributes[
  index
  ].termLinesAttributes[0].termLineFeesAttributes.map(
    (attribute, attributeIdx) => {
      if (values.termsAttributes[index].termLinesAttributes[0]
          .termLineFeesAttributes[attributeIdx]._destroy) return null;

      const currentAccountId =
        values.termsAttributes[index].termLinesAttributes[0]
          .termLineFeesAttributes[attributeIdx].accountId;

    const defaultAccount = accounts.find((account) => account.gl === '4001')

    if (!values.termsAttributes[index].termLinesAttributes[0].termLineFeesAttributes[attributeIdx].accountId) {
      values.termsAttributes[index].termLinesAttributes[0].termLineFeesAttributes[attributeIdx].accountId = parseInt(defaultAccount.id)
    }

      return (
        <Col style={columnStyle.smallDiv} key={attributeIdx}>
          <BasicForm.Select
            name={`termsAttributes[${index}].termLinesAttributes[0].termLineFeesAttributes[${attributeIdx}].accountId`}
            value={
              values.termsAttributes[index].termLinesAttributes[0]
                .termLineFeesAttributes[attributeIdx].accountId || defaultAccount.id
            }
            style={columnStyle.dropdownStyle}
            onChange={(e) =>
              handleChange(e, index, attributeIdx, values, setValues)
            }
          >
            <option value="">GL</option>
            {accounts.map((account, accountIdx) => (
              <option key={accountIdx} value={account.id}>{`GL${account.gl} - ${
                account.name
              }`}</option>
            ))}
          </BasicForm.Select>
        </Col>
      );
    }
  );
};

export default GlAccounts;
