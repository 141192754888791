import React from 'react';
import AuthLink from 'components/src/components/AuthLink';
import { ColTitle, ReportLink } from '../index';

const Financial = () => {
  return (
    <>
      <ColTitle>Financial</ColTitle>

      <AuthLink
        to={'/reports/report/payment-summary'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Payment Summary Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/payments'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Payments Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/autopay-payments'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Autopay Payments Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/transactions'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Transactions Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/transactions-summary'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Transactions Summary Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/line-summary'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Line Summary Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/on-account'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        On Account Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/reserves'}
        resource="Txn"
        action="manage"
        component={ReportLink}
      >
        Reserves Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/writeoffs'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Writeoffs Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/lot-audit-fee'}
        resource="Audit"
        action="read"
        component={ReportLink}
      >
        Lot Audit Fee Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/dealer-fees'}
        resource="FlooredCar"
        action="read"
        component={ReportLink}
      >
        Dealer Fees
      </AuthLink>

      <AuthLink
        to={'/reports/report/other-fees-detail'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Other Fees Detail
      </AuthLink>
    </>
  );
};

export default Financial;
