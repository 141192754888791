export const formatDeltas = (deltas) => {
  return deltas.map((delta, index) => {
    const fieldNames = Object.keys(delta.data);
    const fieldValues = fieldNames.map((name) => {
      const currentData = delta.data[name];
      let oldValue, newValue;

      if (delta.isImport) {
        newValue = currentData;
        if ('object' === typeof newValue) {
          newValue = JSON.stringify(newValue);
        }
      } else {
        oldValue = currentData[0];
        newValue = currentData[1];
      };

      return {
        name,
        oldValue,
        newValue,
        createdAt: delta.createdAt,
        updatedAt: delta.updatedAt,
        action: delta.action,
        user: delta.user,
      };
    });

    return fieldValues;
  });
};
