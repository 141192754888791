import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { Form as FormikForm } from 'formik';
import { useFormikContext } from 'formik';
import { Spacing, Typography } from '../../styles';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { useQuery } from '@apollo/client';
import { USER_SELECTS } from '../../queries/selects';
import Select from 'react-select';
import { Colors } from '../../styles';
import * as yup from 'yup';
import { DateHelpers } from '../../utils';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import AuthContext from '../../contexts/AuthContext';

export const noteReportSchema = yup.object({
  endOn: yup.date().max(new Date(), 'End Date must be on or before today'),
  startOn: yup.date().max(
    yup.ref('endOn'),
    ({ max }) => `Start Date needs to be on or before end date`
  ),
});

const NotesFilterModal = (props) => {
  let { ownerid, ownertype, handleclose, show, resourcetype, handlefilters,
    processing, noteUserIds } = props;

  function formatResourceType(rType) {
    return rType.charAt(0).toUpperCase() + rType.slice(1);
  }

  const handleSubmit = (values) => {
    {handlefilters(values)}
  }

  const showModalBody = (values) => {
    if(processing) {
      return (
        <div style={activityDiv}>
          <div style={activityIndicator}>
            <Dots size={50} color={'#2b6448'} />
          </div>
        </div>
      )
    }else {
      return (
        <div style={Spacing.formWrapper}>
          <Formik
            initialValues={{
              userIds: [],
              ownerType: ownertype,
              ownerId: ownerid,
              startOn: '',
              endOn: '',
              collection: false,
              corporate: false,
            }}
            validationSchema={noteReportSchema}
            onSubmit={handleSubmit}
          >
            <FilterForm handleClose={handleclose} noteUserIds={noteUserIds}/>
          </Formik>
        </div>
      )
    }
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleclose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`Filter Your ${ formatResourceType(resourcetype) } Notes Report`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showModalBody()}
      </Modal.Body>
    </Modal>
  )
}

const FilterForm = (props) => {
  const { values, setFieldValue, errors, handleBlur } = useFormikContext();
  const { handleClose, noteUserIds } = props;
  const { data: selectData } = useQuery(USER_SELECTS);

  const auth = useContext(AuthContext);

  const handleChange = (selectedList) => {
    if (!selectedList) {
      setFieldValue('userIds', []);
      return
    }

    selectedList = selectedList.map((user) => {
      return { userId: parseInt(user.value) };
    })

    setFieldValue('userIds', selectedList);
  }

  let formattedUsers = [];
  if(selectData) {
    const filteredUsers = selectData.users.filter(user => noteUserIds.includes(user.id));
    formattedUsers = filteredUsers.map((user) => {
      return { value: user.id, label: `${user.firstName} ${user.lastName}`};
    })
  }

  const today = DateHelpers.setISODate(new Date());

  return (
    <FormikForm>
      <BasicForm.StyledLabel>Users</BasicForm.StyledLabel>
      {selectData && (
        <Select
          options={formattedUsers}
          isMulti={true}
          onChange={handleChange}
          styles={customStyles}
        />
      )}
      <BasicForm.TextInput
        name="startOn"
        type="date"
        label="Start Date for Report"
        value={values.startOn}
      />
      <BasicForm.TextInput
        name="endOn"
        type="date"
        label="End Date for Report"
        value={values.endOn}
      />
      <BasicForm.Checkbox
        name="collection"
        type="checkbox"
        label="Collection?"
        id="collection-check"
      />
      {auth.hasPolicy('Note', 'View Corporate Only Notes') && (
        <BasicForm.Checkbox
          name="corporate"
          type="checkbox"
          label="Corporate?"
          id="corporate-check"
        />
      )}
      <div style={Spacing.buttonRow}>
        <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose}/>
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  )
}

const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })

}

const activityDiv = {
  display: 'flex',
  justifyContent: 'center',
  height: '100px',
  flexDirection: 'column'
}

const activityIndicator = {
  display: 'flex',
  justifyContent: 'center'
}

export default NotesFilterModal;
