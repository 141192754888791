import React, { useState } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { fileSchema, Form } from './Form';
import { CURRENT_USER } from '../../../queries/currentUser';
import { DEALER } from '../Dealer';
import DataState from '../../DataState';
import Loading from '../../../elements/Loading';

const CREATE_FILE_UPLOAD = gql`
  mutation CreateFileUpload(
    $name: String!,
    $type: String!,
    $ownerType: String,
    $ownerId: Int,
    $createdById: Int!,
    $file: Upload,
    $mainLotPhoto: Boolean,
  ) {
    createFileUpload(
      name: $name
      type: $type
      ownerType: $ownerType
      ownerId: $ownerId
      createdById: $createdById
      file: $file
      mainLotPhoto: $mainLotPhoto
    ) {
      fileUpload {
        id
        ownerType
        ownerId
        name
        type
        fileUrl
      }
      errors {
        message
        path
      }
    }
  }
`;

const New = () => {
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const ownerId = parseInt(useParams().id);
  const ownerType = 'Dealer';

  const [createFileUpload, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_FILE_UPLOAD,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: DEALER, variables: { id: ownerId } }],
      onCompleted(values) {
        setFormSubmitting(false);
        history.push({
          pathname: `/dealers/${ownerId}`,
          state: { alert: true, componentType: 'lot image', type: 'created'}
        })
      }
    }
  );

  const { loading, error, data } = useQuery(CURRENT_USER)

  if (loading || mutationLoading) return <DataState.Loading />;
  if (error || mutationError) return <DataState.Error error={error || mutationError} />;

  const { currentUser } = data;

  const createdById = parseInt(currentUser.id);

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createFileUpload({ variables: values })
  };

  const NewFileForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Upload A New Dealer Image</h1>
        </div>
        <Formik
          initialValues={{
            ownerId,
            ownerType,
            createdById,
            name: '',
            type: 'AWS Storage',
            file: null,
            mainLotPhoto: true,
          }}
          validationSchema={fileSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewFileForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default New;
