import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as DateHelper from '../../utils/dateHelpers'
import * as StringHelper from '../../utils/stringHelpers';
import { Link } from 'react-router-dom';
import { CURRENT_USER } from '../../queries/commonQueries';
import NewPrincipalAdjustment from './Principals/Modal';
import {
  carImageStyle,
  noCarImageStyle,
  functionRowStyle,
  greenRowStyle,
  dataLabelStyle,
  dataValueStyle
} from './Style';

export const labels = [
  'VIN:',
  'Loan Date:',
  'Bill Of Sale Date:',
  'Days on Floor Plan:',
  'Current Term:',
  'Days on Current Term:',
  'Payoff Required On:',
  'Source:',
  'Funding Method:',
  'Amount of Loan:',
  'Current Principal Balance:',
  'adjust principal',
  'Next Payment Date:',
  'Estimated Payment Due:',
  'Most Recent Audit:',
  'Audit Status:',
  'Rate Program:',
  'Fee Program:',
  'Term Program:',
  'Automated Fees:'
]

export const setValues = (car) => {
  const { vin, currentFees, currentInterest, currentCollateralCoverage } = car;
  const { loading, error, data } = useQuery(CURRENT_USER);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);

  $(document).on('click', '#principal-modal', function (event) {
    handleShow()
  })

  const loanDate = DateHelper.setDate(car.loanDate);
  const bosDate = DateHelper.setDate(car.bosDate);
  const floorPlanDays = car.termData.totalDays || '';
  const term = car.termData.term || '';
  const daysOnCurrentTerm = car.termData.day || '';
  const payoffOn = DateHelper.setDate(car.maxPayoffOn) || '';
  const sourceType = formatSourceType(car.sourceType);
  const rateProgram =
    <a href={`/system/rate-programs/${car.rateProgram.id}`}>
      ${car.rateProgram.name}
    </a> || '';
  const feeProgram =
    <a href={`/system/fee-programs/${car.feeProgram.id}`}>
      ${car.feeProgram.name}
    </a> || '';
  const termProgram =
    <a href={`/system/term-programs/${car.termProgram.id}`}>
      ${car.termProgram.name}
    </a> || '';
  const autoFees = car.automatedFees.map(fee => fee.name).join(", ");
  const adjustPrincipal =
    <a id="principal-modal">
      Adjust Principal
    </a>;

  const source = (car.sourceName)
    ? <a
      href={`/${sourceType}/${car.sourceId}`}
    >
      {car.sourceName}
    </a>
    : 'Advance To Account';
  const fundingMethod = car.advanceTo || '';
  const totalDue = (
    parseFloat(currentFees) + parseFloat(currentInterest) +
    parseFloat(car.currentPrincipal) + parseFloat(currentCollateralCoverage)
  )
  const loanAmount = StringHelper.currency(car.loanAmount) || '';
  const currentPrincipal = StringHelper.currency(car.currentPrincipal) || '';
  const nextPaymentDate = DateHelper.setDate(car.currentDueOn) || '';

  return [
    vin,
    loanDate,
    bosDate,
    floorPlanDays,
    term,
    daysOnCurrentTerm,
    payoffOn,
    source,
    fundingMethod,
    loanAmount,
    currentPrincipal,
    adjustPrincipal,
    nextPaymentDate,
    StringHelper.currency(totalDue),
    '',
    '',
    rateProgram,
    feeProgram,
    termProgram,
    autoFees
  ]
    { showModal && <NewPrincipalAdjustment
        showModal={showModal}
        setShowModal={setShowModal}
      />
    }
}

const setRowStyle = (index) => {
  return (index % 2 === 0) ? rowStyle : greenRowStyle;
}

export const InfoTable = ({ labels, values }) => {
  if (!labels || !values) return null;

  const row = labels.map((label, index) => {
    return (
      <tr style={setRowStyle(index)} key={index}>
        <td style={dataLabelStyle}>{`${label}`}</td>
        <td style={dataValueStyle}>{values[index]}</td>
      </tr>
    )
  })

  return (
    <Table responsive borderless>
      <tbody>
        {row}
      </tbody>
    </Table>
  )
}

export const Image = ({ src }) => {
  if (!src) return <h3 style={noCarImageStyle}>
    No Image Available
  </h3>

  return <img
    style={carImageStyle}
    src='car.jpg'
  />
}

export const formatSourceType = (sourceType) => {
  if (!sourceType) return 'Advance To Account'

  sourceType = sourceType.match(/[A-Z][a-z]+/g)
  sourceType = sourceType.map((word) => word.toLowerCase())
  sourceType[sourceType.length - 1] += 's'
  return sourceType.join('-')
}
