import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { Helmet } from 'react-helmet';
import UpdateMobile from '../components/Profile/UpdateMobile';

const Routes = () => {
  return (
    <>
      <Helmet titleTemplate="XL Funding | %s" defaultTitle="XL Funding">
        <meta charSet="utf-8" />
      </Helmet>
      <Switch>
        <Route path="/" title="XL Funding - Update Mobile" component={UpdateMobile} />
      </Switch>
    </>
  );
}

export default Routes;
