import React, { useEffect, useRef, useState } from 'react';
import * as BasicForm from '../../../../elements/forms';
import DataState from '../../../DataState';
import { useFormikContext } from 'formik';
import { ErrorMessage } from '../../../../elements/ErrorMessage';
import * as Options from './Options';
import * as yup from 'yup';
import { currency } from '../../../../utils/stringHelpers';

export const Form = ({ amount, dealerName, reserveBalance, setDisabled, quoteAmount }) => {
  const { setFieldValue, errors, values, values: {advanceType} } = useFormikContext();
  const advanceTypeRef = useRef('source');

  // for some reason initialValues doesn't recognize an initial advanceType value
  // so this is needed
  useEffect(() => {
    setFieldValue('advanceType', 'source')
  }, []);

  (Object.keys(errors).length > 0)
    ? setDisabled(true)
    : setDisabled(false)

  useEffect(() => {
      if (advanceTypeRef.current !== values.advanceType && values.advanceType !== '') {
        setFieldValue('txnType', '');
        setFieldValue('name', '');
        setFieldValue('reference', '');
        setFieldValue('bankAccountId', null);
        setFieldValue('accountId', null);

        advanceTypeRef.current = values.advanceType
      }

    }, [advanceType])

  let reserveOption;
  if (parseFloat(reserveBalance) < quoteAmount) {
    reserveOption = `Reserve Balance is too low for this transaction. Payment Amount: ${currency(quoteAmount)} | Reserve Balance: ${currency(reserveBalance)}`
  } else if (parseFloat(reserveBalance) < amount) {
    reserveOption = `Reserve Balance is too low for this transaction. Payment Amount: ${currency(amount)} | Reserve Balance: ${currency(reserveBalance)}`
  } else {
   reserveOption = <BasicForm.Radio name="advanceType" type="radio" label={`Dealer Reserve ${currency(reserveBalance)}`} value="Dealer Reserve" />
  }

  return <div className="container">
  <div style={Object.assign({}, Options.divStyle, {justifyContent: 'space-between'} )}>
      Payment Type:
      <BasicForm.Radio
        name="advanceType"
        type="radio"
        label="Initiate ACH From Dealer"
        value="source"
        checked={values.advanceType === 'source'}
        />
      <BasicForm.Radio
        name="advanceType"
        type="radio"
        label="Manual Payment Method"
        value="external"
        />
      <BasicForm.Radio
        name="advanceType"
        type="radio"
        label="Payment From Account"
        value="On Account"
        />
        {reserveOption}
      <BasicForm.InlineStyledErrorMessage>
        <ErrorMessage name='advanceType'/>
      </BasicForm.InlineStyledErrorMessage>
    </div>
    { advanceType && advanceType === 'source' &&
      <Options.AdvanceTypeOptions />
    }
    { advanceType && advanceType === 'external' &&
      <Options.ExternalTypeOptions quoteAmount={quoteAmount} setDisabled={setDisabled} />
    }
    { advanceType && advanceType === 'On Account' &&
      `Note: the source for Payment from Account is the Dealer: ${dealerName}`
    }
    </div>
}

