import React, { useState, Fragment } from "react";
import { useParams } from 'react-router-dom';
import * as BasicForm from "../../elements/forms";
import { useFormikContext } from "formik";
import { getQuoteData, PrincipalError } from "./Functions";
import * as StringHelper from "../../utils/stringHelpers";
import * as DateHelper from "../../utils/dateHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FeeDetails, feeButtonStyle } from "./Fees";
import { formNumberStyle } from "./Form";
import { getSearchValues, searchLookup } from "./Search";
import StaticData from './StaticData';
import DynamicInputs from './DynamicInputs';
import { gql, useQuery } from '@apollo/client';
import DataState from '../DataState';

const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      payableFees {
        id
        due
      }
    }
  }
`;

const disabledArray = [];
const loadingArray = [];

const inputStyle = {
  display: "inline-flex",
};

const handleEnableItems = (disabledItems, setDisabledItems) => {
  if (disabledItems) setDisabledItems(false);
};

const handleRoleCheck = (roleData, disabledItems, setDisabledItems, auth) => {
  const role = roleData[0];
  const action = roleData[1];

  auth.hasPolicy(role, action)
    ? handleEnableItems(disabledItems, setDisabledItems)
    : setDisabledItems(true);
};

const adjustTotalDays = (paymentDate, totalDays) => {
  let filteredPaymentDate = paymentDate.replaceAll('-', '/');
  filteredPaymentDate = new Date(filteredPaymentDate);
  let today = new Date();
  today = today.setHours(0, 0, 0, 0);
  today = new Date(today);

  const differenceInTime = filteredPaymentDate.getTime() - today.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return totalDays + Math.round(differenceInDays);
}

export const FormBody = ({
  auth,
  data,
  disabled,
  setPaymentTotal,
  setPrincipalTotal,
  setReservesTotal,
  setInterestTotal,
  setInsuranceTotal,
  setFeesTotal,
  setDisabled,
  paymentTotal,
  reordered,
  setReordered,
  dealerFeesTotal,
  principalTotal,
  reservesTotal,
  interestTotal,
  insuranceTotal,
  feesTotal,
  dealerFees,
  setDealerFeeTotal
}) => {
  const {
    values,
    setValues,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormikContext();
  const id = useParams().id;
  const [showFees, setShowFees] = useState(false);
  const [disabledItems, setDisabledItems] = useState(false);

  let total = 0;
  let totalPrincipal = 0;
  let totalFeeAmount = 0;
  let totalInterest = 0;
  let totalInsurance = 0;
  let totalReserves = 0;

  if (dealerFees === false) {
    total += parseFloat(dealerFeesTotal)
    setPaymentTotal(dealerFeesTotal);
  } else {
    setDealerFeeTotal(0)
  }

  data = data.map((car, index) => {
    let feeDetails;

    let quoteData = getQuoteData(car, index, reordered, dealerFees);

    let loading = (
      <tr key={index} style={{ marginBottom: "0px" }}>
        <td>
          loading...
        </td>
      </tr>
    );

    let tableLoading = (
      <table>
        <tbody>
          <tr key={index} style={{ marginBottom: "0px" }}>
            <td>
              loading...
            </td>
          </tr>
        </tbody>
      </table>
    )

    if (!values.quoteCarsAttributes || !values.quoteCarsAttributes[index])
      return loading;

    if (!quoteData) loadingArray[index] = true;

    if (quoteData) {
      quoteData = quoteData.quoteAmounts;
      loadingArray[index] = false;
    }

    const totalDue =
      StringHelper.currency(values.quoteCarsAttributes[index]?.totalDue) ||
      loading;

    const reserves = values.quoteCarsAttributes[index].reserves;

    let calculatedTotalDue = null;
    let principal = 0;
    if (
      values.quoteCarsAttributes &&
      values.quoteCarsAttributes[index] &&
      quoteData
    ) {
      principal =
        values.quoteCarsAttributes[index]["principal"] === ""
          ? 0
          : parseFloat(values.quoteCarsAttributes[index]["principal"]);

      if (quoteData.feeDetails) {
        const currentFeeIds = Object.keys(
          values.quoteCarsAttributes[index]["feeDetails"]
        );

        if (quoteData.feeDetails.length === 0) {
          const feeDue = 0;
          currentFeeIds.forEach((fee, feeIndex) => {
            values.quoteCarsAttributes[index]["feeDetails"][feeIndex] = {
              [fee]: { amount: parseFloat(feeDue) },
            };
          });
        } else {
          quoteData.feeDetails.forEach((fee, feeIndex) => {
            const feeId = fee.id;
            let feeDue = parseFloat(fee.due);

            values.quoteCarsAttributes[index]["feeDetails"][feeIndex] = {
              [feeId]: { amount: feeDue },
            };
          });
        }
      }
    }

    // Fees
    let totalFees = 0;
    if (quoteData) {
      let currentFees = values.quoteCarsAttributes[index]["feeDetails"];
      currentFees = currentFees.filter((fee) => fee !== undefined);
      currentFees.map((fee) => {
        const amount = parseFloat(Object.values(fee)[0].amount);

        totalFees += amount;
      });

      feeDetails = quoteData.feeDetails;

      const interest = parseFloat(values.quoteCarsAttributes[index].interest || 0)
      const insurance = parseFloat(values.quoteCarsAttributes[index].insurance || 0)
      const reservesAmount = parseFloat(reserves)
      const totalFeesAmount = parseFloat(totalFees)

      // Total Due
      calculatedTotalDue =
        principal +
        totalFeesAmount +
        interest +
        insurance +
        reservesAmount;

      if (values.quoteCarsAttributes[index]["paymentType"] !== "") {
        total += calculatedTotalDue;
        totalPrincipal += principal;
        totalFeeAmount += totalFeesAmount;
        totalInterest += interest;
        totalInsurance += insurance;
        totalReserves += reservesAmount;
      }

      /**
       * TODO: This should be an object, but for some reason it triggers
       * render hell to try to set state on an object.
       */
      setPaymentTotal(total);
      setPrincipalTotal(totalPrincipal);
      setFeesTotal(totalFeeAmount)
      setInterestTotal(totalInterest)
      setInsuranceTotal(totalInsurance)
      setReservesTotal(totalReserves)

      calculatedTotalDue = StringHelper.currency(calculatedTotalDue);
    }

    // Search Functionality
    let searchValues = getSearchValues(car);
    let valueInSearch = searchLookup(searchValues, values);
    if (valueInSearch === false) return null;

    return (
      <Fragment key={index}>
        <tr key={index}>
          <td style={formNumberStyle}>{car.unitNumber}</td>
          <td>
            {car.vin}
            {
              car.scheduledAchId &&
                <div style={{color: 'red'}}>ACH PAYMENT SCHEDULED</div>
            }
          </td>
          <td>{car.year}</td>
          <td style={{ flexWrap: "wrap" }}>{car.make}</td>
          <td style={{ flexWrap: "wrap" }}>{car.model}</td>
          <td>{DateHelper.setDate(car.loanDate)}</td>
          <td>{DateHelper.setDate(car.currentDueOn)}</td>
          <td style={formNumberStyle}>{car.termData?.totalDays
            ? adjustTotalDays(values.paymentDate, car.termData.totalDays)
            : null}</td>
          <td style={inputStyle}>
            <BasicForm.TextInput
              name={`quoteCarsAttributes[${index}][principal]`}
              type="number"
              touched={touched}
              onBlur={handleBlur}
              disabled={disabledItems}
              onFocus={(e) => e.target.select()}
            />
          </td>
          <td style={{ width: "110px" }}>
            {(quoteData && `${StringHelper.currency(totalFees)}`) || tableLoading}
            {quoteData &&
              showFees &&
              values.quoteCarsAttributes[index]["paymentType"] !==
              "principal" && (
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  className="fee-details-button"
                  style={feeButtonStyle}
                  onClick={() => setShowFees(!showFees)}
                />
              )}
            {quoteData &&
              !showFees &&
              values.quoteCarsAttributes[index]["paymentType"] !==
              "principal" && (
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={feeButtonStyle}
                  className="fee-details-button"
                  onClick={() => setShowFees(!showFees)}
                />
              )}
            {showFees && (
              <FeeDetails
                feeDetails={feeDetails}
                index={index}
                disabledItems={disabledItems}
              />
            )}
          </td>
          {
            values.quoteCarsAttributes[index].paymentType !== 'partial' ? (
            <StaticData quoteData={quoteData} tableLoading={tableLoading} />
            ) : (
            <Fragment>
              <DynamicInputs
                touched={touched}
                index={index}
                handleBlur={handleBlur}
              />
              <StaticData quoteData={quoteData} tableLoading={tableLoading} types={'reserves'}/>
            </Fragment>
            )
          }
          <td style={Object.assign({}, formNumberStyle, { minWidth: "120px" })}>
            {calculatedTotalDue || tableLoading}
          </td>
          <td>
            <BasicForm.Radio
              name={`quoteCarsAttributes[${index}][paymentType]`}
              label="No Payment"
              value=""
              checked={values.quoteCarsAttributes[index]["paymentType"] === ""}
              onClick={() => handleEnableItems(disabledItems, setDisabledItems)}
            />
            { car.canCurtail &&
              <>
                <BasicForm.Radio
                  name={`quoteCarsAttributes[${index}][paymentType]`}
                  label="Principal Only"
                  value="principal"
                  checked={
                    values.quoteCarsAttributes[index]["paymentType"] === "principal"
                  }
                  onClick={() => handleEnableItems(disabledItems, setDisabledItems)}
                />
                <BasicForm.Radio
                  name={`quoteCarsAttributes[${index}][paymentType]`}
                  label="Curtailment"
                  value="curtailment"
                  checked={
                    values.quoteCarsAttributes[index]["paymentType"] === "curtailment"
                  }
                  onClick={() =>
                    handleRoleCheck(
                      ["Curtailment", "change"],
                      disabledItems,
                      setDisabledItems,
                      auth
                    )
                  }
                />
              </>
            }
            <BasicForm.Radio
              name={`quoteCarsAttributes[${index}][paymentType]`}
              label="Payoff"
              value="payoff"
              checked={
                values.quoteCarsAttributes[index]["paymentType"] === "payoff"
              }
              onClick={() => setDisabledItems(true)}
            />
            { auth.hasPolicy('Quote', 'partial_payment') &&
              <BasicForm.Radio
                name={`quoteCarsAttributes[${index}][paymentType]`}
                label="Partial Payment"
                value="partial"
                checked={
                  values.quoteCarsAttributes[index]["paymentType"] === "partial"
                }
                onClick={() => setDisabledItems(false)}
              />
            }
          </td>
        </tr>
        <PrincipalError
          values={values}
          index={index}
          disabledArray={disabledArray}
        />
      </Fragment>
    );
  });

  if (disabledArray.includes(true) ||
    loadingArray.includes(true) ||
    paymentTotal  === 0) {
    setDisabled(true)
  } else setDisabled(false);

    setReordered(false);
    return data;
};
