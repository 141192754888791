import React, { useEffect, useState, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import ESResultOption from './ESResultOption';
import secrets from '../secrets';
import { AUTH_TOKEN } from '../constants';
import './elasticsearchbox.css'

const esOptionContainer = {
  display: 'flex',
  flex: 1,
  borderBottom: '1px solid #ccc',
  marginTop: '0px',
}

const asTypeahead = {
  width: '15em',
}

const ElasticsearchBox = (props) => {

  let client;
  let marketIds = props;

  if (secrets.elasticSearch.username) {
    const elasticsearch = require('elasticsearch');
    client = new elasticsearch.Client({
      host: secrets.elasticSearch.host,
      httpAuth: `${secrets.elasticSearch.username}:${secrets.elasticSearch.password}`
    });
  } else {
    const aeclient = require('aws-elasticsearch-client');
    const options = {
      host: secrets.elasticSearch.host,
      region: secrets.elasticSearch.region,
      credentials: new AWS.Credentials({
        accessKeyId: secrets.elasticSearch.accessKey,
        secretAccessKey: secrets.elasticSearch.secretKey
      })
    }
    client = aeclient.create(options);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const history = useHistory();
  const esTextInput = useRef(null);
  const hideMenu = () => {
    esTextInput.current.hideMenu();
    esTextInput.current.clear();
  }

  const getMarkets = () => {
    let markets = [];
    if (props.marketIds.length > 0) {
      props.marketIds.forEach((id) => {
        markets.push({match: { market_id: id }})
      });
    }
    return markets.length ? markets : null;
  }

  const handleSearch = (query) => {

    setIsLoading(true);
    client.search({
      index: "_all",
      body: {
        query: {
          function_score: {
            query: {
              bool: {
                should: [
                  {
                    multi_match: {
                      operator: "and",
                      type: "phrase_prefix",
                      query: `${query}`,
                      // fuzziness: 1,
                      fields: [
                        "name^10", "legal_name^10", "full_name", "full_name.edge",
                        "make", "car_model", "year", "color", "vin^9",
                        "last_six^8", "email", "account_number^10",
                        "account_number.edge^10"
                      ]
                    },
                  }
                ],
                must: {
                  bool: {
                    should: getMarkets()
                  }
                },
                must_not: []
              }
            },
            functions: [
              {
                filter: { term: { model: 'dealers' } },
                weight: 40
              },
              {
                filter: { term: { model: 'floored_cars' } },
                weight: 20
              },
              {
                filter: { term: { model: 'dealer_people' } },
                weight: 10
              },
              {
                filter: { term: { model: 'users' } },
                weight: 8
              },
              {
                filter: { term: { model: 'approved_sources' } },
                weight: 5
              },
              {
                filter: { term: { model: 'auctions' } },
                weight: 7
              }
            ],
            max_boost: 80,
            score_mode: "max",
            boost_mode: "multiply",
            min_score: 2
          }
        }
      }
    })
    .then(function(results) {
      setMenuOptions(results.hits.hits);
      setIsLoading(false);
    });
  }

  const selected = (option) => {
    if(option && option[0] !== undefined) {
      let path = option[0]._index === 'floored_cars' ?
        `/dealers/${option[0]._source.dealer_id}/cars/${option[0]._id}` :
        `/${option[0]._index}/${option[0]._id}`
      history.push(path);
    }
    esTextInput.current.clear();
  }

  return (
    <AsyncTypeahead
      id="async-search"
      isLoading={isLoading}
      labelKey={option =>
        `${option._index === 'dealer_people' ?
          option._source.full_name :
          option._source.name}`
      }
      minLength={3}
      onSearch={handleSearch}
      onInputChange={handleSearch}
      options={menuOptions}
      placeholder="Search:"
      onChange={option => selected(option)}
      renderMenu={(options, menuProps) => {
        const results = menuOptions.map((option, position) => {
          return (
            <div style={esOptionContainer} className='es-option-container'>
              <ESResultOption
                option={option}
                position={position}
                key={`${option._index}${option._id}`}
                hideMenu={hideMenu}
                marketIds={marketIds}
              />
            </div>

          )
        })

        return (
          <Menu {...menuProps}>{results}</Menu>
        )
      }}
      style={asTypeahead}
      ref={esTextInput}
    />
  )
}

export default ElasticsearchBox;
