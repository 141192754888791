import React from 'react';
import AuthLink from 'components/src/components/AuthLink';
import { ColTitle, ReportLink } from '../index';

const Dealer = () => {
  return (
    <>
      <ColTitle>Dealer</ColTitle>

      <AuthLink
        to="/reports/report/annual-review"
        resource="Dealer"
        action="read"
        component={ReportLink}
      >
        Annual Reviews Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/dealer-addresses'}
        resource="Dealer"
        action="read"
        component={ReportLink}
      >
        Dealer Addresses
      </AuthLink>

      <AuthLink
        to={'/reports/dealer-payments'}
        resource="Quote"
        action="read"
        component={ReportLink}
      >
        Payment Report
      </AuthLink>

      <AuthLink
        to={'/portal-report/single-car'}
        resource="Quote"
        action="read"
        component={ReportLink}
      >
        Payment Report - Single VIN
      </AuthLink>

      <AuthLink
        to={'/reports/statement-of-account'}
        resource="Dealer"
        action="read"
        component={ReportLink}
      >
        Statement Of Account Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/temp-programs'}
        resource="TempProgram"
        action="read"
        component={ReportLink}
      >
        Temp Programs Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/collateral-coverage-loans'}
        resource="Txn"
        action="read"
        component={ReportLink}
      >
        Collateral Coverage Loans Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/collateral-coverage-dealers'}
        resource="Dealer"
        action="read"
        component={ReportLink}
      >
        Collateral Coverage Dealers Report
      </AuthLink>
    </>
  );
};

export default Dealer;
