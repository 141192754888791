import React from 'react';
import Form from "react-bootstrap/Form";

export const columnDefinitions = props => {
  const { checkedEntries, setCheckedEntries, forceUpdate } = props;

  const definitions = [
    {
      key: 'select',
      label: 'Select',
      sort: 'prevent',
      content: ({ entry }) => {
        const toggleCars = () => {
          const checked = checkedEntries.includes(entry.id)

          if (checked === false) {
            checkedEntries.unshift(entry.id)
          } else if (checked === true) {
            var index = checkedEntries.indexOf(entry.id)

            if (index >= 0) {
              checkedEntries.splice(index, 1)
            }
          }

          setCheckedEntries(checkedEntries)
        }

        return (
          <Form.Check
            checked={checkedEntries.includes(entry.id)}
            onChange={() => toggleCars()}
          />
        )
      }
    },
    {
      key: 'market',
      label: 'Market',
      value: ({ entry }) => entry.market.name,
    },
    {
      key: 'dealer',
      label: 'Dealer',
      sort: 'asc',
      content: ({ entry }) =>
        <a
          href={`dealers/${entry?.dealer?.id}`}
        >
          {entry?.dealer?.name}
        </a>
    },
    {
      key: 'unitNumber',
      label: 'Unit Number'
    },
    {
      key: 'vin',
      label: 'VIN',
      content: ({ entry }) =>
        <a
          href={`/dealers/${entry.dealer.id}/cars/${entry.id}`}
        >
          {entry.vin}
        </a>
    },
    {
      key: 'year',
      label: 'Year'
    },
    {
      key: 'make',
      label: 'Make'
    },
    {
      key: 'model',
      label: 'Model'
    },
    {
      key: 'titleStatus',
      label: 'Title Status'
    },
    {
      key: 'dealerStatus',
      label: 'Dealer Status',
      content: ({ entry }) => entry?.dealer?.dealerStatus?.name
    }
  ]
  return definitions
}
