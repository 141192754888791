import React, { useState } from 'react';
import { xlGreen } from 'components/src/styles/colors';

const headerStyle = {
  backgroundColor: xlGreen,
  color: 'white',
  fontSize: '1.5em',
  fontWeight: 'bold',
  padding: '6px',
}

const containerStyle = {
  marginTop: '20px'
}

const Group = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={containerStyle}>
      <h2 style={headerStyle} onClick={() => setOpen(!open)}>
        {title}
        {open ? ' -' : ' +'}
      </h2>

      {open && children}
    </div>
  );
};

export default Group;
