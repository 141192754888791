import React, { useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { approvedSourceSchema, Form } from './Form';
import DataState from '../DataState';
import { titleCase } from '../../utils/stringHelpers';
import Loading from '../../elements/Loading';
import {
  APPROVED_SOURCE, UPDATE_APPROVED_SOURCE, CREATE_FILE_UPLOAD
} from './queries';
import { nullifyBlanks } from 'components/src/utils/nullifyBlanks';

const EditApprovedSource = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  const [updateApprovedSource] = useMutation(UPDATE_APPROVED_SOURCE,
    {
      onCompleted(values) {
        setFormSubmitting(false);
        setRedirect(true);
      }
    }
  );

  let submittedValues = useRef();

  const [createFileUpload, { loading: fileLoading }] = useMutation(
    CREATE_FILE_UPLOAD, {
      onCompleted(values) {
        const id = parseInt(values.createFileUpload.fileUpload.id)
        submittedValues.current.agreementUploadId = id
        updateApprovedSource({ variables: submittedValues.current })
      }
    }
  )

  const handleSubmit = (values) => {
    values.state = values.state.toUpperCase();
    nullifyBlanks(values, ['agreementDate']);

    setFormSubmitting(true)

    if (values.file !== undefined) {
      submittedValues.current = values;
      createFileUpload({
        variables: { file: values.file, ownerId: parseInt(id) }
      });
    } else {
      updateApprovedSource({ variables: values })
    }
  };

  const { loading, error, data } = useQuery(APPROVED_SOURCE, { variables: { id } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { approvedSource } = data;

  approvedSource.type = approvedSource.type ? titleCase(approvedSource.type) : '';

  const EditForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/approved-sources`,
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>{`Edit ${approvedSource.name}`}</h1>
          </div>
          <Formik
            initialValues={{
              id: approvedSource.id || '',
              name: approvedSource.name || '',
              city: approvedSource.city || '',
              state: approvedSource.state || '',
              type: approvedSource.type || '',
              street: approvedSource.street || '',
              zip: approvedSource.zip?.value || '',
              phone: approvedSource.phone?.formatted || '',
              titleFundingStatusIds: approvedSource.titleFundingStatusIds || [],
              agreementOnFile: approvedSource.agreementOnFile,
              agreementDate: approvedSource.agreementDate || '',
              pmsiOption: approvedSource.pmsiOption || false,
              pmsiAddress: approvedSource.pmsiAddress || '',
              pmsiCity: approvedSource.pmsiCity || '',
              pmsiState: approvedSource.pmsiState || '',
              pmsiZip: approvedSource.pmsiZip || '',
              pmsiCompanyName: approvedSource.pmsiCompanyName || '',
              pmsiPhone: approvedSource.pmsiPhone?.formatted || ''
            }}
            validationSchema={approvedSourceSchema}
            onSubmit={handleSubmit}
          >
            <Form approvedSource={approvedSource} />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default EditApprovedSource;
