import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../../../elements/forms';
import { SourceType } from './SourceType';
import { SourceId } from './SourceId';

const OnAccount = () => {
  const { values, setFieldValue } = useFormikContext();

  return <div>
    <SourceType />
    <SourceId />
    </div>
  }

export default OnAccount;
