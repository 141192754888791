import React, { useEffect, useRef, useState, Fragment } from 'react';
import * as BasicForm from '../../../elements/forms';
import DataState from '../../DataState';
import { useFormikContext } from 'formik';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import * as Options from './Options';
import * as yup from 'yup';
import { currency } from '../../../utils/stringHelpers';

export const schema = yup.object({
  advanceType: yup
    .string()
    .required('Required'),
  bankAccountId: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'source')
          ? schema.required('Required')
          : schema
        ),
  accountId: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'external')
          ? schema.nullable().required('Required')
          : schema
        ),
  txnType: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'external')
          ? schema.required('Required')
          : schema
      ),
  total: yup
    .number()
    .nullable()
    .when('advanceType',
      (advanceType, schema) =>
      (advanceType === 'external')
      ? schema.required('Required')
      : schema
    ),
})

export const Form = ({ amount, dealerName, reserveBalance, setDisabled, quoteAmount, onAccountBalance }) => {
  const { setFieldValue, errors, values, values: {advanceType} } = useFormikContext();
  const advanceTypeRef = useRef('source');

  // for some reason initialValues doesn't recognize an initial advanceType value
  // so this is needed
  useEffect(() => {
    setFieldValue('advanceType', 'source')
  }, []);

  (Object.keys(errors).length > 0)
    ? setDisabled(true)
    : setDisabled(false)

  if (values.advanceType === 'On Account' &&
    (parseFloat(onAccountBalance) < parseFloat(quoteAmount))) {
    setDisabled(true)
  }

  useEffect(() => {
      if (advanceTypeRef.current !== values.advanceType && values.advanceType !== '') {
        setFieldValue('txnType', '');
        setFieldValue('name', '');
        setFieldValue('reference', '');
        setFieldValue('bankAccountId', '');
        setFieldValue('accountId', '');

        advanceTypeRef.current = values.advanceType
      }

    }, [advanceType])

  let reserveOption;
  if (parseFloat(reserveBalance) < quoteAmount) {
    reserveOption = `Reserve Balance is too low for this transaction. Payment Amount: ${currency(quoteAmount)} | Reserve Balance: ${currency(reserveBalance)}`
  } else if (parseFloat(reserveBalance) < amount) {
    reserveOption = `Reserve Balance is too low for this transaction. Payment Amount: ${currency(amount)} | Reserve Balance: ${currency(reserveBalance)}`
  } else {
   reserveOption = <BasicForm.Radio name="advanceType" type="radio" label={`Dealer Reserve ${currency(reserveBalance)}`} value="Dealer Reserve" />
  }

  let onAccountOption;

  if (parseFloat(onAccountBalance) < quoteAmount) {
    onAccountOption = `On Account Balance is too low for this transaction.
    Payment Amount: ${currency(quoteAmount)} | On Account Balance:
    ${currency(onAccountBalance)}`
 } else if (parseFloat(onAccountBalance) < amount) {
      onAccountOption = `On Account Balance is too low for this transaction.
      Payment Amount: ${currency(amount)} | On Account Balance:
      ${currency(onAccountBalance)}`
    }

  return <div className="container">
   <br />
   {reserveOption}
   <br />
   { onAccountOption && <Fragment>
      <br />
      { onAccountOption }
    </Fragment>
   }
   <br />
   <br />
    <h6><b>Payment Type:</b></h6>
    <br />
    <div style={{justifyContent: 'space-between'}}>
      <BasicForm.Radio
        name="advanceType"
        type="radio"
        label="Initiate ACH From Dealer"
        value="source"
        checked={values.advanceType === 'source'}
        />
      { advanceType && advanceType === 'source' &&
        <div style={{marginLeft: '35px'}}>
          <br />
          <Options.AdvanceTypeOptions />
          <br />
        </div>
      }
      <BasicForm.Radio
        name="advanceType"
        type="radio"
        label="Manual Payment Method"
        value="external"
        />
      { advanceType && advanceType === 'external' &&
        <div style={{marginLeft: '35px'}}>
          <br />
          <Options.ExternalTypeOptions quoteAmount={quoteAmount} setDisabled={setDisabled} />
          <br />
        </div>
      }
      <BasicForm.Radio
        name="advanceType"
        type="radio"
        label="Payment From Account"
        value="On Account"
        />
      { advanceType && advanceType === 'On Account' &&
        <p style={{marginLeft: '35px'}}>
          {`Note: the source for Payment from Account is the Dealer: ${dealerName}`}
        </p>
      }
      <BasicForm.InlineStyledErrorMessage>
        <ErrorMessage name='advanceType'/>
      </BasicForm.InlineStyledErrorMessage>
    </div>
    </div>
}

