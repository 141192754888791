import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const ACCOUNT_GROUP_FIELDS = `
  id
  name
  increasedBy
  bank
  accountsPayable
  income
  expense
  loan
  nonPosting
  listedScopes
`;

const ACCOUNT_GROUP_FIELDS_AND_ERRORS = `
  accountGroup {
    ${ACCOUNT_GROUP_FIELDS}
  }
  ${ERRORS}
`;

export const ACCOUNT_GROUPS = gql`
  query AccountGroups($scopes: [String!]) {
    accountGroups(
      scopes: $scopes
    ) {
      ${ACCOUNT_GROUP_FIELDS}
    }
  }
`;

export const ACCOUNT_GROUP = gql`
  query ACCOUNT_GROUP($id: ID!) {
    accountGroup(id: $id) {
      ${ACCOUNT_GROUP_FIELDS}
      accounts {
        id
        gl,
        name,
        special { name },
        hiddenAt
      }
    }
  }
`;

export const HIDE_ACCOUNT_GROUP = gql`
  mutation HideAccountGroup($id: ID!) {
    hideAccountGroup(
      id: $id
    ) {
      ${ACCOUNT_GROUP_FIELDS_AND_ERRORS}
    }
  }
`;

export const UNHIDE_ACCOUNT_GROUP = gql`
  mutation unhideAccountGroup($id: ID!) {
    unhideAccountGroup(
      id: $id
    ) {
      ${ACCOUNT_GROUP_FIELDS_AND_ERRORS}
    }
  }
`;

const ACCOUNT_GROUP_VARIABLES = `
  $bank: Boolean
  $accountsPayable: Boolean
  $income: Boolean
  $expense: Boolean
  $loan: Boolean
  $nonPosting: Boolean
`;

const ACCOUNT_GROUP_ARGUMENTS = `
  name: $name
  increasedBy: $increasedBy
  bank: $bank
  accountsPayable: $accountsPayable
  income: $income
  expense: $expense
  loan: $loan
  nonPosting: $nonPosting
`;

export const CREATE_ACCOUNT_GROUP = gql`
  mutation CreateAccountGroup(
    $name: String!
    $increasedBy: String!
    ${ACCOUNT_GROUP_VARIABLES}
  ) {
    createAccountGroup(
      ${ACCOUNT_GROUP_ARGUMENTS}
    ) {
      ${ACCOUNT_GROUP_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_ACCOUNT_GROUP = gql`
  mutation UpdateAccountGroup(
    $id: ID!
    $name: String
    $increasedBy: String
    ${ACCOUNT_GROUP_VARIABLES}
  ) {
    updateAccountGroup(
      id: $id
      ${ACCOUNT_GROUP_ARGUMENTS}
    ) {
      ${ACCOUNT_GROUP_FIELDS_AND_ERRORS}
    }
  }
`;
