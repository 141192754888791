import React from "react";
import { Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { modalStyle, firstRowStyle } from "../FilterStyles";

const Normal = ({
  currentFilters,
  filterKeys,
  selectOptions,
  adjustFilterValues,
  selectLabels,
  handleFilters,
}) => {
  return selectLabels.map((label, index) => {
    return (
      <Row key={index} style={index === 0 ? firstRowStyle : modalStyle.rows}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          value={currentFilters.current[filterKeys[index]] || ""}
          onChange={(event) =>
            adjustFilterValues(
              event.currentTarget.value,
              label,
              currentFilters,
              handleFilters
            )
          }
        >
          <option key={0} value="">Filter by {label}</option>
          {selectOptions[index].map((option, optionIndex) => {
            let optionValue = option.id ? (
              <option key={optionIndex} value={option.id}>{option.name}</option>
            ) : (
              <option key={optionIndex}>{option}</option>
            );

            if (Array.isArray(option) && option.length === 3) {
              const [type, id, name] = option;
              optionValue = <option key={id} value={id}>{name}</option>;
            }

            return optionValue;
          })}
        </Form.Control>
      </Row>
    );
  });
};

export default Normal;
