import React, { useState, useContext, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import DataState from "../../../DataState";
// import NotesTable from '../../../Notes'
import { USER_LOGS_WITH_COUNT } from "../../../../queries/indexQueries";
import { setAlertData } from "../../../../utils/setAlertData";
import { useAlert } from "../../../../hooks/useAlert";
import BasicAlert from "../../../../elements/BasicAlert";
import DataTable from "../../../../elements/DataTable";
import AuthContext from "../../../../contexts/AuthContext";
import moment from "moment";
import { DateHelpers, StringHelpers } from "../../../../utils";
import {
  responsiveDefaults,
  getInitialColumnSortIndex,
  createOrder,
} from "../../../../utils/datatableHelpers";
import {
  initialPaginationData,
  insertTableData,
  reSortTableData,
} from "../../../../elements/Pagination/Functions";
import { useLazyLoader } from "../../../../hooks/useLazyLoader";
import { getColumnName } from "./ColumnSort";

export const columns = [
  {
    title: "Created At",
    render: DateHelpers.renderDate,
  },
  {
    title: "Referrer",
  },
  {
    title: "URL",
  },
  {
    title: "Controller",
  },
  {
    title: "Action",
  },
  {
    title: "User Agent",
  },
  {
    title: "IP Address",
  },
  // {
  //   title: 'Params ID'
  // },
  {
    title: "Params",
    className: 'no-sort',
  },
];

const UserLogs = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const [queryLoading, setQueryLoading] = useState(false);
  const [sorting, setSorting] = useState(false);

  let {
    resourceName,
    routePrefix,
    resourceType,
    parentResource,
    newRoute,
    ownerId,
    systemResource,
    editRoutePrefix,
  } = props;

  const id = parseInt(useParams().ownerId);

  const [initialPageLimit, initialOffset] = initialPaginationData();

  const initialSort = "created_at";
  const initialSortColumnTitle = "Created At";
  const initialSortDirection = "desc";

  const columnId = getInitialColumnSortIndex(columns, initialSortColumnTitle);
  const order = createOrder(columnId, initialSortDirection);

  responsiveDefaults.order = order;
  responsiveDefaults.columns = columns;

  const initializeData = {
    initialPageLimit,
    initialSortDirection,
    initialSort,
  };

  const tableId = "#log-table";
  const datatable = $(tableId).DataTable(responsiveDefaults);

  const [limit, setLimit] = useState(initialPageLimit);
  const [queryData, setQueryData] = useState(null);
  let duplicate = useRef(false);

  let userLogCount = useRef(0);

  let currentFilters = useRef({
    created_at: null,
  });

  const { loading, error, data, fetchMore } = useQuery(USER_LOGS_WITH_COUNT, {
    variables: {
      id,
      userId: id,
      limit: initialPageLimit,
      offset: initialOffset,
      sort: initialSort,
      sortDirection: initialSortDirection,
    },
  });

  const [
    handlePaginate,
    handleColumnSort,
    handleFilters,
    handleSearch,
    currentPage,
    sort,
    sortColumnIndex,
    sortDirection,
    offset,
    search,
  ] = useLazyLoader(
    limit,
    fetchMore,
    setQueryData,
    userLogCount,
    null,
    currentFilters,
    getColumnName,
    queryData,
    setQueryLoading,
    datatable,
    initializeData,
    setSorting
  );

  const auth = useContext(AuthContext);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), []);

  useEffect(() => {
    if (!duplicate.current) {
    setQueryData(data);
    duplicate.current = false;
    }
  }, [data]);

  useEffect(() => {
    duplicate.current = true;
  }, [queryData])

  if (loading || !data || !queryData ) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  userLogCount.current = data.userLogsWithCount.count;

  const userLogs = queryData.userLogsWithCount.userLogs;

  const dataSet = sorting
    ? []
    : userLogs.map(function(userLog) {
    const createdAt = moment(userLog.createdAt).format("M/D/Y [at] hh:mm a");
    const referrer = userLog.referrer;
    const url = userLog.url;
    const controller = userLog.controller;
    const action = userLog.action;
    const userAgent = userLog.userAgent;
    const ipAddress = userLog.ipAddress;
    // const paramsId = userLog.paramsId
    let params = userLog.formattedParams;
    params = JSON.parse(params);
    if (params.graphql) {
      params.graphql = JSON.parse(params.graphql);
    }

    const p = [];

    p.push(`<li><b>Action:</b> ${params.action}</li>`)
    p.push(`<li><b>Controller:</b> ${params.controller}</li>`)
    if (params.graphql) {
      p.push(`<li><b>GraphQL:</b> ${params.graphql}</li>`)
    }
    if (params.query) {
      p.push(`<li><b>Query:</b> ${params.query}</li>`)
    }
    if (params.variables) {
      p.push(`<li><b>Variables:</b> ${params.variables}</li>`)
    }

    return [
      createdAt,
      referrer,
      url,
      controller,
      action,
      userAgent,
      ipAddress,
      // paramsId,
      p.join(' '),
    ];
  });

  //** Lazy Loading Functions **//
  // Reset datatable values on data change;
  insertTableData(datatable, dataSet);
  // Since we're programmatically setting the datatable order, datatables
  // acts weird and tries to interject, this allows us to sort correctly.
  reSortTableData(datatable, sortColumnIndex, sortDirection);

  if (!parentResource || parentResource === "undefined") {
    newRoute = `/${resourceType}/${ownerId}/notes/new?ownerResource=${resourceType}&parentResource=${parentResource}`;
  } else {
    newRoute = `/${parentResource}/${parentId}/${resourceType}/${ownerId}/notes/new?ownerResource=${resourceType}&parentResource=${parentResource}`;
  }

  const userName = `${queryData.user?.firstName} ${queryData.user?.lastName}`;

  const filterData = [
    null,
    null,
    queryLoading,
    {
      filterData: [],
    }
  ];

  return (
    <>
      <Helmet>
        <title>{userName} - Log</title>
      </Helmet>
      {showAlert && <BasicAlert componentType="User log" type={alertType} />}
      <div>
        <DataTable
          dataSet={dataSet}
          columns={columns}
          order={order}
          pageTitle={`Log for ${userName}`}
          newRoute={newRoute}
          unhideable={true}
          uncreatable={true}
          handlePaginate={handlePaginate}
          currentPage={currentPage.current}
          paginateLimit={limit}
          setPaginateLimit={setLimit}
          paginateItemsCount={userLogCount.current}
          handleColumnSort={handleColumnSort}
          setSorting={setSorting}
          tableId={tableId}
          handleFilter={filterData}
        />
      </div>
    </>
  );
};

export default UserLogs;
