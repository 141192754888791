import React, { useContext, useEffect, useState } from 'react';
import BasicAlert from '../../../elements/BasicAlert';
import { useAlert } from '../../../hooks/useAlert';
import { gql } from '@apollo/client';
import DataTable from '../../../elements/DataTable';
import AuthContext from '../../../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import { useHideableResource } from '../../../hooks/useHideableResource';
import { setAlertData } from '../../../utils/setAlertData';
import { TERM_PROGRAMS, HIDDEN_TERM_PROGRAMS } from '../../../queries/indexQueries'
import DataState from '../../DataState';


const HIDE_TERM_PROGRAM = gql`
  mutation HideTermProgram($id: ID!) {
    hideTermProgram(
      id: $id
    ) {
      termProgram {
          id
        }
      errors {
        message
        path
      }
    }
  }
`

const UNHIDE_TERM_PROGRAM = gql`
  mutation UnhdeTermProgram($id: ID!) {
    unhideTermProgram(
      id: $id
    ) {
      termProgram {
          id
        }
      errors {
        message
        path
      }
    }
  }
`

const TermPrograms: () => React$Node = (props) => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();
  const auth = useContext(AuthContext);

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_TERM_PROGRAMS,
    VISIBLE_QUERY: TERM_PROGRAMS,
    HIDE_MUTATION: HIDE_TERM_PROGRAM,
    UNHIDE_MUTATION: UNHIDE_TERM_PROGRAM,
    variables: {},
  };

  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)
  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const columns = [
    {
      title: 'Name'
    },
    {
      title: 'Number of Terms'
    },
    {
      title: 'Max Number of Extensions'
    },
    {
      title: 'Total Number of Days'
    },
    {
      title: 'Active Dealers'
    },
    {
      title: 'Active Cars'
    },
    {
      title: 'Non-Normal Terms'
    },
    {
      title: 'Avg Days / Term'
    },
    {
      title: "Actions"
    },
  ]

  const dataSet = data.termPrograms.map((program) => {
    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${program.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${program.id}>Hide</a>`

    const actions = []

    if (auth.hasPolicy('TermProgram', 'update')) {
      actions.push(`<a href="/system/term-programs/${program.id}">Edit</a>`)
    }

    if (auth.hasPolicy('TermProgram', 'hide')) {
      actions.push(hiddenLink)
    }

    if (actions === []){
      actions.push(' ')
    }

    const numberOfTerms = program.termLines.length
    const days = program.termLines.map((termLine) => termLine.days)
    let totalDays = program.extensionDays * program.extensionLimit
    if (days.length > 0) totalDays += (days.reduce(function (a, b) { return a + b }))

    return [
      program.name,
      numberOfTerms,
      program.extensionLimit,
      totalDays,
      program.activeDealers.length,
      program.activeFlooredCars.length,
      program.nonNormalTerms,
      program.averageDaysPerTerm,
      actions.join(" | ")
    ]
  })

  return (
    <>
      <Helmet>
        <title>Term Programs</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="term program" type="update" />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Term Programs'}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
    </>
  );
};

export default TermPrograms;
