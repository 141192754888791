import React from 'react';
import { setDate } from 'components/src/utils/dateHelpers';
import AuthLink from 'components/src/components/AuthLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { currency } from 'components/src/utils/stringHelpers';

export const allColumns = [
  'earnedOn', 'createdDate', 'market', 'dealer', 'flooredCar', 'fee',
  'txn', 'purpose', 'gl', 'account', 'department', 'debit', 'credit',
  'earnsInterest', 'memo'
];

export const columnDefinitions = ({ columns, rawAmount }) => {
  const definitions = [
    {
      key: 'earnedOn',
      label: 'GL Date',
      sort: 'desc',
      width: 120,
      content: ({ value }) => setDate(value)
    },
    {
      key: 'createdDate',
      label: 'Created Date',
      width: 120,
      minViewportWidth: 1300,
      content: ({ entry }) => setDate(entry.txn.createdDate)
    },
    {
      key: 'market',
      label: 'Market',
      minViewportWidth: 1200,
      value: (({ value }) => value?.name)
    },
    {
      key: 'dealer',
      label: 'Dealer',
      minViewportWidth: 800,
      value: (({ value }) => value?.name),
      content: (({ value, entry }) => {
        if (!entry.dealer) { return null; };

        return (
          <AuthLink
            to={`/dealers/${entry.dealer.id}`}
            resource="Dealer"
            action="read"
          >
            {value}
          </AuthLink>
        );
      })
    },
    {
      key: 'flooredCar',
      label: 'Car',
      minViewportWidth: 800,
      value: (({ value }) => value?.vin),
      content: (({ value, entry }) => {
        const car = entry.flooredCar;
        if (!car) { return null; };

        return (
          <AuthLink
            to={`/dealers/${car.dealer.id}/cars/${car.id}`}
            resource="FlooredCar"
            action="read"
          >
            {car.vin}
          </AuthLink>
        );
      })
    },
    {
      key: 'fee',
      label: 'Fee',
      minViewportWidth: 800,
      value: (({ value }) => value?.name),
      content: (({ value, entry }) => {
        if (!entry.fee) { return null; };

        return (
          <AuthLink
            to={`/fee/${entry.fee.id}/transactions`}
            resource="Fee"
            action="read"
          >
            {value}
          </AuthLink>
        );
      })
    },
    {
      key: 'txn',
      label: 'Transaction',
      value: ({ value }) => `${value.type} ${value.reference || ''}`,
      content: (({ value, entry }) => {
        if (!entry.txn) { return null; };

        return (
          <AuthLink
            to={`/transactions/${entry.txn.id}`}
            resource="Txn"
            action="read"
          >
            {value}
          </AuthLink>
        );
      })
    },
    {
      key: 'purpose',
      label: 'Purpose',
      minViewportWidth: 1300,
      value: (({ value, entry }) => value || entry.txn.purpose)
    },
    {
      key: 'gl',
      label: 'GL',
      width: 80,
      value: ({ entry }) => entry.account.gl
    },
    {
      key: 'account',
      label: 'Account',
      minViewportWidth: 900,
      value: ({ entry }) => entry.account.name
    },
    {
      key: 'department',
      label: 'Department',
      minViewportWidth: 900,
      value: (({ value }) => value?.name)
    },
    {
      key: 'debit',
      label: 'Debit',
      width: 120,
      value: ({ entry }) => parseFloat(entry.debitLessCredit),
      content: (({ entry }) => (
        <span style={{textAlign: 'right'}}>
          {rawAmount || !entry.debit ? entry.debit : currency(entry.debit)}
        </span>
      ))
    },
    {
      key: 'credit',
      label: 'Credit',
      width: 120,
      value: ({ entry }) => (parseFloat(entry.debitLessCredit) * -1),
      content: (({ entry }) => (
        <span style={{textAlign: 'right'}}>
          {rawAmount || !entry.credit ? entry.credit : currency(entry.credit)}
        </span>
      ))
    },
    {
      key: 'earnsInterest',
      label: 'Earns Int.',
      width: 80,
      content: (({ value }) => value ? <FontAwesomeIcon icon={faCheck} /> : '')
    },
    {
      key: 'memo',
      label: 'Memo'
    }
  ];

  return definitions.filter(column => columns.includes(column.key));
};
