import * as yup from 'yup';

export const editCarSchema = yup.object({
  mileage: yup
    .number()
    .required('Required'),
  vin: yup
    .string()
    .required('Required')
    .max(17, "Can't be longer than 17 characters"),
  year: yup
    .number()
    .integer()
    .required('Required'),
  make: yup
    .string()
    .max(50, "Can't be longer than 50 characters")
    .required('Required'),
  model: yup
    .string()
    .max(50, "Can't be longer than 50 characters")
    .required('Required'),
  series: yup
    .string()
    .max(50, "Can't be longer than 50 characters"),
  color: yup
    .string()
    .max(50, "Can't be longer than 50 characters")
    .required('Required'),
  rateProgramId: yup
    .number()
    .typeError('Required')
    .required('Required'),
  dealerProgramId: yup
    .number()
    .typeError('Required')
    .required('Required'),
});

export const newCarSchema = yup.object({
  loanAmount: yup
    .number()
    .required('Required'),
  vin: yup
    .string()
    .required('Required')
    .max(17, "Can't be longer than 17 characters"),
  year: yup
    .number()
    .integer()
    .typeError('Required')
    .required('Required'),
  make: yup
    .string()
    .max(50, "Can't be longer than 50 characters")
    .required('Required'),
  model: yup
    .string()
    .max(50, "Can't be longer than 50 characters")
    .required('Required'),
  series: yup
    .string()
    .max(50, "Can't be longer than 50 characters"),
  color: yup
    .string()
    .max(50, "Can't be longer than 50 characters")
    .required('Required'),
  mileage: yup
    .number()
    .required('Required'),
  rateProgramId: yup
    .number()
    .typeError('Required')
    .required('Required'),
  dealerProgramId: yup
    .number()
    .typeError('Required')
    .required('Required'),
  unitNumber: yup.string()
    .required()
    .matches(/^[0-9]+|Auto|auto$/, "Must be a number or Auto"),
  advanceType: yup
    .string()
    .required('Required'),
  sourceType: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'source')
          ? schema.required('Required')
          : schema
    ),
  sourceId: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'source')
          ? schema.required('Required')
          : schema
    ),

  externalPaymentMethod: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'external')
          ? schema.required('Required')
          : schema
    ),
  advanceAccountId: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'external')
          ? schema.nullable().required('Required')
          : schema
    ),
  bankAccountId: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'source')
          ? schema.required('Required')
          : schema
    ),
});
