import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/pro-regular-svg-icons';
import { Colors } from '../../styles';

export const SortButtons = ({columnName, selectedColumn}) => {
  let on;
  let selectedColumnName;

  if (selectedColumn) {
    on = Object.values(selectedColumn)[0];
    selectedColumnName = Object.keys(selectedColumn)[0];
  }

  const currentColumn = columnName === selectedColumnName;

  const currentColor = {
    color: Colors.xlGreen,
    fontSize: '17px'
  }

  const firstVariableStyle = {
    margin: {
      marginLeft: '7px'
    },
    opacity: {
      opacity: '0.5',
    },
  }

  return [ <FontAwesomeIcon
      icon={faSortUp}
      style={(currentColumn && !on)
        ? Object.assign({}, firstVariableStyle.margin, firstVariableStyle.margin, currentColor)
        : Object.assign({}, firstVariableStyle.margin, firstVariableStyle.opacity)}
    />,
    <FontAwesomeIcon
      icon={faSortDown}
      style={(currentColumn && on) ? currentColor : firstVariableStyle.opacity}
    />
  ]
}

export const handleSort = (data, selectedColumn, setOrderedColumn, orderedColumn, setOrderedData, setReordered) => {
  let on = true;
  if (orderedColumn && orderedColumn[selectedColumn]) on = false;

  setOrderedColumn({[selectedColumn]: on})

  let newData = data;
  let isTotalDays = false;
  if (selectedColumn === 'totalDays') isTotalDays = true;

  if (isTotalDays) {
    newData = sortNestedData('termData', 'totalDays', newData, on)
    setReordered(true)
    return
  }

  if (on) {
    newData = newData.sort(function(a,b){
      return new Date(b[`${selectedColumn}`]) - new Date(a[`${selectedColumn}`]);
    })
  } else if (!on) {
      newData = newData.sort(function(a,b){

      return new Date(a[`${selectedColumn}`]) - new Date(b[`${selectedColumn}`]);
    })
  }

  setReordered(true)
  setOrderedData(newData);
 }

 const sortNestedData = (firstValue, secondValue, newData, on) => {
    if (on) {
      newData = newData.sort(function(a,b){
        return b[firstValue][secondValue] - a[firstValue][secondValue];
      })
    } else if (!on) {
        newData = newData.sort(function(a,b){

        return a[firstValue][secondValue] - b[firstValue][secondValue];
      })
    }
 }
