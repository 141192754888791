import {
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCommentAltLines,
  faUsers,
  faMoneyCheckAlt
} from '@fortawesome/pro-regular-svg-icons'

export const getLinks = (name, id) => {
  const title = {
    link: `/vendors/${id}`,
    text: name,
  }

  const navLinks = [
    {
      link: `/vendors/${id}/edit`,
      icon: faEdit,
      text: 'Edit',
      resource: 'Vendor',
      action: 'update',
    },
    {
      link: `/vendors/${id}/contacts`,
      icon: faUsers,
      text: 'Contacts'
    },
    {
      link: `/vendors/${id}/transactions`,
      icon: faMoneyCheckAlt,
      text: 'Transactions'
    },
    {
      link: `/vendors/${id}/notes`,
      icon: faCommentAltLines,
      text: 'Notes'
    }
  ]

  return { title, navLinks }
}
