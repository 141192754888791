import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { useAlert } from '../../../hooks/useAlert';
import { Form, saleTimeSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import DataState from '../../DataState';
import * as TimeHelper from '../../../utils/timeHelpers';
import { AUCTION } from '../Info';

const UPDATE_SALE_TIME = gql`
  mutation UpdateSaleTime($id: ID!, $name: String!, $type: String!, $occursOn: ISO8601Date,
    $dayOfWeek: Int, $saleStartAt: String, $saleEndAt: String) {
    updateSaleTime(
      id: $id
      name: $name
      type: $type
      occursOn: $occursOn
      dayOfWeek: $dayOfWeek
      saleStartAt: $saleStartAt
      saleEndAt: $saleEndAt
    ) {
      saleTime { id }
      errors { message, path }
    }
  }
 `

const SALE_TIME = gql`
  query SALE_TIME($id: ID!) {
    saleTime(id: $id) {
      id
      name
      type
      occursOn
      dayOfWeek
      saleStartAt
      saleEndAt
    }
  }
`

 const EditSaleTime = (props) => {
   const [redirect, setRedirect] = useState(false);
   const auctionId = parseInt(useParams().id);
   const saleTimeId = parseInt(useParams().saleTimeId);
   const history = useHistory();
   const previousRoute = props.location.state?.previousRoute;

   const [updateSaleTime, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_SALE_TIME,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: AUCTION, variables: { id: auctionId }}],
      onCompleted(values) {
        history.push({
          pathname: previousRoute || `/auctions/${auctionId}`,
          state: { alert: true, componentType: 'sale time', type:'updated' }
        });
       }
     })

   const { loading, error, data } = useQuery(SALE_TIME, { variables: { id: saleTimeId }})

   if (loading && !data) return <DataState.Loading />;
   if (error) return <DataState.Error error={error} />;

   let { saleTime: {
      name,
      type,
      occursOn,
      dayOfWeek,
      saleStartAt,
      saleEndAt } } = data;

  if (dayOfWeek) dayOfWeek = dayOfWeek.toString();
  if (saleStartAt) saleStartAt = TimeHelper.formatFromQuery(saleStartAt);
  if (saleEndAt) saleEndAt = TimeHelper.formatFromQuery(saleEndAt);

  const handleSubmit = (values) => {
   values.dayOfWeek = (values.dayOfWeek)
     ? parseInt(values.dayOfWeek)
     : null;
   values.occursOn = (values.occursOn)
     ? values.occursOn
     : null;

    updateSaleTime({ variables: values })
  };

   const EditForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/auctions/${auctionId}`,
          state: { alert: true }
        }}
      />
    }

     return (
       <>
         <div style={Spacing.formWrapper}>
           <div style={Spacing.formHeader}>
             <h1 style={Typography.formHeader}>{`Edit Sale Time For: ${name}`}</h1>
           </div>
           <Formik
             initialValues={{
               id: saleTimeId,
               name: name || '',
               type: type || '',
               occursOn: occursOn || '',
               dayOfWeek: dayOfWeek || '',
               saleStartAt: saleStartAt || '',
               saleEndAt: saleEndAt || '',
             }}
             validationSchema={saleTimeSchema}
             onSubmit={handleSubmit}
           >
             <Form />
           </Formik>
         </div>
       </>
     )
    }

  return (
    <>
    <Helmet>
      <title>Edit Sale Time</title>
    </Helmet>
    <div style={Spacing.formContainer}>
      <EditForm />
    </div>
    </>
  );
};

export default EditSaleTime;
