import React from 'react';
import { Spacing, Typography } from 'components/src/styles';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import DataTable from 'components/src/elements/DataTable';
import DataState from 'components/src/components/DataState';
import { ACH_BATCH } from './queries';
import Transactions from './Transactions';
import AchBatchInfo from './AchBatchInfo';
import AuthLink from 'components/src/components/AuthLink';

const DecryptButton = ({ children, ...props }) => {
  return (
    <div style={{margin: '10px'}}>
      <Link
        className="btn btn-primary"
        style={{color: 'white'}}
        to={props.href}
      >
        {children}
      </Link>
    </div>
  );
};

const Show = () => {
  const id = parseInt(useParams().id);

  const { loading, error, data } = useQuery(ACH_BATCH, { variables: { id } });

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { achBatch } = data;

  return (
    <div style={Spacing.formWrapper}>
      <AchBatchInfo achBatch={achBatch} />
      <Transactions txns={achBatch.txns} />

      <AuthLink
        href={`/financial/ach-batches-decrypt/${id}`}
        resource="BankAccount"
        action="decrypt_token"
        exactPolicy
        component={DecryptButton}
      >
        Decrypt Batch (may take up to 1 minute)
      </AuthLink>

      <pre style={Spacing.showContainer}>{achBatch.data}</pre>
    </div>
  );
}

export default Show;
