import React, { useState, useEffect, useParams } from 'react';
import { useQuery } from '@apollo/client';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { SELECT_OPTIONS } from './Queries';
import DataState from '../../DataState';
import { CustomAlert } from '../../../elements/BasicAlert';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import { errorStyle, missingdataErrorStyle } from './Style';
import { handleNumberChange } from "../../../utils/stringHelpers";
import featureEnabled from 'components/src/utils/featureEnabled';
import styled from 'styled-components';

const Restrictions = styled.div`
  border: 2px solid red;
  padding: 10px;
`;

export const Form = props => {
  const { titleLog, edit, disabled, dealerId, restrictions } = props;
  const { loading, error, data } = useQuery(SELECT_OPTIONS);
  const { values, setFieldValue, errors } = useFormikContext();

  const [dealerAddressValue, setDealerAddressValue] = useState();
  const [shipToTypeValue, setShipToTypeValue] = useState();
  const [shipToIdValue, setShipToIdValue] = useState();
  const [missingDataError, setMissingDataError] = useState(false);
  const [dealerAddressOptions, setDealerAddressOptions] = useState(false);
  const [dealerIdValue, setDealerIdValue] = useState();

  let currentOptions;
  let shipToIdLabel;
  let url;
  let dealerPlaces;

  // reset values when checking if shipment

  useEffect(() => {
    if (values.shipTitle === true) {
      setFieldValue('trackingNumber', null)
    } else {
      setFieldValue('shipToType', null)
      setFieldValue('shipToId', null)
      setFieldValue('dealerId', null)
      setFieldValue('otherAddress.name', '')
      setFieldValue('otherAddress.street', '')
      setFieldValue('otherAddress.city', '')
      setFieldValue('otherAddress.state', '')
      setFieldValue('otherAddress.zip', '')
      setFieldValue('otherAddress.phone', '')
      setShipToTypeValue(null)
    }
  }, [values.shipTitle])

  // apply missing address/phone error

  useEffect(() => {
    setMissingDataError(false)
    if (values.shipToId) {
      if (
        values.shipToType === 'Auction' ||
        values.shipToType === 'ApprovedSource'
      ) {
        let resultObject = currentOptions?.find(
          (value) => parseInt(value.id) === values?.shipToId
        )

        if (
          !resultObject ||
          resultObject.city === '' ||
          resultObject.city === null ||
          resultObject.street === '' ||
          resultObject.street === null ||
          resultObject.state === null ||
          resultObject.state === '' ||
          resultObject.zip.value === null ||
          resultObject.zip.value === '' ||
          resultObject.phone.value === null ||
          resultObject.phone.value === ''
        ) {
          setMissingDataError(true)
        }
      }
    }
  }, [values.shipToId])

  // removing missing address/phone error

  useEffect(() => {
    setMissingDataError(false)
  }, [values.shipToType])

  // sets dealer place options

  useEffect(() => {
    if (values.shipToType === 'DealerPlace') {
      let dealerResultObject = dealerPlaces?.find(
        (value) => parseInt(value.id) === values?.dealerId
      )

      const dealerAddressOption = dealerResultObject?.dealerPlaces.map(
        (option) => {
          return {
            label: `
              ${option.name} - ${option.street} - ${option.city} -
              ${option.state} - ${option.zip.value}`,
            value: option.id
          }
        }
      )

      setDealerAddressOptions(dealerAddressOption)
    }
  }, [values.dealerId])

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { titleStatuses, dealers, auctions, approvedSources } = data;

  const handleFile = (file) => {
    setFieldValue('file', file)
  }

  dealerPlaces = dealers

  if (values.shipToType === 'Auction') {
    currentOptions = auctions
    shipToIdLabel = 'Auction'
    url = 'auctions'
  } else if (values.shipToType === 'ApprovedSource') {
    currentOptions = approvedSources
    shipToIdLabel = 'Approved Source'
    url = 'approved-sources'
  }

  const shipToTypes = [
    { label: 'Dealer', value: 'DealerPlace'},
    { label: 'Approved Source', value: 'ApprovedSource'},
    { label: 'Auction', value: 'Auction' },
    { label: 'Other Address', value: 'Other'}
  ]

  const shipToTypeOptions = shipToTypes.map((option) => {
    return { label: option.label, value: option.value }
  })

  const shipToIdOptions = currentOptions?.map((option) => {
    return { label: option.name, value: option.id }
  })

  const dealerOptions = dealers?.map((option) => {
    return { label: option.name, value: option.id }
  })

  const handleShipToTypeChange = (selected) => {
    if (selected.value !== values.shipToType) {
      setShipToIdValue(null)
      setDealerIdValue(null)
      setFieldValue('shipToId', null)
      setFieldValue('dealerId', null)
      setFieldValue('otherAddress.name', '')
      setFieldValue('otherAddress.street', '')
      setFieldValue('otherAddress.city', '')
      setFieldValue('otherAddress.state', '')
      setFieldValue('otherAddress.zip', '')
      setFieldValue('otherAddress.phone', '')
    }

    if (selected.value === 'DealerPlace') {
      let getDefaultDealer = dealers.find(
        (value) => parseInt(value.id) === dealerId
      )

      setDealerIdValue({ label: getDefaultDealer.name, value: dealerId })
      setFieldValue('dealerId', dealerId)

      let defaultAddress = getDefaultDealer?.defaultMailingLocation ? {
        value: getDefaultDealer.defaultMailingLocation.id,
        label: `
          ${getDefaultDealer.defaultMailingLocation.name} -
          ${getDefaultDealer.defaultMailingLocation.street} -
          ${getDefaultDealer.defaultMailingLocation.city} -
          ${getDefaultDealer.defaultMailingLocation.state} -
          ${getDefaultDealer.defaultMailingLocation.zip.value}
        `
      } : null;

      setDealerAddressValue(defaultAddress)
      setFieldValue('shipToId', parseInt(defaultAddress?.value) || null)
    }
    setShipToTypeValue(selected)
    setFieldValue('shipToType', selected.value)
  }

  const handleShipToIdChange = (selected) => {
    setShipToIdValue(selected)
    setFieldValue('shipToId', parseInt(selected.value))
  }

  const handleDealerIdChange = (selected) => {
    let getDefaultAddress = dealers.find(
      (value) => value.id === selected?.value
    )

    let defaultAddress = getDefaultAddress?.defaultMailingLocation ? {
      value: getDefaultAddress.defaultMailingLocation.id,
      label: `
        ${getDefaultAddress.defaultMailingLocation.name} -
        ${getDefaultAddress.defaultMailingLocation.street} -
        ${getDefaultAddress.defaultMailingLocation.city} -
        ${getDefaultAddress.defaultMailingLocation.state} -
        ${getDefaultAddress.defaultMailingLocation.zip.value}
      `
    } : null;

    setDealerAddressValue(defaultAddress)
    setDealerIdValue(selected)
    setFieldValue('dealerId', parseInt(selected.value))
    setFieldValue('shipToId', parseInt(defaultAddress?.value) || null)
  }

  const handleDealerAddressChange = (selected) => {
    setDealerAddressValue(selected)
    setFieldValue('shipToId', parseInt(selected.value))
  }

  const isBorrowedStatus =
    values.titleStatusId && data.titleStatuses.find(status => (
      parseInt(status.id) === values.titleStatusId
    ))?.autoFeeTitleBorrowed

  const restricted = isBorrowedStatus && restrictions?.length > 0;

  return (
    <FormikForm>
      {restricted && (
        <Restrictions>
          Dealer Is Restricted From Borrowing Titles:
          <ul>
            {restrictions.map(reason => <li key={reason}>{reason}</li>)}
          </ul>
        </Restrictions>
      )}
      {
        !edit &&
          <BasicForm.Checkbox
            name="shipTitle"
            type='checkbox'
            label='Ship Title?'
            disabled={disabled}
          />
      }
      {
        disabled &&
          <Alert variant='danger'>
            This unit already has a title pending shipment -&nbsp;
            <a href="/unshipped-titles">
              view
            </a>
          </Alert>
      }
      {
        values.shipTitle !== true &&
          <BasicForm.TextInput
            name="trackingNumber"
            label="Tracking Number"
          />
      }
      {
        values.shipTitle === true && !edit &&
          <>
            <BasicForm.StyledLabel>Ship To</BasicForm.StyledLabel>
            <Select
              isMulti={false}
              options={shipToTypeOptions}
              onChange={handleShipToTypeChange}
              value={shipToTypeValue}
            />
            <div style={errorStyle}>
              { errors.shipToType ? errors.shipToType : '' }
            </div>
          </>
      }
      {
        values.shipToType === 'Other' && !edit &&
          <>
            <BasicForm.TextInput
              name="otherAddress.name"
              type='text'
              label="Name"
            />
            <BasicForm.TextInput
              name="otherAddress.street"
              type="text"
              label="Street"
            />
            <BasicForm.TextInput
              name="otherAddress.city"
              type="text"
              label="City"
            />
            <BasicForm.StateAbbreviationSelect
              name="otherAddress.state"
              type="text"
              label="State"
            />
            <BasicForm.TextInput
              name="otherAddress.zip"
              type="text"
              label="Zip Code"
            />
            <BasicForm.PhoneInput
              name="otherAddress.phone"
              country="US"
              value={values.otherAddress.phone || ''}
              onChange={
                event => {
                  handleNumberChange(
                    event,
                    'otherAddress.phone',
                    setFieldValue
                  )
                }
              }
              label='Phone Number'
            />
          </>
      }
      {
        shipToIdLabel && !edit &&
          <>
            <BasicForm.StyledLabel>{shipToIdLabel}</BasicForm.StyledLabel>
            <Select
              isMulti={false}
              options={shipToIdOptions}
              onChange={handleShipToIdChange}
              value={shipToIdValue}
            />
            <div style={errorStyle}>
              { errors.shipToId ? errors.shipToId : '' }
            </div>
          </>
      }
      {
        values.shipToType === 'DealerPlace' && !edit &&
          <>
            <BasicForm.StyledLabel>Dealer</BasicForm.StyledLabel>
            <Select
              isMulti={false}
              options={dealerOptions}
              onChange={handleDealerIdChange}
              value={dealerIdValue}
            />
          </>
      }
      {
        values.shipToType === 'DealerPlace' && values.dealerId && !edit &&
          <>
            <BasicForm.StyledLabel>Dealer Address</BasicForm.StyledLabel>
            <Select
              isMulti={false}
              options={dealerAddressOptions}
              onChange={handleDealerAddressChange}
              value={dealerAddressValue}
            />
          </>
      }
      {
        missingDataError &&
          <div style={missingdataErrorStyle}>
            <Alert variant='danger'>
              This shipping option is missing an adress and or phone&nbsp;
              number -&nbsp;
              <a href={`/${url}/${values.shipToId}`}>
                view
              </a>
            </Alert>
          </div>
      }
      {
        !edit &&
          <BasicForm.ProgrammaticSelect
            options={titleStatuses}
            name="titleStatusId"
            label="Title Status"
            optionName='Title Status'
          />
      }
      {
        !titleLog?.fileUpload &&
          <BasicForm.PdfUpload
            name='file'
            label='Upload Scanned Title'
            handleFile={handleFile}
          />
      }
      <BasicForm.TextArea
        name="notes"
        label="Notes"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={missingDataError || restricted}
        />
      </div>
    </FormikForm>
  );
}
