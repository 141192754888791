import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Field} from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '../../../../elements/ErrorMessage';
import { classNames } from '../functionality';
import { Typography, Spacing } from '../../../../styles';
import * as yup from 'yup';
import { Colors } from '../../../../styles';
import '../../../../styles/baseRates.css';
import DataState from '../../../DataState';

const UPDATE_BASE_RATE_CHANGE = gql`
   mutation UpdateBaseRateChanges($id: ID!, $effectiveOn: ISO8601Date!, $rate: Decimal!)
     {
      updateBaseRateChanges(
       id: $id
       effectiveOn: $effectiveOn
       rate: $rate
     ) {
       baseRateChange { id, rate, effectiveOn }
       errors { message, path }
     }
   }
`

const BASE_RATE_CHANGE = gql`
    query BASE_RATE_CHANGE($id: ID!) {
      baseRateChange(id: $id) {
        id
        rate
        effectiveOn
      }
    }
  `

const EditBaseRateChange = () => {
  const baseRateId = parseInt(useParams().id);
  const baseRateChangeId = parseInt(useParams().changeId);
  const [redirect, setRedirect] = useState(false);

  const [updateBaseRateChange] = useMutation(UPDATE_BASE_RATE_CHANGE,
    {
      onCompleted() {
        setRedirect(true);
      }
    }
    );

  const { loading, error, data } = useQuery(BASE_RATE_CHANGE, { variables: { id: baseRateChangeId } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { baseRateChange } = data;

  const today = new Date()

  const schema = yup.object({
      rate: yup
        .number()
        .min(0, 'Must be positive')
        .required('Required'),
      effectiveOn: yup
        .date()
        .min(today, 'The date must be in the future')
        .required('Required')
    });

  function BaseRateChangeForm() {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/system/base-rates/${baseRateId}`,
          state: { alert: true }
        }}
      />
    }

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          id: baseRateChange.id,
          rate: baseRateChange.rate || '',
          effectiveOn: baseRateChange.effectiveOn || ''
        }}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isInvalid,
        errors,
        setValues
      }) => (
        <Form noValidate onSubmit={handleSubmit} style={{paddingRight: 40, paddingLeft: 40}}>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>{`Update Base Rate Change`}</h1>
          </div>
        </div>
          <Form.Label>Rate</Form.Label>
          <Form.Group className='term-line-errors'>
            <Field className={classNames(errors, touched, 'rate')} type="number" name="rate" />
            <ErrorMessage name={"rate"} />
          </Form.Group>
          <Form.Label>Effective On</Form.Label>
          <Form.Group className='term-line-errors'>
            <Field className={classNames(errors, touched, 'effectiveOn')} type="date" name="effectiveOn" />
            <ErrorMessage name={"effectiveOn"} />
          </Form.Group>
           <Button
            type="submit"
            style={buttonColor}
          >
            Update Base Rate Change
          </Button>
        </Form>
      )}
      </Formik>
    )
  }

  const buttonColor = {
     backgroundColor: Colors.xlGreen,
     borderColor: Colors.xlGreen,
     color: 'white'
  }

  const handleSubmit = (values) => {
    updateBaseRateChange({
      variables: values
    })
  }

  return (
    <>
    <Helmet>
      <title>Update Base Rate Change</title>
    </Helmet>
    <BaseRateChangeForm />
    </>
  );
};

export default EditBaseRateChange;
