import React from 'react';
import { Helmet } from 'react-helmet';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Form, statusSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'
import { CREATE_DEALER_STATUS } from './queries';

const NewDealerStatus = () => {
  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_DEALER_STATUS,
    onCompleteRedirectTo: '/system/dealer-statuses'
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const NewForm = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A Dealer Status</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              name: '',
              canFloor: false,
              canPay: false,
              canLogin: false,
              requireCorporateChange: false,
              sendAudits: false,
              sendAvailability: '',
              hidesDealer: false,
              sendAaClosedMessage: false,
              default: false,
              chooseOnNsf: false,
              excludeFromTitleRelease: false,
            }}
            validationSchema={statusSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>New Dealer Status</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default NewDealerStatus;
