import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormikContext } from 'formik';
import * as BasicForm from "../../../../elements/forms";
import * as Buttons from "../../../../elements/buttons";
import { Spacing } from "../../../../styles";
import { currency } from '../../../../utils/stringHelpers';
import { AdvanceType } from "./AdvanceType/AdvanceType";

let modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px',
}

const labelStyle = {
    marginTop: "20px",
};

export const ProcessWithdrawal = ({ showWithdrawModal, setShowWithdrawModal, handleSubmit, formSubmitting, balance, type }) => {
  const { setFieldValue, values, errors, isValid } = useFormikContext();
  const handleClose = () => setShowWithdrawModal(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledForm, setDisabledForm] = useState(false)

  useEffect(() => {
    if (values.bankAccountId !== null && values.bankAccountId !== '') {
      setFieldValue('bankAccountId', parseInt(values.bankAccountId))
    }

    if (values.accountId !== null && values.accountId !== '') {
      setFieldValue('accountId', parseInt(values.accountId))
    }
  }, [values.bankAccountId, values.accountId])

  useEffect(() => {
    if (values.advanceType === 'source') {
      setFieldValue('ach', true)
    } else {
      setFieldValue('ach', false)
    }
  }, [values.advanceType])

  useEffect(() => {
    if (values.advanceType === 'external') {
      setFieldValue('bankAccountId', null)
    }
  }, [values.advanceType])

  useEffect(() => {
    if (values.advanceType === 'Dealer Reserve' || values.advanceType === 'On Account') {
      setFieldValue('ach', false)
      setFieldValue('txnType', 'Journal Entry')
    }
  }, [values.advanceType])

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => setShowWithdrawModal(false)}
        show={showWithdrawModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Withdraw from {type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <BasicForm.DollarInput
            name="amount"
            type="number"
            label={`Amount to Withdraw from ${type}`}
            labelStyle={labelStyle}
          />
          <AdvanceType key="advanceType" />
          <BasicForm.TextInput
            name="memo"
            type="text"
            label="Note"
            labelStyle={labelStyle}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose} />
            <Buttons.Standard
              type="submit"
              onClick={() => handleSubmit(values)}
              text="Submit"
              disabled={!isValid || formSubmitting}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
