import React, { useContext, useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import { View } from 'react-native';
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import { rest } from 'components/src/utils/rest';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';
import Filter from 'components/src/elements/Filter';
import DataTables from '@preflighttech/preflight-tables';
import {
  Typography, preflightTableStyles
} from 'components/src/styles';
import { FLOORPLAN_REQUESTS_WITH_COUNT } from './queries';
import columns from './columnDefinitions';
import BasicAlert, {
  ExportToExcelAlert
} from 'components/src/elements/BasicAlert';

const statuses = [
  'Pending', 'Auto Accept', 'Auto Reject',
  'Manual Accept', 'Manual Reject', 'Auto Ignore'
];

const index = () => {
  const [customAlert, displayCustomAlert] = useState(false);

  const [filterOptions, setFilterOptions] = useState();
  const [settings, setSettings] = useState({});
  const [refetch, setRefetch] = useState();
  const [filters, setFilters] = useState({});

  const queryVariables = {
    filterColumns: [], filterNames: [], scopes: []
  };

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);
  }

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      queryVariables['filterColumns'].push(key);
      queryVariables['filterNames'].push(value);
    }
  });

  const handleExportToExcel = () => {
    rest({
      path: 'fprs_report',
      params: {
        search: settings.search,
        sort: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.sort,
        current_filters: queryVariables
      },
    }).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <ExportToExcel handleExportToExcel={handleExportToExcel} />
      <Filter clearFilters={() => setFilters({})}>
        <Filter.Select
          columnKey="status"
          label="Status"
          options={statuses}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.Select
          columnKey="source"
          label="Source"
          options={filterOptions?.auctionNames}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.Select
          columnKey="dealer"
          label="Dealer"
          options={filterOptions?.dealerNames}
          filters={filters}
          setFilter={setFilter}
        />
      </Filter>
      <ResetSettings />
    </View>
  );

  return (
    <>
      <Helmet>
        <title>All FPRs</title>
      </Helmet>
      { customAlert && <ExportToExcelAlert /> }
      <div style={Typography.pageTitle}>All FPRs</div>
      <DataTables.Gql
        query={FLOORPLAN_REQUESTS_WITH_COUNT}
        queryName="floorplanRequestsWithCount"
        queryVariables={queryVariables}
        entriesName="floorplanRequests"
        columns={columns}
        buttons={buttons}
        pageLength={10}
        lengthMenu={ [10, 25, 50] }
        dataUpdated={data => setFilterOptions(data?.floorplanRequestsWithCount)}
        setSettings={setSettings}
        setRefetch={setRefetch}
        htmlTable
        styles={preflightTableStyles}
      />
    </>
  );
};

export default index;
