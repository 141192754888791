import React from "react";
import * as Styles from "../../styles/paginationStyles.js";
import Form from "react-bootstrap/Form";

// LAZY LOADING

export const Pagination = ({
  handlePaginate,
  currentPage,
  paginateLimit,
  paginateItemsCount,
}) => {
  let firstPage = currentPage === 1 ? true : false;
  let [firstRange, secondRange] = calculcateShowingRange(
    currentPage,
    paginateLimit,
    paginateItemsCount
  );

  const lastPage = currentPage * paginateLimit >= paginateItemsCount;

  if (paginateItemsCount === 0) return null;

  return (
    <Styles.PaginationBar>
      {`${paginateItemsCount} total entries`}
      <Styles.ActionDiv>
        <Styles.ActionButton onClick={() => handlePaginate("First")}>
          First
        </Styles.ActionButton>
        <Styles.ActionButton onClick={() => handlePaginate("Previous")}>
          Previous
        </Styles.ActionButton>
        <PageNumbers
          currentPage={currentPage}
          handlePaginate={handlePaginate}
          paginateItemsCount={paginateItemsCount}
          paginateLimit={paginateLimit}
        />
        {!lastPage && (
          <Styles.ActionButton onClick={() => handlePaginate("Next")}>
            Next
          </Styles.ActionButton>
        )}
        <Styles.ActionButton onClick={() => handlePaginate("Last")}>
          Last
        </Styles.ActionButton>
      </Styles.ActionDiv>
    </Styles.PaginationBar>
  );
};

const calculcateShowingRange = (
  currentPage,
  paginateLimit,
  paginateItemsCount
) => {
  if (currentPage === 1) return [1, paginateLimit];
  // if (currentPage === Math.round(paginateItemsCount / paginateLimit)) return handleLastPageCalculation(currentPage, paginateLimit);

  const secondRange = currentPage * paginateLimit;
  const firstRange = secondRange - paginateLimit + 1;

  return [firstRange, secondRange];
};

const getMaxExtraPageNumbers = (paginateItemsCount, paginateLimit) => {
  if (paginateItemsCount <= paginateLimit) return 0;
  if (paginateItemsCount <= (paginateLimit * 2) && paginateItemsCount > paginateLimit) return 1;
  if (paginateItemsCount <= (paginateLimit * 3) && paginateItemsCount > paginateLimit * 2) return 2;
  if (paginateItemsCount <= (paginateLimit * 4) && paginateItemsCount > paginateLimit * 3) return 3;
  if (paginateItemsCount <= (paginateLimit * 5) && paginateItemsCount > paginateLimit * 4) return 4.5;

  return 4;
}

let pageRange = [];
const PageNumbers = ({
  currentPage,
  handlePaginate,
  paginateLimit,
  paginateItemsCount,
}) => {
  const maxExtraPageNumbers = getMaxExtraPageNumbers(paginateItemsCount, paginateLimit)

  paginateItemsCount = Math.round(paginateItemsCount / paginateLimit);

  if ((currentPage === paginateItemsCount || currentPage === paginateItemsCount + 1) &&
    maxExtraPageNumbers === 4) {

    return handleLastPage(currentPage, handlePaginate);
  }

  let i = currentPage === 1 ? currentPage : currentPage - 1;

  let numbers = currentPage + maxExtraPageNumbers;
    if (maxExtraPageNumbers < 4 || maxExtraPageNumbers === 4.5) {
      pageRange = setSpecialPaginateNumbers(maxExtraPageNumbers, pageRange, numbers, i);

     return setPaginateNumbers(currentPage, handlePaginate)
  }


  if (
    (currentPage !== paginateItemsCount - 1) &&
    (currentPage === 1 ||
      pageRange[pageRange.length - 2] === currentPage ||
      pageRange[pageRange.length - 1] === currentPage ||
      pageRange[0] === currentPage)
  ) {
    pageRange = [];
    while (i <= numbers) {
      pageRange.push(i);
      i += 1;
    }
  }

  return setPaginateNumbers(currentPage, handlePaginate);
};

const handleLastPageCalculation = (currentPage, paginateLimit) => {
  // TODO this needs to be figured out
  return ["hi", "yo"];
};

// These are for total entries that are less than paginateLimit * 5 in order for
// a range of [1... maxExtraPageNumbers] and no extra.
const setSpecialPaginateNumbers = (maxExtraPageNumbers) => {
  if (maxExtraPageNumbers === 4.5) maxExtraPageNumbers = 4;

  return Array.from(Array(maxExtraPageNumbers + 1), (_, i) => i + 1)
}

const handleLastPage = (currentPage, handlePaginate) => {
  let i = currentPage - 4;

  pageRange = [];
  while (i <= currentPage) {
    if (i > 0) pageRange.push(i);
    i += 1;
  }

  return setPaginateNumbers(currentPage, handlePaginate);
};

const setPaginateNumbers = (currentPage, handlePaginate) => {
  return (
    <>
      {pageRange.map((number) => {
        const button =
          number === currentPage ? (
            <Styles.CurrentPage key={number} className="paginate_button.current">
              <p className="paginate_button">{currentPage}</p>
            </Styles.CurrentPage>
          ) : (
            <Styles.PaginateButton key={number} onClick={() => handlePaginate(number)}>
              {number}
            </Styles.PaginateButton>
          );

        return button;
      })}
    </>
  );
};
