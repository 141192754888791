import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons'
import * as BasicForm from '../../../elements/forms';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { setupPrintCheckHandler } from '../functions';


const CheckNumberForm = ({ setShowCheckModal, checkNumberForm, setCheckNumberForm, handleSubmit, txn, lastDownloadTime, lastDownloadId, setLastDownloadId, setLastDownloadTime }) => {
  const { values } = useFormikContext();

  const handleClose = () => setCheckNumberForm(false)

  const id = parseInt(txn.id)

  if (txn) {
    setupPrintCheckHandler(
      txn,
      lastDownloadId, lastDownloadTime,
      setLastDownloadId, setLastDownloadTime, setShowCheckModal
    )
  }

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => setCheckNumberForm(false)}
        show={checkNumberForm}
      >
        <Modal.Header closeButton>
          Enter Check Number
        </Modal.Header>
        <Modal.Body>
          <div style={Object.assign({}, Spacing.formWrapper )}>
            <FormikForm>
              <BasicForm.TextInput
                name="reference"
                type="text"
                label="Check Number"
              />
              <div style={Spacing.buttonRow}>
                <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose} />
                <Buttons.Standard type="submit" text="Submit" onClick={() => handleSubmit(values)} id={`check-print${id}`} data-id={id} />
              </div>
            </FormikForm>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CheckNumberForm
