import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { gql } from "@apollo/client";
import { Formik } from "formik";
import { Spacing, Typography } from "../../../styles";
import { useAlert } from "../../../hooks/useAlert";
import DataState from "../../DataState";
import { contactSchema, Form } from "./Form";
import { titleCase } from "../../../utils/stringHelpers";
import Loading from '../../../elements/Loading';

const CONTACT = gql`
  query CONTACT($id: ID!) {
    contact(id: $id) {
      id
      firstName
      lastName
      title
      email
      mobilePhone {
        value
        formatted
      }
      officePhone {
        value
        formatted
      }
      primary
    }
  }
`;

const UPDATE_CONTACT = gql`
  mutation UpdateContact(
    $id: ID!
    $ownerType: String!
    $ownerId: Int!
    $firstName: String!
    $lastName: String
    $title: String
    $email: String
    $mobilePhone: String
    $officePhone: String
    $primary: Boolean!
  ) {
    updateContact(
      id: $id
      firstName: $firstName
      lastName: $lastName
      title: $title
      email: $email
      mobilePhone: $mobilePhone
      officePhone: $officePhone
      primary: $primary
      ownerType: $ownerType
      ownerId: $ownerId
    ) {
      contact {
        id
        firstName
      }
    }
  }
`;

const Edit = () => {
  const { id, contactId } = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { loading, error, data } = useQuery(CONTACT, {
    variables: { id: parseInt(contactId) },
  });

  const [showAlert, displayAlert] = useAlert();
  const history = useHistory();
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    onCompleted() {
      displayAlert();
      setFormSubmitting(false);
      history.push({
        pathname: `/vendors/${id}/contacts`,
        state: { alert: true },
      });
    },
  });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const {
    firstName,
    lastName,
    title,
    email,
    primary,
  } = data.contact;

  const mobilePhone = data.contact.mobilePhone?.formatted || "";
  const officePhone = data.contact.officePhone?.formatted || "";

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    updateContact({ variables: values });
  };

  const EditForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update Contact: ${titleCase(
            firstName
          )} ${titleCase(lastName)}`}</h1>
        </div>
        <Formik
          initialValues={{
            id: contactId,
            ownerId: parseInt(id),
            ownerType: "Vendor",
            firstName: firstName,
            lastName: lastName || "",
            title: title || "",
            email: email || "",
            mobilePhone: mobilePhone || "",
            officePhone: officePhone || "",
            primary: primary,
          }}
          validationSchema={contactSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    );
  };

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default Edit;
