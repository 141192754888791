import {
  faEdit,
  faMoneyCheckAlt,
  faUniversity,
  faKey
} from '@fortawesome/free-solid-svg-icons';
import { faCars } from '@fortawesome/pro-solid-svg-icons'
import {
  faCommentAltLines,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons'

export const getLinks = (name, id) => {
  const title = {
    link: `/approved-sources/${id}`,
    text: name,
  }

  const navLinks = [
    {
      link: `/approved-sources/${id}/edit`,
      icon: faEdit,
      text: 'Edit',
      resource: 'ApprovedSource',
      action: 'update',
    },
    {
      link: `/approved-sources/${id}/contacts`,
      icon: faUsers,
      text: 'Contacts'
    },
    {
      link: `/approved-sources/${id}/cars`,
      icon: faCars,
      text: 'Cars'
    },
    {
      link: `/approved-sources/${id}/notes`,
      icon: faCommentAltLines,
      text: 'Notes'
    },
    {
      link: `/approved-sources/${id}/bank-accounts`,
      icon: faUniversity,
      text: 'Bank Accounts'
    },
    {
      link: `/approved-sources/${id}/auction-tokens`,
      icon: faKey,
      text: 'Auction API Tokens'
    }
  ]

  return { title, navLinks }
}
