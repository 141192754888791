import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';

export function useHideableResource(props) {
  const {
    HIDDEN_QUERY,
    VISIBLE_QUERY,
    HIDE_MUTATION,
    UNHIDE_MUTATION,
    marketId
  } = props;
  let { variables } = props;

  const [showHidden, setShowHidden] = useState(false);

  const indexQuery = showHidden ? HIDDEN_QUERY : VISIBLE_QUERY;

  if (marketId && !Object.keys(variables).includes('marketId')) {
    variables = { id: parseInt(marketId) }
  }

  const [hideMutation] = useMutation(HIDE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: indexQuery,
        variables: { ...variables },
      }
    ],
  });

  const [unhideMutation] = useMutation(UNHIDE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: indexQuery,
        variables: { ...variables },
      }
    ],
  });

  const { loading, error, data, fetchMore } = useQuery(indexQuery, { variables: { ...variables } });

  $(document).on('click', '#hide', function (event) {
    let id = event.target.dataset.id
    id = parseInt(id, 10)
    hideMutation({ variables: { id } })
  })

  $(document).on('click', '#unhide', function (event) {
    let id = event.target.dataset.id
    id = parseInt(id, 10)
    unhideMutation({ variables: { id } })
  })

  return [showHidden, setShowHidden, loading, error, data, fetchMore];
}
