import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { Form, statusSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import { CURRENT_USER } from '../../../queries/currentUser';
import { TITLE_STATUSES } from './queries';
import DataState from '../../DataState';
import Loading from '../../../elements/Loading';

const NEW_TITLE_STATUS = gql`
  mutation CreateTitleStatus($name: String!, $createdById: Int!, $info: String,
    $isPresent: Boolean!, $toDealer: Boolean!, $autoFeeTitleAdded: Boolean!
    $autoFeeTitleBorrowed: Boolean!, $autoFeeTitleShipped: Boolean!,
    $autoFeeTitleMarkedPresent: Boolean!, $fund: Boolean!) {
    createTitleStatus(
      name: $name
      createdById: $createdById
      info: $info
      isPresent: $isPresent
      toDealer: $toDealer
      autoFeeTitleAdded: $autoFeeTitleAdded
      autoFeeTitleBorrowed: $autoFeeTitleBorrowed
      autoFeeTitleShipped: $autoFeeTitleShipped
      autoFeeTitleMarkedPresent: $autoFeeTitleMarkedPresent
      fund: $fund
    ) {
      titleStatus { id }
      errors { message, path }
    }
  }
 `

const NewTitleStatus = () => {
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [newTitleStatus, { loading: mutationLoading, error: mutationError }] = useMutation(
    NEW_TITLE_STATUS,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: TITLE_STATUSES }],
      onCompleted(variables) {
        setFormSubmitting(false);
        setRedirect(true);
      }
    })

  const { loading, error, data } = useQuery(CURRENT_USER);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { currentUser } = data;
  const createdById = parseInt(currentUser.id);

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    newTitleStatus({ variables: values })
  };

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/title-statuses",
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A Title Status</h1>
          </div>

          <Formik
            initialValues={{
              createdById,
              name: '',
              info: '',
              isPresent: false,
              toDealer: false,
              autoFeeTitleAdded: false,
              autoFeeTitleBorrowed: false,
              autoFeeTitleShipped: false,
              autoFeeTitleMarkedPresent: false,
              fund: false,
            }}
            validationSchema={statusSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>New Title Status</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default NewTitleStatus;
