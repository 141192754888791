import React, { useState, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import DataState from '../../DataState';
import DataTable from '../../../elements/DataTable';
import AuthContext from '../../../contexts/AuthContext';
import * as StringHelper from '../../../utils/stringHelpers';
import * as DateHelper from '../../../utils/dateHelpers';
import NewFeeWaiver from '../../Cars/Fees/Modal';

const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      unpaidFees {
        id, name, amount, chargeOn, termNumber, termType, paid, earnsInterest,
        canUserWaive, due, status, earned, paidAmount, waivedAmount
      }
    }
  }
`

let feeName;
let amountDue;

const UnpaidFees = () => {
  const [showModal, setShowModal] = useState(false);
  const [feeId, setFeeId] = useState();
  const auth = useContext(AuthContext);
  const id = parseInt(useParams().id);

  const handleShow = () => setShowModal(true);

  const { loading, error, data } = useQuery(DEALER, { variables: { id } });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const dealer = { ...data.dealer }

  const feeColumns = [
    {
      title: 'Fee'
    },
    {
      title: 'Original Amount'
    },
    {
      title: 'Amount Due'
    },
    {
      title: 'Assessed On'
    },
    {
      title: 'Status'
    },
    {
      title: 'Earned'
    },
    {
      title: 'Amount Paid'
    },
    {
      title: 'Amount Waived'
    },
    {
      title: 'Earns Interest?'
    },
    {
      title: 'Actions'
    },
    {
      title: 'Waivers'
    }
  ];

  $(document).on('click', '#modal', function (event) {
    let id = event.target.dataset.id
    id = parseInt(id)
    amountDue = parseFloat(event.target.dataset.due);
    feeName = event.target.dataset.fee;

    setFeeId(id)

    handleShow()
  })

  const feesDataSet = dealer.unpaidFees.map((fee) => {
    if (auth.hasPolicy('Txn', 'read')) {
      fee.actions = `<a href="/fee/${fee.id}/transactions"/>Transactions</a>`
    }

    let waiveButton;

    if (fee.canUserWaive === true) {
      waiveButton = `<button data-id="${fee.id}" data-due="${fee.due}" data-fee="${fee.name}" id="modal">Waive Fee</button>`
    } else {
      waiveButton = `Fee cannot be waived`
    }

    if (fee.due === "0.0") {
      waiveButton = fee.status;
    }

    if (auth.hasPolicy('Fee', 'waive')) {
    } else {
      waiveButton = "You do not have the Fee Waiver Role"
    }

    return [
      fee.name,
      StringHelper.currency(fee.amount),
      StringHelper.currency(fee.due),
      DateHelper.setDate(fee.chargeOn),
      fee.status,
      StringHelper.currency(fee.earned),
      StringHelper.currency(fee.paidAmount),
      StringHelper.currency(fee.waivedAmount),
      StringHelper.yesNo(fee.earnsInterest),
      fee.actions,
      waiveButton
    ]
  })

  return (
    <>
      <DataTable
        tableId='#dealer-fees'
        dataSet={feesDataSet}
        columns={feeColumns}
        pageTitle={`Unpaid Fees`}
        unhideable={true}
        uncreatable={true}
      />
      {
        showModal && <NewFeeWaiver
        amountDue={amountDue}
        showModal={showModal}
        setShowModal={setShowModal}
        dealerName={name}
        feeId={feeId}
        feeName={feeName}
        />
      }
    </>
  )
}

export default UnpaidFees
