import React from 'react';
import AuthLink from 'components/src/components/AuthLink';
import { ColTitle, ReportLink } from '../index';

const Audits = () => {
  return (
    <>
      <ColTitle>Audits</ColTitle>
      <AuthLink
        to={'/reports/report/unverified-car'}
        resource="Audit"
        action="read"
        component={ReportLink}
      >
        Unverified Cars Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/audit-exception-detail'}
        resource="Audti"
        action="read"
        component={ReportLink}
      >
        Audit Exception Detail Report
      </AuthLink>

      <AuthLink
        to={'/reports/report/reconciliation-exceptions'}
        resource="Audit"
        action="read"
        component={ReportLink}
      >
        Reconciliation Exceptions
      </AuthLink>
    </>
  );
};

export default Audits;
