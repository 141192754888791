import React, { useContext, useEffect, useState, useRef } from 'react';
import BasicAlert from '../../../elements/BasicAlert';
import { useAlert } from '../../../hooks/useAlert';
import { gql } from '@apollo/client';
import DataTable from '../../../elements/DataTable';
import AuthContext from '../../../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import { setAlertData } from '../../../utils/setAlertData';
import DataState from '../../DataState';
import { useHideableResource } from '../../../hooks/useHideableResource';

export const PAYMENT_TERMS = gql`
  query {
  paymentTerms {
    id
    name
    days
  }
}
`

const HIDDEN_PAYMENT_TERMS = gql`
  query {
    paymentTerms(scopes: ["hidden"]) {
      id
      name
      days
    }
  }
`

const HIDE_PAYMENT_TERM = gql`
  mutation HidePaymentTerm($id: ID!) {
    hidePaymentTerm(
      id: $id
    ) {
      paymentTerm {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const UNHIDE_PAYMENT_TERM = gql`
  mutation UnhidePaymentTerm($id: ID!) {
    unhidePaymentTerm(
      id: $id
    )
    {
      paymentTerm {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const PaymentTerms = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_PAYMENT_TERMS,
    VISIBLE_QUERY: PAYMENT_TERMS,
    HIDE_MUTATION: HIDE_PAYMENT_TERM,
    UNHIDE_MUTATION: UNHIDE_PAYMENT_TERM,
    variables: {},
  };
  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)
  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])
  const auth = useContext(AuthContext);

  let currentData = null;
  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const columns = [
    {
      title: 'Name'
    },
    {
      title: 'Days'
    },
    {
      title: "Actions"
    }
  ]

  const dataSet = data.paymentTerms.map((term) => {
    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${term.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${term.id}>Hide</a>`

    const actions = [];

    if (auth.hasPolicy('Role', 'update')) {
      actions.push(`<a href="/system/payment-terms/${term.id}">Edit</a>`)
    }

    if (auth.hasPolicy("DealerProgram", "hide")) {
      actions.push(hiddenLink);
    }

    return [
      term.name,
      term.days,
      actions.join(" | "),
    ]
  })

  return (
    <>
      <Helmet>
        <title>Payment Terms</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="payment term" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Payment Terms'}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
    </>
  );
};

export default PaymentTerms;
