import * as Colors from "../../styles/colors";

export const modalStyle = {
  header: {
    alignItems: "center",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  body: {
    padding: "3rem",
  },
  rows: {
    padding: "10px",
    marginBottom: "12px",
  },
  checkboxRow: {
    padding: "10px",
    marginBottom: "12px",
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    width: "14px",
    height: "16px",
    marginBottom: "8px",
    marginLeft: "8px",
  },
  loadingRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    paddingTop: '10px',
  }
};

export const firstRowStyle = {
  ...modalStyle.rows,
  marginTop: "-32px",
};

export const filterButton = {
  fontWeight: "bold",
  fontSize: "1em",
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen,
  color: "white",
  marginRight: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
};

export const multiSelectStyle = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })
}
