import React from "react";
import { useFormikContext } from "formik";
import * as Buttons from "../../elements/buttons";
import { Spacing } from "../../styles";
import Button from "react-bootstrap/Button";

const handleTermButton = (values, setValues, type) => {
  const order = values.termsAttributes.length + 1;
  if (type === "add") {
    (values.termsAttributes = [
        ...values.termsAttributes,
        {
          order,
          termLinesAttributes: [
            {
              curtailmentPercentage: 0,
              principalBelow: null,
              termLineFeesAttributes: [{}],
            },
          ],
        },
      ])

    setValues(values)
    return;
  }

  const visibleTerms = values.termsAttributes.filter((term) => !term._destroy)
  const visibleTerm = visibleTerms[visibleTerms.length - 1];
  const termIdx = values.termsAttributes.indexOf(visibleTerm);

  (visibleTerm.id)
    ? values.termsAttributes[termIdx] = {
      id: visibleTerm.id,
      feeType: 'Single',
      termDays: 0,
      type: 'Term',
      _destroy: true,
      order,
    }
    : values.termsAttributes.pop();

    setValues(values)
};

export const TermButtons = () => {
  const { setValues, values } = useFormikContext();

  const addButton = (
    <Buttons.Standard
      type="button"
      text="Add Another Term Or Extension"
      onClick={() => handleTermButton(values, setValues, "add")}
    />
  );

  const removeButton = (
    <Buttons.Standard
      type="button"
      text="Remove Term Or Extension"
      onClick={() => handleTermButton(values, setValues, "remove")}
    />
  );

  return values.termsAttributes.length > 1 ? (
    <div style={Spacing.buttonRow}>
      {addButton}
      {removeButton}
    </div>
  ) : (
    <div style={Spacing.buttonRow}>{addButton}</div>
  );
};

const handleAddPrincipal = (values, index, setValues) => {
  const termLineFeesAttributes = [];

  const totalFees =
    values.termsAttributes[index].termLinesAttributes[0].termLineFeesAttributes?.length || 0;

  let i = 0;
  while (i < totalFees) {
    termLineFeesAttributes.push({});
    i += 1;
  }

  values.termsAttributes[index].termLinesAttributes = [
    ...values.termsAttributes[index].termLinesAttributes,
    {
      curtailmentPercentage: 0,
      principalBelow: null,
      termLineFeesAttributes,
    },
  ];

  setValues(values);
};

const handleRemovePrincipal = (values, index, setValues) => {
  const visiblePrincipals = values.termsAttributes[index].termLinesAttributes.filter((principal) => !principal._destroy);
  const visiblePrincipal = visiblePrincipals[visiblePrincipals.length - 1];
  const principalIdx = visiblePrincipals.indexOf(visiblePrincipal);

 (visiblePrincipal.id)
    ? values.termsAttributes[index].termLinesAttributes[principalIdx] =
    {
      _destroy: true,
      id: visiblePrincipal.id,
      curtailmentPercentage: 0,
      principalBelow: null,
    }
    : values.termsAttributes[index].termLinesAttributes.pop();

  setValues(values);
};

export const PrincipalButtons = ({ values, index, setValues }) => {
  const principals = values.termsAttributes[
    index
  ].termLinesAttributes.filter(
    (attr) => !attr._destroy
  )

  return principals.length === 1 ? (
    <Button
      type="button"
      onClick={() => handleAddPrincipal(values, index, setValues)}
    >
      Add Another Principal Amount
    </Button>
  ) : (
    <div>
      <Button
        type="button"
        onClick={() => handleAddPrincipal(values, index, setValues)}
      >
        Add Another Principal Amount
      </Button>
      <Button
        type="button"
        variant="danger"
        style={{ marginLeft: "10px" }}
        onClick={() => handleRemovePrincipal(values, index, setValues)}
      >
        Remove Principal Amount
      </Button>
    </div>
  );
};

const handleFees = (values, index, setValues, type) => {
  if (type === "remove") {
    values.termsAttributes[index].termLinesAttributes.map((attribute, idx2) => {
      if (attribute._destroy) return;
      const visibleFees = attribute.termLineFeesAttributes.filter((fee) => !fee._destroy);

      const visibleFee = visibleFees[visibleFees.length - 1];
      const feeIdx = attribute.termLineFeesAttributes.indexOf(visibleFee);

      (visibleFee.id)
      ? attribute.termLineFeesAttributes[feeIdx] =
      {
        _destroy: true,
        id: attribute.termLineFeesAttributes[feeIdx].id
      }
      : attribute.termLineFeesAttributes.pop();
    });

    setValues(values);
    return;
  }

  values.termsAttributes[index].termLinesAttributes.map((attribute, idx2) => {
    if (attribute._destroy) return;
    attribute.termLineFeesAttributes = [
      ...attribute.termLineFeesAttributes,
      {},
    ];
  });

  setValues(values);
};

export const FeeButtons = ({ values, index, setValues }) => {
  const visibleFees = values.termsAttributes[index].termLinesAttributes[0]
    .termLineFeesAttributes.map(attr => {
      return (!attr._destroy) ? attr : []
    }).flat();

  return visibleFees.length === 1 ? (
    <Button
      style={{ margin: "10px 25px" }}
      type="button"
      onClick={() => handleFees(values, index, setValues)}
    >
      Add Fee
    </Button>
  ) : (
    <div>
      <Button
        style={{ margin: "10px 25px" }}
        type="button"
        onClick={() => handleFees(values, index, setValues)}
      >
        Add Fee
      </Button>
      <Button
        variant="danger"
        type="button"
        onClick={() => handleFees(values, index, setValues, "remove")}
      >
        Remove Fee
      </Button>
    </div>
  );
};
