import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useParams, Redirect } from "react-router-dom";
import { Formik } from "formik";
import DataState from "../DataState";
import { Spacing, Typography } from "../../styles";
import { titleCase } from "../../utils/stringHelpers";
import { Form } from "./Form";
import Loading from "../../elements/Loading";
import AuthContext from "../../contexts/AuthContext";
import { currency } from "../../utils/stringHelpers";
import featureEnabled from 'components/src/utils/featureEnabled';
import { FLOOR_CAR, DEALER } from './Queries';
import { newCarSchema } from './Schema';

const NewCar = (props) => {
  const dealerId = parseInt(useParams().id);
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [checkRedirect, setCheckRedirect] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const [referrerType, setReferrerType] = useState('');
  const [referrerId, setReferrerId] = useState();
  const [
    floorCar,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(FLOOR_CAR, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: DEALER, variables: { id: dealerId } }],
    onCompleted(values) {
      setFormSubmitting(false);
      setTransactionId(values.floorCar.txn.id)
      if (featureEnabled('doubleEntry', 'checkPrinting') &&
        values.floorCar.flooredCar.advanceReference === null &&
        values.floorCar.flooredCar.externalPaymentMethod === 'Check'
      ) {
        setReferrerType('dealer')
        setReferrerId(dealerId)
        setCheckRedirect(true)
      } else {
        setRedirect(true);
      }
    },
  });

  const { loading, error, data } = useQuery(DEALER, {
    variables: { id: dealerId },
  });

  if (loading || !data || mutationLoading) return <DataState.Loading />;

  if (error || mutationError)
    return <DataState.Error error={error || mutationError} />;

  const { dealer } = data;

  const dealerLine = dealer.dealerLines[0];

  const today = dealer.market.today;

  const rateProgramId = parseInt(dealerLine.activeRateProgram?.id) || "";
  const dealerProgramId = parseInt(dealerLine.activeDealerProgram?.id) || "";
  const dealerLineId = parseInt(dealerLine.id);
  const dealerName = titleCase(dealer.name);
  const dealerDefaultAutomatedFees = dealerLine.automatedFeeResourcesAttributes
    .map(
      (fee) => {
        return { automatedFeeId: parseInt(fee.automatedFee.id) };
      }
    );

  const dealerDefaultAutomatedFeeIds = dealerLine
    .automatedFeeResourcesAttributes.map(
      (fee) => {
        return { value: parseInt(fee.automatedFee.id), label: fee.name };
      }
    );

  const handleSubmit = (values) => {
    if (values.loanAmount === "") values.loanAmount = 0;
    if (values.bosDate === "") values.bosDate = null;
    if (values.sourceType === "other") values.sourceType = null;
    if (values.advanceType === "on_account" && !values.sourceId)
      values.sourceId = parseInt(dealer.id);
    if (values.year) values.year = parseInt(values.year);
    if (values.externalPaymentMethod === "")
      values.externalPaymentMethod = null;
    if (values.sourceId === "") values.sourceId = null;
    values.bankAccountId = values.bankAccountId ?
      parseInt(values.bankAccountId) : null;
    if (values.sourceId) values.sourceId = parseInt(values.sourceId);
    if (values.advanceTo === "") values.advanceTo = null;
    if (values.advanceReference === "") values.advanceReference = null;
    if (values.titleStatusId === "") values.titleStatusId = null;
    if (values.automatedFeeResourcesAttributes.length === 0)
      delete values.automatedFeeResourcesAttributes;
    if (values.blackbookValue === '') delete values.blackbookValue;
    values.advanceAccountId =
      values.advanceAccountId === "" ? null : parseInt(values.advanceAccountId);
    setFormSubmitting(true);

    floorCar({ variables: values });
  };

  const NewForm = () => {
    if (dealer.dealerStatus.canFloor === true) {
      return (
        <>
          <div style={Spacing.formWrapper}>
            <div style={Spacing.formHeader}>
              <h1
                style={Typography.formHeader}
              >{`Floor a Car for ${dealerName}`}</h1>
              <br />
              Current Availability: {currency(dealer.availableCredit)}
            </div>
            <Formik
              initialValues={{
                dealerLineId,
                dealerName,
                rateProgramId,
                dealerProgramId,
                advanceType: "source",
                sourceType: "Auction",
                sourceId: "",
                externalPaymentMethod: "",
                unitNumber: "Auto",
                stockNumber: "",
                vin: "",
                year: "",
                make: "",
                model: "",
                series: "",
                color: "",
                mileage: "",
                blackbookValue: "",
                titleStatusId: "",
                bankAccountId: "",
                titleState: "",
                titleNumber: "",
                loanAmount: "",
                bosDate: "",
                advanceType: "",
                advanceTo: "",
                automatedFeeResourcesAttributes: [
                  ...dealerDefaultAutomatedFees,
                ],
              }}
              validationSchema={newCarSchema}
              onSubmit={handleSubmit}
            >
              <Form
                dealerDefaultAutomatedFeeIds={dealerDefaultAutomatedFeeIds}
                currentMarketDate={today}
              />
            </Formik>
          </div>
        </>
      );
    } else {
      const auth = useContext(AuthContext);
      let floorText;
      if (auth.hasPolicy("Dealer", "update")) {
        floorText = (
          <a href={"/dealers/" + dealer.id + "/edit"}>
            Click here to Edit the Dealer and change their status.
          </a>
        );
      } else {
        floorText = "You do not have permissions to edit the Dealer.";
      }
      return (
        <>
          <div style={Spacing.formWrapper}>
            The status of {dealer.name} is set to {dealer.dealerStatus.name},
            which means that cars cannot currently be floored for the dealer.
            <br />
            <br />
            {floorText}
          </div>
        </>
      );
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/dealers/${dealerId}/cars`,
          state: { alert: true, type: "floored" },
        }}
      />
    );
  }

  if (checkRedirect) {
    return (
      <Redirect
        to={{
          pathname: `/transactions/${transactionId}?referrerType=
            ${referrerType}&referrerId=${parseInt(referrerId)}`,
          state: {
            alert: true,
            type: "processed. You must enter a check number",
            componentType: "payment"
          }
        }}
      />
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      );
    }
    return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    );
  };

  return <DisplayContent />;
};

export default NewCar;
