import React, { useState } from "react";
import { useRouteMatch, useParams, Redirect } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../elements/forms";
import * as Buttons from "../../../elements/buttons";
import { Spacing } from "../../../styles";
import DataState from "../../DataState";
import { currency } from '../../../utils/stringHelpers';

const WRITE_OFF = gql`
  mutation Writeoff($id: ID!, $memo: String) {
    writeoff(id: $id, memo: $memo) {
      quote {
        id
        txns {
          id
          txnLines {
            debit
            credit
            account {
              name
            }
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

const labelStyle = {
  marginTop: "0px",
};

const NewPayment = ({
  propAlertData,
  showModal,
  setShowModal,
  dealerName,
  quoteId,
  carsFromWriteoff,
}) => {
  const dealerId = parseInt(useParams().id);
  const [redirect, setRedirect] = useState(false);

  const [writeoff, { loading, error }] = useMutation(WRITE_OFF, {
    onCompleted(values) {
      setRedirect(true);
    },
  });

  const handleSubmit = (values) => {
    if (values.memo === "") values.memo = null;

    writeoff({ variables: values });
  };

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  if (redirect)
    return (
      <Redirect
        to={{
          pathname: `/dealers/${dealerId}`,
          state: { alert: true, type: "processed", componentType: "write off" },
        }}
      />
    );

  return (
    <Formik
      initialValues={{
        id: quoteId,
        memo: "",
      }}
      onSubmit={handleSubmit}
    >
      <ProcessPaymentModal
        showModal={showModal}
        setShowModal={setShowModal}
        dealerName={dealerName}
        handleSubmit={handleSubmit}
        carsFromWriteoff={carsFromWriteoff}
      />
    </Formik>
  );
};

const ProcessPaymentModal = ({
  showModal,
  setShowModal,
  dealerName,
  handleSubmit,
  carsFromWriteoff,
}) => {
  const { values, errors } = useFormikContext();
  const [disabled, setDisabled] = useState(false);

  let insurance = 0;
  let principal = 0;
  let interest = 0;
  let fees = 0;

  carsFromWriteoff.forEach((car) => {
    insurance += parseFloat(car.insurance);
    interest += parseFloat(car.interest);
    principal += parseFloat(car.principal);
    car.quoteFees.forEach((fee) => {
      fees += parseFloat(fee.amount)
    })
  })

  const total = insurance + principal + interest + fees;

  let modalBodyStyle = {
    height: "100%",
    paddingBottom: "20px",
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Memo</Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <p>Insurance: {currency(insurance)}</p>
          <p>Interest: {currency(interest)}</p>
          <p>Principal: {currency(principal)}</p>
          <p>Fees: {currency(fees)}</p>
          <h4>Total: {currency(total)}</h4>
          <BasicForm.TextInput
            name="memo"
            type="text"
            label="Memo"
            labelStyle={labelStyle}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel
              text="Cancel"
              handleClose={() => setShowModal(false)}
            />
            <Buttons.Standard
              type="submit"
              onClick={() => handleSubmit(values)}
              text="Submit"
              disabled={disabled}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewPayment;
