import React from 'react';
import styled from '@emotion/styled';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';

const Hint = styled.p`
  margin-top: 0.6em;
  font-size: 0.8em;
  color: #303030;
`;

export const departmentSchema = yup.object({
  name: yup
    .string()
    .required('Required')
    .max(50, 'Maximum length for Department name is 50 characters'),
});

export const Form = () => {
  const { isValid } = useFormikContext();
  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" disabled={!isValid} />
      </div>
    </FormikForm>
  );
};
