import React, { useState, useContext } from 'react';
import { UserContext } from "../../contexts/UserContext";
import { View } from 'react-native';
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';
import AuthContext from '../../contexts/AuthContext';
import { DEALERS_WITH_COUNT } from "../../queries/indexQueries";
import { columnDefinitions } from './columnDefinitions';
import Filter from 'components/src/elements/Filter';
import DataTables from '@preflighttech/preflight-tables';
import {
  Typography, Colors, preflightTableStyles
} from 'components/src/styles';
import { rest } from 'components/src/utils/rest';
import { ExportToExcelAlert } from 'components/src/elements/BasicAlert';
import { HIDE_DEALER, UNHIDE_DEALER } from './Queries';
import { useMutation } from '@apollo/client';
import AuthLink from 'components/src/components/AuthLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { useLocation } from 'react-router-dom';

const addRecord = {
  color: Colors.xlGreen,
  fontSize: 24,
  float: 'right',
  marginRight: 30,
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DealerTable = () => {
  const availableFilters = [
    'market', 'status', 'branch', 'audit_status', 'dealer_categories'
  ]

  const [customAlert, displayCustomAlert] = useState(false);
  const [filterOptions, setFilterOptions] = useState();
  const [settings, setSettings] = useState({});
  const [refetch, setRefetch] = useState();
  const [lastDownloadTime, setLastDownloadTime] = useState(null);
  const [filters, setFilters] = useState({});
  const [showHidden, setShowHidden] = useState(false)

  const [hideDealer] = useMutation(HIDE_DEALER, { onCompleted: refetch });
  const [unhideDealer] = useMutation(UNHIDE_DEALER, { onCompleted: refetch });

  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const paramsQuery = useQuery();

  let marketId = parseInt(user.currentMarket.id)
  let pageTitle = 'Dealers';

  const columns = columnDefinitions({
    auth, lastDownloadTime, setLastDownloadTime,
    // , columns: shownColumns,
    hideDealer, unhideDealer, showHidden
  })

  const queryVariables = {
    filterColumns: [], filterNames: [], scopes: []
  };

  if (paramsQuery.get('status')) {
    queryVariables['filterColumns'].push('status');
    queryVariables['filterNames'].push(paramsQuery.get('status'));

    if ('true' === paramsQuery.get('collections')) {
      pageTitle = `Collections; ${paramsQuery.get('status')}`;
    } else {
      pageTitle = `Dealers; ${paramsQuery.get('status')}`;
    }
  }

  if (marketId) {
    queryVariables['filterColumns'].push('market');
    queryVariables['filterNames'].push(marketId);
  }

  if (showHidden) {
    queryVariables.scopes.push('hidden')
  }

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);
  }

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      queryVariables['filterColumns'].push(key);
      queryVariables['filterNames'].push(value);
    }
  });

  const handleExportToExcel = () => {
    rest({
      path: ('dealers_report'),
      params: {
        search: settings.search,
        sort: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.sort,
        current_filters: queryVariables
      },
    }).then((res) => {
      const response = res.status;
      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <ExportToExcel handleExportToExcel={handleExportToExcel} />
      {
        availableFilters &&
        <Filter clearFilters={() => setFilters({})}>
          {
            availableFilters.includes('market') &&
              <Filter.MultiSelect
                columnKey='market_name'
                label='Market'
                options={filterOptions?.markets}
                filters={filters}
                setFilter={setFilter}
              />
          }
          {
            availableFilters.includes('audit_status') &&
              <Filter.MultiSelect
                columnKey='audit_status'
                label='Audit Status'
                options={filterOptions?.auditStatuses}
                filters={filters}
                setFilter={setFilter}
              />
          }
          {
            availableFilters.includes('branch') &&
              <Filter.MultiSelect
                columnKey='branch'
                label='Branch'
                options={filterOptions?.branches}
                filters={filters}
                setFilter={setFilter}
              />
          }
          {
            availableFilters.includes('status') &&
              <Filter.MultiSelect
                columnKey='status'
                label='Status'
                options={filterOptions?.statuses}
                filters={filters}
                setFilter={setFilter}
              />
          }
          {
            availableFilters.includes('dealer_categories') &&
              <Filter.MultiSelect
                columnKey='dealer_categories'
                label='Type'
                options={filterOptions?.dealerCategories}
                filters={filters}
                setFilter={setFilter}
              />
          }
        </Filter>
      }
      <ResetSettings />
    </View>
  )

  return (
    <>
      { customAlert && <ExportToExcelAlert /> }
      <div style={Typography.pageTitle}>{pageTitle}</div>
      { !showHidden &&
        <a
          style={{paddingLeft: 10}}
          href="#"
          onClick={() => setShowHidden(true)}
        >
          Show Hidden
        </a>
      }
      { showHidden &&
        <a
          style={{paddingLeft: 10}}
          href="#"
          onClick={() => setShowHidden(false)}
        >
          Show Visible
        </a>
      }
      <AuthLink
        to={`dealers/new`}
        resource="Dealer"
        action="update"
        style={addRecord}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </AuthLink>
      <DataTables.Gql
        query={DEALERS_WITH_COUNT}
        queryName="dealersWithCount"
        queryVariables={queryVariables}
        entriesName="dealers"
        columns={columns}
        buttons={buttons}
        dataUpdated={data => setFilterOptions(data?.dealersWithCount)}
        setSettings={setSettings}
        setRefetch={setRefetch}
        htmlTable
        styles={preflightTableStyles}
      />
    </>
  )
}

export default DealerTable;
