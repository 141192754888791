import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import * as yup from "yup";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import * as BasicForm from "../../../elements/forms";
import * as Buttons from "../../../elements/buttons";
import { Spacing } from "../../../styles";

export const paymentTermSchema = yup.object({
  name: yup.string().required('Required'),
  days: yup.number().nullable().min(0, 'Required').required('Required'),
});

export const Form = () => {

  return (
    <FormikForm>
      <BasicForm.TextInput name="name" type="text" label="Name" />
      <BasicForm.TextInput name="days" type="number" min={0} label="Days" />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
        />
      </div>
    </FormikForm>
  );
};
