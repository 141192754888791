import React, { Fragment } from "react";
import { Col } from "react-bootstrap";
import { useFormikContext } from "formik";

const FeeHeader = ({ index, allowInterval, columnStyle }) => {
  const { values } = useFormikContext();

  const fees = values.termsAttributes[
    index
  ].termLinesAttributes[0].termLineFeesAttributes.filter(
    (attr) => !attr._destroy
  )

  return fees.map(
    (attributes, feeIndex) => {

      const showFee = allowInterval ? "" : `#${feeIndex + 1}`;

      return (
        <Col style={columnStyle.smallDiv} key={feeIndex + 1}>
          {`Fee ${showFee}`}
        </Col>
      );
    }
  );
};

export default FeeHeader;
