import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  currencyNoCents,
  integerWithDelimiter
} from 'components/src/utils/stringHelpers';
import { Card, Head, Data, Label, Value } from '../Cards';
import '../styles.css';

const percent = (value, scale) => (value * 100).toFixed(scale || 1) + '%';

const MarketStats = ({ stats }) => {
  return (
    <Col className="dashboard-column market-stats" xs={12} xl={3}>
      <h2>Market Stats</h2>

      <Card>
        <Head>Average Turn Days</Head>
        <Data>
          <Label>Paid Cars (TTM)</Label>
          <Value>
            {integerWithDelimiter(stats.averageDaysOnAccountTTM)}
          </Value>
        </Data>
        <Data>
          <Label>Paid Cars (Last 90 Days)</Label>
          <Value>
            {integerWithDelimiter(stats.averageDaysOnAccount90Days)}
          </Value>
        </Data>
        <Data>
          <Label>Unpaid Cars</Label>
          <Value>
            {integerWithDelimiter(stats.averageDaysOnAccountUnpaid)}
          </Value>
        </Data>
      </Card>

      <Card>
        <Head>Yield</Head>
        <Data>
          <Label>Gross</Label>
          <Value>{percent(stats.grossYield)}</Value>
        </Data>
        <Data>
          <Label>Net</Label>
          <Value>{percent(stats.netYield)}</Value>
        </Data>
      </Card>
    </Col>
  );
}

  export default MarketStats;
