import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import DealerInfo from '../DealerInfo';
import { Spacing, Typography } from '../../../styles';
import DataState from '../../DataState';
import { collateralCoverageSchema, Form } from './Form';
import { DEALER_LINE_COLLATERAL_COVERAGES } from '../../../queries/indexQueries';
import { DEALER_DEALER_LINE } from '../../../queries/viewQueries';
import Loading from '../../../elements/Loading';

const CREATE_COLLATERAL_COVERAGE = gql`
  mutation CreateDealerLineInsuranceProgram($insuranceCompany: String,
  $dealerLineId: Int!, $insuranceProgramId: Int, $thirdParty: Boolean,
  $effectiveOn: ISO8601Date, $expiresOn: ISO8601Date) {
      createDealerLineInsuranceProgram (
        insuranceCompany: $insuranceCompany
        dealerLineId: $dealerLineId
        insuranceProgramId: $insuranceProgramId
        thirdParty: $thirdParty
        effectiveOn: $effectiveOn
        expiresOn: $expiresOn
      ) {
        dealerLineInsuranceProgram {
          insuranceCompany
          thirdParty
          effectiveOn
          expiresOn
        }
        errors { message, path }
      }
    }
`

const NewCollateralCoverage = () => {
  const dealerId = parseInt(useParams().id);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [newCollateralCoverage,
    { loading: mutationLoading, error: mutationError }] = useMutation(
      CREATE_COLLATERAL_COVERAGE,
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: DEALER_LINE_COLLATERAL_COVERAGES, variables: { id: dealerId } }],
        onCompleted(values) {
          setFormSubmitting(false);
          setRedirect(true);
        }
      }
    )

  const handleSubmit = (values) => {
    if (values.insuranceProgramId === '') values.insuranceProgramId = null;
    if (values.effectiveOn === '') values.effectiveOn = null;
    if (values.expiresOn === '') values.expiresOn = null;

    setFormSubmitting(true)
    newCollateralCoverage({ variables: values });
  };

  const { loading, error, data } = useQuery(DEALER_DEALER_LINE, { variables: { id: dealerId } })

  if (loading || mutationLoading) return <DataState.Loading />
  if (error || mutationError) return <DataState.Error error={mutationError} />;

  const { dealer } = data;
  const dealerLineId = parseInt(dealer.dealerLines[0].id);

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/dealers/${dealerId}/collateral-coverages`,
          state: { alert: true, type: 'created' }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Collateral Coverage</h1>
          </div>
          <Formik
            initialValues={{
              dealerLineId,
              insuranceProgramId: '',
              thirdParty: false,
              effectiveOn: dealer.market.today,
              expiresOn: '',
              insuranceCompany: '',
            }}
            validationSchema={collateralCoverageSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default NewCollateralCoverage;
