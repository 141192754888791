import gql from 'graphql-tag';

export const GET_NOTIFICATIONS_FOR_NAVBAR = gql`
  query($ownerId: Int!) {
    notifications(ownerId: $ownerId, scopes: ["unviewed"]) {
      id
      notification
      link
      ownerId
      ownerType
    }
  }
`;

export const SUBSCRIPTION_ADD_NOTIFICATION_TO_NAVBAR = gql`
  subscription ($ownerId: Int!) {
    notificationAdded(ownerId: $ownerId) {
      id
      ownerId
      ownerType
      notification
      link
    }
  }
`;
