import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const KPI_FIELDS = `
  id
  market {
    id
    name
  }
  year
  period
  totalRevenueGoal
  totalRevenueActual
  activationsGoal
  activationsActual
  delinquentPercentGoal
  delinquentPercentActual
  delinquentTimesExceeded
  waiversGoal
  waiversActual
  writeoffPercentGoal
  writeoffPercentActual
  writeoffAmount
  ebtdaGoal
  ebtdaActual
  grossYieldGoal
  grossYieldActual
  netYieldGoal
  netYieldActual
  auditFoundPercentGoal
  auditFoundPercentActual
  flooredCarsGoal
  flooredCarsActual
  goalsUpdatedBy {
    id
    initials
  }
  goalsUpdatedAt
  yieldsUpdatedBy {
    id
    initials
  }
  yieldsUpdatedAt
  createdAt
  updatedAt
`;

const KPI_FIELDS_AND_ERRORS = `
  kpi {
    ${KPI_FIELDS}
  }
  ${ERRORS}
`;

export const KPIS = gql`
  query NoUserLogKPIs(
    $marketId: [ID!]
    $year: Int!
    $period: Int
  ) {
    kpis(
      marketId: $marketId
      year: $year
      period: $period
    ) {
      ${KPI_FIELDS}
    }
    markets {
      id
      name
      kpiCount(year: $year)
    }
    kpiYears
  }
`;

const KPI_NORMAL_VARIABLES = `
  $id: ID!
  $totalRevenueGoal: Int
  $activationsGoal: Int
  $delinquentPercentGoal: Float
  $writeoffPercentGoal: Float
  $waiversGoal: Int
  $ebtdaGoal: Int
  $ebtdaActual: Int
  $auditFoundPercentGoal: Float
  $flooredCarsGoal: Int
`;

const KPI_NORMAL_ARGUMENTS = `
  id: $id
  totalRevenueGoal: $totalRevenueGoal
  activationsGoal: $activationsGoal
  delinquentPercentGoal: $delinquentPercentGoal
  waiversGoal: $waiversGoal
  writeoffPercentGoal: $writeoffPercentGoal
  ebtdaGoal: $ebtdaGoal
  ebtdaActual: $ebtdaActual
  auditFoundPercentGoal: $auditFoundPercentGoal
  flooredCarsGoal: $flooredCarsGoal
`;

const KPI_YIELD_VARIABLES = `
  $id: ID!
  $grossYieldGoal: Float
  $netYieldGoal: Float
`;

const KPI_YIELD_ARGUMENTS = `
  id: $id
  grossYieldGoal: $grossYieldGoal
  netYieldGoal: $netYieldGoal
`;

export const UPDATE_KPI_NORMAL = gql`
  mutation UpdateKPI(
    ${KPI_NORMAL_VARIABLES}
  ) {
    updateKpi(
      ${KPI_NORMAL_ARGUMENTS}
    ) {
      ${KPI_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_KPI_YIELD = gql`
  mutation UpdateKPI(
    ${KPI_YIELD_VARIABLES}
  ) {
    updateKpi(
      ${KPI_YIELD_ARGUMENTS}
      updateQuarter: true
    ) {
      ${KPI_FIELDS_AND_ERRORS}
    }
  }
`;

export const CREATE_KPIS = gql`
  mutation CreateKPIs(
    $marketIds: [ID!]
    $year: Int!
  ) {
    createKpis(
      marketIds: $marketIds
      year: $year
    ) {
      ${ERRORS}
    }
  }
`;

export const DESTROY_KPIS = gql`
  mutation DestroyKPIs(
    $marketIds: [ID!]!
    $year: Int!
  ) {
    destroyKpis(
      marketIds: $marketIds
      year: $year
    ) {
      ${ERRORS}
    }
  }
`;
