import React, { useContext, useRef, useEffect, useState } from 'react';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Text } from 'react-native';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useQuery, useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { Form as FormikForm } from 'formik';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import * as Options from './Options';
import DataState from '../DataState';
import { titleCase } from '../../utils/stringHelpers';
import * as Alert from '../../elements/FloorCarAlerts';
import { Colors } from '../../styles';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { PROGRAMS, GET_CAR } from './Queries';
import AuthContext from '../../contexts/AuthContext';
import {
  noVinAlertText,
  rowStyle,
  backgroundStyle,
  customStyles
} from './Style';

export const EditForm = (props) => {
  const [formSubmitting, setFormSubmitting] = useState();
  const { setValues, values, setFieldValue } = useFormikContext();
  const vinRef = useRef(null);
  const carId = parseInt(useParams().carId)
  const [getCar, { loading, error, data }] = useLazyQuery(GET_CAR);
  const originalBlackbookValue = parseInt(props.bbv)
  const auth = useContext(AuthContext);

  if (values.vin.length === 17 && (
    !vinRef.current || vinRef.current && values.vin !== vinRef.current &&
    vinRef.current !== 'loading')
  ) {
    getCar({
      variables: {
        vin: values.vin,
        mileage: values.mileage,
        state: 'OK'
      }
    })
  }

  if (loading) vinRef.current = "loading";
  if (data) vinRef.current = values.vin;
  if (values.vin.length !== 17 && vinRef.current) vinRef.current = null;

  useEffect(() => {
    if (vinRef.current && data) {
      setFieldValue('year', parseInt(data.vinDecode.year)),
      setFieldValue('make', titleCase(data.vinDecode.make)),
      setFieldValue('model', titleCase(data.vinDecode.model)),
      setFieldValue('series', titleCase(data.vinDecode.series)),
      (data.vinDecode.car)
        ? setFieldValue('color', titleCase(data.vinDecode.car.color))
        : setFieldValue('color', ''),
      (data.vinDecode.value)
        ? setFieldValue('blackbookValue', data.vinDecode.value)
        : setFieldValue('blackbookValue', '')
    } else {
      setFieldValue('year', '')
      setFieldValue('make', '')
      setFieldValue('model', '')
      setFieldValue('series', '')
      setFieldValue('color', '')
    }
  }, [vinRef.current]);

  const {
    loading: programLoading,
    error: programError,
    data: dataPrograms
  } = useQuery(PROGRAMS);

  if (programLoading) return <DataState.Loading />
  if (programError) return <DataState.Error error={error} />;

  const {
    ratePrograms,
    dealerPrograms,
    automatedFees,
    titleStatuses
  } = dataPrograms;

  const handleChange = (selectedList) => {
    if (!selectedList) {
      values.automatedFeeIds = []
      setValues(values)
      return
    }

    selectedList = selectedList.map((fee) => {
      return parseInt(fee.value, 10);
    })

    values.automatedFeeIds = selectedList
    setValues(values)
  }

  const preSelectedAutomatedFees = values.automatedFeeIds.map((feeId) => {
      const fee = automatedFees.find(
        (value) => parseInt(value.id, 10) === feeId
      )
      return { value: parseInt(fee.id), label: fee.name }
    }
  )

  let list = [];

  const formattedAutomatedFees = automatedFees.map((fee) => {
    const feeList = preSelectedAutomatedFees.map((feeId) => {
      if (parseInt(fee?.id) !== feeId?.value) {
        list.push({ value: parseInt(fee.id), label: fee.name })
      }
    })
  })

  const key = 'label';

  const arrayUniqueByKey = [...new Map(list.map(item =>
    [item[key], item])).values()];

  const disabled = !auth.hasPolicy('FlooredCar', 'update');

  let submitOptions = (
    <Buttons.Standard
      type="submit"
      text="Submit"
      disabled={formSubmitting || disabled}
    />
  );

  if (disabled && auth.hasExactPolicy('FlooredCar', 'exclude_from_autopay')) {
    submitOptions = (
      <Buttons.Standard
        type="submit"
        text="Submit (Exclude from Autopay only)"
        disabled={formSubmitting}
      />
    );
  }

  return (
    <FormikForm>
      {vinRef.current && data && data.vinDecode &&
        <CarFlooredAlert
          flooredCars={data.vinDecode.flooredCars}
          floorplanRequests={data.vinDecode.floorplanRequests}
          carId={carId}
        />
      }
      {data && vinRef.current && data.vinDecode &&
        <NewBlackbookValueAlert
          originalBlackbookValue={originalBlackbookValue}
          newBlackbookValue={values.blackbookValue}
          data={data.vinDecode}
        />
      }
      <Container fluid>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="mileage"
              type="number"
              label="Mileage"
              disabled={disabled}
            />
          </Col>
          <Col lg style={{ flexGrow: '2' }}>
            <BasicForm.TextInput
              name="vin"
              type="text"
              label="VIN"
              disabled={disabled}
            />
            {vinRef.current && data &&
              <NoVinInformationAlert
                backgroundStyle={backgroundStyle}
                data={data.vinDecode}
              />
            }
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="year"
              type="number"
              label="Year"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="make"
              type="text"
              label="Make"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="model"
              type="text"
              label="Model"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="series"
              type="text"
              label="Series"
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.TextInput
              name="color"
              type="text"
              label="Color"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.TextInput
              name="blackbookValue"
              type="number"
              label="Blackbook Value"
              disabled={true}
            />
          </Col>
          <Col sm>
            <BasicForm.ProgrammaticSelect
              label="Rate Program"
              name="rateProgramId"
              options={ratePrograms}
              optionName='Rate Program'
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.ProgrammaticSelect
              label="Dealer Program"
              name="dealerProgramId"
              options={dealerPrograms}
              optionName='Dealer Program'
              disabled={disabled}
            />
          </Col>
          <Col sm>
            <BasicForm.Checkbox
              name="autopayDisabled"
              label="Exclude From Autopay?"
              disabled={
                !auth.hasExactPolicy('FlooredCar', 'exclude_from_autopay')
              }
            />
          </Col>
        </Row>
        <Row style={backgroundStyle}>
          <Col sm>
            <BasicForm.StyledLabel>Automated Fees</BasicForm.StyledLabel>
            <Select
              options={arrayUniqueByKey}
              defaultValue={preSelectedAutomatedFees}
              isMulti={true}
              onChange={handleChange}
              styles={customStyles}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Container>
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        {submitOptions}
      </div>
    </FormikForm>
  );
}

const NewBlackbookValueAlert = (props, data) => {
  const { originalBlackbookValue, newBlackbookValue } = props
  const { result } = props.data;

  if (result === 'success') {
    if ((newBlackbookValue !== "") && typeof newBlackbookValue === 'number') {
      return (
        <Alert.BlackbookValue
          originalBlackbookValue={originalBlackbookValue}
          newBlackbookValue={newBlackbookValue}
        />
      )
    } else if (newBlackbookValue === "") {
      return (
        <Alert.NoBlackbookValue />
      )
    } else {
      return null
    }
  } else {
    return null
  }
}

const CarFlooredAlert = (props) => {
  const { flooredCars, floorplanRequests, carId } = props;

  let flooredCarId;

  if (flooredCars) {
    flooredCarId = parseInt(flooredCars[0]?.id)
  } else {
    flooredCarId = null
  }

  if (!flooredCars && !floorplanRequests) return null;

  if (flooredCarId === carId) {
    return null
  } else {
    if (flooredCars.length > 0 && floorplanRequests.length > 0) {
      return <Alert.CarFloored
        flooredCars={flooredCars}
        floorplanRequests={floorplanRequests}
      />
    } else if (flooredCars.length > 0) {
      return <Alert.CarFloored
        flooredCars={flooredCars}
        loanAmount={flooredCars[0].loanAmount}
      />
    } else if (floorplanRequests.length > 0) {
      return <Alert.CarFloored
        floorplanRequests={floorplanRequests}
      />
    } else
      return null;
  }
}

const NoVinInformationAlert = (props, data) => {
  const { backgroundStyle } = props;
  const { year, make, model, car, result, errorMessage } = props.data;

  return (result === 'success')
    ? null
    : <Row style={Object.assign({}, backgroundStyle, rowStyle)}>
      <Text style={noVinAlertText}>{errorMessage}</Text>
    </Row>
}
