import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { personSchema, Form } from './Form';
import Loading from '../../elements/Loading';
import { useServerSideErrors } from '../../hooks/useServerSideErrors'

const CREATE_DEALER_PERSON = gql`
  mutation CreateDealerPerson($dealerId: Int!, $firstName: String,
    $lastName: String, $title: String, $role: String,
    $email: String, $mobilePhone: String,
    $homePhone: String, $officePhone: String, $homeStreet: String,
    $homeCity: String, $homeState: String, $homeZip: String,
    $creditScore: String) {
    createDealerPerson (
      dealerId: $dealerId
      firstName: $firstName
      lastName: $lastName
      title: $title
      role: $role
      email: $email
      mobilePhone: $mobilePhone
      officePhone: $officePhone
      homePhone: $homePhone
      homeStreet: $homeStreet
      homeCity: $homeCity
      homeState: $homeState
      homeZip: $homeZip
      creditScore: $creditScore
    ) {
      dealerPerson { id,  }
      errors { message, path }
    }
  }
`;


const New = (props) => {
  const dealerId = parseInt(useParams().id);

  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_DEALER_PERSON,
    onCompleteRedirectTo: `/dealers/${dealerId}/people`
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const NewForm = () => {
    const dealerId = useParams().id;

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Dealer Person</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              title: '',
              email: '',
              mobilePhone: '',
              homePhone: '',
              officePhone: '',
              homeStreet: '',
              homeCity: '',
              homeState: '',
              homeZip: '',
              creditScore: '',
              dealerId: parseInt(dealerId)
            }}
            validationSchema={personSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default New;
