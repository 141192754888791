import React, { Fragment, useEffect } from "react";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../../elements/forms";
import { Container, Row, Col } from "react-bootstrap";
import GlAccountSelect from "./GlAccounts";
import FeeEarnedSelect from "./FeeEarned";
import FeeAccruedInput from "./FeeAccrued";
import PrincipalAmounts from "./PrincipalAmounts";
import { PrincipalButtons } from "../../Buttons";
import TableHeaders from "./TableHeaders";
import { FeeButtons } from "../../Buttons";

const withButtonStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "10px",
};

export const smallTable = {
  fontSize: "12px",
}

const whiteBackground = {
  backgroundColor: "#F1F1F1",
}

const SingleFeeForm = ({ index, accounts, allowAddFee, allowInterval }) => {
  const { values, setValues, errors } = useFormikContext();
  const termLinesAttributes = values.termsAttributes[index].termLinesAttributes;

  return (
    <Fragment>
      <div style={allowAddFee ? withButtonStyle : {}}>
        <h5>Fees added on 1st Day of Term</h5>
        {allowAddFee && (
          <FeeButtons values={values} index={index} setValues={setValues} />
        )}
      </div>
      <Container style={smallTable}>
        <TableHeaders
          termLinesAttributes={termLinesAttributes}
          values={values}
          index={index}
          allowInterval={allowInterval}
          columnStyle={columnStyle}
        />
        <Row>
          <Col style={Object.assign({}, smallTable, columnStyle.smallDiv)}/>
          <Col style={Object.assign({}, smallTable, whiteBackground, columnStyle.smallDiv)} />
          <GlAccountSelect
            termLinesAttributes={termLinesAttributes}
            index={index}
            columnStyle={columnStyle}
            accounts={accounts}
            values={values}
          />
          {allowInterval && <Col style={Object.assign({}, columnStyle.smallDiv, whiteBackground)}/>}
        </Row>
        <Row>
          <Col style={Object.assign({}, smallTable, columnStyle.text, columnStyle.smallDiv)}>How is this fee earned?</Col>
          <Col style={Object.assign({}, smallTable, whiteBackground, columnStyle.smallDiv)}/>
          <FeeEarnedSelect
            termLinesAttributes={termLinesAttributes}
            index={index}
            columnStyle={columnStyle}
            values={values}
          />
          {allowInterval && <Col style={Object.assign({}, columnStyle.smallDiv, whiteBackground)}/>}
        </Row>
        {!allowInterval && (
          <Row>
          <Col style={Object.assign({}, smallTable, columnStyle.text, columnStyle.smallDiv)}>
              What day within the term does the fee accrue?
            </Col>
            <Col style={Object.assign({}, smallTable, whiteBackground, columnStyle.smallDiv)} />
            <FeeAccruedInput
              termLinesAttributes={termLinesAttributes}
              index={index}
              columnStyle={columnStyle}
              values={values}
            />
          </Row>
        )}
        <PrincipalAmounts
          termLinesAttributes={termLinesAttributes}
          index={index}
          columnStyle={columnStyle}
          values={values}
          allowInterval={allowInterval}
        />
        <Row>
          <Col style={columnStyle.noBorder}>
            <PrincipalButtons
              values={values}
              index={index}
              setValues={setValues}
            />
          </Col>
        </Row>
        *Leave the value blank for all Principal Values, or for the last
        principal value
      </Container>
    </Fragment>
  );
};

export const columnStyle = {
  select: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
  },
  text: {
    paddingTop: "25px",
  },
  input: {
    width: "70%",
  },
  noBorder: {
    borderBottom: "none",
  },
  smallDiv: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  dropdownStyle: {
    width: "100%",
    fontSize: "12px",
    padding: '1px',
    margin: '0px',
  },
  whiteBackground: {
    ...whiteBackground
  }
};

export default SingleFeeForm;
