import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { divStyle } from '../../Dealers/Accounts/Withdraw/AdvanceType/AdvanceType';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import { TITLE_STATUSES } from '../../../queries/indexQueries';
import DataState from '../../DataState';
import AccountSelect from '../../../elements/AccountSelect';

export const undoFundingSchema = yup.object({
  bankDate: yup.string().required('Required'),
  type: yup.string().required('Required'),
  accountId: yup.number().required('Required'),
});

export const Form = ({flooredCar, handleSubmit, handleClose, isSubmitting}) => {
  const { setFieldValue, values, errors, isValid } = useFormikContext();
  const { loading, error, data } = useQuery(TITLE_STATUSES);

  useEffect(() => {
    if (values.accountId !== null && values.accountId !== '') {
      setFieldValue('accountId', parseInt(values.accountId))
    }
  }, [values.accountId])

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { titleStatuses } = data;

  return (
    <>
      <BasicForm.TextInput
        name="bankDate"
        type="date"
        label="Payment Received On"
      />
      <div style={divStyle}>
        <BasicForm.StyledLabel>
          Payment Type
        </BasicForm.StyledLabel>
        <BasicForm.Radio name="type" type="radio" label="Check" value="Check" />
        <BasicForm.Radio name="type" type="radio" label="ACH" value="ACH" />
        <BasicForm.Radio
          name="type"
          type="radio"
          label="Wire Transfer"
          value="Wire"
        />
        <BasicForm.InlineStyledErrorMessage>
          <ErrorMessage name='txnType'/>
        </BasicForm.InlineStyledErrorMessage>
      </div>
      <AccountSelect
        scopes={['bank']}
        label="Account Funds Deposited to"
        values={values}
        defaultSpecial="payments_bank"
      />
      <BasicForm.TextInput
        name="reference"
        label="Payment Reference"
      />
      <BasicForm.TextArea
        name="memo"
        label="Payment Memo"
      />
      <BasicForm.ProgrammaticSelect
        options={titleStatuses}
        firstOption={flooredCar.titleStatus}
        name="titleStatusId"
        label="New Title Status"
        optionName='Title Status'
      />
      <div style={Spacing.buttonRow}>
        <Buttons.ModalCancel
          type="cancel"
          text="Cancel"
          handleClose={handleClose}
        />
        <Buttons.Standard
          type="submit"
          onClick={() => handleSubmit(values)}
          text="Submit"
          disabled={!isValid || isSubmitting}
        />
      </div>
    </>
  );
}
