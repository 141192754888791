import React from 'react';
import { setDate, setTime } from 'components/src/utils/dateHelpers';

const optionalDate = value => value && setDate(value);

export const columnDefinitions = ({entries, columns}) => {
  const definitions = [
    {
      key: 'reportStartOn',
      label: 'Report Start On',
      content: (({entry}) => (
        optionalDate(entry.params?.['start-on'] || entry.reportStartOn)
      )),
    },
    {
      key: 'reportEndOn',
      label: 'Report End On',
      content: (({entry}) => (
        optionalDate(entry.params?.['end-on'] || entry.reportEndOn)
      )),
    },
    {
      key: 'report-period',
      label: 'Report Period',
      content: ({entry}) => optionalDate(entry.params?.['report-period']),
    },
    {
      key: 'compare-start-on',
      label: 'Comparison Start',
      content: ({entry}) => optionalDate(entry.params?.['compare-start-on'])
    },
    {
      key: 'compare-end-on',
      label: 'Comparison End',
      content: ({entry}) => optionalDate(entry.params?.['compare-end-on'])
    },
    {
      key: 'annual-review-start-on',
      label: 'Annual Review From',
      content: ({entry}) => optionalDate(entry.params?.['annual-review-start-on'])
    },
    {
      key: 'annual-review-end-on',
      label: 'Annual Review To',
      content: ({entry}) => optionalDate(entry.params?.['annual-review-end-on'])
    },
    {
      key: 'ucc-expires-start-on',
      label: 'UCC Expiration From',
      content: ({entry}) => optionalDate(entry.params?.['ucc-expires-start-on'])
    },
    {
      key: 'ucc-expires-end-on',
      label: 'UCC Expiration To',
      content: ({entry}) => optionalDate(entry.params?.['ucc-expires-end-on'])
    },
    {
      key: 'createdAt',
      label: 'Created At',
      sort: 'desc',
      content: ({value}) => setTime(value),
    },
    {
      key: 'createdBy',
      label: 'Created By',
      value: ({value}) => value.fullName,
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      value: (({entry}) => {
        if (entry.fileUrl) {
          return (
            <>
              <a href={entry.fileUrl}>Click to Download</a>
              {entry.pdfUrl &&
                <>
                  {' | '}
                  <a href={entry.pdfUrl} target="_blank">PDF Download</a>
                </>
              }
            </>
          );
        } else {
          return 'File Not Available';
        }
      })
    }
  ];

  if (columns) {
    return definitions.filter(column => columns.includes(column.key));
  } else {
    return definitions.filter(column => {
      if (['createdAt', 'createdBy', 'actions'].includes(column.key)) {
        return true;
      }

      if ('reportStartOn' === column.key) {
        return entries.find(entry => (
          entry.params?.['start-on'] || entry.reportStartOn
        ));
      }

      if ('reportEndOn' === column.key) {
        return entries.find(entry => (
          entry.params?.['end-on'] || entry.reportEndOn
        ));
      }

      return entries.find(entry => entry.params?.[column.key]);
    });

    return definitions;
  }
};
