import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import * as TimeHelper from '../../../utils/timeHelpers';
import moment from 'moment';

const today = new Date();

export const saleTimeSchema = yup.object({
  name: yup.string().required('Required'),
  type: yup.string().required('Required'),
  occursOn: yup.date().min(today, 'The date must be in the future'),
});

export const Form = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    (values.type === 'Weekly')
      ? setFieldValue('occursOn', '')
      : setFieldValue('dayOfWeek', '');

  }, [values.type])

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.Select
        name="type"
        label="Type"
      >
      <option value="">Select a Type</option>
      <option>Once</option>
      <option>Weekly</option>
      </BasicForm.Select>
      {
        values.type==='Weekly' &&
        <BasicForm.Select
          name="dayOfWeek"
          label="Day of the Week"
        >
        <option value="">Select a Day of the Week</option>
        <option value="0">Sunday</option>
        <option value="1">Monday</option>
        <option value="2">Tuesday</option>
        <option value="3">Wednesday</option>
        <option value="4">Thursday</option>
        <option value="5">Friday</option>
        <option value="6">Saturday</option>
        </BasicForm.Select>
      }
      {
        values.type ==='Once' &&
        <BasicForm.TextInput
          name="occursOn"
          type="date"
          label="Occurs On"
        />
      }
      { (values.occursOn || values.dayOfWeek) &&
        [
          <BasicForm.TextInput
            type="time"
            name="saleStartAt"
            label="Start Time"
            key="saleStartAt"
          />,
          <BasicForm.TextInput
            type="time"
            name="saleEndAt"
            label="End Time"
            key="saleEndAt"
          />
        ]
      }
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}
