import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_KPI_YIELD } from './queries';
import moment from 'moment';
import Input, { InputCell } from './Input';

const FLOATS = ['netYieldGoal', 'grossYieldGoal'];

const InputRow = ({ kpi, refetch }) => {
  const [values, setValues] = useState({...kpi});
  const [updateKPI] = useMutation(UPDATE_KPI_YIELD, { onCompleted: refetch });

  const setField = (field, value) => {
    setValues({ ...values, [field]: value });
  }

  const save = () => {
    let changed = false;

    FLOATS.forEach(field => {
      values[field] = values[field] ? parseFloat(values[field]) : null
      if (values[field] !== kpi[field]) { changed = true; }
    });

    if (changed) {
      updateKPI({ variables: {...values} });
    }
  }

  const inputProps = { kpi, values, setField, save };

  return (
    <tr>
      <td>{values.market?.name || 'CORPORATE'}</td>
      <td>{values.year}</td>
      <td>{(values.period / 3).toFixed(0)}</td>
      <InputCell field="grossYieldGoal" type="percent" {...inputProps} />
      <InputCell field="netYieldGoal" type="percent" {...inputProps} />
      <td>
        {kpi.yieldsUpdatedBy && (
          <span>
            {moment(kpi.yieldsUpdatedAt).format('M/D/YY')}-
            {kpi.yieldsUpdatedBy.initials}
          </span>
        )}
      </td>
    </tr>
  );
};

const InputTable = ({ kpis, refetch }) => {
  const quarterKpis = kpis.filter(kpi => 0 == kpi.period % 3);

  return (
    <table className="kpi-input">
      <thead>
        <tr>
          <th>Market</th>
          <th>Year</th>
          <th>Quarter</th>
          <th>Gross Yield</th>
          <th>Net Yield</th>
          <th>Last Update</th>
        </tr>
      </thead>
      <tbody>
        {quarterKpis.map(kpi => (
          <InputRow key={kpi.id} kpi={kpi} refetch={refetch} />
        ))}
      </tbody>
    </table>
  );
};

const InputYieldGoals = () => {
  return (
    <Input title="Input KPI Yield Goals" InputTable={InputTable} quartersOnly />
  );
}

export default InputYieldGoals;
