import React from 'react';
import { useParams } from 'react-router-dom';
import Info from '../Info';
import Notes from '../../Notes';
import { VENDOR_NOTES } from '../queries';

const VendorNotes = () => {
  const id = parseInt(useParams().id);

  return (
    <>
      <Info />
      <Notes
        query={VENDOR_NOTES}
        ownerType="vendor"
        ownerId={id}
      />
    </>
  );
};

export default VendorNotes;
