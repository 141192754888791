import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useAlert } from '../../../hooks/useAlert';
import { Form, saleTimeSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import { AUCTION } from '../Info';

const NEW_SALE_TIME = gql`
  mutation CreateSaleTime($name: String!, $auctionId: Int!, $type: String!, $occursOn: ISO8601Date,
    $dayOfWeek: Int, $saleStartAt: String, $saleEndAt: String) {
    createSaleTime(
      name: $name
      auctionId: $auctionId
      type: $type
      occursOn: $occursOn
      dayOfWeek: $dayOfWeek
      saleStartAt: $saleStartAt
      saleEndAt: $saleEndAt
    ) {
      saleTime { id }
      errors { message, path }
    }
  }
 `

 const NewSaleTime = (props) => {
   const [redirect, setRedirect] = useState(false);
   const auctionId = parseInt(useParams().id);
   const history = useHistory();
   const previousRoute = props.location.state?.previousRoute;

   const [newSaleTime, { loading: mutationLoading, error: mutationError }] = useMutation(
    NEW_SALE_TIME,
     {
       awaitRefetchQueries: true,
       refetchQueries: [{ query: AUCTION, variables: { id: auctionId }}],
       onCompleted(variables) {
        history.push({
          pathname: previousRoute || `/auctions/${auctionId}`,
          state: { alert: true, componentType: 'sale time', type:'created' }
        });
       }
     })

   const handleSubmit = (values) => {
    values.dayOfWeek = (values.dayOfWeek)
      ? parseInt(values.dayOfWeek)
      : null;
    values.occursOn = (values.occursOn)
      ? values.occursOn
      : null;

    newSaleTime({ variables: values })
   };

   const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/auctions/${auctionId}`,
          state: { alert: true, type: 'created' }
        }}
      />
    }

     return (
       <>
         <div style={Spacing.formWrapper}>
           <div style={Spacing.formHeader}>
             <h1 style={Typography.formHeader}>Create A Sale Time</h1>
           </div>
           <Formik
             initialValues={{
               name: '',
               auctionId: auctionId,
               type: '',
               occursOn: '',
               dayOfWeek: '',
               saleStartAt: '',
               saleEndAt: '',
             }}
             validationSchema={saleTimeSchema}
             onSubmit={handleSubmit}
           >
             <Form />
           </Formik>
         </div>
       </>
     )
    }

  return (
    <>
    <Helmet>
      <title>New Sale Time</title>
    </Helmet>
    <div style={Spacing.formContainer}>
      <NewForm />
    </div>
    </>
  );
};

export default NewSaleTime;
