export const classNames = (errors, touched, name) => {
  if ((errors['rateProgramChangesAttributes'] && errors['rateProgramChangesAttributes'][0][name]) && (touched['rateProgramChangesAttributes'] && touched['rateProgramChangesAttributes'][0][name])) {
    return 'form-control is-invalid'
  } else if (touched['rateProgramChangesAttributes'] && touched['rateProgramChangesAttributes'][0][name]) {
    return 'form-control is-valid'
  }
  else {
    return 'form-control'
  }
}
