import React from 'react';
import * as yup from 'yup';
import { useQuery, gql } from '@apollo/client';
import { ACCOUNT_SELECTS } from '../../../queries/selects';
import { Form as FormikForm } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import * as StringHelpers from '../../../utils/stringHelpers';
import { Spacing } from '../../../styles';
import DataState from '../../DataState';
import featureEnabled from 'components/src/utils/featureEnabled';

export const accountSchema = yup.object({
  gl: yup
    .string()
    .required('Required'),
  name: yup
    .string()
    .required('Required')
    .max(60, 'Maximum length for branch name is 60 characters'),
  accountGroupId: yup
    .number()
    .required('Required')
    .typeError('You must select an Account Group')
});

export const Form = () => {
  const { loading, error, data: selectData } = useQuery(ACCOUNT_SELECTS);

  if (loading && !selectData) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="gl"
        type="text"
        label="GL"
      />
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name"
      />
      <BasicForm.Select
        name="special"
        type="text"
        label="Special"
      >
        <option value="">Select Special Purpose of Account</option>
        { specialOptions(selectData.specialAccounts) }
      </BasicForm.Select>
      {
        selectData &&
          <>
            <BasicForm.ProgrammaticSelect
              name="parentId"
              label="Parent GL"
              optionName="Parent Account (If Required)"
              options={selectData.accounts}
            />
            <BasicForm.ProgrammaticSelect
              name="accountGroupId"
              label="Account Group"
              optionName="Account Group"
              options={selectData.accountGroups}
            />
          </>
      }
      {
        featureEnabled('doubleEntry', 'checkPrinting') &&
          <BasicForm.TextInput
            name="nextCheckNumber"
            type="number"
            label="Next Check Number"
          />
      }
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}

const specialOptions = (specialAccounts) => {
  return specialAccounts.map((account) => {
    return (
      <option key={account.key} value={account.key}>{account.name}</option>
    );
  })
}
