import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { bankAccountSchema, Form } from './Form';
import DataState from '../DataState';
import * as Helper from './functions';
import Loading from '../../elements/Loading';
import { useServerSideErrors } from '../../hooks/useServerSideErrors'

const CREATE_BANK_ACCOUNT = gql`
  mutation CreateBankAccount($ownerType: String, $ownerId: Int, $accountName: String!,
    $bankName: String, $routingNumber: String, $accountNumber: String, $type: String) {
    createBankAccount(
      ownerType: $ownerType
      ownerId: $ownerId
      accountName: $accountName
      bankName: $bankName
      routingNumber: $routingNumber
      accountNumber: $accountNumber
      type: $type
    ) {
      bankAccount { id }
      errors { message, path }
    }
  }
 `

const NewBankAccount = (props) => {
  const ownerId = parseInt(useParams().id);
  let { ownerType, gqlQuery } = props;
  const currentPath = useLocation().pathname;
  const redirectPath = currentPath.split('/new')[0];

  if (!ownerType) ownerType = Helper.setOwnerType();

  const customSubmitHandler = (values) => {
    if (values.bankAccount && values.bankAccount === '') values.bankAccount = null;
  }

  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_BANK_ACCOUNT,
    onCompleteRedirectTo: redirectPath,
    customSubmitHandler: customSubmitHandler,
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const NewForm = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create a Bank Account</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              ownerType,
              ownerId,
              accountName: '',
              bankName: '',
              routingNumber: '',
              accountNumber: '',
            }}
            validationSchema={bankAccountSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default NewBankAccount;
