import React, { useState } from 'react';
import * as yup from 'yup';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as BasicForm from 'components/src/elements/forms';
import { Spacing } from 'components/src/styles';
import * as Buttons from 'components/src/elements/buttons';
import { CustomizableDropzone } from 'components/src/elements/Dropzone';

export const adminDocumentSchema = yup.object({
  name: yup
    .string()
    .required('Required')
    .max(50, 'Maximum length for Document Name is 50 characters'),
  file: yup.mixed().required('Required'),
});

export const editAdminDocumentSchema = yup.object({
  name: yup
    .string()
    .required('Required')
    .max(50, 'Maximum length for Document Name is 50 characters'),
});

const Form = () => {
  const { values, isValid, setFieldValue } = useFormikContext();

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="Name of Entry"
      />
      <BasicForm.StyledLabel>
        Upload Document
      </BasicForm.StyledLabel>
      <CustomizableDropzone
        accept=".pdf, .doc, .docx"
        description="Drag and drop file here, or click to choose file."
        setFiles={files => setFieldValue('file', files?.[0]) }
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard
          type="submit"
          text="Submit"
          disabled={!isValid}
        />
      </div>
    </FormikForm>
  );
};

export default Form;
