import { gql } from '@apollo/client';

const REVENUE_FIELDS = `
  totalRevenueGoal
  totalRevenueActual
`;

const ACTIVATIONS_FIELDS = `
  activationsGoal
  activationsActual
`;

const DELINQUENT_FIELDS = `
  delinquentPercentGoal
  delinquentPercentActual
  delinquentTimesExceeded
`;

const WRITEOFF_FIELDS = `
  writeoffPercentGoal
  writeoffPercentActual
  writeoffAmount180Days
`;

const WAIVERS_FIELDS = `
  waiversGoal
  waiversActualMonth
  waiversActualYtd
`;

const EBTDA_FIELDS = `
  ebtdaGoal
  ebtdaActual
`;

const GROSS_YIELD_FIELDS = `
  grossYieldGoal
  grossYieldActual
`;

const NET_YIELD_FIELDS = `
  netYieldGoal
  netYieldActual
`;

const AUDIT_FOUND_FIELDS = `
  auditFoundPercentGoal
  auditFoundPercentActual
`;

const FLOORED_CARS_FIELDS = `
  flooredCarsGoal
  flooredCarsActual
`;

const ALL_FIELDS = `
  ${REVENUE_FIELDS}
  ${ACTIVATIONS_FIELDS}
  ${DELINQUENT_FIELDS}
  ${WRITEOFF_FIELDS}
  ${WAIVERS_FIELDS}
  ${EBTDA_FIELDS}
  ${GROSS_YIELD_FIELDS}
  ${NET_YIELD_FIELDS}
  ${AUDIT_FOUND_FIELDS}
  ${FLOORED_CARS_FIELDS}
`;

export const FIELD_MAP = {
  totalRevenue: REVENUE_FIELDS,
  activations: ACTIVATIONS_FIELDS,
  delinquentPercent: DELINQUENT_FIELDS,
  writeoffPercent: WRITEOFF_FIELDS,
  waivers: WAIVERS_FIELDS,
  ebtda: EBTDA_FIELDS,
  grossYield: GROSS_YIELD_FIELDS,
  newYield: NET_YIELD_FIELDS,
  auditFoundPercent: AUDIT_FOUND_FIELDS,
  flooredCars: FLOORED_CARS_FIELDS,
};

const KPI_RESULT_VARIABLES = `
  $marketIds: [ID!]
  $year: Int!
  $startQuarter: Int!
  $endQuarter: Int!
`;

const KPI_RESULT_ARGUMENTS = `
  marketIds: $marketIds
  year: $year
  startQuarter: $startQuarter
  endQuarter: $endQuarter
`;

export const kpiResultsQuery = fields => (
  gql`
    query KPIResults(
      ${KPI_RESULT_VARIABLES}
    ) {
      kpiResults(
        ${KPI_RESULT_ARGUMENTS}
      ) {
        ${fields || ALL_FIELDS}
      }
      kpiYears
    }
  `
);
