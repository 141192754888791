import React, { useEffect } from 'react';
import { Form as FormikForm, useFormikContext } from 'formik';
import DebitInput from './DebitInput'
import CreditInput from './CreditInput'

export const DebitAndCreditInputs = (props) => {
  const { indexValue } = props;
  const { values } = useFormikContext();

  return (
    <>
      <DebitInput indexValue={indexValue} />
      <CreditInput indexValue={indexValue} />
    </>
  );
};

export default DebitAndCreditInputs;
