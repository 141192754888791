import React, { useState, useEffect, useContext } from 'react';
import { useParams, useRouteMatch, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import AuctionRoutes from '../../routes/AuctionRoutes';
import { titleCase } from '../../utils/stringHelpers';
import {
  faEdit,
  faMoneyCheckAlt,
  faUniversity,
  faKey
} from '@fortawesome/free-solid-svg-icons';
import { faCars } from '@fortawesome/pro-solid-svg-icons'
import {
  faCommentAltLines,
  faUsers,
  faTriangle,
} from '@fortawesome/pro-regular-svg-icons'
import DataState from '../DataState';
import SubNav from '../../elements/SubNav'
import Cars from './AuctionCars'
import { setUniqueAlertData } from '../../utils/setAlertData';
import { useAlert } from '../../hooks/useAlert';
import BasicAlert from '../../elements/BasicAlert';
import AuthContext from "../../contexts/AuthContext";

const AUCTION = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      id
      name
    }
  }
`;

const useURLQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Show = (props) => {
  const auth = useContext(AuthContext);
  let checkRefresh = useURLQuery();
  const forceRefresh = checkRefresh.get("forceRefresh");
  if(forceRefresh) {
    window.location.assign(useLocation().pathname);
  }
  const { id } = useParams();
  const route = useRouteMatch();
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  const { loading, error, data } = useQuery(AUCTION, { variables: { id } });
  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { name } = data.auction;

  const title = {
    link: `/auctions/${id}`,
    text: name,
  }

  const navLinks = [
    {
      link: `/auctions/${id}/edit`,
      icon: faEdit,
      text: 'Edit',
      resource: 'Auction',
      action: 'update',
    },
    {
      link: `/auctions/${id}/cars`,
      icon: faCars,
      text: 'Cars'
    },
    {
      link: `/auctions/${id}/FPRs`,
      icon: faMoneyCheckAlt,
      text: 'FPRs'
    },
    {
      link: `/auctions/${id}/contacts`,
      icon: faUsers,
      text: 'Contacts'
    },
    {
      link: `/auctions/${id}/notes`,
      icon: faCommentAltLines,
      text: 'Notes'
    },
    {
      link: `/auctions/${id}/bank-accounts`,
      icon: faUniversity,
      text: 'Bank Accounts'
    },
    {
      link: `/auctions/${id}/auction-tokens`,
      icon: faKey,
      text: 'Auction API Tokens'
    }
  ]

  if (auth.hasPolicy('Delta', 'view')) {
    navLinks.push(
      {
        link: `/auctions/${id}/deltas`,
        icon: faTriangle,
        text: 'Deltas',
      }
    )
  }

  return (
    <div>
      <Helmet>
        <title>{titleCase(name)}</title>
      </Helmet>
      <SubNav title={title} navLinks={navLinks} />
      <AuctionRoutes />
      {
        route.isExact &&
        <>
          <Cars {...props} />
        </>
      }
    </div>
  );
}

export default Show;
