import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Formik, Form as FormikForm } from 'formik';
import { Spacing } from '../../../styles';
import * as Buttons from '../../../elements/buttons';
import * as BasicForm from '../../../elements/forms';
import { titleCase } from '../../../utils/stringHelpers';
import DataState from '../../DataState';
import * as Alert from '../../../elements/FloorCarAlerts';

const ACCEPT_FLOORPLAN_REQUEST = gql`
  mutation AcceptFloorplanRequest($id: ID!, $memo: String) {
    acceptFloorplanRequest(
      id: $id
      memo: $memo
    ) {
      floorplanRequest {
        id,
        status,
        memo,
        flooredCar { id }
        dealerLine {
          id
          dealer {
            id
          }
        }
      },
      errors {
        message
        path
      }
    }
  }
`

const REJECT_FLOORPLAN_REQUEST = gql`
  mutation RejectFloorplanRequest($id: ID!, $memo: String) {
    rejectFloorplanRequest(
      id: $id
      memo: $memo
    ) {
      floorplanRequest {
        id,
        status,
        memo,
        flooredCar { id },
        dealerLine {
          id
          dealer {
            id
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`

const GET_CAR = gql`
  query VinDecode($vin: String!, $mileage: Int!, $state: String!) {
    vinDecode(vin: $vin, mileage: $mileage, state: $state) {
      result
      errorMessage
      year
      make
      model
      series
      value
      car {
        color
      }
      flooredCars {
        id
        vin
        loanAmount
        status
        payoffOn
        loanDate
        dealer { name }
      }
      floorplanRequests {
        id
        vin
        loanAmount
        dealerLine { dealer { name } }
      }
    }
  }
`;

const CREATE_NOTE = gql`
  mutation CreateNote(
    $ownerId: Int!
    $ownerType: String!
    $subject: String!
    $note: String!
  ) {
    createNote(
      ownerId: $ownerId
      ownerType: $ownerType
      subject: $subject
      note: $note
    ) {
      note {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const ReviewForm = ({
  setShowModal,
  fprId,
  reviewType,
  handleClose,
  serverSideErrors,
  setServerSideErrors,
}) => {
  const [formSubmitting, setFormSubmitting] = useState(false);

  const REVIEW_MUTATION = reviewType === 'Approve' ?
    ACCEPT_FLOORPLAN_REQUEST :
    REJECT_FLOORPLAN_REQUEST
  const [reviewFpr] = useMutation(REVIEW_MUTATION, {
    onCompleted(data) {
      const key = Object.keys(data)[0];
      const errors = data[key].errors;

      if (errors?.length) {
        setFormSubmitting(false);
        let errorString = '';
        let errorName = '';
        let errorMessage = '';
        errors.forEach((error, index) => {
          errorName = error.path[1];
          errorMessage = error.message;
          const separator = index > 0 ? ', ' : ''
          errorString += `${separator} ${titleCase(errorName)} ${errorMessage}`
        })
        setServerSideErrors(errorString)
        setShowModal(false)
      } else {
        if (reviewType === 'Approve') {
          createNote({ variables: {
            ownerType: "Dealer",
            ownerId: parseInt(data.acceptFloorplanRequest.floorplanRequest.dealerLine.dealer.id),
            subject: data.acceptFloorplanRequest.floorplanRequest.status,
            note: data.acceptFloorplanRequest.floorplanRequest.memo
          }})
        } else if (reviewType === 'Deny') {
          createNote({ variables: {
            ownerType: 'Dealer',
            ownerId: parseInt(data.rejectFloorplanRequest.floorplanRequest.dealerLine.dealer.id),
            subject: data.rejectFloorplanRequest.floorplanRequest.status,
            note: data.rejectFloorplanRequest.floorplanRequest.memo
          }})
        }
      }
    }
  })

  const [createNote] = useMutation(CREATE_NOTE,
    {
      onCompleted() {
        setShowModal(false)
        window.location.reload()
      }
    }
  )

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    reviewFpr({ variables: values })
  }

  return (
    <>
      <Formik
        initialValues={{
          id: fprId,
          memo: '',
        }}
        onSubmit={handleSubmit}
      >
        <FormikForm>
          <BasicForm.TextArea
            name="memo"
            type="text"
            label="Memo"
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel
              type="cancel"
              text="Cancel"
              handleClose={handleClose}
            />
            <Buttons.Standard
              type="submit"
              text={reviewType}
              disabled={formSubmitting}
            />
          </div>
        </FormikForm>
      </Formik >
    </>
  )
}

const Review = ({
  showModal,
  setShowModal,
  fprId,
  reviewType,
  setServerSideErrors,
  fprMileage,
  fprVin
}) => {
  const handleClose = () => {
    setShowModal(false);
  }

  const { loading, error, data } = useQuery(GET_CAR, {
    variables: { vin: fprVin, mileage: parseInt(fprMileage), state: 'OK' }
  });

  let currentData = null;

  if (loading && (currentData !== data || !data)) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  currentData = data;

  const titleText = `${reviewType} FPR`

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={showModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {titleText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data &&
          <CarFlooredAlert
            flooredCars={data.vinDecode.flooredCars}
          />
        }
        <ReviewForm
          setShowModal={setShowModal}
          fprId={fprId}
          reviewType={reviewType}
          handleClose={handleClose}
          setServerSideErrors={setServerSideErrors}
        />
      </Modal.Body>
    </Modal>
  )
}

const CarFlooredAlert = (props) => {
  const { flooredCars } = props;

  if (!flooredCars) return null;

  if (flooredCars.length > 0) {
    return <Alert.CarFloored
      flooredCars={flooredCars}
      loanAmount={flooredCars[0].loanAmount}
    />
  } else {
    return null;
  }
}

export default Review;
