import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import moment from 'moment'
import './datatable.css';
import { Typography, Spacing, Colors } from '../styles';
import $ from 'jquery';
import { DataTable as DataTableImport } from 'datatables.net';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthLink from '../components/AuthLink';
import DataState from '../components/DataState';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router';
import * as Buttons from './buttons';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { Pagination } from './Pagination';
import { OptionsBar } from './Pagination/OptionsBar';

$.DataTable = DataTableImport

require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-responsive');
require('datatables.net-responsive-dt');
require('datatables.net-searchpanes');
require('datatables.net-select');
require('datatables.net-colreorder-dt');

import {
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons'

const LOAD_SETTINGS = gql`
  query($page: String!) {
  displaySettingForPage(
    page: $page
  ) {
    id
    settings
  }
}
`
const SAVE_SETTINGS = gql`
mutation SaveDisplaySettingForPage($page: String!, $settings: JSON!) {
    saveDisplaySettingForPage(
      page: $page
      settings: $settings
    ) {
      displaySetting { id, settings, page }
    }
  }
`

const textForCreateStyle = {
  fontSize: '16px',
  paddingLeft: '10px'
}

let currentIndex;
let currentDirection;
let duplicate = false;

const DataTable = (props) => {
  let {
    dataSet,
    columns,
    pageTitle,
    userId,
    newRoute,
    subHeader,
    orderRows,
    addFilter,
    addColvis,
    setShowHidden,
    showHidden,
    unhideable,
    uncreatable,
    tableId,
    handleShowModal,
    handleShowEditModal,
    textForCreate,
    toggleData,
    toggleDataLabel,
    toggleDataState,
    lazyLoad,
    handlePaginate,
    handleColumnSort,
    handleSearch,
    handleFilter,
    handleExportToExcel,
    currentPage,
    paginateLimit,
    setPaginateLimit,
    paginateItemsCount,
    noSortColumns,
    allowExportToExcelAsEagerTable,
    refresh,
    order,
    setSorting,
    filterButtonText,
    columnDefs,
  } = props;

  if (!tableId) tableId = '#data-table';
  const divId = tableId.slice(1);

  const history = useHistory()

  const page = useLocation().pathname

  const id = parseInt(userId, 10)
  let ordering = true;
  if (orderRows === false) ordering = false;

  const { loading, error, data } = useQuery(LOAD_SETTINGS, {
    variables: {
      userId: id,
      page: page
    }
  });

  const [saveSettings] = useMutation(SAVE_SETTINGS)

  const createRoute = newRoute || `${page}/new`;

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;


  const { displaySettingForPage } = data;
  let displaySettings = null;
  if (displaySettingForPage) {
    displaySettings = displaySettingForPage.settings;
  }

  let colReorder = (handlePaginate)
    ? false
    : true;

  let table;
  $(document).ready(function () {
    const buttons = [];
    let language = {};

    if (addFilter) {
      buttons.push({
        extend: 'searchPanes',
        text: 'Filter',
        cascadePanes: false,
        className: 'btn toggle-vis filter',
        config: {
          layout: 'columns-1',
          cascadePanes: true,
        }
      }
      )

      language = {
        searchPanes: {
          collapse: { 0: 'Filter Options', _: 'Filter Options (%d)' }
        }
      }
    }

    if (addColvis) {
      buttons.push({
        extend: 'colvis',
        text: 'Show/Hide Columns',
        className: 'btn toggle-vis',
      })

      buttons.push({
        text: 'Reset Settings',
        className: 'btn toggle-vis reset',
        action: function () {
          table.state.clear();
          const clearedTableState = table.state();
          saveTableSettings(clearedTableState)
          window.location.reload();
        }
      })
    }

    if (allowExportToExcelAsEagerTable) {
      buttons.push({
        text: 'Export to Excel',
        className: 'btn btn-primary excel-button filter toggle-vis',
        action: function () {

          allowExportToExcelAsEagerTable(table.rows(
            { filter: 'applied' }).data());
        }
      }
      )
    }

    columnDefs = columnDefs
      ? columnDefs
      : [
      { responsivePriority: 1, targets: [0, 1] },
      { responsivePriority: 2, targets: -1 },
    ]

    // Right now, only the auctions/cars table is using this client side
    // filter for funded_date column. If more tables need to use this, we need
    // to create a dynamic function that sets necessary target index for columns below.
    if (addFilter) columnDefs.push(
      { searchPanes: { show: true }, targets: [9] })
    // // //

    if (noSortColumns) columnDefs.push(
      { orderable: false, targets: noSortColumns }
    )

    let lengthMenu = (handlePaginate)
      ? [10, 25, 50]
      : [[10, 25, 50, -1], [10, 25, 50, 'All']];

    table = $(tableId).DataTable(
      {
        columns,
        buttons,
        language,
        ordering,
        columnDefs,
        colReorder,
        lengthMenu,
        order: order || [],
        processing: true,
        dom: '<lf><t><ip>',
        data: dataSet,
        pagingType: "full_numbers",
        retrieve: true,
        responsive: true,
        stateSave: (order && order.length > 0 ? false : true),
        stateLoadParams: function (settings, data) {
          if (displaySettings) {
            if (data.searchPanes) data.searchPanes.selectionList = [];
            data.search.search = '';
            data.ColReorder = displaySettings.ColReorder;
            data.columns = displaySettings.columns;
            data.order = displaySettings.order;
            data.length = 10;
            data.start = 0;
          }
        }
      }
    );

    const dataTablesFilter = $(`div${tableId}_filter`)
    table.buttons().container()
      .appendTo(dataTablesFilter);

    // This filter menu gets duplicated if the user clicks dealers from nav if
    // already on the page. This function stops that from happening, but now
    // removes the datatables length option. TODO: This should be worked out.
    if (dataTablesFilter.length > 1) dataTablesFilter[0].remove();

    // stop table extension from duplicating when refreshing table data
    const pagination = $(`div${tableId}_paginate`);
    const info = $(`div${tableId}_info`);
    const showLength = $(`div${tableId}_length`);
    if (pagination && pagination.length > 1) pagination[1].remove();
    if (info && info.length > 1) info[1].remove();
    if (showLength && showLength.length > 1) {
      showLength[1].remove()
    }

    // Adds close button to filter options without duplication
    table.on('buttons-action', function (e, buttonApi, dataTable, node, config) {
      if (config.hasOwnProperty('_panes')) {
        if (!$(".dtsp-closePanes")[0]) {
          $('.dtsp-titleRow').prepend(
            $('<button class="dtsp-closePanes">Close</button>').click(function () {
              table.button().popover(false);
            })
          );
        }
      }
    });

    if (handleColumnSort) {
      table.on('order.dt', function (e) {
        let order = table.order();

        const columnIndex = order[0][0];
        const sortDirection = order[0][1];

        if (currentDirection === sortDirection &&
          currentIndex === columnIndex) {
          duplicate = true;
        } else duplicate = false;

        if (duplicate) return;

        const currentFilters = (handleFilter && handleFilter[0])
          ? handleFilter[0]
          : null;

        setSorting(true);
        handleColumnSort(columnIndex, sortDirection, currentFilters);
      })
    }

    // CUSTOM PAGINATION (LAZY LOADING)
    // Handle custom pagination length change
    if (handlePaginate) {
      $(`#${divId}_length`).on('change', function (e) {
        setPaginateLimit(parseInt(e.target.value))
      }
      )

      $(`.paginate_button`).on('click', function (e) {
        const action = e.target.innerText;
        handlePaginate(action)
      })

      // remove datatables internal pagination
      $(`${tableId}_paginate`).hide();
      $(`${tableId}_info`).hide();
      $(`${tableId}_filter`).hide();

      // remove sorting on specified columns
      $(".no-sort").removeClass("sorting sorting_desc sorting_asc")
      $("th.no-sort").css("pointer-events", "none")
    }
    //

    // Modal - Edit
    $(".editModal").click(function (event) {
      const id = parseInt(event.target.dataset.id)
      handleShowEditModal(id);
    });
  });


  history.listen((location) => {
    let data;
    if (table) data = table.state();

    if (data) saveTableSettings(data);
  })

  const saveTableSettings = (data) => {
    saveSettings(
      {
        variables:
        {
          page: page,
          settings: data
        }
      })
  }

  const addRecord = {
    color: Colors.xlGreen,
    fontSize: 24
  }

  const showHiddenLink = {
    alignSelf: 'flex-end',
    paddingBottom: '0.65rem',
    paddingLeft: '0.7rem',
    fontSize: '0.8rem',
  }

  const leftSideInfo = {
    alignItems: 'center',
    paddingLeft: '0.6rem',
    width: '60%',
  }


  const handleClick = ({ showHidden }) => {
    const data = table.state();

    // saveTableSettings(data);

    // Currently getting a weird datatable error if there are no hidden values
    // and then press show visible if using custom lazyloading, having page
    // for now.
    handlePaginate && !showHidden
      ? window.location.reload()
      : setShowHidden(showHidden);
  }

  function ShowHiddenLink(props) {
    return <Buttons.Link text="Show Visible" style={showHiddenLink} onClick={() => handleClick({ showHidden: false })} />
  }

  function ShowVisibleLink(props) {
    return <Buttons.Link text="Show Hidden" style={showHiddenLink} onClick={() => handleClick({ showHidden: true })} />
  }


  function DisplayLink(props) {
    const { showHidden } = props;

    if (showHidden) {
      return <ShowHiddenLink {...props} />
    }
    return <ShowVisibleLink {...props} />
  }

  // Modal Create
  const handleModal = () => {
    handleShowModal(true);
  }

  const handleToggle = () => {
    toggleData();
  }

  return (
    <React.Fragment key={refresh}>
      <div className="data-table-header">
        <div style={leftSideInfo}>
          <div style={Typography.pageTitle}>{pageTitle}</div>
          {!unhideable &&
            <DisplayLink showHidden={showHidden} />
          }
        </div>
        <div className='hide-column-actions'></div>
        {!uncreatable && !handleShowModal &&
          <AuthLink
            to={createRoute}
            resource="User"
            action="read"
            style={addRecord}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            {textForCreate && <span style={textForCreateStyle}>{textForCreate}</span>}
          </AuthLink>
        }
        {!uncreatable && handleShowModal && <FontAwesomeIcon
          className='show-modal-button'
          style={addRecord}
          onClick={handleModal}
          icon={faPlusCircle}
        />
        }
      </div>
      <div style={Spacing.tableWrapper}>
        {subHeader &&
          <div style={Typography.formSubHeader}>{subHeader}</div>
        }
        {
          toggleData && <div>
            <input
              type="checkbox"
              checked={toggleDataState}
              onChange={handleToggle}
              style={{ marginRight: '10px' }}
            />
            {toggleDataLabel}
          </div>
        }
        {handlePaginate && <Pagination
          handlePaginate={handlePaginate}
          currentPage={currentPage}
          paginateLimit={paginateLimit}
          paginateItemsCount={paginateItemsCount}
        />
        }
        {
          handlePaginate && (handleFilter || handleSearch) && <OptionsBar
            handleSearch={handleSearch}
            handleFilter={handleFilter}
            handleExportToExcel={handleExportToExcel}
            dataSet={dataSet}
            filterButtonText={filterButtonText}
          />
        }
        <table id={divId} className="cell-border table display hover stripe responsive" width="100%"></table>
        {handlePaginate && <Pagination
          handlePaginate={handlePaginate}
          currentPage={currentPage}
          paginateLimit={paginateLimit}
          paginateItemsCount={paginateItemsCount}
        />
        }
      </div>
    </React.Fragment>

  );
}

export default DataTable;
