import { Colors } from '../styles';

export const horizontalSpace = {
  leftMargin: '10%',
  rightMargin: '10%,'
}

export const tableWrapper = {
  width: '98%',
  margin: 'auto'
}

export const alerts = {
  marginTop: '0.2%',
}

export const dateInput = {
  marginTop: '1.5%',
}

export const formContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '2%',
}

export const formWrapper = {
  marginTop: '2%',
  width: '98%'
}

export const formHeader = {
  marginBottom: '2px'
}

export const buttonRow = {
  display: 'flex',
  justifyContent: 'space-around',
  marginBottom: '2%',
  marginTop: '-3%',
}

export const paymentButtonRow = {
  display: 'flex',
  justifyContent: 'space-around',
  marginBottom: '2%',
  marginTop: '-3%',
}

export const topButtonRow = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: '-5%',
  marginBottom: '0.5%',
  marginLeft: '1%'
}

export const showContainer = {
  margin: '15px',
}
