import React, { useContext } from 'react';
import AuthContext from 'components/src/contexts/AuthContext';
import { UserContext } from 'components/src/contexts/UserContext';
import DataState from 'components/src/components/DataState';
import { useQuery } from '@apollo/client';
import { kpiResultsQuery } from './queries';
import KPIRow from './Row';
import './style.css';

const KPIs = () => {
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);

  const date = new Date();
  const quarter = auth.currentFiscalQuarter ||
    Math.ceil((date.getMonth() + 1) / 3)

  const defaults = {
    year: (auth.currentFiscalYear || date.getFullYear()),
    startQuarter: quarter,
    endQuarter: quarter,
    marketIds: user.filterMarketIds(),
  };

  const kpiResults = kpiResultsQuery();
  const { loading, error, data, refetch } = useQuery(
    kpiResults, { variables: defaults }
  );

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const props = {...defaults, results: data.kpiResults, years: data.kpiYears };

  return (
    <>
      <h2>Key Performance Indicators</h2>
      <table className="kpi-results">
        <thead>
          <tr>
            <th>KPI Description</th>
            <th>Year</th>
            <th>Starting<br />Quarter</th>
            <th>Ending<br />Quarter</th>
            <th>Goal</th>
            <th>Actual</th>
            <th>Progress Toward Goal</th>
          </tr>
        </thead>
        <tbody>
          <KPIRow
            field="totalRevenue"
            label="Total Revenue"
            type="currency"
            {...props}
          />
          <KPIRow
            field="activations"
            label="Activations"
            type="count"
            {...props}
          />
          <KPIRow
            field="delinquentPercent"
            label="Delinquent Percentage"
            type="delinquent"
            {...props}
          />
          <KPIRow
            field="writeoffPercent"
            label="Write-off Percentage"
            type="writeoff"
            {...props}
          />
          <KPIRow field="waivers" label="Waivers" type="waivers" {...props} />
          <KPIRow field="ebtda" label="EBTDA" type="currency" {...props} />
          <KPIRow
            field="grossYield"
            label="Gross Yield"
            type="percent"
            {...props}
          />
          <KPIRow
            field="netYield"
            label="Net Yield"
            type="percent"
            {...props}
          />
          <KPIRow
            field="auditFoundPercent"
            label="Lot Audit Found Percentage"
            type="percent"
            {...props}
          />
          <KPIRow
            field="flooredCars"
            label="Floored Cars"
            type="count"
            {...props}
          />
        </tbody>
      </table>
    </>
  );
};

export default KPIs;
