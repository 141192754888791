import React, { useState } from "react";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useRouteMatch, useParams, Redirect } from "react-router-dom";
import { Formik } from "formik";
import DataState from "../../DataState";
import { Form, voidSchema } from "./Form";
import * as Formatting from "../../../styles/formatting";
import Modal from "react-bootstrap/Modal";
import { titleCase } from "../../../utils/stringHelpers";
import { useServerSideErrors } from "../../../hooks/useServerSideErrors";

const VOID_TRANSACTION = gql`
  mutation VoidTxn(
    $id: ID!
    $bankDate: ISO8601Date
    $nsf: Boolean
    $creditOnAccount: Boolean
    $memo: String
  ) {
    voidTxn(
      id: $id
      bankDate: $bankDate
      nsf: $nsf
      creditOnAccount: $creditOnAccount
      memo: $memo
    ) {
      txn {
        id
        voidable
      }
      errors {
        path
        message
      }
    }
  }
`;

const NewVoid = (props) => {
  const [serverSideErrors, setServerSideErrors] = useState("");

  const [voidTransaction, { errors: mutationErrors, loading: mutationLoading }] = useMutation(
    VOID_TRANSACTION,
    {
      onCompleted(values) {
        const key = Object.keys(values)[0];
        const errors = values[key].errors;
        if (errors.length) {
          let errorString = "";
          let errorName = "";
          let errorMessage = "";
          errors.forEach((error, index) => {
            errorName = error.path[1];
            errorMessage = error.message;
            const separator = index > 0 ? ", " : "";
            errorString += `${separator} ${titleCase(
              errorName
            )} ${errorMessage}`;
          });
          setServerSideErrors(errorString);
        } else {
          props.setShowModal(false);
          props.displayAlert();
        }
        window.location.reload()
      },
    }
  );

  const handleSubmit = (values) => {
    voidTransaction({ variables: values });
  };

  let today = new Date();
  today = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];

  if (mutationLoading)
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={mutationLoading}
      >
        <Modal.Header>
          <Modal.Title>
            <DataState.Loading />
          </Modal.Title>
        </Modal.Header>
      </Modal>
    );

  return (
    <>
    { serverSideErrors && <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div> }
      <Formik
        initialValues={{
          id: props.txn.id,
          creditToAccount: false,
          bankDate: today,
          nsf: false,
          memo: "",
        }}
        validationSchema={voidSchema}
      >
        <Form {...props} handleSubmit={handleSubmit} />
      </Formik>
    </>
  );
};

export default NewVoid;
