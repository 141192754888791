import React from "react";
import { SearchBar } from "./SearchBar";
import { ResetSettings } from "./ResetSettings";
import { ExportToExcel } from "../../elements/ExportToExcel";
import DisplayLoading from "./DisplayLoading";
import { Filter } from "./Filter";

const flexStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "baseline",
  marginBottom: "-10px",
};

const queryLoadingStyle = {
  marginRight: "20px",
};

export const OptionsBar = ({
  handleSearch,
  handleFilter,
  handleExportToExcel,
  dataSet,
  filterButtonText,
}) => {
  const options = [
    <Filter
      key="filter"
      handleFilter={handleFilter}
      dataSet={dataSet}
      filterButtonText={filterButtonText}
    />,
    <ResetSettings key="reset" />
  ];

  if (handleExportToExcel) {
    options.unshift(
      <ExportToExcel key="export" handleExportToExcel={handleExportToExcel} />
    );
  }

  const queryLoading = handleFilter ? handleFilter[2] : false;

  return (
    <div style={handleFilter ? flexStyle : {}}>
      {queryLoading && <DisplayLoading style={queryLoadingStyle} size={20} />}
      {handleFilter && options}
      {handleSearch && (
        <SearchBar handleSearch={handleSearch} hasFilter={handleFilter} />
      )}
    </div>
  );
};
