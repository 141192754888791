import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BorrowedTitles from './BorrowedTitles';
import Collections from './Collections';
import PaymentsDue from './PaymentsDue';
import PoaCpp from './PoaCpp';
import Unverified from './Unverified';

const DashboardTableRoutes = () => {
  return (
    <Switch>
      <Route path="/dashboard/payments-due" component={PaymentsDue} />
      <Route path="/dashboard/borrowed-titles" component={BorrowedTitles} />
      <Route path="/dashboard/collections" component={Collections} />
      <Route path="/dashboard/poa-cpp" component={PoaCpp} />
      <Route path="/dashboard/unverified" component={Unverified} />
    </Switch>
  );
};

export default DashboardTableRoutes;
