import React, { useState, Fragment, useEffect } from 'react';
import Form from "react-bootstrap/Form";
import { setISODate } from '../../../utils/dateHelpers';
import { StyledLabel, StyledErrorMessage } from '../../../elements/forms';
import { BANK_ACCOUNTS } from '../../../queries/indexQueries';
import { useQuery } from '@apollo/client';
import DataState from '../../DataState';

const inputStyle = {
  marginBottom: '1%'
}

const HeaderOptions = ({ options, bankDateValue }) => {
  const [bankDate, setBankDate] = useState(options.current.bankDate);
  const [memo, setMemo] = useState(options.current.memo);
  const [type, setType] = useState(options.current.type);
  const [txnsPerVendor, setTxnsPerVendor] = useState(options.current.txnsPerVendor);
  const [bankAccount, setBankAccount] = useState(options.current.bankAccount);

  const { loading, error, data } = useQuery(BANK_ACCOUNTS);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  if (!bankAccount) {
    options.current.bankAccount = accounts[0].id;
    setBankAccount(accounts[0].id);
  }

  return (
    <div key="options">
      <StyledLabel key="bankDate">Bank Date</StyledLabel>
      <Form.Control
        type="date"
        style={inputStyle}
        value={bankDate}
        ref={bankDateValue}
        onChange={(event) => {
          options.current.bankDate = event.target.value;
          setBankDate(event.target.value);
          }
        }
      />
      { bankDateValue.current?.value === '' && (
        <StyledErrorMessage>Required</StyledErrorMessage>
        )
      }
      <StyledLabel key="txnsPerVendor">Transactions Per Vendor</StyledLabel>
      <Form.Control
        as="select"
        value={txnsPerVendor}
        onChange={(event) => {
          options.current.txnsPerVendor = event.target.value;
          setTxnsPerVendor(event.target.value);
        }
      }
      >
        <option value="single">Single</option>
        <option value="multiple">Multiple</option>
      </Form.Control>
      <StyledLabel key="type">Type</StyledLabel>
      <Form.Control
        as="select"
        value={type}
        onChange={(event) => {
          options.current.type = event.target.value;
          setType(event.target.value);
        }
      }
      >
        <option value="ACH">ACH</option>
        <option value="Check">Check</option>
        <option value="Wire">Wire</option>
        <option value="Cash">Cash</option>
      </Form.Control>
      <StyledLabel>Account</StyledLabel>
      <Form.Control
        as="select"
        value={bankAccount}
        onChange={(event) => {
          options.current.bankAccount = event.target.value;
          setBankAccount(event.target.value);
        }
      }
      >
      { accounts.map((account) => {
        return <option key={account.id} value={account.id}>{account.name}</option>
      })}
      </Form.Control>
      <StyledLabel key="memo">Memo</StyledLabel>
      <Form.Control
        type="text"
        value={memo}
        onChange={(event) => {
          options.current.memo = event.target.value;
          setMemo(event.target.value);
        }
      }
      />
    </div>
  )
}

export default HeaderOptions;
