import moment from 'moment'
import { DateHelpers, StringHelpers } from '../../../utils';

export const columns = [
  {
    title: 'Position',
    className: 'text-right',
  },
  {
    title: 'Secured Party'
  },
  {
    title: 'Filing Location'
  },
  {
    title: 'Filed On',
    render: DateHelpers.renderDate
  },
  {
    title: 'Expires On',
    render: DateHelpers.renderDate,
    className: 'showOverdue'
  },
  {
    title: 'Original File Date',
    render: DateHelpers.renderDate
  },
  {
    title: 'PMSI Tracking Number',
    className: 'text-right',
  },
  {
    title: 'Address of Secured Party'
  },
  {
    title: 'PMSI Sent/Received On',
    render: DateHelpers.renderDate
  },
  {
    title: 'View PMSI'
  },
  {
    title: 'View UCC'
  },
  {
    title: 'Actions'
  }
]

export const rowData = (uccs, auth, id, showHidden) => {
  return uccs.map((ucc) => {

    const hiddenLink = showHidden ?
      `<a href='' id="unhide" data-id=${ucc.id}>Unhide</a>` :
      `<a href='' id="hide" data-id=${ucc.id}>Hide</a>`

    const unformattedActions = []
    if (auth.hasPolicy('Ucc', 'update')) {
      unformattedActions.push(`<a href="/dealers/${id}/uccs/${ucc.id}/edit">Edit</a>`)
      unformattedActions.push(hiddenLink)
    }

    if (auth.hasPolicy('Ucc', 'destroy')) {
      unformattedActions.push(`<a href="" id="delete" data-id=${ucc.id}>Delete</a>`)
    }

    // action formatting
    let actions = unformattedActions[0]
    if (unformattedActions.length > 1) {
      ucc.actions = unformattedActions.map((action, index) => {
        if (index === 0) {
          actions += ' | '
        } else if (index < unformattedActions.length - 1) {
          actions += `${action} | `
        } else {
          actions += action
        }
      })
    }

    let securedPartyName = ucc.securedPartyName
    if (ucc.xlf) securedPartyName = `${securedPartyName} <span id='xlf-check'>&#10003</span>`

    let pmsiSentReceiveOn;
    if (ucc.pmsiSent) pmsiSentReceiveOn = `${moment(ucc.pmsiSent).format('MM/DD/Y')} (sent)`;
    if (ucc.pmsiReceived) pmsiSentReceiveOn = `${moment(ucc.pmsiReceived).format('MM/DD/Y')} (received)`;
    if (!ucc.pmsiSent && !ucc.pmsiReceived) pmsiSentReceiveOn = '';

    let address = `${ucc.address || ''}`
    if (ucc.city) address += ` ${StringHelpers.titleCase(ucc.city)}`
    if (ucc.city && ucc.state) address+= ','
    if (ucc.state) address += ` ${ucc.state}`
    if (ucc.zip) address += ` ${ucc.zip}`

    const filedOn = DateHelpers.setDate(ucc.filedOn);

    const expiresOn = DateHelpers.setDate(ucc.expiresOn);

    const originalFileDate = DateHelpers.setDate(ucc.originalFileDate);
    const trackingNumber = ucc.fileUrl !== null ?
      `<a href="${ucc.fileUrl}">${ucc.pmsiTrackingNumber}</a>` :
      ucc.pmsiTrackingNumber !== null && ucc.pmsiTrackingNumber !== '' ?
      ucc.pmsiTrackingNumber : '';

    return [
      ucc.position,
      StringHelpers.titleCase(securedPartyName),
      ucc.filingLocation,
      filedOn,
      expiresOn,
      originalFileDate || '',
      trackingNumber,
      address,
      pmsiSentReceiveOn || '',
      'View / Upload New',
      'View / Upload New',
      actions || ''
    ]
  })
}
