import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useQuery, gql } from '@apollo/client';
import DataState from '../../DataState';
import TxnLinesTable from './Table';
import { TXN_LINE_FIELDS } from '../../../queries/viewQueries';
import '../../Dealers/dealershow.css';

const TXN_LINES = gql`
  query TxnLines($txnId: Int, $dealerId: Int, $flooredCarId: Int) {
    txnLines(txnId: $txnId, dealerId: $dealerId, flooredCarId: $flooredCarId) {
      ${TXN_LINE_FIELDS}
    }
  }
`;

const modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px',
}

const ModalBody = ({ txn, referrerType, referrerId }) => {
  const variables = { txnId: parseInt(txn.id) };
  if ('car' === referrerType) {
    variables['flooredCarId'] = referrerId;
  } else if ('dealer' === referrerType) {
    variables['dealerId'] = referrerId;
  }

  const { loading, error, data } = useQuery(TXN_LINES, { variables });

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return <TxnLinesTable txnLines={data.txnLines} title={false} />;
};

const LinesModal = ({ setTxn, ...props }) => {
  return (
    <Modal
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
      onHide={() => setTxn(null)}
      show={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Transaction Lines
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <ModalBody {...props} />
      </Modal.Body>
    </Modal>
  );
};

export default LinesModal;
