export const insertTableData = (datatable, dataSet) => {
  datatable
    .clear()
    .rows.add(dataSet)
    .draw(false);
};

export const reSortTableData = (datatable, sortColumnIndex, sortDirection) => {
  if (datatable.order() && sortColumnIndex.current && sortDirection.current) {
    datatable.order([sortColumnIndex.current, sortDirection.current]).draw();
  }
};
