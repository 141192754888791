import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import * as BasicForm from 'components/src/elements/forms';
import DataState from 'components/src/components/DataState';

const BANK_ACCOUNTS = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      primaryBankAccount {
        id
        accountName
        accountNumberMasked
        isPrimary
      }
      bankAccounts {
        id
        accountName
        accountNumberMasked
        isPrimary
      }
    }
  }
`;

const bankAccountOption = account => {
  let label = `${account.accountName} ${account.accountNumberMasked}`;

  if (account.isPrimary) { label = label + ' (Primary)'; }

  return { value: account.id, label };
};

const Select = ({ id, setFieldValue, values, ...props }) => {
  const { loading, error, data } = useQuery(BANK_ACCOUNTS, {
    variables: { id },
  });

  useEffect(() => {
    if (!values.bankAccountId && data?.dealer?.primaryBankAccount) {
      setFieldValue('bankAccountId', data.dealer.primaryBankAccount.id);
    }
  }, [data]);

  if (loading || !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { bankAccounts, primaryBankAccount } = data.dealer;

  if (0 === bankAccounts.length) {
    return (
      <span>No Bank Accounts</span>
    );
  } else if (1 === bankAccounts.length) {
    return (
      <span>{bankAccountOption(primaryBankAccount).label}</span>
    );
  } else {
    const formattedBankAccounts = bankAccounts.map(account => (
      bankAccountOption(account)
    ));

    return (
      <BasicForm.AutoCompleteTextInput
        clearButton
        name="bankAccountId"
        type="text"
        options={formattedBankAccounts}
        defaultSelected={[bankAccountOption(primaryBankAccount)]}
        id="source-data"
        onChange={(selected) => {
          selected.length > 0
            ? setFieldValue("bankAccountId", selected[0].value)
            : setFieldValue("bankAccountId", "");
        }}
        {...props}
      />
    );
  }
};

export default Select;
