import React from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import { useFormikContext } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';
import { useQuery } from '@apollo/client';
import { INSURANCE_STATE_SELECTS } from '../../../queries/selects';

export const newInsuranceStateSchema = yup.object({
  name: yup
    .string()
    .required('Required'),
  state: yup
    .string()
    .max(2, 'Must be two characters or less')
    .required('Required'),
});

export const editInsuranceStateSchema = yup.object({
  name: yup
    .string()
    .required('Required'),
  state: yup
    .string()
    .max(2, "Must be two characters or less")
    .required('Required'),
});

export const Form = ({ type }) => {
  const { values, errors } = useFormikContext();
  const { data: selectData } = useQuery(INSURANCE_STATE_SELECTS);

  return (
    <FormikForm>
      <BasicForm.TextInput
        name="name"
        type="text"
        label="State (full name)"
        disabled={true}
      />
      <BasicForm.StateAbbreviationSelect
        name="state"
        type="text"
        label="State Abriviation"
        disabled={true}
      />
      {
        selectData &&
        <BasicForm.ProgrammaticSelect
          name="defaultInsuranceProgramId"
          label="Default Collateral Coverage Program"
          options={selectData.insurancePrograms}
        />
      }
      {(type !== 'edit') && <ChangeForm />}
      <div style={Spacing.buttonRow}>
        <Buttons.Cancel type="cancel" text="Cancel" />
        <Buttons.Standard type="submit" text="Submit" />
      </div>

      {type === 'edit'}
    </FormikForm>
  );
}
