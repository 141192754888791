import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { AUDITS, DEALER } from './queries';
import { preflightTableStyles } from '../../../styles';
import DataTable from '@preflighttech/preflight-tables';
import DataState from '../../DataState';
import { columnDefinitions } from './columnDefinitions';
import { Container, Row, Col } from 'react-bootstrap';
import * as Formatting from '../../../styles/formatting';
import { titleCase } from '../../../utils/stringHelpers';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';

const index = () => {
  const dealerId = parseInt(useParams().id);

  const { loading, error, data } = useQuery(AUDITS, {
    variables: { dealerId }
  });

  const {
    loading: dealerLoading,
    error: dealerError,
    data: dealerData
  } = useQuery(DEALER, { variables: { id: dealerId } })

  if (loading && !data || dealerLoading && !dealerData) return (
    <DataState.Loading />
  );
  if (error || dealerError) return (
    <DataState.Error error={error || dealerError} />
  );

  const columns = columnDefinitions({})

  let dealerTypeNames = [];

  const dealerTypes = dealerData.dealer.dealerCategories.map((type) => {
    dealerTypeNames.push(type.name)
  })

  const auditPercentage = dealerData.dealer.lastSixFoundPercentage;

  return (
    <>
      <br />
      <Container style={Formatting.infoContainer}>
        <Row>
          <div className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Legal Name</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {dealerData.dealer.legalName}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br/>
          <div className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Dealer Status</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {dealerData.dealer.dealerStatus.name}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br/>
          <div className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">Dealer Type</th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {dealerTypeNames.join(", ")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br/>
          <div className="col-xl-3 info-section">
            <table width="100%">
              <tbody>
                <tr>
                  <th className="car-info-header">
                    Avg Found Last 6 Audits
                  </th>
                </tr>
                <tr>
                  <td className="car-info-green">
                    {auditPercentage ?
                      `${auditPercentage}%` : 'No Closed Audits'
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
      <hr />
      <ResourcesHeader
        title='Dealer Audits'
        noNew
      />
      <DataTable.Simple
        data={data.audits}
        columns={columns}
        htmlTable
        styles={preflightTableStyles}
      />
    </>
  )
}

export default index;
