import React from 'react';
import { Helmet } from 'react-helmet';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Form, categorySchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'

const NEW_DEALER_CATEGORY = gql`
  mutation CreateDealerCategory($name: String!) {
    createDealerCategory(name: $name) {
      dealerCategory { id, name }
      errors { message, path}
    }
  }
`

const NewDealerCategory = () => {
  const serverSideErrorProps = {
    SAVE_MUTATION: NEW_DEALER_CATEGORY,
    onCompleteRedirectTo: '/system/dealer-Categories'
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const NewForm = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A Dealer Type</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              name: ''
            }}
            validationSchema={categorySchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>New Dealer Type</title>
      </Helmet>
      <DisplayContent />
    </>
  )
}

export default NewDealerCategory;
