import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import DataState from '../DataState';
import '../../styles/Source/showInfo.css';
import * as Style from '../../styles/Source/showInfo';
import * as StringHelper from '../../utils/stringHelpers';

const VENDOR = gql`
query Vendor($id: ID!) {
  vendor(id: $id) {
    id
    name
    city
    state
    street
    zip { value }
    phone { value, formatted }
    primaryContact {
      id
      fullName
      officePhone {
        value
        formatted
      }
      email
    }
    tin { formatted }
    tinType
    federalClassification
    taxClassification
    eligibleFor1099
    payableAccount { id, gl, name }
    expenseAccount { id, gl, name }
  }
}`

const Info = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(VENDOR, { variables: { id } });

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { vendor } = data;
  const { name, city, state, street, zip, phone, tin, tinType,
    federalClassification, taxClassification, eligibleFor1099 } = vendor;

  const contactPhone = vendor.primaryContact?.officePhone?.formatted;
  const primaryContact = vendor.primaryContact?.fullName || '';
  const email = vendor.primaryContact?.email || '';

  const cityState = (city && state) ? `${city}, ${state}` : `${city}${state}`

  return (
    <>
      <Container style={Style.auctionInfoContainer}>
        <Row>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>Vendor:</Col>
              <Col align="right">{name}</Col>
            </Row>
            <Row className="info-row">
              <Col>Phone Number:</Col>
              <Col align="right">{phone?.formatted}</Col>
            </Row>
            <Row className="info-row">
              <Col>Address:</Col>
              <Col align="right">
                {street}
                <br/>
                {cityState}
                <br/>
                {zip.value}
              </Col>
            </Row>
          </div>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>{tinType || 'TIN'}:</Col>
              <Col align="right">{tin?.formatted ? 'Present' : 'Absent'}</Col>
            </Row>
            <Row className="info-row">
              <Col>Federal Classification:</Col>
              <Col align="right">{federalClassification}</Col>
            </Row>
            {
              'Limited liability company' == federalClassification &&
                <Row className="info-row">
                  <Col>Tax Classification:</Col>
                  <Col align="right">{taxClassification}</Col>
                </Row>
            }
            <Row className="info-row">
              <Col>Eligible For 1099:</Col>
              <Col align="right">{eligibleFor1099}</Col>
            </Row>
            <Row className="info-row">
              <Col>Payable Account:</Col>
              <Col align="right">
                { vendor.payableAccount &&
                  `${vendor.payableAccount.gl} - ${vendor.payableAccount.name}`
                }
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Expense Account:</Col>
              <Col align="right">
                { vendor.expenseAccount &&
                  `${vendor.expenseAccount.gl} - ${vendor.expenseAccount.name}`
                }
              </Col>
            </Row>
          </div>
          <div className="col-xl-3 dealer-info">
            <Row className="info-row">
              <Col>Primary Contact:</Col>
              <Col align="right">{primaryContact}</Col>
            </Row>
            <Row className="info-row">
              <Col>Primary Contact Phone:</Col>
              <Col align="right">{contactPhone}</Col>
            </Row>
            <Row className="info-row">
              <Col>Primary Contact Email:</Col>
              <Col align="right">{email}</Col>
            </Row>
          </div>
        </Row>
      </Container>
      <div style={Style.bottomBorder} />
    </>
  )
}

export default Info;
