import React, { useContext, useState, useRef } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_TITLE_LOG,
  CREATE_FILE_UPLOAD,
  TITLE_LOG_RESTRICTIONS,
} from './Queries';
import DataState from '../../DataState';
import { Spacing, Typography } from '../../../styles';
import { Formik } from 'formik';
import { DateHelpers } from "../../../utils";
import { Form } from './Form';
import ReactDOM from 'react-dom';
import { newTitleLogSchema } from './Schema';
import AuthContext from 'components/src/contexts/AuthContext';

const New = props => {
  let {
    show,
    handleClose,
    refetch,
    vin,
    setNewTitleLogAlert,
    dealerId
  } = props;

  const auth = useContext(AuthContext);

  const { loading, error, data } =
    useQuery(TITLE_LOG_RESTRICTIONS, { variables: { dealerId } });

  const [createTitleLog, { loading: titleLoading }] = useMutation(
    CREATE_TITLE_LOG, {
      onCompleted(values) {
        setNewTitleLogAlert(true)
        handleClose();
        refetch();
      }
    }
  );

  let submittedValues = useRef();

  const [createFileUpload, { loading: fileLoading }] = useMutation(
    CREATE_FILE_UPLOAD, {
      onCompleted(values) {
        const id = parseInt(values.createFileUpload.fileUpload.id)
        submittedValues.current.fileUploadId = id
        createTitleLog({ variables: submittedValues.current })
      }
    }
  )

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const disabled =
    data.titleLogs.filter(log => log.flooredCar.vin === vin).length > 0;

  const handleSubmit = (values) => {
    submittedValues.current = values
    if (values.file !== undefined) {
      createFileUpload({ variables: values })
    } else {
      if (values.trackingNumber === '') values.trackingNumber = undefined;
      createTitleLog({ variables: values })
    }
  };

  const NewTitleLog = () => {
    const carId = useParams().carId
    return (
      <div style={Object.assign({}, Spacing.formWrapper )}>
        <Formik
          initialValues={{
            flooredCarId: parseInt(carId),
            createdById: parseInt(auth.user.id),
            titleStatusId: undefined,
            trackingNumber: '',
            notes: undefined,
            fileUploadId: undefined,
            name: `Scanned Title ${DateHelpers.renderDate(new Date())}`,
            type: 'AWS Storage',
            file: undefined,
            ownerType: 'FlooredCar',
            ownerId: parseInt(carId),
            shipTitle: false,
            shipToType: undefined,
            shipToId: undefined,
            dealerId: undefined,
            otherAddress: {
              name: undefined,
              address: undefined,
              city: undefined,
              state: undefined,
              zip: undefined,
              phone: undefined
            }

          }}
          validationSchema={newTitleLogSchema}
          onSubmit={handleSubmit}
        >
          <Form
            disabled={disabled}
            dealerId={dealerId}
            restrictions={data.dealer.borrowedTitleRestrictions}
          />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Create a Title Log
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(titleLoading || fileLoading) ? <p>Loading...</p> : <NewTitleLog />}
      </Modal.Body>
    </Modal>
  )
}

export default New;
