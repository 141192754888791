import React from 'react';
import {
  Navbar,
  InputGroup,
  Nav,
} from 'react-bootstrap';
import './subnav.css';
import * as Colors from '../styles/colors';
import { LinkContainer } from 'react-router-bootstrap';
import SubNavLink from './SubNavLink'
import * as StringHelpers from '../utils/stringHelpers';

const SubNav = (props) => {
  const { title, navLinks, style, canFloor } = props;

  const canFloorBar = {
    backgroundColor: Colors.xlGreen,
  }

  const noFloorBar = {
    backgroundColor: '#d62300'
  }

  const ableToFloor = canFloor || canFloor === undefined ? true : false
  const subNavBar = ableToFloor ? canFloorBar : noFloorBar;

  const titleWrapper = {
    fontWeight: 'bold',
  }

  return (
    <>
      <Navbar expand="xxl" variant="dark" style={Object.assign({}, subNavBar, { ...style })} >
        <LinkContainer to={title.link}>
          <Navbar.Brand className="my-auto">
            <span style={titleWrapper}>{StringHelpers.truncate(title.text, 35, '...')}</span>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler-right" />
        <Navbar.Collapse className="flex-md-column" id="navbarCollapse">
          <InputGroup>
            <Nav className="mr-auto white">
              {navLinks.map(function (navLink) {
                return <SubNavLink key={navLink.link} {...navLink} />;
              })}
            </Nav>
          </InputGroup>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default SubNav;
