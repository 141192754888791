import { gql } from '@apollo/client'
import { NOTES_SUBQUERY } from '../Notes/queries';

const ERRORS = `errors { path, message }`;

const FLOORED_CAR_BASIC_FIELDS = `
  id
  vin
  year
  make
  model
  series
  style
  color
  mileage
  status
  loanAmount
  loanDate
  bosDate
  blackbookValue
  titleState
  titleStatus
  titleStatusOn
  titleStatusDays
  borrowedTitlePastDue
  titleNumber
  titleReleaseOn
  fundedOn
  payoffOn
  writeoffOn
  soldOn
  unitNumber
  stockNumber

  scheduledAchs
  scheduledAchId

  createdAt
  updatedAt

  advanceType
  sourceType
  sourceId
  sourceName
  externalPaymentMethod

  repoOn
  repoLocation
  repoSoldOn
  sotOn
  arbitratedOn
`;

const FLOORED_CAR_CURRENT_FIELDS = `
  currentFees
  currentInterest
  currentPrincipal
  currentCollateralCoverage
  currentInsurance
  currentDueOn
  maxPayoffOn
  totalDue

  termData {
    totalDays
  }
`;

const FLOORED_CAR_ASSOCIATION_FIELDS = `
  dealer {
    id
    name
    accountNumber
    dealerStatus { name }
    legalName
    dealerCategories { name }
    creditLine
    currentPrincipal
    unpaidCount
    unverifiedAuditCarsCount
  }
  dealerLine {
    dealer {
      id
      name
    }
  }
  market {
    id
    name
    today
  }
  rateProgram {
    id
    name
  }
  dealerProgram {
    id
    name
  }
  car {
    id
  }
`;

const FLOORED_CAR_AUDIT_FIELDS = `
  lastAudit
  auditCars {
    id
    verificationMethod
    qtStatus
  }
  lastAuditCode
  newAuditCode
  qtStatus
`;


export const PROGRAMS = gql`
  query {
    ratePrograms {
      id
      name
    }
    dealerPrograms {
      id
      name
    }
    automatedFees {
      id
      name
    }
    titleStatuses {
      id
      name
    }
  }
`;

export const GET_CAR = gql`
  query VinDecode($vin: String!, $mileage: Int!, $state: String!) {
    vinDecode(vin: $vin, mileage: $mileage, state: $state) {
      result
      errorMessage
      year
      make
      model
      series
      value
      car {
        color
      }
      flooredCars {
        vin
        loanAmount
        status
        payoffOn
        loanDate
        dealer { name }
      }
      floorplanRequests {
        id
        vin
        loanAmount
        dealerLine { dealer { name } }
      }
    }
  }
`;

export const DEALER = gql`
  query Dealer($id: ID!, $scopes: [String!]) {
    dealer(id: $id) {
      id
      name
      availableCredit
      nextUnitNumber
      market { today }
      unpaidFees {
        id
      }
      dealerStatus {
        name
        canFloor
      }
      dealerLines {
        id
        rateProgram {
          id
        }
        dealerProgram {
          id
        }
        activeRateProgram {
          id
        }
        activeDealerProgram {
          id
        }
        automatedFeeResourcesAttributes {
          name
          id
          automatedFee {
            id
          }
        }
      }
      flooredCars(scopes: $scopes) {
        ${FLOORED_CAR_BASIC_FIELDS}
        ${FLOORED_CAR_CURRENT_FIELDS}
        ${FLOORED_CAR_ASSOCIATION_FIELDS}
        ${FLOORED_CAR_AUDIT_FIELDS}
      }
    }
  }
`;

export const FLOORED_CARS_WITH_COUNT = gql`
  query(
    $limit: Int
    $offset: Int
    $sort: String
    $sortDirection: String
    $search: String
    $marketId: [Int!]
    $filterColumns: [String!]
    $filterNames: [JSON!]
    $scopes: [String!]
  ) {
    flooredCarsWithCount(
      limit: $limit
      offset: $offset
      sort: $sort
      sortDirection: $sortDirection
      search: $search
      marketId: $marketId
      filterColumns: $filterColumns
      filterNames: $filterNames
      scopes: $scopes
    ) {
      flooredCars {
        ${FLOORED_CAR_BASIC_FIELDS}
        ${FLOORED_CAR_CURRENT_FIELDS}
        ${FLOORED_CAR_ASSOCIATION_FIELDS}
      }
      count
      dealerNames
      titleStatuses
      sourceTypes
      sources
      dealerStatuses
    }
    markets { id, name }
  }
`;


export const CANCEL_ACH = gql`
  mutation($id: ID!) {
    cancelAch(id: $id) {
      quote {
        id
      }
      ${ERRORS}
    }
  }
`

export const FLOOR_CAR = gql`
  mutation FloorCar(
    $dealerLineId: Int!
    $vin: String!
    $year: Int!
    $make: String
    $model: String
    $color: String!
    $mileage: Int!
    $bosDate: ISO8601Date
    $externalPaymentMethod: String
    $advanceType: String
    $dealerProgramId: Int
    $rateProgramId: Int
    $titleNumber: String
    $titleState: String
    $titleStatusId: Int
    $bankAccountId: Int
    $loanAmount: Decimal
    $stockNumber: String!
    $unitNumber: String!
    $sourceId: Int
    $sourceType: String
    $advanceTo: String
    $advanceReference: String
    $blackbookValue: Decimal
    $automatedFeeResourcesAttributes: [AutomatedFeeResourceInput!]
    $advanceAccountId: Int
  ) {
    floorCar(
      dealerLineId: $dealerLineId
      vin: $vin
      year: $year
      make: $make
      model: $model
      color: $color
      mileage: $mileage
      bosDate: $bosDate
      externalPaymentMethod: $externalPaymentMethod
      advanceType: $advanceType
      rateProgramId: $rateProgramId
      dealerProgramId: $dealerProgramId
      titleNumber: $titleNumber
      titleState: $titleState
      titleStatusId: $titleStatusId
      bankAccountId: $bankAccountId
      loanAmount: $loanAmount
      stockNumber: $stockNumber
      unitNumber: $unitNumber
      sourceId: $sourceId
      sourceType: $sourceType
      advanceTo: $advanceTo
      blackbookValue: $blackbookValue
      advanceReference: $advanceReference
      automatedFeeResourcesAttributes: $automatedFeeResourcesAttributes
      advanceAccountId: $advanceAccountId
    ) {
      flooredCar {
        id
        advanceReference
        externalPaymentMethod
      }
      txn {
        id
      }
      ${ERRORS}
    }
  }
`;

export const ARBITRATE = gql`
  mutation Arbitrate(
    $id: ID!,
    $arbitratedOn: ISO8601Date,
    $arbitrationReason: String
  ) {
    arbitrate(
      id: $id
      arbitratedOn: $arbitratedOn
      arbitrationReason: $arbitrationReason
    ) {
      ${ERRORS}
    }
  }
`;

export const FLOORED_CAR = gql`
  query FLOORED_CAR($id: ID!) {
    flooredCar(id: $id) {
      name
      id
      vin
      year
      make
      model
      color
      series
      mileage
      automatedFeeIds
      blackbookValue
      autopayDisabled
      automatedFees {
        id
        name
      }
      automatedFeeResourcesAttributes {
        id
        name
        automatedFee { id }
      }
      dealerLine {
        dealer { name, id }
      }
      rateProgram {
        name
        id
      }
      dealerProgram {
        name
        id
      }
    }
  }
`;

export const UPDATE_FLOORED_CAR = gql`
  mutation UpdateFlooredCar(
    $id: ID!,
    $vin: String!,
    $year: Int,
    $make: String,
    $model: String,
    $series: String,
    $color: String!,
    $rateProgramId: Int,
    $dealerProgramId: Int,
    $mileage: Int!,
    $automatedFeeIds: [Int!]
    $blackbookValue: Decimal
    $autopayDisabled: Boolean
   ) {
    updateFlooredCar(
      id: $id
      vin: $vin
      year: $year
      make: $make
      model: $model
      series: $series
      color: $color
      rateProgramId: $rateProgramId
      dealerProgramId: $dealerProgramId
      mileage: $mileage
      automatedFeeIds: $automatedFeeIds
      blackbookValue: $blackbookValue
      autopayDisabled: $autopayDisabled
    ) {
      flooredCar {
        id
        vin
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_FLOORED_CAR_AUTOPAY_DISABLED = gql`
  mutation UpdateFlooredCarAutopayDisabled(
    $id: ID!
    $autopayDisabled: Boolean!
   ) {
    updateFlooredCarAutopayDisabled(
      id: $id
      autopayDisabled: $autopayDisabled
    ) {
      flooredCar {
        id
      }
      ${ERRORS}
    }
  }
`;


export const REPO = gql`
  mutation Repo(
    $id: ID!
    $repoOn: ISO8601Date
    $repoLocation: String
    $repoBy: String
    $repoSold: Boolean
  ) {
    repo(
      id: $id
      repoOn: $repoOn
      repoLocation: $repoLocation
      repoBy: $repoBy
      repoSold: $repoSold
    ) {
      ${ERRORS}
    }
  }
`;

export const SOT = gql`
  mutation Sot($id: ID!, $sotOn: ISO8601Date) {
    sot(
      id: $id
      sotOn: $sotOn
    ) {
      ${ERRORS}
    }
  }
`;

export const FLOORED_CAR_NOTES = gql`
  query FlooredCar($id: ID!, $scopes: [String!]) {
    flooredCar(id: $id) {
      id
      name
      ${NOTES_SUBQUERY}
    }
  }
`;
