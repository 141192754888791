import React from 'react';
import secrets from '../../secrets';

export const handleSubmit = (values, setFormSubmitting, displayAlert ) => {
  setFormSubmitting(true);

  const {
    name,
    phone,
    email,
    url,
    problem,
    expectation,
    supplied_url,
    user_id,
    subject } = values;

  const axios = require("axios");
  axios({
    method: "POST",
    url: 'https://preflighttech.com/api/1/trouble_tickets',
    headers: {
      Authorization: secrets.pti.token,
      "Content-Type": "application/json",
      Accept: "application/xlsx",
    },
    data: {
      trouble_ticket: {
        name,
        phone,
        email,
        url,
        problem,
        expectation,
        supplied_url,
        subject,
        user_id,
        app_id: '1',
        app_name: 'xlf',
        client_id: '1',
        status: 'New',
      }
    }
  }).then((res) => {
    const response = res.status;
    let message;
    let variant;

    setFormSubmitting(false);
    if (parseInt(response) < 300) {
      message = 'Thank you for contacting us. We will be in touch with you as quickly as possible.';
      variant = "success";
    } else {
      message = 'There was an error handling your request.'
      variant = 'danger';
    }
    displayAlert(Object.assign({}, {message, variant}));
  })
}
