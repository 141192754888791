import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AUDITS_WITH_COUNT } from './queries';
import { Typography, preflightTableStyles } from '../../styles';
import DataTables from '@preflighttech/preflight-tables';
import { columnDefinitions } from './columnDefinitions';
import ResourcesHeader from 'components/src/elements/ResourcesHeader';
import { UserContext } from "../../contexts/UserContext";
import { ExportToExcel } from 'components/src/elements/ExportToExcel';
import { ExportToExcelAlert } from 'components/src/elements/BasicAlert';
import { rest } from 'components/src/utils/rest';
import { View } from 'react-native';
import Filter from 'components/src/elements/Filter';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';

const Audits = () => {
  const [customAlert, displayCustomAlert] = useState(false);
  const [filterOptions, setFilterOptions] = useState();
  const [filters, setFilters] = useState({ audit_status: 'Open' });
  const [settings, setSettings] = useState({});

  const columns = columnDefinitions();

  const user = useContext(UserContext);

  let marketId = parseInt(user.currentMarket.id)

  const queryVariables = {
    filterColumns: [], filterNames: []
  };

  if (marketId) {
    queryVariables['filterColumns'].push('market');
    queryVariables['filterNames'].push(marketId);
  }

  const setFilter = (key, value) => {
    const currentFilters = {...filters};

    currentFilters[key] = value;

    setFilters(currentFilters);
  }

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      queryVariables['filterColumns'].push(key);
      queryVariables['filterNames'].push(value);
    }
  });

  const handleExportToExcel = () => {
    rest({
      path: ('audits_report'),
      params: {
        current_filters: queryVariables,
        sort_by: settings.order?.[0]?.key,
        sort_direction: settings.order?.[0]?.sort
      }
    }).then((res) => {
      const response = res.status;

      if (parseInt(response) < 300) displayCustomAlert(true);
    });
  };

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <ExportToExcel handleExportToExcel={handleExportToExcel} />
      <Filter clearFilters={() => setFilters({})}>
        <Filter.MultiSelect
          columnKey='market_name'
          label='Market'
          options={filterOptions?.markets}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.Select
          columnKey='audit_status'
          label='Audit Status'
          options={['Open', 'Closed']}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.MultiSelect
          columnKey='dealer_name'
          label='Dealer'
          options={filterOptions?.dealers}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.DateRange
          columnKey='inspection_on'
          label='Lot Audit Date'
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.PercentageRange
          columnKey='found_percentage'
          label='Found Percentage Range'
          filters={filters}
          setFilter={setFilter}
        />
      </Filter>
      <ResetSettings />
    </View>
  )

  return (
    <>
      { customAlert && <ExportToExcelAlert /> }
      <ResourcesHeader
        title="Audits"
        noNew
      />
      <DataTables.Gql
        query={AUDITS_WITH_COUNT}
        queryName="auditsWithCount"
        queryVariables={queryVariables}
        entriesName="audits"
        columns={columns}
        htmlTable
        setSettings={setSettings}
        styles={preflightTableStyles}
        dataUpdated={data => setFilterOptions(data?.auditsWithCount)}
        buttons={buttons}
      />
    </>
  )
};

export default Audits;
