import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { useParams, Redirect} from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../../styles';
import { useAlert } from '../../../../hooks/useAlert';
import { noteSchema, Form } from './Form';
import DataState from '../../../DataState';
import { UCC } from '../Edit';
import NewNoteForm from '../../../Notes/New';

const NewUccNote = () => {
  return <NewNoteForm gqlQuery={UCC} />
}

export default NewUccNote;
