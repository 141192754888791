import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const MARKET_FIELDS = `
  id
  name
  timezone
  hiddenAt
  canBeHidden
`;

const MARKET_FIELDS_AND_ERRORS = `
  market {
    ${MARKET_FIELDS}
  }
  ${ERRORS}
`;

export const MARKETS = gql`
  query Markets($scopes: [String!]) {
    markets(
      scopes: $scopes
    ) {
      ${MARKET_FIELDS}
    }
  }
`;

export const HIDE_MARKET = gql`
  mutation HideMarket($id: ID!) {
    hideMarket(
      id: $id
    ) {
      ${MARKET_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_MARKET = gql`
  mutation UnhideMarket($id: ID!) {
    unhideMarket(
      id: $id
    )
    {
      ${MARKET_FIELDS_AND_ERRORS}
    }
  }
`;

