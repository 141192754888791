import React, { useState } from 'react';
import { modalStyle, filterButton } from '../Pagination/FilterStyles';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import PercentageRange from './PercentageRange';
import DateRange from './DateRange';
import Select from './Select';
import MultiSelect from './MultiSelect';

const FilterModal = ({ setShowModal, clearFilters, children }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show
      onHide={() => setShowModal(false)}
    >
      <Modal.Header style={modalStyle.header} closeButton>
        <Modal.Title style={modalStyle.title}>
          Filter Options
          <Button variant="danger" onClick={clearFilters}>Clear Filters</Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalStyle.body}>
        {children}
      </Modal.Body>
    </Modal>
  );
}

const Filter = ({clearFilters, children}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button style={filterButton} onClick={() => setShowModal(true)}>
        Filter Options
      </Button>
      {
        showModal &&
          <FilterModal
            setShowModal={setShowModal}
            clearFilters={clearFilters}
            children={children}
          />
      }
    </>
  );
};

Filter.PercentageRange = PercentageRange;
Filter.DateRange = DateRange;
Filter.Select = Select;
Filter.MultiSelect = MultiSelect;

export default Filter;
