import React from 'react';
import Form from 'react-bootstrap/Form';
import { useFormikContext } from 'formik';
import * as Action from './functionality';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import * as StringHelpers from '../../../utils/stringHelpers';

const RESOURCE_LABELS = {
  'DealerTxn': 'DealerTxn accg',
}

export const SelectResource = (props) => {
  const { values,
    setValues,
    handleChange,
    handleBlur,
    onBlur,
    isValid,
    isInvalid,
    touched,
    errors } = useFormikContext();

  const { policies } = props;

  const { index } = props;

  return <Form.Group className='term-line-errors' key={index}>
    <Form.Control
      as="select"
      name={`policies[${index}][0]`}
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.policies[index][0] || ''}
      isInvalid={(errors.policies
        && errors.policies[index]
        && !!errors.policies[index][0])
      }
    >
      <option value="">Select a Resource</option>
      {policies.map((policy, index) => {
        const resourceLabel =
          RESOURCE_LABELS[policy.resource] || policy.resource;

        return (
          <option key={index} value={policy.resource}>{resourceLabel}</option>
        )
      })}
    </Form.Control>
  </Form.Group>
}

export const SelectAction = (props) => {
  const { policies, userPolicy, index } = props;
  const { values,
    setValues,
    handleChange,
    handleBlur,
    onBlur,
    isValid,
    isInvalid,
    touched,
    errors } = useFormikContext();

  const selectedPolicy = Action.selectedPolicy(policies, userPolicy)

  Action.resetAction(index, selectedPolicy)
  Action.resetOptions(index)
  Action.resetParam(index)

  return <Form.Group className='term-line-errors' key={index}>
    <Form.Control
      as="select"
      name={`policies[${index}][1]`}
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.policies[index][1] || ''}
      isInvalid={(errors.policies
        && errors.policies[index]
        && errors.policies[index][1]) &&
        (touched.policies
          && touched.policies[index]
          && touched.policies[index][1])
      }
    >
      <option value={''}>Select an Action</option>
      {selectedPolicy.actions.map((action, index) => {
        return (
          <option key={index} value={action.name}>
            {StringHelpers.titleCase(action.name, { onlyIfLower: true })}
          </option>
        );
      })}
    </Form.Control>
    <ErrorMessage name={`policies[${index}]`} />
  </Form.Group>
}

export const SelectParam = (props) => {
  const { policies, userPolicy, index, paramIndex, userAction } = props;
  const { values,
    setValues,
    handleChange,
    handleBlur,
    onBlur,
    isValid,
    isInvalid,
    touched,
    errors } = useFormikContext();

  Action.resetOptions(index)

  const selectedPolicy = Action.selectedPolicy(policies, userPolicy)
  const selectedAction = Action.selectedAction(selectedPolicy, userAction)
  if (!selectedAction || !selectedAction.params) return null;

  if (selectedAction.params.length > 0) {
    return <Form.Group key={index}>
      <Form.Control
        as="select"
        name={`options[${index}][0]`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.options[index][0] || ''}
      >
        <option value="">Select an Option</option>
        {selectedAction.params.map((param, paramIndex) => {
          return <option key={paramIndex}>{param.name}</option>
        })}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {`errors.options[${index}]`}
      </Form.Control.Feedback>
    </Form.Group>
  } else {
    return null
  }
}

export const SelectOptionCheckbox = (props) => {
  const { policies,
    userPolicy,
    index,
    paramIndex,
    userAction,
    userOption } = props;

  const { values,
    setValues,
    handleChange,
    handleBlur,
    isValid,
    isInvalid,
    touched,
    errors } = useFormikContext();


  const selectedPolicy = Action.selectedPolicy(policies, userPolicy)
  const selectedAction = Action.selectedAction(selectedPolicy, userAction)
  const selectedOption = Action.selectedOption(selectedAction, userOption)

  if (!selectedOption) return null;

  let optionValues = values.options[index].slice(1)

  optionValues = optionValues.map((value) => {
    if (!value) return false

    const key = Object.keys(value)[0]
    if (value[key] === true) return key
    if (value[key] === false) return ''
  })

  return selectedOption.values.map((value, idx) => {
    const checked = optionValues.includes(value)
    return <Form.Group key={idx}>
      <Form.Check
        label={value}
        name={`options[${index}][${idx + 1}][${value}]`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.options[index] && values.options[index][idx + 1] && values.options[index][idx + 1][value] || false}
        checked={values.options[index] && values.options[index][idx + 1] && values.options[index][idx + 1][value] || checked}
      />
    </Form.Group>
  })
}
