import React, { useContext, useState } from 'react';
import moment from 'moment'
import { useParams } from 'react-router-dom';
import './mentions.css';
import AuthContext from '../../contexts/AuthContext';
import { DateHelpers, StringHelpers } from '../../utils';
import Table from './Table';
import { AUTH_TOKEN } from '../../constants';
import NotesFilterModal from './NotesFilterModal';
import * as Buttons from '../../elements/buttons';
import secrets from '../../secrets';

const Notes = ({ query, ownerType, ownerId, unhideable, prefix }) => {
  const [show, setShow] = useState(false);
  const [noteUserIds, setNoteUserIds] = useState([]);

  const [filterResources, setFilterResources] = useState({
    userIds: [{ userId: '' }],
    ownerType: '',
    ownerId: '',
    startOn: '',
    endOn: '',
    collection: false,
    corporate: false,
    filterApplied: false
  });
  const [filterApplied, setFilterApplied] = useState(false);
  const [processing, setProcessing] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFilters = (resources) => {
    setFilterResources({ ...resources });
    setProcessing(true);
    setFilterApplied(true);
  }

  const printNotes = () => {
    const axios = require('axios');
    const token = localStorage.getItem(AUTH_TOKEN);
    const FileDownload = require('js-file-download');
    let formattedUserIds;
    if (filterResources.userIds) {
      formattedUserIds = filterResources.userIds.map(owner => {
        return parseInt(owner.userId);
      });
    }

    axios({
      method: 'get',
      url: `${secrets.api.baseUri}/notes_report`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
        'Authorization': `${token}`,
        'JWT_AUD': 'react'
      },
      params: {
        'owner-type': filterResources.ownerType,
        'owner-id': filterResources.ownerId,
        'user-ids': formattedUserIds,
        'start-on': filterResources.startOn === '' ? null : filterResources.startOn,
        'end-on': filterResources.endOn === '' ? null : filterResources.endOn,
        'corporate': filterResources.corporate,
        'collection': filterResources.collection
      }
    })
      .then(res => {
        setFilterApplied(false);
        const response = res.data;
        var date = new Date();
        var timestamp = date.getTime();
        FileDownload(response, `notes_report_${timestamp}.pdf`);
        setProcessing(false);
        handleClose();
      });
  }

  React.useEffect(() => {
    if (filterApplied) {
      printNotes();
    }
  });

  const showHiddenLink = {
    alignSelf: 'flex-end',
    paddingBottom: 0,
    paddingLeft: '0.7rem',
    fontSize: '0.8rem',
  };

  return (
    <div>
      <Buttons.Link text="Print Notes Report" style={showHiddenLink} onClick={handleShow} />
      <NotesFilterModal
        ownerid={ownerId}
        ownertype={StringHelpers.titleCase(ownerType)}
        handleclose={handleClose}
        show={show}
        resourcetype={ownerType}
        handlefilters={handleFilters}
        processing={processing}
        noteUserIds={noteUserIds}
      />
      <Table
        query={query}
        ownerType={ownerType}
        ownerId={ownerId}
        unhideable={unhideable}
        setNoteUserIds={setNoteUserIds}
        prefix={prefix}
      />
    </div>
  );
}

export default Notes;
