import * as yup from 'yup';
import { phoneRegExp } from "../../../utils/stringHelpers";

export const verificationOptions = [
  { label: 'Phone', value: 'Phone' },
  { label: 'In Person', value: 'In Person' },
  { label: 'Virtual', value: 'Virtual' },
  { label: 'Photo With Daily Code', value: 'Photo With Daily Code' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Other', value: 'Other' },
  { label: 'Arbitrated', value: 'Arbitrated' },
  { label: 'Auction', value: 'Auction' },
]

export const twoUnverifiedOptions = [
  { label: 'In Person', value: 'In Person' },
  { label: 'Virtual', value: 'Virtual' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Other', value: 'Other' },
  { label: 'Arbitrated', value: 'Arbitrated' },
  { label: 'Auction', value: 'Auction' },
]

export const editSchema = yup.object({
  verificationMethod: yup.string().required('Required'),
  soldOn: yup
    .date()
    .nullable()
    .when('verificationMethod', {
      is: 'Sold',
      then: yup
        .date()
        .required('Required')
        .max(new Date(), 'Date cannot be in the future')
    }),
  arbitratedOn: yup
    .date()
    .nullable()
    .when('verificationMethod', {
      is: 'Arbitrated',
      then: yup
        .date()
        .required('Required')
    }),
  phone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    }),
  financePhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    }),
  arbitrationReason: yup
    .string()
    .when('verificationMethod', {
      is: 'Arbitrated',
      then: yup
        .string()
        .required('Required')
    }),
  note: yup
    .string()
    .when('verificationMethod', {
      is: 'Other',
      then: yup
        .string()
        .required('Required')
    }),
  files: yup
    .string()
    .when('verificationMethod', {
      is: 'Photo With Daily Code',
      then: yup.string().required('Required')
    })
})
