import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '../../../styles';
import { gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@apollo/client';
import DealerInfo from '../DealerInfo';
import AuthContext from '../../../contexts/AuthContext';
import DataState from '../../DataState';
import DataTable from '../../../elements/DataTable';
import { useAlert } from '../../../hooks/useAlert';
import BasicAlert from '../../../elements/BasicAlert';
import { useParams } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { columns, rowData } from './IndexTableData';
import { useHideableResource } from '../../../hooks/useHideableResource';

const UCCS = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      uccs {
        id
        position
        securedPartyName
        filingLocation
        filedOn
        expiresOn
        address
        city
        state
        zip
        pmsiReceived
        pmsiSent
        xlf
        originalFileDate
        pmsiTrackingNumber
        fileUrl
      }
    }
  }
`

const HIDDEN_UCCS = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      uccs(scopes: ["hidden"]) {
        id
        position
        securedPartyName
        filingLocation
        filedOn
        expiresOn
        address
        city
        state
        zip
        pmsiReceived
        pmsiSent
        xlf
        originalFileDate
        pmsiTrackingNumber
      }
    }
  }
`
const DESTROY_UCC = gql`
  mutation DestroyUcc($id: ID!) {
    destroyUcc(
      id: $id
    ) {
      ucc { id }
      errors { message, path}
    }
  }
`

const HIDE_UCC = gql`
  mutation hideUcc($id: ID!) {
    hideUcc(
      id: $id
    ) {
      ucc { id }
      errors { message, path}
    }
  }
`

const UNHIDE_UCC = gql`
  mutation unhideUcc($id: ID!) {
    unhideUcc(
      id: $id
    ) {
      ucc { id }
      errors { message, path}
    }
  }
`

const UCCs = (props) => {
  const id = parseInt(useParams().id);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  const hiddenProps = {
    HIDDEN_QUERY: HIDDEN_UCCS,
    VISIBLE_QUERY: UCCS,
    HIDE_MUTATION: HIDE_UCC,
    UNHIDE_MUTATION: UNHIDE_UCC,
    variables: { id }
  };

  const [showHidden, setShowHidden, loading, error, data] = useHideableResource(hiddenProps)

  const [destroyUcc] = useMutation(
    DESTROY_UCC, {
    update(cache, { data }) {
      const updatedUccs = dealer.uccs.filter(ucc => ucc.id !== data.destroyUcc.ucc.id)
      cache.writeQuery({
        query: UCCS,
        data: {
          dealer: {
            id: dealer.id,
            name: dealer.name,
            __typename: dealer.__typename,
            uccs: updatedUccs
          }
        }
      })
    }
  })
  const auth = useContext(AuthContext);

  useEffect(() => {
    const history = createBrowserHistory();

    if (props.location.state && props.location.state.alert) {
      if (props.location.state.type) setAlertType(props.location.state.type)
      displayAlert();
      let state = { ...history.location.state };
      delete state.alert
      delete state.type
      history.replace({ ...history.location, state })
    }
  }, [])

  if (loading) return null;
  if (error) return <DataState.Error error={error} />;

  const { dealer } = data;
  const { uccs } = data.dealer;

  const dataSet = rowData(uccs, auth, id, showHidden);

  $(document).on('click', '#delete', function (event) {
    const id = event.target.dataset.id

    destroyUcc({ variables: { id } })
  })

  const newRoute = `/dealers/${id}/uccs/new`

  return (
    <>
      <Helmet>
        <title>UCCS</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="UCC" type={alertType} />
      }
      <DataTable
        columns={columns}
        dataSet={dataSet}
        pageTitle='UCCs'
        newRoute={newRoute}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
    </>
  );
};

export default UCCs;
