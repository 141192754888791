import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import { newUserSchema, UserForm } from './Form';
import { CREATE_USER, USERS } from './Queries'
import Loading from '../../../elements/Loading';

const NewUser = () => {
  const [showAlert, displayAlert] = useAlert();
  const history = useHistory();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [createUser] = useMutation(CREATE_USER,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: USERS
        }
      ],
      onCompleted() {
        history.push({
          pathname: `/system/users`,
          state: { alert: true, type: 'created' },
        })
        setFormSubmitting(false);
      }
    }
  );

  const NewForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Create a User</h1>
        </div>
        <Formik
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            mobilePhone: '',
            password: '',
            passwordConfirmation: '',
            roleIds: [],
            marketIds: [],
            hasWaiverLimit: false,
            waiverLimit: 0
          }}
          validationSchema={newUserSchema}
          onSubmit={handleSubmit}
        >
          <UserForm />
        </Formik>
      </div>
    )
  }

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createUser({ variables: values });
  };

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formContainer}>
          <NewForm />
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default NewUser;
