import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormikContext } from 'formik';
import * as BasicForm from "../../../../../elements/forms";
import * as Buttons from "../../../../../elements/buttons";
import { Spacing } from "../../../../../styles";
import { currency } from '../../../../../utils/stringHelpers';
import AccountSelect from '../../../../../elements/AccountSelect';

let modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px',
}

const labelStyle = {
    marginTop: "20px",
};

const ProcessCredit = ({
  showModal, setShowModal, handleSubmit, formSubmitting, dealerName
}) => {
  const { setFieldValue, values, errors, isValid } = useFormikContext();
  const handleClose = () => setShowModal(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledForm, setDisabledForm] = useState(false)

  useEffect(() => {
    if (values.accountId !== null && values.accountId !== '') {
      setFieldValue('accountId', parseInt(values.accountId))
    }
  }, [values.accountId])

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => setShowtModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Credit On Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <BasicForm.DollarInput
            name="amount"
            type="number"
            label="Amount to Credit On Account"
            labelStyle={labelStyle}
          />
          <AccountSelect
            scopes={['income']}
            label="Account to Debit"
            values={values}
          />
          <BasicForm.TextInput
            name="reference"
            type="text"
            label="Reference"
            labelStyle={labelStyle}
          />
          <BasicForm.TextInput
            name="memo"
            type="text"
            label="Note"
            labelStyle={labelStyle}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel type="cancel" text="Cancel" handleClose={handleClose} />
            <Buttons.Standard
              type="submit"
              onClick={() => handleSubmit(values)}
              text="Submit"
              disabled={!isValid || formSubmitting}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
};

export default ProcessCredit;
