import React, { useContext, useEffect, useState } from 'react';
import BasicAlert from '../../../../elements/BasicAlert';
import { useAlert } from '../../../../hooks/useAlert';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import DataTable from '../../../../elements/DataTable';
import AuthContext from '../../../../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import DataState from '../../../DataState';
import { setAlertData } from '../../../../utils/setAlertData';
import { useHideableResource } from '../../../../hooks/useHideableResource';
import { COLLATERAL_COVERAGE_CHANGES } from '../../../../queries/indexQueries';
import * as DateHelper from '../../../../utils/dateHelpers';
import * as SringHelper from '../../../../utils/stringHelpers';

const DESTROY = gql`
  mutation DestroyInsuranceProgramChange($id: ID!) {
    destroyInsuranceProgramChange (
      id: $id
    ) {
      errors { message, path }
    }
  }
`

const Changes = (props) => {
  const id = parseInt(useParams().id);
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const auth = useContext(AuthContext);
  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  const [destroyInsuranceProgramChange] = useMutation(DESTROY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: COLLATERAL_COVERAGE_CHANGES,
        variables: { id },
      }
    ]
  })

  const { loading, error, data } = useQuery(COLLATERAL_COVERAGE_CHANGES, {
    variables: { id },
  })

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  let { insuranceProgram: { insuranceProgramChanges }} = data;

  insuranceProgramChanges = insuranceProgramChanges.sort(function(a,b){

    return new Date(b.effectiveOn) - new Date(a.effectiveOn);
  });

  let currentRate = null;

  const dataSet = insuranceProgramChanges.map(function (program) {
    const showDate = new Date() < new Date(program.effectiveOn)
    const currentDate = new Date() === new Date(program.effectiveOn)

    if (auth.hasPolicy('Role', 'update') && showDate) {
      program.actions = [
        `
          <a href="/system/collateral-coverages/${id}/changes/${program.id}">Edit</a> |
          <a href="" id="delete" data-id=${program.id}>Delete</a>
        `
      ]
    }

    if ((!showDate || currentDate) && currentRate === null) currentRate = program.rate

    const rate = `${program.rate}%`;
    const dateDisplay = DateHelper.setDate(program.effectiveOn);

    return [
      rate,
      dateDisplay,
      program.actions || '',
    ]
  })

  $(document).on('click', '#delete', function (event) {
    let id = event.target.dataset.id
    id = parseInt(id, 10)

    destroyInsuranceProgramChange({ variables: { id } })
  })

  const columns = [
    {
      title: 'Rate'
    },
    {
      title: 'Effective On',
      render: DateHelper.renderDate
    },
    {
      title: 'Actions'
    }
  ];

  const newRoute = `/system/collateral-coverages/${id}/changes/new`

  return (
    <>
      <Helmet>
        <title>Collateral Coverages</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType="collateral coverage change" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={'Collateral Coverage Changes'}
        subHeader={`Current Rate: ${currentRate || 0}%`}
        unhideable={true}
        newRoute={newRoute}
        orderRows={false}
      />
    </>
  )
};

export default Changes;
