import React, { useState } from 'react';
import { useRouteMatch, useParams, Redirect, useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import DataState from '../../DataState';
import * as yup from 'yup';
import { ProcessFeeWaiver } from './ProcessFeeWaiver';

const WAIVE_FEE = gql`
  mutation WaiveFee($id: ID!, $amount: Decimal!, $memo: String) {
    waiveFee(id: $id, amount: $amount, memo: $memo) {
      quote {
        id
        txns {
          id
          txnLines {
            debit
            credit
            account {
              name
            }
          }
        }
      }
        errors {
        path
        message
      }
    }
  }
`
const schema = yup.object({
  amount: yup
    .number()
    .required('Required')
})

const NewFeeWaiver = ({ showModal, setShowModal, fee, feeId, amountDue, feeName }) => {
  const dealerId = parseInt(useParams().id);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();

  const handleRedirect = () => {
    setRedirect(true);
  }

  const [waiveFee, { loading, error }] = useMutation(WAIVE_FEE, {
    onCompleted(values) {
      setShowModal(false)
      window.location.reload();
    }
  })

  const handleSubmit = (values) => {
    if (values.memo === '') values.memo = null;
    waiveFee({ variables: values })
  }

  if (loading) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  if (redirect) {
    return <Redirect
      to={{
        pathname: `/dealers/${dealerId}/cars/${data.flooredCar.id}`,
        state: { alert: true, type: 'created', componentType: 'fee' }
      }}
    />
  };

  return (
    <Formik
      initialValues={{
        id: feeId || fee?.id,
        memo: '',
        amount: 0,
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <ProcessFeeWaiver
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        fee={fee}
        amountDue={amountDue}
        feeName={feeName}
      />
    </Formik>
  )
}

export default NewFeeWaiver;
