import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const DEALER_STATUS_FIELDS = `
  id
  name
  canFloor
  canPay
  canLogin
  sendAudits
  requireCorporateChange
  sendAvailability
  hidesDealer
  sendAaClosedMessage
  default
  chooseOnNsf
  hiddenAt
  canBeHidden
  excludeFromTitleRelease
`;

const DEALER_STATUS_FIELDS_AND_ERRORS = `
  dealerStatus {
    ${DEALER_STATUS_FIELDS}
  }
  ${ERRORS}
`;

export const DEALER_STATUSES = gql`
  query DealerStatuses($scopes: [String!]) {
    dealerStatuses(
      scopes: $scopes
    ) {
      ${DEALER_STATUS_FIELDS}
    }
  }
`;

export const DEALER_STATUS = gql`
  query DealerStatus($id: ID!) {
    dealerStatus(id: $id) {
      ${DEALER_STATUS_FIELDS}
    }
  }
`;

export const HIDE_DEALER_STATUS = gql`
  mutation HideDealerStatus($id: ID!) {
    hideDealerStatus(
      id: $id
    ) {
      ${DEALER_STATUS_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_DEALER_STATUS = gql`
  mutation UnhideDealerStatus($id: ID!) {
    unhideDealerStatus(
      id: $id
    )
    {
      ${DEALER_STATUS_FIELDS_AND_ERRORS}
    }
  }
`;

const DEALER_STATUS_VARIABLES = `
  $name: String!
  $canFloor: Boolean!
  $requireCorporateChange: Boolean
  $canPay: Boolean
  $canLogin: Boolean
  $sendAudits: Boolean
  $sendAvailability: String
  $hidesDealer: Boolean
  $sendAaClosedMessage: Boolean
  $chooseOnNsf: Boolean
  $default: Boolean
  $excludeFromTitleRelease: Boolean
`;

const DEALER_STATUS_ARGUMENTS = `
  name: $name
  canFloor: $canFloor
  canPay: $canPay
  requireCorporateChange: $requireCorporateChange
  canLogin: $canLogin
  sendAudits: $sendAudits
  sendAvailability: $sendAvailability
  hidesDealer: $hidesDealer
  sendAaClosedMessage: $sendAaClosedMessage
  chooseOnNsf: $chooseOnNsf
  default: $default
  excludeFromTitleRelease: $excludeFromTitleRelease
`;

export const CREATE_DEALER_STATUS = gql`
  mutation CreateDealerStatus(
    ${DEALER_STATUS_VARIABLES}
  ) {
    createDealerStatus(
      ${DEALER_STATUS_ARGUMENTS}
    ) {
      ${DEALER_STATUS_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_DEALER_STATUS = gql`
  mutation UpdateDealerStatus(
    $id: ID!
    ${DEALER_STATUS_VARIABLES}
  ) {
    updateDealerStatus(
      id: $id
      ${DEALER_STATUS_ARGUMENTS}
    ) {
      ${DEALER_STATUS_FIELDS_AND_ERRORS}
    }
  }
`;
