import React, { Fragment, useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import * as BasicForm from "../../elements/forms";
import FeeTypeOptionForm from "./FeeTypeOptionForm";

const termStyle = {
  container: {
    marginTop: "0px",
  },
  containerOdd: {
    backgroundColor: "#DADADA",
    padding: "15px",
  },
  containerEven: {
    backgroundColor: "#CFEED5",
    padding: "15px",
  },
  header: {
    marginBottom: "0px",
    fontWeight: "bold",
  },
  input: {
    padding: "2px",
    fontSize: "12px",
  },
};

const TermAttributes = ({ term, index, accounts, isEdit }) => {
  const { values, setValues } = useFormikContext();

  function isOdd(num) {
    return num % 2;
  }


  const hasId = values.termsAttributes[index].id

  let background;
  if (isOdd(index) === 0) {
    background = termStyle.containerOdd;
  } else {
    background = termStyle.containerEven;
  }

  useEffect(() => {
    if (isEdit) return;
    values.termsAttributes[index].termLinesAttributes = [
      {
        principalBelow: null,
        curtailmentPercentage: 0,
        termLineFeesAttributes: [{}],
      }
    ]

    setValues(values)
  }, [values.termsAttributes[index].feeType])

  return (
    <div style={background}>
      <h6 style={termStyle.header}>Term #{index + 1}</h6>
      <BasicForm.Select name={`termsAttributes[${index}].type`} type="text">
        <option value="">Please Select a Term Type</option>
        <option value="Term">Term</option>
        <option value="Extension">Extension</option>
      </BasicForm.Select>
      {values.termsAttributes[index].type && (
        <Fragment>
          <BasicForm.TextInput
            name={`termsAttributes[${index}].termDays`}
            type="number"
            label={`Number of Days in this ${
              values.termsAttributes[index].type
            }`}
          />
          <BasicForm.Select
            label={`What type of Fees will be used in this ${
              values.termsAttributes[index].type
            }`}
            name={`termsAttributes[${index}].feeType`}
            value={values.termsAttributes[index].feeType || ''}
            type="text"
            disabled={isEdit && hasId}
          >
            <option value="">
              Please Select a Fee Type To Display Options
            </option>
            <option value="Single">
              A Single Fee added on the first day of the term
            </option>
            <option value="Multiple">
              Multiple Fees earned throughout the term
            </option>
            <option value="Interval">
              Interval Fees earned throughout the term
            </option>
            <option value="Interest">This is an Interest Only Term</option>
          </BasicForm.Select>
          {values.termsAttributes[index].feeType && (
            <FeeTypeOptionForm
              key={index}
              accounts={accounts}
              index={index}
              option={values.termsAttributes[index].feeType}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default TermAttributes;
