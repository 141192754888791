import React, { useState, useRef } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useQuery, useMutation } from '@apollo/client';
import { TITLE_LOG } from '../../../queries/viewQueries';
import DataState from '../../DataState';
import { UPDATE_TITLE_LOG, CREATE_FILE_UPLOAD } from './Queries';
import { Spacing } from '../../../styles';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { DateHelpers } from "../../../utils";
import { Form } from './Form';
import { editTitleLogSchema } from './Schema';

const Update = props => {
  const { handleClose, show, editId, refetch, setEditTitleLogAlert } = props;

  const { loading, error, data } = useQuery(TITLE_LOG,
    { variables: { id: editId } }
  )

  const [updateTitleLog, {loading: mutationLoading}] = useMutation(
    UPDATE_TITLE_LOG, {
      onCompleted(values) {
        setEditTitleLogAlert(true)
        handleClose();
        refetch()
      }
    }
  );

  let submittedValues = useRef();

  const [createFileUpload, { loading: fileLoading}] = useMutation(
    CREATE_FILE_UPLOAD, {
      onCompleted(values) {
        const fileId = parseInt(values.createFileUpload.fileUpload.id)
        submittedValues.current.fileUploadId = fileId
        updateTitleLog({ variables: submittedValues.current })
      }
    }
  )

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const handleSubmit = (values) => {
    submittedValues.current = values
    if (values.file !== undefined) {
      createFileUpload({ variables: values })
    } else {
      updateTitleLog({ variables: values })
    }
  };

  const UpdateTitleLog = () => {
    let uploadId = data?.titleLog?.fileUpload?.id
    let carId = useParams().carId
    let titleLog = data.titleLog

    return (
      <div style={Object.assign({}, Spacing.formWrapper)}>
        <Formik
          initialValues={{
            id: editId,
            flooredCarId: parseInt(carId),
            titleStatusId: parseInt(titleLog.titleStatus.id),
            trackingNumber: titleLog.trackingNumber || '',
            notes: titleLog.notes || undefined,
            fileUploadId: parseInt(uploadId),
            name: `Scanned Title ${DateHelpers.renderDate(new Date())}`,
            type: 'AWS Storage',
            file: undefined,
            ownerType: 'FlooredCar',
            ownerId: parseInt(carId),
            shipTitle: titleLog.shipTitle,
            shipToType: titleLog.shipToType,
            shipToId: titleLog.shipToId,
            otherAddress: {
              name: '',
              street: '',
              city: '',
              state: '',
              zip: '',
              phone: ''
            }
          }}
          validationSchema={editTitleLogSchema}
          onSubmit={handleSubmit}
        >
          <Form titleLog={titleLog} edit={true} />
        </Formik>
      </div>
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Edit Title Log
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          (fileLoading || mutationLoading) ?
            <p>Loading...</p> :
            <UpdateTitleLog />
        }
      </Modal.Body>
    </Modal>
  )
}

export default Update;
