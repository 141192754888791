import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { useAlert } from '../../../hooks/useAlert';
import { Form, statusSchema } from './Form';
import { Spacing, Typography } from '../../../styles';
import { titleCase } from '../../../utils/stringHelpers';
import DataState from '../../DataState';
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'
import { DEALER_STATUS, UPDATE_DEALER_STATUS } from './queries';

const UpdateDealerStatus = () => {
  const { id } = useParams();
  const [showAlert, displayAlert] = useAlert();
  const [redirect, setRedirect] = useState(false);

  const serverSideErrorProps = {
    SAVE_MUTATION: UPDATE_DEALER_STATUS,
    onCompleteRedirectTo: '/system/dealer-statuses'
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const { loading, error, data } = useQuery(DEALER_STATUS, { variables: { id } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { dealerStatus } = data;

  const UpdateForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/dealer-statuses",
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Edit Dealer Status: {titleCase(dealerStatus.name)}</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              id: dealerStatus.id,
              name: dealerStatus.name,
              canFloor: dealerStatus.canFloor,
              canPay: dealerStatus.canPay,
              canLogin: dealerStatus.canLogin,
              requireCorporateChange: dealerStatus.requireCorporateChange,
              sendAudits: dealerStatus.sendAudits,
              sendAvailability: dealerStatus.sendAvailability,
              hidesDealer: dealerStatus.hidesDealer,
              sendAaClosedMessage: dealerStatus.sendAaClosedMessage,
              chooseOnNsf: dealerStatus.chooseOnNsf,
              default: dealerStatus.default,
              excludeFromTitleRelease: dealerStatus.excludeFromTitleRelease,
            }}
            validationSchema={statusSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <UpdateForm />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>Edit {titleCase(dealerStatus.name)} Dealer Status</title>
      </Helmet>
      <DisplayContent />
    </>
  );
};

export default UpdateDealerStatus;
