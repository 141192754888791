import React, { useState, Fragment } from 'react';
import { Formik } from 'formik';
import { Form as FormikForm, useFormikContext } from "formik";
import { Spacing, Typography } from '../../styles';
import Loading from "../../elements/Loading";
import DataState from '../DataState';
import { CURRENT_USER } from '../../queries/currentUser';
import { useQuery } from '@apollo/client';
import { troubleTicketSchema, Form } from './Form';
import { handleSubmit } from './handleSubmit';
import { Alert } from './Alert';

const TroubleTicket = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [alert, displayAlert] = useState({ message: null, variant: null });
  const { loading, error, data } = useQuery(CURRENT_USER)

  const submitTicket = (values) => {
    handleSubmit(values, setFormSubmitting, displayAlert);
  }

  if (loading) return <DataState.Loading />;
  if (error) return <DataState.Error error={error || mutationError} />;

  const { currentUser } = data;

  const New = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Submit a Trouble Ticket</h1>
        </div>
        <Formik
          initialValues={{
            name: currentUser.fullName,
            phone: '',
            email: currentUser.email,
            url: '',
            subject: '',
            problem: '',
            expectation: '',
            supplied_url: document.referrer,
            user_id: currentUser.id,
          }}
          validationSchema={troubleTicketSchema}
          onSubmit={submitTicket}
        >
        <FormikForm>
          <Form formSubmitting={formSubmitting}/>
        </FormikForm>
      </Formik>
    </div>
    )
  }

const DisplayContent = () => {
  if (formSubmitting) {
    return (
      <div style={Spacing.formContainer}>
        <Loading />
      </div>
    );
  }
  return (
    <div style={Spacing.formContainer}>
      <New />
    </div>
  );
};

return (
  <Fragment>
    <Alert data={alert} />
    <DisplayContent />
  </Fragment>
  );
 };

export default TroubleTicket;
