import { gql } from '@apollo/client';
import { BANK_ACCOUNTS_SUBQUERY } from '../BankAccounts/queries';
import { NOTES_SUBQUERY } from '../Notes/queries';

const ERRORS = `errors { path, message }`;

const AUCTION_FIELDS = `
  id
  name
  street
  city
  state
  zip {
    value
    formatted
  }
  xlgAuction
  auctionAccessId
  status
  url
  primaryContact {
    id
    fullName
    officePhone {
      value
      formatted
    }
  }
  hiddenAt
  createdAt
  updatedAt
  canBeHidden
`;

const AUCTION_FIELDS_AND_ERRORS = `
  auction {
    ${AUCTION_FIELDS}
  }
  ${ERRORS}
`;

export const AUCTIONS = gql`
  query Auctions($scopes: [String!]) {
    auctions(
      scopes: $scopes
    ) {
      ${AUCTION_FIELDS}
    }
  }
`;

export const HIDE_AUCTION = gql`
  mutation HideAuction($id: ID!) {
    hideAuction(
      id: $id
    ) {
      ${AUCTION_FIELDS_AND_ERRORS}
    }
  }
`

export const UNHIDE_AUCTION = gql`
  mutation UnhideAuction($id: ID!) {
    unhideAuction(
      id: $id
    ) {
      ${AUCTION_FIELDS_AND_ERRORS}
    }
  }
`;

export const AUCTION_BANK_ACCOUNTS = gql`
  query Auction($id: ID!, $scopes: [String!]) {
    auction(id: $id) {
      id
      name
      ${BANK_ACCOUNTS_SUBQUERY}
    }
  }
`;

export const AUCTION_NOTES = gql`
  query Auction($id: ID!, $scopes: [String!]) {
    auction(id: $id) {
      id
      name
      ${NOTES_SUBQUERY}
    }
  }
`;
