import { gql } from '@apollo/client'

export const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      primaryPlace {
        id
      }
      defaultMailingLocation {
        id
      }
    }
  }
`;

export const UPDATE_DEALER = gql`
  mutation UpdateDealerPlaceDefaults($id: ID!, $primaryPlaceId: Int, $defaultMailingLocationId: Int)
  {
    updateDealerPlaceDefaults(
      id: $id
      primaryPlaceId: $primaryPlaceId
      defaultMailingLocationId: $defaultMailingLocationId
    ) {
      dealer {
        id
        name
      }
    }
  }
`;

export const HIDE_PLACE = gql`
  mutation HidePlace($id: ID!) {
    hideDealerPlace(
      id: $id
    )
    {
      dealerPlace {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

export const UNHIDE_PLACE = gql`
  mutation UnhidePlace($id: ID!) {
    unhideDealerPlace(
      id: $id
    )
    {
      dealerPlace {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

export const DEALER_PLACE = gql`
  query DEALER_PLACE($id: ID!) {
    dealerPlace(id: $id) {
      name,
      type,
      phone { value, formatted },
      fax { value, formatted },
      attention,
      street,
      city,
      state,
      zip { value, formatted },
    }
  }
`;

export const UPDATE_DEALER_PLACE = gql`
  mutation UpdateDealerPlace(
    $id: ID!,
    $name: String!,
    $type: String!,
    $phone: String,
    $fax: String,
    $attention: String,
    $street: String,
    $city: String,
    $state: String,
    $zip: String,
   ) {
    updateDealerPlace(
      id: $id
      name: $name
      type: $type
      phone: $phone
      fax: $fax
      attention: $attention
      street: $street
      city: $city
      state: $state
      zip: $zip
    ) {
      dealerPlace {
        id
        name
        type
        phone { value, formatted }
        fax { value, formatted }
        attention
        street
        city
        state
        zip { value, formatted }
      }
    }
  }
`;

export const CREATE_DEALER_PLACE = gql`
  mutation CreateDealerPlace($dealerId: Int!, $name: String!, $type: String!,
  $phone: String, $fax: String, $attention: String, $street: String,
  $city: String, $state: String, $zip: String)
  {
    createDealerPlace (
      dealerId: $dealerId
      name: $name
      type: $type
      phone: $phone
      fax: $fax
      attention: $attention
      street: $street
      city: $city
      state: $state
      zip: $zip
    ) {
      dealerPlace { id }
      errors { message, path }
    }
  }
`;
