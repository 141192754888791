import React, { useContext, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import Info from '../Info';
import { useAlert } from '../../../hooks/useAlert';
import AuthContext from '../../../contexts/AuthContext';
import { setUniqueAlertData } from '../../../utils/setAlertData';
import { useParams } from 'react-router-dom';
import DataState from '../../DataState';
import { useQuery } from '@apollo/client';
import { setAlertData } from '../../../utils/setAlertData';
import BasicAlert from '../../../elements/BasicAlert';
import DataTable from '../../../elements/DataTable';
import * as StringHelper from '../../../utils/stringHelpers';
import * as DateHelpers from '../../../utils/dateHelpers';
import { APPROVED_SOURCES_CARS } from '../../../queries/indexQueries';

const Cars = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();

  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(APPROVED_SOURCES_CARS, { variables: { id } });

  const auth = useContext(AuthContext);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { approvedSource } = data;
  const dataSet = approvedSource.flooredCars.map(function (car) {
    car = { ...car };

    if (auth.hasPolicy('FlooredCar', 'read')) {
      car.actions = `
        <a href="/dealers/${car.dealerLine.dealer.id}/cars/${car.id}"/>View</a>
      `
    }

    const { currentFees, currentInterest, currentPrincipal, currentCollateralCoverage } = car;

    const make = StringHelper.titleCase(car.make) || '';
    const model = StringHelper.titleCase(car.model) || '';
    const color = StringHelper.titleCase(car.color) || '';
    const age = car.termData?.totalDays || '';
    const nextDueDate = DateHelpers.setDate(car.currentDueOn) || '';
    const loanDate = DateHelpers.setDate(car.loanDate) || '';

    const totalDue = (
      parseFloat(currentFees) + parseFloat(currentInterest) + parseFloat(currentPrincipal) +
      parseFloat(currentCollateralCoverage)
    )

    const dealerName = `<a href="/dealers/${car.dealerLine.dealer.id}"/>${StringHelper.titleCase(car.dealerLine.dealer.name)}</a>`
    const vinLink = `<a href="/dealers/${car.dealerLine.dealer.id}/cars/${car.id}">${car.vin}</a>`

    return [
      dealerName,
      vinLink,
      car.year,
      make,
      model,
      color,
      age,
      loanDate,
      nextDueDate,
      StringHelper.currency(currentPrincipal),
      StringHelper.currency(currentFees),
      StringHelper.currency(currentInterest),
      StringHelper.currency(currentCollateralCoverage),
      StringHelper.currency(totalDue),
      StringHelper.titleCase(car.titleStatus) || '',
      car.actions
    ]
  })

  const columns = [
    {
      title: 'Dealer'
    },
    {
      title: 'VIN',
    },
    {
      title: 'Year',
      render: DateHelpers.renderDate,
    },
    {
      title: 'Make'
    },
    {
      title: 'Model'
    },
    {
      title: 'Color'
    },
    {
      title: 'Days on Floorplan',
      className: 'text-right'
    },
    {
      title: 'Loan Date',
      render: DateHelpers.renderDate
    },
    {
      title: 'Next Due Date'
    },
    {
      title: 'Principal',
      className: 'text-right'
    },
    {
      title: 'Fees Due',
      className: 'text-right'
    },
    {
      title: 'Interest Due',
      className: 'text-right'
    },
    {
      title: 'Collateral Coverage',
      className: 'text-right'
    },
    {
      title: 'Total Due',
      className: 'text-right'
    },
    {
      title: 'Title Status',
    },
    {
      title: 'Functions'
    },
  ];

  let saleTimeAlertData = setUniqueAlertData(props, setAlertType, displayAlert);
  return (
    <>
      <Info {...saleTimeAlertData} />
      {showAlert &&
        <BasicAlert componentType="car" type={alertType} />
      }
      <DataTable
        dataSet={dataSet}
        columns={columns}
        pageTitle={`Cars`}
        unhideable
        uncreatable
      />
    </>
  )
};

export default Cars;

