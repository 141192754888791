import { gql } from '@apollo/client';

export const CREATE_DEALER_TXN = gql`
  mutation CreateDealerTxn(
    $dealerId: Int!
    $direction: String!
    $type: String!
    $accountId: Int
    $bankAccountId: Int
    $bankDate: ISO8601Date
    $amount: Decimal!
    $ach: Boolean
    $memo: String
    $name: String
    $txnType: String
    $reference: String
    $advanceType: String
  ) {
    createDealerTxn(
      dealerId: $dealerId
      direction: $direction
      type: $type
      accountId: $accountId
      bankAccountId: $bankAccountId
      bankDate: $bankDate
      amount: $amount
      ach: $ach
      memo: $memo
      name: $name
      txnType: $txnType
      reference: $reference
      advanceType: $advanceType
    ) {
      txn {
        id
        txnLines { credit, debit, account { gl } }
      }
      errors { path, message }
    }
  }
`;

export const CREATE_TXN = gql`
  mutation CreateTxn(
      $type: String!
      $bankDate: ISO8601Date!
      $name: String
      $paymentTermId: Int
      $memo: String
      $purpose: String
      $reference: String
      $entityType: String
      $entityId: Int
      $txnLinesAttributes: [TxnLineInput!]!
      $booksClosedPassword: String
    ) {
    createTxn (
      type: $type
      bankDate: $bankDate
      name: $name
      paymentTermId: $paymentTermId
      memo: $memo
      purpose: $purpose
      reference: $reference
      entityType: $entityType
      entityId: $entityId
      txnLinesAttributes: $txnLinesAttributes
      booksClosedPassword: $booksClosedPassword
     ) {
      txn {
        id
        txnLines {
          id
        }
      }
      errors { message, path }
    }
  }
`;
