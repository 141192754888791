import React, { Fragment } from "react";
import { useFormikContext } from "formik";
import SingleFeeForm from "../SingleFee";

const IntervalFeesForm = ({ index, accounts }) => {
  return (
    <SingleFeeForm index={index} accounts={accounts} allowInterval />
  );
};

export default IntervalFeesForm;
