import React, { useState, useEffect } from 'react';
import { Formik, FieldArray, Field, getIn } from 'formik';

export const ErrorMessage = ({ name }) => (

   <Field name={name}>
    {({ field, form, meta }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);

        return touch && error
           ? error
           : null;
     }}
   </Field>
);
