import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import { Formik } from 'formik';
import ProcessPaymentsReport from './ProcessPaymentsReport';
import { AUTH_TOKEN } from '../../../constants';
import secrets from '../../../secrets';
import { useParams } from 'react-router-dom';
import BasicAlert, { ReportProcessingAlert } from '../../../elements/BasicAlert';

export const reportSchema = yup.object({
  endOn: yup.date().max(new Date(), 'End Date must be on or before today')
});

const index = () => {
  const [customAlert, displayCustomAlert] = useState(false);

  const dealerId = parseInt(useParams().id);

  const printReport = (values) => {
    const axios = require('axios');
    const token = localStorage.getItem(AUTH_TOKEN);
    const FileDownload = require('js-file-download');
    axios({
      method: 'get',
      url: `${secrets.api.baseUri}/payments-report`,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/xlsx',
        'Authorization': `${token}`,
        'JWT_AUD': 'react'
      },
      params: {
        'start-on': values.startOn === '' ? null : values.startOn,
        'end-on': values.endOn === '' ? null : values.endOn,
        'dealer-statuses': values['dealer-statuses']
          ? values['dealer-statuses']
          : null,
        'dealer-id': values['dealerId']
      }
    })
      .then(res => {
        const response = res.data;
        var date = new Date();
        var timestamp = date.getTime();
        if (parseInt(res.status) < 300) displayCustomAlert(true);
      })
  }

  return (
    <div>
      { customAlert && <ReportProcessingAlert /> }
      <Formik
        initialValues={{
          startOn: '',
          endOn: '',
          dealerId: dealerId
        }}
        validationSchema={reportSchema}
        onSubmit={printReport}
      >
        <ProcessPaymentsReport dealerId={dealerId} handleSubmit={printReport} />
      </Formik>
    </div>
  )
}

export default index;
