import React, { useState, useContext, createContext } from 'react';
import AuthContext from 'components/src/contexts/AuthContext';

// context instance - exported and can be consumed by any child component of UserProvider
export const UserContext = createContext();

// This context provider is passed to any and all components requiring this context
export const UserProvider = ({ children }) => {
  const auth = useContext(AuthContext);

  // Create a global setter and getter for currentMarket accessible throughout the app.
  const [currentMarket, setCurrentMarket] = useState({
    id: null,
    name: '',
  })

  // Create a global flag to allow a market user to view all of their markets instead of just one.
  const [viewAllMarketUserMarkets, setAllMarketUserMarkets] = useState(false)

  const filterMarketIds = () => {
    const marketId = parseInt(currentMarket.id);

    if (viewAllMarketUserMarkets) {
      return auth?.user?.marketIds;
    } else if (marketId) {
      return [marketId];
    } else {
      return null;
    }
  }

  const isCorporate = () => 0 === auth?.user?.marketIds?.length

  // Wrap the parent component, then each child anywhere in the application
  //  can subscribe to the data in the provider and subsequent updates, making both setters and
  //  getters available throughout.
  return (
    <UserContext.Provider
      value={{
        currentMarket,
        setCurrentMarket,
        viewAllMarketUserMarkets,
        setAllMarketUserMarkets,
        filterMarketIds,
        isCorporate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
