import React, { useEffect, useRef, useState } from 'react';
import * as BasicForm from '../../../elements/forms';
import { divStyle } from './Style';
import { useFormikContext } from 'formik';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import AccountSelect from './AccountSelect';
import { SourceType } from './SourceType';
import { Advance } from './Advance';

export const ExternalTypeOptions = () => {
  let [paymentMethod, setPaymentMethod] = useState(null);

  const {
    setFieldValue,
    values: { sourceType, externalPaymentMethod },
  } = useFormikContext();

  useEffect(() => {
    setPaymentMethod(externalPaymentMethod)
  }, [externalPaymentMethod])

  useEffect(() => {
    setFieldValue("sourceId", "");
    setFieldValue("bankAccountId", "");
  }, [sourceType]);

  return (
    [
      <PaymentMethod key="externalPaymentMethod" />,
      <AccountSelect key="accountSelect" />,
      <SourceType includeOther key="SourceType" />,
      <Advance key='check' type={paymentMethod} />,
    ]
  )
}

const PaymentMethod = () => {
  return <div style={divStyle}>
    <BasicForm.StyledLabel>
      What will the method of payment be?
    </BasicForm.StyledLabel>
      <BasicForm.Radio
        name="externalPaymentMethod"
        type="radio"
        label="Check"
        value="Check"
      />
      <BasicForm.Radio
        name="externalPaymentMethod"
        type="radio"
        label="ACH"
        value="ACH"
      />
      <BasicForm.Radio
        name="externalPaymentMethod"
        type="radio"
        label="Wire Transfer"
        value="Wire"
      />
      <BasicForm.InlineStyledErrorMessage>
        <ErrorMessage name='externalPaymentMethod'/>
      </BasicForm.InlineStyledErrorMessage>
    </div>
}
