import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Spacing } from '../../../styles';
import { useFormikContext } from 'formik';
import * as BasicForm from "../../../elements/forms";
import * as Buttons from '../../../elements/buttons'
import * as yup from 'yup';

export const voidSchema = yup.object({})

export const Form = ({ showModal, setShowModal, handleSubmit, txn }) => {

  const { values } = useFormikContext();

  return <>
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
      onHide={() => setShowModal(false)}
      show={showModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Void Transaction
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <BasicForm.TextInput
          name="bankDate"
          type="date"
          label="Bank Date"
          labelStyle={labelStyle}
        />
        <BasicForm.Checkbox
          name="nsf"
          type="checkbox"
          label="NSF"
        />
        { txn.purpose === 'Payment' &&
          <BasicForm.Checkbox
            name="creditOnAccount"
            type="checkbox"
            label="Credit to On Account"
          />
        }
        <BasicForm.TextArea
          name="memo"
          label="Memo"
        />
        <div style={Spacing.buttonRow}>
          <Buttons.ModalCancel
            handleClose={() => setShowModal(false)}
            text="Cancel"
          />
          <Buttons.Standard
            type="submit"
            onClick={() => handleSubmit(values)}
            text="Submit"
          />
        </div>
      </Modal.Body>
   </Modal>
  </>
}

let modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px',
}

const labelStyle = {
    marginTop: "0px",
};
