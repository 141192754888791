import { Colors } from 'components/src/styles';

export const addRecord = {
  color: Colors.xlGreen,
  fontSize: 24,
  float: 'right',
  marginRight: 30,
}

export const page = {
  paddingBottom: '50px'
}

export const containerStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '600px',
  height: '900px'
}

export const orange = {
  color: Colors.xlOrange,
  paddingTop: "10px",
  paddingLeft: "10px",
  textAlign: 'center'
}

export const green = {
  color: Colors.xlGreen,
  textAlign: 'center',
  paddingBottom: '20px'
}

export const errorStyle = {
  fontWeight: 'bold',
  color: '#A1473F',
  fontStyle: 'italic',
  fontSize: '16px'
}

export const missingdataErrorStyle = {
  paddingTop: '20px'
}
