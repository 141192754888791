import React, { useState, useEffect } from "react";
import { useRouteMatch, useParams, Redirect } from "react-router-dom";
import { Form, schema } from "./Form";
import { gql, useMutation, useQuery } from "@apollo/client";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { useFormikContext } from "formik";
import * as Buttons from "../../../elements/buttons";
import { Spacing } from "../../../styles";
import * as Formatting from '../../../styles/formatting';
import DataState from "../../DataState";
import * as DateHelper from '../../../utils/dateHelpers';
import * as StringHelper from '../../../utils/stringHelpers';
import { getServerSideErrors } from '../../../utils/errorHelpers';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors';

const PAY_QUOTE = gql`
  mutation PayQuote(
    $id: ID!
    $txnType: String!
    $name: String
    $reference: String
    $accountId: Int
    $total: Decimal
  ) {
    payQuote(
      id: $id
      name: $name
      txnType: $txnType
      reference: $reference
      accountId: $accountId
      total: $total
    ) {
      quote {
        id
        txns {
          id
          reference
          type
          quoteCar {
            id
            flooredCar {
              id
            }
          }
          quoteCars {
            id
          }
          txnLines {
            debit
            credit
            account {
              name
            }
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

const ACH_QUOTE = gql`
  mutation AchQuote($id: ID!, $bankAccountId: Int) {
    achQuote(id: $id, bankAccountId: $bankAccountId) {
      quote {
        id
        status
        quoteFees { id, amount }
      }
      errors { path, message }
    }
  }
`;

const DEALER = gql`
  query Dealer($id: ID!) {
    dealer(id: $id) {
      id
      name
      payableFees {
        id
        amount
        due
      }
    }
  }
`;

const NewPayment = ({
  propAlertData,
  showModal,
  setShowModal,
  dealerName,
  reserveBalance,
  onAccountBalance,
  quoteAmount,
  quoteId,
  dealerFees,
  quoteDate,
  setPaymentButton
}) => {
  const dealerId = parseInt(useParams().id);
  const [redirect, setRedirect] = useState(false);
  const id = dealerId
  const { loading, error, data } = useQuery(DEALER, { variables: { id } });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [payQuote, { loading: quoteLoading, error: quoteError }] = useMutation(
    PAY_QUOTE,
    {
      onCompleted(values) {
        setRedirect(true);
      }
    }
  );

  const [achQuote, { loading: achLoading, error: achError }] = useMutation(
    ACH_QUOTE,
    {
      onCompleted(values) {
        const serverErrors = getServerSideErrors(values);
        if (serverErrors) {
          setServerSideErrors(serverErrors);
          setFormSubmitting(false);
        } else {
          setRedirect(true);
        }
      },
    }
  );

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    if (values.advanceType !== "source") delete values.bankAccountId;
    if (values.name === "") values.name = undefined;
    if (values.reference === "") values.reference = undefined;
    if (values.advanceType === "On Account") {
      values.txnType = "On Account";
    }
    if (values.advanceType === "Dealer Reserve") {
      values.txnType = "Dealer Reserve";
    }
    if (values.accountId === "") values.accountId = undefined;

    if (values.advanceType === "source") {
      delete values.advanceType;
      delete values.total;
      values.bankAccountId = parseInt(values.bankAccountId);
      achQuote({ variables: values });
    } else {
      delete values.advanceType, payQuote({ variables: values });
    }
  };

  if (quoteLoading || achLoading || loading) return <DataState.Loading />;
  if (quoteError || achError || error)
    return <DataState.Error error={quoteError || achError || error} />;

  let total = 0;
  const sum = data.dealer.payableFees.map((num) => {
    total += num.due
  })

  if (redirect)
    return (
      <Redirect
        to={{
          pathname: `/dealers/${dealerId}`,
          state: { alert: true, type: "processed", componentType: "payment" },
        }}
      />
    );

  if (dealerFees === true) {
    total = 0
  }

  return (
    <Formik
      initialValues={{
        id: quoteId,
        advanceType: "source",
        total: undefined,
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <ProcessPaymentModal
        showModal={showModal}
        setShowModal={setShowModal}
        dealerName={dealerName}
        reserveBalance={reserveBalance}
        onAccountBalance={onAccountBalance}
        handleSubmit={handleSubmit}
        quoteAmount={quoteAmount}
        total={total}
        formSubmitting={formSubmitting}
        setFormSubmitting={setFormSubmitting}
        serverSideErrors={serverSideErrors}
        quoteDate={quoteDate}
        setPaymentButton={setPaymentButton}
      />
    </Formik>
  );
};

const ProcessPaymentModal = ({
  showModal,
  setShowModal,
  dealerName,
  reserveBalance,
  onAccountBalance,
  handleSubmit,
  quoteAmount,
  total,
  formSubmitting,
  setFormSubmitting,
  serverSideErrors,
  quoteDate,
  setPaymentButton
}) => {
  const { values, errors, isValid } = useFormikContext();
  const [disabled, setDisabled] = useState(false);

  let modalBodyStyle = {
    height: "100%",
    paddingBottom: "20px",
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => {setShowModal(false); setPaymentButton(false)}}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Information | Total: {StringHelper.currency(quoteAmount)} | Payment Date: {DateHelper.setDate(quoteDate)}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <p>Payment: {StringHelper.currency(quoteAmount - total)} | Dealer Fees: {StringHelper.currency(total)}</p>
          <Form
            dealerName={dealerName}
            reserveBalance={reserveBalance}
            onAccountBalance={onAccountBalance}
            quoteAmount={quoteAmount}
            setDisabled={setDisabled}
          />
          { serverSideErrors &&
            <div style={Formatting.serverSideErrorStyles}>{serverSideErrors}</div>
          }
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel text="Cancel" handleClose={() => {setShowModal(false); setPaymentButton(false)}} />
            <Buttons.Standard
              type="submit"
              onClick={() => {handleSubmit(values); setFormSubmitting(true);}}
              text="Submit"
              disabled={!isValid || disabled || formSubmitting}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewPayment;
