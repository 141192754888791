import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import DataTables from '@preflighttech/preflight-tables';
import Header from '../Header';
import { UserContext } from 'components/src/contexts/UserContext';
import { NSFS } from './queries';
import { preflightTableStyles } from 'components/src/styles';
import { setDate } from 'components/src/utils/dateHelpers';
import { currency } from 'components/src/utils/stringHelpers';
import {
  dealerColumn
} from 'components/src/components/Dealers/columnDefinitions';

const columns = [
  dealerColumn('market'),
  dealerColumn('dealerName'),
  dealerColumn('dealerStatus'),
  dealerColumn('dealerCategories'),
  dealerColumn('creditLine'),
  dealerColumn('currentPrincipal'),
  dealerColumn('accountNumber'),
  {
    key: 'inspectionOn',
    label: 'Last Lot Audit Date',
    value: ({ entry }) => [...entry.dealer.recentAudits].pop()?.inspectionOn,
    content: ({ value }) => (value && setDate(value)),
  },
  {
    key: 'foundPercentage',
    label: 'Found Percentage',
    minViewportWidth: 1200,
    content: ({ value }) => value ? `${value}%` : '',
  },
  dealerColumn('unverifiedAuditCarsCount'),
  {
    key: 'unverifiedAuditCarsValue',
    label: 'Value of Currently Missing Units',
    minViewportWidth: 800,
    value: ({ entry }) => (
      entry.dealer.recentAudits[0]?.unverifiedAuditCarsValue
    ),
    content: ({ value }) => currency(value),
  },
  {
    key: 'openNsfCount',
    label: '# NSFs Unpaid/ Unwaived',
    minViewportWidth: 1200,
    value: ({ entry }) => entry.dealer.openNsfCount,
  },
  {
    key: 'achStatus',
    label: 'ACH',
    minViewportWidth: 1400,
    value: ({ entry }) => (
      (entry.flooredCar && entry.flooredCar.scheduledAchs > 0) ? 'Pending' : ''
    )
  },
  {
    key: 'pastDueCount',
    label: '# Past Due',
    minViewportWidth: 1200,
    value: ({ entry }) => entry.dealer.pastDueCount,
  },
  {
    key: 'sotCount',
    label: '# SOT',
    minViewportWidth: 1200,
    value: ({ entry }) => entry.dealer.sotCount,
  },
  {
    key: 'repoCount',
    label: '# Repo',
    minViewportWidth: 1200,
    value: ({ entry }) => entry.dealer.repoCount,
  },
  {
    key: 'unitNumber',
    label: 'Unit #',
    value: ({ entry }) => entry.flooredCar?.unitNumber,
  },
  {
    key: 'vin',
    label: 'VIN',
    value: ({ entry }) => entry.flooredCar?.vin,
    content: ({ entry }) => (
      entry.flooredCar ? (
        <a href={`/dealers/${entry.dealer.id}/cars/${entry.flooredCar.id}`}>
          {entry.flooredCar.vin}
        </a>
      ) : (
        <a href={`/fee/${entry.fee.id}/transactions`}>
          {entry.fee.name}
        </a>
      )
    )
  },
  {
    key: 'setupPayment',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      entry.flooredCar ? (
        <a
          href={
            `/dealers/${entry.dealer.id}/cars/${entry.flooredCar.id}/payments`
          }
        >
          Setup Payment
        </a>
      ) : (
        <a href={`/dealers/${entry.dealer.id}/payments`}>
          Setup Payment
        </a>
      )
    )
  },
];

const Collection = () => {
  const user = useContext(UserContext);

  const queryVariables = {};

  if (!queryVariables.marketIds) {
    queryVariables.marketIds = user.filterMarketIds() || [];
  }

  const { loading, error, data } = useQuery(
    NSFS,
    { variables: queryVariables }
  );

  return (
    <>
      <Header title="Collections; NSFs" />
      <DataTables.Simple
        data={data?.openNsfs?.entries}
        columns={columns}
        htmlTable
        movableColumns
        styles={preflightTableStyles}
      />
    </>
  );
};

export default Collection;
