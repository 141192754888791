import React, { useEffect, useState, Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { Form as FormikForm } from 'formik';
import Form from 'react-bootstrap/Form';
import { useFormikContext } from 'formik';
import { Spacing, Typography } from '../../styles';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { useQuery } from '@apollo/client';
import { USER_SELECTS } from '../../queries/selects';
import { DEALER_STATUSES } from '../../queries/indexQueries';
import { DEALER_NAMES, AUTOMATED_FEE_NAMES } from '../../queries/selects';
import { MARKETS } from 'components/src/components/System/Markets/queries';
import DataState from '../DataState';
import Select from 'react-select';
import { Colors } from '../../styles';
import * as yup from 'yup';
import { DateHelpers } from '../../utils';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import {
  handleMultiSelect,
  isFinancialReport,
  hasFinancialAuth
} from './functions';
import { allowFilterOptions } from './Report';
import { isSafari } from "react-device-detect";

const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/

export const reportSchema = yup.object({
  endOn: yup
    .string()
    .trim()
    .matches(regex, 'invalid date')
    .typeError('Must be a valid date')
    .max(new Date(), 'End Date must be on or before today'),
  startOn: yup
    .string()
    .trim()
    .matches(regex, 'invalid date').typeError('Must be a valid date')
});

const financialReportSchema = yup.object({
  endOn: yup
    .string()
    .trim()
    .matches(regex, 'invalid date')
    .typeError('Must be a valid date')
});

const FINANCIAL_REPORTS = [
  'balance-sheet', 'income-statement', 'cash-flow', 'trial-balance'
];

const NO_VALIDATION_REPORTS = ['annual-review', 'kpis'];

const ReportsFilter = (props) => {
  let { handleclose, show, resourcetype, handlefilters,
    processing, allowFiltering, auth } = props;

  const handleSubmit = (values) => {
    values.startOn = DateHelpers.setISODate(values.startOn);
    values.endOn = DateHelpers.setISODate(values.endOn);
    handlefilters(values);
  }

  let validationSchema = reportSchema;
  if (FINANCIAL_REPORTS.includes(props.reportName)) {
    validationSchema = financialReportSchema;
  } else if (NO_VALIDATION_REPORTS.includes(props.reportName)) {
    validationSchema = undefined;
  } else {
    validationSchema = reportSchema;
  }

  return (
    <div style={Spacing.formWrapper}>
      {processing && (
        <div style={activityDiv}>
          <div style={activityIndicator}>
            <Dots size={50} color={'#2b6448'} />
          </div>
        </div>
      )}

      <Formik
        initialValues={{
          startOn: '',
          endOn: '',
          dealerIds: [],
          compareStartOn: '',
          compareEndOn: '',
          reportPeriod: '',
          annualReviewStartOn: '',
          annualReviewEndOn: '',
          uccExpiresStartOn: '',
          uccExpiresEndOn: '',
          userIds: [],
          dealerId: '',
          marketId: '',
          year: '',
          kpiPeriod: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <FilterForm
          handleClose={handleclose}
          reportName={props.reportName}
          allowFiltering={allowFiltering}
          setPreview={props.setPreview}
          auth={auth}
        />
      </Formik>
    </div>
  );
}

const showStartOnInput = (values, label) => {
  return (
    <BasicForm.TextInput
      name="startOn"
      key="startOn"
      type="date"
      label={label || 'Start Date for Report (mm/dd/yyyy)'}
      placeholder="mm/dd/yyyy"
      value={values.startOn}
    />
  )
}

const showEndOnInput = (values, label) => {
  return (
    <BasicForm.TextInput
      name="endOn"
      key="endOn"
      type="date"
      label={label || 'End Date for Report (mm/dd/yyyy)'}
      placeholder="mm/dd/yyyy"
      value={values.endOn}
    />
  )
}

const showFlooredStartOnInput = (values) => {
  return (
    <BasicForm.TextInput
      name="startOn"
      key="startOn"
      type="date"
      label="Select All Cars Floored, Starting On:"
      placeholder="mm/dd/yyyy"
      value={values.startOn}
    />
  )
}

const showFlooredEndOnInput = (values) => {
  return (
    <BasicForm.TextInput
      name="endOn"
      key="endOn"
      type="date"
      label="Select All Cars Floored, Ending On:"
      placeholder="mm/dd/yyyy"
      value={values.endOn}
    />
  )
}


const showTransactionOnInput = (values) => {
  return (
    <BasicForm.TextInput
      name="startOn"
      key="startOn"
      type="date"
      label="Date of Transactions for Report"
      placeholder="mm/dd/yyyy"
      value={values.startOn}
    />
  )
}

const showCustomDateInput = (values, name, label) => {
  return (
    <BasicForm.TextInput
      name={name}
      key={name}
      type="date"
      placeholder="mm/dd/yyyy"
      label={label}
      value={values[name]}
    />
  )
}

const showDateTypeSelector = (values) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('queryType', 'created_date')
  }, [])

  return (
    <BasicForm.Select
      name="queryType"
      key="queryType"
      type="select"
      label="Date Type"
      placeholder="mm/dd/yyyy"
    >
      <option value="created_date">Created By Date</option>
      <option value="loan_date">Loan Date</option>
      <option value="voided_date">Voided Date</option>
    </BasicForm.Select>
  )
}

const showFeeTypeSelector = (values) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('queryType', 'paid_and_unpaid')
  }, [])

  return (
    <BasicForm.Select
      name="queryType"
      key="queryType"
      type="select"
      label="Fee Type"
    >
      <option value="paid">Paid</option>
      <option value="unpaid">Unpaid</option>
      <option value="paid_and_unpaid">Paid and Unpaid</option>
    </BasicForm.Select>
  )
}

const showDateType = values => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('dateType', 'created_date')
  }, [])

  return (
    <BasicForm.Select
      name="dateType"
      key="dateType"
      type="select"
      label="Pull By Date Type ('Created Date (UTC)' is the date listed as Created Date in the Transactions Report)"
    >
      <option value="created_date">Created Date</option>
      <option value="bank_date">Bank Date - Col E</option>
      <option value="gl_date">GL Date ("Earned On") - Col F</option>
      <option value="created_at">Created Date (UTC) - Col D</option>
    </BasicForm.Select>
  )
};

const showReportPeriodSelector = ({ includeStartPeriod }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('reportPeriod', 'YTD')
  }, [])

  return (
    <BasicForm.Select
      name="reportPeriod"
      key="reportPeriod"
      type="select"
      label="Report Period"
    >
      <option value="YTD">Year to Date</option>
      {
        includeStartPeriod &&
          <>
            <option value="MTD">Month to Date</option>
            <option value="YTD MTD Comparison">YTD/MTD Comparison</option>
          </>
      }
      <option value="TTM">Trailing Twelve Month</option>
      <option value="YOY">Year over Year</option>
      {
        includeStartPeriod &&
          <>
            <option value="YOY for Month">Year over Year for Month</option>
            <option value="YOY for Quarter">Year over Year for Quarter</option>
          </>
      }
    </BasicForm.Select>
  )
}

const showCalculateRetainedEarnings = () => {
  return (
    <BasicForm.Checkbox
      name="calculateRetainedEarnings"
      label="Calculate Retained Earnings"
    />
  )
}

const showYearInput = values => {
  return (
    <BasicForm.TextInput
      name="year"
      key="year"
      label="Year"
      type="number"
      value={values.year}
    />
  )
}

const showKPIPeriodInput = values => {
  return (
    <BasicForm.Select key="kpiPeriod" name="kpiPeriod" value={values.kpiPeriod}>
      <option>Select Quarter or Month</option>
      {[1,2,3,4].map(quarter => (
        <option key={quarter}>Quarter {quarter}</option>
      ))}
      {Array.from({length: 12}, (_, i) => i + 1).map(period => (
        <option key={period}>Month {period}</option>
      ))}
    </BasicForm.Select>
  );
};

const ignoreStatuses = (values, label) => {
  const { setFieldValue } = useFormikContext();
  const { loading, error, data } = useQuery(DEALER_STATUSES)

  if (!label) { label = 'Ignore These Statuses'; }

  if (loading && !data) return <DataState.Loading key="dealer-statuses" />
  if (error) return <DataState.Error error={error} />;

  const { dealerStatuses } = data;

  const formattedDealerStatuses = dealerStatuses.map((status) => {
    return { value: status.id, label: status.name }
  })

  return(
    <Fragment key="dealer-statuses">
      <BasicForm.StyledLabel>{label}</BasicForm.StyledLabel>
      <Select
        isMulti
        options={formattedDealerStatuses}
        styles={customStyles}
        onChange={(selectedList) => {

          handleMultiSelect(selectedList, 'dealer-statuses', setFieldValue)}
        }
      />
    </Fragment>
  )
}

const showDealerStatuses = values => {
  return ignoreStatuses(values, 'Include These Statuses');
}

const dealerInputOnly = (values) => {
  const { loading, error, data } = useQuery(DEALER_NAMES);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { dealers } = data;

  return (
    <Fragment>
      <BasicForm.Select
        name="dealerId"
        style={{marginBottom: '20px'}}
      >
      <option value=''>Select A Dealer</option>
      { dealers.map((dealer) => (
        <option key={dealer.id} value={dealer.id}>{dealer.name}</option>
      ))}
      </BasicForm.Select>
    </Fragment>
  )
}

const showDealerInput = (values) => {
  const { setFieldValue } = useFormikContext();
  const { loading, error, data } = useQuery(DEALER_NAMES);
  const [disabled, setDisabled] = useState(true);
  const handleClick = () => {
    setDisabled(!disabled);
    if (!disabled) setFieldValue('dealerId', '');
  }

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { dealers } = data;

  return (
    <Fragment>
      <BasicForm.Checkbox
        name="showDealer"
        label="Filter by Dealer"
        onClick={handleClick}
      />
      <BasicForm.Select
        name="dealerId"
        disabled={disabled}
        style={{marginBottom: '20px'}}
      >
      <option value=''>Select A Dealer</option>
      { dealers.map((dealer) => (
        <option value={dealer.id}>{dealer.name}</option>
      ))}
      </BasicForm.Select>
    </Fragment>
  )
}

const showMultiDealerInput = (values) => {
  const { setValues, setFieldValue } = useFormikContext();
  const { loading, error, data } = useQuery(DEALER_NAMES);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;
  const { dealers } = data;

  const formattedDealers = dealers.map((dealer) => {
    return { value: dealer.id, label: dealer.name }
  })

  const handleChangeDealer = (selectedList) => {
    if (!selectedList) {
      values.dealerIds = [];
      setValues(values)
      return
    }

    selectedList = selectedList.map((dealer) => {
      return parseInt(dealer.value, 10)
    })

    setFieldValue('dealerIds', selectedList)
  }

  return (
    <div>
      <BasicForm.StyledLabel>Dealers</BasicForm.StyledLabel>
      <Select
        options={formattedDealers}
        isMulti={true}
        name="dealerIds"
        styles={customStyles}
        onChange={handleChangeDealer}
      />
    </div>
  );
};

const showAutomatedFeesInput = ({ hint }) => {
  const { setValues, setFieldValue } = useFormikContext();
  const { loading, error, data } = useQuery(AUTOMATED_FEE_NAMES);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const formatted = data.automatedFees.map(automatedFee => {
    return { value: automatedFee.id, label: automatedFee.name };
  });

  const handleChange = selected => {
    if (!selected) {
      setFieldValue('automatedFeeIds', []);
      return;
    }

    const selectedIds = selected.map(item => parseInt(item.value, 10));
    setFieldValue('automatedFeeIds', selectedIds);
  };

  return (
    <div>
      <BasicForm.StyledLabel>
        Automated Fees {hint ? hint : ''}
      </BasicForm.StyledLabel>
      <Select
        options={formatted}
        isMulti={true}
        name="automatedFeeIds"
        styles={customStyles}
        onChange={handleChange}
      />
    </div>
  );
};

const showMarketInput = (values) => {
  const { setFieldValue } = useFormikContext();
  const { loading, error, data } = useQuery(MARKETS);
  const [disabled, setDisabled] = useState(true);

  if (loading && !data) return <DataState.Loading key="market" />
  if (error) return <DataState.Error error={error} />;

  const { markets } = data;

  return (
    <Fragment key="market">
      <BasicForm.Select
        label="Market"
        name="marketId"
      >
        <option value=''>Select A Market</option>
        {markets.map(market => (
          <option key={market.id} value={market.id}>{market.name}</option>
        ))}
      </BasicForm.Select>
    </Fragment>
  )
}

const showUserInput = values => {
  const { setValues, setFieldValue } = useFormikContext();
  const { data, error, loading } = useQuery(USER_SELECTS);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const formattedUsers = data.users.map(user => {
    return { value: user.id, label: `${user.firstName} ${user.lastName}`};
  })

  const handleChange = selectedList => {
    if (!selectedList) {
      values.userIds = [];
      setValues(values);
      return
    }

    selectedList = selectedList.map(user => {
      return parseInt(user.value, 10)
    })

    setFieldValue('userIds', selectedList);
  }

  return (
    <>
      <BasicForm.StyledLabel>Users</BasicForm.StyledLabel>
      <Select
        options={formattedUsers}
        isMulti={true}
        onChange={handleChange}
        styles={customStyles}
      />
    </>
  )
}

const showInputs = (reportName, values) => {
  let inputs = [];
  switch(reportName) {
    case 'aging':
      inputs.push(showStartOnInput(values))
      break;
    case 'balance':
      inputs.push(showEndOnInput(values))
      break;
    case 'payments':
    case 'autopay-payments':
      inputs.push([showStartOnInput(values), showEndOnInput(values)])
      break;
    case 'nsf-payments':
      inputs.push([
        showStartOnInput(values, 'Date Flagged as NSF Start (mm/dd/yyyy)'),
        showEndOnInput(values, 'Date Flagged as NSF End (mm/dd/yyyy)'),
        showMultiDealerInput(values)
      ]);
      break;
    case 'income-statement':
      inputs.push(
        showEndOnInput(values),
        showReportPeriodSelector({ includeMTD: true })
      )
      break;
    case 'balance-sheet':
    case 'cash-flow':
      inputs.push(
        showEndOnInput(values),
        showReportPeriodSelector({})
      )
      break;
    case 'trial-balance':
      inputs.push(
        showEndOnInput(values),
        showReportPeriodSelector({}),
        showCalculateRetainedEarnings()
      )
      break;
    case 'temp-programs':
      inputs.push([showStartOnInput(values), showEndOnInput(values)])
      break;
    case 'transactions':
      inputs.push(showStartOnInput(values), showEndOnInput(values),
        showMultiDealerInput(values)
      )
      break;
    case 'transactions-summary':
      inputs.push(
        showStartOnInput(values), showEndOnInput(values), showDateType(values),
      );
      break;
    case 'transactions-csv':
      inputs.push(showTransactionOnInput(values))
      break;
    case 'lot-audit-fee':
      inputs.push([showStartOnInput(values), showEndOnInput(values)])
      break;
    case 'cars-by-loan-date':
      inputs.push([showStartOnInput(values), showEndOnInput(values),
        showDateTypeSelector(values)]
      )
      break;
    case 'title-release':
      inputs.push([showStartOnInput(values), showEndOnInput(values)])
      break;
    case 'title-status':
      inputs.push([showFlooredStartOnInput(values),
        showFlooredEndOnInput(values)]
      )
      break;
    case 'collateral-coverage-loans':
      inputs.push([
        showStartOnInput(values),
        showEndOnInput(values),
        showDealerInput(values)
      ]);
      break;
    case 'collateral-coverage-dealers':
      inputs.push([showEndOnInput(values)])
      break;
    case 'end-of-day-shipments':
      inputs.push([showEndOnInput(values), showUserInput(values)])
      break;
    case 'funding-detail':
      inputs.push([showStartOnInput(values), showEndOnInput(values)])
      break;
    case 'waivers':
      inputs.push([showStartOnInput(values), showEndOnInput(values)])
      break;
    case 'line-summary':
      inputs.push([showStartOnInput(values),ignoreStatuses(values)])
      break;
    case 'on-account':
    case 'reserves':
      inputs.push([showStartOnInput(values), showEndOnInput(values)])
      break;
    case 'writeoffs':
      inputs.push([showStartOnInput(values), showEndOnInput(values),
        showDealerInput(values)]
      )
      break;
    case 'payment-summary':
      inputs.push(showStartOnInput(values), showEndOnInput(values),
        showMultiDealerInput(values)
      )
      break;
    case 'dealer-fees':
      inputs.push(showStartOnInput(values), showEndOnInput(values),
        showFeeTypeSelector(values)
      )
      break;
    case 'repoed-cars':
      inputs.push(showStartOnInput(values), showEndOnInput(values))
      break;
    case 'annual-review':
      inputs.push([
        showCustomDateInput(
          values, 'annualReviewStartOn', 'Annual Review From'
        ),
        showCustomDateInput(
          values, 'annualReviewEndOn', 'Annual Review To'
        ),
        showCustomDateInput(
          values, 'uccExpiresStartOn', 'UCC Expiration From'
        ),
        showCustomDateInput(
          values, 'uccExpiresEndOn', 'UCC Expiration To'
        ),
        showDealerStatuses(values)
      ]);
      break;
    case 'dealer-addresses':
      break;
    case 'reconciliation-exceptions':
      inputs.push(showStartOnInput(values), showEndOnInput(values))
      break;
    case 'cars':
      break;
    case 'unverified-car':
      inputs.push(showStartOnInput(values), showEndOnInput(values))
      break;
    case 'audit-exception-detail':
      inputs.push(
        showStartOnInput(values),
        showEndOnInput(values),
        showMarketInput(values)
      );
      break;
    case 'kpis':
      inputs.push(showYearInput(values), showKPIPeriodInput(values));
      break;
    case 'other-fees-detail':
      inputs.push(
        showStartOnInput(values),
        showEndOnInput(values),
        showAutomatedFeesInput({ hint: '(Leave blank for all Admin Fees)' })
      );
      break;
    default:
      break;
  }
  return inputs;
}

const FilterForm = (props) => {
  const { values, setFieldValue, errors, handleBlur } = useFormikContext();
  const { auth, handleClose, allowFiltering } = props;
  const { data: selectData } = useQuery(USER_SELECTS);

  const today = DateHelpers.setISODate(new Date());

  const preview = () => {
    props.setPreview({ type: props.reportName, params: values});
  }

  return (
    <FormikForm>
      {showInputs(props.reportName, values)}
      <div style={Spacing.buttonRow}>
      {allowFiltering && (
        <Fragment>
          <Buttons.ModalCancel
            type="cancel"
            text="Cancel"
            handleClose={handleClose}
          />
          {isFinancialReport(props.reportName) && (
            <Buttons.Standard
              type="button"
              text="Preview"
              onClick={preview}
            />
          )}
          {displaySubmitButton(
            isFinancialReport,
            hasFinancialAuth,
            props.reportName,
            auth
          )}
        </Fragment>
      )}
      </div>
    </FormikForm>
  )
}

const displaySubmitButton = (
  isFinancialReport,
  hasFinancialAuth,
  reportName,
  auth
) => {
  if (!isFinancialReport(reportName)) {
    return <Buttons.Standard type="submit" text="Submit" />
  }
  if (hasFinancialAuth(auth, 'reports')) {
    return <Buttons.Standard type="submit" text="Submit" />
  }
}

const customStyles = {
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: Colors.xlGreen,
    color: 'white'
  })
}

const activityDiv = {
  display: 'flex',
  justifyContent: 'center',
  height: '30px',
  flexDirection: 'column'
}

const activityIndicator = {
  display: 'flex',
  justifyContent: 'center'
}

export default ReportsFilter;
