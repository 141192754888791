import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { vendorSchema, Form } from './Form';
import DataState from '../DataState';
import { titleCase } from '../../utils/stringHelpers';
import Loading from '../../elements/Loading';

const UPDATE_VENDOR = gql`
  mutation updateVendor($id: ID!, $name: String!, $city: String, $state: String,
    $street: String, $zip: String, $phone: String, $tin: String,
    $tinType: String, $federalClassification: String,
    $taxClassification: String, $eligibleFor1099: String,
    $paymentTermId: String, $expenseAccountId: Int, $payableAccountId: Int) {
    updateVendor (
      id: $id,
      name: $name,
      city: $city,
      state: $state,
      street: $street,
      zip: $zip,
      phone: $phone,
      tin: $tin,
      tinType: $tinType,
      federalClassification: $federalClassification,
      taxClassification: $taxClassification,
      eligibleFor1099: $eligibleFor1099,
      paymentTermId: $paymentTermId,
      expenseAccountId: $expenseAccountId,
      payableAccountId: $payableAccountId
    ) {
      vendor {
        id,
        name,
        city,
        state,
        street,
        zip { formatted },
        phone { formatted },
        tin { formatted },
        tinType,
        federalClassification,
        taxClassification,
        eligibleFor1099,
        paymentTerm { name, days }
        payableAccount { id, gl, name }
        expenseAccount { id, gl, name }
      }
      errors { message, path }
    }
  }
`;

const VENDOR = gql`
  query Vendor($id: ID!) {
    vendor(id: $id) {
      id
      name
      city
      state
      street
      zip { value }
      phone { formatted }
      tin { formatted }
      tinType
      federalClassification
      taxClassification
      eligibleFor1099
      paymentTerm { id }
      payableAccount { id, gl, name }
      expenseAccount { id, gl, name }
    }
  }
`


const EditVendor = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { id } = useParams();

  const [updateVendor] = useMutation(UPDATE_VENDOR,
    {
      onCompleted(values) {
        setFormSubmitting(false);
        setRedirect(true);
      }
    }
  );

  const handleSubmit = (values) => {
    values.state = values.state.toUpperCase();
    setFormSubmitting(true)

    updateVendor({ variables: values })
  };

  const { loading, error, data } = useQuery(VENDOR, { variables: { id } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const { vendor } = data;

  const EditForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: `/vendors`,
          state: { alert: true }
        }}
      />
    }

    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>{`Edit ${vendor.name}`}</h1>
          </div>
          <Formik
            initialValues={{
              id: vendor.id || '',
              name: vendor.name || '',
              city: vendor.city || '',
              state: vendor.state || '',
              street: vendor.street || '',
              zip: vendor.zip?.value || '',
              phone: vendor.phone?.formatted || '',
              tin: vendor.tin?.formatted || '',
              tinType: vendor.tinType || undefined,
              federalClassification: vendor.federalClassification || undefined,
              taxClassification: vendor.taxClassification || undefined,
              eligibleFor1099: vendor.eligibleFor1099 || undefined,
              paymentTermId: vendor.paymentTerm?.id || undefined,
              expenseAccountId: parseInt(vendor.expenseAccount?.id) || undefined,
              payableAccountId: parseInt(vendor.payableAccount?.id) || undefined
            }}
            validationSchema={vendorSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <EditForm />
      </div>
    )
  }

  return (
    <DisplayContent />
  );
};

export default EditVendor;
