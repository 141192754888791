import React, { useState, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import {
  UNSHIPPED_TITLE_LOGS,
  CURRENT_USER,
  CANCEL_SHIPMENT,
  CREATE_SHIPMENT
} from './Queries';
import DataTable from '@preflighttech/preflight-tables';
import { useQuery, useMutation } from '@apollo/client';
import DataState from '../DataState';
import {
  Typography,
  Spacing,
  preflightTableStyles
} from 'components/src/styles';
import * as BasicForm from '../../elements/forms';
import { Formik as FormikForm } from 'formik';
import Select from 'react-select';
import {
  selectStyle,
  shipStyle,
  errorStyle,
  errorSelectionStyle
} from './Style';
import * as Buttons from '../../elements/buttons';
import { columnDefinitions, shippingOptions } from './columnDefinitions';
import Loading from '../../elements/Loading';
import BasicAlert from '../../elements/BasicAlert';
import { useAlert } from '../../hooks/useAlert';
import { setAlertData } from '../../utils/setAlertData';
import Update from './Edit';
import { View } from 'react-native';
import Filter from 'components/src/elements/Filter';
import {
  ResetSettings
} from 'components/src/elements/Pagination/ResetSettings';
import Button from "react-bootstrap/Button";

const UnshippedTitles = props => {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const [checkedEntries, setCheckedEntries] = useState(false);
  const [shippingMethod, setShippingMethod] = useState(false);
  const [showShipmentAlert, setShowShipmentAlert] = useState(false)
  const [showCancelAlert, setShowCancelAlert] = useState(false)
  const [showEdit, setShowEdit] = useState(false);
  const [logId, setLogId] = useState();
  const [dealerId, setDealerId] = useState();
  const [editTitleLogAlert, setEditTitleLogAlert] = useState(false)
  const [filters, setFilters] = useState({});
  const [settings, setSettings] = useState({});

  const queryVariables = { filterColumns: [], filterNames: [] };

  const handleCloseEdit = () => setShowEdit(false);

  const { loading, error, data, refetch } = useQuery(UNSHIPPED_TITLE_LOGS);
  const {
    loading: userLoading,
    error: userError,
    data: userData
  } = useQuery(CURRENT_USER);

  const [cancelTitleShipment] = useMutation(CANCEL_SHIPMENT, {
    update(cache, { data }) {
      var index = checkedEntries.indexOf(data.cancelTitleShipment.titleLog.id)

      if (index >= 0) {
        checkedEntries.splice(index, 1)
      }

      setShowCancelAlert(true)
      refetch()
    }
  })

  const [createShipment, { loading: shipmentLoading }] = useMutation(
    CREATE_SHIPMENT, {
      update(cache, { data }) {
        if (data.createShipment.errors[0]) {
          alert('Error from FEDEX: ' + data.createShipment.errors[0].message);
        } else {
          const fileUrl = data.createShipment?.fileUpload?.fileUrl;
          if (fileUrl) { window.open(fileUrl); }
          setCheckedEntries([])
          setShowShipmentAlert(true)
          refetch()
        }
      }
    }
  )

  useEffect(() => setAlertData(props, setAlertType, displayAlert), []);

  if (loading || userLoading || !data || !userData)
    return <DataState.Loading />;
  if (error || userError)
    return <DataState.Error error={error || userError} />;

  if (checkedEntries === false) {
    const initialCheckedEntries = []
    data.titleLogs.forEach(log => {
      if (log.createdBy.id === userData.currentUser.id) {
        initialCheckedEntries.unshift(log.id)
      }
    })

    setCheckedEntries(initialCheckedEntries)
  }

  let options = []

  data.titleLogs.map((log) => {
    options.unshift(log.createdBy)
  })

  const uniqueOptions = Array.from(new Set(options.map(a => a.id))).map(id => {
    return options.find(a => a.id === id)
  })

  const formattedUserOptions = uniqueOptions.map(option => {
    return { label: option.fullName, value: option.id }
  })

  const handleChange = selected => {
    if (selected) {
      data.titleLogs.forEach(log => {
        if (log.createdBy.id === selected.value &&
          !checkedEntries.includes(log.id)) {
          checkedEntries.unshift(log.id)
        }
      })

      setCheckedEntries(checkedEntries)
      forceUpdate();
    }
  }

  const handleUncheck = selected => {
    if (selected) {
      data.titleLogs.map(log => {
        if (log.createdBy.id === selected.value) {
          var index = checkedEntries.indexOf(log.id);

          if (index >= 0) {
            checkedEntries.splice(index, 1)
            setCheckedEntries(checkedEntries)
            forceUpdate()
          }
        }
      })
    }
  }

  const checkAll = () => {
    data.titleLogs.forEach(log => {
      if (!checkedEntries.includes(log.id)) {
        checkedEntries.unshift(log.id)
      }
    })

    setCheckedEntries(checkedEntries)
    forceUpdate()
  }

  const formattedShippingOptions = shippingOptions.map(option => {
    return { label: option.label, value: option.value }
  })

  const handleShippingOptions = selected => {
    if (selected) {
      setShippingMethod(selected.value)
    }
  }

  const setFilter = (key, value) => {
    const currentFilters = {...filters};
    currentFilters[key] = value;
    setFilters(currentFilters);

    Object.entries(currentFilters).forEach(([key, value]) => {
      if (value) {
        queryVariables['filterColumns'].push(key);
        queryVariables['filterNames'].push(value);
      }
    });

    refetch(queryVariables)
  }

  const buttons = (
    <View style={{flexDirection: 'row'}}>
      <Filter
        clearFilters={
          () => { setFilters({ created_by: null }); refetch(queryVariables) }
        }
      >
        <Filter.MultiSelect
          columnKey='created_by'
          label='Created By'
          options={formattedUserOptions}
          filters={filters}
          setFilter={setFilter}
        />
        <Filter.MultiSelect
          columnKey="market_id"
          label="Market"
          options={data.markets}
          filters={filters}
          setFilter={setFilter}
        />
      </Filter>
      <ResetSettings />
    </View>
  )

  const columns = columnDefinitions({
    checkedEntries, setCheckedEntries, forceUpdate,
    cancelTitleShipment, setShowEdit, setLogId, setDealerId
  })

  const visibleCheckedEntries = [];

  if (checkedEntries !== false) {
    data.titleLogs.forEach(titleLog => {
      if (checkedEntries.includes(titleLog.id)) {
        visibleCheckedEntries.push(titleLog.id);
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>Unshipped Titles</title>
      </Helmet>
      {showAlert && (
        <BasicAlert componentType='Title Log Batch' type='created' />
      )}
      {showShipmentAlert && (
        <BasicAlert componentType='Shipment' type='created' />
      )}
      {showCancelAlert && (
        <BasicAlert componentType='Pending Shipment' type='canceled' />
      )}
      {editTitleLogAlert && (
        <BasicAlert componentType="Title Log Address" type="updated" />
      )}
      {showEdit && (
        <Update
          show={showEdit}
          handleClose={handleCloseEdit}
          dealerId={dealerId}
          logId={logId}
          refetch={refetch}
          setEditTitleLogAlert={setEditTitleLogAlert}
        />
      )}
      <div style={Typography.pageTitle}>Unshipped Title Logs</div>
      <div style={selectStyle}>
        <div>Add All Titles For:</div>
        <Select
          options={formattedUserOptions}
          isMulti={false}
          onChange={handleChange}
          isClearable={true}
          style={{ width: '500px' }}
        />
        <br/>
        <div>Remove All Titles For:</div>
        <Select
          options={formattedUserOptions}
          isMulti={false}
          onChange={handleUncheck}
          isClearable={true}
          style={{ width: '500px' }}
        />
        <br/>
        <Button style={{margin: "5px"}} onClick={() => checkAll()}>
          Check All
        </Button>
        <Button style={{margin: "5px"}} onClick={() => setCheckedEntries([])}>
          Uncheck All
        </Button>
      </div>
      <DataTable.Simple
        data={data.titleLogs}
        columns={columns}
        pageLength={25}
        lengthMenu={ [10, 25, 50, 'All'] }
        htmlTable
        buttons={buttons}
        setSettings={setSettings}
        styles={preflightTableStyles}
      />
      <div style={errorSelectionStyle}>
        { visibleCheckedEntries.length < 1 ? 'Required' : '' }
      </div>
      <div style={selectStyle}>
        <div style={Typography.formSubHeader}>
          Selected: {visibleCheckedEntries.length}
        </div>
      </div>
      <div style={shipStyle}>
        <div>Shipping Method:</div>
        <Select
          options={formattedShippingOptions}
          isMulti={false}
          onChange={handleShippingOptions}
        />
        <div style={errorStyle}>
          { shippingMethod === false ? 'Required' : '' }
        </div>
      </div>
      <div style={Spacing.buttonRow}>
        {shipmentLoading ? <Loading /> :
          <Buttons.Standard
            type='submit'
            text='Create Shipment'
            disabled={
              shippingMethod === false || visibleCheckedEntries.length < 1
            }
            onClick={
              () => createShipment({
                variables: {
                  shippingMethod: shippingMethod,
                  checkedEntries: visibleCheckedEntries
                }
              })
            }
          />
        }
      </div>
    </>
  )
}

export default UnshippedTitles;
