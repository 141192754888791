import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const DEPARTMENT_FIELDS = `
  id
  name
  canBeHidden
`;

const DEPARTMENT_FIELDS_AND_ERRORS = `
  department {
    ${DEPARTMENT_FIELDS}
  }
  ${ERRORS}
`;

export const DEPARTMENTS = gql`
  query Departments($scopes: [String!]) {
    departments(
      scopes: $scopes
    ) {
      ${DEPARTMENT_FIELDS}
    }
  }
`;

export const DEPARTMENT = gql`
  query DEPARTMENT($id: ID!) {
    department(id: $id) {
      ${DEPARTMENT_FIELDS}
    }
  }
`;

export const HIDE_DEPARTMENT = gql`
  mutation HideDepartment($id: ID!) {
    hideDepartment(
      id: $id
    ) {
      ${DEPARTMENT_FIELDS_AND_ERRORS}
    }
  }
`;

export const UNHIDE_DEPARTMENT = gql`
  mutation unhideDepartment($id: ID!) {
    unhideDepartment(
      id: $id
    ) {
      ${DEPARTMENT_FIELDS_AND_ERRORS}
    }
  }
`;

export const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment(
    $name: String!
  ) {
    createDepartment(
      name: $name
    ) {
      ${DEPARTMENT_FIELDS_AND_ERRORS}
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment(
    $id: ID!
    $name: String
  ) {
      updateDepartment(
      id: $id
      name: $name
    ) {
      ${DEPARTMENT_FIELDS_AND_ERRORS}
    }
  }
`;
