import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const INSURANCE_STATE_FIELDS = `
  id
  name
  state
  defaultInsuranceProgram {
    id
    name
  }
`;

export const INSURANCE_STATES = gql`
  query InsuranceStates {
    insuranceStates {
      ${INSURANCE_STATE_FIELDS}
    }
  }
`;
