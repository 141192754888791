export const canWaiveFee = (fee, auth) => {

  if (0.0 === parseFloat(fee.due)) {
    return [false, fee.status];
  } else if (!fee.canUserWaive) {
    return [false, 'Fee cannot be waived'];
  } else if (!auth.hasPolicy('Fee', 'waive')) {
    return [false, 'You do not have the Fee Waiver Role'];
  }

  return [true, ''];
};
