import React, { useState } from 'react';
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { APPROVED_SOURCES_CONTACTS } from "../../../queries/indexQueries";
import { contactSchema, Form } from "./Form";
import { Spacing, Typography } from "../../../styles";
import { useParams } from "react-router-dom";
import Loading from '../../../elements/Loading';

const CREATE_CONTACT = gql`
  mutation CreateContact(
    $firstName: String!
    $lastName: String
    $email: String
    $title: String
    $officePhone: String
    $mobilePhone: String
    $primary: Boolean!
    $receivesPaymentInformation: Boolean
    $ownerType: String!
    $ownerId: Int!
  ) {
    createContact(
      firstName: $firstName
      lastName: $lastName
      email: $email
      title: $title
      officePhone: $officePhone
      mobilePhone: $mobilePhone
      primary: $primary
      receivesPaymentInformation: $receivesPaymentInformation
      ownerType: $ownerType
      ownerId: $ownerId
    ) {
      contact {
        id
        firstName
        lastName
      }
      errors {
        message
        path
      }
    }
  }
`;

const New = () => {
  const history = useHistory();
  const approvedSourceId = useParams().id;
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [createContact] = useMutation(CREATE_CONTACT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: APPROVED_SOURCES_CONTACTS,
        variables: { id: approvedSourceId },
      },
    ],
    onCompleted() {
      setFormSubmitting(false);
      history.push({
        pathname: `/approved-sources/${approvedSourceId}/contacts`,
        state: { alert: true, type: "created" },
      });
    },
  });

  const handleSubmit = (values) => {
    setFormSubmitting(true)
    createContact({ variables: values });
  };

  const NewForm = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Contact</h1>
          </div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              title: "",
              officePhone: "",
              mobilePhone: "",
              primary: false,
              receivesPaymentInformation: false,
              ownerType: "ApprovedSource",
              ownerId: parseInt(approvedSourceId),
            }}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    );
  };

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return (
      <div style={Spacing.formContainer}>
        <NewForm />
      </div>
    )
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default New;
