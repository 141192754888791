import React from 'react';
import { useParams } from 'react-router-dom';
import Info from '../Info';
import Notes from '../../Notes';
import { AUCTION_NOTES } from '../queries';

const AuctionNotes = () => {
  const id = parseInt(useParams().ownerId);

  return (
    <>
      <Info />
      <Notes
        query={AUCTION_NOTES}
        ownerType="auction"
        ownerId={id}
      />
    </>
  );
};

export default AuctionNotes;
