import React from 'react';
import { Typography } from '../../../styles';
import DealerInfo from '../DealerInfo';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import NewQuote from '../../Payments/New';
import { DEALER_FLOORED_CARS } from '../../../queries/viewQueries';
import DataState from '../../DataState';
import { Helmet } from 'react-helmet';
import { currency } from '../../../utils/stringHelpers';
import { DateHelpers } from '../../../utils';

const index = () => {
  const id = parseInt(useParams().id);
  const { loading, error, data } = useQuery(DEALER_FLOORED_CARS, {
    variables: { id }
  })

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const { dealer: { flooredCars }} = data;

  const hrStyle = {
    width: '100%',
  }

  let total = 0;

  flooredCars.sort((a, b) => {
    return new Date(a.currentDueOn) - new Date(b.currentDueOn)
  });

  const payableFees = data.dealer.payableFees.map((fee) => {
    total += parseFloat(fee.due)
    return (
      <div key={fee.id}>
        {fee.name}: {currency(fee.due)} - {DateHelpers.setDate(fee.chargeOn)}
        <br />
      </div>
    )
  })

  const PayableFees = () => {
    if (data.dealer.payableFees.length > 0) {
      return (
        <>
          <hr style={hrStyle} />
            <div style={{paddingLeft: "20px"}}>
              <h4>The following Dealer Fee(s) will be added to this payment:</h4>
              <div>{payableFees}</div>
              <h5>total: {currency(total)}</h5>
            </div>
          <hr style={hrStyle} />
        </>
      )
    }
    else {
      return null
    }
  }

  return (
    <>
      <Helmet>
        <title>{`Set Up Payment For ${data.dealer.name}`}</title>
      </Helmet>
      <div style={Typography.pageTitle}>Set Up Payment</div>
        <PayableFees />
      <NewQuote data={flooredCars} total={total} />
    </>
  );
};

export default index;
