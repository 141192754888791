import React from 'react';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../styles';
import { dealerSchema, Form } from './NewForm';
import Loading from '../../elements/Loading';
import { useServerSideErrors } from '../../hooks/useServerSideErrors'
import { DEALER_VARIABLES, DEALER_ARGUMENTS } from './Queries';

const CREATE_DEALER = gql`
  mutation CreateDealer(
    ${DEALER_VARIABLES}
    $dealerStatusId: Int!
    $dealerPlacesAttributes: [DealerPlaceInput!]
  ) {
    createDealer(
      ${DEALER_ARGUMENTS}
      dealerStatusId: $dealerStatusId
      dealerPlacesAttributes: $dealerPlacesAttributes
    ) {
      dealer {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const New = () => {
  const customSubmitHandler = (values) => {
    if ('' === values.entityType) {
      values.entityType = null;
    }

    if (values.automatedFeeResourcesAttributes) {
      values.dealerLinesAttributes = [{
        name: 'Main',
        rateProgramId: values.rateProgramId,
        dealerProgramId: values.dealerProgramId,
        creditLine: values.creditLine,
        contractedLine: values.contractedLine,
        reservesAmount: values.reservesAmount,
        automatedFeeIds: values.automatedFeeResourcesAttributes.map(fee => {
          return parseInt(fee.id)
        })
      }]
    } else {
      values.dealerLinesAttributes = [{
        name: 'Main',
        rateProgramId: values.rateProgramId,
        dealerProgramId: values.dealerProgramId,
        creditLine: values.creditLine,
        contractedLine: values.contractedLine,
        reservesAmount: values.reservesAmount,
      }]
    }
    values.dealerPlacesAttributes = [{
      name: values.locationName,
      type: values.locationType,
      phone: values.phone,
      fax: values.fax,
      attention: values.attention,
      street: values.street,
      city: values.city,
      state: values.state,
      zip: values.zip
    }]
  };

  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_DEALER,
    onCompleteRedirectTo: '/dealers',
    customSubmitHandler,
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const NewDealerForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Create A New Dealer</h1>
        </div>
        <ServerSideErrors />
        <Formik
          initialValues={{
            name: '',
            legalName: '',
            accountNumber: null,
            licenseNumber: '',
            contractedOn: null,
            licenseExpiresOn: null,
            annualReviewOn: null,
            branchId: null,
            marketId: null,
            dealerStatusId: null,
            accountRepId: null,
            serviceRepId: null,
            internalCreditScore: '',
            licenseType: '',
            poaReceived: false,
            recentBusinessTaxYear: '',
            businessFinancialsDate: null,
            recentPersonalTaxYear: '',
            personalFinancialsDate: null,
            dealerProgramId: null,
            rateProgramId: null,
            automatedFeeResourcesAttributes: null,
            creditLine: null,
            contractedLine: null,
            reservesAmount: 0,
            locationName: null,
            locationType: "Lot",
            phone: null,
            fax: null,
            attention: null,
            street: '',
            city: '',
            state: null,
            zip: '',
            entityType: '',
            entityTypeOther: '',
            dealerCategoryIds: null
          }}
          validationSchema={dealerSchema}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      </div>
    )
  }

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formContainer}>
          <NewDealerForm />
        </div>
      </>
    )
  }
  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default New;
