import React from 'react';
import * as yup from 'yup';
import { Form as FormikForm } from 'formik';
import * as BasicForm from '../../../elements/forms';
import * as Buttons from '../../../elements/buttons';
import { Spacing } from '../../../styles';

export const arbitrationSchema = yup.object({
  arbitrationReason: yup
    .string()
    .required('Required'),
  arbitratedOn: yup
    .date()
    .required('Required')
});

export const Form = () => {
  return (
    <FormikForm>
      <BasicForm.TextInput
        name="arbitratedOn"
        type="date"
        label="Arbitration On"
      />
      <BasicForm.TextInput
        name="arbitrationReason"
        type="text"
        label="Arbitration Reason"
      />
      <div style={Spacing.buttonRow}>
        <Buttons.Standard type="submit" text="Submit" />
      </div>
    </FormikForm>
  );
}
