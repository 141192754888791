import * as Colors from '../styles/colors';

export const errorCenter = {
  textAlign: 'center',
  paddingTop: '50px'
}

export const bottomBorder = {
  height: '7px',
  width: '100%',
  backgroundColor: process.env.REACT_APP_HEADER_BACKGROUND ||
    Colors.xlOrange,
}

export const navBar = {
  paddingTop: 0,
  paddingBottom: 0,
  content: '',
  whiteSpace: 'pre'
}

export const navItem = {
  color: process.env.REACT_APP_HEADER_BACKGROUND || Colors.xlOrange,
  fontSize: 17,
  marginRight: 50
};

export const navText = {
  marginLeft: 4,
  fontWeight: 500,
};

export const notificationItem = {
  color: Colors.xlGreen,
  fontSize: 12,
};

export const pStyle = {
  fontSize: '50px',
  color: Colors.xlGreen
}

export const preStyle = {
  fontSize: '18px'
}

export const buttonStyle = {
  margin: '20px',
  backgroundColor: 'white'
}

export const spanColor = {
  color: '#FF0102'
}

export const authStyle = {
  marginTop: '20px'
}

export const h4Style = {
  color: 'red',
  marginBottom: '0px'
}

export const circleStyle = {
  color: Colors.xlOrange,
  marginLeft: '20px'
}

export const greenNav = {
  color: Colors.xlGreen
}

