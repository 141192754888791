import { gql } from '@apollo/client'

export const POA_CPP = gql`
  query PoaCpp(
    $marketIds: [ID!],
    $sessionMarketIds: [ID!],
    $types: [String!]
  ) {
    poaCpp(
     marketIds: $marketIds
     sessionMarketIds: $sessionMarketIds
     types: $types
    ) {
      entries {
        dealer {
          id
          name
          insuranceExpiresOn
          market {
            name
          }
        }
        type
      }
      markets {
        id
        name
      }
    }
  }
`;
