import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { useAlert } from '../../../hooks/useAlert';
import DataState from '../../DataState';
import { updateUserSchema, UserForm } from './Form';
import { titleCase } from '../../../utils/stringHelpers';
import UserNotes from './Notes';
import Loading from '../../../elements/Loading';
import { USER, UPDATE_USER } from './Queries';

const Edit = () => {
  const id = useParams().ownerId;
  const { loading, error, data } = useQuery(USER, { variables: { id } });
  const [redirect, setRedirect] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER,
    {
      onCompleted() {
        setFormSubmitting(false);
        setRedirect(true);
      }
    }
  );

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  let { email, firstName, lastName, mobilePhone, roleIds, marketIds, password, hasWaiverLimit, waiverLimit } = data.user

  if (!firstName) firstName = ''
  if (!lastName) lastName = ''

  const EditForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/users",
          state: { alert: true }
        }}
      />
    }

    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>{`Update User: ${titleCase(firstName)} ${titleCase(lastName)}`}</h1>
        </div>
        <Formik
          initialValues={{
            id: id,
            email: email || '',
            firstName: firstName || '',
            lastName: lastName || '',
            mobilePhone: mobilePhone.formatted || '',
            roleIds: roleIds || [],
            marketIds: marketIds || [],
            password: '',
            passwordConfirmation: '',
            supervisorId: data.user.supervisor?.id || '',
            hasWaiverLimit: hasWaiverLimit || false,
            waiverLimit: waiverLimit || 0
          }}
          validationSchema={updateUserSchema}
          onSubmit={handleSubmit}
        >
          <UserForm />
        </Formik>
      </div>
    )
  }

  const handleSubmit = (values) => {
    if (values.password === '') {
      values = {
        id: values.id,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        mobilePhone: values.mobilePhone,
        roleIds: values.roleIds,
        marketIds: values.marketIds,
        supervisorId: values.supervisorId,
        hasWaiverLimit: values.hasWaiverLimit,
        waiverLimit: values.waiverLimit || 0
      }
    }

    setFormSubmitting(true)
    updateUser({ variables: values });
  };

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formContainer}>
          <EditForm />
        </div>
        <UserNotes />
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default Edit;
