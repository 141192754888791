import React, { useState } from 'react';
import { currency } from '../../../utils/stringHelpers';
import { setDate } from 'components/src/utils/dateHelpers';
import Edit from './Edit';

export const columnDefinitions = props => {
  const definitions = [
    {
      key: 'programType',
      label: 'Program Type',
      content: ({ entry }) => entry.programType || 'Credit Limit Increase'
    },
    {
      key: 'programName',
      label: 'Program Name/Amount',
      content: ({ entry }) =>
        entry.programName || currency(entry.creditLimitIncrease)
    },
    {
      key: 'effectiveOn',
      label: 'Effective Date',
      content: ({ entry }) => setDate(entry.effectiveOn)
    },
    {
      key: 'endOn',
      label: 'End Date',
      content: ({ entry }) => setDate(entry.endOn)
    },
    {
      key: 'createdBy',
      label: 'Created By',
      content: ({ entry }) => entry.createdBy.fullName
    },
    {
      key: 'actions',
      label: 'Actions',
      content: ({ entry }) => {
        const [showModal, setShowModal] = useState(false)

        let endOn = new Date(entry.endOn)

        endOn.setHours(23)
        endOn.setDate(endOn.getDate() + 1)

        return (
          <>
            {
              showModal && <Edit
                showModal={showModal}
                setShowModal={setShowModal}
                tempId={entry.id}
              />
            }
            {
               endOn >= new Date() &&
                <a href="#" onClick={() => setShowModal(true)}>Edit</a>
            }
          </>
        )
      }
    }
  ]

  return definitions
}
