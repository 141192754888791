import React from 'react';
import { useFormikContext, Form as FormikForm } from 'formik';
import { useQuery } from '@apollo/client';
import { Spacing } from 'components/src/styles';
import * as Buttons from 'components/src/elements/buttons';
import * as BasicForm from 'components/src/elements/forms';
import { DEALER_NAMES } from 'components/src/queries/selects';
import Select from 'react-select';
import DataState from 'components/src/components/DataState';

const SelectDealer = () => {
  const { setFieldValue } = useFormikContext();
  const { loading, error, data } = useQuery(DEALER_NAMES);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  return (
    <>
      <BasicForm.Select
        name="dealerId"
        label="Select Dealer"
        style={{marginBottom: '20px'}}
      >
        <option value="">Select A Dealer</option>
        {data.dealers.map(dealer => (
          <option value={dealer.id}>{dealer.name}</option>
        ))}
      </BasicForm.Select>
    </>
  );
};

const ProcessPaymentsReport = ({ dealerId, handleSubmit }) => {
  const { values } = useFormikContext();

  const reportsFilterTitle = {
    fontWeight: 'bold',
    fontSize: '1.5em',
    color: 'rgb(214, 142, 0)',
    marginTop: '1%',
    paddingLeft: '0.5%'
  }

  return (
    <FormikForm>
      <div style={reportsFilterTitle}>
        Dealer Payments Report
      </div>
      <div style={{padding: "20px"}}>
        <div>
          {!dealerId && <SelectDealer />}
          <BasicForm.TextInput
            name="startOn"
            type="date"
            label="Start Date for Report"
          />
          <BasicForm.TextInput
            name="endOn"
            type="date"
            label="End Date for Report"
          />
        </div>
        <div style={Spacing.buttonRow}>
          <Buttons.Cancel
            type="cancel"
            text="Cancel"
          />
          <Buttons.Standard
            type="submit"
            text="Submit"
            disabled={!values.dealerId}
          />
        </div>
      </div>
    </FormikForm>
  )
}

export default ProcessPaymentsReport
