import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { Spacing, Typography } from '../../../styles';
import { branchSchema, Form } from './Form';
import { BRANCHES } from '../../../queries/indexQueries'
import Loading from '../../../elements/Loading';
import { useServerSideErrors } from '../../../hooks/useServerSideErrors'

const CREATE_BRANCH = gql`
 mutation CreateBranch($code: String!, $name: String!, $street: String!,
  $city: String!, $state: String!, $zip: String!, $phoneNumber: String!,
  $attention: String! $email: String!)
  {
    createBranch(code: $code, name: $name, street: $street, city: $city,
      state: $state, zip: $zip, phoneNumber: $phoneNumber,
      attention: $attention, email: $email)
    {
      branch {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;


const New = () => {
  const serverSideErrorProps = {
    SAVE_MUTATION: CREATE_BRANCH,
    onCompleteRedirectTo: '/system/branches'
  }

  const [ServerSideErrors, handleSubmit, formSubmitting] = useServerSideErrors(serverSideErrorProps);

  const NewForm = () => {
    return (
      <>
        <div style={Spacing.formWrapper}>
          <div style={Spacing.formHeader}>
            <h1 style={Typography.formHeader}>Create A New Branch</h1>
          </div>
          <ServerSideErrors />
          <Formik
            initialValues={{
              code: '',
              name: '',
              street: '',
              city: '',
              state: '',
              zip: '',
              phoneNumber: '',
              attention: '',
              email: '',
            }}
            validationSchema={branchSchema}
            onSubmit={handleSubmit}
          >
            <Form />
          </Formik>
        </div>
      </>
    )
  }

  const LoadedContent = () => {
    return (
      <>
        <div style={Spacing.formContainer}>
          <NewForm />
        </div>
      </>
    )
  }

  const DisplayContent = () => {
    if (formSubmitting) {
      return (
        <div style={Spacing.formContainer}>
          <Loading />
        </div>
      )
    } return <LoadedContent />
  }

  return (
    <>
      <DisplayContent />
    </>
  );
};

export default New;
