import {
  faEdit,
  faMoneyCheckAlt,
  faUniversity,
} from '@fortawesome/free-solid-svg-icons';
import { faCars, faFileInvoice } from '@fortawesome/pro-solid-svg-icons'
import {
  faCommentAltLines,
  faUsers,
  faMoneyCheckEditAlt,
  faClipboardListCheck,
  faTriangle,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faFileAlt,
} from '@fortawesome/free-regular-svg-icons';

export const setNavOptions = (id, dealerId, name, auth) => {
  const navLinks = [
    {
      link: `/dealers/${dealerId}/cars/${id}/edit`,
      icon: faEdit,
      text: 'Edit'
    },
    {
      link: `/dealers/${dealerId}/cars/${id}/transactions`,
      icon: faMoneyCheckAlt,
      text: 'Transactions'
    },
    {
      link: `/dealers/${dealerId}/cars/${id}/payments`,
      icon: faMoneyCheckEditAlt,
      text: 'Set Up Payment'
    },
    {
      link: `/dealers/${dealerId}/cars/${id}/audits`,
      icon: faClipboardListCheck,
      text: 'Audits'
    },
    {
      link: `/dealers/${dealerId}/cars/${id}/notes`,
      icon: faCommentAltLines,
      text: 'Notes'
    },
    {
      link: `/dealers/${dealerId}/cars/${id}/files`,
      icon: faFileAlt,
      text: 'Files'
    }
  ]

  if (auth.hasExactPolicy('Txn', 'view_accruals')) {
    navLinks.splice(
      2, 0, {
        link: `/dealers/${dealerId}/cars/${id}/accruals`,
        icon: faFileInvoice,
        text: 'Accruals'
      }
    );
  }

  const title = {
    link: `/dealers/${dealerId}/cars/${id}`,
    text: name,
  }

  if (auth.hasPolicy('Delta', 'view')) {
    navLinks.push(
      {
        link: `/dealers/${dealerId}/cars/${id}/deltas`,
        icon: faTriangle,
        text: 'Deltas',
      }
    )
  }

  return [navLinks, title]
}
